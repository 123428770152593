import { PaymentRequestCategory } from './payment-request-category';

export interface ICreatePaymentRequest
{
    amount: number;
    paymentAddress: string;
    reason: string;
    requesterId: number;
    paymentMethodId: number;
    confirmationEmail: string;
    orderId?: number;
    category?: PaymentRequestCategory;
    status: string;
    refundType: string;
    fullRefund?: boolean;
    comment: string;
    currencyId: number;
}
