// Module
var code = `<template>
    <div id="cx-posts-cards">
        <div class="card-deck">
            <div class="card" repeat.for="blog of blogs">
                <img if.bind="blog.previewImagePath" src="\${baseAwsEndpoint}\${blog.previewImagePath}" alt="\${blog.name} image" class="card-img-top" loading="lazy">
                <div else class="no-image">
                    <span class="material-icons-outlined">image_not_supported</span>
                </div>
                <div class="card-body">
                    <h5 if.bind="blog.title.length > 26">
                        <a href="\${blogRoute}/\${blog.slug}" class="card-title d-inline-block font-bold text-truncate" mdc-tooltip="value.bind: blog.title; x-position.bind: 'END';">
                            \${blog.title}
                        </a>
                    </h5>
                    <h5 else>
                        <a href="\${blogRoute}/\${blog.slug}" class="card-title d-inline-block font-bold text-truncate">
                            \${blog.title}
                        </a>
                    </h5>
                    <div class="d-flex tag-container">
                        <p click.delegate="tagClickHandler(blog.blogTag.name)" class="tag-label">\${blog.blogTag.name}</p>
                    </div>
                    <div if.bind="blog.content" innerhtml.bind="blog.content | textTruncate:140" class="card-text font-light"></div>
                    <div else class="card-text font-light"></div>
                    <div class="footer-blog">
                        <a class="card-author" href="\${blogRoute}/author/\${blog.blogAuthor.alias}">
                            <p if.bind="blog.blogTag.name === 'CSR' && blog.blogTag.translated === true">
                                \${'CX_TRANSLATED_BY_AUTHOR' | siteStrings} \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                            </p>
                            <p else>
                                \${'CX_BY_AUTHOR' | siteStrings} \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                            </p>
                        </a>
                        <div class="card-footer d-flex justify-content-between align-items-center">
                            <p>\${blog.createdDate | dateFormatter:'format':'MMMM DD, YYYY':hrefLang}</p>
                            <a href="\${blogRoute}/\${blog.slug}" class="font-medium">\${'CX_READ_MORE' | siteStrings}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;