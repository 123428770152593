// Module
var code = `<template>
    <cx-try-again-box
        id="rate-limit"
        use-keys.bind="true"
        main="RATE_LIMITED_TITLE"
        subtitle="RATE_LIMITED_SUBTITLE"
        page="Rate Limited"
        icon="error_outline"
        href.bind="homePageRoute"
    >
    </cx-try-again-box>
</template>
`;
// Exports
export default code;