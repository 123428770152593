import { BlacklistResponse } from '../blacklist/blacklistResponse';
import { LoginResponse } from './loginResponse';
import { PaymentMethodResponse, TransactionAddressType } from '../purchase-flow/exchange';

export class User {
    firstName: string;
    lastName: string;
    email: string;
    id: number;
    idVerified: boolean;
    phoneNumberConfirmed: boolean;
    emailConfirmed: boolean;
    optedInForEmails: boolean;
    closedSubscriptionBanner: boolean;
    addressVerified: boolean;
    avatarImagePath: string;
    twoFactorEnabled?: boolean;
    phoneNumber: string;
    liveChatCXHash: string;
    phoneCountryCode?: number;
    phoneVerified?: boolean;
    phoneCountryFlag: string;
    paymentMethodId?: number;
    gameId?: number;
    roles: string[];
    blueSnapVaultedShopperId?: number;
    blueSnapUsChicksGoldVaultedShopperId?: number;
    blueSnapUsDivicaSalesVaultedShopperId?: number;
    discordId: string;
    address: string;
    city: string;
    country: string;
    zip: string;
    state: string;
    closedChicksXBanner: boolean;
    closedTempPasswordBanner: boolean;
    isDeleted: boolean;
    balance: number;
    refreshToken: LoginResponse;
    balanceEnabled: boolean;
    notUsingTemporaryPassword: boolean;
    userBlacklist: BlacklistResponse[];
    isSuperAdmin: boolean;
    departmentId?: number;
    subscriptions;
    discordTag: string;
    pendingBalance?: number;
    addressVerificationInReview?: boolean;
    receivingPaymentMethodId: number;
    receivingPaymentMethods: ReceivingPaymentMethodResponse[];
    hasFreeTrial: boolean;
    idVerificationInReview?: boolean;
    emailInReview?: boolean;
    selfieVerificationInReview?: boolean;
    createdDate: Date;

    isSubscribed?: boolean;
    discordUnlink?: boolean;
}

export class TotalSpentByUser {
    totalSpent: number;
    total1DaySpent: number;
    total7DaySpent: number;
    total14DaySpent: number;
    total30DaySpent: number;
    totalChargebackAmount: number;
    totalCountOrderCompleted: number;
}

export class ReceivingPaymentMethodResponse {
    userId: number;
    paymentMethodId: number;
    paymentMethod: PaymentMethodResponse;
    address: string;
    code?: string;
    transactionAddressType?: TransactionAddressType;
    additionalInformation?: string;
}

export interface IReceivingPaymentMethodRequest {
    paymentMethodId: number;
    address?: string;
    code?: string;
    additionalInformation?: string;
    transactionAddressType?: TransactionAddressType;
}

export enum WithdrawPasswordVerificationResponse {
    Success,
    Invalid,
    Blocked
}

export interface ExtendedUser extends User {
    renewal: boolean;
    street: string;
}
