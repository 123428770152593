// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/green_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/exclamation-red.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/exclamation-yellow.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/exclamation-blue.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-banner" if.bind="showBanner" class="\${state === 'warning' ? 'warning-background' : state === 'error' ? 'error-background' : 'default-background'}">
        <div class="banner-box text-regular" id="cx-banner-container">
            <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="state === 'success'" class="align-text-top" alt="success" loading="lazy">
            <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="state === 'error'" class="align-text-top" alt="error" loading="lazy">
            <img src="${___HTML_LOADER_IMPORT_2___}" if.bind="state === 'warning'" class="align-text-top" alt="warning" loading="lazy">
            <img src="${___HTML_LOADER_IMPORT_3___}" if.bind="state === 'info'" class="align-text-top" alt="warning" loading="lazy">
            <span class="banner-text text-light-black font-light">
                \${text}
                <span if.bind="clickFunctionName && clickFunctionName !== ''" class="text-decoration-underline cursor-pointer banner-text" click.delegate="clickFunction()">click here</span>
                <a if.bind="url && url !== ''" class="text-decoration-underline cursor-pointer banner-text text-light-black" href="\${url}" target="_blank" rel="nofollow noopener noreferrer">click here</a>
                <span class="dot-container">.</span>
            </span>
            <div class="text-large cursor-pointer text-light-black" click.delegate="closeBanner()">
                ✕
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;