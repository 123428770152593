// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/green_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/red_fail.svg", import.meta.url);
// Module
var code = `<template>
    <div id="security-two-fa-settings">
        <cx-card class.bind="'top-up-view-container'">
            <cx-back-button route.bind="'security'" class="mb-20px"></cx-back-button>
            <div class="title-container">
                <h1 class="page-title">\${'CX_2FA_SETTINGS' | siteStrings}</h1>
            </div>
            <div class="form-container">
                <div class="cx-notice-box indications" if.bind="!user.emailConfirmed">
                    <p if.bind="width > tablet" class="main-message">
                        \${twoFactorTop | siteStrings}
                    </p>
                    <p else class="main-message">
                        \${'CX_VERIFY_EMAIL_MESSAGE_1' | siteStrings:'params':[user.email]}
                    </p>
                    <div class="code-container">
                        <div class="qr-code-img enabled icon-only">
                            <cx-dynamic-icon icon.bind="twoFactorIcon" size.bind="32" alt.bind="twoFactorIcon" icon-class="copy-icon"></cx-dynamic-icon>
                        </div>
                        <div class="lower-message flex-column">
                            <p if.bind="width > tablet">
                                \${'CX_VERIFY_EMAIL_MESSAGE_2' | siteStrings:'params':[user.email]}
                                 <a class="" click.delegate="sendConfirmationEmail()">\${'CX_SEND_CONFIRMATION_EMAIL' | siteStrings}</a>
                            </p>
                            <p else>
                                <template if.bind="width <= phone">\${twoFactorTop | siteStrings}</template>
                                <template else>\${twoFactorMessage | siteStrings}</template>
                            </p>
                            <button
                                if.bind="width > phone && width <= tablet"
                                type="button"
                                mdc-button
                                class="submit-button button-blue-filled confirmation-button"
                                click.delegate="sendConfirmationEmail()"
                            >
                                <span class="text-center">\${'CX_RESEND_EMAIL' | siteStrings}</span>
                            </button>
                        </div>
                    </div>
                    <button
                        type="button"
                        mdc-button
                        class="submit-button button-blue-filled confirmation-button"
                        click.delegate="sendConfirmationEmail()"
                        if.bind="width <= phone"
                    >
                        <span class="text-center">\${'CX_RESEND_EMAIL' | siteStrings}</span>
                    </button>
                </div>

                <div else class="cx-notice-box">
                    <p class="main-message">
                        <template if.bind="width <= phone">\${twoFactorMessage | siteStrings}</template>
                        <template else>\${twoFactorTop | siteStrings}</template>
                    </p>
                    <div class="code-container">
                        <cx-circular-progress
                            show.bind="activating2FA"
                            size.bind="60"
                            show-loading-text.bind="false">
                        </cx-circular-progress>
                        <div show.bind="!activating2FA" class="qr-code-img  \${hasTwoFactorEnabled ? 'enabled' : 'cursor-pointer'}" click.delegate="copyQrCode()">
                            <cx-dynamic-icon icon.bind="twoFactorIcon" size="30" alt.bind="twoFactorIcon" icon-class="copy-icon"></cx-dynamic-icon>
                            <div class="qrImage" ref="qrPlaceholder"></div>
                        </div>
                        <div class="lower-message">
                            <p>
                                <template if.bind="width <= phone">\${twoFactorTop | siteStrings}</template>
                                <template else>\${twoFactorMessage | siteStrings}</template>
                            </p>
                            <cx-input-copy
                                text.bind="sharedKey"
                                if.bind="!hasTwoFactorEnabled && width > phone && width <= tablet"
                            >
                            </cx-input-copy>
                        </div>
                    </div>
                    <div class="lower-message share-message" if.bind="hasTwoFactorEnabled && width <= phone">
                        <p>\${'CX_2FA_DONT_SHARE' | siteStrings}</p>
                    </div>
                    <cx-input-copy
                        text.bind="sharedKey"
                        if.bind="!hasTwoFactorEnabled && width <= phone"
                    >
                    </cx-input-copy>
                </div>

                <div class="d-flex align-items-end form-container input-2fa-container" if.bind="user.emailConfirmed">
                    <form name="2fa" submit.delegate="execute2FA()" if.bind="(hasTwoFactorEnabled && disablingToken) || confirmingToken">
                        <label class="input-2fa-title heading font-medium">\${'CX_2FA_TOKEN' | siteStrings}</label>
                        <div class="position-relative d-flex align-items-center">
                            <mdc-text-field
                                ref="twoFactorTokenElement"
                                outlined
                                type="text"
                                value.bind="token & validate"
                                name.bind="'CX_TOKEN' | siteStrings"
                                required.bind="true"
                                placeholder.bind="'CX_ENTER_2FA_TOKEN' | siteStrings"
                                class="w-100 input-password cx-form-control \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkToken ? 'cx-form-control--success' : showErrorCheckMarkToken ? 'cx-form-control--error' : ''}"
                                keyup.delegate="tokenUpdatedOnKeyPress()"
                                focusin.delegate="tokenOnFocusIn()"
                                focusout.delegate="checkTokenValidation()"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                paste.delegate="helper.handlePasteNumberValidation(\$event, twoFactorTokenElement)"
                            ></mdc-text-field>
                            <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon position-absolute" alt="green check" if.bind="showGreenCheckMarkToken" loading="lazy">
                            <img src="${___HTML_LOADER_IMPORT_1___}" class="circle-icon position-absolute" alt="error" if.bind="showErrorCheckMarkToken" loading="lazy">
                            <mdc-circular-progress if.bind="showMiniSpinnerToken" class="mini-spinner-icon position-absolute" size="25" stroke-width="2"></mdc-circular-progress>
                        </div>
                    </form>
                    <p 
                        if.bind="hasTwoFactorEnabled"
                        click.delegate="toggleDisabling()"
                        class="two-factor-button cursor-pointer disable-select"
                    >
                        <span show.bind="disablingToken" class="material-icons">arrow_left</span> 
                        <span class="two-factor-button__text">\${(disablingToken ? 'CX_CANCEL' : 'CX_DISABLE_2FA') | siteStrings}</span>
                        <span show.bind="!disablingToken" class="material-icons">arrow_right</span> 
                    </p>
                </div>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;