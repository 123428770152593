import '../locations.scss';
import './vancouver.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { baseUrl } from 'environment';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class Vancouver {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private currencyService: CurrencyService,
        private helper: Helper
    ) { }

    currentYear;
    tradePageRoute;
    termsOfServiceRoute;
    privacyPolicyRoute;
    cookiesRoute;
    mapElement;
    activeCurrencies: Currency[];

    currencies = [
        { from: 'USD', to: 'BTC' },
        { from: 'BTC', to: 'USD' },
        { from: 'CAD', to: 'BTC' },
        { from: 'CAD', to: 'ETH' },
        { from: 'CAD', to: 'USD' },
        { from: 'BTC', to: 'CAD' },
        { from: 'EUR', to: 'BTC' },
        { from: 'CAD', to: 'USDT' }
    ];

    created() {
        this.currentYear = new Date().getFullYear();
    }

    async activate() {
        this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.tradePageRoute = pages.find(x => x.name === 'Trade')?.routeName ?? 'trade';
        this.termsOfServiceRoute = pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.privacyPolicyRoute = pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
        this.cookiesRoute = pages.find(x => x.name === 'Cookies')?.routeName ?? 'cookies';
        this.handleLocalBusiness();
    }

    scrollMap() {
        this.mapElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    openMaps() {
        window.open(
            'https://maps.app.goo.gl/MmD2RY59uQsuxaym8',
            '_blank');
    }

    handleLocalBusiness() {
        const localBusiness = `{
                "@type": "FinancialService",
                "@id": "${baseUrl()}#/schema/FinancialService/ChicksX",
                "image": "${baseUrl()}static/logo/chicksx-logo-purple.svg",
                "name": "ChicksX Inc.",
                "address": {
                    "@id": "${baseUrl()}#/schema/PostalAddress/ChicksX"
                },
                "geo": {
                    "@id": "${baseUrl()}#/schema/GeoCoordinates/ChicksX"
                },
                "url": "${baseUrl()}locations/vancouver",
                "telephone": "(1) 416-244-2579",
                "openingHoursSpecification": [
                    {
                        "@id": "${baseUrl()}#/schema/OpeningHoursSpecification/ChicksX"
                    }
                ]
            },
            {
                "@type": "GeoCoordinates",
                "@id": "${baseUrl()}#/schema/GeoCoordinates/ChicksX",
                "latitude": 49.32887665386039,
                "longitude": -123.16156192886106
            },
            {
                "@type": "OpeningHoursSpecification",
                "@id": "${baseUrl()}#/schema/OpeningHoursSpecification/ChicksX",
                "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday"
                ],
                "opens": "10:00",
                "closes": "18:00"
            }`;
        this.helper.combineApplicationLdJsonSchemasIntoOne(localBusiness);
    }

    goToTrade(data) {
        const give = this.activeCurrencies.find(c => c.code === data?.from);
        const receive = this.activeCurrencies.find(c => c.code === data?.to);
        let intent;
        if (give || receive)
            intent = this.helper.getTradeIntent(give, receive);
        else
            intent = data;
        this.helper.redirectWithOptionsSelected(this.router, this.tradePageRoute, data, intent);
    }
}
