import { EventAggregator } from 'aurelia-event-aggregator';
import './cx-currency-text-input-selector.scss';
import { ToastService } from 'services/toast-service';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { Currency } from 'services/models/purchase-flow/exchange';
import { SessionService } from 'services/session-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { debounce } from '@chicksgroup/helpers';

@autoinject()
export class CxCurrencyTextInputSelector {
    constructor(
        private toastService: ToastService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    @bindable currencyList: Currency[];
    @bindable currencySelected: Currency;
    @bindable exchangeCurrencySelected: Currency;
    @bindable amount: string;
    @bindable intent: string;
    @bindable minAmount: number;
    @bindable maxAmount: number;
    @bindable paymentMethodSelectedName: string;
    @bindable activeCurrencies: Currency [];
    @bindable state;
    @bindable mostPopularCurrencies = [];
    @bindable otherCurrencies = [];

    baseAwsEndpoint: string;
    parent;
    arrowTriggered: HTMLElement;
    lastEventTriggered: string;
    arrowRotated: boolean;
    id = 'currency-input-default';
    toTextTimeout;
    updateContraryAmountTimeout;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.removeNotActiveCurrencies();
        this.overrideMdc();
        const randomKey = Date.now().toString(36) + Math.random().toString(36).substring(2);
        this.id = 'currency-input-' + randomKey;
        this.currencySelected.symbol = this.currencySelected.symbol.replace(/-TRC20|-ERC20/g, '');
    }

    overrideMdc() {
        const selectArrow = document.getElementsByClassName('mdc-select__dropdown-icon');
        const newArrowIcon = '<a class="expandable-arrow material-icons p-0">expand_more</a>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }

    handleClick() {
        clearTimeout(this.toTextTimeout);
        if (this.lastEventTriggered === 'selectFocusOut') {
            this.openCurrencyTextInput();
        }
    }

    handleFocusOut() {
        this.lastEventTriggered = 'selectFocusOut';
        this.toTextTimeout = setTimeout(() => {
            this.state = 'toText';
        }, 200);
    }

    async updateAmountInSelect() {
        await this.parent.updateAmountInSelect(this.intent, this.currencySelected);
    }

    async selectCurrency(currency: Currency) {
        if (!currency) {
            return;
        }

        if (currency.code === this.exchangeCurrencySelected.code) {
            this.toastService.showToast('Error', 'Exchange between the same currency is not allowed.', 'error');
            currency = this.currencyList.find(c => c.code === this.exchangeCurrencySelected.code);
        }

        if (currency.type === 'F' && this.exchangeCurrencySelected.type === 'F') {
            this.eventAggregator.publish('force-payment-method', { paymentMethodReference: 'cash-in-person' });
        }

        if (this.exchangeCurrencySelected.type === 'F' && currency.type === 'C' && this.intent === 'give') {
            const cryptoPaymentMethod = await this.paymentMethodWebsiteService.getByReference(currency.reference);
            this.eventAggregator.publish('force-payment-method', { paymentMethodReference: cryptoPaymentMethod.paymentMethod.reference });
        }

        if (this.currencySelected !== currency) {
            this.currencySelected = currency;
        }
        this.currencySelected.symbol = this.currencySelected.symbol.replace(/-TRC20|-ERC20/g, '');
        this.updateAmountInSelect();
    }

    async updateContraryAmount() {
        this.parent.isCryptoPayValid = false;
        debounce(async () => {
            await this.validateAmount();
            await this.callParentUpdateContraryAmount();
        }, 1000)();
    }

    private async callParentUpdateContraryAmount() {
        await this.parent.updateContraryAmount(this.intent);
        this.parent.validateCryptoPayButton();
    }

    async validateAmount() {
        const amount = parseFloat(this.amount);
        const toFixedAmount = this.currencySelected.type === 'F' || this.currencySelected.isStable ? 2 : 6;
        this.amount = amount.toFixed(toFixedAmount);
        if (this.minAmount && amount < this.minAmount) {
            this.toastService.showToast('Minimum amount not met', `The minimum amount for ${this.paymentMethodSelectedName} is ${this.minAmount.toFixed(toFixedAmount)} `, 'error');
            this.amount = this.minAmount.toFixed(toFixedAmount);
        }
        if (this.maxAmount && amount > this.maxAmount) {
            this.toastService.showToast('Maximum amount not met', `The maximum amount for ${this.paymentMethodSelectedName} is ${this.maxAmount.toFixed(toFixedAmount)} `, 'error');
            this.amount = this.maxAmount.toFixed(toFixedAmount);
        }
    }

    removeNotActiveCurrencies() {
        if (!this.activeCurrencies) return;
        this.currencyList = this.currencyList.filter(c => this.activeCurrencies.find(ac => ac.code === c.code));
        this.mostPopularCurrencies = this.mostPopularCurrencies.filter(c => this.activeCurrencies.find(ac => ac.code === c.code));
    }

    currencyTextOnFocusIn() {
        this.lastEventTriggered = 'inputFocusIn';
        clearTimeout(this.toTextTimeout);
    }

    openCurrencyTextInput() {
        this.state = null;
        setTimeout(() => {
            const input = document.getElementById(this.id);
            input.focus();
        }, 100);
    }

    currencyTextOnFocusOutToText() {
        this.toTextTimeout = setTimeout(() => {
            this.state = 'toText';
        }, 200);
    }

    @computedFrom('id')
    get currencyInputId() {
        return this.id;
    }
}
