import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';

import './cx-select.scss';

@autoinject()
export class CxSelect {
    @bindable id: string;
    @bindable options: { value: string, label: string }[];
    @bindable selectedOption: { value: string, label: string };
    @bindable selectedOptionValue: string;
    @bindable label: string;
    @bindable icon: string;
    @bindable isSearchable: boolean;
    @bindable optionSelected: (option) => string;

    selectedOptionValueChanged() {
        if (!this.selectedOptionValue)
            return;
        this.selectedOption = this.options.find(o => o.value === this.selectedOptionValue);
    }

    selectOption(option) {
        this.optionSelected(option.value);
    }
}
