// Module
var code = `<template element.ref="countryCodeSelector">
    <div id="cx-phone-input">
        <div class="d-flex justify-content-center spin-loader" show.bind="loading">
            <mdc-circular-progress size="75" stroke-width="5"></mdc-circular-progress>
        </div>
        <div show.bind="!loading">
            <div class="row" if.bind="verificationStage === stages.ENTERING_PHONE || (verificationStage === stages.VERIFIED)">
                <label if.bind="containLabel" class="text-medium font-medium \${useLabelMargin ? 'mt-4' : ''} \${labelStyle}">
                    \${labelText ? labelText : 'Phone'}
                </label>
                <div class="col-12 \${flowPage ? 'my-2' : 'mt-2'}">
                    <div class="row">
                        <div ref="inputContainer" class="position-relative phone-number-container">
                            <input
                                id="phone-number-input"
                                type="text"
                                element.ref="phoneElement"
                                class="my-2 mdc-text-field mdc-text-field--no-label mdc-text-field--outlined mdc-ripple-enhanced mdc-ripple-upgraded \${inputStyle}"
                                class.bind="getElementStateClass(phoneInputState, 'phone')"
                                keyup.delegate="startPhoneVerification(\$event)"
                                focusin.delegate="startPhoneVerification()"
                                focusout.delegate="startPhoneVerification()"
                                disabled.bind="user.phoneNumberConfirmed && user.phoneNumber"
                                value.bind="phoneNumber"
                                keydown.delegate="validateInput(\$event)"
                            >
                            <mdc-circular-progress
                                if.bind="showMiniSpinnerPhone"
                                class="mini-spinner-icon"
                                size="25"
                                stroke-width="2"
                            ></mdc-circular-progress>
                            <span
                                class="circle-icon check text-large material-icons-outlined"
                                alt="success"
                                if.bind="phoneInputState === 'success'"
                            >
                                check_circle
                            </span>
                            <span
                                class="position-absolute error circle-icon text-large material-icons"
                                alt="error"
                                if.bind="phoneInputState === 'error'"
                            >
                                error_outline
                            </span>
                            <span
                                class="white-x-mark text-navy-blue material-icons text-large cursor-pointer"
                                alt="clear icon"
                                mousedown.delegate="clearPhoneInput()"
                                if.bind="whiteXMark && phoneNumber.length > 0"
                            >
                                close
                            </span>
                        </div>
                        <span class="text-small mt-2 copy-text-bellow font-light" if.bind="!flowPage && firstMessage">\${ copyTextBellow }</span>
                        <div class="text-small copy-text-bellow d-flex flex-column font-light" if.bind="flowPage">
                            <span>\${'CX_PHONE_INFO_MESSAGE' | siteStrings}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div if.bind="verificationStage === stages.ENTERING_CODE" class="row">
            <div class="col-12 position-relative my-2  code-verification-container">
                <mdc-text-field
                    outlined
                    autocomplete="off"
                    value.two-way="authCode"
                    id="code"
                    class="w-100 input-password cx-form-control"
                    class.bind="getElementStateClass(authCodeState, 'authCode')"
                    keyup.delegate="authCodeUpdatedOnKeyPress(\$event)"
                    focusout.delegate="authCodeFocusOut()"
                    focusin.delegate="authCodeFocusIn()"
                    required.bind="true"
                    placeholder="Enter your verification code"
                    keydown.delegate="inputNumericValueChecker.toView(\$event)"
                    ref="authCodeInput"
                ></mdc-text-field>
                <span
                    class="circle-icon check text-large material-icons-outlined"
                    alt="success"
                    if.bind="authCodeState === 'success' && !user.phoneNumberInReview"
                >
                    check_circle
                </span>
                <span
                    class="circle-icon error material-icons text-large"
                    alt="error"
                    if.bind="authCodeState === 'error' && !user.phoneNumberInReview"
                >
                    error_outline
                </span>
                <span
                    class="white-x-mark text-navy-blue material-icons text-large cursor-pointer"
                    alt="clear icon"
                    mousedown.delegate="clearAuthCode()"
                    if.bind="whiteAuthCodeXMark && authCode.length > 0"
                >
                    close
                </span>
                <mdc-circular-progress
                    if.bind="showMiniSpinnerAuthCode"
                    class="mini-spinner-icon"
                    size="25"
                    stroke-width="2"
                ></mdc-circular-progress>
            </div>
            <div class="row">                
                <span class="text-darkergray text-tiny mt-2 mb-1 copy-text-bellow font-light" if.bind="!flowPage && firstMessage">\${ copyTextBellow }</span>
                <div class="text-darkergray text-small copy-text-bellow d-flex flex-column font-light" if.bind="flowPage">
                    <span>\${'CX_PHONE_INFO_MESSAGE' | siteStrings}</span>
                </div>
                <div class="col-12 text-purple resend-code \${ flowPage ? 'mt-2' : '' }">
                    <span class="link-styling cursor-pointer \${ flowPage ? 'font-regular' : '' }" click.delegate="codeNotSent()">\${'CX_RESEND_VERIFICATION_CODE' | siteStrings}</span>
                    <span if.bind="showMessage || (!user.phoneNumberConfirmed && user.phoneNumberInReview)" class="text-medium px-1">|</span>
                    <span class="link-styling cursor-pointer \${ flowPage ? 'font-regular' : '' }" click.delegate="callInstead()">\${'CX_CALL_INSTEAD' | siteStrings}</span>
                    <span class="text-medium px-1">|</span>
                    <span class="link-styling cursor-pointer \${ flowPage ? 'font-regular' : '' }" click.delegate="setPhoneNumberNotInReview()">\${'CX_CANCEL' | siteStrings}</span>
                </div>
            </div>
        </div>
        <input id="firebase-button" type="submit" hidden>
    </div>
</template>
`;
// Exports
export default code;