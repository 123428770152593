// Module
var code = `<template>
    <div id="security-reset-password">
        <cx-veriff
            view-model.ref="veriffViewModel"
            hidden-button="true"
            user.bind="user"
            class="d-block mb-3 mb-sm-0">
        </cx-veriff>
        <cx-card class.bind="'top-up-view-container'">
            <cx-back-button page-loading.bind="pageLoading" route.bind="'security'"></cx-back-button>
            <div class="title-container">
                <h1 class="page-title" skeleton-loading="is-loading.bind:pageLoading;">\${titleKey|siteStrings:title}</h1>
                <p class="page-description" skeleton-loading="is-loading.bind:pageLoading;">
                    <span>\${descriptionKey|siteStrings:description}</span>
                </p>
            </div>
            <div class="form-container">
                <form class="password-update-form">
                    <cx-password-input 
                        show.bind="!isWithdraw || hasWithdrawPassword"
                        view-model.ref="currentInput"
                        name.bind="'CX_CURRENT_PASSWORD' | siteStrings"
                        password.two-way="currentPassword"
                        show-green-check-mark.two-way="showGreenCheckMarkCurrentPassword"
                        show-error-check-mark.two-way="showErrorCheckMarkCurrentPassword"
                        class="current-password-container"
                        verify-current.bind="isWithdraw ? verifyWithdrawPassword.bind(\$this) : null"
                        forgot-delegate.bind="forgotPasswordHandler.bind(\$this)"
                        is-current.bind="true"
                        page-loading.bind="pageLoading"
                        >
                    </cx-password-input>
                    <cx-password-input
                        show.bind="!confirming"
                        view-model.ref="newInput"
                        name.bind="'CX_NEW_PASSWORD' | siteStrings"
                        password.two-way="newPassword"
                        show-green-check-mark.two-way="showGreenCheckMarkNewPassword"
                        show-error-check-mark.two-way="showErrorCheckMarkNewPassword" 
                        class="new-password-container"
                        on-green-check-mark.call="setConfirming(true)"
                        show-validator.bind="true"
                        page-loading.bind="pageLoading"
                        >
                    </cx-password-input>
                    <cx-password-input 
                        show.bind="confirming" 
                        view-model.ref="confirmInput"
                        name.bind="'CX_CONFIRM_NEW_PASSWORD' | siteStrings"
                        password.two-way="confirmNewPassword"
                        show-green-check-mark.two-way="showGreenCheckMarkConfirmNewPassword"
                        show-error-check-mark.two-way="showErrorCheckMarkConfirmNewPassword"
                        class="confirm-password-container" 
                        back-button.bind="setConfirming" 
                        show-validator.bind="true"
                        page-loading.bind="pageLoading"
                        >
                    </cx-password-input>
                </form>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;