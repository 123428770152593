// Module
var code = `<template>
    <div id="cx-referral-row" class="d-none d-sm-block">
        <div class="row inner d-flex align-items-center">
            <div class="col-1 col-sm-1 p-0">
                <span class="material-icons-outlined currency-icon">currency_exchange</span>
            </div>
            <div class="col-3 text-small p-0">
                <div class="truncated-span-wrapper">
                    <span class="truncated-span">
                        <span class="text-regular-static font-regular p-0 mb-1" mdc-tooltip.bind="referral.fullName">\${referral.fullName}</span>
                    </span>
                    <span class="truncated-span">
                        <span class="text-small-static font-light" mdc-tooltip.bind="referral.email">\${referral.email}</span>
                    </span>
                </div>
            </div>
            <div class="col-3 text-regular-static font-regular p-0">\$\${referral.totalSpendByUser}</div>
            <div class="col-3 text-regular-static font-regular text-green p-0">+\$\${referral.referralEarnings}</div>
            <div class="col-2 d-md-block text-regular-static font-regular p-0">\${referral.activity | dateFormatter:'format':'MM/DD/YYYY'}</div>
        </div>
    </div>
    <div id="cx-referral-row-mobile" class="d-block d-sm-none">
        <div class="mobile-inner">
            <div class="row-mobile">
                <div class="d-flex mb-3">
                    <span class="material-icons-outlined currency-icon">currency_exchange</span>
                    <div class="d-flex flex-column">
                        <span class="text-small text-mediumgray font-regular text-uppercase">Referral</span>
                        <div class="truncated-span-wrapper">
                            <span class="truncated-span" mdc-tooltip.bind="referral.fullName + referral.email">
                                <span class="text-small-static font-light me-1">\${referral.fullName}</span>
                                <span class="text-small-static font-light">\${referral.email}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <span class="text-uppercase text-white-50 font-regular text-extra-small small-spacer">Earnings</span>
                        <span class="text-regular text-green font-light">+\$\${referral.referralEarnings}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="text-uppercase text-white-50 font-regular text-extra-small small-spacer">Activity</span>
                        <span class="text-regular font-light">\${referral.activity | dateFormat:'format:'MM/DD/YYYY'}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="text-uppercase text-white-50 font-regular text-extra-small small-spacer">Total spent</span>
                        <span class="text-regular font-light">\$\${referral.totalSpendByUser}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;