// Module
var code = `<template>
    <div id="cx-currency-pill">
        <div class="currency-pill \${large ? 'large' : ''}">
            <span>
                \${currencySymbol}
            </span>
        </div>
    </div>
</template>
`;
// Exports
export default code;