import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-wallet-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { validate } from 'crypto-address-validator-ts';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { Currency } from 'services/models/purchase-flow/exchange';
import { ToastService } from 'services/toast-service';
import { TooltipArrowStyling } from 'resources/value-converters/tooltip-arrow-styling';

@autoinject()
export class CxWalletInput {
    constructor (
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService,
        private pageContentAreaService: PageContentAreaService,
        private tooltipArrowStyling: TooltipArrowStyling) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable cryptoCurrencySelected: Currency;
    @bindable valid: boolean;
    @bindable walletAddress: string;
    @bindable extraField: boolean;
    requiresExtraInput: boolean;
    walletInputExpandable;
    expandableOpen = false;
    isValidatingExtraField: boolean = false;
    extraInputLabel: 'Tag' | 'Memo' | 'Token';
    extraFieldInput;

    //Validation variables
    walletValid: boolean;
    extraFieldValid: boolean;
    showMiniSpinnerWallet: boolean;
    showMiniSpinnerExtraInput: boolean;
    showGreenCheckMarkAddress: boolean;
    showErrorCheckMarkAddress: boolean;

    firedFunction: boolean;
    timeouts: NodeJS.Timeout[];
    addressUpdatedStopWatch: NodeJS.Timeout;
    addressSpinnerStopWatch: NodeJS.Timeout;
    addressFocusInStopWatch: NodeJS.Timeout;
    addressFocusOutStopWatch: NodeJS.Timeout;

    walletAddressTooltip;

    attached() {
        this.setBooleansForExtraInput();
        if (this.walletAddress) {
            this.validateWalletAddress();
            this.validateExtraInputAddress();
        }
    }

    cryptoCurrencySelectedChanged() {
        this.setBooleansForExtraInput();
    }

    setBooleansForExtraInput() {
        switch (this.cryptoCurrencySelected.code) {
            case 'XRP':
            case 'SCRT':
            case 'XMR':
                this.extraInputLabel = 'Tag';
                this.requiresExtraInput = true;
                break;
            case 'XLM':
            case 'SIX':
            case 'BAND':
            case 'DON':
            case 'LUNA':
            case 'ATOM':
            case 'IOST':
            case 'STX':
                this.extraInputLabel = 'Memo';
                this.requiresExtraInput = true;
                break;
            case 'BNB':
                this.extraInputLabel = 'Token';
                this.requiresExtraInput = true;
                break;
            default:
                this.requiresExtraInput = false;
        }
    }

    async walletUpdatedOnKeyPress(ev: KeyboardEvent) {
        this.firedFunction = false;
        this.showMiniSpinnerWallet = this.showMiniSpinnerExtraInput = this.showGreenCheckMarkAddress = this.showErrorCheckMarkAddress = false;
        this.timeouts = [this.addressUpdatedStopWatch, this.addressSpinnerStopWatch, this.addressFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev?.key === 'Enter') {
            this.validateWalletAddress();
            return;
        } else {
            if (this.walletAddress) {
                this.addressSpinnerStopWatch = setTimeout(() => {
                    this.showMiniSpinnerWallet = true;
                }, 1000);
                this.addressUpdatedStopWatch = setTimeout(async() => {
                    this.showMiniSpinnerWallet = false;
                    this.validateWalletAddress();
                    this.firedFunction = true;
                }, 4000);
            } else {
                this.walletValid = false;
                this.validateWalletAddress();
                this.firedFunction = true;
            }
        }
        return true;
    }

    walletOnFocusIn() {
        this.showMiniSpinnerWallet = this.showMiniSpinnerExtraInput = this.showGreenCheckMarkAddress = this.showErrorCheckMarkAddress = false;
        this.firedFunction = false;
        this.timeouts = [this.addressFocusOutStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.addressFocusInStopWatch = setTimeout(() => {
            if (this.walletAddress) {
                this.walletUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
            }
        }, 2000);
    }

    walletOnFocusOut() {
        this.showMiniSpinnerWallet = this.showMiniSpinnerExtraInput = this.showGreenCheckMarkAddress = this.showErrorCheckMarkAddress = false;
        this.firedFunction = false;
        this.timeouts = [this.addressUpdatedStopWatch, this.addressSpinnerStopWatch, this.addressFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.addressFocusOutStopWatch = setTimeout(() => {
            if (this.walletAddress) {
                this.walletUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
            }
        }, 250);
    }

    focusElement(el: HTMLElement) {
        return el?.focus && el?.focus();
    }

    async validateWalletAddress() {
        this.walletValid = validate(this.walletAddress, this.cryptoCurrencySelected.code, null);
        this.valid = this.validateAll();

        if (this.walletValid) {
            this.showGreenCheckMarkAddress = true;
            this.extraFieldInput && !this.extraFieldValid && (this.focusElement(this.extraFieldInput));
        } else {
            this.showErrorCheckMarkAddress = true;
            await this.toastService.showToast('Error', 'Invalid wallet address.', 'error');
        }
    }

    extraFieldChanged() {
        this.isValidatingExtraField = true;
    }

    validateExtraInputAddress() {
        if (this.extraField) {
            this.isValidatingExtraField = false;
            this.extraFieldValid = true;
        } else {
            this.isValidatingExtraField = false;
            this.extraFieldValid = false;
        }
        this.valid = this.validateAll();
    }

    validateAll() {
        if (this.requiresExtraInput) {
            return this.walletValid && this.extraFieldValid;
        }
        return this.walletValid;
    }

    resetValidation() {
        this.walletValid = null;
        this.extraFieldValid = null;
        this.showMiniSpinnerWallet = null;
        this.showMiniSpinnerExtraInput = null;
        this.showGreenCheckMarkAddress = false;
        this.showErrorCheckMarkAddress = false;
        this.walletAddress = null;
        this.extraField = null;
        this.valid = false;
    }

    onToggle() {
        this.walletInputExpandable.toggle();
    }
}
