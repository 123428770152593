// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/tracking/accepted.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/tracking/in_progress.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/tracking/shipped.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/tracking/in_transit.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/icons/tracking/delivered.svg", import.meta.url);
// Module
var code = `<template>
    <div class="d-flex flex-row flex-sm-column align-items-center \${isTooltip ? 'background-color-controller-tooltip' : 'background-color-controller'}" id="cx-tracking-progress">
        <div class="cx-tracking-proggress-horizontal \${isTooltip ? 'size-controller-tooltip' : 'size-controller'} d-none d-sm-flex">
            <mdc-linear-progress class="\${isTooltip ? 'cx-linear-progress-tooltip' : 'cx-linear-progress'}" open.bind="true" progress.bind="progressCurrent" buffer="1">
            </mdc-linear-progress>
            <div class="position-absolute w-100 steps-container">
                <ul class="d-flex flex-row justify-content-between">
                    <li repeat.for="step of numSteps"
                        class="\${isTooltip ? 'step-tooltip' : 'step'} rounded-pill d-flex align-items-center justify-content-center 
                        \${step + 1 > currentStep ? '' : step + 1 == currentStep ? 'step-active material-icons' : 'step-success material-icons'}">
                        <span class="step-number \${isTooltip ? 'text-small' : 'text-large'}"></span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-100 cx-tracking-proggress-vertical d-flex d-sm-none position-absolute">
            <mdc-linear-progress class="\${isTooltip ? 'cx-linear-progress-tooltip' : 'cx-linear-progress'}" open.bind="true" progress.bind="progressCurrent" buffer="1">
            </mdc-linear-progress>
            <div class="position-absolute">
                <ul class="d-flex flex-column justify-content-between h-100">
                    <li repeat.for="step of numSteps"
                        class="\${isTooltip ? 'step-tooltip' : 'step'} rounded-pill d-flex align-items-center justify-content-center 
                        \${step + 1 > currentStep ? '' : step + 1 == currentStep ? 'step-active material-icons' : 'step-success material-icons'}">
                        <span class="step-number \${isTooltip ? 'text-small' : 'text-large'}"></span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="\${isTooltip ? 'status-container-tooltip' : 'status-container'} d-flex justify-content-between flex-column flex-sm-row w-100">
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="accepted icon" loading="lazy">
                <span class="\${ isVertical && currentStep >= 1 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">Created</span>
            </div>
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="accepted icon" loading="lazy">
                <span class="\${ isVertical && currentStep >= 2 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">Accepted</span>
            </div>
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_1___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="in progress icon" loading="lazy">
                <span class="\${ isVertical && currentStep >= 3 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">In progress</span>
            </div>
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_2___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="shipped icon" loading="lazy">
                <span class="\${ isVertical && currentStep >= 4 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">Shipped</span>
            </div>
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_3___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="in transit icon" loading="lazy">
                <span class="\${ isVertical && currentStep >= 5 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">In transit</span>
            </div>
            <div class="status d-flex flex-row-reverse flex-sm-row justify-content-between align-items-center">
                <img src="${___HTML_LOADER_IMPORT_4___}" class="\${isTooltip ? 'icon-inverted' : ''}" alt="delivered icon" loading="lazy">
                <span class="\${ isVertical && currentStep == 6 ? 'font-bold' : 'font-medium' } \${isTooltip ? 'text-white font-size-controller' : ''}">Delivered</span>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;