import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import './cx-dropdown-content.scss';

@autoinject()
export class CxDropdownContent {

    @bindable() title: string;
    @bindable() nameref: string;
    @bindable() content: string;
    @bindable() openDropdown = false;
    @bindable() isClicked = false;
    @bindable() contentHovered = false;
    @bindable() tabActive = false;

    isHover = false;
    navbar;

    contentButton: HTMLElement;

    constructor(
        private readonly eventAgregator: EventAggregator,
        private router: Router
    ) {}

    attached() {
        const navbar = document.getElementById('navigation-bar');
        const navbarIsPurple = !navbar.classList.value.includes('navbar-transparent');
        this.contentButton.classList.add(navbarIsPurple ? 'text-white' : 'text-purple');
        if (navbarIsPurple) {
            this.contentButton.classList.add('inverted');
            this.contentButton.classList.add('border-bottom-white');
        }
    }

    @computedFrom('navbar', 'contentHovered')
    get invertedClass() {
        if (!this.navbar) return;
        const navbar = this.navbar.navbar;
        const navbarIsPurple = !navbar.classList.value.includes('navbar-transparent');
        this.contentButton.classList.add(navbarIsPurple ? 'text-white' : 'text-purple');
        return navbarIsPurple ? 'border-bottom-white inverted' : '';
    }

    bind(bindingContext, overrideContext) {
        this.navbar = overrideContext.parentOverrideContext.bindingContext;
    }

    mouseEventsHandler(event) {
        const someOneContentClicked:boolean = this.navbar.someOneContentClicked();
        if (someOneContentClicked && this.content !== this.navbar.dynamicNavContent && event.type !== 'click') return;
        if (someOneContentClicked && this.content === this.navbar.dynamicNavContent && event.type !== 'click') return;

        switch (event.type) {
            case 'mouseenter':
                this.isClicked &&= this.openDropdown;
                this.openDropdown ||= !this.isClicked;
                this.isHover = true;
                break;
            case 'mouseleave':
                this.isHover = this.openDropdown &&= this.isClicked;
                this.isHover = false;
                break;
            case 'click':
                this.isHover = false;
                this.isClicked = !this.isClicked;
                this.openDropdown = this.isClicked;

                if (!someOneContentClicked) break;

                // eslint-disable-next-line no-case-declarations
                const dropdownChangedIndex = this.navbar.dropdowns.findIndex(drop => drop.contentName === this.navbar.dynamicNavContent);
                this.navbar.dropdowns[dropdownChangedIndex].isOpen =
                this.navbar.dropdowns[dropdownChangedIndex].isContentHovered =
                this.navbar.dropdowns[dropdownChangedIndex].isClicked = false;

                break;
            default:
                break;
        }

        this.eventAgregator.publish('nav-content-selected', {
            content: this.content,
            selected: this.openDropdown,
            dropdown: this.nameref,
            moveTo: event.toElement?.id,
            event: event.type
        });
    }
}
