import './cx-file-input.scss';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { ImageService } from 'services/image-service';
import { DocumentTypeService } from 'services/document-type-service';
import { Helper } from 'resources/helpers/helper';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class CxFileInput {
    constructor(
        private imageService: ImageService,
        private documentTypeService:DocumentTypeService,
        private taskQueue: TaskQueue,
        private helper: Helper,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) { }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable id;
    @bindable text;
    @bindable state;
    @bindable class;
    @bindable disabled = false;
    @bindable uploadFunctionName;
    @bindable verificationCategoryId = null;
    @bindable selectable;
    @bindable selectPlaceholder = 'Choose a Document';
    @bindable selectPlaceholderKey = 'CX_CHOOSE_DOCUMENT';
    @bindable uploading;
    @bindable idUploads;
    @bindable selfieUploads;
    @bindable fileExtensions = ['pdf', 'heic'];
    @bindable fileTypes = ['image', 'video'];
    documentTypeId = null;
    documentTypes;
    selectedDocumentType;
    parent;
    inputElement;
    fileSelector;
    idCode;
    labelEl;
    inputAccept = '';
    STATES = {
        ERROR: 'error',
        WARNING: 'warning',
        SUCCESS: 'success',
        VERIFIED: 'verified',
        REMOVE: 'remove',
        DONE: 'done'
    };

    created() {
        this.idCode = Date.now().toString(36) + Math.random().toString(36).substring(2);
        this.id = `menu-${this.idCode}`;
    }

    async attached() {
        if (this.selectable) {
            if (this.verificationCategoryId) {
                this.documentTypes = await this.documentTypeService.getByCategoryId(this.verificationCategoryId);
            } else {
                this.documentTypes = await this.documentTypeService.list();
            }
        }
        this.overrideMdc();

        for (const type of this.fileTypes) {
            this.inputAccept += `${type}/*, `;
        }

        for (const extension of this.fileExtensions) {
            this.inputAccept += `.${extension}, `;
        }
        this.createInputFile();
        this.setSelectPlaceholder();
    }

    detached() {
        this.inputElement?.remove();
        document.getElementById(`style-${this.idCode}`)?.remove();
    }

    overrideMdc() {
        const selectArrow = this.fileSelector.querySelector('.cx-file-input--select.mdc-select .mdc-select__dropdown-icon');
        const newArrowIcon = '<span class="arrow-icon-select-input-file material-icons text-cx-purple">expand_more</span>';
        if (selectArrow) {
            selectArrow.innerHTML = newArrowIcon;
        }
    }

    createInputFile() {
        this.inputElement = document.createElement('input');
        this.inputElement.id = this.id;
        this.inputElement.type = 'file';
        this.inputElement.className = 'd-none';
        this.inputElement.oninput = (ev) => this.fileChanged(ev);
        this.inputElement.disabled = this.disabled;
        this.inputElement.accept = this.inputAccept;
        document.body.append(this.inputElement);
    }

    setSelectPlaceholder() {
        const selectPlaceholderEl = document.createElement('style');
        selectPlaceholderEl.id = `style-${this.idCode}`;
        const placeholderToShow = this.siteStringsValueConverter.toView(this.selectPlaceholderKey, this.selectPlaceholder);
        selectPlaceholderEl.innerHTML = `#cx-file-input.file-selector-${this.idCode} .placeholder-text::before {content: '${placeholderToShow}';}`;
        document.head.append(selectPlaceholderEl);
        this.labelEl = this.fileSelector.querySelector('.mdc-select__selected-text');
        this.labelEl.classList.add('placeholder-text');
    }

    getElementStateClass(state) {
        if (state === this.STATES.ERROR) {
            return 'cx-form-control--error';
        }
        if (state === this.STATES.WARNING) {
            return 'cx-form-control--warning';
        }
        if (state === this.STATES.SUCCESS || state === this.STATES.VERIFIED) {
            return 'cx-form-control--success';
        }
        if (state === this.STATES.REMOVE || state === this.STATES.DONE) {
            return '';
        }
        return '';
    }

    async fileChanged(e) {
        let file;
        if (e?.target?.files) {
            file = e.target.files;
        }
        if (file) {
            const result = this.imageService.buildFormData(file);
            if (result) {
                this.text = 'Upload in progress';
                const returnObj = {
                    formData: result,
                    verificationCategoryId: this.selectedDocumentType?.userDocumentVerificationCategoryId || this.verificationCategoryId,
                    documentTypeId: this.selectedDocumentType?.id || null,
                    documentName: this.selectedDocumentType?.name
                };
                await this.parent[this.uploadFunctionName](returnObj);
                setTimeout(() => {
                    this.state = 'done';
                    this.selectedDocumentType = undefined;
                    this.taskQueue.queueMicroTask(() => {
                        this.text = 'Choose a Document';
                        this.labelEl.classList.add('placeholder-text');
                    });
                }, 1000);
            }
        }
        this.inputElement.value = '';
    }

    handleUploadClick() {
        if (!this.disabled) {
            const input = document.getElementById(this.id);
            if (input) {
                input.click();
            }
        }
    }

    changeSelectHandler() {
        this.labelEl.classList.remove('placeholder-text');
    }

    blobToFile(theBlob, fileName): File {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }
}
