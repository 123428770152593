export class FullnameValueConverter {
    toView(value, lastName = '', capitalizedText = true): string {
        if (!value && !lastName) {
            return '-';
        }

        if (!lastName) {
            lastName = '';
        }

        const fullNameStr = `${ value } ${ lastName }`;
        const fullNameSplit = fullNameStr.split(' ');
        return capitalizedText ? fullNameSplit.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : fullNameStr;
    }
}
