import { BaseEntity } from '../baseEntity';
import { Country, Currency } from '../purchase-flow/exchange';
import { Website } from '../website/website';
import { Game } from './game';

export class ProductCategory extends BaseEntity {
    name: string;
    products;
    productSubCategories;
    games: GameProductCategory[];
    productCategoryGameFields: ProductCategoryGameField[];
    couponCodes;
    websites: ProductCategoryWebsite[];
}

export class GameProductCategory extends BaseEntity {
    gameId: number;
    game: Game;
    productCategoryId: number;
    productCategory: ProductCategory;
    title: string;
    metaDescription: string;
    slugs: Slug[];
    isTrending: boolean;
    trendingPosition: number;
    headerTitle: string;
    websiteId?: number;
    website: Website;
}

export class Slug extends BaseEntity {
    routeName: string;
    gameProductCategoryId: number;
    gameProductCategory: GameProductCategory;
    defaultSlug: boolean;
}

export class ProductSubCategory extends BaseEntity {
    name: string;
    iconPath: string;
    gameId?: number;
    game: Game;
}

export class ProductCategoryGameField {
    gameId: string;
    game: Game;
    productCategoryId: string;
    productCategory: ProductCategory;
    fieldId: string;
    field;
}

export class ProductCategoryWebsite {
    productCategoryId: number;
    productCategory: ProductCategory;
    websiteId: number;
    website: Website;
}

export class ProductCatWithGame {
    id: number;
    name: string;
    games: GameProductCategory[];
    gameForNav: GameForNav[];
}

export class GameForNav {
    id: string;
    name: string;
    shortName: string;
    isActive: boolean;
    gameNavigationIcon: string;
    schemaImagePath: string;
    productCategoryName: string;
    dropdownText: string;
    sellIcon: string;
    sellAltText: string;
    sellAccountRedirectLink: string;
    title: string;
    metaDescription: string;
    position?: number;
    parentGameId: number;
    slug: string[];
    slugName: string;
    currencyProductSymbol: string;
    isTrending: boolean;
    trendingPosition: number;
    currencyPercentage?: number;
    dynamicService: boolean;
    headerTitle: string;
    currencyId?: number;
    currency: Currency;
    countryId?: number;
    country: Country;
    giveExchangeCurrencyId?: number;
    giveExchangeCurrency: Currency;
    receiveExchangeCurrencyId?: number;
    receiveExchangeCurrency: Currency;
}
