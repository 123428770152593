// Module
var code = `<template>
    <section id="faq">
        <div class="faq-container">
            <div class="container-header">
                <h1 class="container-heading-text text-left font-bold mb-0">
                    <cx-page-content-area
                        key.bind="'CX_FAQS_TITLE'"
                    ></cx-page-content-area>
                </h1>
                <div class="container-search">
                    <div class="search-mobile">
                        <cds-drawer drawertoggle.trigger="handleDrawerToggle(\$event)" class="drawer">
                            <div if.bind="showDrawerContent" ref="drawerContentSimplebar" data-simplebar slot="drawer-content" class="purple-scrollbar drawer-content">
                                    <div class="d-flex section-column-mobile" slot="content">
                                        <cds-collapse instant.bind="section.instant" open-transition-delay="0" id="cds-collapse-\${section.id}" class="cds-collapse" collapsetoggle.trigger="handleLineClamp(\$event, section.id)"
                                            repeat.for="section of filteredSections" is-open.bind="section.openByDefault">
                                    <div slot="collapse-header-icon" class="container-section-icon">
                                        <div class="container-category-card-image">
                                            <img hex-to-filter="hex-code.bind: '#310FA2'" class="category-card-image"
                                                slot="collapse-header-icon" src="\${getIconSrc(section.iconPath)}" alt="faq category image">
                                        </div>
                                    </div>
                                    <h2 ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header" class="section-header line-clamp">
                                        \${section.title}
                                    </h2>
                                    <div class="question-card-border-container">
                                        <div class="question-card">
                                            <div repeat.for="question of section.questions">
                                                <div class="\${!\$last && 'question-card-border'}">
                                                    <cds-question-collapse opened-icon="remove" closed-icon="add"
                                                        question="\${question.question}"
                                                        is-open.bind="question.openByDefault">
                                                        <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                                    </cds-question-collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </cds-collapse>
                                </div>
                            </div>
                            <div class="drawer-search" slot="drawer-header" >
                                <cds-search leading-icon.bind="currentLeadingIcon" value.two-way="search" class="mb-10"
                                    input.trigger="handleSearchChange(\$event)"
                                    placeholder="Search"></cds-search>
                            </div>
                        </cds-drawer>
                    </div>
                    
                </div>
                <div class="search-bar">
                    <cds-search leading-icon.bind="currentLeadingIcon" value.bind="search" class="mb-10"
                        input.trigger="handleSearchChange(\$event)"
                        placeholder="Search"></cds-search>
                </div>
            </div>
            <hr class="divider-mobile" />
            <div if.bind="!showDrawerContent" class="container-question-section">
                <div class="d-flex section-column">
                    <cds-collapse id="cds-collapse-\${section.id}" class="cds-collapse"
                        collapsetoggle.trigger="handleLineClamp(\$event, section.id)"
                        repeat.for="section of getFirstColumnSliced(filteredSections)"
                        is-open.bind="section.openByDefault">
                        <div slot="collapse-header-icon" class="container-section-icon">
                            <div class="container-category-card-image">
                                <img hex-to-filter="hex-code.bind: '#310FA2'" class="category-card-image"
                                    slot="collapse-header-icon" src="\${getIconSrc(section.iconPath)}" alt="faq category image">
                            </div>
                        </div>
                        <h2 ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header"
                            class="section-header line-clamp">
                            \${section.title}
                        </h2>
                        <div class="question-card-border-container">
                            <div class="question-card">
                                <div repeat.for="question of section.questions">
                                    <div class="\${!\$last && 'question-card-border'}">
                                        <cds-question-collapse opened-icon="remove" closed-icon="add"
                                            question="\${question.question}" 
                                            is-open.bind="question.openByDefault">
                                            <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                        </cds-question-collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </cds-collapse>
                </div>
                <div class="d-flex section-column">
                    <cds-collapse id="cds-collapse-\${section.id}" class="cds-collapse"
                        collapsetoggle.trigger="handleLineClamp(\$event, section.id)"
                        repeat.for="section of getSecondColumnSliced(filteredSections)"
                        is-open.bind="section.openByDefault">
                        <div slot="collapse-header-icon" class="container-section-icon">
                            <div class="container-category-card-image">
                                <img hex-to-filter="hex-code.bind: '#310FA2'" class="category-card-image"
                                    slot="collapse-header-icon" src="\${getIconSrc(section.iconPath)}" alt="faq category image">
                            </div>
                        </div>
                        <h2 ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header"
                            class="section-header line-clamp">
                            \${section.title}
                        </h2>
                        <div class="question-card-border-container">
                            <div class="question-card">
                                <div repeat.for="question of section.questions">
                                    <div class="\${!\$last && 'question-card-border'}">
                                        <cds-question-collapse opened-icon="remove" closed-icon="add"
                                            question="\${question.question}"
                                            is-open.bind="question.openByDefault">
                                            <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                        </cds-question-collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </cds-collapse>
                </div>
            </div>
        </div>
        <cx-faq-dialog view-model.ref="faqDialog"></cx-faq-dialog>
    </section>
</template>
`;
// Exports
export default code;