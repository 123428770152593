export const OrderChatStatus = {
    Open: 1,
    Closed: 2,
    Snoozed: 3
};

export const CurrencyTypes = {
    Crypto: 'C',
    Fiat: 'F'
};
