import './cx-lookup.scss';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { SimplebarOverride } from 'resources/simplebar_override';
import { Helper } from 'resources/helpers/helper';
import { getAWSBucketEndpoint } from 'environment';

@autoinject
export class CxLookup {
    constructor(
        private taskQueue: TaskQueue,
        private eventAggregator: EventAggregator,
        private helper: Helper,
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    @bindable class;
    @bindable label;
    @bindable icon;
    @bindable placeholder = '';
    @bindable selectedObject;
    @bindable disabled;
    @bindable dropdownOptions;
    @bindable hideArrow;
    @bindable pascalSpacing = false;
    @bindable purpleScrollbar;
    @bindable alwaysShowLabel;
    @bindable handleChange = () => [];
    @bindable scrollbarDynamicHeight;
    @bindable alignVertically = false;
    @bindable lookupName;
    @bindable filter;
    @bindable type;
    dynamicValue = 1;
    search = {
        value: '',
        focus: false
    };

    parent;
    height;
    triggerHideArrow;
    sizeChanged;
    savedSelectedObject;
    mdcLookupRef;
    limitedOptions;
    limitedFilterOptions;
    searchExists;
    options;
    lookupTextField;
    lookupElement;
    lookupSelector;
    propertyWithValue;
    baseAwsEndpoint: string;
    isOpen: boolean = false;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    attached() {
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.handleLimitedOptionsByValue(true);
        if (this.hideArrow) this.triggerHideArrow = true;
        this.handleEventSubscriptions();
        this.taskQueue.queueMicroTask(() => {
            setTimeout(() => {
                this.addSimpleBarToDropdown();
            }, 500);
        });
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    scrollbarDynamicHeightChanged() {
        this.scrollbarDynamicHeight = Boolean(this.scrollbarDynamicHeight);
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.height = payload.height;
            this.addSimpleBarToDropdown();
        });
    }

    selectedObjectChanged() {
        if (this.selectedObject) this.savedSelectedObject = this.selectedObject;
    }

    dropdownOptionsChanged() {
        if (!this.dropdownOptions?.length) return;
        this.handleLimitedOptionsByValue();
        this.mdcLookupRef.optionsArray = this.limitedOptions;
    }

    getOptions = async(filter, value) => {
        this.searchExists = false;
        this.options = this.limitedOptions.map(obj => ({ ...obj }));
        if (value) {
            let findIndexSelected;
            if (this.lookupName === 'currency') {
                findIndexSelected = this.dropdownOptions.findIndex(x => x.symbol === value.symbol || x.code === value.code);
            } else {
                findIndexSelected = this.dropdownOptions.findIndex(x => this.handleSequenceSearch(x, value));
            }
            this.options.splice(findIndexSelected, 1);
            this.options.unshift(value);
        } else if (filter) {
            this.dynamicValue = 1;
            this.searchExists = true;
            let filteredLimitedOptions;
            if (this.lookupName === 'currency') {
                filteredLimitedOptions = this.dropdownOptions.filter(x => x.symbol?.toLowerCase()?.includes(filter.toLowerCase()) || x.code?.toLowerCase()?.includes(filter.toLowerCase()));
            } else {
                filteredLimitedOptions = this.dropdownOptions.filter(x =>
                    x.displayName?.toLowerCase()?.includes(filter.toLowerCase())
                ?? x.display?.toLowerCase()?.includes(filter.toLowerCase())
                ?? x.name?.toLowerCase()?.includes(filter.toLowerCase())
                ?? x.value.toLowerCase().includes(filter.toLowerCase()));
            }
            this.limitedFilterOptions = filteredLimitedOptions;
            this.search.value = filter;

            return new Promise(resolve => resolve(filteredLimitedOptions.slice(0, (this.dynamicValue * 100))));
        }
        this.limitedFilterOptions = this.options;
        this.search.value = '';
        return Promise.resolve(this.options);
    };

    getDisplayField = (element) => {
        return element.code
            ?? element.displayName
            ?? element.display
            ?? element.name
            ?? element.code;
    };

    addSimpleBarToDropdown() {
        const element = this.lookupSelector?.querySelector('.lookup-container > mdc-menu > mdc-list > div');
        if (!element) return;
        if (this.purpleScrollbar) element.classList.add('purple-scrollbar');
        if (this.scrollbarDynamicHeight) {
            element.style.maxHeight = `${this.height - 343}px`;
            element.style.height = `${this.options.length * 48}px`;
        }
        const instance = SimplebarOverride.instances.get(element);
        if (instance) instance.unMount();
        const newInstance = new SimplebarOverride(element);
        const offset = newInstance.getScrollElement();
        setTimeout(() => {
            const maxHeightStyling = this.lookupElement?.querySelector('mdc-menu')?.style?.maxHeight;
            if (!maxHeightStyling || this.scrollbarDynamicHeight) return;
            element.style.maxHeight = parseInt(maxHeightStyling.slice(0, -2)) < 350 ? `${maxHeightStyling}` : null;
        }, 150);
        offset.addEventListener('scroll', (e: Event) => {
            if (this.dynamicValue <= (Math.ceil((e.target as HTMLElement).scrollTop) / 4650)) {
                this.dynamicValue = Math.ceil((e.target as HTMLElement).scrollTop) / 4650 + 1;
                this.handleLimitedOptionsByValue();
                this.mdcLookupRef.optionsArray = this.limitedFilterOptions;
            }
        });
    }

    handleTextFieldFocusIn(arrowClicked) {
        this.isOpen = false;
        if (arrowClicked) {
            this.lookupTextField.focus();
            this.lookupTextField.classList.add('mdc-text-field--focused');
            this.mdcLookupRef.menuSurface.open = true;
        }
        setTimeout(() => {
            const mdcMenu = this.lookupElement?.querySelector('mdc-menu');
            this.handleToOpenUpwardsLookup(mdcMenu, parseInt(mdcMenu?.style?.maxHeight?.slice(0, -2)) < 200 ? 'add' : 'remove');
        }, 75);
        setTimeout(() => {
            this.isOpen = true;
        }, 100);
        this.addSimpleBarToDropdown();
        if (this.hideArrow) this.hideArrow = false;
        if (this.parent.handleNavBarHeightByCurrencyLookup) this.parent.handleNavBarHeightByCurrencyLookup();
        if (this.selectedObject) {
            const propertiesToCheck = ['display', 'displayName', 'value', 'name'];
            this.propertyWithValue = propertiesToCheck.find(prop => this.selectedObject[prop] !== undefined && this.selectedObject[prop] !== null && this.selectedObject[prop] !== '');
            if (this.selectedObject && this.helper.compareBy(this.lookupTextField.value, this.selectedObject[this.propertyWithValue], (value) => value)) {
                this.clearSearch();
            }
        }

        this.search.focus = true;
    }

    handleTextFieldFocusOut(event, arrowClicked) {
        if (this.triggerHideArrow) this.hideArrow = true;
        if (!event?.relatedTarget || arrowClicked) {
            const lookupSelectorElement = this.lookupTextField;
            if (lookupSelectorElement) {
                lookupSelectorElement.blur();
                lookupSelectorElement.classList.remove('mdc-text-field--focused');
            }
        }
        if (!this.selectedObject) this.selectedObject = this.savedSelectedObject;
        if (this.selectedObject && !this.lookupTextField.value) this.lookupTextField.value = this.selectedObject[this.propertyWithValue];
        this.handleChange();
        if (this.parent.handleNavBarHeightByCurrencyLookup && event?.relatedTarget?.tagName?.toLowerCase() !== 'mdc-list-item') this.parent.handleNavBarHeightByCurrencyLookup(false);
        this.eventAggregator.publish('field-updated', { updated: true });
        if (!event?.relatedTarget || arrowClicked) this.search.focus = false;
    }

    async handleSelectionChanged() {
        this.lookupTextField.querySelector('.mdc-floating-label--float-above')?.classList?.remove('mdc-floating-label--float-above');
        this.handleTextFieldFocusOut(null, true);
        this.handleChange();
        this.eventAggregator.publish('field-updated', { updated: true, filter: this.filter, type: this.type });
    }

    handleLimitedOptionsByValue = (forceFirstOneHundred = false) => this.limitedOptions = this.dropdownOptions.sort((a, b) => { return b?.position - a?.position || a.position - b.position; }).slice(0, forceFirstOneHundred ? 100 : (this.dynamicValue * 100));

    handleSequenceSearch = (obj, value) =>
        obj.displayName
            ? obj.displayName === value.displayName
            : obj.display
                ? obj.display === value.display
                : obj.name
                    ? obj.name === value.name
                    : obj.value
                        ? obj.value === value.value
                        : obj === value;

    clearSearch(restoreList: boolean = false) {
        this.lookupTextField.value = this.search.value = '';
        if (restoreList) {
            this.lookupTextField.dispatchEvent(new Event('input'));
        }
    }

    handleArrowDropdown = () => {
        if (this.search.focus) {
            this.handleTextFieldFocusOut(null, true);
            return;
        }
        this.handleTextFieldFocusIn(true);
    };

    handleToOpenUpwardsLookup = (mdcMenu, type = 'add') => mdcMenu?.classList[type]('force-upwards');

    handleCloseOnInputClick = () => {
        if (this.isOpen && !(window.getSelection().toString().length > 0)) {
            this.isOpen = false;
            this.handleTextFieldFocusOut(null, true);
        }
    };
}
