import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { CountryFilterRequest } from './models/country/countryFilterRequest';
import { CountryDto } from './models/country/countryDto';

@autoinject()
export class CountryService {
    path = 'Country';
    currencyRates = [];

    constructor(
        private api: ApiService,
    ) { }

    async getMultiple(request?: CountryFilterRequest): Promise<CountryDto[]> {
        return await this.api.doPost(`${this.path}/Multiple`, request);
    }

    async getWithStores(request?: CountryFilterRequest): Promise<CountryDto[]> {
        return await this.api.doPost(`${this.path}/WithStores`, request);
    }
}
