// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/arrow-right.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-nav-preorder-mobile">
        <div id="selected-content">
            <mdc-deprecated-list if.bind="selectedOption.id !== 'initial'">
                <mdc-deprecated-list-item class="drawer-item drawer-back-item text-purple" click.delegate="goBack()">
                    <div class="d-flex gap-2 align-items-center">
                        <img
                            class="material-icons reverse-arrow"
                            src="${___HTML_LOADER_IMPORT_0___}"
                            alt="go back"
                            loading="lazy"
                        />
                        <span if.bind="isLanguageOrCurrency(selectedOption.id)">\${selectedOption.title}</span>
                        <span else>\${(selectedOption.title) | siteStrings} \${!isAnInitialOption(selectedOption.id) && !isAnExchangeOption(selectedOption.id) ? 'selected' : '' }</span>
                    </div>
                </mdc-deprecated-list-item>
            </mdc-deprecated-list>

            <div class="selected-option" if.bind="selectedOption">
                <mdc-deprecated-list>
                    <mdc-deprecated-list-item 
                        repeat.for="option of selectedOption.childs"
                        class="\${!['initial', 'exchange'].includes(selectedOption.id) ? \`drawer-item-displayed drawer-list-item \${isAnSeparatorOption(option.id) ? 'separator' : ''}\` : 'drawer-item drawer-list-item'} \${selectedOption.id === 'exchange' ? 'drawer-item-displayed' : ''} \${option.childs.length === 0 && !isAnSeparatorOption(option.id)? 'no-border' : ''} \${option.id === 'all' && listHaveChilds() ? 'border' : ''}"
                        click.delegate="selectOption(option)"
                    >
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="icon-name-option">
                                <img
                                    if.bind="option.image"
                                    class="material-icons icon rounded rounded-5"
                                    src="\${option.image}"
                                    alt="\${option.title}"
                                    loading="lazy"
                                />
                                <span if.bind="isLanguageOrCurrency(option.id)" class="fw-medium'">\${option.title}</span>
                                <span else class="fw-medium'">\${option.title | siteStrings:option.auxname}</span>
                            </div>
                            <img
                                if.bind="option.childs.length > 0"
                                class="material-icons arrow"
                                src="${___HTML_LOADER_IMPORT_0___}"
                                alt="next options"
                                loading="lazy"
                            />
                        </div>
                    </mdc-deprecated-list-item>
                </mdc-deprecated-list>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;