import './cx-breadcrumbs.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class CxBreadcrumbs {
    @bindable breadcrumbs = [];
    @bindable class;
    @bindable excludeForcingActive = false;
    @bindable forceParentFunction = false;
    breadcrumbsWithStatus = [];
    homePageRoute;
    parent;
    breadcrumbUpdateSubscription;

    constructor(
        private websiteService: WebsiteService,
        private router: Router,
        private eventAggregator: EventAggregator
    ) { }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.handleBreadcrumbsWithStatus();
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        setTimeout(() => this.handleBreadCrumbStyling(), 1000);
        this.handleEventSubscriptions();
    }

    detached() {
        this.breadcrumbUpdateSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.breadcrumbUpdateSubscription = this.eventAggregator.subscribe('breadcrumb-update', payload => {
            this.breadcrumbs = payload.breadcrumbs;
            this.handleBreadcrumbsWithStatus();
        });
    }

    handleBreadCrumbStyling() {
        const anchorElements = Array.from(document.getElementsByClassName('crumb'));
        anchorElements.forEach((anchorElement) => {
            const anchorWidth = (anchorElement as HTMLElement).offsetWidth;
            if (anchorWidth > 0) {
                const wrapperWidth = anchorWidth + (anchorWidth * 0.01);
                const parentNode = anchorElement.parentNode as HTMLElement;
                parentNode.style.width = `${wrapperWidth}px`;
            }
        });
    }

    changeRouteByParentFunction() {
        this.parent.categorySelected?.(null);
    }

    handleBreadcrumbsWithStatus = () => this.breadcrumbsWithStatus = this.excludeForcingActive ? [...this.breadcrumbs] : [...this.breadcrumbs].map(breadcrumb => ({ ...breadcrumb, isActive: this.router.currentInstruction.config.name === breadcrumb.name }));
}
