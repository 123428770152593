import './cx-faq-question-section.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { SimplebarOverride } from 'resources/simplebar_override';
import { FadingFunctionality } from 'resources/extensions/fading_functionality';
import { isStaticAsset as isStaticAssetRegExp } from 'resources/constants';

export class CxFaqQuestionSection extends FadingFunctionality {
    private questionAnswerRef;
    private questionContentRef;
    private questionHeaderRef;
    @bindable isOpen:boolean;
    @bindable defaultIsOpen:boolean;
    @bindable question:string;
    @bindable questionId;
    @bindable answer:string;
    @bindable questions:string[];
    @bindable iconName: string;
    @bindable children:boolean;
    @bindable child:boolean;
    baseAwsUrl: string;
    answerTextLength: number;
    isStaticAsset = true;

    constructor() {
        super();
        this.baseAwsUrl = getAWSBucketEndpoint('icons');
    }

    attached() {
        this.isStaticAsset = isStaticAssetRegExp().test(this.iconName);
        this.answerTextLength = this.questionAnswerRef.textContent.trim().length;
        this.generateScrollEvents();

        this.defaultIsOpen ? this.openDefault() : this.closeDefault();
    }

    closeDefault() {
        this.addLineClamp(this.questionHeaderRef);
        this.decreaseHeight(this.questionContentRef);
        this.isOpen = this.defaultIsOpen;
    }

    getScrollWrapperEl() {
        return SimplebarOverride.instances.get(this.questionContentRef).contentWrapperEl;
    }

    openDefault() {
        this.removeLineClamp(this.questionHeaderRef);
        this.expandHeight(this.questionContentRef);
        const scrollbarWrapper = this.getScrollWrapperEl();
        scrollbarWrapper.scrollTop = 1;
        this.isOpen = this.defaultIsOpen;
        setTimeout(() => scrollbarWrapper.scrollTop = 0, 150);
    }

    expandHeight(ref) {
        ref.classList.remove('question-content-height-hide');
        ref.classList.add('question-content-height');
    }

    decreaseHeight(ref) {
        ref.classList.remove('question-content-height');
        ref.classList.add('question-content-height-hide');
    }

    addLineClamp(ref) {
        ref.classList.remove('line-clamp-hide');
        ref.classList.add('line-clamp');
    }

    removeLineClamp(ref) {
        ref.classList.remove('line-clamp');
        ref.classList.add('line-clamp-hide');
    }

    close() {
        setTimeout(() => this.addLineClamp(this.questionHeaderRef), 150);
        this.decreaseHeight(this.questionContentRef);
    }

    open() {
        const scrollbarWrapper = this.getScrollWrapperEl();
        scrollbarWrapper.scrollTop = 1;
        setTimeout(() => scrollbarWrapper.scrollTop = 0, 250);
        this.removeLineClamp(this.questionHeaderRef);
        this.expandHeight(this.questionContentRef);
    }

    onCollapseClick() {
        this.isOpen = !this.isOpen;

        if (!this.isOpen) return this.close();
        this.open();
    }

    scrollBottomReached() {
        this.questionContentRef.classList.toggle('touching-bottom', false);
    }

    generateScrollEvents() {
        if (!this.questionContentRef) return;
        this.questionContentRef.classList.toggle('touching-bottom', false);
        this.questionContentRef.classList.toggle('touching-top', true);
        this.setScrollContainer(this.questionContentRef);
        this.reinstanceScrollbar();
        this.createScrollEvent();
    }
}
