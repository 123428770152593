// Module
var code = `<template>
    <div id="cx-faq-question-\${question.id}" ref='faqQuestionElement' class="cx-faq-question">
        <div class="header-expandable">
            <h5 class="question font-regular text-black text-medium pe-4 text-truncate text-truncate-width" ref='questionElement'>\${question.question}</h5>
            <span class="material-icons text-purple" element.ref="icon">arrow_drop_down</span>
        </div>
        <div element.ref="answer" class="answer answer-close">
            <p class="font-regular text-regular">\${question.answer}</p>
            <div if.bind="question.isVerificationQuestion" class="text-purple" click.delegate="functionShowTable()">
                <span class="link-styling">Show Table</span> >>
            </div>
        </div>
    </div>
</template>`;
// Exports
export default code;