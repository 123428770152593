import './cx-payoutable-form.scss';
import { bindable } from 'aurelia-framework';
import { PaymentMethodWebsite, TransactionAddressType } from 'services/models/purchase-flow/exchange';
import { ICxTextInput } from 'resources/elements/cx-text-input/cx-text-input';
import { IReceivingPaymentMethodRequest } from 'services/models/user/user';
import { autoinject } from 'aurelia-dependency-injection';
import {
    ControllerValidateResult,
    Rule,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules
} from 'aurelia-validation';
import { ReceivingPaymentMethodService } from 'services/receiving-payment-method-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { MethodPayoutableChangedEvent, SpecialSelectorEvent } from 'resources/constants';
import { BillingAddress } from 'services/models/user/billingAddress';
import { ISpecialSelectorEvent } from 'types/events';
import { Helper } from 'resources/helpers/helper';
import { GlobalDropdown } from 'resources/helpers/global-dropdown';
import { Country, ICountry } from 'country-state-city';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

class InputOptions {
    name: string;
    label: string;
    type: 'number' | 'text' | 'password' | 'address' | 'date' | 'time';
    value?: string;
    inlineInputs?: ICxTextInput[] = [];
    inline?: boolean = false;
    hasDropdown?: boolean = false;
    dropdownOptions?: string[] = [];
    id?: string;
    parent?: string;
    inputFunction?: (value: string, code?: string) => void;
}

@autoinject()
export class CxPayoutableForm {
    @bindable show = true;
    @bindable paymentMethod: PaymentMethodWebsite;

    formData: IReceivingPaymentMethodRequest;

    formInputs: ICxTextInput[] = [];
    secondaryFormInputs: ICxTextInput[] = [];

    validationController: ValidationController;
    rules: Rule<CxPayoutableForm, string>[][];
    manualAddressFormRules: Rule<CxPayoutableForm, string>[][];

    address?: string;
    code?: string;
    additionalInformation?: string;
    billingInfo: BillingAddress = {};

    loading: boolean = false;
    validationTimeout: NodeJS.Timeout | null = null;
    secondaryForm = false;
    addressInputFocused = false;

    globalDropdown?: GlobalDropdown;

    //properties for manual address form
    manualAddress: string;
    manualCountry: string;
    manualCity: string;
    manualState: string;
    manualZip: string;
    self = this;

    countries: ICountry[];

    constructor(
        private receivingPaymentMethodService: ReceivingPaymentMethodService,
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        validationControllerFactory: ValidationControllerFactory
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.rules = ValidationRules
            .ensure<CxPayoutableForm, string>('address')
            .email()
            .when(x => this.shouldEnsureEmail(x.paymentMethod))
            .minLength(5)
            .when(x => x.paymentMethod.transactionAddressType === TransactionAddressType.AccountAddress)
            .minLength(25)
            .when(x => x.paymentMethod.transactionAddressType === TransactionAddressType.WalletAddress)
            .satisfies(() => this.validateBillingAddress())
            .when(x => [TransactionAddressType.MailAddress, TransactionAddressType.InPersonAddress].includes(x.paymentMethod.transactionAddressType))
            .required()
            .ensure('code')
            .required()
            .when(x => this.shouldEnsureCode(x.paymentMethod))
            .ensure('additionalInformation')
            .required()
            .when(x => x.paymentMethod.transactionAddressType === TransactionAddressType.InPersonAddress)
            .on(this).rules;

        this.manualAddressFormRules = ValidationRules
            .ensure<CxPayoutableForm, string>('manualAddress').required()
            .ensure('manualCountry').required().satisfies((value: string) => Boolean(this.getCountry(value)))
            .ensure('manualCity').required()
            .ensure('manualState').required()
            .ensure('manualZip').required().satisfies((zip: string, ref: CxPayoutableForm) => this.validatePostalCode(zip, ref.manualCountry))
            .on(this).rules;
    }

    attached() {
        this.validationController.addObject(this, this.rules);
        const payoutableInfo = this.paymentMethod.paymentMethod.payoutableInfo;

        if (payoutableInfo) {
            this.formData = {
                paymentMethodId: this.paymentMethod.paymentMethodId,
                address: payoutableInfo?.address,
                code: payoutableInfo?.code,
                additionalInformation: payoutableInfo?.additionalInformation,
                transactionAddressType: payoutableInfo?.type ?? this.paymentMethod.transactionAddressType ?? TransactionAddressType.EmailAddress
            };
        } else {
            this.formData = {
                paymentMethodId: this.paymentMethod.paymentMethodId,
                address: null,
                code: null,
                transactionAddressType: this.paymentMethod.transactionAddressType ?? TransactionAddressType.EmailAddress
            };
        }

        this.address = this.formData.address;
        this.code = this.formData.code;
        this.additionalInformation = this.formData.additionalInformation;
        this.secondaryForm = false;
        this.manualCountry = this.manualState = this.manualAddress = this.manualCity = this.manualZip = '';

        this.buildForm();
        this.buildSecondaryForm();
    }

    detached() {
        this.formInputs = [];
    }

    buildForm(oldForm?: ICxTextInput[]) {
        const form: ICxTextInput[] = [];
        const mainCode = this.getMainInputCode();
        const secondaryCode = mainCode === 'address' ? 'code' : 'address';
        const mainInput = this.buildInput({ name: mainCode, label: this.getMainInputLabel(), type: this.getMainInputType(), value: this.formData[mainCode], id: mainCode } as InputOptions);
        const secondaryInputs: ICxTextInput[] = [];

        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.WalletAddress: {
                mainInput.inlineInputs.push(this.buildInput({ name: secondaryCode, label: 'Memo', type: 'text', value: this.formData[secondaryCode], inline: true } as InputOptions));
                break;
            }
            case TransactionAddressType.AccountAddress: {
                secondaryInputs.push(this.buildInput({ name: 'code', label: 'Swift code / Routing number', type: 'text', value: this.formData.code } as InputOptions));
                break;
            }
            case TransactionAddressType.InPersonAddress: {
                mainInput.inlineInputs.push(this.buildInput({
                    name: 'additionalInformation',
                    label: 'Time',
                    type: 'time',
                    value: this.formData.additionalInformation,
                    inline: true,
                    hasDropdown: true,
                    parent: 'code',
                    dropdownOptions: this.helper.getArrayOfHours(0, 24),
                } as InputOptions));
                secondaryInputs.push(this.buildInput({ name: 'address', label: 'Address', type: 'address', value: this.formData.address } as InputOptions));
                break;
            }
        }

        form.push(mainInput, ...secondaryInputs);

        if (oldForm) {
            this.copyFormStatus(form, oldForm, 'address');
            this.copyFormStatus(form, oldForm, 'code');
            this.copyFormStatus(form, oldForm, 'additionalInformation');
        }

        this.formInputs = form;
    }

    buildSecondaryForm() {
        const form: ICxTextInput[] = [];
        if (![TransactionAddressType.MailAddress, TransactionAddressType.InPersonAddress].includes(this.paymentMethod.transactionAddressType)) return;

        const mainInput = this.buildInput({ name: 'manualAddress', label: 'Address', type: 'text', inputFunction: this.handleSecondaryTextInput } as InputOptions);
        mainInput.inlineInputs.push(this.buildInput({ name: 'manualCountry', label: 'Country', type: 'text', inputFunction: this.handleSecondaryTextInput } as InputOptions));
        const additionalInput = this.buildInput({ name: 'manualCity', label: 'City', type: 'text', inputFunction: this.handleSecondaryTextInput } as InputOptions);
        additionalInput.inlineInputs.push(this.buildInput({ name: 'manualState', label: 'State', type: 'text', inline: true, inputFunction: this.handleSecondaryTextInput } as InputOptions));
        additionalInput.inlineInputs.push(this.buildInput({ name: 'manualZip', label: 'Zip code', type: 'text', inline: true, inputFunction: this.handleSecondaryTextInput } as InputOptions));

        form.push(mainInput, additionalInput);
        this.secondaryFormInputs = form;
    }

    copyFormStatus(newForm: ICxTextInput[], oldForm: ICxTextInput[], name: string) {
        const newInput = this.findField(newForm, name);
        const oldInput = this.findField(oldForm, name);
        if (!newInput || !oldInput) return;
        newInput.showSuccess = oldInput.showSuccess;
        newInput.showError = oldInput.showError;
    }

    getMainInputLabel() {
        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.AccountAddress: return 'Account number';
            case TransactionAddressType.WalletAddress: return 'Wallet address';
            case TransactionAddressType.MailAddress: return 'Address';
            case TransactionAddressType.InPersonAddress: return 'Date';
            default: return `${this.paymentMethod.paymentMethod.name.split(' ')[0]} email address`;
        }
    }

    getMainInputCode() {
        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.InPersonAddress: return 'code';
            default: return 'address';
        }
    }

    getMainInputType() {
        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.MailAddress: return 'address';
            case TransactionAddressType.InPersonAddress: return 'date';
            default: return 'text';
        }
    }

    getCountry(name: string) {
        if (!this.countries) this.countries = Country.getAllCountries();
        return this.countries.find(x => x.name.toLowerCase().includes(name.toLowerCase().trim()))?.isoCode;
    }

    validateBillingAddress() {
        if (!this.billingInfo) return false;
        if (!this.billingInfo.countryName) return false;

        if (!this.billingInfo.zip) {
            this.toastService.showToast('Error', 'Billing address does not contain ZIP/Postal code. Please check your information again.', 'error');
            return false;
        }

        return this.validatePostalCode(this.billingInfo.zip, this.billingInfo.countryName);
    }

    validatePostalCode(code: string, country: string) {
        const countryCode = this.getCountry(country);
        if (!countryCode) return false;
        const validatorFound = postcodeValidatorExistsForCountry(countryCode);
        if (!validatorFound) return true;
        const codeValid = postcodeValidator(code, countryCode);
        if (!codeValid) this.toastService.showToast('Error', 'Billing address does not match ZIP/Postal code. Please check your information again.', 'error');
        return codeValid;
    }

    shouldEnsureCode(paymentMethod: PaymentMethodWebsite) {
        return [TransactionAddressType.AccountAddress, TransactionAddressType.WalletAddress, TransactionAddressType.InPersonAddress].includes(paymentMethod.transactionAddressType);
    }

    shouldEnsureEmail(paymentMethod: PaymentMethodWebsite) {
        return paymentMethod.transactionAddressType === null || paymentMethod.transactionAddressType === TransactionAddressType.EmailAddress;
    }

    private async save() {
        this.loading = true;
        const info = await this.receivingPaymentMethodService.add(this.formData);

        if (!info) {
            this.loading = false;
            return;
        }

        this.paymentMethod.paymentMethod.payoutableInfo = {
            address: info.address,
            code: info.code,
            type: info.transactionAddressType,
            additionalInformation: info.additionalInformation
        };

        this.loading = false;
        await this.toastService.showToast('Success', 'Payment method saved.', 'success');
        this.eventAggregator.publish(MethodPayoutableChangedEvent, { paymentMethod: this.paymentMethod.paymentMethod });
    }

    handleSecondaryTextInput = async(value: string, code: string) => {
        this[code] = value;
        await this.validateInputs(false, 'secondaryFormInputs');
    };

    isBillingAddress(obj): obj is BillingAddress {
        if (!obj) return false;
        const keys = Object.keys(obj);
        return keys.includes('street') && keys.includes('countryCode') && keys.includes('zip');
    }

    handleTextInput = async(value: string, code?: string, extraInfo?: unknown) => {
        if (/^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/.test(value)) value = this.helper.convertTo24Hour(value);

        const old = this[code];
        if (old === value) return;

        if (this.isBillingAddress(extraInfo)) this.billingInfo = extraInfo;
        this[code] = value;
        await this.validateInputs();

        const field = this.findField(this.formInputs, code);
        if (field?.inline && ['date', 'time'].includes(field?.type)) this.buildForm(this.formInputs);
    };

    sendSelectedEvent(focused: boolean) {
        this.eventAggregator.publish(SpecialSelectorEvent, { focused } as ISpecialSelectorEvent);
    }

    handleAddressInputFocusIn = () => {
        this.sendSelectedEvent(true);
        this.addressInputFocused = true;
    };

    handleAddressInputFocusOut = () => {
        this.sendSelectedEvent(false);
        setTimeout(() => this.addressInputFocused = false, 100);
    };

    toggleSecondaryForm = async(value: boolean) => {
        if (value) {
            this.validationController.removeObject(this);
            this.validationController.addObject(this, this.manualAddressFormRules);
        } else {
            const addressValid = await this.validationController.validate();
            this.validationController.removeObject(this);
            this.validationController.addObject(this, this.rules);
            this.updateAddress(addressValid.valid);
        }

        this.secondaryForm = value;
    };

    formTitleClicked = () => {
        if (!this.secondaryForm) return;
        this.toggleSecondaryForm(false);
    };

    private updateAddress(addressValid: boolean) {
        if (!addressValid) return;
        const newAddress = `${this.manualZip} ${this.manualAddress} ${this.manualCity}, ${this.manualState}, ${this.manualCountry}`;
        this.handleTextInput(newAddress, 'address');
    }

    private async validateInputs(triggerSave = true, inputsKey = 'formInputs') {
        if (this.validationTimeout) {
            clearTimeout(this.validationTimeout);
            this.validationTimeout = null;
        }

        const validation = await this.validationController.validate();
        this.setInputValidationState(validation, inputsKey);
        if (!validation.valid || !triggerSave) return;
        this.validationTimeout = setTimeout(async() => await this.save(), 2000);
    }

    private invalidateField(inputs: ICxTextInput[], name: string, invalid: boolean) {
        for (const input of inputs) {
            if (input.name === name) {
                input.showError = invalid;
                input.showSuccess = !invalid;
                break;
            }

            if (input.inlineInputs.length === 0) continue;
            this.invalidateField(input.inlineInputs, name, invalid);
        }
    }

    private findField(inputs: ICxTextInput[], name: string): ICxTextInput | null {
        for (const input of inputs) {
            if (input.name === name) return input;
            if (input.inlineInputs.length === 0) continue;
            const foundField = this.findField(input.inlineInputs, name);
            if (foundField) return foundField;
        }

        return null;
    }

    private setInputValidationState(validation: ControllerValidateResult, inputsKey: string) {
        if (inputsKey === 'formInputs') {
            let addressInvalid = Boolean(validation.results.find(x => x.propertyName === 'address' && !x.valid));
            let codeInvalid = Boolean(validation.results.find(x => x.propertyName === 'code' && !x.valid));
            let additionalInvalid = Boolean(validation.results.find(x => x.propertyName === 'additionalInformation' && !x.valid));

            switch (this.paymentMethod.transactionAddressType) {
                case TransactionAddressType.AccountAddress:
                case TransactionAddressType.WalletAddress: {
                    addressInvalid = codeInvalid = addressInvalid || codeInvalid;
                    this.invalidateField(this.formInputs, 'address', addressInvalid);
                    this.invalidateField(this.formInputs, 'code', codeInvalid);
                    break;
                }
                case TransactionAddressType.InPersonAddress: {
                    codeInvalid = additionalInvalid = codeInvalid || additionalInvalid;
                    this.invalidateField(this.formInputs, 'address', addressInvalid);
                    this.invalidateField(this.formInputs, 'code', codeInvalid);
                    this.invalidateField(this.formInputs, 'additionalInformation', additionalInvalid);
                    break;
                }
                default: {
                    this.invalidateField(this.formInputs, 'address', addressInvalid);
                    break;
                }
            }

            this.formData.address = this.address;
            this.formData.code = this.code;
            this.formData.additionalInformation = this.additionalInformation;
            return;
        }

        let addressInvalid = Boolean(validation.results.find(x => x.propertyName === 'manualAddress' && !x.valid));
        let countryInvalid = Boolean(validation.results.find(x => x.propertyName === 'manualCountry' && !x.valid));
        const cityInvalid = Boolean(validation.results.find(x => x.propertyName === 'manualCity' && !x.valid));
        const stateInvalid = Boolean(validation.results.find(x => x.propertyName === 'manualState' && !x.valid));
        const zipInvalid = Boolean(validation.results.find(x => x.propertyName === 'manualZip' && !x.valid));

        addressInvalid = countryInvalid = addressInvalid || countryInvalid || cityInvalid || stateInvalid || zipInvalid;

        this.invalidateField(this.secondaryFormInputs, 'manualAddress', addressInvalid);
        this.invalidateField(this.secondaryFormInputs, 'manualCountry', countryInvalid);

        this.billingInfo = {
            street: this.manualAddress,
            countryName: this.manualCountry,
            countryCode: this.getCountry(this.manualCountry),
            city: this.manualCity,
            state: this.manualState,
            zip: this.manualZip
        };
    }

    private buildInput(options: InputOptions): ICxTextInput {
        const inlineStyle = options.inline ? 'input-password__additional-input' : '';

        if (options.hasDropdown && !this.globalDropdown) {
            this.globalDropdown = new GlobalDropdown();
        }

        return {
            class: `input-password ${inlineStyle}`,
            inputStyle: 'secondary-input',
            floatingLabel: true,
            name: options.name,
            type: options.type,
            label: options.label,
            value: options.value,
            hasDropdown: options.hasDropdown,
            dropdownOptions: options.dropdownOptions,
            id: options.id,
            inline: options.inline,
            inputFunction: options.inputFunction ?? this.handleTextInput,
            inlineInputs: options.inlineInputs ?? [],
            focusinFunction: () => {
                if (!options.hasDropdown) return;
                this.globalDropdown.setDropdownOptions({
                    options: options.dropdownOptions,
                    optionSelected: (option: string) => this.handleTextInput(option, options.name),
                    onOpen: () => this.sendSelectedEvent(true),
                    onClose: () => setTimeout(() => this.sendSelectedEvent(false), 100),
                    style: 'primary',
                    height: 184
                });
                this.globalDropdown.open(document.getElementById(options.name));
            },
            focusoutFunction: () => {
                if (!options.hasDropdown) return;
                setTimeout(() => this.globalDropdown.close(), 100);
            },
            get outlineStyle(): string {
                if (this.showError) return 'input-outline--error';
                if (this.showSuccess) return 'input-outline--success';
                return '';
            },
            get inputTrailingIcon(): string {
                if (this.showError) return 'error_outline';
                if (this.showSuccess) return 'check_circle_outline';
                return '';
            }
        };
    }

    get formTitle() {
        if (this.secondaryForm) return this.secondaryFormLabel;

        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.AccountAddress: return 'Add a bank account';
            case TransactionAddressType.WalletAddress: return 'Add a wallet address';
            case TransactionAddressType.MailAddress: return 'Add address';
            case TransactionAddressType.InPersonAddress: return 'Add new address';
            default: return `Add a ${this.paymentMethod.paymentMethod.name.split(' ')[0]} account`;
        }
    }

    get secondaryFormLabel() {
        switch (this.paymentMethod.transactionAddressType) {
            case TransactionAddressType.InPersonAddress:
            case TransactionAddressType.MailAddress: return 'Enter address manually';
            default: return '';
        }
    }

    get formTitleStyle() {
        return this.secondaryForm ? 'cursor-pointer' : '';
    }

    get textInputs() {
        return this.formInputs.filter(x => x.type !== 'address');
    }

    get addressInputs() {
        return this.formInputs.filter(x => x.type === 'address');
    }
}
