// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-main-logo.svg", import.meta.url);
// Module
var code = `<template>
    <div class="navbar-area fixed-top">
        <nav id="navigation-bar" class="navbar navbar-expand-md sticky-top cx-background navbar-transparent \${router.currentInstruction.config.route === 'privacy-policy' ||
            router.currentInstruction.config.route === 'copyright-policy' || router.currentInstruction.config.route === 'terms-of-service' ? 'border-less' : ''}" ref="navbar">
            <div class="container-nav justify-content-between">
                <div class="left-container">
                    <div class="d-flex align-items-center">
                        <cx-touch-target expanded>
                            <button class="navbar-toggler p-1 order-0" type="button"
                                click.delegate="handleDrawerOpenClick()">
                                <span id="drawer-menu-icon" class="navbar-toggler-icon material-icons-round text-cx-purple">
                                    menu
                                </span>
                            </button>
                        </cx-touch-target>
                        <a class="navbar-brand" href="\${homePageRoute | languageRoute & async}">
                            <img src="${___HTML_LOADER_IMPORT_0___}" id="chicksx-nav-logo" alt="logo"
                                loading="lazy">
                        </a>
                        <hr id="nav-separator" class="d-none d-lg-block">
                    </div>
                    <!-- Dropdowns -->
                    <div class="d-none d-lg-flex dropdowns">
                        <cx-dropdown-content
                            repeat.for="dropdown of dropdowns"
                            mouseover.trigger="handleContentMouseEnter(\$event)"
                            title.bind="dropdown.title | siteStrings"
                            class="py-auto"
                            nameref.bind="'content-dropdown'"
                            name="content-dropdown"
                            content.bind="dropdown.contentName"
                            click.delegate="handleDropdownOpenClick(dropdown.contentName)"
                            open-dropdown.bind="dropdown.isOpen"
                            content-hovered.bind="dropdown.isContentHovered"
                            is-clicked.bind="dropdown.isClicked"
                            tab-active.bind="dropdown.isActive"
                        ></cx-dropdown-content>
                    </div>
                </div>
                <div id="navbar-text" class="navbar-content d-flex flex-row">
                    <cx-notification-bell if.bind="user && !navBarLoading" data-cy="notification-bell"></cx-notification-bell>
                    <cx-currency-dropdown
                        class="d-none d-lg-flex"
                        currency-options.bind="currencyOptions"
                        languages.bind="languageList"
                        current-currency.from-view="currentCurrency"
                        current-language.from-view="currentLanguage"
                        ref="currencyDropdown"
                        data-cy="currency-dropdown"
                    ></cx-currency-dropdown>
                    <li if.bind="navBarLoading" class="skeleton-main-container d-flex flex-row align-items-center me-sm-3">
                        <div class="skeleton-container"></div>
                        <div class="skeleton-container"></div>
                        <div show.bind="width > widescreen" class="skeleton-container"></div>
                        <div class="d-flex flex-row skeleton-container align-items-center">
                            <div class="inner-circle-skeleton-container"></div>
                            <div class="d-flex flex-column inner-skeleton-main-container">
                                <div class="inner-skeleton-container"></div>
                                <div class="inner-skeleton-container"></div>
                            </div>
                        </div>
                    </li>
                    <a id="parent" click.delegate="handleRedirect('')" if.bind="!user && !navBarLoading" class="d-flex align-items-center">
                        <cx-button
                            button-text="\${'CX_SIGN_IN' | siteStrings}"
                            button-style.bind="\`\${buttonStyling}\`"
                            data-cy="sign-in"
                        ></cx-button>
                    </a>
                    <cx-user-dropdown
                        user.bind="user"
                        class="dropdown-orientation"
                        user-class="name-width"
                        button-class="btn-padding"
                        if.bind="user && !navBarLoading"
                        data-cy="customer-portal-dropdown"
                    ></cx-user-dropdown>
                </div>
            </div>
            <div>
                <mdc-drawer type="modal" view-model.ref="drawer" class="drawer w-100">
                    <mdc-drawer-header>
                        <div class="mobile-navbar-header">
                            <cx-touch-target>
                                <button class="menu-container d-flex align-items-center text-purple"
                                    click.delegate="handleDrawerOpenClick()">
                                    <span class="material-icons cursor-pointer close-icon" click.delegate="handleDrawerOpenClick()">
                                        close
                                    </span>
                                    <span class="menu-button">\${ 'CX_CLOSE' | siteStrings}</span>
                                </button>
                            </cx-touch-target>

                            <cx-text-input
                                class="w-100"
                                label="Search"
                                button-icon.bind="filterOptionsMobile"
                                leading-icon.bind="'search'"
                                value.two-way="filterOptionsMobile"
                                key-up-function.call="filterOptionsMobileChange(\$event)"
                                button-icon-function.call="cleanMobileSearchBar()"
                            ></cx-text-input>
                        </div>
                    </mdc-drawer-header>
                    <mdc-drawer-content data-simplebar class="purple-scrollbar" id="mobile-navbar-drawer-content">
                        <cx-nav-preorder-mobile
                            crypto-list.bind="cryptoList"
                            currency-list.bind="currencyList"
                            payment-methods-list.bind="paymentMethods"
                            filter-input.two-way="filterOptionsMobile"
                            current-currency.bind="currentCurrency"
                            language-list.bind="languageList"
                            current-language.bind="currentLanguage"
                        ></cx-nav-preorder-mobile>
                    </mdc-drawer-content>
                </mdc-drawer>
            </div>
        </nav>
        <!-- Dynamic selected dropdown content -->
        <div 
            mouseleave.trigger="handleContentMouseLeave()"
            class="dynamic-dropdown-content"
        >
            <cx-newsletter-prompt if.bind="user && !user.optedInForEmails && !user.closedSubscriptionBanner" showing-another-banner.bind="showBanner" user.bind="user"></cx-newsletter-prompt>
            <cx-banner
                show-banner.bind="showBanner"
                state.bind="stateBanner"
                text.bind="textBanner"
                click-function-name.bind="clickFunctionName"
                url.bind="url | languageRoute & async"
            ></cx-banner>
            <cx-nav-preorder
                if.bind="dynamicNavContent || someOneContentHovered()"
                preoder-type-action.bind="dynamicNavContent"
                crypto-list.bind="cryptoList"
                currency-list.bind="currencyList"
                payment-methods-list.bind="paymentMethods"
                max-visible-options.bind="maxVisibleOptions"
            ></cx-nav-preorder>
        </div>
    </div>
    <div id="cx-reading-progress-bar" class="w-100" show.bind="showReadingProgress">
        <div id="reading-progress-fill"></div>
    </div>
</template>

`;
// Exports
export default code;