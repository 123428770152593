import './cx-id-selfie-verification-progress.scss';
import { bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';

export class CxIdSelfieVerificationProgress {
    constructor(private pageContentAreaService: PageContentAreaService) {}

    @bindable flowPage : boolean;
    @bindable verified : boolean;
}
