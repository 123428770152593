import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-posts-cards.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint, apiEndpoint } from 'environment';
import { Helper } from 'resources/helpers/helper';
import { SessionService } from 'services/session-service';

@autoinject()
export class CxPostsCards {
    constructor(private helper: Helper, private pageContentAreaService: PageContentAreaService, private sessionService : SessionService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    @bindable blogs;
    @bindable blogRoute;
    baseAwsEndpoint: string;
    enviroment = apiEndpoint();
    parent;
    hrefLang;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async tagClickHandler(tag) {
        await this.parent.tagClickHandler({
            name: tag,
            slug: tag.toLowerCase().replace(' ', '-'),
            activate: true
        });
    }

    async attached() {
        this.blogs.forEach(blog => {
            blog.content = this.helper.sanitizeHtml(blog.content, true);
        });
        const language = await this.sessionService.getLanguage();
        this.hrefLang = language.hrefLang;
    }

    blogsChanged() {
        this.blogs.forEach(blog => {
            blog.content = this.helper.sanitizeHtml(blog.content, true);
        });
    }
}
