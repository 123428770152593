import './balance.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { User } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { CurrencyChangedEvent } from 'resources/constants';
import { Helper } from 'resources/helpers/helper';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';


@autoinject()
export class Balance {
    user: User;
    currencySubscription: Subscription;
    convertedBalance: string;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;

    constructor(
        private helper: Helper,
        private sessionService: SessionService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private eventAggregator: EventAggregator,
        private router: Router,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) { }

    async activate() {
        this.user = await this.sessionService.getProfile();
        this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.user.balance);
    }

    attached() {
        try {
            this.helper.addLoadingComponent('balance');
            this.handleEventSubscriptions();
            this.currencySubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, async() => {
                this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.user.balance);
            });
        } finally {
            this.helper.validateLoading('balance');
        }
    }

    redirectTo = (route: string) => {
        this.router.navigate(`balance/${route}`);
    };

    detached() {
        this.currencySubscription.dispose();
        this.pageLoadedSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }
}
