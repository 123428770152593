import './cx-paragraph-title.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxParagraphTitle {
    @bindable caption;
    @bindable text;
    @bindable invertedColor;
    @bindable captionStyle: string = 'mb-4';
    @bindable textStyle: string = 'text-medium opacity-75 lh-lg';
}
