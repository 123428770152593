// Module
var code = `<template>
    <div id="cx-checkbox-filter" mouseleave.trigger="toggleDropdown(\$event)">
        <div if.bind="type === 'checkbox'" tabindex="0" focusout.delegate="openDropdown = false">
            <div class="position-relative">
                <div class="d-flex select-spacer align-items-center">
                    <div class="bullet checkbox-external d-flex align-items-center justify-content-center cursor-pointer \${ checkboxExternalIsChecked ? 'bullet-active' : '' }" click.delegate="clickCheckboxExternalHandler()">
                        <span show.bind="checkboxExternalIsChecked" class="material-icons text-white">check</span>
                    </div>
                    <span class="material-icons text-darkergray select-arrow cursor-pointer" mouseenter.trigger="toggleDropdown(\$event)" click.delegate="toggleDropdown(\$event)">
                        <span if.bind="filled" class="material-icons text-darkergray select-arrow cursor-pointer">
                            \${openDropdown ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </span>
                        <span else class="material-icons text-darkergray select-arrow cursor-pointer">
                            \${openDropdown ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                        </span>
                    </span>
                </div>
                <div class="select-box-container checkbox-container position-absolute flex-column justify-content-between \${ openDropdown ? 'd-flex' : '' }">
                    <div repeat.for="option of options" click.delegate="handleFiltering(option)" class="d-flex align-items-center cursor-pointer option">
                        <div class="bullet d-flex align-items-center justify-content-center \${option.active ? 'bullet-active' : ''}">
                            <span show.bind="option.active" class="material-icons text-regular text-white">check</span>
                        </div>
                        <span class="option-text text-regular \${option.active ? 'option-selected' : ''}">\${option.selection | entityStatus}</span>
                    </div>
                </div>
            </div>
        </div>
        <div else id="container-filter">
            <div class="position-relative">
                <div class="d-flex select-spacer">
                    <div class="select-box-dropdown d-flex align-items-center">
                        <div class="d-flex flex-row">
                            <span class="text-truncate">\${selectedOptions[0].selection | entityStatus}</span>
                            <span if.bind="isShowMoreFilters">(+\${selectedOptions.length - 1})</span>
                        </div>
                        <span class="material-icons select-arrow">
                            arrow_drop_down
                        </span>
                    </div>
                </div>
                <div class="select-box-container dropdown-container position-absolute flex-column justify-content-between">
                    <div repeat.for="option of options" click.delegate="handleFiltering(option)" class="d-flex align-items-center cursor-pointer mt-1 option">
                        <div class="bullet d-flex align-items-center justify-content-center \${option.active ? 'bullet-active' : ''}">
                            <span show.bind="option.active" class="material-icons text-regular text-white">check</span>
                        </div>
                        <span class="option-text text-regular \${option.active ? 'option-selected' : ''}">\${option.selection | entityStatus}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;