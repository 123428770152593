import numeral from 'numeral';

export class NumberFormatValueConverter {
    toView(value, format, charLimit) {
        if (!value) {
            return 0;
        }

        if (!format) {
            format = '(0,0.000000)';
        }

        if (charLimit && value.length > charLimit) {
            return numeral(value).format(format).substr(0, charLimit) + '...';
        }

        return numeral(value).format(format);
    }
}
