// Module
var code = `<template>
    <div class="d-flex justify-content-center flex-column align-items-center spin-loader pb-5 pb-sm-0" show.bind="pageLoading">
        <mdc-circular-progress size="80" stroke-width="4"></mdc-circular-progress>
        <div class="mt-4" show.bind="pageLoading">Loading...</div>
    </div>
    <div id="chat-view" show.bind="!pageLoading">
        <div class="d-flex justify-content-between flex-column flex-sm-row">
            <div class="d-flex flex-column mb-lg-4">
                <span class="text-extra-large font-medium support-header-create">Viewing ticket \${ ticketData.ticketKey }</span>
                <span id="title" class="me-0 me-sm-1 text-black text-truncate">\${ ticketData.title }</span>
            </div>
            <cx-button
                if.bind="ticketData.status === 'closed'"
                class="text-regular mt-1 mb-3 mb-sm-0"
                button-text="Reopen ticket"
                button-style="secondary resolve ticket-btn text-regular font-medium"
                button-icon="check_circle"
                button-icon-style="me-3 text-large-plus"
                click.delegate="changeTicketStatus('active')"
                button-disabled.bind="triggeredMarkTicketAsReopened"
            ></cx-button>
            <cx-button
                else
                class="text-regular mt-1 mb-3 mb-sm-0"
                button-text="Resolve ticket"
                button-style="secondary resolve ticket-btn text-regular font-medium"
                button-icon="check_circle"
                button-icon-style="me-3 text-large-plus"
                click.delegate="changeTicketStatus('closed')"
                button-disabled.bind="triggeredMarkTicketAsResolved"
            ></cx-button>
        </div>
        <hr class="divisor-header d-md-block d-lg-none">
        <div id="messages" class="messages">
            <div repeat.for="messageData of messages">
                <cx-chat-message 
                    is-sender.bind="messageData.senderId == user.id"
                    ticket-key.bind="ticketData.ticketKey"
                    image-url="\${messageData.userId == user.id ? user.avatarImagePath : messageData.user.avatarImagePath}"
                    date.bind="messageData.sentDate | dateFormatter"
                    message.bind="messageData.message"
                    files.bind="messageData.attachments"
                    chat-messages-loading.two-way="chatMessagesLoading"
                ></cx-chat-message>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center">
            <div class="mb-2">
                <span if.bind="displayResponseTime" class="text-input-placeholder">Our current response time is: \${responseTime} minutes</span>
            </div>
            <div class="w-100 position-relative">
                <cx-support-issue-input 
                    is-saving-draft.bind="isSavingDraft"
                    issue.two-way="issue" 
                    files-list.two-way="fileList"
                    element.ref="issueInput"
                    file-types.bind="[]"
                    file-extensions.bind="['pdf', 'heic', 'jpg', 'jpeg', 'png', 'bmp', 'gif']"
                    viewing-ticket="true"
                    disabled.bind="ticketData.status === 'closed'"
                ></cx-support-issue-input>
            </div>
            <div class="btn-container align-self-end d-flex flex-column flex-sm-row">
                <cx-button
                    class="text-regular me-0 me-sm-3 mb-3 mb-sm-0"
                    button-text="Connect live chat"
                    button-style.bind="'secondary ticket-btn text-regular font-medium'"
                    click.delegate="showIntercomMessage()"
                    button-icon="connect_without_contact"
                    button-icon-style="me-3 text-large-plus"
                    button-disabled.bind="ticketData.status === 'closed'"
                ></cx-button>
                <cx-button
                    class="text-regular mt-1 mt-sm-0"
                    button-text="Reply to ticket"
                    button-style="primary primary-style ticket-btn send-btn text-regular font-medium \${ attachmentsUploading || ticketData.status === 'closed' || triggeredReplyToTicket ? 'disabled' : '' }"
                    button-icon-style.bind="'text-small'"
                    click.delegate="replyToTicket()"
                    button-icon="send"
                    button-icon-style="me-2 text-small"
                    button-disabled.bind="attachmentsUploading || ticketData.status === 'closed' || triggeredReplyToTicket"
                ></cx-button>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;