import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { TotalSpentByUser, User, WithdrawPasswordVerificationResponse } from 'services/models/user/user';
import { CustomerService } from 'services/customer-service';
import { PaymentMethod } from 'services/models/purchase-flow/exchange';
import { Helper } from 'resources/helpers/helper';
import { inject } from 'aurelia-dependency-injection';
import { PhoneUpdatedEvent, UserUpdatedEvent, VeriffVerificationEvent } from 'resources/constants';
import { CreditCardHelper } from 'resources/helpers/credit-card-helper';
import { IPhoneUpdatedEvent, IUserUpdatedEvent, IVeriffVerificationEvent } from 'types/events';

export enum PasswordVerificationState {
    Loading,
    Success,
    Error
}

@inject(EventAggregator, PaymentMethodWebsiteService, CustomerService, Helper, CreditCardHelper)
export abstract class UserVerification {
    private userSubscription: Subscription;
    private phoneSubscriber: Subscription;
    private veriffSubscriber: Subscription;
    private manualPaymentMethods = ['manual', 'zelle', 'alipay', 'cash', 'western-union', 'interac'];

    phoneVerified = false;
    payEnabled = false;
    veriffStep = false;
    totalSpent: TotalSpentByUser | null;
    idCriteriaMet: boolean;
    verified: boolean;
    hasWithdrawPassword?: boolean;
    passwordVerificationState?: PasswordVerificationState;
    tooManyAttemps?: boolean;

    protected constructor(
        public eventAggregator: EventAggregator,
        public paymentMethodWebsiteService: PaymentMethodWebsiteService,
        public customerService: CustomerService,
        public helper: Helper,
        public creditCardHelper: CreditCardHelper
    ) {}

    abstract userVerificationUpdated(user: User);
    abstract phoneVerificationUpdated(phoneConfirmed: boolean);
    abstract autoTriggerOrder();

    startVerificationListeners() {
        this.phoneSubscriber = this.eventAggregator.subscribe(PhoneUpdatedEvent, (payload: IPhoneUpdatedEvent) => {
            this.phoneVerificationUpdated(payload.successful);
        });

        this.userSubscription = this.eventAggregator.subscribe(UserUpdatedEvent, async (payload: IUserUpdatedEvent) => {
            if (payload.user) {
                this.idCriteriaMet = await this.customerService.verificationCategoryCriteriaMet('ID & Selfie Verification');
                this.totalSpent = await this.customerService.getTotalSpentByUser(payload.user.id);
            }

            this.userVerificationUpdated(payload.user);
        });

        this.veriffSubscriber = this.eventAggregator.subscribe(VeriffVerificationEvent, (payload: IVeriffVerificationEvent) => {
            this.verified = payload.user?.idVerified;
            if (this.verified) {
                this.autoTriggerOrder();
            }
        });
    }

    clearVerificationListeners() {
        this.userSubscription.dispose();
        this.phoneSubscriber.dispose();
        this.veriffSubscriber.dispose();
    }

    async checkForVerification(user: User, totalPrice: number, selectedPaymentMethod?: PaymentMethod) {
        if (!totalPrice || !selectedPaymentMethod) {
            this.phoneVerified = true;
            return;
        }

        if (!this.totalSpent) this.totalSpent = await this.customerService.getTotalSpentByUser(user.id);
        const result = await this.paymentMethodWebsiteService.getVerificationThresholds(this.totalSpent, selectedPaymentMethod, totalPrice);

        this.phoneVerified = result.amountRequiresPhoneVeriff ? user.phoneNumberConfirmed : true;

        if (!this.phoneVerified) {
            this.payEnabled = false;
            return;
        }

        if (this.helper.includesWithout(selectedPaymentMethod?.reference, this.manualPaymentMethods, ['bluesnap-checkout', 'coinpayments']) && !user.idVerified && !result?.amountRequiresVeriff) {
            this.veriffStep = true;
            this.payEnabled = false;
            return;
        }

        this.veriffStep = false;
        this.payEnabled = true;
    }

    async requiresVeriff(user: User, paymentMethod: PaymentMethod, amount: number) {
        const thresolds = await this.paymentMethodWebsiteService.getVerificationThresholds(this.totalSpent, paymentMethod, amount);
        return !user.idVerified && thresolds.amountRequiresVeriff && !this.idCriteriaMet;
    }

    isManualPaymentMethod(paymentMethod?: PaymentMethod) {
        return this.manualPaymentMethods.includes(paymentMethod?.reference);
    }

    async getHasWithdrawPassword() {
        if (this.hasWithdrawPassword !== null && this.hasWithdrawPassword !== undefined) return this.hasWithdrawPassword;
        this.hasWithdrawPassword = await this.customerService.hasWithdrawPassword();
        return this.hasWithdrawPassword;
    }

    async verifyWithdrawPassword(password: string | null) {
        if (!password) {
            this.passwordVerificationState = null;
            return;
        }

        this.passwordVerificationState = PasswordVerificationState.Loading;
        const response = await this.customerService.verifyWithdrawPassword(password);

        if (response === WithdrawPasswordVerificationResponse.Success) {
            this.passwordVerificationState = PasswordVerificationState.Success;
            return;
        }

        this.passwordVerificationState = PasswordVerificationState.Error;
        this.tooManyAttemps = response === WithdrawPasswordVerificationResponse.Blocked;
    }
}
