import './cx-meetup-combo.scss';
import { bindable, observable, autoinject } from 'aurelia-framework';
import moment from 'moment';
import flatpickr from 'flatpickr';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { Instance } from 'flatpickr/dist/types/instance';
import { Exchange } from 'services/models/purchase-flow/exchange';

@autoinject()
export class CxMeetupCombo {
    constructor (
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable time: string;
    @bindable timePlaceholder = 'Enter time';
    @bindable date: string;
    @bindable location: string;
    @bindable meetupAddressValid: boolean;
    @observable selectedOption: string;
    @bindable selectedDelivery: DeliveryMethod;
    @bindable loading: boolean = true;
    autocompletedAddress: string;
    hours: string[] = [];
    parent;
    element: HTMLElement;
    flatpickrInstance: Instance;

    showMiniSpinnerAddress: boolean;
    showGreenCheckMarkAddress: boolean;
    timeouts: NodeJS.Timeout[];
    globalTimeout: NodeJS.Timeout;
    cashInPersonCheck: boolean = false;
    exchange: Exchange;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.buildHoursArray();
        this.element = document.getElementById('datepickerInput');
        this.setCalendarOptions();
        this.exchange = this.parent.exchange;
        this.paymentMethodCheck();
    }

    selectedDeliveryChanged() {
        this.setCalendarOptions();
        this.setHoursOptions();
        this.validateAddress();
    }

    getDate(days: number) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }

    getAvailableDateOptions(date: 'minDate' | 'maxDate') {
        if (!this.selectedDelivery) {
            if (date === 'minDate') return new Date();
            if (date === 'maxDate') return null;
        }
        if (!this.selectedDelivery.minDays && !this.selectedDelivery.maxDays) return new Date();
        if (!this.selectedDelivery.minDays && this.selectedDelivery.maxDays) return this.getDate(this.selectedDelivery?.name === 'special' && date === 'maxDate' ? this.selectedDelivery.maxDays + 1 : this.selectedDelivery.maxDays);

        if (date === 'maxDate') return this.getDate(this.selectedDelivery.maxDays);
        if (date === 'minDate') return this.getDate(this.selectedDelivery.minDays);
    }

    setCalendarOptions() {
        const minDate = this.getAvailableDateOptions('minDate');
        const maxDate = this.getAvailableDateOptions('maxDate');
        this.buildCalendar(minDate, maxDate);
        const date = new Date(this.date);
        if (minDate > date || date > maxDate) this.flatpickrInstance.setDate(minDate, true);
    }

    buildCalendar(minDate: Date, maxDate: Date) {
        this.flatpickrInstance = flatpickr(this.element, {
            minDate,
            maxDate: this.selectedDelivery?.name === 'regular' ? null : maxDate,
            disableMobile: true,
            onOpen : function() {
                const calendar = document.getElementsByClassName('flatpickr-calendar')[0];
                setTimeout(() => {
                    calendar.classList.remove('arrowTop');
                    calendar.classList.remove('arrowBottom');
                }, 100);
            },
        });
    }

    getRoundedTime() {
        const current = moment();
        if (current.minute() > 30) current.add(1, 'hour');
        return current.startOf('hour');
    }

    setHoursOptions() {
        this.buildHoursArray();
        if (this.time && (this.selectedDelivery?.minHours || this.selectedDelivery?.maxHours)) {
            const selectedTime = moment(this.time, 'hh:mm A').hour();
            const minHour = moment().add(this.selectedDelivery.minHours, 'hours').hour();
            const maxHour = moment().add(this.selectedDelivery.maxHours, 'hours').hour();
            if (minHour > selectedTime || selectedTime > maxHour) {
                this.selectedOption = this.timePlaceholder = this.hours.find(h => h === this.getRoundedTime().add(this.selectedDelivery.minHours, 'hours').format('h:mm A'));
            }
        }
    }

    buildHoursArray() {
        moment.locale('en');
        this.hours = [];
        let hour = 0;
        let maxHour = 24;
        if (this.selectedDelivery?.minHours) hour = this.getRoundedTime().add(this.selectedDelivery.minHours, 'hours').hour();
        if (this.selectedDelivery?.maxHours) maxHour = moment().add(this.selectedDelivery.maxHours + 7, 'hours').hour();
        if (hour > maxHour) {
            this.hoursFor(hour, 24);
            hour = 0;
        }
        this.hoursFor(hour, maxHour);
    }

    hoursFor(hour: number, maxHour: number) {
        for (hour; hour < maxHour; hour++) {
            this.hours.push(moment({ hour }).format('h:mm A'));
            this.hours.push(
                moment({
                    hour,
                    minute: 30
                }).format('h:mm A')
            );
        }
    }

    selectedOptionChanged(newValue: string) {
        this.time = newValue;
        this.parent.validateCryptoPayButton();
    }

    validateAddress() {
        this.showMiniSpinnerAddress = this.showGreenCheckMarkAddress = this.meetupAddressValid = false;
        if (!this.parent.expandableOpen) this.parent.onToggle();
        this.timeouts = [this.globalTimeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.time && this.date && this.location?.length > 0) {
            this.showMiniSpinnerAddress = true;
            this.globalTimeout = setTimeout(() => {
                this.showMiniSpinnerAddress = false;
                this.showGreenCheckMarkAddress = true;
                this.meetupAddressValid = true;
                this.parent.validateCryptoPayButton();
            }, 3000);
        } else {
            this.meetupAddressValid = false;
            this.parent.validateCryptoPayButton();
        }
    }

    reset() {
        this.timePlaceholder = 'Enter time';
        this.time = '';
        this.date = '';
        this.location = '';
        this.autocompletedAddress = '';
        this.validateAddress();
    }

    paymentMethodCheck() {
        this.cashInPersonCheck = this.exchange?.receivingPaymentMethod?.paymentMethod.reference === 'cash-in-person';
    }
}
