import './cx-number-arrows.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { DesktopBreakpoint } from 'resources/constants';

@autoinject()
export class CxNumberArrows {
	@bindable tempQuantity;
    @bindable @observable quantity;
    @bindable sliderQuantity;
    @bindable product;
    @bindable suffix;
    @bindable type;
    @bindable parentEvent;
    @bindable disabled;
    @bindable minimum = 0;
    @bindable maximum = 999999;
    @bindable extraButtons = false;
    @bindable isCurrency = false;
    changed;
    sizeChangedSubscriber;
    parent;
    increaseArrow;
    decreaseArrow;
    extraButtonsref;

    width: number;
    desktop: number;

    constructor(
		private helper: Helper,
		private eventAggregator: EventAggregator
    ) {
        this.helper.getResolutions(this);
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.width = this.helper.getWidth();
        this.desktop = DesktopBreakpoint;
        this.handleEventSubscriptions();
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    preventFocusLoss(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    handleEventSubscriptions() {
        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
    }

    setQuantities() {
        this.mapQuantities(quantity => {
            if (quantity === 'All' || !quantity) quantity = 0;
            return this.helper.convertNumberWithoutComma(quantity, 'float');
        });

        this.quantity = this.tempQuantity;
    }

    mapQuantities = (callback) => {
        [this.quantity, this.tempQuantity] = [this.quantity, this.tempQuantity].map(quantity => callback(quantity));
    };

    handleChanged() {
        this.mapQuantities(() => this.helper.clamp(this.quantity || this.tempQuantity, this.maximum, this.minimum));
        if (!this.changed) return;

        if (this.sliderQuantity) {
            this.sliderQuantity = this.tempQuantity;
        }

        if (!this.parentEvent) return;

        if (this.type && this.parent.itemQuantityChanged) {
            this.parent[this.parentEvent](this.type, this.tempQuantity);
        } else if (this.parent.itemQuantityChanged || this.parent.handleArrowsChange) {
            this.parent[this.parentEvent](this.quantity);
        }
    }

    add(quantity) {
        this.changed = true;
        const quantityToAdd = this.isCurrency ? this.handleCurrencyValueIncrease(quantity) : 1;
        return (quantity + quantityToAdd).toFixed(2);
    }

    subtract(quantity) {
        this.changed = true;
        const quantityToSubtract = this.isCurrency ? this.handleCurrencyValueDecrease(quantity) : 1;
        return (quantity - quantityToSubtract).toFixed(2);
    }

    increaseValue() {
        this.setQuantities();
        this.changed = false;
        if (this.quantity < this.maximum) {
            this.quantity = this.add(this.quantity);
            this.tempQuantity = this.add(this.tempQuantity);
        }
        this.handleChanged();
    }

    decreaseValue() {
        this.setQuantities();
        this.changed = false;
        if (this.quantity > this.minimum) {
            this.quantity = this.subtract(this.quantity);
            this.tempQuantity = this.subtract(this.tempQuantity);
        }
        this.handleChanged();
    }

    handleCurrencyValueIncrease(quantity) {
        switch (true) {
            case quantity >= 0 && quantity < 1:
                return 0.01;
            case quantity >= 1 && quantity < 100:
                return 1;
            default:
                return 100;
        }
    }

    handleCurrencyValueDecrease(quantity) {
        switch (true) {
            case quantity > 0 && quantity <= 1:
                return 0.01;
            case quantity > 1 && quantity <= 100:
                return 1;
            default:
                return 100;
        }
    }

    removeStyling(element) {
        if (!element) return;
        element.style.filter = null;
    }

    quantityChanged() {
        if (!this.extraButtonsref || (this.width >= this.desktop && !this.extraButtonsref)) return;
        const length = this.quantity?.toString().length;
        this.extraButtonsref.style.width = length < 2
            ? '36px'
            : `${36 + ((length - 2) * 8)}px`;
    }
}
