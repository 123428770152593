// Module
var code = `<template>
    <div id="cx-table" ref="tableComponent">
        <div if.bind="width > 576" class="table-headers \${ tableHeadersClass }">
            <p
                repeat.for="header of tableHeaders"
                class="\${header.class}"
            >
                \${\`CX_\${header.title.split(' ').join('_').toUpperCase()}\` | siteStrings:header.title}
            </p>
        </div>
        <div class="list-container">
            <slot></slot>
        </div>
    </div>
</template>
`;
// Exports
export default code;