import './contact.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class Contact {
    optionSelected: number;
    selectOptions = [
        { name: 'Help with an order', keyName: 'CX_HELP_ORDER' },
        { name: 'Business Inquiries', keyName: 'CX_BUSINESS_INQUIRIES' },
        { name: 'Careers', keyName: 'CX_CAREERS' },
        { name: 'Help with your account', keyName: 'CX_HELP_ACCOUNT' },
    ];

    dropZoneOutside: HTMLElement;
    fileInput: HTMLElement;
    filesList;
    tempFiles;
    category: string;
    email: string;
    message: string;
    name: string;
    fileTypes: string[];
    fileExtensions: string[];
    maxNumberOfFiles: number;
    isSending: boolean;
    arrowTriggered;
    lastEventTriggered;
    arrowRotated;
    phoneInput;
    showGreenCheckMarkEmail: boolean;
    showErrorCheckMarkEmail: boolean;
    showMiniSpinnerEmail: boolean;
    emailFocusInStopWatch;
    timeouts;
    firedFunction;
    successFunction = undefined;
    autoFillTriggered = false;
    autoFillStopWatch;
    miniSpinnerEmailStopwatch;
    autoFillStopWatch1;
    emailStopWatch;
    emailStopWatch2;
    showDropdown = false;
    phoneElement;
    isToastSent = false;
    emailInput;
    user;
    faqRoute = '';

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private customerService: CustomerService,
        private validator: ValidationController,
        private toastService: ToastService,
        private router: Router,
        private sessionService: SessionService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private helper: Helper) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Contact Us')?.id);
        this.pageContentAreaService.retrievedContent.forEach(item => {
            if (item.markup.includes('support@chicksx.com') && !item.markup.includes('<a href=')) {
                item.markup = item.markup.replace('support@chicksx.com', '<a href="mailto:support@chicksx.com" class="link-support">support@chicksx.com</a>');
            }
        });
        this.filesList = [];
        this.tempFiles = [];
        this.optionSelected = 0;
        this.category = 'Help with an order';
        this.fileTypes = ['image', 'video'];
        this.fileExtensions = ['txt', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx', 'pdf', 'odp', 'ods', 'odt', 'xml'];
        this.maxNumberOfFiles = 10;
        this.isSending = false;
        this.showGreenCheckMarkEmail = false;
        this.showErrorCheckMarkEmail = false;
        this.showMiniSpinnerEmail = false;
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
        this.user = await this.sessionService.getProfile();
        this.fileInput = document.getElementById('file-input');
        this.dropZoneOutside = document.getElementById('drop-zone-outside');
        this.overrideMdc();
        this.validator.reset();
    }

    overrideMdc() {
        const selectArrow = document.getElementsByClassName('mdc-select__dropdown-icon');
        const newArrowIcon = '<span class="arrow-icon material-icons">arrow_drop_down</span>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }

    handleClick(e) {
        this.arrowTriggered = e.target.querySelector('.arrow-icon');
        if (this.arrowRotated) {
            if (this.arrowTriggered) {
                this.arrowTriggered.style.transform = 'rotate(0deg)';
            }
            this.arrowRotated = false;
        } else {
            if (this.lastEventTriggered !== 'focusOut') {
                this.arrowTriggered.style.transform = 'rotate(180deg)';
                this.arrowRotated = true;
            }
        }
    }

    handleFocusOut() {
        if (this.arrowTriggered) {
            this.arrowTriggered.style.transform = 'rotate(0deg)';
            this.arrowRotated = false;
            this.lastEventTriggered = 'focusOut';
            setTimeout(() => {
                this.lastEventTriggered = '';
            }, 200);
        }
    }

    dragEnterHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.path.filter(item => (item['id'] === 'drop-zone-outside'))) {
            this.dropZoneOutside.classList.add('hover-drop-zone');
            this.showDropdown = false;
        }
    }

    dragLeaveHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target['id'] === 'drop-zone-outside' || ev.target['id'] === 'drop-zone') {
            this.dropZoneOutside.classList.remove('hover-drop-zone');
            this.showDropdown = false;
        }
    }

    findLastIndex(array, query) {
        const arrayTemp = [];
        array.forEach(element => arrayTemp.push(element.name));
        return arrayTemp.lastIndexOf(query);
    }

    dropHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.dataTransfer.items) {
            for (const key in ev.dataTransfer.items) {
                const item = ev.dataTransfer.items[key];

                if (item.kind === 'file') {
                    if (this.checkFileType(item.getAsFile()) && this.checkFileSize(item.getAsFile())) {
                        if (this.tempFiles.length >= this.maxNumberOfFiles) {
                            this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                        } else {
                            this.tempFiles.push({ name: item.getAsFile().name, isLoaded: false });
                            this.createAttachedObject(item.getAsFile())
                                .then(result => {
                                    this.filesList.push(result);
                                    this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;
                                });
                        }
                    }
                }
            }
        } else {
            for (const key in ev.dataTransfer.files) {
                const item = ev.dataTransfer.items[key].getAsFile();

                if (this.checkFileType(item.getAsFile()) && this.checkFileSize(item.getAsFile())) {
                    if (this.tempFiles.length >= this.maxNumberOfFiles) {
                        this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                    } else {
                        this.tempFiles.push({ name: item.getAsFile().name, isLoaded: false });
                        this.createAttachedObject(item.getAsFile())
                            .then(result => {
                                this.filesList.push(result);
                                this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;
                            });
                    }
                }
            }
        }

        this.dropZoneOutside.classList.remove('hover-drop-zone');
        this.showDropdown = false;

        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    changeHandler(ev) {
        for (const file of ev.target['files']) {
            if (this.checkFileType(file) && this.checkFileSize(file)) {
                if (this.tempFiles.length >= this.maxNumberOfFiles) {
                    this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                } else {
                    this.tempFiles.push({ name: file.name, isLoaded: false });
                    this.createAttachedObject(file)
                        .then(result => {
                            this.filesList.push(result);
                            this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;
                        });
                }
            }
        }
        this.fileInput['value'] = null;
        this.showDropdown = false;
    }

    checkFileType(file) {
        if (this.fileTypes.filter(item => file['type'].startsWith(item + '/')).length > 0 ||
            this.fileExtensions.filter(item => file['name'].endsWith('.' + item)).length > 0) {
            return true;
        }

        this.toastService.showToast('File type is not allowed', file['name'], 'error');
        return false;
    }

    checkFileSize(file) {
        const maxSizePerFile = 20971520; //Equivalent to 20 MB
        const maxSizeAllFiles = 20971520; //Equivalent to 20 Mb

        if (file['size'] > maxSizePerFile) {
            this.toastService.showToast('File size exceeds 20 MB', file['name'], 'error');
            return false;
        }

        if (this.filesList.map(item => item['size']).reduce((prev, curr) => prev + curr, 0) + file['size'] > maxSizeAllFiles) {
            this.toastService.showToast('The total size of all files must not exceed 20 MB', 'Please try again', 'error');
            return false;
        }

        return true;
    }

    async createAttachedObject(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                // The next line is to retrieve only the Base64 encoded string, first remove data:*/*;base64, from the result.
                const result = reader.result.toString().substring(reader.result.toString().indexOf('base64,') + 'base64,'.length);

                resolve({ content: result, filename: file.name, type: file.type, disposition: 'attachment', size: file.size });
            };

            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    scrollToFormWith(option) {
        const contactElement = document.getElementById('contact-form');
        contactElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
        this.choiseSelectOption(option);
    }

    choiseSelectOption(option) {
        this.optionSelected = option;
    }

    deleteItem(index: number) {
        this.tempFiles.splice(index, 1);
        this.filesList.splice(index, 1);
    }

    showItem(index: number) {
        return index < 4 ? true : false;
    }

    async sendEmail() {
        try {
            this.isSending = true;
            const result = await this.validator.validate();
            if (!result.valid) {
                return;
            }

            const response = await this.customerService.sendContactEmail(this.email.trim(), this.message, this.category, this.name, this.filesList);
            if (response) {
                this.toastService.showToast('Contact request sent', 'Success!', 'success');
                this.email = '';
                this.message = '';
                this.name = '';
                this.filesList = [];
                this.tempFiles = [];
                if (!this.user?.phoneNumberConfirmed) {
                    this.phoneElement.au.controller.viewModel.clearPhoneInput();
                }
                this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.showMiniSpinnerEmail = false;
            } else {
                this.toastService.showToast('Email could not be sent', 'Please try again.', 'error');
            }
        } catch (e) {
            this.toastService.showToast('Email could not be sent', 'Please try again.', 'error');
        } finally {
            this.isSending = false;
        }
    }

    async emailUpdatedOnKeyPress(event) {
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!event?.keyCode && !this.autoFillTriggered) {
            return;
        } else if (!event.keyCode && this.autoFillTriggered) {
            if (!this.firedFunction) {
                this.autoFillStopWatch = setTimeout(() => {
                    this.showMiniSpinnerEmail = true;
                    this.autoFillStopWatch1 = setTimeout(async () => {
                        this.firedFunction = true;
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmail = true;
                        }
                    }, 1000);
                }, 1000);
            }
            return;
        }

        this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.showMiniSpinnerEmail = false;
        this.validator.reset();

        if (this.email?.trim() !== undefined) {
            this.miniSpinnerEmailStopwatch = setTimeout(() => {
                this.showMiniSpinnerEmail = true;
            }, 1000);
            this.emailStopWatch = setTimeout(async () => {
                ValidationRules
                    .ensure('email').displayName('Email').required().matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/).withMessage('Please enter a valid email.')
                    .on(this);
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('email', rules.results);

                if (!checkValidation) {
                    this.validator.reset();
                    this.emailStopWatch2 = setTimeout(async () => {
                        ValidationRules
                            .ensure('email').displayName('Email').required().matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/).withMessage('Please enter a valid email.')
                            .on(this);
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerEmail = false;
                        this.showErrorCheckMarkEmail = !this.validatorCheckOneCondition('email', rules2.results);
                        if (!this.isToastSent) {
                            await this.toastService.showToast('Please enter a valid email', 'Entered email does not match the expected structure.', 'error');
                            this.isToastSent = true;
                        }
                    }, 2000);
                } else {
                    this.firedFunction = true;
                }
                if (checkValidation) {
                    this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = false;
                    this.showGreenCheckMarkEmail = true;
                    this.isToastSent = false;
                }
            }, 2000);
        }
    }

    emailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.showMiniSpinnerEmail = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.validator.reset();
        this.emailFocusInStopWatch = setTimeout(() => {
            if (this.email?.trim() !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.isToastSent = false;
                this.emailUpdatedOnKeyPress(event);
            }
        });
    }

    async checkEmailValidation() {
        this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.showMiniSpinnerEmail = false;
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.autoFillStopWatch, this.autoFillStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.email?.trim() !== undefined) {
            ValidationRules
                .ensure('email').required().matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/).withMessage('Please enter a valid email.')
                .on(this);
            const rules = await this.validator.validate();
            this.showMiniSpinnerEmail = false;
            const checkValidation = this.validatorCheckOneCondition('email', rules.results);

            if (!checkValidation) {
                this.showErrorCheckMarkEmail = true;
                if (!this.isToastSent) {
                    await this.toastService.showToast('Please enter a valid email', 'Entered email does not match the expected structure.', 'error');
                    this.isToastSent = true;
                }
            } else {
                this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = false;
                this.showGreenCheckMarkEmail = true;
                this.isToastSent = false;
            }
        }
    }

    validatorCheckOneCondition(field, results) {
        let valid = true;
        for (const result of results) {
            if (result.propertyName === field && result.message) {
                valid = result.valid;
            }
        }
        return valid;
    }

    toggleDropdown(ev) {
        if (ev.relatedTarget?.localName === 'mdc-list' || ev.type === 'click') {
            this.showDropdown = !this.showDropdown;
        }
        return this.showDropdown;
    }

    emailFocus(event) {
        if (event.type === 'focusin') {
            this.dropZoneOutside.classList.add('active');
        } else {
            this.dropZoneOutside.classList.remove('active');
        }
    }

    changeSelectHandler(ev) {
        this.optionSelected = ev.detail.index;
        this.handleFocusOut();
    }
}

ValidationRules
    .ensure('email').matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/).required()
    .on(Contact);
