import './cx-back-button.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

type CallbackFunction = () => void;
@autoinject()
export class CxBackButton {
    @bindable route?: string;
    @bindable clickFunction: CallbackFunction;
    @bindable parentClass: string;
    @bindable pageLoading: boolean;
    @bindable disabled: boolean;
    @bindable noPadding: boolean = true;

    constructor(
        private router: Router
    ) {}

    onClick = () => {
        if (this.clickFunction) {
            this.clickFunction();
            return;
        }
        if (this.route) this.router.navigate(this.route);
        else this.router.navigateBack();
    };

    @computedFrom('parentClass', 'disabled', 'noPadding')
    get primaryClass() {
        return `${this.parentClass} ${this.disabled ? 'opacity-50 pe-none' : ''} ${this.noPadding ? 'no-padding' : ''}`;
    }
}
