import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class ReferralService {
    path = 'ReferralLink';

    constructor(private api: ApiService) {}


    async submitReferralLink(url: string, linkTypeId: string) {
        return await this.api.doPost(`${this.path}/submit?referralLinkUrl=${url}&linkTypeId=${linkTypeId}`, {});
    }
}
