// Module
var code = `<template>
    <div id="cx-learn-more-card" skeleton-loading="is-loading.bind:pageLoading;">
        <div class="container-card">
            <div class="container-up">
                <div class="cx-learn-more-header">
                    <div class="learn-more-title font-bold \${ viewingAsAdmin ? '' : 'lines-overflow' }">
                        <cx-page-content-area key.bind="title"></cx-page-content-area>
                    </div>
                </div>
                <div class="learn-more-paragraph \${ viewingAsAdmin ? '' : 'lines-overflow' }">
                    <cx-page-content-area key.bind="paragraph"></cx-page-content-area>
                </div>
            </div>
            <a href="\${ href }" class="d-inline-block" aria-describedby="\${ ariaLabel }">
                <cds-button class="learn-more-button">\${ buttonCaption }</cds-button>
            </a>
        </div>
    </div>
</template>
`;
// Exports
export default code;