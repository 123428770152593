/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-extend-native */

import { Helper } from 'resources/helpers/helper';

interface ArrayAndStringExtensions {
    excludes(val: any): boolean;
    includesSome(arr: any[], name?: string): boolean;
}

interface StringAndNumberExtensions {
    toFloat(): number;
    toInt(): number;
}

declare global {
    interface String extends StringAndNumberExtensions, ArrayAndStringExtensions {
        isProfane(): boolean;
        hasLetters(): boolean;
        isFile(): string;
        toPascal(): string;
        removeSelectorSymbol(): string;
        toCamelCase(): string;
        toCapitalCase(type?: string): string;
    }
    interface Number extends StringAndNumberExtensions {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> extends ArrayAndStringExtensions {
        copy(): T[];
        reversed(): T[];
    }
    interface Object {
        enumName(value: number | string): string;
    }
}

export type Callback = (...args: any[]) => any;

export class TypeExtensions {
    implement(helper: Helper) {
        // For Array and String
        const arrayStringFunctions = [
            new BuiltProperty('excludes', function(val) { return !this.includes(val); }),
            new BuiltProperty('includesSome', function(arr, name?) { return helper.includesSome(this, arr, name); })
        ];

        // For String And Number
        const parseFunctions = [
            new BuiltProperty('toFloat', function() { return parseFloat(this); }),
            new BuiltProperty('toInt', function() { return parseInt(this); })
        ];

        const methodExtensions = [
            new CxPrototype(
                String.prototype,
                [
                    ...arrayStringFunctions,
                    ...parseFunctions,
                    new BuiltProperty('isProfane', function() { return helper.isProfane(this); }),
                    new BuiltProperty('hasLetters', function() { return /[a-zA-Z]/g.test(this); }),
                    new BuiltProperty('isFile', function(noRoute = false) { return helper.isFile(this, noRoute); }),
                    new BuiltProperty('toPascal', function() { return helper.toPascal(this); }),
                    new BuiltProperty('removeSelectorSymbol', function() { return helper.removeSelectorSymbol(this); }),
                    new BuiltProperty('toCamelCase', function() { return helper.camelize(this); }),
                    new BuiltProperty('toCapitalCase', function(type = 'first') { return helper.toCapitalize(this, type); })
                ]
            ),
            new CxPrototype(
                Array.prototype,
                [
                    ...arrayStringFunctions,
                    new BuiltProperty('copy', function() { return helper.copyArrayOfObjects(this); }),
                    new BuiltProperty('reversed', function() {return this.copy().reverse();})
                ]
            ),
            new CxPrototype(
                Number.prototype,
                [
                    ...parseFunctions
                ]
            ),
            new CxPrototype(
                Object.prototype,
                [
                    new BuiltProperty('enumName', function(value: number | string) { return helper.getEnumName(this, value); }),
                ]
            ),
        ];

        methodExtensions.forEach(proto => {
            proto.props.forEach(prop => {
                this.buildDefault(proto.type, prop.name, prop.callback);
            });
        });
    }

    buildDefault(type: any, name: string, callback: Callback) {
        Object.defineProperty(type, name, {
            value: callback,
            writable: true,
            configurable: true
        });
    }
}

class CxPrototype {
    type: any;
    props: BuiltProperty[];

    constructor(type: any, props: BuiltProperty[]) {
        this.type = type;
        this.props = props;
    }
}

class BuiltProperty {
    name: string;
    callback: Callback;

    constructor(name: string, callback: Callback) {
        this.name = name;
        this.callback = callback;
    }
}
