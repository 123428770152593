import './cx-faq-question.scss';
import { bindable, inject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';

@inject(Helper)

export class CxFaqQuestion {
    constructor(private helper) {
        this.helper = helper;
    }

    @bindable question;
    @bindable functionShowTable;
    private answer: HTMLElement;
    private icon: HTMLElement;
    faqQuestionElement: HTMLElement;
    questionElement: HTMLElement;

    toggle() {
        if (this.answer.classList.contains('answer-close')) {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        this.faqQuestionElement.classList.add('active');
        this.helper.toggleClasses(this.answer, 'answer-close', 'answer-open');
        this.helper.toggleClasses(this.questionElement, 'text-truncate', 'text-truncate-active');
        this.icon.innerText = 'arrow_drop_up';
    }

    close() {
        this.helper.toggleClasses(this.answer, 'answer-open', 'answer-close');
        this.helper.toggleClasses(this.questionElement, 'text-truncate-active', 'text-truncate');
        this.faqQuestionElement.classList.remove('active');
        this.icon.innerText = 'arrow_drop_down';
    }
}
