// Module
var code = `<template>
    <section id="privacy-policy" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">Privacy Policy</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'PRIVACY_POLICY_LAST_UPDATED'" class="text-end last-update">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_FIRST_PARAGRAPH'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DEFINITIONS_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DEFINITIONS_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_COLLECTION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_COLLECTION_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_HOW_WE_USE_YOU_INFORMATION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_HOW_WE_USE_YOU_INFORMATION_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_COOKIES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_COOKIES_CONTENT'" class="text-content margened-paragraph cookies">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_ANALYTICS_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_ANALYTICS_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_BEHAVIORAL_REMARKETING_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_BEHAVIORAL_REMARKETING_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_SHARING_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_SHARING_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_UNSUBSCRIBE_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_UNSUBSCRIBE_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_STORED_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_STORED_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_SECURITY_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_SECURITY_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_USER_DATA_RIGHTS_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_USER_DATA_RIGHTS_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHILD_PROTECTION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHILD_PROTECTION_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_RIGHT_ACCESS_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_RIGHT_ACCESS_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_GDPR_REGULATIONS_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_GDPR_REGULATIONS_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_RETENTION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_RETENTION_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHANGES_TO_PRIVACY_POLICY_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHANGES_TO_PRIVACY_POLICY_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CCPA_GUIDELINES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CCPA_GUIDELINES_CONTENT'" class="text-content">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;