// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/arrow-right.svg", import.meta.url);
// Module
var code = `<template>
    <div id="horizontal-list-container">
        <div class="horizontal-list" ref="listContainer">
            <slot></slot>
        </div>
        <div class="fade-out" if.bind="showRightArrow"></div>
        <img
            class="material-icons arrow right-arrow"
            click.trigger="scrollRight()"
            src="${___HTML_LOADER_IMPORT_0___}"
            loading="lazy"
            alt="next page"
        />
    </div>
</template>
`;
// Exports
export default code;