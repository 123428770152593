import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-input-search.scss';
import { bindable, autoinject } from 'aurelia-framework';

@autoinject()
export class CxInputSearch {
    @bindable placeholder = 'CX_SEARCH';
    @bindable label = 'CX_SEARCH';
    @bindable searchQuery;
    @bindable handleSearch = () => {};
    @bindable buttonHandler = () => {};
    @bindable inputClass: string = 'cx-form-control input-password';
    @bindable outlined: boolean = true;

    constructor(private pageContentAreaService: PageContentAreaService) {}
}
