import './cx-circular-progress.scss';
import { bindable, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';

@inject(Router, EventAggregator, Helper)
export class CxCircularProgress {
    constructor(router, EventAggregator, Helper) {
        this.router = router;
        this.eventAggregator = EventAggregator;
        this.helper = Helper;
    }

    @bindable size = 80;
    @bindable strokeWidth = 4;
    @bindable class: string;
    @bindable showLoadingText = true;
    @bindable loadingText = 'Loading...';

    sizeChanged;
    width;
    router;
    eventAggregator;
    helper;
    desktop: number = 992;

    attached() {
        this.width = this.helper.getWidth();
        this.handleEventSubscriptions();
        this.checkSizeByRouter();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async(payload) => {
            this.width = payload.width;
            this.checkSizeByRouter();
        });
    }

    checkSizeByRouter = () => {
        if (this.width <= this.desktop || this.router?.currentInstruction?.config?.name !== 'blog') return;
        this.size = 120;
    };
}
