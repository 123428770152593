// Module
var code = `<template>
    <div id="cx-file-input" class="d-flex flex-column flex-lg-row align-items-start align-items-lg-end file-selector-\${idCode}" ref="fileSelector">
        <mdc-select
            outlined
            class="cx-form-control text-regular cx-file-input--select w-100"
            value.bind="selectedDocumentType"
            show.bind="selectable && documentTypes.length > 0"
            disabled.bind="state === 'remove'"
            change.delegate="changeSelectHandler()"
        >
            <section data-simplebar class="lilac-scrollbar">
                <mdc-list>
                    <mdc-list-item class="text-small" repeat.for="documentType of documentTypes" value.bind="documentType">
                        \${helper.sanitizeHtml(documentType.name, true)}
                    </mdc-list-item>
                </mdc-list>
            </section>
        </mdc-select>        
        <div id="cx-file-input--input" class="\${uploading ? 'uploading-size' : ''} w-100 d-flex flex-column justify-content-end">
            <mdc-linear-progress
                class="linear-progress-file"
                open.bind="uploading"
                if.bind="uploading"
            ></mdc-linear-progress>
            <div class="position-relative cursor-pointer" click.delegate="handleUploadClick()">
                <mdc-text-field
                    outlined
                    autocomplete="off"
                    class="w-100 input-password cx-form-control cursor-pointer \${ uploading ? 'cx-form-control--success' : '' }"
                    required.bind="true"
                    placeholder.bind="text" 
                    value.bind="fileName"
                    disabled
                    class.bind="getElementStateClass(state)" 
                ></mdc-text-field>
                <span class="material-icons-outlined text-cx-purple upload-icon \${ uploading ? 'disabled' : 'cursor-pointer' } position-absolute">upload</span>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;