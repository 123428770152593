// Module
var code = `<template>
    <div id="subscription-cashback">
        <cx-order-section 
            title.bind="'Cashback Earned'"
            type="Cashback"
            operation-type.bind="operationType"
            element.ref="orderSectionCashback"
            create-button.bind="false"
            back-route.bind="backRoute"
            table-headers.bind="tableHeaders"
            icon-path-row.bind="iconPathRow"
            sort.bind="true"
        ></cx-order-section>
    </div>
</template>
`;
// Exports
export default code;