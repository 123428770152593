// Module
var code = `<template>
    <div id="cx-blog-search" class="w-100 bordertry">
        <div class="mb-2-3 joined-input" ref="inputBox">
            <div class="position-relative d-inline-block double-input-search">
                <mdc-text-field
                    placeholder="\${'CX_SEARCH_BLOG_ARTICLE' | siteStrings}..."
                    value.bind="searchValue"
                    class="w-100 cx-form-control"
                    keyup.delegate="keyUpFunction(\$event)"
                    outlined
                >
                    <span mdc-text-field-icon leading class="material-icons">search</span>
                </mdc-text-field>
                <div class="close-icon close-icon-phone-input material-icons-round position-absolute" click.delegate="buttonHandler()" show.bind="searchValue">close</div>
            </div><div class="position-relative d-inline-block double-input-extra-field">
                <mdc-select
                    outlined
                    class="p-0 cx-dropdown align-self-end"
                    value.bind="optionValue"
                    selected-index.bind="selectedIndex"
                    change.delegate="changeSelectHandler(\$event)">
                    <span mdc-select-icon>\${'CX_SORT_BY' | siteStrings}:</span>
                    <section data-simplebar>
                        <mdc-list>
                            <mdc-list-item repeat.for="option of selectOptions" value.bind="option.name" disable-ripple="false">
                                \${ option.keyName | siteStrings }
                            </mdc-list-item>
                        </mdc-list>
                    </section>
                </mdc-select>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;