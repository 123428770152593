// Module
var code = `<template>
    <section id="crypto-asset-statements" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title ">
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_TITLE'" class="text-start">
                </cx-page-content-area>
            </div>
        </div>
        <div class="cas-main-content text-medium">
            <div class="cas-body">
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_LAST_UPDATED'" class="text-end last-update custom-last-update">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DEFINITION_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DEFINITION_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INFORMATION_COLLECTION_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INFORMATION_COLLECTION_CONTENT'" class="text-content custom-information-collection content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_WE_USE_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_WE_USE_CONTENT'" class="text-content custom-we-use content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COOKIES_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COOKIES_CONTENT'" class="text-content custom-cookies content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_ANALYTICS_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_ANALYTICS_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_BEHAVIORAL_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_BEHAVIORAL_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INFORMATION_SHARING_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INFORMATION_SHARING_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_OPTING_OUT_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_OPTING_OUT_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DATA_STORED_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DATA_STORED_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_SECURITY_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_SECURITY_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_USER_DATA_RIGHTS_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_USER_DATA_RIGHTS_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_CHILD_PROTECTION_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_CHILD_PROTECTION_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_RIGHT_TO_ACCESS_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_RIGHT_TO_ACCESS_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_NOTICE_EU_RESIDENTS_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_NOTICE_EU_RESIDENTS_CONTENT'" class="text-content custom-notice-ue-residents content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DATA_RETENTION_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_DATA_RETENTION_CONTENT'" class="text-content content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_CHANGES_PRIVACY_POLICY_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_CHANGES_PRIVACY_POLICY_CONTENT'" class="text-content content-mode-desktop custom-changes-privacy-policy">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_NOTICE_CALIFORNI_RESIDENTS_TITLE'" class="text-title font-bold content-mode-desktop">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_NOTICE_CALIFORNI_RESIDENTS_CONTENT'" class="text-content hide-botton-line content-mode-desktop">
                </cx-page-content-area>

                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INTRODUCTION_TITLE'" class="text-title font-bold content-mode-mobile">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_INTRODUCTION_CONTENT'" class="text-content content-mode-mobile">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COUNTER_NOTIFICATION_1_TITLE'" class="text-title font-bold content-mode-mobile">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COUNTER_NOTIFICATION_1_CONTENT'" class="text-content custom-counter-notification content-mode-mobile">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COUNTER_NOTIFICATION_2_TITLE'" class="text-title font-bold content-mode-mobile">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'CX_CRYPTO_ASSET_STATEMENTS_COUNTER_NOTIFICATION_2_CONTENT'" class="text-content custom-counter-notification content-mode-mobile">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;