import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-sticky-post.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';

export class CxStickyPost {
    constructor(private pageContentAreaService: PageContentAreaService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    @bindable blogs;
    @bindable title;
    @bindable blogRoute;
    baseAwsEndpoint: string;
    blog;
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async tagClickHandler(tag) {
        await this.parent.tagClickHandler({
            name: tag,
            slug: tag.toLowerCase().replace(' ', '-'),
            activate: true
        });
    }
}
