import './cx-veriff-progress.scss';
import { bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';

export class CxVeriffProgress {
    constructor(private pageContentAreaService: PageContentAreaService) {}

    @bindable flowPage: boolean;
    @bindable verified : boolean;
}
