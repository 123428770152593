// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/green_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/red_fail.svg", import.meta.url);
// Module
var code = `<template>
    <main id="cx-password-input">
        <label class="font-medium heading \${labelClasses || ''}" skeleton-loading="is-loading.bind:pageLoading;">
            <span if.bind="backButton" click.delegate="backButton()"
                class="material-icons cursor-pointer">arrow_left</span>
            \${name}
        </label>
        <div class="position-relative">
            <mdc-text-field
                outlined autocomplete="on"
                value.two-way="password & validate" 
                required.bind="true"
                placeholder="Enter \${name.toLowerCase()}" 
                type="password"
                class="w-100 input-password cx-form-control \${parent.isRequesting ? 'pe-none' : ''} \${showGreenCheckMark ? 'cx-form-control--success' : showErrorCheckMark ? 'cx-form-control--error' : ''}"
                keyup.delegate="updatedOnKeyPress()"
                focusin.delegate="onFocusIn()"
                focusout.delegate="checkValidation()"
                view-model.ref="input"
                skeleton-loading="is-loading.bind:pageLoading;">
            </mdc-text-field>
            <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon" alt="green check" if.bind="showGreenCheckMark"
                loading="lazy">
            <img src="${___HTML_LOADER_IMPORT_1___}" class="circle-icon" alt="error" if.bind="showErrorCheckMark" loading="lazy">
            <mdc-circular-progress if.bind="showMiniSpinner" class="mini-spinner-icon" size="25"
                stroke-width="2"></mdc-circular-progress>
            <p class="cursor-pointer forgot-text ul-hover" show.bind="forgotDelegate && isCurrent"
                click.delegate="clickForgot()" skeleton-loading="is-loading.bind:pageLoading;">\${'CX_FORGOT_YOUR_PASSWORD' | siteStrings}</p>
            <div if.bind="showValidator" class="validator outer-validator \${ !password ? 'hidden' : ''}">
                <cx-validator-progress value.bind="password"
                    validator-property.two-way="validatorProperty"></cx-validator-progress>
            </div>
        </div>
    </main>
</template>
`;
// Exports
export default code;