// Module
var code = `<template>
    <div id="cx-gallery-dialog">
        <mdc-dialog view-model.ref="dialog" mdcdialog:closing.trigger="handleClosing()" tabindex="-1">
            <div class="mdc-dialog__header">
                <span click.delegate="close()" class="material-icons close-icon text-black cursor-pointer" tabindex="0">
                    close
                </span>
            </div>
            <div class="mdc-dialog__content d-flex flex-row">
                <div class="carousel-control d-flex align-items-center \${ showLeftArrow ? 'cursor-pointer' : '' }">
                    <span if.bind="showLeftArrow" class="material-icons text-purple" alt="arrow-left" click.delegate="backImage()">arrow_back_ios</span>
                </div>                
                <div class="carousel-inner d-flex justify-content-center align-items-center">
                    <div class="carousel-item \${ \$index == index ? 'active' : '' }" repeat.for="image of imagesList">
                        <img class="d-block w-100" tabindex="0" src="\${ environment }Image/\${ image.url }" alt="attached image" loading="lazy">
                    </div>
                </div>
                <div class="carousel-control d-flex align-items-center \${ showRightArrow ? 'cursor-pointer' : '' }">
                    <span if.bind="showRightArrow"  class="material-icons text-purple" alt="arrow-right" click.delegate="nextImage()">arrow_forward_ios</span>
                </div>
            </div>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;