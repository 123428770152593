import { Exchange } from 'services/models/purchase-flow/exchange';
import './cx-payment-information.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class CxPaymentInformation {
    @bindable exchange: Exchange;
    paymentExpandableRef;
    expandableOpen = false;

    constructor(private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService) {}

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Purchase Flow')?.id);
    }

    onToggle() {
        this.paymentExpandableRef.toggle();
    }
}
