// Module
var code = `<template>
    <div id="cx-footer-menu">
        <cds-menu 
            typeahead-controller.bind="typeaheadController" 
            menuopening.trigger="openMenu = true"
            menuclosing.trigger="openMenu = false">
            <a slot="menu-anchor" class="menu-anchor">
                \${link.keyName | siteStrings}
                <span class="material-icons">
                    \${openMenu ? 'arrow_drop_up' : 'arrow_drop_down'}
                </span>
            </a>
            <md-menu-item if.bind="link.showFilter" keep-open="true" slot="menu-options" class="menu-options-filter">
                <cds-option-box>
                    <cds-text-field
                        leading-icon="search"
                        variant="filled"
                        value.two-way="keyword & debounce: 400"
                        label="\${'CX_FILTER_PAGES' | siteStrings}"
                        stay-open-on-focusout="true"
                        stay-open-on-outside-click="true"
                    >
                    </cds-text-field>
                </cds-option-box>
            </md-menu-item>
            <md-menu-item slot="menu-options" repeat.for="item of filteredItems">
                <cds-option-box click.trigger="navigateToRoute(item)">\${item.name}</cds-option-box>
            </md-menu-item>
        </cds-menu>
    </div>
</template>
`;
// Exports
export default code;