import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-blog-search.scss';
import { autoinject, observable, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class CxBlogSearch {
    @bindable searchValue;
    @bindable selectedIndex: number;
    @bindable optionValue;
    @bindable keyUpFunction;
    @bindable changeSelectHandler;
    @bindable buttonHandler;

    @observable page = 1;
    @observable category;

    selectOptions = [
        { name: 'Popular', keyName: 'CX_POPULAR' },
        { name: 'Oldest', keyName: 'CX_OLDEST' },
        { name: 'Newest', keyName: 'CX_NEWEST' },
        { name: 'Alphabetical', keyName: 'CX_ALPHABETICAL' }
    ];

    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService
    ) { }

    async activate(params) {
        if (params.page) this.page = parseInt(params.page);
        if (params.category) this.category = params.category;
    }

    async attached() {
        this.overrideMdc();
    }

    overrideMdc() {
        const selectArrow = document.getElementsByClassName('mdc-select__dropdown-icon');
        const newArrowIcon = '<span class="arrow-icon material-icons">expand_more</span>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }
}
