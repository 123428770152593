export class PackageStatusValueConverter {
    toView(value: string) {
        if (!value || value === 'created') {
            return 'Created';
        } else if (value === 'pre_transit') {
            return 'Accepted';
        } else if (value === 'in_transit') {
            return 'In Progress';
        } else if (value === 'out_for_delivery') {
            return 'Shipped';
        } else if (value === 'delivered') {
            return 'Delivered';
        } else {
            return 'In Transit';
        }
    }
}
