// Module
var code = `<template>
    <div id="cx-live-rates">
        <div class="container p-0 exchange-title font-medium text-start">
            Live Exchange Rates
        </div>
        <div class="container p-0 table-responsive">
            <table class="w-100">
                <thead>
                    <tr class="header text-normal">
                        <th>Top cryptocurrencies</th>
                        <th>Amount</th>
                        <th>\${width <= 768 ? '24h %' : 'Change (24h)'}</th>
                        <th class="d-none">\${highlightedRates} \${mostPopularCurrencies}</th>
                    </tr>
                </thead>
                <tbody class="content-table text-medium" if.bind="isLoading">
                    <div>
                        <tr class="hightlighted">
                            <td><div class="skeleton"></div></td>
                            <td><div class="skeleton"></div></td>
                            <td><div class="skeleton"></div></td>
                        </tr>
                        <tr class="\${\$index % 2 == 0 ? 'regular-odd' : 'regular-even'} skeleton" repeat.for="_ of ['','','','']">
                            <td><div class="skeleton"></div></td>
                            <td><div class="skeleton"></div></td>
                            <td><div class="skeleton"></div></td>
                        </tr>
                    </div>
                </tbody>
                <tbody class="content-table text-medium" repeat.for="crypto of filteredCryptoList" if.bind="\$index < 5 && !isLoading">
                    <div>
                        <tr class="hightlighted" if.bind="\$index === 0">
                            <td><img src="\${baseAwsEndpoint}\${crypto.imagePath}" alt="currency-flag" loading="lazy" class="aling-items-left currency-image">\${crypto.description}</td>
                            <td>1</td>
                            <td></td>
                        </tr>
                        <tr class="\${\$index % 2 == 0 ? 'regular-odd' : 'regular-even'}" if.bind="\$index > 0">
                            <td><img src="\${baseAwsEndpoint}\${crypto.imagePath}" alt="currency-flag" loading="lazy" class="aling-items-left currency-image">\${crypto.description}</td>
                            <td>\${crypto.symbol} \${highlightedRates[crypto.code]| numberFormat:width <= 992 ? '0,0.00' : '0,0.000000'}</td>
                            <td class="\${ upwardTrend ? 'upward-trend' : 'downward-trend' }">
                                <span class="material-icons">\${ upwardTrend ? 'add' : 'remove'}</span>
                                <span if.bind="width > 992" class="pertentage">\${loadVariation(\$index)}%</span>
                                <span else>\${loadVariation(\$index, true)}%</span>
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
</template>
`;
// Exports
export default code;