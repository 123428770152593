import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class SignalRTrackingService {
    path = 'SignalRTracking';

    constructor(private api: ApiService) { }

    async processSignalRTracking(sendNotification: boolean) {
        return await this.api.doPut(`${this.path}/ProcessSignalRTracking/${sendNotification}`, {});
    }
}
