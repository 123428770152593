// Module
var code = `<template>
    <div if.bind="isVerificationTag == 'true'" id="cx-verification-status-tag">
        <div class="\${ tagStyling.toLowerCase() } tag d-flex align-items-center">
            <span class="\${tagIconOutlined == 'true' ? 'material-icons-outlined' : 'material-icons' }  \${tagIconStyling}">
                \${tagIcon}
            </span>
            <span class="w-100 d-inline-block ">\${status}</span>
        </div>
    </div>
    <div else id="cx-status-tag">
        <span class="w-100 d-inline-block \${ getStatusClass() } tag">
            <span>\${status}</span>
        </span>
    </div>
</template>
`;
// Exports
export default code;