// Module
var code = `<template>
    <div id="cx-pagination">
        <ul class="pagination">
            <li if.bind="page.toString()!=1 && page.toString() !== '0' && pages.length > 4" class="page-item">
                <cx-touch-target expanded>
                    <a class="d-flex justify-content-center align-items-center page-link navigation-arrow" href=""
                        click.delegate="pagePrevious()" aria-label="Previous">
                        <span class="visually-hidden">Previous</span>
                        <span aria-hidden="true">❮</span>
                    </a>
                </cx-touch-target>
            </li>
            <li if.bind="pages.length > 1" class="page-item" repeat.for="i of pages">
                <a if.bind="showDots" class="\${page.toString() === i  ? 'highlighted' : page === 0 ? 'highlighted pe-none' : ''} page-dots material-icons-outlined" href="" click.delegate="changePage(\$index, i)"></a>
                <div if.bind="!showDots">
                    <a
                        if.bind="i!='...'"
                        class="d-flex justify-content-center align-items-center page-link page-number \${page.toString() === i  ? 'highlighted' : page === 0 ? 'highlighted pe-none' : ''}"
                        href=""
                        click.delegate="changePage(\$index, i)">
                        \${i}
                    </a>
                    <mdc-text-field
                        id="pagination"
                        class="input-pagination"
                        if.bind="i=='...'"
                        outlined
                        type="text"
                        placeholder.bind="'...'"
                        value.bind="inputPage & debounce:500"
                    ></mdc-text-field>
                </div>
            </li>
            <li if.bind="page.toString()!=pages.slice(-1).pop().toString() && page.toString() !== '0' && pages.length > 4" class="page-item">
                <cx-touch-target expanded>
                    <a class="d-flex justify-content-center align-items-center page-link navigation-arrow" href="" click.delegate="pageNext()" aria-label="Next">
                        <span class="visually-hidden">Next</span>
                        <span aria-hidden="true">❯</span>
                    </a>
                </cx-touch-target>
            </li>
        </ul>
    </div>
</template>
`;
// Exports
export default code;