// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/exclamation-blue.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-newsletter-prompt" ref="newsletterPromptElement">
        <div class="banner-box text-regular" id="baner-box-newsletter-prompt">
            <img
                src="${___HTML_LOADER_IMPORT_0___}"
                alt="Notification"
                loading="lazy"
            >
            <span class="text-light-black">
                Would you like to subscribe to receive exclusive coupons, promotions and updates on related products? Please
                <span class="text-decoration-underline cursor-pointer text-light-black" click.delegate="optIn()">click here</span>.
            </span>
            <div class="text-large cursor-pointer text-light-black" click.delegate="dismissPrompt()">
                ✕
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;