export interface IMdcTooltip {
    tooltip: HTMLDivElement
}

export class TooltipStyling {
    styleMap = new Map([
        ['BELOW', 'arrow-position-below'],
        ['BELOW_START', 'arrow-position-below__start']
    ]);

    setArrowPosition(element: IMdcTooltip, pos: 'BELOW' | 'BELOW_START') {
        switch (pos) {
            case 'BELOW_START': {
                const style = this.styleMap.get(pos);
                if (!element.tooltip.classList.contains(style)) element.tooltip.classList.add(style);
            }
            // eslint-disable-next-line no-fallthrough
            case 'BELOW': {
                const style = this.styleMap.get('BELOW');
                if (!element.tooltip.classList.contains(style)) element.tooltip.classList.add(style);
                break;
            }
        }
    }

    addWidthClass(element: IMdcTooltip) {
        if (!element.tooltip.classList.contains('mdc-tooltip--tooltip-width')) element.tooltip.classList.add('mdc-tooltip--tooltip-width');
    }

    setMaxWidth(element: IMdcTooltip, maxWidth: number) {
        element.tooltip.style.setProperty('--tooltip-width', `${maxWidth}px`);
    }
}
