import './cx-veriff.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Person, StartVerificationRequest } from 'services/models/veriff/startVerificationRequest';
import { StartVerificationResponse } from 'services/models/veriff/startVerificationResponse';
import { CustomerService } from 'services/customer-service';
import { User } from 'services/models/user/user';
import { SignalrService } from 'services/signalr-service';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { HubConnection } from '@microsoft/signalr';
import { ToastType } from 'services/models/toast';
import { OpenedVeriffDialogEvent, VeriffVerificationEvent } from 'resources/constants';

@autoinject()
export class CxVeriff {
    constructor(
        private customerService: CustomerService,
        private signalRService: SignalrService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService) { }

    @bindable user: User;
    @bindable hiddenButton: boolean = false;
    connection: HubConnection;
    loading: boolean;
    triggeredNotification: boolean;
    openedVeriffDialog: boolean;

    async startVerification() {
        if (this.loading && !this.hiddenButton) return;

        this.loading = true;
        this.triggeredNotification = false;

        try {
            this.connection = await this.signalRService.getSignalRConnection();
            const verification = new StartVerificationRequest();
            verification.person = new Person();
            verification.person.firstName = this.user.firstName;
            verification.person.lastName = this.user.lastName;
            this.eventAggregator.publish(VeriffVerificationEvent, { user: this.user });

            const startResponse = await this.customerService.startVerification(this.user.id, verification);
            let veriffFrame;

            if (startResponse.status === 'success') {
                this.openedVeriffDialog = true;
                this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: this.openedVeriffDialog });
                veriffFrame = createVeriffFrame({
                    url: startResponse.verification.url,
                    onEvent: async(msg) => {
                        if (msg) {
                            switch (msg) {
                                case 'CANCELED': {
                                    this.loading = false;
                                    break;
                                }
                                case 'FINISHED': {
                                    const response = await this.customerService.getDocumentDecision(startResponse.verification.id);
                                    this.handleVeriffResult(response, veriffFrame);
                                    break;
                                }
                            }
                            this.openedVeriffDialog = false;
                            this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: this.openedVeriffDialog });
                        }
                    }
                });
            } else {
                this.toastService.showToast('Error', 'There has been an error processing your request, please try again in a few minutes', 'error');
            }

            this.connection.on('VeriffResolution', (veriffResolution: StartVerificationResponse) =>
                this.handleVeriffResult(veriffResolution, veriffFrame));

            this.connection.invoke('SubscribeVeriffResolution', this.user.id);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    handleVeriffResult(documentDecision, veriffFrame) {
        this.user.idVerified = documentDecision.verification?.status === 'approved';
        this.eventAggregator.publish(VeriffVerificationEvent, { user: this.user });
        if (this.user.idVerified && !this.triggeredNotification) {
            this.triggeredNotification = true;
            this.toastService.showToast(undefined, 'Your ID has been verified successfully', ToastType.SUCCESS);
        } else if (!this.triggeredNotification) {
            this.triggeredNotification = true;
            const errorMessage = documentDecision.verification?.reason?.toLowerCase()?.includes('velocity')
                ? 'You have already ID verified on another account. Please log into that account to continue. Or email support@chicksx.com'
                : 'There has been a problem with your ID Verification, please get in contact at support@chicksx.com';
            this.toastService.showToast(undefined, errorMessage, ToastType.ERROR);
        }
        veriffFrame?.close();
    }
}
