import { PageContentArea } from 'services/models/page/pageContentArea';
import './get-started.scss';
import { bindable } from 'aurelia-framework';
import { OperationType } from 'services/models/purchase-flow/exchange';

export class GetStarted {
    @bindable headerText: PageContentArea;
    @bindable descriptionText: PageContentArea;
    @bindable banner: string;
    @bindable exchangeType: OperationType;

    async openIntercom() {
        if (window.Intercom) {
            window.Intercom('show');
        }
    }
}
