// Module
var code = `<template>
    <div id="cx-pdf-viewer">
        <mdc-dialog view-model.ref="dialog" mdcdialog:closing.trigger="handleClosing()" tabindex="-1">
            <div class="mdc-dialog__header">
                <span click.delegate="close()" class="material-icons close-icon text-black cursor-pointer" tabindex="0">
                    close
                </span>
            </div>
            <div class="mdc-dialog__content d-flex flex-row">
                <embed id="pdf-viewer" class="pdf-container" src.bind="pdfContent.imageData"/>
            </div>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;