import './locations.scss';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class Locations {
    constructor(private router: Router) {}

    configureRouter(config, router) {
        config.options.pushState = true;
        this.router = router;
        config.map([
            {
                route: '',
                redirect: 'mississauga',
            },
            {
                route: '/mississauga',
                name: 'mississauga',
                moduleId: PLATFORM.moduleName('pages/locations/mississauga/mississauga'),
                title: 'Mississauga'
            },
            {
                route: '/calgary',
                name: 'calgary',
                moduleId: PLATFORM.moduleName('pages/locations/calgary/calgary'),
                title: 'Calgary'
            },
            {
                route: '/vancouver',
                name: 'vancouver',
                moduleId: PLATFORM.moduleName('pages/locations/vancouver/vancouver'),
                title: 'Vancouver'
            }
        ]);

        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
    }
}
