// Module
var code = `<template>
    <div id="cx-search-drawer">
        <button mdc-button class="\${buttonStyle ? buttonStyle : 'button-blue-filled'} search-button" type="button" click.delegate="handleDrawerNavigationClick('open')">
            <mdc-icon class="material-icons">
                search
            </mdc-icon>
        </button>
        <mdc-drawer id="modal" type="modal" view-model.ref="drawer" class="drawer \${drawerClass}">
            <mdc-drawer-content id="drawer-content">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="h-100">
                        <button mdc-button click.delegate="handleDrawerNavigationClick('close')" class="close-button button-transparent">
                            <mdc-icon mdc-icon class="material-icons">close</mdc-icon>
                            Close
                        </button>
                    </div>
                    <div class="custom-container">
                        <mdc-text-field
                            placeholder="Search"
                            class="input-password mdc-text-field"
                            value.two-way="search"
                            keyup.delegate="onChange() & debounce:500"
                        >
                            <mdc-icon mdc-text-field-icon leading class="material-icons opacity-75 text-white search-icon-input">
                                <span>search</span>
                            </mdc-icon>
                            <mdc-icon mdc-text-field-icon trailing class="material-icons opacity-75 text-white" if.bind="search" click.delegate="clearSearch()">
                                <span>close</span>
                            </mdc-icon>
                        </mdc-text-field>
                    </div>
                </div>
                <cx-circular-progress if.bind="searchLoading" class="spin-loader my-4"></cx-circular-progress>
                <section show.bind="!searchLoading && search">
                    <slot></slot>
                </section>
            </mdc-drawer-content>
        </mdc-drawer>
    </div>
</template>
`;
// Exports
export default code;