// Module
var code = `<template>
    <div id="cx-paragraph-image" class="\${class}">
        <img class="banner-img \${textFirst ? 'second' : 'first'}" src="\${image}" alt="Security" loading="lazy">
        <div class="container-text-icon">
            <img if.bind="icon" class="icon d-none d-xl-flex" src="\${icon}" alt="Security" loading="lazy">
            <div class="d-flex flex-row align-items-center gap-1 d-flex d-xl-none">
                <img if.bind="icon" class="icon" src="\${icon}" alt="Security" loading="lazy">
                <cx-page-content-area key.bind="caption" class="caption-title"></cx-page-content-area>
            </div>
            <div class="\${textFirst ? 'first' : 'second'} \${containerClass}">
                <cx-paragraph-title
                    caption.bind="caption"
                    text.bind="text"
                    inverted-color.bind="invertedColor"
                    class="d-none d-xl-flex"
                ></cx-paragraph-title>
                <div class="text-medium caption-text d-flex d-xl-none">
                    <cx-page-content-area key.bind="text"></cx-page-content-area>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>`;
// Exports
export default code;