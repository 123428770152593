// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/exclamation-blue.svg", import.meta.url);
// Module
var code = `<template>
    <div id="payment-information">
        <mdc-expandable class="purchase-flow-expandable verification-expandable" view-model.ref="paymentExpandableRef" open.two-way="expandableOpen">
            <div class="text-large-plus  d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="Upload" loading="lazy" width="24" height="24">
                <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_PAYMENT_INFORMATION_TITLE'"></cx-page-content-area>
            </div>
            <div class="text-regular text-darkergray" show.bind="expandableOpen">
                <div class="col-12" if.bind="exchange.selectedPaymentMethod.paymentMethod.reference == 'interac'">
                    <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_INTERAC_PARAGRAPH'"></cx-page-content-area>
                </div>
                <div class="col-12" if.bind="exchange.receivingPaymentMethod.paymentStepNotes">
                    <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_PAYMENT_STEP_NOTES'"></cx-page-content-area>
                </div>
                <section data-simplebar class="col-12 mt-2 info-scrollbar purple-scrollbar">
                    <div class="text-backface-visibility" innerhtml.bind="exchange.selectedPaymentMethod.paymentMethod.reference == 'interac' ? exchange.selectedPaymentMethod.paymentStepNotes : exchange.receivingPaymentMethod.paymentStepNotes"></div>
                </section>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;