import './cx-filter.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxFilter {
    @bindable iconPath: string;
    @bindable options: string[];
    @bindable materialIcon: string;
    @bindable label = 'Filter';
    @bindable type: string;
    @bindable value: string;
    @bindable changeFunction = () => { };
    @bindable required = false;
    @bindable forceDefault = false;
    @bindable inputClass: string;
    filterSelector: HTMLElement;

    attached() {
        this.overrideMdc();
        if (!this.forceDefault || this.value || !this.options) return;
        this.value = this.options[0];
    }

    overrideMdc() {
        const selectArrow = this.filterSelector?.querySelector('.mdc-select .mdc-select__dropdown-icon');
        const newArrowIcon = '<span class="arrow-icon material-icons">arrow_drop_down</span>';
        if (!selectArrow) return;
        selectArrow.innerHTML = newArrowIcon;
    }
}
