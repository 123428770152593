import { bindable, observable, autoinject } from 'aurelia-framework';
import {
    Currency,
    PaymentMethodWebsite,
} from 'services/models/purchase-flow/exchange';
import './cx-nav-preorder-mobile.scss';
import { getAWSBucketEndpoint } from 'environment';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Language } from 'services/models/language/language';
import { LanguageService } from 'services/language-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { LanguageChangedEvent, CurrencyChangedEvent } from 'resources/constants';
import SimpleBar from 'simplebar';

interface Option {
    id: string;
    title: string;
    auxname: string;
    image?: string;
    childs: Option[];
}

@autoinject()
export class CxNavPreorderMobile {
    @bindable cryptoList: Currency[] = [];
    @bindable currencyList: Currency[] = [];
    @bindable paymentMethodsList: PaymentMethodWebsite[] = [];
    @bindable filterInput: string = '';
    @bindable currentCurrency: Currency;
    @bindable languageList: Language[] = [];
    @bindable currentLanguage: Language;

    @observable selectedOption: Option;
    private selectedOptionAux: Option;
    currencyExpandable;
    languageExpandable;

    private readonly options: Option = {
        id: 'initial',
        title: 'initial',
        auxname: 'initial',
        childs: [
            {
                id: 'buy-crypto',
                title: 'CX_BUY_CRYPTO',
                auxname: 'Buy Crypto',
                childs: [],
            },
            {
                id: 'sell-crypto',
                title: 'CX_SELL_CRYPTO',
                auxname: 'Sell Crypto',
                childs: [],
            },
            {
                id: 'exchange',
                title: 'CX_EXCHANGE',
                auxname: 'Exchange',
                childs: [
                    {
                        id: 'exchange-fiat',
                        title: 'CX_EXCHANGE_FIAT',
                        auxname: 'Exchange Fiat',
                        childs: [],
                    },
                    {
                        id: 'exchange-crypto',
                        title: 'CX_EXCHANGE_CRYPTO',
                        auxname: 'Exchange Crypto',
                        childs: [],
                    }
                ],
            },
            {
                id: 'currency',
                title: 'USD',
                auxname: 'Currency',
                childs: [],
            },
            {
                id: 'language',
                title: 'ENG',
                auxname: 'Language',
                childs: [],
            },
        ],
    };

    optionsStack: Option[] = [];

    private baseAwsEndpoint: string;
    private languageAwsEndpoint: string;
    private basePaymentMethodsEndpoint: string;

    navbarCloseSubscriber;
    languageChangedSubscriber;
    currencyChangedSubscriber;

    containerElement: HTMLElement;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private languageService: LanguageService,
        private pageContentAreaService: PageContentAreaService
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
        this.basePaymentMethodsEndpoint =
            getAWSBucketEndpoint('payment-methods');
        this.languageAwsEndpoint = getAWSBucketEndpoint('languages');
    }

    attached() {
        this.handleEventSubscriptions();
        this.containerElement = document.getElementById(
            'selected-content'
        );
    }

    detached() {
        this.navbarCloseSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.navbarCloseSubscriber = this.eventAggregator.subscribe(
            'navbar-close',
            () => {
                this.resetOptions();
            }
        );
        this.languageChangedSubscriber = this.eventAggregator.subscribe(LanguageChangedEvent, payload => {
            if (!payload.languageSelected) return;
            this.setOptionTitle('language', payload.languageSelected.name);
        });
        this.currencyChangedSubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            if (!payload.currencySelected) return;
            this.setOptionTitle('currency', payload.currencySelected.code);
        });
    }

    filterInputChanged() {
        if (this.filterInput === '') {
            this.selectedOption.childs = structuredClone(
                this.selectedOptionAux.childs
            );
            return;
        }
        this.selectedOption.childs = this.filterOptions(this.selectedOptionAux, this.filterInput);
    }

    paymentMethodsListChanged() {
        this.fillOptions();
        this.selectedOption = structuredClone(this.options);
        this.selectedOptionAux = structuredClone(this.selectedOption);
    }

    fillOptions() {

        if (this.cryptoList) this.options.childs[0].childs = this.options.childs[1].childs = this.orderCyrptoByTrending(this.cryptoList);

        if (this.cryptoList && this.currencyList) {
            const cryptoMap = this.orderCyrptoByTrending(this.cryptoList);
            const currencyMap = this.orderCurrencyByTrendingList(this.currencyList);
            const languageMap = this.formattingLanguageList(this.languageList);

            this.options.childs[2].childs[1].childs = cryptoMap.map(
                (crypto) => ({
                    ...crypto,
                    childs: !this.isAnSeparatorOption(crypto.id) ? cryptoMap : [],
                })
            );

            this.options.childs[2].childs[0].childs = currencyMap.map(
                (crypto) => ({
                    ...crypto,
                    childs: !this.isAnSeparatorOption(crypto.id) ? currencyMap : [],
                })
            );

            this.options.childs[3].childs = currencyMap.map(
                (currency) => ({
                    ...currency,
                })
            );

            this.options.childs[4].childs = languageMap.map(
                (language) => ({
                    ...language,
                })
            );
        }
    }

    selectOption(option: Option) {
        if (!this.selectedOption || !this.selectedOption.childs || this.isAnSeparatorOption(option.id)) {
            return;
        }

        this.optionsStack.push(this.selectedOptionAux);

        if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-crypto') {
            this.filterAndSetSelectedOption(option, this.cryptoList);
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-fiat') {
            this.filterAndSetSelectedOption(option, this.currencyList);
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'language') {
            this.selectLanguage(this.languageList.find(x => x.hrefLang === option.id));
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'currency') {
            this.selectCurrency(this.currencyList.find(x => x.code === option.title));
        } else {
            this.selectedOption = option;
            this.selectedOptionAux = structuredClone(this.selectedOption);
        }

        if (this.filterInput !== '') {
            this.selectedOption.childs = this.selectedOptionAux.childs.filter(
                (child) => child.title.toLowerCase().includes(this.filterInput.toLowerCase())
            );
        }

        const { queryParams, objectParams } = this.getQueryParams(option);
        if (queryParams) {
            this.router.navigate('/trade' + queryParams);
            if (this.router.currentInstruction.config.route.includes('trade')) this.eventAggregator.publish('new-order-from-navbar', objectParams);
            this.eventAggregator.publish('trade-page-redirect');
            this.resetOptions();
        }
    }

    filterAndSetSelectedOption(option: Option, list = []) {
        const filteredExchangeOption = option;

        filteredExchangeOption.childs = list
            .filter((item) => ![item.reference, item.code].includes(option.id))
            .filter((item) => item.code.toLowerCase().includes(this.filterInput.toLowerCase()));

        if (this.optionsStack.length > 2) {
            if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-crypto') filteredExchangeOption.childs = this.orderCyrptoByTrending(filteredExchangeOption.childs, false);
            if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-fiat') filteredExchangeOption.childs = this.orderCurrencyByTrendingList(filteredExchangeOption.childs);
        }

        this.selectedOption = filteredExchangeOption;
        this.selectedOptionAux = structuredClone(this.selectedOption);
    }

    getQueryParams(option:Option) {
        if (
            this.optionsStack.find(({ id }) => ['buy-crypto', 'sell-crypto'].includes(id)) &&
            this.optionsStack.length === 3
        ) {
            const action = this.optionsStack[1].id === 'buy-crypto' ? 'receive' : 'give';
            const queryParams = `?${action}=${this.optionsStack[2].id}&method=${option.id}`;
            return { queryParams, objectParams: { [action]: this.optionsStack[2].id, method: option.id } };
        }

        if (this.optionsStack.find(({ id }) => id === 'exchange') && this.optionsStack.length === 4) {
            const queryParams = `?give=${this.optionsStack[3].id}&receive=${option.id}`;
            return { queryParams, objectParams: { give: this.optionsStack[3].id, receive: option.id } };
        }

        return { queryParams: '', objectParams: null };
    }

    resetOptions() {
        this.selectedOption = this.selectedOptionAux = structuredClone(
            this.options
        );
        this.filterInput = '';
        this.optionsStack = [];
    }

    goBack() {
        if (this.optionsStack.length === 0) return;
        this.selectedOption = this.optionsStack.pop();
        this.selectedOptionAux = structuredClone(this.selectedOption);
        this.selectedOption.childs = this.filterOptions(this.selectedOptionAux, this.filterInput);
    }

    showGoBackButton(): boolean {
        return this.optionsStack.length > 0;
    }

    formattingCurrencyList(currency: Currency[]) {
        return currency?.map(curr => ({
            id: curr.reference || curr.code,
            title: curr.code,
            auxname: curr.description,
            code: curr.reference || curr.code,
            reference: curr.reference || curr.code,
            image: `${this.baseAwsEndpoint}${curr.imagePath}`,
            childs: [] as Option[]
        })).sort((a, b) => {
            if (a.code < b.code) return -1;
            else if (a.code > b.code) return 1;
            return 0;
        });
    }

    formattingMethodList(method: PaymentMethodWebsite[]) {
        return method?.map(method => ({
            id: method.paymentMethod.reference,
            title: method.paymentMethod.name,
            auxname: method.paymentMethod.name,
            image: `${this.basePaymentMethodsEndpoint}${method.paymentMethod.imagePath}`,
            childs: []
        }));
    }

    formattingLanguageList(language: Language[]) {
        return language?.map(lang => ({
            id: lang.hrefLang,
            title: lang.name,
            auxname: lang.name,
            image: `${this.languageAwsEndpoint}${lang.imagePath}`,
            childs: [] as Option[]
        }));
    }

    private filterOptions(options: Option, filter: string) {
        if (!filter) return structuredClone(options.childs);

        return structuredClone(options.childs.filter(
            child => [child.title, child.auxname].some(x => x.toLowerCase().includes(filter.toLowerCase()))
        ));
    }

    selectCurrency(currency: Currency) {
        this.eventAggregator.publish('selected-currency', { currency, element: this.currencyExpandable });
    }

    selectLanguage(language: Language) {
        this.eventAggregator.publish('selected-language', { language, element: this.languageExpandable });
    }

    setOptionTitle(optionId, text) {
        const optionIndex = this.options.childs.findIndex(x => x.id === optionId);
        this.options.childs[optionIndex].title = text;
    }

    isAnInitialOption = (optionId) => ['buy-crypto', 'sell-crypto', 'exchange'].includes(optionId);

    isAnExchangeOption = (optionId) => ['exchange-crypto', 'exchange-fiat'].includes(optionId);

    recalculateSimplebar() {
        setTimeout(async () => {
            const mobileSimplebar = new SimpleBar(document.getElementById('mobile-navbar-drawer-content'));
            if (mobileSimplebar) mobileSimplebar.recalculate();
        }, 100);
    }

    isLanguageOrCurrency(id) {
        return ['language', 'currency'].includes(id);
    }

    isAnSeparatorOption = (optionId) => ['trending', 'popular', 'all'].includes(optionId);

    orderCyrptoByTrending(cryptoList, addPaymentMethodsList = true) {
        let sortCryptoList = [];
        const trendingCryptoList = cryptoList.filter(crypto => crypto.isTrending);
        const paymentMethodOrderList = this.orderPaymentMethodListByPopular(this.paymentMethodsList);

        if (trendingCryptoList.length > 0) {
            sortCryptoList = sortCryptoList.concat([
                {
                    id: 'trending',
                    title: 'Trending',
                    auxname: 'Trending',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...trendingCryptoList.map((crypto) => ({
                    id: crypto.reference || crypto.code,
                    title: crypto.code,
                    code: crypto.code,
                    reference: crypto.reference,
                    auxname: crypto.description,
                    image: `${this.baseAwsEndpoint}${crypto.imagePath}`,
                    childs: addPaymentMethodsList ? [...paymentMethodOrderList] : []
                }))
            ]);
        }

        sortCryptoList = sortCryptoList.concat([
            {
                id: 'all',
                title: 'All',
                auxname: 'All',
                code: '',
                reference: '',
                image: null,
                childs: []
            },
            ...cryptoList.map((crypto) => ({
                id: crypto.reference || crypto.code,
                title: crypto.code,
                auxname: crypto.description,
                image: `${this.baseAwsEndpoint}${crypto.imagePath}`,
                childs: addPaymentMethodsList ? [...paymentMethodOrderList] : []
            }))
        ]);

        return sortCryptoList;
    }

    orderCurrencyByTrendingList(currencyList) {
        const trendingCurrencyList = currencyList.filter(currency => currency.isTrending);
        const trendingCurrencyIds = trendingCurrencyList.map(currency => currency.id);
        const restOfTrendingList = currencyList.filter(currency => !trendingCurrencyIds.includes(currency.id));
        let currencyListSorted = [];

        if (trendingCurrencyList.length > 0) {

            currencyListSorted = currencyListSorted.concat([
                {
                    id: 'trending',
                    title: 'Trending',
                    auxname: 'Trending',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingCurrencyList(trendingCurrencyList),
            ]);

        }


        currencyListSorted = currencyListSorted.concat([
            {
                id: 'all',
                title: 'All',
                auxname: 'All',
                code: '',
                reference: '',
                image: null,
                childs: []
            },
            ...this.formattingCurrencyList(restOfTrendingList),
        ]);

        return currencyListSorted;
    }

    orderPaymentMethodListByPopular(paymentMethodList: PaymentMethodWebsite[]) {
        let orderList = [];
        const popularPaymentMethodList = [...paymentMethodList].slice(0, 3);
        const restOfList = paymentMethodList.splice(3);

        if (popularPaymentMethodList.length > 0) {
            orderList = orderList.concat([
                {
                    id: 'popular',
                    title: 'Popular',
                    auxname: 'Popular',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingMethodList(popularPaymentMethodList)
            ]);
        }

        orderList = orderList.concat([
            {
                id: 'all',
                title: 'All',
                auxname: 'All',
                code: '',
                reference: '',
                image: null,
                childs: []
            },
            ...this.formattingMethodList(restOfList)
        ]);

        return orderList;
    }

    listHaveChilds() {
        if (!this.selectOption) return;
        return this.selectedOption.childs.every(option => option.childs.length === 0);
    }
}
