// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/store/card-logo.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/store/astro-chicken-1.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/store/astro-chicken-2.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/store/astro-chicken-3.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/store/clock.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/store/phone_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/store/map.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/store/tooltip.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/store/pin.svg", import.meta.url);
// Module
var code = `<template>
    <div class="d-flex flex-column align-items-center" id="location-content">
        <div class="header store-content flex-column flex-sm-row w-100 d-flex align-items-end align-items-xl-center justify-content-between">
            <div class="text-container">
                <h1 class="hero-txt">
                    <cx-page-content-area
                        key.bind="'LOCATION_VANCOUVER_HERO_TITLE'"
                    ></cx-page-content-area>
                </h1>
                <div class="divisor top"></div>
                <span class="hero-content mb-3">
                    <cx-page-content-area
                        key.bind="'LOCATION_VANCOUVER_HERO_CONTENT'"
                    ></cx-page-content-area>
                </span>
                <div class="btn-container d-flex">
                    <cx-button
                        class="text-large fisrt-btn btn-w"
                        button-text.bind="'CX_VISIT_OUR_STORE' | siteStrings"
                        button-style.bind="'primary primary-style font-medium btn'"
                        click.delegate="scrollMap()"
                        touchend.delegate="scrollMap()"
                    ></cx-button>
                    <a href.bind="tradePageRoute | languageRoute & async" class="btn-w">
                        <cx-button
                            class="text-large"
                            button-text.bind="'CX_BUY_ONLINE' | siteStrings"
                            button-style.bind="'secondary font-medium btn'"
                        ></cx-button>                    
                    </a>
                </div>
            </div>
            <div class="img-container">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="hero-img" alt="credit-card" loading="lazy">
            </div>
        </div>
        <div class="offer w-100">
            <div class="store-content d-flex flex-column align-items-center">
                <h1 class="offer-title">
                    <cx-page-content-area
                        key.bind="'LOCATION_TITLE_1'"
                    ></cx-page-content-area>
                </h1>
                <div class="divisor mid"></div>
                <span class="paragraph text-center">
                    <cx-page-content-area
                        key.bind="'LOCATION_PARAGRAPH_1'"
                    ></cx-page-content-area>
                </span>
                <div class="card-container">
                    <div click.delegate="goToTrade('buy')" class="card-body d-flex flex-column flex-sm-row flex-xl-column align-items-center justify-content-between cursor-pointer">
                        <img src="${___HTML_LOADER_IMPORT_1___}" class="card-img first-card pb-1 pb-xl-0" alt="astro-chicken-1" loading="lazy">
                        <div class="card-content d-flex flex-column align-items-center">
                            <span class="card-title font-bold">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_TITLE_1'"
                                ></cx-page-content-area>
                            </span>
                            <span class="card-subtitle text-center">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_SUBTITLE_1'"
                                ></cx-page-content-area>
                            </span>
                        </div>
                    </div>
                    <div click.delegate="goToTrade('sell')" class="card-body d-flex flex-column flex-sm-row flex-xl-column align-items-center justify-content-between cursor-pointer">
                        <img src="${___HTML_LOADER_IMPORT_2___}" class="card-img second-card" alt="astro-chicken-1" loading="lazy">
                        <div class="card-content d-flex flex-column align-items-center">
                            <span class="card-title font-bold sell-card">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_TITLE_2'"
                                ></cx-page-content-area>
                            </span>
                            <span class="card-subtitle text-center">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_SUBTITLE_2'"
                                ></cx-page-content-area>
                            </span>
                        </div>
                    </div>
                    <div click.delegate="goToTrade('swap/fiat')" class="card-body d-flex flex-column flex-sm-row flex-xl-column align-items-center justify-content-between cursor-pointer">
                        <img src="${___HTML_LOADER_IMPORT_3___}" class="card-img third-card pb-4 pb-xl-0 mt-md-2" alt="astro-chicken-1" loading="lazy">
                        <div class="card-content d-flex flex-column align-items-center">
                            <span class="card-title font-bold exchange-card">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_TITLE_3'"
                                ></cx-page-content-area>
                            </span>
                            <span class="card-subtitle text-center">
                                <cx-page-content-area
                                    key.bind="'LOCATION_CARD_SUBTITLE_3'"
                                ></cx-page-content-area>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="exchange w-100">
            <div class="d-flex flex-column align-items-center">
                <h1 class="exchange-title text-black font-medium ">
                    <cx-page-content-area
                        key.bind="'LOCATION_TITLE_2'"
                    ></cx-page-content-area>
                </h1>
                <div class="container-pairs d-flex justify-content-center justify-content-md-between align-content-between mx-0">
                    <cx-exchange-pair
                        class="p-0"
                        repeat.for="currencyPair of currencies"
                        pair-data.bind="currencyPair"
                        click-function.call="goToTrade(currencyPair)"
                    ></cx-exchange-pair>
                </div>
            </div>
        </div>
        <div class="hours w-100 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column align-items-center text-white">
                <h1 class="hours-title">
                    <cx-page-content-area
                        key.bind="'LOCATION_TITLE_3'"
                    ></cx-page-content-area>
                </h1>
                <div class="icon-container text-center">
                    <img class="hours-clock" src="${___HTML_LOADER_IMPORT_4___}" alt="clock icon" loading="lazy">
                    <h5 class="hours-day">
                        <cx-page-content-area
                            key.bind="'LOCATION_DAYS'"
                        ></cx-page-content-area>
                    </h5>
                </div>
                <h6 class="hours-schedule font-regular text-medium">
                    <cx-page-content-area
                        key.bind="'LOCATION_HOURS'"
                    ></cx-page-content-area>
                </h6>
                <div class="d-flex align-items-center phone-number">
                    <img class="me-2" src="${___HTML_LOADER_IMPORT_5___}" loading="lazy"/>
                    <span class="text-medium text-white">(+1) 604-912-0444 (ChicksX)</span>
                </div>
            </div>
        </div>
        <div class="location w-100" ref="mapElement" id="vancouver-location">
            <div class="d-flex flex-column align-items-center">
                <h1 class="location-title">
                    <cx-page-content-area
                        key.bind="'LOCATION_TITLE_4'"
                    ></cx-page-content-area>
                </h1>
                <div class="divisor mid"></div>
                <cx-button
                    class="pt-0 pt-sm-3 text-medium"
                    button-text.bind="'CX_OPEN_IN_MAPS' | siteStrings"
                    button-style.bind="'primary primary-style btn open-maps font-medium'"
                    click.delegate="openMaps()"
                ></cx-button>
                <div class="map-container position-relative">
                    <img alt="map" src="${___HTML_LOADER_IMPORT_6___}" class="location-map w-100" loading="lazy">
                    <div class="address-tooltip position-absolute d-flex">
                        <img src="${___HTML_LOADER_IMPORT_7___}" alt="address tooltip" loading="lazy" class="w-100">
                        <div class="d-flex position-absolute content-tooltip align-items-center align-items-sm-start">
                            <img class="address-pin" src="${___HTML_LOADER_IMPORT_8___}" alt="address pin" loading="lazy">
                            <p class="store-address m-0">1771 Marine Dr, West Vancouver, BC V7V 1J5, Canada</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;