import { autoinject } from 'aurelia-framework';

@autoinject
export class PerformanceMetricsService {
    private performanceData: { Task: string, Time: string }[] = [];

    constructor() {}

    async measurePerformance<T>(taskName: string, task: () => Promise<T>): Promise<T> {
        const startTime = performance.now();
        const result = await task();
        const endTime = performance.now();
        this.performanceData.push({
            Task: taskName,
            Time: `${(endTime - startTime).toFixed(2)} ms`
        });
        return result;
    }

    logPerformanceMetrics(): void {
        console.table(this.performanceData);
    }

    resetMetrics(): void {
        this.performanceData = [];
    }

}
