import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class DocumentTypeService {
    path = 'UserDocumentType';
    userDocumentTypeList;

    constructor(private api: ApiService) {}

    async list() {
        if (this.userDocumentTypeList) return this.userDocumentTypeList;
        this.userDocumentTypeList = await this.api.doGet(`${this.path}/List`);
        return this.userDocumentTypeList;
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByCategoryId(id) {
        return await this.api.doGet(this.path + '/ByCategory/' + id);
    }
}
