import './cx-rate-of-change.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxRateOfChange {
    constructor(private helper: Helper, private element: Element) {
        this.helper = helper;
        this.element = element;
    }

    @bindable content;
    @bindable value;
    @bindable description;
    @bindable class;
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }
}

