// Module
var code = `<template>
    <cx-order-section 
        title.bind="'CX_SOLD_ORDERS' | siteStrings"
        type="Sold"
        operation-type.bind="operationType"
        row-callback.call="openOrderDetail(order)"
        icon-path-row.bind="iconPathRow"
        icon-variant.bind="iconVariant"
        route.bind="tradePageRoute"
        element.ref="orderSectionSold"
        create-button-text.bind="'CX_CREATE_NEW_ORDER' | siteStrings"
    ></cx-order-section>
</template>
`;
// Exports
export default code;