// Module
var code = `<template>
    <div id="cx-places-autocomplete-input" ref="googleAutoCompleteContainer">
        <form autocomplete="off">
            <div if.bind="suggestionPosition === 'TOP'" class="place-suggestions-container"></div>
            <slot name="input-label"></slot>
            <cx-text-input
                value.two-way="autocompletedAddress"
                key-up-function.bind="updateLocation & debounce:300"
                label.bind="placeholder"
                floating-label.bind="true"
                input-style.bind="inputClass ? inputClass : 'secondary-input'"
                class.bind="'input-password'"
                trailing-icon.bind="inputIcon"
                on-blur.bind="handleBlur"
                on-focus.bind="handleFocus"
                ref="location"
                disable-autocomplete.bind="true"
                name.bind="'new-user-address'"
                button-icon-function.bind="handleInputClear"
            ></cx-text-input>
            <div if.bind="suggestionPosition === 'BOTTOM'" id="google-autocomplete-places"></div>
        </form>
    </div>
</template>
`;
// Exports
export default code;