import './cx-user-dropdown.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { apiEndpoint } from 'environment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxUserDropdown {
    constructor(
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private helper: Helper
    ) {
        this.helper.getResolutions(this);
    }

    @bindable navigationItems;
    @bindable clickHandler;
    @bindable hideSubmenuOnMouseLeave = false;
    @bindable userClass;
    @bindable buttonClass;
    @bindable class;
    @bindable user;
    firstUserName;
    switchElement;
    userVeriffData;
    iconsNumbers;
    isLoading = true;
    environment = apiEndpoint();
    iconOutlineStates = this.createIcons(this.helper.range(8), 'icon', false);

    avatarDialogModule;
    otherAvatarDialogShow;
    openDropdown = false;
    openByClick = false;
    pages;
    avatarModalSubscriber: Subscription;
    sizeChangedSubscriber: Subscription;
    width: number;
    clickOutsideEvent: void;
    navigation;
    dropdownMenu;
    dropdownMenuButton;
    showableClasses = ['show', 'force-show'];
    bottomClass:string = '';
    navbar;
    isClicked:boolean = false;

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
    }

    bind(bindingContext, overrideContext) {
        this.navbar = overrideContext.bindingContext;
    }

    detached() {
        this.avatarModalSubscriber?.dispose();
        this.sizeChangedSubscriber?.dispose();
        document.removeEventListener('click', () => {}, true);
    }

    handleEventSubscriptions() {
        this.avatarModalSubscriber = this.eventAggregator.subscribe('avatar-dialog-open-modal', async() => {
            this.handleAvatarDialogOpen();
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });

        this.clickOutsideEvent = document.addEventListener('click', event => {
            const targetElement = event.target as Element;
            let shouldReturn: boolean | Element = targetElement.classList.contains('.dropdown-menu') || targetElement.closest('cx-user-dropdown');
            shouldReturn = shouldReturn || !this.helper.elementsContainAnyClass(this.getShowable(), this.showableClasses);
            if (shouldReturn) return;
            this.removeShow();
            this.navigation?.collapseAll();
            this.bottomClass = '';
            this.isClicked = false;
        });
    }

    removeShow() {
        this.getShowable().forEach(element => this.showableClasses.map(cls => element.classList.toggle(cls, false)));
    }

    navigate(slug) {
        this.clickHandler(slug);
    }

    getShowable = () => [this.dropdownMenu, this.dropdownMenuButton].filter(x => x);

    handleDropdownClick(active = -1) {
        this.isClicked = !this.isClicked;
        [this.dropdownMenu, this.dropdownMenuButton].map(x => {
            const list = x.classList;
            list.toggle('force-show', active === -1 ? undefined : active);
            list.toggle('show', list.contains('force-show'));
        });
    }

    handleAvatarDialogOpen() {
        if (!this.otherAvatarDialogShow) {
            this.avatarDialogModule.dialog.open();
            const menuLinks = document.querySelectorAll('.dropdown-item');
            menuLinks.forEach((link: HTMLAnchorElement) => link.blur());
        }
    }

    @computedFrom('user.firstName', 'user.lastName', 'userVeriffData.dateOfBirth', 'userVeriffData.firstName', 'userVeriffData.lastName')
    get firstName() {
        return this.helper.userNameFormat(this.user, this.userVeriffData).split(' ')[0];
    }

    async userChanged() {
        if (!this.user) return;
        this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
    }

    createIcons(array: number[], name: string, value: boolean) {
        return array.reduce((obj, num) => ({ ...obj, [`${name}${num}`]: value }), {});
    }

    materialIconClass(icon: boolean): string {
        return icon ? 'material-icons' : 'material-icons-outlined';
    }

    getFirstName(name: string): string {
        return name.split(' ')[0];
    }

    onMouseHover() {
        const navbar = document.getElementById('navigation-bar');
        const navbarIsPurple = !navbar.classList.value.includes('navbar-transparent');
        this.bottomClass = `bottom-border ${navbarIsPurple ? 'border-bottom-white' : ''}`;
    }

    onMouseLeave() {
        if (this.isClicked) return;
        this.bottomClass = '';
    }
}
