import './disclaimer.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class Disclaimer {
    constructor (private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService) {}

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Disclaimer')?.id);
    }
}
