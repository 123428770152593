// Module
var code = `<template>
    <section id="modern-slavery-statement" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_TITLE'"></cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_LAST_UPDATED'" class="text-end last-update"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_MAIN_CONTENT'" class="tos-main-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_BUSINESS_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_BUSINESS_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_RISK_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_RISK_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_POLICIES_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_POLICIES_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_DILIGENCE_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_DILIGENCE_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_REPORTING_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_REPORTING_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_CONTINUOUS_IMPROVEMENT_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'MODERN_SLAVERY_STATEMENT_CONTINUOUS_IMPROVEMENT_CONTENT'" class="text-content"></cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;