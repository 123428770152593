// Module
var code = `<template>
    <div id="cx-infinite-scroll">
        <div ref="scrollDataContainer" class="vh-100">
            <slot></slot>
        </div>
    </div>
</template>
`;
// Exports
export default code;