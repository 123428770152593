import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { CouponCode } from './models/purchase-flow/exchange';

@inject(ApiService)
export class CouponService {
    path = 'CouponCode';
    activeCouponsCount;

    constructor(
        private api: ApiService,
    ) { }

    async validateCoupon(coupon: string, cartValue: number): Promise<CouponCode> {
        const body = {
            coupon: coupon,
            purchaseAmount: cartValue,
            websites: [ 4 ]
        };
        return await this.api.doPost(this.path + '/validate', body);
    }
}
