import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';

@autoinject()
export class LanguageRouteValueConverter {
    constructor(
        private sessionService: SessionService
    ) { }

    async toView(value: string, isRoute: boolean = false) {
        if (!value && value !== '') return;
        if (value.startsWith('http')) return value;
        const currentLanguage = await this.sessionService.getLanguage();
        if (['en', 'en-us'].includes(currentLanguage.hrefLang.toLowerCase())) return value;
        return isRoute ? `${value}-${currentLanguage.hrefLang.split('-').join('').toLowerCase()}` : `${currentLanguage.hrefLang}/${value}`.replace(/([^:]\/)\/+/g, '$1');
    }
}
