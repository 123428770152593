import { IUserUpdatedEvent } from './../../../types/events';
import './profile.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { apiEndpoint, discordAuthUrl } from 'environment';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PhoneFormatValueConverter } from 'resources/value-converters/phone-formatter';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/helpers/helper';
import { SubscriptionService } from 'services/subscription-service';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';
import { ReferralConfigurationService } from 'services/referral-configuration-service';
import { PAGE_NAMES, UserUpdatedEvent } from 'resources/constants';
import { User } from 'services/models/user/user';
import { VeriffPerson } from 'services/models/veriff/startVerificationResponse';
import { SubscribedUserResponse } from 'services/models/subscription/subscription';
import { ReferralConfiguration } from 'services/models/referrals/referralConfiguration';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class Profile {
    constructor (
        private router: Router,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private eventAggregator: EventAggregator,
        private phoneFormatValueConverter: PhoneFormatValueConverter,
        private websiteService: WebsiteService,
        private helper: Helper,
        private subscriptionService: SubscriptionService,
        private toastService: ToastService,
        private referralConfigurationService: ReferralConfigurationService,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.originalLeft = null;
        this.helper.getResolutions(this);
    }

    Sections = {
        Main: 0,
        Notifications: 2,
        Unlink: 3
    };

    @bindable section = this.Sections.Main;
    isMain = true;
    environment = apiEndpoint();
    editingName = false;
    editingLastName = false;
    avatarDialogModule;
    requestedNameEdit = false;
    requestedLastNameEdit = false;
    isVerified = false;
    customerPortalRoute: string;
    enableReferralsTesting: boolean;
    userSubscription: SubscribedUserResponse;
    originalLeft = null;
    contentLoading = true;
    referralConfiguration: ReferralConfiguration;
    user: User;
    userVeriffData: VeriffPerson;
    sectionsData = {};
    events: {[key: string]: (payload?) => void;};
    discordTagChecked = false;
    discordUnlink = false;
    width: number;

    async activate(params) {
        if (params.isMarketing) {
            this.setSection(this.Sections.Notifications);
            this.sectionChanged();
        }

        this.sectionsData = {
            [this.Sections.Main]: {
                title: this.siteStringsValueConverter.toView('CX_SETTINGS'),
                description: this.siteStringsValueConverter.toView('CX_MANAGE_SETTINGS')
            },
            [this.Sections.Notifications]: {
                title: this.siteStringsValueConverter.toView('CX_NOTIFICATION'),
                description: this.siteStringsValueConverter.toView('CX_MANAGE_NOTIFICATION')
            },
            [this.Sections.Unlink]: {
                title: this.siteStringsValueConverter.toView('CX_DISCORD_SETTINGS'),
                description: this.siteStringsValueConverter.toView('CX_MANAGE_DISCORD_SETTINGS')
            }
        };
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('profile', this);
            this.width = this.helper.getWidth();
            this.user = await this.sessionService.refreshProfile();
            if (!this.user) {
                this.router.navigateToRoute('home');
                return;
            }
            this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
            this.handleEventSubscriptions();
            this.getActiveSubscription();
            this.user ??= await this.sessionService.refreshProfile();
            if (!this.user) return;
            this.referralConfiguration = await this.referralConfigurationService.getReferralsConfiguration(this.user.id);
            this.user.isSubscribed = this.subscriptionService.hasSubscription(this.user);
            this.customerPortalRoute = await this.websiteService.getRouteOrDefault(PAGE_NAMES.CUSTOMER_PORTAL);
            await this.handleLinkingDiscordRoute();
            if (this.user.discordTag && !this.discordTagChecked) {
                const discordTagResponse = await this.customerService.updateDiscordTag();
                if (discordTagResponse) this.user.discordTag = discordTagResponse.discordTag;
            }
            this.enableReferralsTesting = window.location.href.includesSome(['local', 'dev']);
        } finally {
            this.helper.validateLoading('profile');
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
        this.helper.resetLoading('profile');
    }

    handleEventSubscriptions() {
        this.events = {
            [UserUpdatedEvent]: (payload: IUserUpdatedEvent) => this.user = payload.user,
            'page-loaded': () => this.contentLoading = false,

            'banner-updated': payload => {
                if (!payload.optedIn) return;
                this.user.optedInForEmails = true;
            },
        };
        this.helper.subscribeEvents(this, this.events);
        this.events = {};
    }

    checkUserBillingAddress() {
        return Boolean(this.user?.address && this.user?.city && this.user?.state && this.user?.country);
    }

    getUserBillingAddress() {
        const data = [this.user?.address, this.user?.city, this.user?.state, this.user?.country?.toUpperCase()];
        return this.helper.joinString(data, ', ');
    }

    updateInformation = async(unlinkingSocialNetwork) => {
        this.helper.debounce(this, 'updatingInfo', 'updatingInfoTimeout', 500, async() => {
            try {
                const response = await this.customerService.updateInformation(this.user);
                if (response) {
                    this.user = { ...this.user, ...response };
                    this.eventAggregator.publish('user-updated', { user: this.user });
                    this.triggerSuccess(unlinkingSocialNetwork);
                } else {
                    this.triggerError(unlinkingSocialNetwork);
                }
            } catch (e) {
                console.log(e);
                this.triggerError(unlinkingSocialNetwork);
            }
        });
    };

    triggerSuccess(unlinking) {
        const message = unlinking
            ? 'You have successfully unlinked Discord from your profile.'
            : 'Your profile has been updated successfully.';
        this.toastService.showToast(undefined, message, ToastType.SUCCESS);
    }

    triggerError(unlinking) {
        const message = unlinking
            ? 'Failed to unlink Discord from your profile, try again or contact live chat for assistance.'
            : 'Failed to update information, please check that all fields are valid.';
        this.toastService.showToast(undefined, message, ToastType.ERROR);
    }

    getSaveIcon(saving) {
        const name = saving ? 'saving' : 'saved';
        return `/customer-portal/${name}.svg`;
    }

    loadTooltips(id, status) {
        let verified = '';
        let pending = '';
        let firstName = '';
        let birthDay = '';

        switch (id) {
            case 'name':
                firstName = this.fullName;
                birthDay = this.userVeriffData ? this.helper.dateFormat(this.userVeriffData?.dateOfBirth, 'DD/MM/yyyy') : '-';
                verified = `Your verified name is ${firstName} (DOB: ${birthDay}), to update please contact support@chicksgold.com`;
                pending = 'Your ID is currently pending. To verify please proceed with the verification process in the verification tab.';
                break;
            case 'phone':
                verified = `Your phone number is ${this.phoneFormatValueConverter.toView(this.user?.phoneNumber, this.user?.phoneCountryCode)}, to update please contact support@chicksgold.com`;
                pending = 'Your phone number is currently pending. To verify please proceed with the verification process in the verification tab.';
                break;
            case 'email':
                verified = `Your verified email is ${this.user?.email}, to update please contact support@chicksgold.com`;
                pending = 'Your email address is currently pending. To verify please proceed with the verification process in the verification tab.';
                break;
            case 'address':
                verified = this.getAddressMessage();
                pending = 'Your billing address is currently pending. To verify please upload a bill, statement or invoice to confirm in the verification tab.';
                break;
        }
        return status === 'verified' ? verified : pending;
    }

    async redirectToVerification(field) {
        if (!field) return;
        this.router.navigate(`/${this.customerPortalRoute}/verification`);
        const option = 'verification';
        this.eventAggregator.publish('route-updated', { option: option });
    }

    setSection(incomingSection) {
        this.section = incomingSection;
    }

    linkDiscordProfile() {
        const state = this.helper.generateRandomString();
        window.localStorage.setItem('discordAuthState', state);
        this.router.navigate(`${discordAuthUrl()}&state=${state}`);
    }

    async handleLinkingDiscordRoute() {
        const hashUrlParams = new URLSearchParams(window.location.hash.replace('#', '?'));
        if (!hashUrlParams.get('token_type') || !hashUrlParams.get('access_token') || this.user?.discordTag) {
            return;
        }
        if (!this.helper.handleStateOrNonceCheck('discordAuthState', hashUrlParams.get('state'), 'Failed to link a discord profile')) return;
        const response = await this.customerService.linkDiscordToProfile(hashUrlParams.get('token_type'), hashUrlParams.get('access_token'));
        if (response) {
            this.discordTagChecked = true;
            this.user.discordId = response.discordId;
            this.user.discordTag = response.discordTag;
            this.eventAggregator.publish('user-updated', { user: this.user });
            this.toastService.showToast(undefined, 'Your Discord account has been successfully linked to your Chicks Gold profile.', ToastType.SUCCESS);
        }
        this.router.navigate(`/${this.customerPortalRoute}`);
    }

    async getActiveSubscription() {
        this.userSubscription = await this.subscriptionService.getActiveSubscription(this.user);
    }

    @computedFrom('user.createdDate')
    get memberSinceString() {
        return 'ChicksX Member since: ' + this.helper.dateFormat(this.user?.createdDate, 'timeless');
    }

    openDialog() {
        this.setSection(this.Sections.Unlink);
    }

    async unlinkingAccount() {
        this.user.discordUnlink = true;
        await this.updateInformation(true);
        this.setSection(this.Sections.Main);
    }

    getAddressMessage() {
        const hasBilling = this.checkUserBillingAddress();
        const hasMultiple = this.userVeriffData?.addresses?.length > 0 && hasBilling;
        const fullAddress = this.helper.toCapitalize(this.userVeriffData?.addresses?.[0]?.fullAddress, null);
        const billing = hasBilling ? this.getUserBillingAddress() : '';
        const plural = hasMultiple ? ' addresses are' : ' address is';
        const address = this.helper.joinString([fullAddress, billing], ' & ');

        return `Your verified billing ${plural} ${address}', to update it please contact support@chicksgold.com`;
    }

    sectionChanged() {
        this.sectionKeys?.forEach(x => this[`is${x}`] = x === this.sectionName);
    }

    @computedFrom('user.discordTag')
    get discordOptionDescription() {
        if (this.user?.discordTag) {
            return `${this.siteStringsValueConverter.toView('CX_LINKED_DISCORD_ACCOUNT')} ${this.user?.discordTag}`;
        }
        return this.siteStringsValueConverter.toView('CX_CUSTOMER_PORTAL_DISCORD_INFO');
    }

    @computedFrom('user.firstName', 'user.lastName', 'userVeriffData.dateOfBirth', 'userVeriffData.firstName', 'userVeriffData.lastName')
    get fullName() {
        return this.helper.userNameFormat(this.user, this.userVeriffData);
    }

    @computedFrom('sectionsData', 'section')
    get currentSection() {
        return this.sectionsData?.[this.section];
    }

    @computedFrom('section', 'Sections')
    get sectionName() {
        return this.sectionKeys.find(x => this.Sections[x] === this.section);
    }

    @computedFrom('Sections')
    get sectionKeys() {
        return Object.keys(this.Sections);
    }

    @computedFrom('currentSection')
    get title() {
        return this.currentSection?.title;
    }

    @computedFrom('currentSection')
    get description() {
        return this.currentSection?.description;
    }

    @computedFrom('referralConfiguration.referrerBase', 'referralConfiguration.referredCondition', 'referralConfiguration.referredTime')
    get referralProgramDescription() {
        return this.siteStringsValueConverter.toView('CX_CUSTOMER_PORTAL_REFERRAL_PROGRAM_INFO', '', [this.referralConfiguration?.referrerBase, this.referralConfiguration?.referredCondition, this.referralConfiguration?.referredTime]);
    }

    @computedFrom('contentLoading', 'user.addressVerified', 'userVeriffData.addresses', 'this.user.address', 'this.user.city', 'this.user.state', 'this.user.country')
    get addressPending() {
        return !this.contentLoading && (!this.user?.addressVerified && (this.userVeriffData?.addresses?.length > 0 || this.checkUserBillingAddress()));
    }
}
