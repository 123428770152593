// Module
var code = `<template>
    <div id="cx-timer">
        <div id="timer-element-\${id}" class="d-flex flex-row align-items-center \${reverseRow ? 'reverse-row' : ''}">
            <div class="base-timer position-relative">
                <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="https://www.w3.org/2000/svg">
                    <g class="base-timer__circle">
                        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
                        <path ref="baseTimerPathRemainingRef" id="base-timer-path-remaining" stroke-dasharray="\${FULL_DASH_ARRAY}" class="base-timer__path-remaining"
                            d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90 0"></path>
                    </g>
                </svg>
                <span
                    class="base-timer__label position-absolute d-flex align-items-center justify-content-center text-small">
                    \${ timeCurrent }
                </span>
            </div>
            <div class="mx-2 label font-regular text-normal text-start" if.bind="text">
                \${ text }
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;