import { autoinject, observable, bindable } from 'aurelia-framework';
import './cx-cycle-list.scss';

@autoinject()
export class CxCycleList {
  @observable listContainer: HTMLDivElement;

  @observable private currentIndex: number = 0;
  @bindable @observable public maxItems: number = 6;

  isLoading: boolean = true;

  get showRightArrow(): boolean {
      return this.currentIndex !== this.getMaxIndex();
  }

  attached() {
      this.currentIndex = 0;
  }

  scrollRight() {
      if (this.showRightArrow) {
          this.currentIndex++;
          this.scrollToStep();
      } else {
          this.scrollToStart();
      }
  }

  private scrollToStep() {
      const itemWidth = this.listContainer.clientWidth / this.maxItems;
      const scrollLeft = this.currentIndex * itemWidth * this.maxItems;
      this.listContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  }

  private scrollToStart() {
      this.listContainer.scrollTo({ left: 0, behavior: 'smooth' });
      this.currentIndex = 0;
  }

  private getMaxIndex(): number {
      const totalItems = this.listContainer.children.length;
      return Math.max(0, Math.ceil(totalItems / this.maxItems) - 1);
  }

}
