import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { ReferralConfiguration } from './models/referrals/referralConfiguration';

@autoinject()
export class ReferralConfigurationService {
    path = 'ReferralConfiguration';
    referralConfiguration: ReferralConfiguration;
    constructor(private api: ApiService) {}

    async getReferralsConfiguration(userId: number): Promise<ReferralConfiguration> {
        return await this.api.doGet(`${this.path}/GetReferralsConfiguration/?userId=${userId}`);
    }
}
