// Module
var code = `<template>
    <div id="cx-filter" class="w-100" ref="filterSelector">
        <!-- Normal Select For Filter -->
        <mdc-select
            if.bind="type === 'select' && value !== 'sort'"
            change.delegate="changeFunction(\$event)"
            label.bind="label"
            class="w-100 text-regular \${inputClass}"
            required.bind="required"
            value.bind="value"
            disabled.bind="disabled"
        >
            <div mdc-select-icon>
                <img if.bind="iconPath" src="\`/icons/\${iconPath}\`" alt="\${label} Icon" loading="lazy">
                <div if.bind="materialIcon" class="material-icons text-purple">\${materialIcon}</div>
                <div if.bind="!iconPath && !materialIcon" class="material-icons-outlined">image_not_supported</div>
            </div>
            <section data-simplebar class="purple-scrollbar">
                <mdc-list>
                    <mdc-list-item repeat.for="option of options" value.bind="option" disable-ripple.bind="true">
                        \${\`CX_\${(displayValue ? option[displayValue] : option).split(' ').join('_').toUpperCase()}\` | siteStrings:displayValue ? option[displayValue] : option}
                    </mdc-list-item>
                </mdc-list>
            </section>
        </mdc-select>
    </div>
</template>
`;
// Exports
export default code;