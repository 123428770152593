import { autoinject, buildQueryString } from 'aurelia-framework';
import { ApiService } from './api-service';
import { PaymentMethodCurrencyFeeValuesResponse, PaymentMethodCurrencyFeeWebRequest } from './models/purchase-flow/exchange';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class PaymentMethodCurrencyFeeService {
    path = 'PaymentMethodCurrencyFee';

    constructor(private api: ApiService, private helper: Helper) { }

    async getByWebsitePaymentMethodAndCurrency(data: PaymentMethodCurrencyFeeWebRequest): Promise<PaymentMethodCurrencyFeeValuesResponse> {
        return await this.helper.fetchData(this.api, `${this.path}/GetByWebsitePaymentMethodAndCurrency?${buildQueryString({ ...data, websiteShortCode: 'CX' })}`, 'PaymentMethodCurrencyFeeService');
    }
}
