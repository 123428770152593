import './cx-live-rates.scss';
import { autoinject } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { CurrencyService } from 'services/currency-service';
import { CoinbaseService } from 'services/coinbase-service';
import { CustomerService } from 'services/customer-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import numeral from 'numeral';

@autoinject()

export class CxLiveRates {
    constructor(
        private coinbaseService: CoinbaseService,
        private currencyService: CurrencyService,
        private customerService: CustomerService,
        private eventAggregator: EventAggregator
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    isLoading = true;
    baseAwsEndpoint;
    activeCurrencies;
    currencyList;
    cryptoList;
    coinpaymentList;
    cryptoMethodsList;
    highlightedRates;
    mostPopularCurrencies;
    filteredCryptoList = [];
    filteredCryptoCodes = [];
    upwardTrend = false;
    width: number;
    sizeChangeSubcription;

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
        this.currencyList = this.activeCurrencies.filter(e => e.type === 'F');
        this.cryptoList = this.activeCurrencies.filter(e => e.type === 'C');
        const btcIndex = this.cryptoList.findIndex(item => item.code === 'BTC');
        if (btcIndex !== -1) {
            const btcElement = this.cryptoList.splice(btcIndex, 1)[0];
            this.cryptoList.unshift(btcElement);
        }
        this.highlightedRates = await this.coinbaseService.getJustExchangesRates(this.cryptoList[0].code);
        this.cryptoMethodsList = await this.currencyService.getCryptoForSelector();
        this.cryptoList.forEach(element => {
            if (this.highlightedRates[element.code]) {
                this.filteredCryptoList.push(element);
                this.filteredCryptoCodes.push(element.code);
            }
        });
        this.mostPopularCurrencies = await this.currencyService.getMultipleCurrencyStatsByCode(this.filteredCryptoCodes);
        this.isLoading = false;
        this.handleEventSubscriptions();
    }

    detached() {
        this.sizeChangeSubcription?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChangeSubcription = this.eventAggregator.subscribe('size-changed', ({ width }: {width: number}) => {
            this.width = width;
        });
    }

    loadVariation(index, twoDecimalPlaces = false) {
        const percent = numeral(this.mostPopularCurrencies[index].changePercentLast24).format(twoDecimalPlaces ? '0,0.00' : '0,0.0000');
        this.upwardTrend = Number(percent) >= 0;
        return Math.abs(Number(percent));
    }
}
