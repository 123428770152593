// Module
var code = `<template>
    <main id="cx-rate-of-change">
        <div class="cx-rate-of-change \${class}">
            <div class="d-flex flex-wrap align-items-center mb-2">
                <span class="text-large-plus-static font-medium me-1">\${content || value}</span>
                <div class="font-light d-flex align-items-center">
                    <span class="d-flex align-items-center \${value > 0 ? 'text-green' : 'text-red'} me-1">
                        <span class="material-icons \${value > 0 ? '' : 'reverse-arrow'}">arrow_drop_up</span>
                        <span class="">\${value | rateOfChange}%</span>
                    </span>
                    <span class="text-middlegray">past month</span>
                </div>
            </div>
            <span class="text-black font-regular">\${description}</span>
        </div>
    </main>
</template>`;
// Exports
export default code;