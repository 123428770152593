// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-logo-purple-all-white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/chicks/lost_in_space_chick.svg", import.meta.url);
// Module
var code = `<template>
    <div id="unknown-route">
        <a href="\${homePageRoute | languageRoute & async}" class="logo d-inline d-lg-none logo-icon"><img src="${___HTML_LOADER_IMPORT_0___}" class="d-lg-none" width="170" alt="logo"></a>
        <div class="row vh-100">
            <div class="d-none d-lg-inline logo-col text-center">
                <a href="\${homePageRoute | languageRoute & async}">
                    <img src="${___HTML_LOADER_IMPORT_0___}" class="img-fluid" width="148" alt="logo" loading="lazy">
                </a>
            </div>
            <div class="col-12 col-lg-4 txt-col my-lg-auto my-5">
                <h1 class="big-header">404</h1>
                <div class="horizontal-divisor"></div> 
                <div class="unknown-text unknown-text-header">Lost in outer space?</div>
                <div class="unknown-text font-light unknown-second-text">We can’t find the page you were looking for. Please return home.</div>
                <div class="unknown-button-container d-inline-block">
                    <a href="\${homePageRoute | languageRoute & async}">
                        <cx-button
                            class="mb-5 pt-3"
                            button-text.bind="'CX_TAKE_ME_BACK' | siteStrings"
                            button-style.bind="'primary primary-style unknown-button px-5'"
                        ></cx-button>
                    </a>
                </div>
            </div>
            <div class="d-none d-lg-flex col img-col p-0">
                <img src="${___HTML_LOADER_IMPORT_1___}" class="img-fluid" alt="Lost in space" loading="lazy">
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;