import './auth.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { WebsiteService } from 'services/website-service';
import { ReferralCodeService } from 'services/referral-code-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';

@autoinject()
export class Auth extends AuthenticationExtension {
    params;
    user;
    pages;
    homePageRoute;
    routeChangeSubscription;
    sizeChangeSubcription;
    width: number;
    height: number;
    state: string | string[] = 'sign-in';
    backRoute = '';

    constructor(
        sessionService: SessionService,
        private router: Router,
        private eventAggregator: EventAggregator,
        private websiteService: WebsiteService,
        private referralCodeService: ReferralCodeService
    ) {
        super(null, sessionService);
    }

    async canActivate(params) {
        await this._handleReferralAndReferrerLinks();
        const referralLink = params?.referralLink;

        if (!referralLink) {
            this.handleAuthRedirection('');
            return true;
        }

        const referrerUserId = await this.referralCodeService.getUserIdByReferralCode(referralLink);
        this.sessionService.saveReferrerCode(referrerUserId);

        this.handleAuthRedirection('', referralLink);
        return true;
    }

    async activate(params, routeConfig) {
        this.params = params;

        [this.user, this.pages] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
        if (this.user && !params.accessToken) {
            this.router.navigateToRoute('home');
        } else if (this.user && params.fromEmail) {
            this.router.navigateToRoute('security');
        } else if (routeConfig.route.includes('childRoute')) {
            this.router.navigateToRoute('404');
        }
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.backRoute = pages.find(x => x.name === 'sign-in')?.routeName ?? 'sign-in';
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.handleEventSubscriptions();
        this.backToSignInPage();
    }

    detached() {
        this.routeChangeSubscription?.dispose();
        this.sizeChangeSubcription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.state = this.router.currentInstruction?.config?.route;
        });

        this.sizeChangeSubcription = this.eventAggregator.subscribe('size-changed', ({ width, height }: {height: number, width: number}) => {
            this.width = width;
            this.height = height;
        });
    }

    backToSignInPage() {
        this.eventAggregator.publish('pressed-back');
    }

    private async _handleReferralAndReferrerLinks() {
        const urlParams = new URLSearchParams(window.location.search);
        const referralLink = urlParams.get('ref');
        const platformLink = urlParams.get('gclid') ?? urlParams.get('msclkid') ?? urlParams.get('rdt_cid');

        if (referralLink) {
            await this.sessionService.saveReferralLink(referralLink);
            urlParams.delete('ref');
        }

        if (platformLink) {
            let platform;
            urlParams.has('gclid') ? platform = 'gclid' : urlParams.has('rdt_cid') ? platform = 'rdt_cid' : platform = 'msclkid';
            this.sessionService.setPlatformLinkCookie(platform, platformLink, 3);
        }

        const referrerLink = document.referrer;
        if (
            referrerLink &&
            referrerLink !== '' &&
            !referralLink &&
            !referrerLink.includes('chicksx') &&
            !referrerLink.includes('localhost') &&
            !referrerLink.includes('user.chicksgroup')
        ) {
            await this.sessionService.saveReferrerLink(referrerLink);
        }
    }
}

