import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SubscribedUserResponse, SubscriptionResponse } from 'services/models/subscription/subscription';
import { ExtendedUser } from 'services/models/user/user';
import { SubscriptionOptions, SubscriptionSections, UnsubscribedUserReason } from 'services/models/subscription/subscription-enums';
import { Helper } from 'resources/helpers/helper';
import { SubscriptionService } from 'services/subscription-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import PaymentPlan from 'services/models/subscription/payment-plan';
import SubscriptionCheckbox from 'services/models/subscription/subscription-checkbox';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';

@autoinject()
export abstract class UserSubscription {
    constructor(
        public eventAggregator: EventAggregator,
        public helper: Helper,
        public subscriptionService: SubscriptionService,
        public pageContentAreaService: PageContentAreaService,
        public sessionService: SessionService
    ) { }

    protected toastService: ToastService;
    userSubscriptionInfo: SubscribedUserResponse;
    subscription: SubscriptionResponse;
    sectionData: object;
    totalEarned: number = 0;
    types: object;
    checkBoxes: SubscriptionCheckbox[] = [];

    async getSubscription() {
        this.subscription = await this.subscriptionService.getChicksVIP();
        this.types = {};
        this.types[SubscriptionOptions.Monthly] = new PaymentPlan(this.subscription.monthlyPrice, 'month', 'Monthly');
        this.types[SubscriptionOptions.Annual] = new PaymentPlan(this.subscription.annualPrice, 'year', 'Annual');
    }

    async getActiveSubscription(user: ExtendedUser) {
        this.userSubscriptionInfo = this.subscriptionService.getActiveSubscription(user);

        if (!this.userSubscriptionInfo) return;

        user.isSubscribed = true;
        this.getPrice();
        user.renewal = this.userSubscriptionInfo.isSubscribed;
        this.eventAggregator.publish('user-updated', { user: user, fromSubscriptionView: true });
    }

    async initSubscriptionData(skipPageContentAreaService: boolean = false) {
        this.sectionData = {
            [SubscriptionSections.Main]: {
                title: 'Subscription',
                titleKey: 'CX_SUBSCRIPTION'
            },
            [SubscriptionSections.Cashback]: {
                title: 'Cashback Earned',
                titleKey: 'CX_CASHBACK_EARNED',
                route: 'cashback',
                back: SubscriptionSections.Main
            },
            [SubscriptionSections.Manage]: {
                title: 'Manage membership',
                titleKey: 'CX_MANAGE_MEMBERSHIP',
                route: 'manage-membership',
                description: 'Payment details, learn more, cancel membership',
                descriptionKey: 'CX_MANAGE_MEMBERSHIP_DESCRIPTION',
                back: SubscriptionSections.Main
            },
            [SubscriptionSections.LearnMore]: {
                title: 'Learn more',
                titleKey: 'CX_LEARN_MORE',
                description: 'Membership frequently asked questions',
                descriptionKey: 'CX_LEARN_MORE_DESCRIPTION'
            },
            [SubscriptionSections.ChangePlan]: {
                title: 'Change current plan',
                titleKey: 'CX_CHANGE_PLAN',
                descriptionKey: 'CX_CHANGE_PLAN_DESCRIPTION',
            },
            [SubscriptionSections.Details]: {
                title: 'Payment Details',
                titleKey: 'CX_PAYMENT_DETAILS',
                route: 'payment-details',
                description: 'Change current plan, set up payment methods',
                descriptionKey: 'CX_PAYMENT_DETAILS_DESCRIPTION',
                back: SubscriptionSections.Manage
            },
            [SubscriptionSections.Cancel]: {
                title: 'Membership ended',
                titleKey: 'CX_MEMBERSHIP_ENDED',
                optionTitle: 'Cancel membership',
                optionTitleKey: 'CX_CANCEL_MEMBERSHIP',
                description: 'Disable renewal of your Chicks VIP subscription',
                descriptionKey: 'CX_CANCEL_MEMBERSHIP_DESCRIPTION',
                route: 'cancel-membership',
                back: SubscriptionSections.Manage
            }
        };

        if (!skipPageContentAreaService) {
            this.subscription.benefits = await Promise.all(
                this.helper.range(4).map(async x => ({
                    tableText: `CX_SUBSCRIPTION_BENEFIT_TABLE_${x + 1}`,
                    boxText: `CX_SUBSCRIPTION_BENEFIT_BOX_${x + 1}`,
                    icon: await this.pageContentAreaService.getImageAltByKey(`CX_SUBSCRIPTION_BENEFIT_BOX_${x + 1}`),
                }))
            );
        }

        this.totalEarned = await this.subscriptionService.totalCashbackEarned() || 0;

        const checkboxData = {
            [UnsubscribedUserReason.NoLongerNeeded]: { title: 'No longer have a need for videogame assets', titleKey: 'CX_UNSUBSCRIBE_REASON_1' },
            [UnsubscribedUserReason.MembershipTooExpensive]: { title: 'Membership is too expensive.', titleKey: 'CX_UNSUBSCRIBE_REASON_2' },
            [UnsubscribedUserReason.NotEnoughBenefits]: { title: 'Not enough membership benefits.', titleKey: 'CX_UNSUBSCRIBE_REASON_3' },
            [UnsubscribedUserReason.Other]: { title: 'Other', titleKey: 'CX_UNSUBSCRIBE_REASON_4' }
        };

        Object.keys(checkboxData).forEach(x => {
            this.checkBoxes.push(new SubscriptionCheckbox(checkboxData[x].title, checkboxData[x].titleKey));
        });
    }

    getPrice() {
        if (!this.userSubscriptionInfo) return;
        this.userSubscriptionInfo.price = this.userSubscriptionInfo.paymentPlan === 1 ? this.subscription.monthlyPrice : this.subscription.annualPrice;
    }

    getBackRoute(section: SubscriptionSections) {
        return this.sectionData[section].back && section !== SubscriptionSections.Main ? this.sectionData[this.sectionData[section].back].route : 'subscription';
    }

    async setRenewal(user: ExtendedUser) {
        if (!this.userSubscriptionInfo) return;
        user.renewal = true;
        await this.subscriptionService.updateSubscriber({
            subscriptionId: this.userSubscriptionInfo.subscriptionId,
            userId: this.userSubscriptionInfo.userId
        }, true);
        return await this.updateData(user);
    }

    async updateData(user: ExtendedUser) {
        return { ...user, ...await this.sessionService.refreshProfile() };
    }

    async changePaymentPlan(plan: SubscriptionOptions, user: ExtendedUser, optionData: PaymentPlan, renewalDate: string) {
        if (!this.userSubscriptionInfo || !user.renewal) return;
        this.helper.debounce(this, 'changingPlan', 'changePlanTimeout', 300, async() => {
            this.userSubscriptionInfo.paymentPlan = plan;
            this.getPrice();
            await this.subscriptionService.updateSubscriber({
                subscriptionId: this.userSubscriptionInfo.subscriptionId,
                userId: this.userSubscriptionInfo.userId,
                paymentPlan: plan
            });
            await this.updateData(user);
            this.toastService.showToast('Success', `You current plan has changed to ${optionData.productType}, changes will take effect on ${renewalDate}.`, ToastType.SUCCESS);
        });
    }
}
