import './cx-order-row.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable, computedFrom } from 'aurelia-framework';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { Helper } from 'resources/helpers/helper';
import { AdvancedOrderSearchOrder, OrderTransactionType, SmallOrder } from 'services/models/order';
import { User } from 'services/models/user/user';
import { WebsiteShortCode } from 'services/models/website/website';

@autoinject()
export class CxOrderRow {
    @bindable order: SmallOrder | AdvancedOrderSearchOrder;
    @bindable width: number;
    @bindable noHover: boolean = false;
    @bindable columnWidth?: number;
    @bindable mobileColumnWidth?: number;
    @bindable containerLeftPadding?: number;
    @bindable statusColumnWidth?: number;
    @bindable statusColumnWidthTablet?: number;
    @bindable mobileRowHeight?: number;
    @bindable type = 'Purchased';
    @bindable iconPath: string = 'account_balance_wallet';
    @bindable iconVariant: string = 'outlined';
    @bindable isSubscription: boolean = false;
    @bindable user: User;

    orderRow: HTMLDivElement;
    isCashback: boolean = false;
    isOrdercompleted: boolean = false;
    isSupportTicket: boolean = false;
    isReferral: boolean = false;
    isBalance: boolean = false;

    statusClasses = new Map([
        ['all', ''],
        ['created', ''],
        ['pending', 'text-warning'],
        ['marked-sent', 'text-warning'],
        ['partially-delivered', 'text-warning'],
        ['completed', 'text-success-green'],
        ['complete', 'text-success-green'],
        ['cancelled', 'text-red'],
        ['refunded', 'text-red'],
        ['rejected', 'text-red']
    ]);

    constructor(
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private fiatCurrencyFormatter: FiatCurrencyFormatValueConverter,
        private helper: Helper
    ) {}

    bind() {
        this[`is${this.helper.toPascal(this.type)}`] = true;
    }

    async attached() {
        this.setStyleVariables();
    }

    setStyleVariables() {
        if (this.columnWidth) this.orderRow.style.setProperty('--column-width', `${this.columnWidth}px`);
        if (this.mobileColumnWidth) this.orderRow.style.setProperty('--mobile-column-width', `${this.mobileColumnWidth}px`);
        if (this.statusColumnWidth) this.orderRow.style.setProperty('--status-width', `${this.statusColumnWidth}px`);
        if (this.containerLeftPadding) this.orderRow.style.setProperty('--left-padding', `${this.containerLeftPadding}px`);
        if (this.statusColumnWidthTablet) this.orderRow.style.setProperty('--tablet-status-width', `${this.statusColumnWidthTablet}px`);
        if (this.mobileRowHeight) this.orderRow.style.setProperty('--mobile-row-height', `${this.mobileRowHeight}px`);
    }

    get isPhone() {
        return this.width <= 576;
    }

    get totalStyle() {
        return 'type' in this.order && this.order.type === 'withdraw' ? 'text-red' : '';
    }

    get orderStatus() {
        return this.helper.parseOrderStatus(this.order.status, this.order.fulfilled);
    }

    get orderType() {
        return 'type' in this.order ? this.helper.toCapitalize(this.order.type, 'first') : '';
    }

    get statusStyle() {
        const status = this.orderStatus.toLowerCase().replace(' ', '-');
        return this.statusClasses.get(status) || `order-${status}`;
    }

    get orderTotal() {
        let amount;

        if (!this.isSmallOrder && !this.isSubscription && !this.isCashback) {
            const order = this.order as AdvancedOrderSearchOrder;
            const avoidConversion = Boolean(order.currencyUsed);
            amount = this.currencyFormatValueConverter.toViewFiatOnly(order.total, null, order.currencyUsed, avoidConversion);
        } else {
            amount = this.getOrderQuantity(this.order as SmallOrder);
        }

        return amount;
    }

    getOrderQuantity(order: SmallOrder) {
        if (order.isExchangeOrder) {
            return this.helper.formatByCurrency(
                this.isCashback ? order.price : order.quantity,
                this.isCashback ? order.baseCurrency : order.targetCurrency,
                this.currencyFormatValueConverter
            );
        }
        return this.fiatCurrencyFormatter.toView(order.quantity ?? order.price, null, order.baseCurrency.originalCode, true);
    }

    get orderLastMessage() {
        return 'orderChat' in this.order && this.order.orderChat ? this.order.orderChat.lastMessage.message : '-';
    }

    get isSmallOrder() {
        return !this.isBalance && !this.isCashback && !this.isOrdercompleted;
    }

    get productName() {
        const order = this.order as SmallOrder;
        return order.products[0]?.product.name || '-';
    }

    get isClickable() {
        const isOrderWithoutId = !this.order.id && this.isSupportTicket;
        const isNonClickableOrder = this.isCashback || this.isReferral;
        return !(isOrderWithoutId || isNonClickableOrder);
    }

    @computedFrom('order.websiteShortCode')
    get websiteName() {
        if ('websiteShortCode' in this.order) {
            return WebsiteShortCode.enumName(this.order?.websiteShortCode || 'CX');
        }
        return 'CX';
    }

    @computedFrom('isCashback', 'order.subscriptionCashback', 'order.price')
    get earning() {
        if (!this.isCashback) return;
        const smallOrder = this.order as SmallOrder;
        if (this.isCashback) {
            const rateToUse = smallOrder.baseCurrency?.type === 'C' ? 1 / smallOrder.rate : smallOrder.rate;
            return smallOrder.subscriptionCashback * rateToUse;
        }
        return smallOrder.price;
    }

    get transactionType() {
        if ('transactionType' in this.order) {
            return OrderTransactionType.enumName(this.order.transactionType as string | number);
        }
        return '';
    }
}
