// Module
var code = `<template>
    <div id="cx-chat-message" class="\${ isSender ? 'chat-position-right' : 'chat-position-left' }">
        <div class="d-flex flex-column">
            <div if.bind="message" class="d-flex flex-row \${ isSender ? 'justify-content-end' : '' }">
                <img class="\${ isSender ? 'second' : 'first' }" src="\${ imageUrl ? \`\${ environment }Image/chicks-avatars/\${ imageUrl }\` : \`\${ environment }Image/chicks-avatars/chick_12\` }" alt="User profile" loading="lazy">
                <div class="container-message flex-column-reverse \${ isSender ? 'first flex-sm-row align-items-start' : 'second flex-sm-row-reverse align-items-end' } \${ containerClass } d-flex align-items-md-center">
                    <span class="date text-nowrap">\${ date }</span>
                    <p class="message d-flex align-items-center">\${ message }</p>
                </div>
            </div>
            <div if.bind="imageFiles.length > 0" >
                <div class="d-flex flex-column container-files">
                    <div class="d-flex flex-row \${ isSender ? 'justify-content-end' : '' }">
                        <img class="\${ isSender ? 'second' : 'first' }" src="\${ imageUrl ? \`\${ environment }Image/chicks-avatars/\${ imageUrl }\` : \`\${ environment }Image/chicks-avatars/chick_12\` }" alt="User profile" loading="lazy">
                        <div class=" flex-column-reverse \${ isSender ? 'first flex-sm-row align-items-start' : 'second flex-sm-row-reverse align-items-end' } \${ containerClass } d-flex align-items-center">
                            <span class="date text-nowrap">\${ date }</span>
                            <div class="message files images-files d-flex align-items-center">
                                <div repeat.for="image of imageFiles | spliceArray:0:2" class="position-relative container-image cursor-pointer" click.delegate="openGallery(\$index)">
                                    <div if.bind="\$index == 1 && imageFiles.length > 2" class="position-absolute text-white d-flex justify-content-center align-items-center mask">
                                        <span>+\${ imageFiles.length - 2 }</span>
                                    </div>
                                    <img src="\${ environment }Image/\${ image.url }" alt="attached image" loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div if.bind="documentsList.length > 0"  repeat.for="document of documentsList">
                <div class="d-flex flex-column container-files">
                    <div class="d-flex flex-row \${ isSender ? 'justify-content-end' : '' }">
                        <img class="\${ isSender ? 'second' : 'first' }" src="\${ imageUrl ? \`\${ environment }Image/chicks-avatars/\${ imageUrl }\` : \`\${ environment }Image/chicks-avatars/chick_12\` }" alt="User profile" loading="lazy">
                        <div class="\${ isSender ? 'first flex-row' : 'second flex-row-reverse' } \${ containerClass } d-flex align-items-center">
                            <span class="date text-nowrap">\${ date }</span>
                            <div class="message files d-flex align-items-center">
                                <img src="\${ document.extension ? \`/icons/file-extensions/\${ document.extension }.svg\` : '' }" class="extension" alt="\${ document.extension } icon" loading="lazy">
                                <div class="text-underline cursor-pointer" click.delegate="viewPdf(\$index)">\${ document.fileName }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cx-gallery-dialog 
            if.bind="imageFiles.length > 0"
            view-model.ref="galleryDialog"
            opened.bind="opened" 
        ></cx-gallery-dialog>
        <cx-pdf-viewer
            if.bind="documentsList.length > 0"
            view-model.ref="pdfDialog"
        ></cx-pdf-viewer>
    </div>
</template>
`;
// Exports
export default code;