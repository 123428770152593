import './crypto-asset-statements.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class CryptoAssetStatements {
    constructor(private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService) {}

    async attached() {
        setTimeout(() => {
            const cookiesContainer = document.querySelector('.cookies')?.querySelectorAll('p');
            cookiesContainer?.forEach((e, index) => {
                index > 2 && index < 7 ? e.style.marginLeft = '20px' : e.style.marginLeft = '0';
            });
        }, 500);
    }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Crypto Asset Statements')?.id);
    }
}
