// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/location_pin.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cash-in-person">
        <mdc-expandable class="purchase-flow-expandable" view-model.ref="cashInPersonExpandable"  open.two-way="expandableOpen">
            <div class="text-large-plus d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="Upload" loading="lazy">
                Meet up Location
                <div class="tooltip-container" if.bind="meetupAddressValid && !expandableOpen && meetupTime">
                    <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                        aria-label="tooltip">
                        contact_support
                    </mdc-icon>
                    <div class="position-absolute tooltip-detail text-small">
                        <span class="mb-2 caption">We’ll meet you at or around \${location} in \${meetupTime} around \${time} (PST).</span>
                        <span class="caption">After we receive your payment, connect with a representative through live chat or support ticket to arrange delivery.</span>
                    </div>
                </div>
            </div>
            <div show.bind="expandableOpen">
                <span class="text-darkergray">Please enter your preferred meetup location. A ChicksX team member can meet you in any major city around the world.</span>     
                <cx-meetup-combo
                    valid.two-way="isWalletAddressValid"
                    location.two-way="location"
                    date.two-way="date"
                    time.two-way="time"
                    meetup-address-valid.two-way="meetupAddressValid"
                    selected-delivery.two-way="selectedDelivery"
                    view-model.ref="meetupComboViewmodel"
                    loading.bind="loading"
                ></cx-meetup-combo>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;