import { BaseEntity } from '../baseEntity';

export class DeliveryMethod extends BaseEntity {
    name: 'regular' | 'special' | 'premium';
    displayName: string;
    markupPercent: number;
    gameId: number;
    categoryId: number;
    imagePath: string;
    paymentMethodId: number;
    minHours: number;
    maxHours: number;
    minDays: number;
    maxDays: number;
    position: number;
    default: boolean;
    products;

    selected?: boolean;
}
