// Module
var code = `<template>
    <div id="cx-sticky-post">
        <div class="content-box mx-auto d-block justify-content-start flex-column flex-sm-row" repeat.for="blog of blogs">
            <img if.bind="blog.previewImagePath" class="blog-image" src="\${baseAwsEndpoint}\${blog.previewImagePath}" alt="\${blog.name} image" loading="lazy">
            <div class="d-flex flex-column align-items-start content-sticky">
                <a href="\${blogRoute}/\${blog.slug}" class="font-bold blog-title">
                    \${blog.title}
                </a>
                <div class="content">
                    <div class="d-flex justify-content-between align-items-center label-content">
                        <div class="d-flex justify-content-start">
                            <span repeat.for="tag of blog.blogTags" click.delegate="tagClickHandler(tag.blogTag.name)" class="tag-label">\${tag.blogTag.name}</span>
                        </div>
                        <a class="author-data" href="\${blogRoute}/author/\${blog.blogAuthor.alias}">
                            <p if.bind="blog.blogTag.name === 'CSR' && blog.blogTag.translated === true">
                                \${'CX_TRANSLATED_BY_AUTHOR' | siteStrings} \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                            </p>
                            <p else>
                                \${'CX_BY_AUTHOR' | siteStrings} \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                            </p>
                        </a>
                    </div>
                    <div innerhtml.bind="blog.content | textTruncate:432" class="inside-content"></div>
                    <div class="d-flex justify-content-start align-items-center">
                        <a href="\${blogRoute}/\${blog.slug}">
                            <button class="read-more font-medium text-center">
                                \${'CX_READ_MORE' | siteStrings}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;