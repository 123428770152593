// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/green_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/red_fail.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-confirm-dialog">
        <mdc-dialog view-model.ref="dialog" mdcdialog:closing.trigger="handleClosing()">
            <header class="dialog-header d-flex justify-content-between">
                <mdc-dialog-title class="d-flex justify-content-start align-items-center text-center fw-700">
                    \${titleText}
                </mdc-dialog-title>
                <div class="d-flex justify-content-end close-icon material-icons mt-1 pe-1"
                    data-mdc-dialog-action="close" click.delegate="handleClosing()">close</div>
            </header>
            <article class="dialog-container">
                <img if.bind="sideIcon && !htmlContent" src="\${sideIcon}" alt="\${sideAlt}" class="side-image"
                    loading="lazy" />
                <div class="main-content">
                    <mdc-dialog-content class="d-flex justify-content-center align-items-center p-0">
                        <cx-circular-progress if.bind="htmlContent"></cx-circular-progress>
                        <div if.bind="htmlContent" ref="htmlContentEl" class="html-content-wrapper"
                            innerhtml.bind="htmlContent" tabindex="0"></div>
                        <p if.bind="!htmlContent" class.bind="textClass">\${contentText}</p>
                    </mdc-dialog-content>
                    <mdc-dialog-actions if.bind="!htmlContent">
                        <div if.bind="step === 1" class="dialog-buttons">
                            <button if.bind="buttons"
                                class="dialog-confirm \${underlineConfirmButton ? 'underline-on-hover' : ''}"
                                mdc-button data-mdc-dialog-button-default data-mdc-dialog-initial-focus
                                click.delegate="confirm()">
                                \${confirmText ? confirmText : 'Confirm'}
                            </button>
                            <button if.bind="buttons" class="button-blue-filled cancel-btn" mdc-button
                                data-mdc-dialog-action="close" click.delegate="handleClosing()">
                                Cancel
                            </button>
                        </div>
                        <form if.bind="step === 2" submit.delegate="checkPasswordValidation()"
                            class="password-input \${hasCheckMark? 'check-mark' : ''}" autocomplete="off">
                            <div if.bind="usePassword" class="position-relative  w-100">
                                <mdc-text-field 
                                    outlined ref="passwordInput" 
                                    name="passLabel" 
                                    autocomplete="new-password"
                                    value.two-way="password & validate" 
                                    required.bind="true" 
                                    type="\${visiblePassword ? 'text' : 'password'}"
                                    class="cx-form-control input-password w-100 \${passwordValid ? 'cx-form-control--success' : passwordInvalid ? 'cx-form-control--error' : ''}"
                                    placeholder="Password" 
                                    keyup.delegate="passwordUpdatedOnKeyPress(\$event)"
                                    focusin.delegate="passwordUpdatedOnFocusIn()" 
                                    focusout.delegate="checkPasswordValidation()">
                                </mdc-text-field>
                                <button 
                                 mdc-icon-button
                                 mdc-text-field-icon 
                                 trailing 
                                 class="circle-icon visible-icon material-icons material-icon-button flex-center-center border-0"
                                 tabindex="0" 
                                 type="button"
                                 click.delegate="visiblePassword = !visiblePassword"
                                 >\${visiblePassword ? 'visibility_off' : 'visibility'}
                                 </button>
                                <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon" alt="green check" if.bind="psswordValid" loading="lazy">
                                <img src="${___HTML_LOADER_IMPORT_1___}" class="circle-icon" alt="error" if.bind="showErrorCheckMarkPassword"
                                    loading="lazy">
                                <mdc-circular-progress if.bind="showMiniSpinnerPassword" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                            </div>
                        </form>
                        <form if.bind="step === 3" submit.delegate="checkTokenValidation()" autocomplete="off">
                            <div if.bind="usePassword" class="position-relative">
                                <mdc-text-field ref="tokenElement" outlined name="codeLabel" autocomplete="new-code"
                                    value.two-way="token & validate" type="text" placeholder.bind="'CX_ENTER_2FA_TOKEN' | siteStrings"
                                    class="input-password blue-background-container w-100 \${showGreenCheckMarkToken ? 'cx-input--success' : showErrorCheckMarkToken ? 'cx-input--error' : ''}"
                                    keyup.delegate="tokenUpdatedOnKeyPress(\$event)"
                                    focusin.delegate="tokenUpdatedOnFocusIn()"
                                    focusout.delegate="checkTokenValidation()"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                    paste.delegate="helper.handlePasteNumberValidation(\$event, tokenElement)"></mdc-text-field>
                                <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon" alt="green check"
                                    if.bind="showGreenCheckMarkToken" loading="lazy">
                                <img src="${___HTML_LOADER_IMPORT_1___}" class="circle-icon" alt="error"
                                    if.bind="showErrorCheckMarkToken" loading="lazy">
                                <mdc-circular-progress if.bind="showMiniSpinnerToken" class="mini-spinner-icon"
                                    size="25" stroke-width="2"></mdc-circular-progress>
                            </div>
                        </form>
                    </mdc-dialog-actions>
                </div>
            </article>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;