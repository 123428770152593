// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/cx_vip_icon.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-user-dropdown" class="d-flex align-items-center cursor-pointer h-100">
        <cx-user-avatar
            if.bind="width > desktop"
            user.bind="user"
            is-edit.bind="true"
            use-spacing.bind="true"
            data-cy="customer-portal-dropdown-user"
        ></cx-user-avatar>
        <div class="dropdown user-info" element.ref="categoryElement">
            <button
                class="btn text-regular \${ buttonClass } d-flex align-items-center align-middle"
                type="button"
                id="dropdownMenuButton"
                element.ref="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                click.delegate="handleDropdownClick()"
                data-cy="customer-portal-dropdown-button"
            >
                <div if.bind="width > desktop" class="d-inline-block align-middle text-truncate text-capitalize font-bold \${userClass}">\${ firstName }</div>
                <a if.bind="width > desktop" class="material-icons p-0 arrow-down text-cx-purple">arrow_drop_down</a>
                <div id="cx-user-avatar" if.bind="width <= desktop">
                    <div class="user-img position-relative">
                        <div class="user-profile-container \${ user.isSubscribed ? 'subscribed' : '' }">
                            <img class="user-profile" src="\${ user.avatarImagePath ? \`\${environment}Image/chicks-avatars/\${user.avatarImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\` }" alt="User profile" loading="lazy">
                            <img if.bind="user.isSubscribed" loading="lazy" class="user-crown" alt="vip icon" src="${___HTML_LOADER_IMPORT_0___}"/>
                        </div>
                    </div>
                </div>
            </button>
            <ul class="dropdown-menu \${class} text-regular" aria-labelledby="dropdownMenuButton" ref="dropdownMenu">
                <cx-customer-portal-navigation
                    is-home.bind="true"
                    view-model.ref="navigation"
                    is-dropdown.bind="true"
                    data-cy="customer-portal-dropdown-navigation">
                </cx-customer-portal-navigation>
            </ul>
        </div>
    </div>
</template>
`;
// Exports
export default code;