import './cashback.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExtendedUser } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { SubscriptionService } from 'services/subscription-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { UserSubscription } from 'resources/extensions/user_subscription';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SubscriptionSections } from 'services/models/subscription/subscription-enums';
import { ITableHeader } from 'resources/elements/cx-table/cx-table';

@autoinject()
export class Cashback extends UserSubscription {
    constructor(
        private router: Router,
        sessionService: SessionService,
        subscriptionService: SubscriptionService,
        helper: Helper,
        eventAggregator: EventAggregator,
        pageContentArea: PageContentAreaService
    ) {
        super(eventAggregator, helper, subscriptionService, pageContentArea, sessionService);
    }

    user: ExtendedUser;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    sections;
    backRoute: string;
    iconPathRow: string = 'currency_exchange';
    tableHeaders: ITableHeader[] = [
        { title: 'id', class: 'id-header-width text-center' },
        { title: 'amount', class: 'column-header-width' },
        { title: 'operation', class: 'column-header-width' },
        { title: 'website', class: 'column-header-width' },
        { title: 'date', class: 'column-header-width' },
        { title: 'cashback', class: 'status-header-width' },
    ];

    async activate() {
        this.user = await this.sessionService.checkFreeTrialAndGetUser() as ExtendedUser;
    }

    deactivate() {
        this.pageLoadedSubscriber?.dispose();
    }

    async attached() {
        try {
            this.sections = SubscriptionSections;
            this.helper.addLoadingComponent('cashback');
            this.user = this.user ?? await this.sessionService.getProfile() as ExtendedUser;
            if (!this.user) {
                this.router.navigateToRoute('home');
                return;
            }
            this.handleEventSubscriptions();
            await super.initSubscriptionData(true);
            this.backRoute = super.getBackRoute(this.sections.Cashback);
        } finally {
            this.helper.validateLoading('cashback');
        }
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }
}
