import './balance-history.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { OrderService } from 'services/order-service';
import {
    AdvancedOrderSearchOrder,
    AdvancedOrderSearchRequest,
    AdvancedOrderSearchResponse
} from 'services/models/order';
import { computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { SizeChangedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { ITableHeader } from 'resources/elements/cx-table/cx-table';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { LanguageRouteValueConverter } from 'resources/value-converters/language-route';

@autoinject()
export class BalanceHistory {
    searchParams: AdvancedOrderSearchRequest = new AdvancedOrderSearchRequest();
    searchResponse?: AdvancedOrderSearchResponse;
    loading = true;
    mobileSearchParams = new AdvancedOrderSearchRequest();
    mobileSearchResponse?: AdvancedOrderSearchResponse;

    options = [
        'All',
        'Complete',
        'Pending',
        'Rejected',
        'Refunded',
        'Created'
    ];

    tableHeaders: ITableHeader[] = [
        { title: 'id', class: 'id-header-width text-center' },
        { title: 'amount', class: 'column-header-width' },
        { title: 'type', class: 'column-header-width' },
        { title: 'website', class: 'column-header-width' },
        { title: 'date', class: 'column-header-width' },
        { title: 'status', class: 'status-header-width' },
    ];

    width: number = 0;
    sizeWatcher: Subscription;
    currentPage = 1;

    constructor(
        private orderService: OrderService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private languageRouteValueConverter: LanguageRouteValueConverter
    ) {
        this.mobileSearchParams.pageSize = 12;
        this.mobileSearchParams.page = 0;
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        await this.fetchOrders();
        this.loading = false;
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.sizeWatcher = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => this.width = payload.width);
    }

    detached() {
        this.sizeWatcher.dispose();
    }

    async fetchOrders() {
        this.loading = true;
        this.searchResponse = await this.orderService.doAdvancedSearch(this.searchParams);
        this.loading = false;
    }

    pageChanged = async(page: number) => {
        this.searchParams.page = page - 1;
        await this.fetchOrders();
    };

    searchQueryChanged = async() => {
        this.searchParams.page = 0;
        this.currentPage = 1;
        await this.fetchOrders();
    };

    handleFilterChange = async(option: Event) => {
        const eventTarget = option.target as HTMLInputElement;
        this.searchParams.status = eventTarget.value.toLowerCase();
        this.searchParams.page = 0;
        this.currentPage = 1;
        await this.fetchOrders();
    };

    orderClicked = async (order: AdvancedOrderSearchOrder) => {
        this.router.navigateToRoute(await this.languageRouteValueConverter.toView('order-completed', true), { id: order.id, history: true });
    };

    get ordersEmpty() {
        return this.searchResponse?.orders?.length <= 0;
    }

    infiniteScrollLoad = async(newPage: number) => {
        if (this.mobileSearchParams.page === newPage) return 0;
        this.mobileSearchParams.page = newPage;
        const oldList = this.mobileSearchResponse?.orders ?? [];

        this.mobileSearchResponse = await this.orderService.doAdvancedSearch(this.mobileSearchParams);

        if (!this.mobileSearchResponse) return;

        this.mobileSearchResponse.orders = [...oldList, ...this.mobileSearchResponse.orders];
    };

    searchDrawerQueryChanged = async(query: string) => {
        this.mobileSearchParams.query = query;
        this.mobileSearchParams.page = 0;
        this.mobileSearchResponse = await this.orderService.doAdvancedSearch(this.mobileSearchParams);
    };

    searchDrawerClear = () => {
        this.mobileSearchParams.query = '';
        this.mobileSearchParams.page = 0;
        if (this.mobileSearchResponse) this.mobileSearchResponse.orders.length = 0;
    };

    @computedFrom('searchParams', 'searchResponse', 'loading')
    get currentCount() {
        if (this.loading) return '';
        const page = this.searchParams.page;
        const pageStart = this.searchParams.pageSize * page;
        let pageEnd = pageStart + this.searchParams.pageSize;
        const totalCount = this.searchResponse?.count ?? 0;

        if (pageEnd > totalCount) pageEnd = totalCount;

        return this.siteStringsValueConverter.toView('CX_SHOWING_FROM_PAGINATED', '', [pageStart + 1, pageEnd, totalCount]);
    }

    get pageCount() {
        const ordersCount = this.searchResponse?.count ?? 0;
        return Math.ceil(ordersCount / this.searchParams.pageSize);
    }

    get mobilePageCount() {
        const ordersCount = this.mobileSearchResponse?.count ?? 0;
        return Math.ceil(ordersCount / this.mobileSearchParams.pageSize);
    }
}
