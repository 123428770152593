import './trade.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';
import { ProductCategoryService } from 'services/product-category-service';
import { PLATFORM, observable } from 'aurelia-framework';
import { GameForNav, ProductCatWithGame } from 'services/models/game/productCategory';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Router } from 'aurelia-router';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { OperationType } from 'services/models/purchase-flow/exchange';

@autoinject()
export class Trade {
    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private productCategoryService: ProductCategoryService,
        private eventAggregator: EventAggregator,
    ) {}

    pageConfig: PageByWebsite;
    navCategory: ProductCatWithGame;
    faqRoute: string;
    tradesForNav: GameForNav[];
    @observable operationType: OperationType = OperationType.Buy;
    selectedTrade: GameForNav;
    titleKeyContent: PageContentArea;
    descriptionKeyContent: PageContentArea;
    bannerKeyContent: string;
    exchangeTypeSubscriber: Subscription;
    pageContentArea: PageContentArea[];
    changeRouteSusbscriber: Subscription;
    redirectUserToHomePage = () => {};

    async attached() {
        await this.loadInitialData();
        await this.loadPageContentArea();
        this.setSeoData();
        this.changeRouteSusbscriber = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.setSeoData();
        });
        this.eventAggregator.publish('finished-loading');
    }

    detached() {
        this.exchangeTypeSubscriber?.dispose();
        this.changeRouteSusbscriber?.dispose();
        window.removeEventListener('popstate', this.redirectUserToHomePage);
    }

    async loadInitialData() {
        const [navCategory, pages] = await Promise.all([
            this.productCategoryService.getNavCategory('trade'),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.navCategory = navCategory;
        this.tradesForNav = this.navCategory?.gameForNav || [];
        this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
        this.pageConfig = pages.find(x => x.name === 'Trade');
    }

    async loadPageContentArea() {
        this.pageContentArea = await this.pageContentAreaService.getByPageId(this.pageConfig?.id);

        const paramTrade = window.location.pathname.split('/').pop();
        this.selectedTrade = this.tradesForNav?.find(game => game.slug?.includes(paramTrade));

        if (this.selectedTrade && !this.pageContentArea.find(x => x.key === `TRADE_${this.selectedTrade.shortName}_FIRST_PARAGRAPH`)) {
            this.pageContentArea = await this.pageContentAreaService.getByPageIdAndGame(this.pageConfig?.id, `${this.selectedTrade.shortName}`);
        }
    }

    async configureRouter(config, router: Router) {
        this.navCategory = await this.productCategoryService.getNavCategory('trade');
        this.tradesForNav = this.navCategory?.gameForNav || [];

        config.options.pushState = true;
        config.map([
            {
                route: '',
                name: 'trade',
                moduleId: PLATFORM.moduleName('pages/trade/trade-currency/trade-currency'),
                settings: {
                    metaDescription: this.pageConfig?.metaDescription,
                },
                data: router.parent.routes.find(route => route.name === 'trade')?.data
            },
            ...this.tradesForNav.map(game => ({
                route: game.slug,
                name: game.slug[0],
                moduleId: PLATFORM.moduleName('pages/trade/trade-currency/trade-currency'),
                title: game.title,
                settings: {
                    metaDescription: game.metaDescription,
                    keyName: `TRADE_${game.shortName}`
                }
            }))
        ]);

        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
        this.router = router;
        this.redirectUserToHomePage = () => this.router.navigate('/');
        window.addEventListener('popstate', this.redirectUserToHomePage);
    }

    setSeoData() {
        const paramTrade = window.location.pathname.split('/').pop();
        this.selectedTrade = this.tradesForNav?.find(game => game.slug?.includes(paramTrade));
        this.titleKeyContent = this.pageContentArea.find(x => x.key === (this.selectedTrade ? `TRADE_${this.selectedTrade.shortName}_FIRST_PARAGRAPH` : 'CX_TRADE_HEADER_TEXT'));
        this.descriptionKeyContent = this.pageContentArea.find(x => x.key === (this.selectedTrade ? `TRADE_${this.selectedTrade.shortName}_FIRST_PARAGRAPH_BODY` : `CX_TRADE_${ this.operationTypeText }_SUBTITLE_TEXT`));
        const bannerKey = this.operationType === OperationType.Buy ? 'BUY' : this.operationType === OperationType.Sell ? 'SELL' : 'EXCHANGE_FIAT';
        this.bannerKeyContent = this.selectedTrade ? `CX_TRADE_${this.selectedTrade.shortName}_BANNER` : `CX_TRADE_${bannerKey}_BANNER`;
    }

    operationTypeChanged() {
        if (!this.pageContentArea) return;
        this.setSeoData();
    }

    get operationTypeText() {
        if (!this.operationType || this.operationType === OperationType.Buy || this.operationType === OperationType.Crypto2Crypto) return 'CRYPTO';
        if (this.operationType === OperationType.Sell || this.operationType === OperationType.Fiat2Fiat) return 'CURRENCY';
    }
}
