import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { ExchangeRatesResponse } from './models/purchase-flow/exchange';

@autoinject()
export class CoinbaseService {
    path = 'Coinbase';

    constructor(private api: ApiService) { }

    async getExchangeRates(currency: string): Promise<ExchangeRatesResponse> {
        return await this.api.doGet(this.path + '/exchange-rates/' + currency);
    }

    async getJustExchangesRates(currency: string) {
        const response = await this.getExchangeRates(currency);
        return response?.data?.rates;
    }

    async getExchangeRatesWithInvertedDesiredCurrency(currency: string, mandatoryCurrencyExchange : string): Promise<ExchangeRatesResponse> {
        return await this.api.doGet(this.path + '/exchange-rates/' + currency + `?mandatoryCurrencyExchange=${mandatoryCurrencyExchange}`);
    }

    async getJustExchangesRatesWithInvertedDesiredCurrency(currency: string, mandatoryCurrencyExchange : string) {
        const response = await this.getExchangeRatesWithInvertedDesiredCurrency(currency, mandatoryCurrencyExchange);
        return response?.data?.rates;
    }
}
