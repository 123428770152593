import './cx-option-box.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxOptionBox {
    constructor(private helper: Helper, private element: Element) {
    }

    @bindable title: string;
    @bindable description: string;
    @bindable isDark = true;
    @bindable handleClick = null;
    @bindable icon: string;
    @bindable alt: string;
    @bindable color = '#310FA2';
    @bindable outlined = false;
    @bindable disabled = false;
    @bindable iconSize = 30;
    @bindable arrowRight = true;
    @bindable doubleTitle = false;
    @bindable symbol: boolean = false;

    doAction() {
        this.handleClick?.();
    }
}

