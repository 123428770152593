// Module
var code = `<template>
    <div id="cx-seo-dropdown" skeleton-loading="is-loading.bind:isLoading">
        <cds-collapse class="seo-dropdown" closed-icon="arrow_drop_down" opened-icon="arrow_drop_up" open-transition-delay="1" close-transition-delay="1">
            <span slot="collapse-header-icon" class="collapse-header__icon material-icons-outlined">info</span>
            <p slot="collapse-header" class="collapse-header__title">\${ 'CX_VIEW_MORE' | siteStrings:'View more'}</p>
            <div class="collapse-content">
                <slot></slot>
            </div>
        </cds-collapse>
    </div>
</template>
`;
// Exports
export default code;