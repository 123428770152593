import { bindable, inject, customAttribute } from 'aurelia-framework';
import { hexToCSSFilter } from 'hex-to-css-filter';

@customAttribute('hex-to-filter')
@inject(Element)
export class HexToFilterAttribute {
    @bindable hexCode : string;
    constructor(private element : HTMLElement) {}

    attached() {
        if (!this.hexCode) return;
        this.element.style.filter = 'brightness(0) ' + hexToCSSFilter(this.hexCode).filter.replace(';', '');
    }
}
