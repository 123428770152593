// Module
var code = `<template>
    <div id="cx-button">
        <button mdc-button class="\${buttonStyle} border-0 \${buttonDisabled ? disabledStyle : ''}" disabled.bind="buttonDisabled">
            <div class="d-flex align-items-center justify-content-center">
                <span show.bind="buttonIcon" class="material-icons \${buttonIconStyle}">\${buttonIcon}</span>
                <span class="text-nowrap button-text \${'truncate-text-1-b' |in: isTruncate}">\${buttonText}</span>
            </div>
        </button>
    </div>
</template>
`;
// Exports
export default code;