// Module
var code = `<template>
    <div id="cx-faq-category-card-\${categoryId}" class="cx-category-card d-flex flex-center flex-column
    d-block py-4 py-lg-5 \${active ? 'active' : ''}">
        <div class="d-flex align-items-center justify-content-center icon-container">
            <img if.bind="!isStaticAsset" src="\${baseAwsUrl}\${iconName}" class="category-card-image \${active ? 'text-white' : ''}" alt="faq category image">
            <span else class="material-icons category-card-icon \${active ? 'text-white' : ''}">
                \${iconName}
            </span>
            <span class="material-icons text-purple" element.ref="icon">arrow_drop_down</span>
        </div>
        <p class="text-medium text-center w-90 mx-auto font-light">
            \${title}
        </p>
    </div>
</template>
`;
// Exports
export default code;