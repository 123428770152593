import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { ReferralCodeStats } from './models/referrals/referralCodeStats';

@autoinject()
export class ReferralCodeService {
    path = 'ReferralCode';
    referralCodeStats: ReferralCodeStats;

    constructor(private api: ApiService) {
    }

    /**
     * Checks if the user has a referral code and sets the first referral code if not.
     *
     * @param {number} userId - The ID of the user.
     * @returns {Promise<any>} A promise that resolves with the result of the referral code check.
     */
    async checkAndSetFirstReferralCode(userId: number) {
        return await this.api.doGet(`${this.path}/CheckAndSetFirstReferralCode/${userId}`);
    }

    /**
     * Sets a referral code for a user.
     *
     * @param {number} userId - The ID of the user.
     * @param {string} referralCode - The referral code to set.
     * @returns {Promise<any>} A promise that resolves when the referral code is set.
     */
    async setReferralCode(userId: number, referralCode: string) {
        const data = { userId, referralCode };
        return await this.api.doPost(`${this.path}/SetReferralCode`, data);
    }

    /**
     * Retrieves the user ID associated with a referral code.
     *
     * @param {string} referralCode - The referral code.
     * @returns {Promise<number>} A promise that resolves with the user ID.
     */
    async getUserIdByReferralCode(referralCode: string): Promise<number> {
        return await this.api.doGet(`${this.path}/GetUserIdByReferralCode/${referralCode}`);
    }

    /**
     * Retrieves referral code statistics for a user by their user ID.
     *
     * @param {number} userId - The ID of the user.
     * @returns {Promise<ReferralCodeStats>} A promise that resolves with the referral code statistics.
     */
    async getReferralCodeStatsByUserId(userId: number): Promise<ReferralCodeStats> {
        if (this.referralCodeStats) return this.referralCodeStats;
        this.referralCodeStats = await this.api.doGet(`${this.path}/GetReferralCodeStatsByUserId/${userId}`);
        return this.referralCodeStats;
    }

    /**
     * Filters relations based on pagination and ordering options.
     *
     * @param {number} [page=1] - The current page number.
     * @param {number} pageSize - The number of items per page.
     * @param {any} order - The ordering criteria.
     * @returns {Promise<any>} A promise that resolves with the filtered relations.
     */
    async filterRelations(page = 1, pageSize: number, order) {
        const options = {
            page: page,
            pageSize: pageSize,
            order: order
        };

        return await this.api.doPost(`${this.path}/DynamicFilter`, options);
    }

    /**
     * Retrieves all referral codes associated with a user by their user ID.
     *
     * @param {number} userId - The ID of the user.
     * @returns {Promise<any>} A promise that resolves with the referral codes.
     */
    async getReferralCodesByUserId(userId: number) {
        return await this.api.doGet(`${this.path}/GetReferralCodesByUserId/${userId}`);
    }

    /**
     * Deletes a referral code associated with a user.
     *
     * @param {string} referralCode - The referral code to delete.
     * @param {number} userId - The ID of the user.
     * @returns {Promise<any>} A promise that resolves when the referral code is deleted.
     */
    async deleteReferralCode(referralCode: string, userId: number) {
        const data = { referralCode, userId };
        return await this.api.doDelete(`${this.path}/DeleteReferralCode`, data);
    }

    /**
     * Creates a referral code for a user.
     *
     * @param {string} referralCode - The referral code to create.
     * @param {number} userId - The ID of the user.
     * @returns {Promise<any>} A promise that resolves when the referral code is created.
     */
    async createReferralCode(referralCode: string, userId: number) {
        const data = { userId, referralCode };
        return await this.api.doPost(`${this.path}/CreateReferralCodeForUserId`, data);
    }

    /**
     * Retrieves referral code statistics by a referral code.
     *
     * @param {string} referralCode - The referral code to retrieve statistics for.
     * @returns {Promise<ReferralCodeStats>} A promise that resolves with the referral code statistics.
     */
    async getReferralCodeStatsByCode(referralCode: string): Promise<ReferralCodeStats> {
        return this.referralCodeStats = await this.api.doGet(`${this.path}/GetReferralCodeStatsByCode/${referralCode}`);
    }
}
