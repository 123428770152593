import './cancel-membership.scss';
import '../subscription.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExtendedUser } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { SubscriptionService } from 'services/subscription-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { UserSubscription } from 'resources/extensions/user_subscription';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SubscriptionSections, UnsubscribedUserReason } from 'services/models/subscription/subscription-enums';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';

@autoinject()
export class CancelMembership extends UserSubscription {
    constructor(
        private router: Router,
        sessionService: SessionService,
        subscriptionService: SubscriptionService,
        helper: Helper,
        eventAggregator: EventAggregator,
        pageContentAreaService: PageContentAreaService,
        private dateFormatterValueConverter: DateFormatterValueConverter
    ) {
        super(eventAggregator, helper, subscriptionService, pageContentAreaService, sessionService);
    }

    user: ExtendedUser;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    sections: typeof SubscriptionSections;
    backRoute: string;
    reasonsKeys = Object.keys(UnsubscribedUserReason).filter(key => !isNaN(Number(key)));
    anyCheckBoxActive: boolean;
    reasons: typeof UnsubscribedUserReason;
    otherReasonInput: HTMLInputElement;

    async activate() {
        this.user = await this.sessionService.checkFreeTrialAndGetUser() as ExtendedUser;
        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }
        await super.getSubscription();
        await super.initSubscriptionData(true);
        await super.getActiveSubscription(this.user);
    }

    deactivate() {
        this.pageLoadedSubscriber?.dispose();
    }

    async attached() {
        try {
            this.reasons = UnsubscribedUserReason;
            this.sections = SubscriptionSections;
            this.helper.addLoadingComponent('cancel-membership');
            this.user = this.user ?? await this.sessionService.getProfile() as ExtendedUser;
            if (!this.user) {
                this.router.navigateToRoute('home');
                return;
            }
            this.handleEventSubscriptions();
            this.backRoute = super.getBackRoute(this.sections.Cancel);
        } finally {
            this.helper.validateLoading('cancel-membership');
        }
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    async setRenewalFunction() {
        if (!this.userSubscriptionInfo) return;
        this.user = await super.setRenewal(this.user);
    }

    toggleReason(incomingReason: UnsubscribedUserReason) {
        this.checkBoxes[incomingReason].checked = !this.checkBoxes[incomingReason].checked;
        this.checkForActive();

    }

    checkForActive() {
        this.anyCheckBoxActive = Object.values(this.checkBoxes).some(checkBox => checkBox.checked);
    }

    setSection(section: SubscriptionSections) {
        if (!this.sections[section]) return;

        this.router.navigateToRoute(this.sectionData[section]?.route ?? 'subscription');
    }

    async unsubscribe() {
        if (!this.userSubscriptionInfo) return;
        this.user.renewal = false;
        const checkBoxesArray = Object.values(this.checkBoxes);
        const unsubscribeReasons = checkBoxesArray.filter(c => c.description !== 'Other' && c.checked);
        const unsubscribeData = {
            subscriptionId: this.userSubscriptionInfo.subscriptionId,
            userId: this.userSubscriptionInfo.userId,
            reasons: unsubscribeReasons.map(c => ({ unsubscribeReason: c.description })),
            unsubscribeOtherReason: this.checkBoxes[this.reasons.Other].checked ? this.otherReasonInput.value : ''
        };

        if ((this.checkBoxes[this.reasons.Other].checked && !this.otherReasonInput.value) || !this.anyCheckBoxActive) return;

        await this.subscriptionService.unsubscribeUser(unsubscribeData);
        this.user = await super.updateData(this.user);
    }
}
