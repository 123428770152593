import './cx-line.scss';
import { bindable } from 'aurelia-framework';

export class CxLine {
    @bindable width?: number;
    @bindable maxWidth?: number;
    @bindable alignment?: string;
    @bindable class?: string;
    @bindable color?: string;
    line: HTMLElement;

    getWidth(width) {
        if (width === 'unset') {
            return 'width: unset';
        } else if (width) {
            return `width:${!isNaN(width) ? width + 'px' : width}`;
        } else {
            return 'width: 60px';
        }
    }

    getMaxWidth() {
        if (this.maxWidth) {
            return `max-width:${!isNaN(this.maxWidth) ? this.maxWidth + 'px' : this.maxWidth}`;
        } else {
            return this.getWidth(this.width);
        }
    }

    getAlignmentClass() {
        if (this.alignment === 'start') {
            return '';
        }
        if (this.alignment === 'center') {
            return 'm-auto';
        }
        if (this.alignment === 'end') {
            return 'float-right';
        }
    }

    getColorClass() {
        if (this.color === 'purple') {
            return 'purple-line';
        }

        return '';
    }

    getColorStyle() {
        return this.getColorClass() ? '' : `background: ${this.color}`;
    }

    getStyle() {
        return `${this.getWidth(this.width)}; ${this.getColorStyle()}; ${this.getMaxWidth()};`;
    }

    widthChanged() {
        this.line?.setAttribute('style', this.getStyle());
    }
}
