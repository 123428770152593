// Module
var code = `<template>
    <div id="cx-info-banner" class="\${class}">
        <div class="stick"></div>
        <div class="content-container">
            <slot></slot>
        </div>
    </div>
</template>
`;
// Exports
export default code;