// Module
var code = `<template>
    <div id="cx-trade-intro">
        <div class="trade-container">
            <slot></slot>
        </div>
    </div>
</template>
`;
// Exports
export default code;