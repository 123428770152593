// Module
var code = `<template>
    <div id="cx-card" class="\${class}">
        <slot>
            <slot name="card-title"></slot>
            <slot name="card-body"></slot>
        </slot>
    </div>
</template>
`;
// Exports
export default code;