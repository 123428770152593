import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { OrderOperationType } from 'services/models/order';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class Purchased {
    constructor(
        private router: Router,
        private websiteService: WebsiteService
    ) { }

    operationType = OrderOperationType.Buy;
    tradePageRoute: string = '';

    async attached() {
        try {
            let pages = [];
            pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.tradePageRoute = pages?.find(x => x.name === 'Trade')?.routeName ?? 'trade';
        } catch (error) {
            throw new Error(error);
        }
    }

    openOrderDetail(order) {
        this.router.navigateToRoute('order-completed', { id: order.id });
    }
}
