import { SupportTicketStatus } from 'services/models/support-ticket/support-ticket';

export class SupportTicketStatusValueConverter {
    toView(value, supportTicket, currentUserId) {
        if (!supportTicket) {
            return;
        }
        if (value === SupportTicketStatus.Closed) {
            return 'Closed';
        } else {
            if (!supportTicket.messages) {
                return;
            }
            const lastMsg = supportTicket.messages[supportTicket.messages?.length - 1];

            if (!lastMsg) {
                return 'Draft';
            } else if (lastMsg.userId !== currentUserId) {
                return 'Pending';
            } else {
                return 'Active';
            }
        }
    }
}
