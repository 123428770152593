import './cx-footer-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Footer } from 'resources/elements/footer/footer';

@autoinject()
export class CxFooterDropdown {
    constructor(private footer : Footer) { }

    dropdown;
    allDropdowns;
    dropdownActive;
    @bindable title;
    @bindable links;

    dropdownToggle() {
        this.allDropdowns.forEach(dropdown => this.dropdownsHandler(dropdown));
    }

    dropdownsHandler(dropdown) {
        if (dropdown !== this.dropdown) {
            this.setInactive(dropdown);
            return;
        }
        dropdown.classList.contains('inactive') ? this.setActive(dropdown) : this.setInactive(dropdown);
    }

    setInactive(dropdown) {
        dropdown.classList.replace('active', 'inactive');
    }

    setActive(dropdown) {
        dropdown.classList.replace('inactive', 'active');
    }

    closeDropdowns() {
        this.allDropdowns.forEach(dropdown => this.setInactive(dropdown));
    }

    attached() {
        this.allDropdowns = this.footer.footerContainer.querySelectorAll('#footer-dropdown');
    }
}
