// Module
var code = `<template>
    <div id="cx-input-search" class="position-relative search-bar-container-hamburger w-100">
        <mdc-text-field
            tabindex="-1"
            class="search-bar w-100 \${ inputClass }"
            placeholder.bind="!outlined ? '' : placeholder|siteStrings"
            label.bind=" outlined ? '' : label|siteStrings:label"
            value.bind="searchQuery"
            disabled.bind="disabled"
            keyup.delegate="handleSearch() & debounce:400"
            outlined.bind="outlined"
        >
            <mdc-icon
                mdc-text-field-icon
                leading
                class="material-icons"
            >search</mdc-icon>
            <mdc-icon
                mdc-text-field-icon
                trailing
                class="material-icons pe-auto close-icon" 
                if.bind="searchQuery"
                click.delegate="buttonHandler()"
            >close</mdc-icon>
        </mdc-text-field>
    </div>
</template>
`;
// Exports
export default code;