// Module
var code = `<template>
    <div id="cx-validator-progress">
        <div class="progress mt-2" show.bind="value">
            <div id="\${id}" class="progress-bar progress-bar-striped progress-bar-animated \${getCurrentProgressBarStatus(value)} \${!validatorProperty ? 'w-0' : ''}" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div if.bind="validatorProperty && (firstProperty || secondProperty || thirdProperty || fourthProperty || fifthProperty)" class="validator mt-2 pt-1 text-backface-visibility \${isCustomerPortal ? 'text-black' : ''}">
            At least <span class="\${secondProperty ? 'green' : 'red'}">8 characters</span> long with <span class="\${fourthProperty ? 'green' : 'red'}">one uppercase letter</span>, <span class="\${firstProperty ? 'green' : 'red'}">one lowercase letter</span>, <span class="\${fifthProperty ? 'green' : 'red'}">one special character</span>, or <span class="\${thirdProperty ? 'green' : 'red'}">numeral</span>.
        </div>
    </div>
</template>
`;
// Exports
export default code;