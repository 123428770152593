import './cx-section.scss';
import { bindable } from 'aurelia-framework';

export class CxSection {
    @bindable title: string;
    @bindable description: string;
    @bindable contentLoading: boolean;

    constructor() {}
}
