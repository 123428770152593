import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DateOnlyValueConverter } from 'resources/value-converters/date-only';
import { ProductService } from './product-service';
import { Helper } from 'resources/helpers/helper';
import moment from 'moment';
import { User } from './models/user/user';

@autoinject()
export class SubscriptionService {
    path = 'Subscription';

    constructor(private api: ApiService,
                private productService: ProductService,
                private dateOnlyValueConverter: DateOnlyValueConverter,
                private helper: Helper) {}

    getRoute = (route: string) => `${this.path}${route ? '/' : ''}${route}`;

    get = async(route: string) => await this.api.doGet(this.getRoute(route));

    getParams = async(route: string, params: { [key: string]: string | number}) => await this.get(this.helper.toParams(route, params));

    getByUserId = async(userId: number) => await this.getParams('GetByUserId', { userId });

    getByName = async(name: string) => await this.getParams('GetByName', { name });

    getChicksVIP = async() => await this.getByName('Chicks VIP');

    getById = async(id: number) => await this.get(String(id));

    getProductsBySubscriptionName = async(name = 'Chicks VIP') => await this.productService.getProductsWithFilter(`${name}`);

    getActiveSubscription = (user: User) => user?.subscriptions?.find(x => (this.dateOnlyValueConverter.toView(x.renewalDate) > this.dateOnlyValueConverter.toView(new Date())) || x.isSubscribed);

    hasChicksVipFreeTrial = async(user: User) => {
        if (!user) {
            return true;
        }
        return await this.get('CheckIfUserHasSubscriptionFreeTrial');
    };

    hasSubscription = (user: User) => Boolean(this.getActiveSubscription(user));

    getAvailableChicksVipProducts = async(user: User) => {
        if (this.hasSubscription(user)) {
            return;
        }
        let chicksVipProducts = await this.getProductsBySubscriptionName();
        chicksVipProducts = chicksVipProducts.filter(x => !x.name.includes('Renewal'));

        return chicksVipProducts.map(x => {
            x.productId = x.id;
            x.hasFreeTrial = user ? user.hasFreeTrial : true;
            if (x.hasFreeTrial) {
                x.priceAfterTrial = x.price;
                x.price = 0;
            }
            if (x.name.includes('Monthly') || x.hasFreeTrial) {
                x.validUntil = moment().add(30, 'days');
            } else {
                x.validUntil = moment().add(1, 'year');
            }
            x.quantity = x.tempQuantity = 1;
            return x;
        });
    };

    unsubscribeUser = async(data) =>
        await this.api.doPost(`${this.path}/Unsubscribe`, {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            reasons: data.reasons,
            unsubscribeOtherReason: data.unsubscribeOtherReason
        });

    updateSubscriber = async(data, renewal = false) => {
        const request = {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            paymentPlan: data.paymentPlan,
            mainPaymentMethodId: data.mainPaymentMethodId,
            backupPaymentMethodId: data.backupPaymentMethodId,
            mainCardLastDigits: data.mainCardLastDigits,
            backupCardLastDigits: data.backupCardLastDigits
        };
        if (renewal) return this.api.doPost(`${this.path}/Subscribe`, request);
        return await this.api.doPut(`${this.path}/UpdateSubscriber`, request);
    };

    async totalCashbackEarned() {
        return await this.get('TotalCashbackEarned');
    }
}
