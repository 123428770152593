export default class SubscriptionCheckbox {
    checked: boolean;
    description: string;
    key: string;

    constructor(description: string, key: string, checked: boolean = false) {
        this.checked = checked;
        this.description = description;
        this.key = key;
    }
}
