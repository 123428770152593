// Module
var code = `<template>
    <section id="code-of-ethics" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TITLE'"></cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_LAST_UPDATED'" class="text-end last-update"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_MAIN_CONTENT'" class="tos-main-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CUSTOMER_FIRST_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CUSTOMER_FIRST_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TRANSPARENCY_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TRANSPARENCY_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMPILANCE_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMPILANCE_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_SECURITY_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_SECURITY_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_FAIR_TRADING_PRACTICES_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_FAIR_TRADING_PRACTICES_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_PRIVACY_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_PRIVACY_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CONTINUOUS_IMPROVEMENT_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CONTINUOUS_IMPROVEMENT_CONTENT'" class="text-content"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMMUNITY_RESPONSABILITY_TITLE'" class="text-title font-bold"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMMUNITY_RESPONSABILITY_CONTENT'" class="text-content"></cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;