// Module
var code = `<template>
    <div id="cx-language-slider" class="d-flex align-items-center" ref="languageSliderContainer">
        <div class="glider-contain">
            <div class="glider glider-language h-100 overflow-hidden glider-track">
                <div 
                    repeat.for="language of languages"
                    class="d-flex align-items-center justify-content-center language-container cursor-pointer \${selectedLanguage.id === language.id ? 'active-language active-border' : ''}"
                    click.delegate="selectLanguage(language)"
                    focus.trigger="handleFocus(language)"
                    tabindex="0"
                >
                    <img if.bind="language.imagePath" class="language-icon" src="\${baseAwsEndpoint}\${language.imagePath}" alt.bind="language.name" loading="lazy">
                    <span else class="material-icons-outlined text-white">image_not_supported</span>
                    <span class="ms-2">\${language.name}</span>
                    <div if.bind="getRenderedLastItem(\$last)"></div>
                </div>
                <div if.bind="!languages.length && getRenderedLastItem(true)"></div>
            </div>
            <button aria-label="Next" class="glider-next glider-language-next material-icons right-arrow-language">arrow_right</button>
        </div>
    </div>
</template>
`;
// Exports
export default code;