// Module
var code = `<template>
    <div if.bind="!loading" id="cx-notification" class="d-flex flex-column position-relative justify-content-center">
        <div class="w-100 d-flex flex-column notification-info">
            <div class="d-flex align-items-center notification-body">
                <div mouseenter.trigger="mouseEventHandler(\$event)" mouseleave.trigger="mouseEventHandler(\$event)" class="cursor-pointer icon-container">
                    <span if.bind="!isHover && !notificationData.checked" class="material-icons-outlined text-green text-huge d-flex align-items-center">
                        <img
                            src="\${ loadIcon() }"
                            alt="Notification Icon"
                            class="notification-image"
                            loading="lazy"
                        >
                    </span>
                    <div if.bind="isHover || notificationData.checked" class="checkbox-notification">
                        <div class="bullet d-flex align-items-center justify-content-center \${ notificationData.checked ? 'bullet-active' : '' }" click.delegate="functionChecked()">
                            <span show.bind="notificationData.checked" class="material-icons text-extra-large text-white">check</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column w-100 text-black notification-content">
                    <span class="notification-header text-truncate title">
                        \${ notificationData.title }
                    </span>
                    <span class="notification-description description font-light">
                        \${ notificationData.description }
                    </span>
                </div>
            </div>
            <span class="ms-auto text-end text-small font-light text-medium-gray notification-date">\${ notificationData.time | dateFormatter:'format':'MMM Do YYYY, hh:mm A':currentLanguage.hrefLang}</span>
        </div>
        <div class="divisor-container">
            <hr if.bind="!last" class="divisor-gray-dark notification-divisor align-self-center">
        </div>
    </div>
    <div else id="skeleton-animation">
        <div class="w-100 d-flex flex-column">
            <span class="ms-auto me-3 mt-2 date skeleton-placeholder"></span>
            <div class="d-flex align-items-center ps-4">
                <span class="icon-placeholder me-4">
                </span>
                <div class="d-flex flex-column w-75">
                    <span class="title skeleton-placeholder mb-2"></span>
                    <span class="content skeleton-placeholder"></span>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;