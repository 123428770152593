// Module
var code = `<template>
    <div id='veriff-root'></div>
    <cx-button
        if.bind="!user.idVerified && !hiddenButton"
        button-text="Start verification"
        button-style.bind="'primary primary-style veriff-btn font-bold'"
        click.delegate="startVerification()"
        id="start-veriff"
    ></cx-button>
</template>
`;
// Exports
export default code;