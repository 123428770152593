import './cx-list-element.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';

@autoinject()
export class CxListElement {
    @bindable iconOutlined: boolean = true;
    @bindable icon: string;
    @bindable title: string;
    @bindable subtitle: string;
    @bindable redirect: string | null;
    @bindable action: (() => void) | undefined;

    constructor() { }

    handleClick = () => {
        this.action?.();
    };

    get materialIconStyle() {
        if (this.iconOutlined) {
            return 'material-icons-outlined';
        }

        return 'material-icons';
    }

    get hoverEffect() {
        if (this.action) {
            return 'cursor-pointer hover-effects';
        }

        return '';
    }

    get navigationArrowStyle() {
        if (this.action) {
            return 'd-lg-none arrow-style';
        }

        return 'd-none';
    }
}
