// Module
var code = `<template>
    <div id="cx-number-arrows" mousedown.delegate="preventFocusLoss(\$event)">
        <div class="button-container position-absolute d-none d-lg-flex flex-column \${suffix ? 'suffix' : ''} \${disabled ? 'opacity-0 invisible' : ''}">
            <div 
                class="number-arrow-container"
                ref="increaseArrow"
                click.delegate="increaseValue()"
                mouseleave.trigger="removeStyling(increaseArrow)"
                disabled.bind="disabled"
            >
                <div class="material-icons number-arrow cursor-pointer">arrow_drop_up</div>
            </div>
            <div 
                class="number-arrow-container"
                ref="decreaseArrow"
                click.delegate="decreaseValue()"
                mouseleave.trigger="removeStyling(decreaseArrow)"
                disabled.bind="disabled"
            >
                <div class="material-icons number-arrow cursor-pointer">arrow_drop_down</div>
            </div>
        </div>
        <div if.bind="extraButtons" class="extra-button-container d-lg-none d-flex">
            <button class="btn btn-primary extra-button cursor-pointer my-auto" disabled.bind="!quantity" click.delegate="decreaseValue()">-</button>
            <mdc-text-field
                ref="extraButtonsref"
                outlined
                class="input-whole-transparent quantity-input text-regular my-auto"
                type="text"
                value.two-way="quantity"
            ></mdc-text-field>
            <button class="btn btn-primary extra-button cursor-pointer my-auto" click.delegate="increaseValue()">+</button>
        </div>
    </div>
</template>
`;
// Exports
export default code;