import { autoinject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class DateFormatterValueConverter {
    constructor(private helper: Helper) {}

    toView(value, type = 'calendar', format?: string, locale = 'en-US') {
        return this.helper.dateFormat(value, type, format, locale);
    }

}
