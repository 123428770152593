import './cx-search-drawer.scss';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { MdcDrawer } from '@aurelia-mdc-web/drawer';

@autoinject()
export class CxSearchDrawer {
    @bindable search = '';
    @bindable buttonStyle: string | null;
    @bindable handleSearch: ((query: string) => Promise<void>) | null;
    @bindable handleClear: (() => void) | null;
    @bindable disabled = false;
    @bindable noFoundText: string;
    @bindable isEmpty: boolean;

    currentRoute = '';
    searchLoading = false;
    drawer: MdcDrawer;

    constructor(private router: Router) {
        this.router = router;
    }

    attached() {
        document.addEventListener('click', (event) => {
            const element = document.getElementById('drawer-content');
            const modal = document.getElementById('modal');
            if (element !== event.target && modal?.classList.contains('mdc-drawer--closing')) {
                this.handleSearchDrawerStyle('close');
            }
        });

        this.currentRoute = this.router.currentInstruction.config.name;
    }

    detached() {
        this.handleSearchDrawerStyle('close');
    }

    async onChange() {
        if (this.handleSearch) {
            this.searchLoading = true;
            await this.handleSearch(this.search);
        }

        this.searchLoading = false;
    }

    handleDrawerNavigationClick(menu) {
        if (this.disabled) {
            return;
        }

        this.drawer.open = !this.drawer.open;
        this.handleSearchDrawerStyle(menu);
    }

    handleSearchDrawerStyle(menu: 'open' | 'close') {
        const disableMainPageScroll = document.getElementById('main-page-host');
        if (menu === 'open') {
            disableMainPageScroll.style.overflow = 'hidden';
        } else if (menu === 'close') {
            disableMainPageScroll ? disableMainPageScroll.style.overflow = null : '';
        }
    }

    clearSearch() {
        this.search = '';
        this.searchLoading = false;
        this.handleClear?.();
    }

    get drawerClass() {
        if (this.isEmpty) {
            return 'no-results-search-drawer';
        }

        return this.searchLoading ? 'loading-results' : 'empty-search-bar';
    }
}
