// Module
var code = `<template>
    <div id="cx-currency-text-input-selector" show.bind="state != 'toText'">
        <mdc-text-field
            value.bind="amount"
            required.bind="true"
            placeholder="Enter amount"
            type="number"
            class="input-password dropdown-input"
            prefix.bind="currencySelected.symbol"
            keyup.delegate="updateContraryAmount()"
            id="\${currencyInputId}"
            focusout.delegate="currencyTextOnFocusOutToText()"
            focusin.delegate="currencyTextOnFocusIn()"
        >
            <mdc-icon mdc-text-field-icon leading class="d-grid">
                <img
                    if.bind="currencySelected.imagePath"
                    class="\${currencySelected.type === 'C' ? 'crypto-icon' : 'currency-flag'}"
                    alt="\${currencySelected.type === 'C' ? 'Crypto icon' : 'Flag icon}"
                    src="\${baseAwsEndpoint}\${currencySelected.imagePath}"
                    loading="lazy"
                    mdc-select-icon
                >
            </mdc-icon>
        </mdc-text-field>
        <mdc-select
            class="select-background text-small"
            required.bind="true"
            value.bind="currencySelected"
            ref="cxCurrencyTextInputSelect"
            click.delegate="handleClick(\$event)"
            change.delegate="selectCurrency(\$event.detail.value)"
        >
            <section data-simplebar class="lilac-scrollbar h-330 w-300">
                <mdc-list class="currency-dropdown text-black text-regular" focusout.delegate="handleFocusOut(\$event)">
                    <div class="currency-category-title text-small my-2 px-3 color-gray">
                        Most Popular
                    </div>
                    <mdc-list-item repeat.for="currency of mostPopularCurrencies" value.bind="currency">
                        <a class="dropdown-item" href="#">
                            <img id="you-give-icon" class="\${currency.type === 'C' ? 'crypto-icon' : 'currency-flag'} me-1" if.bind="currency.imagePath" src="\${baseAwsEndpoint}\${currency.imagePath}">
                            <label class="text-regular text-black pt-1">
                                \${currency.code}
                            </label>
                            <label class="text-regular text-black font-light pt-1">
                                - \${currency.shortName}
                            </label>
                        </a>
                    </mdc-list-item>
                    <div class="currency-category-title text-small mb-2 mt-3 px-3 other-currencies">
                        Other Currencies
                    </div>
                    <mdc-list-item repeat.for="currency of otherCurrencies" value.bind="currency">
                        <a class="dropdown-item" href="#">
                            <img id="you-give-icon" class="\${currency.type === 'C' ? 'crypto-icon' : 'currency-flag'} me-1" if.bind="currency.imagePath" src="\${baseAwsEndpoint}\${currency.imagePath}">
                            <label class="text-regular text-black pt-1">
                                \${currency.code}
                            </label>
                            <label class="text-regular text-black font-light pt-1">
                                - \${currency.shortName}
                            </label>
                        </a>
                    </mdc-list-item>
                </mdc-list>
            </section>
        </mdc-select>
    </div>
    <div id="cx-currency-text-input-selector" show.bind="state == 'toText'">
        <div class="text-mode">
            <span show.bind="currencySelected.type === 'F'" click.delegate="openCurrencyTextInput()">
                \${currencySelected.symbol}\${amount}
            </span>
            <span show.bind="currencySelected.type === 'C'" click.delegate="openCurrencyTextInput()">
                \${amount} \${currencySelected.symbol}
            </span>
        </div>
    </div>
</template>
`;
// Exports
export default code;