import { inject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';

@inject(Helper)
export class PreActivateStep {
    /**
     * @param {Helper} helper
     */
    constructor(private helper: Helper) {
        this.staticRoutesWithChildRoutes = {
            'customer-portal': this.helper.range(5, true),
            'sign-in': this.helper.range(2, true),
            'blog': this.helper.range(3, true),
            'blog-post': this.helper.range(2, true),
            'blog-category': this.helper.range(3, true, 2),
            'author': this.helper.range(3, true, 2),
            'order-completed': this.helper.range(2, true),
            'trade': this.helper.range(2, true),
            'locations': this.helper.range(3, true)
        };

        this.customerPortalChildRoutes = {
            '': this.helper.range(2, true),
            'profile': this.helper.range(3, true),
            'security': this.helper.range(3, true),
            'security/reset-password': this.helper.range(4, true),
            'security/two-fa-settings': this.helper.range(4, true),
            'security/withdraw': this.helper.range(4, true),
            'purchased': this.helper.range(3, true),
            'sold': this.helper.range(3, true),
            'exchange': this.helper.range(3, true),
            'order-details': this.helper.range(4, true),
            'verification': this.helper.range(3, true),
            'balance': this.helper.range(3, true),
            'support': this.helper.range(4, true),
            'balance/history': this.helper.range(4, true),
            'balance/top-up': this.helper.range(4, true),
            'balance/withdraw': this.helper.range(4, true),
            'referrals': this.helper.range(3, true),
            'subscription': this.helper.range(3, true),
            'subscription/manage-membership': this.helper.range(4, true),
            'subscription/cancel-membership': this.helper.range(4, true),
            'subscription/payment-details': this.helper.range(4, true),
            'subscription/cashback': this.helper.range(4, true),
            'orders': this.helper.range(3, true),
        };
    }

    staticRoutesWithChildRoutes;
    customerPortalChildRoutes;
    routeName;
    childRoute;

    run(navigationInstruction, next) {
        this.childRoute = navigationInstruction.params.childRoute;
        this.routeName = navigationInstruction.config.name;

        const baseRoute = this.staticRoutesWithChildRoutes[this.routeName]
            ? this.routeName
            : this.routeName.replace(/-\w+$/, '');

        if (navigationInstruction.params.childRoute) {
            const foundRoute = navigationInstruction.router.routes.find(x => x.name === baseRoute);
            const foundRouteCountSlashes = foundRoute?.route?.split('/');
            const currentUrlSlashes = navigationInstruction.fragment.slice(1).split('/');

            if (this.childRoute.includes('order-details')) {
                this.childRoute = this.childRoute.split('/')[0];
            }

            if (foundRoute?.name === 'customer-portal' && !this.customerPortalChildRoutes[this.childRoute]?.includes(currentUrlSlashes?.length)) {
                navigationInstruction.router.navigateToRoute('404');
            } else if ((currentUrlSlashes?.length !== foundRouteCountSlashes?.length && !this.staticRoutesWithChildRoutes[baseRoute])
                || (currentUrlSlashes?.length !== foundRouteCountSlashes?.length && this.staticRoutesWithChildRoutes[baseRoute] && !this.staticRoutesWithChildRoutes[baseRoute].includes(currentUrlSlashes?.length))) {
                navigationInstruction.router.navigateToRoute('404');
            }
        }
        return next();
    }
}
