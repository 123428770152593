// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/triangle-right.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-order-row" ref="orderRow">
        <cx-list-item class="\${!noHover ? 'hover-effects' : ''}">
            <div class="order-row \${!isClickable ? 'cursor-default' : 'cursor-pointer'}">
                <div class="row-container">
                    <div if.bind="!isPhone" class="w-100 d-flex justify-content-between align-items-center">
                        <div class="id-container" if.bind="!isSubscription || !isOrdercompleted">
                            <div class="payment-method-icon">
                                <cds-icon
                                    icon.bind="iconPath"
                                    variant.bind="iconVariant"
                                    label="Order row icon"
                                    color="#4300a9">
                                </cds-icon>
                            </div>
                            <p>\${order.id}</p>
                        </div>
                        <div class="d-flex align-items-center subscription-container" if.bind="isSubscription && isOrdercompleted">
                            <cx-user-avatar
                                user.bind="user"
                                force-subscribe.bind="true"
                                size.bind="40"
                            ></cx-user-avatar>
                            <div>
                                <p class="text-truncate">\${ productName }</p>
                                <p if.bind="order.subscription.product.isFreeTrial">Free trial - \$0.00</p>
                            </div>
                        </div>
                        <p if.bind="!isSubscription || !isOrdercompleted" class="option-width \${totalStyle}">\${orderTotal & async}</p>
                        <p if.bind="!isSmallOrder && !isSubscription && !isCashback" class="option-width">\${orderType}</p>
                        <!-- Message -->
                        <p if.bind="isSmallOrder" class="option-width text-truncate" mdc-tooltip="value.bind: orderLastMessage">
                            \${ orderLastMessage }
                        </p>
                        <!-- Operation-->
                        <p if.bind="isCashback" class="option-width">
                            \${ transactionType }
                        </p>

                        <!-- Website -->
                        <p if.bind="!isSmallOrder && !isSubscription && !isCashback" class="option-width">\${ order.websiteName }</p>
                        <p if.bind="isCashback" class="option-width">\${ websiteName }</p>

                        <p class="option-width" if.bind="!isSubscription || !isOrdercompleted">\${order.date}</p>
                        <p class="valid-width" if.bind="isOrdercompleted && isSubscription && user.subscriptions.length > 0">Valid until \${user.subscriptions[0].renewalDate | dateFormatter:'format':'MMMM DD, YYYY'}</p>
                        <p class="valid-width" if.bind="isOrdercompleted && isSubscription && user.subscriptions.length === 0">-</p>
                        <!-- Activity -->
                        <p if.bind="order.orderChat.updatedDate && isSmallOrder  && !isSubscription" class="option-width">
                            \${order.orderChat.updatedDate | dateFormatter:'dateFromNow' }
                        </p>
                        <p if.bind="!order.orderChat.updatedDate && isSmallOrder || (isSubscription && !isOrdercompleted)" class="option-width">-</p>
                        <p if.bind="isSubscription && isOrdercompleted" class="option-width \${totalStyle}">\${orderTotal & async}</p>
                        <!-- Status -->
                        <p if.bind="!isCashback && !isReferral" class="status-width \${statusStyle}">\${orderStatus}</p>
                        <!-- Cashback -->
                        <p if.bind="isCashback || isReferral" class="option-width text-green">
                            +\${ earning | currencyFormat:order.baseCurrency.symbol:order.baseCurrency.type:order.baseCurrency.isStable }
                        </p>
                    </div>
                    <div if.bind="isCashback && isPhone" class="payment-method-icon align-self-center">
                        <cds-icon
                            icon.bind="iconPath"
                            variant.bind="iconVariant"
                            label="Order row icon"
                            color="#4300a9">
                        </cds-icon>
                    </div>
                    <div if.bind="isPhone" class="w-100 d-flex flex-column justify-content-between">
                        <div class="mobile-top-row">
                            <div if.bind="!isCashback" class="payment-method-icon">
                                <cds-icon
                                    icon.bind="iconPath"
                                    variant.bind="iconVariant"
                                    label="Order row icon"
                                    color="#4300a9">
                                </cds-icon>
                            </div>
                            <div class="mobile-top-data \${ isCashback ? 'flex-fill' : '' }">
                                <div class="mobile-order-data">
                                    <p if.bind="!isCashback">ID</p>
                                    <p class="data">\${order.id}</p>
                                </div>
                                <div if.bind="!isCashback" class="mobile-order-data">
                                    <p>Amount</p>
                                    <p class="\${totalStyle} data">\${orderTotal & async}</p>
                                </div>
                                <div if.bind="!isSmallOrder && !isCashback" class="mobile-order-data">
                                    <p>Type</p>
                                    <p class="data">\${orderType}</p>
                                </div>
                                <div if.bind="isCashback" class="mobile-order-data">
                                    <p class="data text-end">\${ websiteName }</p>
                                </div>
                            </div>
                        </div>
                        <div class="mobile-middle-row \${ isCashback ? 'justify-content-between' : '' }">
                            <!-- Message -->
                            <div if.bind="isSmallOrder" class="mobile-order-data \${ !isCashback ? 'w-100' : '' }">
                                <p class="">Message</p>
                                <p class="text-truncate data">
                                    \${ orderLastMessage }
                                </p>
                            </div>
                            <div if.bind="isCashback" class="mobile-order-data">
                                <p class="\${totalStyle} data">\${orderTotal & async}</p>
                            </div>
                            <div if.bind="isCashback" class="mobile-order-data">
                                <p class="data text-end">\${order.date}</p>
                            </div>
                        </div>
                        <div class="mobile-bottom-row">
                            <div if.bind="isCashback" class="mobile-order-data">
                                <p class="data">\${ transactionType }</p>
                            </div>
                            <div if.bind="!isSmallOrder && !isCashback" class="mobile-order-data">
                                <p>Website</p>
                                <p class="data">\${order.websiteName}</p>
                            </div>
                            <div if.bind="!isCashback" class="mobile-order-data">
                                <p>Date</p>
                                <p class="data">\${order.date}</p>
                            </div>
                            <!-- Activity -->
                            <div if.bind="isSmallOrder && !isCashback" class="mobile-order-data">
                                <p class=>ACTIVITY</p>
                                <p if.bind="order.orderChat.updatedDate" class="data">
                                    \${order.orderChat.updatedDate | dateFormatter:'dateFromNow' }
                                </p>
                                <p else class="data">-</p>
                            </div>
                            <div if.bind="!isCashback" class="mobile-order-data">
                                <p>Status</p>
                                <p class="\${statusStyle} data">\${orderStatus}</p>
                            </div>
                            <div if.bind="isCashback" class="mobile-order-data">
                                <p class="data text-green text-nowrap text-end">
                                    +\${ earning | currencyFormat:order.baseCurrency.symbol:order.baseCurrency.type:order.baseCurrency.isStable }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div if.bind="!isPhone && !noHover && isClickable" class="arrow-style">
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="navigation icon" loading="lazy">
                </div>
            </div>
        </cx-list-item>
    </div>
</template>
`;
// Exports
export default code;