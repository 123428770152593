import './cx-password-input.scss';
import { bindable, inject, NewInstance } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/helpers/helper';
import { ToastService } from 'services/toast-service';

import { ControllerValidateResult, validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { EventAggregator } from 'aurelia-event-aggregator';

@inject(SessionService, Helper, ToastService, NewInstance.of(ValidationController), EventAggregator)
export class CxPasswordInput {
    constructor(
        private sessionService: SessionService,
        private helper: Helper,
        private notification: ToastService,
        private validator: ValidationController,
        private eventAggregator: EventAggregator
    ) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    @bindable name: string = 'Password';
    @bindable showValidator = false;
    @bindable isCurrent: boolean = false;
    @bindable verifyCurrent;
    @bindable showGreenCheckMark: boolean = false;
    @bindable showErrorCheckMark: boolean = false;
    @bindable password = undefined;
    @bindable labelClasses;
    @bindable onGreenCheckMark: (arg?: string | boolean) => void = () => { };
    @bindable backButton;
    @bindable forgotDelegate = null;
    @bindable pageLoading = false;
    firedFunction = false;
    firedUpdatedPasswordError = false;
    showMiniSpinner = false;
    toastSent = false;
    defaultTimeout = 2000;


    miniSpinnerStopwatch: NodeJS.Timeout;
    passwordStopWatch: NodeJS.Timeout;
    passwordStopWatch2: NodeJS.Timeout;
    avoidNotification: boolean;
    passwordValid: boolean;
    fromKeyPress: boolean;
    validatorProperty: ControllerValidateResult;

    parent;
    focusInStopWatch: NodeJS.Timeout;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    onFocusIn() {
        this.showGreenCheckMark = this.showErrorCheckMark = this.showMiniSpinner = this.firedFunction = this.firedUpdatedPasswordError = this.toastSent = false;
        this.validator.reset();
        if (!document.hasFocus()) return;

        this.focusInStopWatch = setTimeout(() => {
            if (this.password === undefined) return;
            this.updatedOnKeyPress();
        });
    }

    counterValidations = (counterValidation, results, setZero = false) => {
        counterValidation += results.filter(x => x.valid && x.rule.messageKey !== 'maxLength').length;
        if (setZero && !this.isRuleValid(results, 'maxLength')) counterValidation = 0;
        return counterValidation;
    };

    isRuleValid = (results, rule = 'minLength') => results.find(x => x.rule.messageKey === rule).valid;


    makeError() {
        this.showGreenCheckMark = this.showMiniSpinner = false;
        this.showErrorCheckMark = true;
    }

    makeDefault() {
        this.showErrorCheckMark = this.showMiniSpinner = false;
    }

    resetVariables() {
        this.clearTimeouts();
        this.password = undefined;
        this.showGreenCheckMark = this.showErrorCheckMark = null;
    }

    clearTimeouts() {
        this.helper.clearTimeouts([this.miniSpinnerStopwatch, this.passwordStopWatch, this.passwordStopWatch2, this.focusInStopWatch]);
    }


    async verifyCurrentPassword(password) {
        return this.verifyCurrent
            ? await this.verifyCurrent(password)
            : await this.sessionService.verifyPassword(password);
    }

    async checkResponse(response) {
        this.avoidNotification = false;
        this.passwordValid = response;
        if (typeof (response) === 'boolean') return;
        const data = response;
        this.passwordValid = Boolean(data?.isValid);
        this.avoidNotification = data?.avoidNotification;
    }

    async checkValidation() {
        if (this.fromKeyPress || !document.hasFocus()) {
            this.fromKeyPress = false;
            return;
        }
        this.showMiniSpinner = true;
        this.avoidNotification = false;
        this.clearTimeouts();
        if (this.isCurrent) ValidationRules.ensure('password').required().minLength(8).maxLength(100).on(this);
        else ValidationRules.ensure('password').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/).on(this);

        const rules = await this.validator.validate();
        let validationsCounter = 0;
        if (this.password) {
            if (this.isCurrent) this.passwordValid = await this.verifyCurrentPassword(this.password);
            else validationsCounter = this.counterValidations(validationsCounter, this.validatorProperty.results, true);
        }

        this.showMiniSpinner = false;
        this.checkResponse(this.passwordValid);

        if (this.password === undefined || this.firedFunction) return;
        if (this.areValidated(validationsCounter)) {
            this.showErrorCheckMark = false;
            if (this.parent.checkGreenMarks(this.name)) {
                this.firedFunction = true;
                await this.parent.updatePassword();
                return;
            }
            if (this.parent.checkUnvalid(this.name)) this.showGreenCheckMark = true;
            else this.showMiniSpinner = false;
            return;
        }

        if (rules.valid && this.passwordValid && this.parent.checkGreenMarks(this.name) && !this.firedFunction) {
            await this.parent.updatePassword();
        }

        if (this.password === undefined || this.firedFunction) return;
        if (this.parent.showGreenChecker(rules) && this.passwordValid) {
            this.showMiniSpinner = this.showErrorCheckMark = false;
            this.showGreenCheckMark = true;
            return;
        }
        if (this.parent.successFunction) return;
        this.showErrorCheckMark = true;
        if (this.toastSent || this.firedUpdatedPasswordError || this.avoidNotification) return;
        this.notification.showToast('Error', `Please enter a valid ${this.name.toLowerCase()}.`, 'error');
    }

    areValidated = (counter) => counter >= 3 && this.isRuleValid(this.validatorProperty.results);

    async updatedOnKeyPress() {
        this.avoidNotification = this.showGreenCheckMark = this.showErrorCheckMark = this.showMiniSpinner = this.toastSent = this.firedFunction = this.parent.successFunction = false;
        ValidationRules.ensure('password').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/).on(this);
        this.validatorProperty = await this.validator.validate();
        let validationsCounter = 0;
        if (this.password) validationsCounter = this.counterValidations(validationsCounter, this.validatorProperty.results);
        if (validationsCounter < 3) {
            this.showGreenCheckMark = this.showErrorCheckMark = false;
        }
        this.clearTimeouts();
        await this.validator.reset();
        if (this.password === undefined) return;
        this.miniSpinnerStopwatch = setTimeout(() => this.showMiniSpinner = true, 1000);
        this.passwordStopWatch = setTimeout(async () => {
            this.fromKeyPress = true;
            if (this.showValidator) {
                let validationsCounterTwo = 0;
                if (this.password) validationsCounterTwo = this.counterValidations(validationsCounterTwo, this.validatorProperty.results, true);
                if (this.areValidated(validationsCounterTwo)) {
                    this.showErrorCheckMark = false;
                    if (this.parent.checkGreenMarks(this.name)) await this.fireAndUpdate();
                    else if (this.parent.checkUnvalid()) this.showGreenCheckMark = true;
                    this.showMiniSpinner = false;
                    return;
                }
            }
            if (this.isCurrent) {
                ValidationRules.ensure('password').required().minLength(6).maxLength(100).on(this);
                const rules = await this.validator.validate();
                this.passwordValid = await this.verifyCurrentPassword(this.password);

                this.checkResponse(this.passwordValid);

                if (rules.valid && this.passwordValid) {
                    if (this.parent.checkGreenMarks(this.name)) await this.fireAndUpdate();
                    else if (this.parent.showGreenChecker(rules)) {
                        this.showMiniSpinner = this.showErrorCheckMark = false;
                        this.showGreenCheckMark = true;
                    }
                    this.showMiniSpinner = false;
                    return;
                }
            }
            await this.validator.reset();
            this.showErrorCheckMark = this.toastSent = true;
            this.showMiniSpinner = false;
            if (this.avoidNotification) return;
            this.notification.showToast('Error', `Please enter a valid ${this.name.toLowerCase()}.`, 'error');
        }, this.defaultTimeout);
    }

    async fireAndUpdate() {
        this.firedFunction = true;
        await this.parent.updatePassword();
    }

    showGreenCheckMarkChanged() {
        if (this.showGreenCheckMark) this.onGreenCheckMark?.(this.name);
    }

    async clickForgot() {
        this.forgotDelegate?.();
    }

}
