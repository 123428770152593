// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/finger_filled.svg", import.meta.url);
// Module
var code = `<template>
    <div id="document-upload">
        <button class="verification-button justify-content-between w-100" if.bind="!user.idVerified" click.delegate="openVeriff()">
            <div class="text-large-plus d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="Upload" loading="lazy" width="24" height="24">
                \${'CX_VERIFICATION' | siteStrings}
            </div>
            <div class="d-flex align-items-center position-relative">
                <span class="material-icons text-cx-purple me-1 mt-2 text-extra-large-static arrow-icon">chevron_right</span>
            </div>
            <cx-veriff show.bind="false" user.bind="user" class="d-block"></cx-veriff>
        </button>
        <mdc-expandable class="purchase-flow-expandable verification-expandable" if.bind="user.idVerified" open.two-way="expandableOpen">
            <div class="text-large-plus d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="Upload" loading="lazy" width="24" height="24">
                \${'CX_VERIFICATION' | siteStrings}
            </div>
            <div show.bind="expandableOpen">
                <mdc-data-table busy.bind="busy" class="w-100 dt-display">
                    <table>
                        <thead>
                            <th class="font-regular text-darkergray">\${'CX_NAME' | siteStrings}</th>
                            <th class="font-regular text-darkergray">\${'CX_DATE_OF_BIRTH' | siteStrings}</th>
                            <th class="font-regular text-darkergray">\${'CX_ADDRESS' | siteStrings}</th>
                        </thead>
                        <tbody>
                            <tr mdc-data-table-row>
                                <td class="d-flex align-items-center">
                                    \${ userVeriffData ? userVeriffData.firstName : user.firstName | fullname: userVeriffData ? userVeriffData.lastName : user.lastName }
                                    <img if.bind="user.idVerified" class="d-block checkmark" alt="checkmark" loading="lazy">   
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        \${ userBirth }
                                        <img if.bind="user.idVerified" class="d-block checkmark" alt="checkmark" loading="lazy">  
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        \${ userVeriffData && userVeriffData.addresses.length > 0 ? userVeriffData.addresses[0].fullAddress : checkUserBillingAddress() ? getUserBillingAddress() : '-' | capitalizeText:'sentence' }
                                        <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                            if.bind="userVeriffData && userVeriffData.addresses.length || checkUserBillingAddress()" 
                                            mdc-tooltip="This is the address that was collected from your ID verification. To update, or confirm, you may visit your profile settings at any time after this process."
                                            aria-label="tooltip">
                                            contact_support
                                        </mdc-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mdc-data-table>
                <div class="d-sm-none">
                    <div class="d-flex align-items-center mb-2 text-normal">
                        <span class="text-darkergray">\${'CX_NAME' | siteStrings}</span>
                        <span class="ms-2">\${ userVeriffData ? userVeriffData.firstName : user.firstName | fullname: userVeriffData ? userVeriffData.lastName : user.lastName }</span>
                        <img if.bind="user.idVerified" class="d-block checkmark" alt="checkmark" loading="lazy">
                    </div>
                    <div class="d-flex align-items-center mb-2 text-normal">
                        <span class="text-darkergray">\${'CX_DATE_OF_BIRTH' | siteStrings}</span>
                        <span class="ms-2">\${ userBirth }</span>
                        <img if.bind="user.idVerified" class="d-block checkmark" alt="checkmark" loading="lazy">
                    </div>
                    <div class="d-flex align-items-center text-normal">
                        <span class="text-darkergray">\${'CX_ADDRESS' | siteStrings}</span>
                        <span class="ms-2">\${ userVeriffData.addresses.length > 0 ? userVeriffData.addresses[0].fullAddress : checkUserBillingAddress() ? getUserBillingAddress() : '-' | capitalizeText:'sentence' }</span>
                        <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                            if.bind="userVeriffData && userVeriffData.addresses.length || checkUserBillingAddress()"
                            mdc-tooltip="This is the address that was collected from your ID verification. To update, or confirm, you may visit your profile settings at any time after this process."
                            aria-label="tooltip">
                            contact_support
                        </mdc-icon>
                    </div>
                </div>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;