// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/chicks/to_space_chick.svg", import.meta.url);
// Module
var code = `<template>
    <div id="coming-soon" class="d-flex flex-column align-items-center pe-3 ps-3">
        <div class="header font-medium text-huge text-center text-black mt-4 mb-2 lh-base px-lg-5">
            All your favorite payment methods are coming soon
        </div>
        <div class="subheader font-regular text-medium text-center text-soft-black mb-4 opacity-75">
            Connect to live chat to buy or sell now
        </div>
        <img class="img-fluid mb-3" src="${___HTML_LOADER_IMPORT_0___}" alt="Chick with space suit going to space" loading="lazy">
        <cx-button
            click.delegate="openIntercom()"
            class="mb-5 pt-3"
            button-text="Connect to live chat"
            button-style.bind="'secondary font-medium text-regular'"
        ></cx-button>
    </div>
</template>
`;
// Exports
export default code;