import { ApiService } from './api-service';
import { autoinject } from 'aurelia-framework';
import { websiteShortCode } from 'environment';

@autoinject()
export class NotificationService {
    path = 'Notification';

    constructor(private api: ApiService) {}

    async getLatestNotifications(userId:number, count:number, includeHidden:boolean) {
        const params = {
            userId: userId,
            websiteShortCode: websiteShortCode(),
            count:count,
            includeHidden: includeHidden
        };

        return await this.api.doGet(this.path + '/GetLatestNotification', params);
    }

    async markAsRead(notificationsId: number[], state: boolean) {
        const data = {
            ids: notificationsId,
            state: state
        };

        return await this.api.doPost(this.path + '/MarkAsRead', data);
    }

    async markAsHidden(notificationsId: number[]) {
        return await this.api.doPost(this.path + '/MarkAsHidden', notificationsId);
    }

    async getAllUnreadCount(userId: number) {
        const params = {
            userId: userId,
            websiteShortCode: websiteShortCode()
        };
        return await this.api.doGet(this.path + '/unreadCount', params);
    }

    async delete(notificationsId: number[]) {
        return await this.api.doDelete(this.path + '/Delete', notificationsId);
    }
}
