import './cx-faq-category-card.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';

export class CxFaqCategoryCard {
    @bindable title: string;
    @bindable iconName: string;
    @bindable categoryId: number;
    @bindable active: boolean;

    isStaticAsset = true;
    baseAwsUrl: string;

    constructor() {
        this.baseAwsUrl = getAWSBucketEndpoint('icons');
    }

    activate() {
        this.active = true;
    }

    deactivate() {
        this.active = false;
    }

    attached() {
        this.isStaticAsset = !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(this.iconName);
    }
}
