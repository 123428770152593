import './cx-gallery-dialog.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { apiEndpoint } from 'environment';

@autoinject()
export class CxGalleryDialog {
    @bindable dialog;
    @bindable opened;
    index;
    imagesList;
    environment = apiEndpoint();
    imagesListDom;
    showLeftArrow;
    showRightArrow;

    activate(params) {
        this.imagesList = params['imagesList'];
        this.index = params['index'];

        this.updateCarouselControls();
    }

    open() {
        this.imagesListDom = document.querySelector('.mdc-dialog--open').getElementsByClassName('carousel-item');
        this.dialog.open = !this.dialog.open;
    }

    close() {
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.imagesList = [];
    }

    backImage() {
        this.imagesListDom = document.querySelector('.mdc-dialog--open').getElementsByClassName('carousel-item');

        if (this.index > 0) {
            this.imagesListDom[this.index].classList.remove('active');
            this.index--;
            this.imagesListDom[this.index].classList.add('active');
        }

        this.updateCarouselControls();
    }

    nextImage() {
        this.imagesListDom = document.querySelector('.mdc-dialog--open').getElementsByClassName('carousel-item');

        if (this.index < this.imagesList.length - 1) {
            this.imagesListDom[this.index].classList.remove('active');
            this.index++;
            this.imagesListDom[this.index].classList.add('active');
        }

        this.updateCarouselControls();
    }

    updateCarouselControls() {
        this.showRightArrow = this.index < this.imagesList.length - 1 ? true : false;
        this.showLeftArrow = this.index > 0 ? true : false;
    }
}
