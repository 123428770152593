import numeral from 'numeral';
import { CurrencyService } from 'services/currency-service';
import { autoinject } from 'aurelia-dependency-injection';
import { SessionService } from 'services/session-service';

@autoinject()
export class FiatCurrencyFormatValueConverter {
    signals = ['currency-change'];

    constructor(
        private currencyService: CurrencyService,
        private sessionService: SessionService
    ) { }

    async toView(value: number, format: string | null = null, currencyUsed: string = null, avoidConversion: boolean = false, forceRate?: number) {
        if (value === null || value === undefined) {
            return '0';
        }

        format ??= '(0,0.00)';

        const currency = await this.sessionService.getCurrency();
        const symbol = this.getCurrencyPrefix(currencyUsed ?? currency);
        value = await this.getConvertedPrice(value, currency, avoidConversion, forceRate);

        return `${symbol}${numeral(value).format(format)}`;
    }

    async getConvertedPrice(value: number, currentCurrency: string, avoidConversion: boolean = false, forceRate?: number) {
        if (avoidConversion) return value;

        const rate = forceRate ?? await this.currencyService.getStoredCurrencyRates(currentCurrency);

        if (!rate) {
            return 1;
        }

        const total = this.evenRound(value, 5);
        return total * rate;
    }

    getCurrencyPrefix(currentCurrency: string) {
        const activeCurrencies = this.currencyService.getStoredActiveCurrenciesByWebsite();
        return activeCurrencies.find(x => x.code === currentCurrency)?.symbol || 'CURRENCY-ERROR';
    }

    /**
     * This function performs even rounding on a number.
     *
     * It accepts a number and the desired decimal places and then applies even rounding strategy.
     * In even rounding (also known as bankers' rounding / Gaussian rounding / statistician's rounding)
     * a number which is exactly halfway between two others is rounded towards the nearest even number.
     *
     * @param {number} num - The number to be rounded.
     * @param {number} decimalPlaces - The number of decimal places to round to.
     *
     * @return {number} The rounded number.
     */
    evenRound(num: number, decimalPlaces: number) {
        const d = decimalPlaces || 0;
        const m = Math.pow(10, d);
        const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
        const i = Math.floor(n); const f = n - i;
        const e = 1e-8; // Allow for rounding errors in f
        const r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n);
        return d ? r / m : r;
    }
}
