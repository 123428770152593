import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { IReceivingPaymentMethodRequest, ReceivingPaymentMethodResponse } from './models/user/user';

@inject(ApiService)
export class ReceivingPaymentMethodService {
    path = 'ReceivingPaymentMethod';

    constructor(
        private api: ApiService
    ) {
    }

    async getById(id: number) {
        return await this.api.doGet(`${this.path}/${id}`) as ReceivingPaymentMethodResponse | null;
    }

    async add(data: IReceivingPaymentMethodRequest) {
        return await this.api.doPost(this.path, data) as ReceivingPaymentMethodResponse | null;
    }

    async delete(paymentMethodId: number) {
        return await this.api.doDelete(`${this.path}/DeleteByPaymentMethodId/${paymentMethodId}`) as boolean;
    }
}
