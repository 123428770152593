import { PageContentAreaService } from 'services/page-content-area-service';
import './sitemap.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';
import { ProductCategoryService } from 'services/product-category-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { Router } from 'aurelia-router';
import { apiEndpoint } from 'environment';
import { ProductCatWithGame } from 'services/models/game/productCategory';

@autoinject()
export class Sitemap {
    constructor(
        private websiteService: WebsiteService,
        private productCategoryService: ProductCategoryService,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private router: Router,
        private pageContentAreaService: PageContentAreaService
    ) { }

    environment = apiEndpoint();
    pageRoutes;
    blogRoute;
    siteMapPageRoute;
    categories = [
        { name: 'ChicksX', icon: null, url: `${this.environment}Image/chicks-icons/x.svg`, pages: [], keyName: 'CX_SITENAME' },
        { name: 'Support', icon: 'contact_support', pages: [], keyName: 'CX_SUPPORT' },
        { name: 'Legal', icon: 'description', pages: [], keyName: 'CX_LEGAL' },
        { name: 'Buy crypto', icon: 'shopping_bag', pages: [], keyName: 'CX_BUY_CRYPTO' },
        { name: 'Sell crypto', icon: 'sell', pages: [], keyName: 'CX_SELL_CRYPTO' },
        { name: 'Exchange', icon: 'currency_exchange', pages: [], keyName: 'CX_EXCHANGE' },
    ];

    searchQuery = '';
    filteredCategories = [];
    navCategory: ProductCatWithGame;
    cryptoList = [];
    drawer;
    maxAmount = 6;
    selectedCategory;
    selectedPage;
    breadcrumbs;
    sizeChanged;
    width;
    routerSubscription;
    noResults: boolean;

    async activate() {
        this.pageRoutes = await this.websiteService.getPagesByWebsiteShortcode();
    }

    async attached() {
        const pathName = window.location?.pathname;
        const slugRoute = pathName?.split('/');
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
        this.navCategory = await this.productCategoryService.getNavCategory('trade');
        this.cryptoList = this.navCategory?.gameForNav || [];
        this.blogRoute = this.pageRoutes.find(x => x.name.toLowerCase() === 'blog')?.routeName ?? 'blog';
        this.siteMapPageRoute = this.pageRoutes.find(x => x.name.toLowerCase() === 'sitemap')?.routeName ?? 'sitemap';
        const buyCryptoCategory = this.getCategoryByName('Buy crypto');
        const sellCryptoCategory = this.getCategoryByName('Sell crypto');
        const exchangeCategory = this.getCategoryByName('Exchange');
        const tradePageRoute = `/${this.pageRoutes.find(x => x.name.toLowerCase() === 'trade')?.routeName ?? 'trade'}`;
        this.cryptoList.forEach(x => {
            const cryptoPage = {
                name: x.name,
                slug: `${tradePageRoute}/${x.slugName}`,
                keyName: `CX_${x.shortName}`
            };

            const categoryMap = {
                'Buy': buyCryptoCategory,
                'Sell': sellCryptoCategory,
                'Exchange': exchangeCategory
            };

            const categoryName = Object.keys(categoryMap).find(category => x.name.includes(category));
            if (!categoryName) return;
            categoryMap[categoryName].pages.push(cryptoPage);
        });

        const chicksX = {
            pages: [
                // Removed 3 KeyName properties from the object below temporarily. Awaiting fully dynamic implementation.
                { name: 'Mississauga Location', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'mississauga')?.routeName ?? 'locations/mississauga', keyName: 'CX_MISSISSAUGA_LOCATION' },
                { name: 'Vancouver Location', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'vancouver')?.routeName ?? 'locations/vancouver', keyName: 'CX_VANCOUVER_LOCATION' },
                { name: 'Calgary Location', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'calgary')?.routeName ?? 'locations/calgary', keyName: 'CX_CALGARY_LOCATION' },
                { name: 'Careers', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'careers')?.routeName ?? 'careers', keyName: 'CX_CAREERS' },
                { name: 'Blog', slug: this.blogRoute, keyName: 'CX_BLOG' },
                { name: 'CSR', slug: this.blogRoute + '/category/csr', keyName: 'CX_CSR' },
                { name: 'Buy Crypto', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'trade')?.routeName ?? 'trade', keyName: 'CX_BUY_CRYPTO' },
                { name: 'Sell Crypto', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'trade')?.routeName ?? 'trade', keyName: 'CX_SELL_CRYPTO' },
                { name: 'Bug Bounty', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'bug-bounty')?.routeName ?? 'bug-bounty', keyName: 'CX_BUG_BOUNTY' },
            ]
        };
        this.categories.find(x => x.name === 'ChicksX').pages = chicksX.pages;

        const supportPages = {
            pages: [
                { name: 'FAQ', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'faq')?.routeName ?? 'faq', keyName: 'CX_FAQ' },
                { name: 'Contact us', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'contact')?.routeName ?? 'contact', keyName: 'CX_CONTACT_US' },
                { name: 'Crypto Asset Statements', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'crypto asset statements')?.routeName ?? 'crypto-asset-statements', keyName: 'CX_CRYPTO_ASSET_STATEMENTS' },
            ]
        };
        this.categories.find(x => x.name === 'Support').pages = supportPages.pages;

        const legalPages = {
            pages: [
                { name: 'Terms of Service', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'terms of service')?.routeName ?? 'terms-of-service', keyName: 'CX_TERMS_OF_SERVICE' },
                { name: 'Privacy Policy', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'privacy policy')?.routeName ?? 'privacy-policy', keyName: 'CX_PRIVACY_POLICY' },
                { name: 'Cookies Policy', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'cookies policy')?.routeName ?? 'cookies', keyName: 'CX_COOKIES' },
            ]
        };
        this.categories.find(x => x.name === 'Legal').pages = legalPages.pages;

        this.filteredCategories = [...this.categories];
        this.checkCategoryByRoute(slugRoute);
    }

    detached() {
        this.sizeChanged?.dispose();
        this.routerSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);

        this.routerSubscription = this.eventAggregator.subscribe('router:navigation:success', payload => {
            const slugRoute = payload.instruction?.fragment?.split('/');
            this.checkCategoryByRoute(slugRoute);
            this.clearSearch();
        });
    }

    search() {
        const lowerCaseQuery = this.searchQuery.toLowerCase();
        if (this.selectedCategory) this.categorySelected(this.selectedCategory.name);
        this.filteredCategories = this.categories.map(category => ({
            name: category.name,
            icon: category.icon,
            url: category.url,
            pages: category.pages.filter(page => page.name.toLowerCase().includes(lowerCaseQuery)),
            keyName: category.keyName
        }));
        this.noResults = this.searchQuery?.trim() !== '' && this.filteredCategories.every(category => category.pages.length === 0);
    }

    searchDesktop = () => this.search();

    searchMobile = () => {
        this.search();
        if (this.searchQuery.trim() === '') return;
    };

    handleDrawerOpenClick() {
        this.drawer.open = !this.drawer.open;
    }

    clearSearch = () => {
        this.searchQuery = '';
        this.search();
    };

    compareToSearch = (arrayValues) => arrayValues.some(x => x?.toLowerCase()?.includes(this.searchQuery?.toLowerCase()));

    compareToFilter(category) {
        category.pages = category.pages.filter(x => this.compareToSearch([x.name]));
        return category;
    }

    setBreadCrumb() {
        this.breadcrumbs = [
            {
                title: 'Sitemap',
                titleKey: 'CX_SITEMAP',
                href: this.siteMapPageRoute
            }
        ];

        if (this.selectedCategory?.name) {
            this.breadcrumbs.push(
                {
                    title: this.selectedCategory.name,
                    titleKey: `CX_${this.selectedCategory.name.split(' ').join('_').toUpperCase()}`,
                    isHome: this.checkIfCategoryIsSelected(this.selectedCategory.name),
                    alternativeRoute: this.checkIfCategoryIsSelected(this.selectedCategory.name) ? `${this.siteMapPageRoute}/${this.selectedCategory.name?.toLowerCase()}` : undefined,
                    isActive: this.selectedCategory.name === 'Sell' !== this.checkIfCategoryIsSelected(this.selectedCategory?.name)
                }
            );
        }

        if (this.selectedPage) {
            this.breadcrumbs.push({
                title: this.selectedPage,
                titleKey: `${this.selectedPage.split(' ').join('_').toUpperCase()}`,
                isActive: true });
        }

        this.eventAggregator.publish('breadcrumb-update', { breadcrumbs: this.breadcrumbs });
    }

    categorySelected(category, page?) {
        this.selectedCategory = structuredClone(this.categories).find(x => x.name === category);
        this.selectedPage = page;
        if (!this.selectedCategory) return;
        if (this.searchQuery) this.compareToFilter(this.selectedCategory);
        this.selectedCategory.pages = this.helper.separateByChunks(this.checkIfCategoryIsSelected(this.selectedCategory.name)
            ? (this.selectedPage ? this.selectedCategory.pages?.find(x => x.name === this.selectedPage)?.pages : this.selectedCategory.pages)
            : this.selectedCategory.pages, this.maxAmount
        );
        this.setBreadCrumb();
    }

    checkIfCategoryIsSelected = (category) => this.selectedCategory.name === category;

    getCategoryByName = (categoryName) => this.categories.find(x => x.name === categoryName);

    backButtonFunction = () => this.selectedCategory = null;

    checkCategoryByRoute = (slugRoute) => {
        const checkIfSubPageExists = slugRoute.length > 2;
        const route = checkIfSubPageExists ? slugRoute[2] : slugRoute.pop();
        let subPage;
        if (checkIfSubPageExists && slugRoute.length > 3) subPage = this.helper.toCapitalize(slugRoute.pop(), 'first');
        if (!route) return;
        let category = checkIfSubPageExists ? route : this.router.routes.find(x => x.route === route)?.name;
        category = this.helper.toCapitalize(category, 'first')?.replaceAll('-', ' ');
        if (category === 'Chicksx') category = this.helper.toCapitalizeLastLetter(category);
        this.categorySelected(category, subPage);
    };
}
