import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { TrackingData } from './models/purchase-flow/tracking-data';

@autoinject()
export class EasyPostService {
    path = 'Tracker';

    constructor(private api: ApiService) { }

    getTrackingData(trackingNumber: string): Promise<TrackingData> {
        return this.api.doGet(this.path + '?trackingNumber=' + trackingNumber);
    }
}
