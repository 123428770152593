// Module
var code = `<template>
    <div id="pseudo-table" class="pseudo-table mb-2 cursor-pointer">
        <hr class="divisor-gray divisor">
        <div class="d-flex justify-content-between mb-2">
            <div if.bind="ticket" class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-1">Tickets Number</span>
                <span class="text-small text-underline cursor-pointer" click.delegate="switchToDetails(index)">\${ ticket.ticketKey }</span>
            </div>
            <div else class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold">Product Details</span>
                <span class="text-small">\${ order.coinFrom } - \${ order.coinTo }</span>
            </div>

            <div if.bind="ticket" class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-1">Date</span>
                <span class="text-small truncated-text" mdc-tooltip="value.bind: ticket.date;">\${ ticket.date | textTruncate:10 }</span>
            </div>
            <div else class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold">Quantity</span>
                <span class="text-small">\${ order.quantity }</span>
            </div>
            <div if.bind="ticket" class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-1">Recent Activity</span>
                <span class="text-small">\${ ticket.recentActivity }</span>
            </div>
            <div else class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-1">Status</span>
                <span>                                
                    <cx-status-tag
                        status="\${order.status | entityStatus:order.fulfilled}"
                        tag-styling="\${ order.status }"
                    ></cx-status-tag>
                </span>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div if.bind="ticket" class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-2">Title</span>
                <span class="text-small truncated-text text-underline cursor-pointer" mdc-tooltip="value.bind: ticket.title;" click.delegate="switchToDetails(index)">\${ ticket.title | textTruncate:10 }</span>
            </div>
            <div else  class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-2">Rate</span>
                <span class="text-small">\${ order.rate }</span>
            </div>

            <div if.bind="ticket" class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-2">Message</span>
                <span class="text-small truncated-text" mdc-tooltip="value.bind: ticket.message;">\${ ticket.message | textTruncate:12 }</span>
            </div>
            <div else class="d-flex flex-column pseudo-cell">
                <span class="text-tiny font-bold mb-2">Price</span>
                <span class="text-small truncated-text">\${ order.price }</span>
            </div>
            <div class="d-flex flex-column pseudo-cell \${ order ? 'invisible' : '' }">
                <span class="text-tiny font-bold mb-1">Status</span>
                <span>                                
                    <cx-status-tag
                        status="\${ ticket.status | supportTicketStatus : ticket : user.id }"
                        tag-styling="\${ ticket.status } \${ order ? 'd-none' : '' }"
                    ></cx-status-tag>
                </span>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;