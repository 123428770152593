export enum ApiErrorReason {
    Unauthorized,
    NotFound,
    MethodNotAllowed,
    TooManyRequests,
    Locked,
    BadRequest,
    ServerError,
    ValidationError,
    InvalidData
}

export interface IApiException {
    reason: ApiErrorReason;
    message: string;
}
