// Module
var code = `<template>
    <div id="cx-faq-dialog">
        <mdc-dialog view-model.ref="dialog" tabindex="-1">
            <span class="close-icon text-extra-large-plus cursor-pointer text-end material-icons" data-mdc-dialog-action="cancel" tabindex="0">close</span>
            <div class="px-4">
                <cx-page-content-area key.bind="'FAQ_TABLE_MAIN_TITLE'" class="main-title font-medium text-extra-large">
                </cx-page-content-area>
            </div>
            <div class="table-container">
                <mdc-data-table busy.bind="busy">
                    <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>
                            <cx-page-content-area key.bind="'FAQ_TABLE_TITLE_1'" class="font-medium text-large">
                            </cx-page-content-area>
                        </th>
                        <th>
                            <cx-page-content-area key.bind="'FAQ_TABLE_TITLE_2'" class="font-medium text-large">
                            </cx-page-content-area>
                        </th>
                        <th>
                            <cx-page-content-area key.bind="'FAQ_TABLE_TITLE_3'" class="font-medium text-large">
                            </cx-page-content-area>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row-subtitles" mdc-data-table-row>
                        <td>
                            <cx-page-content-area key.bind="'FAQ_TABLE_SUBTITLE_1'" class="font-medium text-medium">
                            </cx-page-content-area>
                        </td>
                        <td>
                            <cx-page-content-area key.bind="'FAQ_TABLE_SUBTITLE_2'" class="font-medium text-medium">
                            </cx-page-content-area>
                        </td>
                        <td>
                            <cx-page-content-area key.bind="'FAQ_TABLE_SUBTITLE_3'" class="font-medium text-medium">
                            </cx-page-content-area>
                        </td>
                      </tr>
                      <tr mdc-data-table-row>
                        <td class="table-content-cell">
                            <cx-page-content-area key.bind="'FAQ_TABLE_CONTENT_1'" class="table-content font-regular text-small">
                            </cx-page-content-area>
                        </td>
                        <td class="table-content-cell">
                            <cx-page-content-area key.bind="'FAQ_TABLE_CONTENT_2'" class="table-content font-regular text-small">
                            </cx-page-content-area>
                        </td>
                        <td class="table-content-cell">
                            <cx-page-content-area key.bind="'FAQ_TABLE_CONTENT_3'" class="table-content font-regular text-small">
                            </cx-page-content-area>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </mdc-data-table>                
            </div>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;