// Module
var code = `<template>
    <mdc-icon 
        id="cx-tooltip"
        class="material-symbols-outlined tooltip-icon"
        mdc-tooltip.ref="tooltip"
        mdc-tooltip="value.bind: message; x-position.bind: xPosition"
        aria-label="tooltip"
        skeleton-loading="is-loading.bind:isLoading;border-radius:50%;width:var(--size);height:var(--size)"
    >
        \${icon}
    </mdc-icon>
</template>
`;
// Exports
export default code;