// Module
var code = `<template>
    <section id="tos" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">\${'CX_TERMS_OF_SERVICE' | siteStrings}</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LAST_UPDATED'" class="text-end last-update">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_SUMARY_OF_TERMS_TITLE'" class="font-bold text-title summary">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_SUMMARY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ACCOUNT_REGISTRATION_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ACCOUNT_REGISTRATION_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USER_REPRESENTATIONS_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USER_REPRESENTATIONS'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_AML_COMPLIANCE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_AML_COMPLIANCE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMOS_OF_SERVICE_NO_ENDOSEMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_NO_ENDORSEMENT_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_RISK_ACKNOWLEDGEMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_RISK_ACKNOWLEDGEMENT_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GENERAL_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GENERAL_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USE_OF_SERVICE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USE_OF_SERVICE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FRAUDULENT_ACTIVITY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FRAUDULENT_ACTIVITY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_THIRD_PARTY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_THIRD_PARTY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INTELLECTUAL_PROPERTY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INTELLECTUAL_PROPERTY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INFORMATION_POSTED_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INFORMATION_POSTED_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_LIABILITY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_LIABILITY_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ELECTRONIC_COMUNICATIONS_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ELECTRONIC_COMUNICATIONS_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ENTIRE_AGREEMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ENTIRE_AGREEMENT_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ASSIGNMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ASIGNMENT_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_TERMINATION_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_TERMINATION_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_NON_WAIVER_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_NON_WAIVER_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INDEMNIFICATION_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_IDEMNIFICATION_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FORCE_MAJEURE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FORCE_MAJEURE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GOVERNING_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GOVERNING_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ARBITRATION_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ARBITRATION_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONTACT_US_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONTACT_US_CONTENT'" class="text-content">
            </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;