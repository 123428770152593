import './cx-faq-dialog.scss';
import { bindable } from 'aurelia-framework';

export class CxFaqDialog {
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable dialog;

    open() {
        this.dialog.open = !this.dialog.open;
    }
}
