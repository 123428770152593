import './notifications.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { NotificationService } from 'services/notification-service';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';

@autoinject()
export class Notifications {
    private user;
    @bindable selectOptions = [
        {
            selection: 'all',
            active: false
        },
        {
            selection: 'none',
            active: false
        },
        {
            selection: 'read',
            active: false
        },
        {
            selection: 'unread',
            active: false
        },
    ];

    unreadNotifications;
    notificationsHidden = false;
    clickedOption = [{
        selection: 'all',
        active: true
    }];

    notifications = [];
    filteredNotifications = [];
    loading: boolean;
    unreadNotificationsCount = 0;
    search;
    changeSearchWatch;
    toastSearchSent: boolean;
    isAnUnread = true;
    allNotificationsSuscriber;
    checkboxSelect;
    timeouts;
    newNotifications = [];
    previousNotifications = [];
    anyIsChecked = false;

    constructor(private notificationService: NotificationService, private sessionService: SessionService, private toastService: ToastService, private eventAggregator: EventAggregator, private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async attached() {
        this.user = await this.sessionService.getProfile();
        this.getNotifications();
        this.allNotificationsSuscriber = this.eventAggregator.subscribe('update-all-notifications', () => {
            this.getNotifications();
        });
    }

    setActiveOption(clickedOption) {
        if (clickedOption.length > 0) {
            this.filteredNotifications.forEach(notification => {
                switch (clickedOption[0]?.selection) {
                    case 'all':
                        notification.checked = true;
                        break;
                    case 'read':
                        if (notification.isRead) {
                            notification.checked = true;
                        } else {
                            notification.checked = false;
                        }
                        break;
                    case 'unread':
                        if (!notification.isRead) {
                            notification.checked = true;
                        } else {
                            notification.checked = false;
                        }
                        break;
                    case 'none':
                        notification.checked = false;
                        break;
                    default:
                        break;
                }
            });
        } else {
            this.filteredNotifications.forEach(notification => notification.checked = false);
        }

        this.anyIsChecked = this.filteredNotifications.some(notification => notification.checked);
        if (this.anyIsChecked) {
            this.isAnUnread = this.filteredNotifications.some(notification => !notification.isRead && notification.checked);
        } else {
            this.isAnUnread = true;
        }
    }

    async getNotifications() {
        this.loading = true;
        this.notifications = await this.notificationService.getLatestNotifications(this.user.id, 30, true);
        this.filteredNotifications = this.notifications;
        this.groupingNotifications();
        this.loading = false;
    }

    async markNotificationsAsRead() {
        for (const notification of this.filteredNotifications) {
            if (notification.checked) {
                notification.isRead = this.isAnUnread;
                notification.status = this.isAnUnread ? 'read' : 'unread';
            }
        }
        const notificationsId = this.filteredNotifications.filter(item => item.checked).map(x => x.id);
        await this.notificationService.markAsRead(notificationsId, this.isAnUnread);
        this.isAnUnread = true;
        this.filteredNotifications.forEach(item => item.checked = false);
        this.setActiveOption(this.selectOptions.filter(option => option.active));
        this.eventAggregator.publish('get-unread-notifications');
        this.checkboxSelect.au.controller.viewModel.clearOptionsSelection();
        this.groupingNotifications();
    }

    async deleteNotifications() {
        const notificationsId = this.filteredNotifications.filter(item => item.checked).map(x => x.id);
        if (notificationsId.length > 0) {
            await this.notificationService.delete(notificationsId);
            this.isAnUnread = true;
            this.getNotifications();
            this.filteredNotifications.forEach(item => item.checked = false);
            this.eventAggregator.publish('get-unread-notifications');
        }
        this.checkboxSelect.au.controller.viewModel.clearOptionsSelection();
        this.groupingNotifications();
    }

    async searchNotification() {
        if (!this.search) {
            this.setActiveOption(this.selectOptions.filter(option => option.active));
            return;
        }

        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);

        const relevantNotifications = [];
        for (const notification of this.filteredNotifications) {
            if (notification.title?.toLowerCase().includes(this.search.toLowerCase()) || notification.description?.toLowerCase().includes(this.search.toLowerCase())) {
                relevantNotifications.push(notification);
            }
        }

        if (relevantNotifications.length === 0) {
            if (this.toastSearchSent === false) {
                await this.toastService.showToast('Couldn\'t find anything', 'Try again', 'info');
                this.toastSearchSent = true;
            }
            return;
        }

        this.filteredNotifications = relevantNotifications;
        this.groupingNotifications();
    }

    keyUpHandler(ev) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchNotification();
            return;
        } else {
            this.changeSearchWatch = setTimeout(() => {
                if (this.search) {
                    this.searchNotification();
                } else {
                    this.filteredNotifications = this.notifications;
                    this.checkboxSelect.au.controller.viewModel.clearOptionsSelection();
                    this.groupingNotifications();
                }
            }, 1500);
        }

        return true;
    }

    functionChecked(id) {
        this.filteredNotifications.forEach(notification => {
            if (notification.id === id) {
                notification.checked = !notification.checked;
            }
        });
        this.anyIsChecked = this.filteredNotifications.some(notification => notification.checked);
        if (this.anyIsChecked) {
            this.isAnUnread = this.filteredNotifications.some(notification => !notification.isRead && notification.checked);
        } else {
            this.isAnUnread = true;
        }
    }

    clearSearch() {
        this.search = '';
        this.filteredNotifications = this.notifications;
        this.checkboxSelect.au.controller.viewModel.clearOptionsSelection();
        this.groupingNotifications();
    }

    groupingNotifications () {
        this.newNotifications = this.filteredNotifications.filter(notification => !this.notificationsHidden && !notification.isRead);
        this.previousNotifications = this.filteredNotifications.filter(notification => !this.notificationsHidden && notification.isRead);
    }
}
