// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/trash.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/chick-icons/chick_crying_2.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-notification-bell"
        class="\${ openDropdown ? 'open-dropdown' : '' }"
        mouseenter.trigger="mouseEventsHandler(\$event)"
        mouseleave.trigger="mouseEventsHandler(\$event)"
    >
            <div class="bell-spacer" click.delegate="mouseEventsHandler(\$event)">
                <div if.bind="unreadNotificationsCount > 0"
                    class="\${ isHoverOnBell ? 'notification-badge-inverted' : 'notification-badge' } position-absolute d-flex align-items-center justify-content-center">
                    <span class="font-medium">\${unreadNotificationsCount}</span>
                </div>
                <span id="notifications"
                    class=" \${ isHoverOnBell ? 'material-icons' : 'material-icons-outlined' } cursor-pointer">
                    notifications
                </span>
            </div>
            <div class="notifications-container position-fixed position-lg-absolute">
                <!-- Tablet and Mobile Layout -->
                <div class="tab-mob-container d-flex justify-content-start d-block d-lg-none align-items-center">
                    <button class="mdc-button mdc-button--touch p-0" click.delegate="mouseEventsHandler(\$event)">
                        <span
                            class="material-icons text-extra-large-static cursor-pointer material-close-icon">close</span>
                        <span class="close-span text-black">\${'CX_CLOSE'|siteStrings}</span>
                    </button>
                    <div class="search-bar-container justify-content-between align-items-center tablet">
                        <cx-text-input label.bind="'Search'"
                            class.bind="'w-100 input-with-label outline-styles quantity-input'"
                            button-icon.bind="leftFilter" leading-icon.bind="'search'"
                            value.two-way="search & debounce:500" button-icon-function.call="clearSearch()"
                            key-up-function.call="keyUpHandler(\$event)"></cx-text-input>
                    </div>
                </div>
                <div class="d-flex justify-content-between title-notification-section">
                    <span class="text-large font-medium notification-title text-black">\${'CX_NOTIFICATIONS'|siteStrings}</span>
                    <div class="d-flex d-lg-none align-items-center control-buttons">
                        <button show.bind="anyIsChecked"
                            class="material-icons-outlined p-0 align-items-center text-purple text-huge d-flex align-items-center delete-icon"
                            click.delegate="deleteNotifications()">
                            <img src="${___HTML_LOADER_IMPORT_0___}" alt="Notification Icon" class="control-image"
                                loading="lazy">
                        </button>
                        <button show.bind="anyIsChecked"
                            class="material-icons-outlined p-0 align-items-center text-purple cursor-pointer mail-icon"
                            click.delegate="markNotificationsAsRead()">
                            <span class="material-symbols-outlined"> \${isAnUnread ? 'mail' : 'drafts'} </span>
                        </button>
                        <cx-checkbox-select ref="checkboxSelectMobile" type.bind="'checkbox'"
                            options.two-way="selectOptions" class="checkbox me-lg-auto control-options m-checkbox"
                            filled.bind="true"></cx-checkbox-select>
                    </div>
                </div>
                <!-- Desktop control layout -->
                <div class="container-control d-flex flex-column flex-sm-row d-none d-lg-block">
                    <div class="d-flex justify-content-lg-between align-items-center w-lg-100 controls">
                        <cx-checkbox-select ref="checkboxSelectDesktop" type.bind="'checkbox'"
                            options.two-way="selectOptions"
                            class.bind="'checkbox me-0 me-lg-auto outline-styles quantity-input'"
                            filled.bind="true"></cx-checkbox-select>
                        <div class="delete-buttons d-flex flex-row">
                            <cx-button
                                class="text-medium text-black first-button \${!anyIsChecked ? 'mark-read-hover' : '' }"
                                button-text.bind="'CX_DELETE'|siteStrings"
                                button-style.bind="'secondary btn w-100 mark-read d-none d-lg-block'"
                                click.delegate="markNotificationsAsHidden()"
                                button-disabled.bind="!anyIsChecked"></cx-button>
                            <cx-button
                                class="text-medium text-black d-none d-lg-block \${!anyIsChecked ? 'mark-read-hover' : '' }"
                                button-text.bind="isAnUnread ? 'CX_MARK_READ' : 'CX_MARK_UNREAD'|siteStrings"
                                button-style.bind="'secondary mark-as-read btn w-100 mark-read'"
                                click.delegate="markNotificationsAsRead()"
                                button-disabled.bind="!anyIsChecked"></cx-button>
                        </div>
                    </div>
                </div>
                <div class="search-bar-container d-none d-lg-block">
                    <cx-text-input label.bind="'Search'" class.bind="'w-100 input-with-label'"
                        button-icon.bind="leftFilter" leading-icon.bind="'search'" value.two-way="search & debounce:500"
                        button-icon-function.call="clearSearch()"
                        key-up-function.call="keyUpHandler(\$event)"></cx-text-input>
                </div>
                <section data-simplebar ref="notificationScrollbar" class="lilac-scrollbar" id="notificationList">
                    <div class="new-divisor text-black font-medium \${notifications.length === 0 ? 'no-title' : ''}"
                        if.bind="anyNewNotifications || anyNewChecked || showNew">\${'CX_NEW'|siteStrings}</div>
                    <div class="d-flex flex-column new-notifications-container">
                        <cx-notification show.bind="!anyNewChecked ? notification.show : true"
                            repeat.for="notification of newNotifications" notification-data.bind="notification"
                            selected-filter.bind="clickedOption" loading.bind="loading"
                            function-checked.call="functionChecked(notification.id)"
                            function-mark-read.call="markNotificationsAsReadById(notification.id)"
                            class="\${\$last && anyOldNotifications ? 'last-element' : ''}"></cx-notification>
                    </div>
                    <div class="earlier-divisor text-black font-medium \${notifications.length === 0 ? 'no-title' : ''}"
                        if.bind="anyOldNotifications || anyOldChecked || showOld">\${'CX_EARLIER'|siteStrings}</div>
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-column">
                            <cx-notification show.bind="!anyOldChecked ? notification.show : true"
                                repeat.for="notification of previousNotifications" notification-data.bind="notification"
                                selected-filter.bind="clickedOption" loading.bind="loading"
                                function-checked.call="functionChecked(notification.id)"></cx-notification>
                        </div>
                    </div>
                    <div class="no-result-section d-flex justify-content-center align-items-center flex-column"
                        if.bind="noResult">
                        <img src="${___HTML_LOADER_IMPORT_1___}" alt="Chick Icon" loading="lazy">
                        <div class="text-center m-auto text-regular no-results text-medium-info text-black">\${'CX_NOTIFICATIONS_NO_RESULTS' | siteStrings}</div>
                    </div>
                </section>
            </div>
    </div>
</template>
`;
// Exports
export default code;