export class RateOfChangeValueConverter {
    toView(value) {
        if (typeof value !== 'number') {
            return value;
        }

        const absoluteValue = Math.abs(value);
        const roundedValue = Math.round(absoluteValue * 10) / 10;

        return roundedValue;
    }
}
