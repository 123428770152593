import './cx-auth-form.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable, observable, BindingEngine, TaskQueue } from 'aurelia-framework';
import { ControllerValidateResult, ValidateResult, validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { Router, activationStrategy } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { VeriffVerificationEvent, emailDisallowConRegExr } from 'resources/constants';
import { baseUrl, chicksGoldBaseUrl, accKingsBaseUrl, gamerTotalBaseUrl, divicaSalesBaseUrl, googleSignInClientId, appleClientId, discordSignInUrl, discordSignUpUrl } from 'environment';
import { TooltipArrowStyling } from 'resources/value-converters/tooltip-arrow-styling';
import { jwtDecode } from 'jwt-decode';
import { Helper } from 'resources/helpers/helper';
import { GoogleJwtPayload, GoogleButtonWrapper } from 'services/models/google';
import { validationObj } from 'resources/helpers/validation-helper';
import emailMisspelled from 'email-misspelled';
import { DomainsOverride } from 'resources/domains_override';
import { AppleJwtPayload, AppleButtonWrapper } from 'services/models/apple';
import { ScriptService } from 'services/script-service';
import AuthFunctionality from 'resources/extensions/auth_functionality';
import { ToastType } from 'services/models/toast';
import { CxVeriff } from '../cx-veriff/cx-veriff';
import { IVeriffVerificationEvent } from 'types/events';

@autoinject()
export class CxAuthForm extends AuthFunctionality {
    @bindable params;
    @bindable type;

    @observable email: string;
    @observable newEmail: string;
    @observable recoveryEmail: string;
    @observable password: string;
    @observable newPassword: string;
    @observable resetPassword: string;
    @observable confirmPassword: string;
    @observable token: string;
    newPasswordValidatorProperty: ControllerValidateResult;
    resetPasswordValidatorProperty: ControllerValidateResult;
    confirmPasswordValidatorProperty: ControllerValidateResult;
    optedInForEmails: boolean;
    showGreenCheckMarkEmailRegister: boolean;
    showErrorCheckMarkEmailRegister: boolean;
    showMiniSpinnerEmailRegister: boolean;
    passwordValidRegister: boolean;
    passwordInvalidRegister: boolean;
    showErrorCheckMarkPasswordRegister: boolean;
    showMiniSpinnerPasswordRegister: boolean;
    showGreenCheckMarkEmailLogin: boolean;
    showErrorCheckMarkEmailLogin: boolean;
    showMiniSpinnerEmailLogin: boolean;
    passwordValidLogin: boolean;
    passwordInvalidLogin: boolean;
    showErrorCheckMarkPasswordLogin: boolean;
    showMiniSpinnerPasswordLogin: boolean;
    showGreenCheckMarkTokenLogin: boolean;
    showErrorCheckMarkTokenLogin: boolean;
    showMiniSpinnerTokenLogin: boolean;
    showGreenCheckMarkRecoveryEmail: boolean;
    showErrorCheckMarkRecoveryEmail: boolean;
    showMiniSpinnerRecoveryEmail: boolean;
    resetPasswordValid: boolean;
    resetPasswordInvalid: boolean;
    showErrorCheckMarkResetPassword: boolean;
    showMiniSpinnerResetPassword: boolean;
    confirmPasswordValid: boolean;
    confirmPasswordInvalid: boolean;
    showErrorCheckMarkConfirmPassword: boolean;
    showMiniSpinnerConfirmPassword: boolean;
    firedFunction: boolean;
    newEmailStopWatch: NodeJS.Timeout;
    newEmailStopWatch2: NodeJS.Timeout;
    newEmailFocusOutStopWatch: NodeJS.Timeout;
    miniSpinnerNewEmailStopwatch: NodeJS.Timeout;
    newEmailFocusInStopWatch: NodeJS.Timeout;
    newPasswordStopWatch: NodeJS.Timeout;
    newPasswordStopWatch2: NodeJS.Timeout;
    miniSpinnerNewPasswordStopwatch: NodeJS.Timeout;
    newPasswordFocusInStopWatch: NodeJS.Timeout;
    newPasswordFocusOutStopWatch: NodeJS.Timeout;
    emailStopWatch: NodeJS.Timeout;
    emailStopWatch2: NodeJS.Timeout;
    miniSpinnerEmailStopwatch: NodeJS.Timeout;
    emailFocusInStopWatch: NodeJS.Timeout;
    checkEmailValidationTimeout: NodeJS.Timeout;
    passwordStopWatch: NodeJS.Timeout;
    passwordStopWatch2: NodeJS.Timeout;
    miniSpinnerPasswordStopwatch: NodeJS.Timeout;
    passwordFocusInStopWatch: NodeJS.Timeout;
    checkPasswordValidationTimeout: NodeJS.Timeout;
    tokenStopWatch: NodeJS.Timeout;
    tokenStopWatch2: NodeJS.Timeout;
    miniSpinnerTokenStopwatch: NodeJS.Timeout;
    tokenFocusInStopWatch: NodeJS.Timeout;
    checkTokenValidationTimeout: NodeJS.Timeout;
    recoveryEmailStopWatch: NodeJS.Timeout;
    recoveryEmailStopWatch2: NodeJS.Timeout;
    miniSpinnerRecoveryEmailStopwatch: NodeJS.Timeout;
    recoveryEmailFocusInStopWatch: NodeJS.Timeout;
    resetPasswordStopWatch: NodeJS.Timeout;
    resetPasswordStopWatch2: NodeJS.Timeout;
    miniSpinnerResetPasswordStopwatch: NodeJS.Timeout;
    resetPasswordFocusInStopWatch: NodeJS.Timeout;
    confirmPasswordStopWatch: NodeJS.Timeout;
    confirmPasswordStopWatch2: NodeJS.Timeout;
    miniSpinnerConfirmPasswordStopwatch: NodeJS.Timeout;
    confirmPasswordFocusInStopWatch: NodeJS.Timeout;
    firedClickFunction: boolean;
    loginFailed: boolean;
    successFunction: boolean;
    toastNewEmailSent: boolean;
    toastNewPasswordSent: boolean;
    toastEmailSent: boolean;
    toastPasswordSent: boolean;
    toastTokenSent: boolean;
    toastRecoveryEmailSent: boolean;
    toastResetPasswordSent: boolean;
    toastConfirmPasswordSent: boolean;
    isRequesting: boolean;
    autoFillTriggered: boolean;
    autoFillStopWatch: NodeJS.Timeout;
    autoFillStopWatch1: NodeJS.Timeout;
    timeouts: NodeJS.Timeout[];
    mainStopWatch: NodeJS.Timeout;
    mainStopWatch1: NodeJS.Timeout;
    mainFunctionTrigger1: NodeJS.Timeout;
    mainFunctionTrigger2: NodeJS.Timeout;
    emailChecker;
    emailLoginElement;
    passwordLoginElement;
    tokenLoginElement;
    visible = false;
    tokenRequired = false;
    state: string | string[] = 'sign-in';
    baseUrl = baseUrl();
    chicksGoldBaseUrl = chicksGoldBaseUrl();
    accKingsBaseUrl = accKingsBaseUrl();
    gamerTotalBaseUrl = gamerTotalBaseUrl();
    divicaSalesBaseUrl = divicaSalesBaseUrl();
    multipleStateValidation = ['sign-in', 'sign-up'];
    routeChangeSubscriber: Subscription;
    showErrorCheckMarkToken;
    showSignIn;
    loading = false;
    signSubscriber;
    termsOfServicePageRoute: string;
    privacyPolicyPageRoute: string;
    urlParams;
    correctEmail: boolean;
    firedCorrectEmail: boolean;
    triggeredOptions: boolean;
    googleButtonWrapper: GoogleButtonWrapper;
    signInOption: string;
    googleLibraryInitializeInterval;
    googleLibraryInitializationCounter = 0;
    appleLibraryInitializationCounter = 0;
    firstTimeLoading;
    appleLibraryInitializeInterval;
    appleButtonWrapper: AppleButtonWrapper;
    veriffViewModel: CxVeriff;
    veriffSubscriber: Subscription;

    constructor(
        private router: Router,
        private validator: ValidationController,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        public customerService: CustomerService,
        private bindingEngine: BindingEngine,
        public toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private tooltipArrowStyling: TooltipArrowStyling,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private helper: Helper,
        private taskQueue: TaskQueue,
        private scriptService: ScriptService
    ) {
        super();
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
        this.queryParamsValueConverter = queryParamsValueConverter;
        this.tooltipArrowStyling = tooltipArrowStyling;
        this.helper = helper;
        this.taskQueue = taskQueue;
        scriptService.injectGoogleSignInScript();
        scriptService.injectAppleSignInScript();
    }

    addPasswordValidationChain = (propertyName: string) => validationObj(propertyName).addPasswordRules().build(this);
    addEmailValidationChain = (propertyName: string) => validationObj(propertyName).addEmailRules().build(this);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addConditionalValidationChain = (firstCallback: () => any, secondaryCallback: () => any, condition: boolean) => {
        if (condition) {
            firstCallback();
        } else {
            secondaryCallback();
        }
    };

    buildCommonMultiPropertyChains = (type: 'sign-up' | 'password-confirmation') => {
        if (type === 'sign-up') {
            validationObj('newPassword').addPasswordRules().addProperty('newEmail').addEmailRules().build(this);
        } else if (type === 'password-confirmation') {
            validationObj('resetPassword').addPasswordRules().addProperty('confirmPassword').addPasswordRules().build(this);
        }
    };

    async attached() {
        this.firstTimeLoading = true;
        this.handleSignInOrUpViaOptions();
        this.emailChecker = emailMisspelled({ domains: DomainsOverride });
        this.state = this.router.currentInstruction?.config?.route;
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.termsOfServicePageRoute = pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.privacyPolicyPageRoute = pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
        this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
        if (this.params?.reset2FA) this.twoFactorAuthenticationResetHandler();
        if (this.params.accessToken) {
            const valid = await this.customerService.verifyResetPasswordToken(this.params.accessToken, this.urlParams.email);
            if (!valid) {
                this.router.navigate('/expired-link');
            }
        } else if (this.router.currentInstruction.queryParams.isValidatingIp) {
            const email = this.urlParams.email;
            const ip = this.router.currentInstruction.queryParams.ip;
            const accessTokenIp = this.router.currentInstruction.queryParams.accessTokenIp;

            const data = { email: email, ip: ip, accessToken: accessTokenIp };
            const isIpAdded = await this.customerService.authorizeIp(data);
            if (isIpAdded) {
                await this.toastService.showToast('New IP validated', 'Your new device has been verified. In case you log into your account from a new IP or device, another email authorization will be requested.', 'success');
                this.firedClickFunction = true;
                this.firedFunction = true;
                this.email = isIpAdded;
                this.password = this.helper.generateRandomString();
                this.mainStopWatch = setTimeout(() => {
                    this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
                    this.mainStopWatch1 = setTimeout(async () => {
                        await this.login(null, null, null, accessTokenIp);
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                        }
                    }, 1000);
                }, 1000);
            }
        }

        this.handleEventSubscriptions();

        document.getElementById('auth').onclick = async (e: MouseEvent) => {
            const element = e.target as HTMLElement;
            if (element.tagName.toLowerCase() === 'input') {
                this.autoFillTriggered = true;
                this.firedClickFunction = true;
                return;
            }
            const autoFilledUsername = document.querySelector('#username-input input:-webkit-autofill');
            const autoFilledPassword = document.querySelector('#password-input input:-webkit-autofill');
            this.state = this.router.currentInstruction?.config?.route;
            if (!this.firedClickFunction && this.state === 'sign-in' && this.email?.trim() && this.password && autoFilledUsername && autoFilledPassword) {
                this.firedClickFunction = true;
                this.firedFunction = true;
                this.mainStopWatch = setTimeout(() => {
                    this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
                    this.mainStopWatch1 = setTimeout(async () => {
                        await this.login();
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                        }
                    }, 1000);
                }, 1000);
            }
        };

        this.firstTimeLoading = false;
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleChangingWhileRequesting() {
        if (this.isRequesting && !this.successFunction) {
            this.timeouts = [this.mainFunctionTrigger1, this.mainFunctionTrigger2, this.autoFillStopWatch, this.autoFillStopWatch1];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.eventAggregator.publish('user-updated', {});
            this.firedFunction = this.isRequesting = this.loading = false;
        }
    }

    handleEventSubscriptions() {
        this.signSubscriber = this.eventAggregator.subscribe('pressed-back', () => {
            this.tokenRequiredUpdate();
        });

        this.veriffSubscriber = this.eventAggregator.subscribe(VeriffVerificationEvent, async (payload: IVeriffVerificationEvent) => {
            if (!payload.user.idVerified) return;
            const response = await this.customerService.reset2FAByEmail(this.params.email, this.params.token);
            if (response) {
                this.toastService.showToast(undefined, 'You have disabled 2FA on your account. Please try your login again', ToastType.SUCCESS);
                return;
            }
            this.toastService.showToast(undefined, 'The token provided is wrong or expired.', ToastType.ERROR);
        });


        this.routeChangeSubscriber = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.handleSignInOrUpViaOptions();
            this.state = this.router.currentInstruction?.config?.route;
            this.email = undefined;
            this.newEmail = undefined;
            this.password = undefined;
            this.newPassword = undefined;
            this.recoveryEmail = undefined;
            this.resetPassword = undefined;
            this.confirmPassword = undefined;
            this.passwordValidRegister = undefined;
            this.passwordInvalidRegister = undefined;
            this.showErrorCheckMarkPasswordRegister = undefined;
            this.showMiniSpinnerPasswordRegister = undefined;
            this.showGreenCheckMarkEmailRegister = undefined;
            this.showErrorCheckMarkEmailRegister = undefined;
            this.showMiniSpinnerEmailRegister = undefined;
            this.passwordValidLogin = undefined;
            this.passwordInvalidLogin = undefined;
            this.showErrorCheckMarkPasswordLogin = undefined;
            this.showMiniSpinnerPasswordLogin = undefined;
            this.showGreenCheckMarkEmailLogin = undefined;
            this.showErrorCheckMarkEmailLogin = undefined;
            this.showMiniSpinnerEmailLogin = undefined;
            this.showGreenCheckMarkRecoveryEmail = undefined;
            this.showErrorCheckMarkRecoveryEmail = undefined;
            this.showMiniSpinnerRecoveryEmail = undefined;
            this.resetPasswordValid = undefined;
            this.resetPasswordInvalid = undefined;
            this.showErrorCheckMarkResetPassword = undefined;
            this.showMiniSpinnerResetPassword = undefined;
            this.confirmPasswordValid = undefined;
            this.confirmPasswordInvalid = undefined;
            this.showErrorCheckMarkConfirmPassword = undefined;
            this.showMiniSpinnerConfirmPassword = undefined;
            this.newPasswordValidatorProperty = undefined;
            this.resetPasswordValidatorProperty = undefined;
            this.confirmPasswordValidatorProperty = undefined;
            this.firedFunction = undefined;
            this.successFunction = undefined;
            this.toastNewEmailSent = undefined;
            this.toastNewPasswordSent = undefined;
            this.toastEmailSent = undefined;
            this.toastPasswordSent = undefined;
            this.toastTokenSent = undefined;
            this.toastRecoveryEmailSent = undefined;
            this.toastResetPasswordSent = undefined;
            this.toastConfirmPasswordSent = undefined;
            this.newEmailFocusInStopWatch = undefined;
            this.newPasswordFocusInStopWatch = undefined;
            this.emailFocusInStopWatch = undefined;
            this.passwordFocusInStopWatch = undefined;
            this.newEmailFocusOutStopWatch = undefined;
            this.newPasswordFocusOutStopWatch = undefined;
            this.tokenFocusInStopWatch = undefined;
            this.recoveryEmailFocusInStopWatch = undefined;
            this.resetPasswordFocusInStopWatch = undefined;
            this.confirmPasswordFocusInStopWatch = undefined;
            this.isRequesting = undefined;
            this.autoFillTriggered = undefined;
            this.autoFillStopWatch = undefined;
            this.autoFillStopWatch1 = undefined;
            this.timeouts = undefined;
            this.validator.reset();
        });
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    tokenRequiredUpdate() {
        this.tokenRequired = false;
    }

    async optedInForEmailsOnClick() {
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.newPasswordFocusOutStopWatch, this.miniSpinnerNewPasswordStopwatch,
            this.newPasswordFocusInStopWatch, this.newEmailStopWatch, this.newEmailStopWatch2, this.newEmailFocusOutStopWatch, this.miniSpinnerNewEmailStopwatch,
            this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister && !this.firedFunction) {
            this.firedFunction = true;
            await this.register();
        }
    }

    async newEmailUpdatedOnKeyPress(event?: KeyboardEvent) {
        if (this.isRequesting) return;
        this.showGreenCheckMarkEmailRegister = this.showErrorCheckMarkEmailRegister = this.showMiniSpinnerEmailRegister = this.toastNewEmailSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.newEmailStopWatch, this.newEmailStopWatch2, this.miniSpinnerNewEmailStopwatch, this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.newPassword !== undefined) {
            this.addPasswordValidationChain('newPassword');
            this.validator.validate();
        } else if (event?.key === 'Enter') {
            this.checkNewEmailValidation();
            return;
        } else if (this.newEmail?.trim() !== undefined) {
            this.miniSpinnerNewEmailStopwatch = setTimeout(() => {
                this.showMiniSpinnerEmailRegister = true;
            }, 1000);
            this.newEmailStopWatch = setTimeout(async () => {
                this.addConditionalValidationChain(
                    () => this.buildCommonMultiPropertyChains('sign-up'),
                    () => this.addEmailValidationChain('newEmail'),
                    this.newPassword !== undefined);
                const rules = await this.validator.validate();
                this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
                this.showGreenCheckMarkEmailRegister &&= !this.emailChecker(this.newEmail)?.length;
                this.showGreenCheckMarkEmailRegister &&= emailDisallowConRegExr().test(this.newEmail);
                if (this.showGreenCheckMarkEmailRegister) {
                    this.showMiniSpinnerEmailRegister = false;
                }
                if (this.password !== undefined) {
                    this.passwordValidRegister = this.validatorCheckOneCondition('newPassword', rules.results);
                    this.passwordInvalidRegister = !this.passwordValidRegister;
                    this.showErrorCheckMarkPasswordRegister = !this.passwordValidRegister;
                }
                if (!this.showGreenCheckMarkEmailRegister) {
                    this.validator.reset();
                    if (this.newPassword !== undefined) {
                        this.addPasswordValidationChain('newPassword');
                        this.validator.validate();
                    }
                    this.newEmailStopWatch2 = setTimeout(async () => {
                        this.addConditionalValidationChain(
                            () => this.buildCommonMultiPropertyChains('sign-up'),
                            () => this.addEmailValidationChain('newEmail'),
                            this.newPassword !== undefined);
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerEmailRegister = false;
                        this.showErrorCheckMarkEmailRegister = !this.validatorCheckOneCondition('newEmail', rules2.results);
                        this.showErrorCheckMarkEmailRegister &&= this.emailChecker(this.newEmail)?.length;
                        this.showErrorCheckMarkEmailRegister &&= !emailDisallowConRegExr().test(this.newEmail);
                        this.toastNewEmailSent = true;
                        await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister) {
                    this.firedFunction = true;
                    await this.register();
                }
            }, 2000);
        }
    }

    newEmailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmailRegister = this.showErrorCheckMarkEmailRegister = this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastNewEmailSent = false;
        this.validator.reset();
        if (this.newPassword !== undefined) {
            this.addPasswordValidationChain('newPassword');
            this.validator.validate();
        }
        this.newEmailFocusInStopWatch = setTimeout(() => {
            if (this.newEmail?.trim() !== undefined) {
                this.newEmailUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
            }
        });
    }

    async checkNewEmailValidation() {
        this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
        this.timeouts = [this.newEmailStopWatch, this.newEmailStopWatch2, this.miniSpinnerNewEmailStopwatch, this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.newEmail?.trim() !== undefined) {
            this.addConditionalValidationChain(
                () => this.buildCommonMultiPropertyChains('sign-up'),
                () => this.addEmailValidationChain('newEmail'),
                this.newPassword !== undefined);
            const rules = await this.validator.validate();
            this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
            this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
            this.showGreenCheckMarkEmailRegister &&= !this.emailChecker(this.newEmail)?.length;
            this.showGreenCheckMarkEmailRegister &&= emailDisallowConRegExr().test(this.newEmail);
            this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
            if (!this.showGreenCheckMarkEmailRegister && !this.toastNewEmailSent) {
                await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
            }

            if (this.password !== undefined) {
                this.passwordValidRegister = this.validatorCheckOneCondition('newPassword', rules.results);
                this.passwordInvalidRegister = !this.passwordValidRegister;
                this.showErrorCheckMarkPasswordRegister = !this.passwordValidRegister;
            }

            if (this.passwordValidRegister && this.showGreenCheckMarkEmailRegister && !this.firedFunction) {
                this.newEmailFocusOutStopWatch = setTimeout(async () => {
                    this.firedFunction = true;
                    await this.register();
                }, 4000);
            }
        }
    }

    async newPasswordUpdatedOnKeyPress(event: KeyboardEvent) {
        if (this.isRequesting) return;
        this.showMiniSpinnerPasswordRegister = this.toastNewPasswordSent = this.firedFunction = this.successFunction = false;
        this.addConditionalValidationChain(
            () => this.buildCommonMultiPropertyChains('sign-up'),
            () => this.addPasswordValidationChain('newPassword'),
            this.newEmail?.trim() !== undefined);
        this.newPasswordValidatorProperty = await this.validator.validate();
        this.passwordValidRegister = false;
        this.passwordInvalidRegister = false;
        this.showErrorCheckMarkPasswordRegister = false;
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkNewPasswordValidation();
            return;
        } else {
            if (this.newPassword !== undefined) {
                this.miniSpinnerNewPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerPasswordRegister = true;
                }, 1000);
                this.newPasswordStopWatch = setTimeout(async () => {
                    let counterValidationsNewPasswordTwo = 0;
                    if (this.newPassword) {
                        for (const validations of this.newPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'newPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsNewPasswordTwo++;
                            }
                        }
                        if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsNewPasswordTwo = 0;
                        }
                    }
                    if (this.newEmail?.trim() !== undefined) {
                        this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', this.newPasswordValidatorProperty.results);
                        this.showGreenCheckMarkEmailRegister &&= !this.emailChecker(this.newEmail)?.length;
                        this.showGreenCheckMarkEmailRegister &&= emailDisallowConRegExr().test(this.newEmail);
                        this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
                    }
                    if (counterValidationsNewPasswordTwo >= 3 && this.newPasswordValidatorProperty.results.find(x => x.propertyName === 'newPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.passwordValidRegister = true;
                        this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = false;
                        if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister) {
                            this.firedFunction = true;
                            await this.register();
                        }
                    } else {
                        this.newPasswordStopWatch2 = setTimeout(async () => {
                            this.passwordValidRegister = this.showMiniSpinnerPasswordRegister = false;
                            this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.toastNewPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                        }, 2000);
                    }
                }, 4000);
            }
        }
    }

    async newPasswordUpdatedOnFocusIn() {
        this.passwordValidRegister = this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastNewPasswordSent = false;
        this.validator.reset();
        if (this.email?.trim() !== undefined) {
            this.addEmailValidationChain('newEmail');
            const rules = await this.validator.validate();
            this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
            this.showGreenCheckMarkEmailRegister &&= !this.emailChecker(this.newEmail)?.length;
            this.showGreenCheckMarkEmailRegister &&= emailDisallowConRegExr().test(this.newEmail);
            this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
        }
        this.newPasswordFocusInStopWatch = setTimeout(() => {
            if (this.newPassword !== undefined) {
                this.newPasswordUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
            }
        });
    }

    async checkNewPasswordValidation() {
        this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.newPassword !== undefined) {
            this.addConditionalValidationChain(
                () => this.buildCommonMultiPropertyChains('sign-up'),
                () => this.addPasswordValidationChain('newPassword'),
                this.newEmail?.trim() !== undefined);
            this.newPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsNewPassword = 0;
            if (this.newPassword) {
                for (const validations of this.newPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'newPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsNewPassword++;
                    }
                }
            }

            if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength')?.valid) {
                counterValidationsNewPassword = 0;
            }

            if (this.newEmail?.trim() !== undefined) {
                this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', this.newPasswordValidatorProperty.results);
                this.showGreenCheckMarkEmailRegister &&= !this.emailChecker(this.newEmail)?.length;
                this.showGreenCheckMarkEmailRegister &&= emailDisallowConRegExr().test(this.newEmail);
                this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
            }

            if (counterValidationsNewPassword >= 3 && this.newPasswordValidatorProperty.results.find(x => x.propertyName === 'newPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
                this.passwordValidRegister = true;
                if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister && !this.firedFunction) {
                    this.newPasswordFocusOutStopWatch = setTimeout(async () => {
                        this.firedFunction = true;
                        await this.register();
                    }, 1000);
                }
            } else if (!this.successFunction) {
                this.passwordValidRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
                this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = true;
                if (!this.toastNewPasswordSent && !this.showSignIn) {
                    await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                }
            }
        }
    }

    async emailUpdatedOnKeyPress(event?: KeyboardEvent) {
        this.handleChangingWhileRequesting();
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!event?.key && !this.autoFillTriggered) return;
        if (event?.key === 'Enter') {
            this.checkEmailValidation();
            return;
        }
        if (!event?.key && this.autoFillTriggered) {
            const autoFilledPassword = document.querySelector('#password-input input:-webkit-autofill');
            if (!this.firedFunction && autoFilledPassword) {
                this.autoFillStopWatch = setTimeout(() => {
                    this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
                    this.autoFillStopWatch1 = setTimeout(async() => {
                        this.firedFunction = true;
                        await this.login();
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                        }
                    }, 1000);
                }, 1000);
            } else if (!this.firedFunction) {
                this.firedCorrectEmail = true;
                if (!this.helper.isEmpty(this.email)) this.correctEmail = await this.customerService.checkEmail(this.email);

                this.miniSpinnerEmailStopwatch = setTimeout(() => {
                    this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                    this.showMiniSpinnerEmailLogin = true;
                }, 1000);
                this.emailStopWatch = setTimeout(() => {
                    if (this.correctEmail) {
                        this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                        this.showGreenCheckMarkEmailLogin = true;
                    } else {
                        this.emailStopWatch2 = setTimeout(async() => {
                            this.showMiniSpinnerEmailLogin = this.showGreenCheckMarkEmailLogin = false;
                            this.showErrorCheckMarkEmailLogin = true;
                            this.toastEmailSent = true;
                            await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
            return;
        }
        this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.showMiniSpinnerEmailLogin = this.toastEmailSent = this.firedFunction = this.firedCorrectEmail = this.successFunction = this.tokenRequired = this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = false;
        this.token = undefined;
        this.validator.reset();
        if (this.password !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.password !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.password === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        if (this.email?.trim() !== undefined) {
            this.miniSpinnerEmailStopwatch = setTimeout(() => {
                const passwordAutoFill = document.querySelector('#password-input input:-webkit-autofill');
                if (!this.passwordValidLogin && !this.passwordInvalidLogin && !this.showErrorCheckMarkPasswordLogin && passwordAutoFill) this.showMiniSpinnerPasswordLogin = true;
                this.showMiniSpinnerEmailLogin = true;
            }, 1000);
            this.emailStopWatch = setTimeout(async () => {
                if (this.password !== undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.password !== undefined && this.token === undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .on(this);
                } else if (this.password === undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .on(this);
                }
                const rules = await this.validator.validate();
                let checkValidation = this.validatorCheckOneCondition('email', rules.results);
                checkValidation &&= !this.emailChecker(this.email)?.length;
                checkValidation &&= emailDisallowConRegExr().test(this.email);
                let checkPasswordValidation: boolean;
                let checkTokenValidation: boolean;
                this.showMiniSpinnerPasswordLogin = false;
                if (this.password !== undefined && this.token !== undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                } else if (this.password !== undefined && this.token === undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                } else if (this.password === undefined && this.token !== undefined) {
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                }
                if (!checkValidation) {
                    this.validator.reset();
                    if (this.password !== undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    } else if (this.password !== undefined && this.token === undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    } else if (this.password === undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    }
                    this.emailStopWatch2 = setTimeout(async () => {
                        if (this.password !== undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.password !== undefined && this.token === undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .on(this);
                        } else if (this.password === undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerEmailLogin = false;
                        this.showErrorCheckMarkEmailLogin = !this.validatorCheckOneCondition('email', rules2.results);
                        this.showErrorCheckMarkEmailLogin &&= this.emailChecker(this.email)?.length;
                        this.showErrorCheckMarkEmailLogin &&= !emailDisallowConRegExr().test(this.email);
                        this.toastEmailSent = true;
                        await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    }, 2000);
                } else if (this.tokenRequired && this.showGreenCheckMarkTokenLogin && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login(this.firedCorrectEmail);
                    return;
                }
                if (!this.tokenRequired && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login(this.firedCorrectEmail);
                    return;
                }
                this.firedCorrectEmail = true;
                this.correctEmail = await this.customerService.checkEmail(this.email.trim());
                if (checkValidation && this.correctEmail && this.tokenRequired && (this.successFunction || !this.password || !this.token || checkPasswordValidation || checkTokenValidation)) {
                    this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                    this.showGreenCheckMarkEmailLogin = true;
                } else if (checkValidation && this.correctEmail && !this.tokenRequired && (this.successFunction || !this.password || checkPasswordValidation)) {
                    this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                    this.showGreenCheckMarkEmailLogin = true;
                } else if (!this.autoFillTriggered && typeof this.correctEmail === 'boolean' && !this.correctEmail) {
                    this.showMiniSpinnerEmailLogin = this.showGreenCheckMarkEmailLogin = false;
                    this.showErrorCheckMarkEmailLogin = true;
                }
            }, 2000);
        }
    }

    emailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastEmailSent = false;
        this.firedCorrectEmail = false;
        this.validator.reset();
        this.timeouts = [this.mainStopWatch, this.mainStopWatch1, this.checkPasswordValidationTimeout, this.checkEmailValidationTimeout, this.checkTokenValidationTimeout, this.tokenStopWatch, this.tokenStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.password !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.password !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.password === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        this.emailFocusInStopWatch = setTimeout(() => {
            if (this.email === undefined) return;
            this.emailUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
        });
    }

    onTabPressed = (event: KeyboardEvent) => {
        if (event.key === 'Tab') this.checkEmailValidation(true);
        return true;
    };

    async checkEmailValidation(tabPressed?: boolean) {
        this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.autoFillStopWatch, this.autoFillStopWatch1, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.email !== undefined) {
            if (this.password !== undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.password !== undefined && this.token === undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .on(this);
            } else if (this.password === undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerTokenLogin = false;
            let checkValidation = this.validatorCheckOneCondition('email', rules.results);
            checkValidation &&= !this.emailChecker(this.email)?.length;
            checkValidation &&= emailDisallowConRegExr().test(this.email);

            if (!checkValidation && !this.toastEmailSent) {
                this.showErrorCheckMarkEmailLogin = true;
                await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
            }

            let checkPasswordValidation: boolean;
            let checkTokenValidation: boolean;
            const userNameAutoFill = document.querySelector('#username-input input:-webkit-autofill');
            const passwordAutoFill = document.querySelector('#password-input input:-webkit-autofill');
            this.autoFillTriggered = userNameAutoFill && passwordAutoFill ? true : false;

            if (!this.firedCorrectEmail && userNameAutoFill && passwordAutoFill) {
                this.firedCorrectEmail = true;
                if (!this.helper.isEmpty(this.email)) this.correctEmail = await this.customerService.checkEmail(this.email);
            }

            if (this.password !== undefined && this.token !== undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.passwordInvalidLogin;
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            } else if (this.password !== undefined && this.token === undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.passwordInvalidLogin;
            } else if (this.password === undefined && this.token !== undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            }

            if (tabPressed) {
                await this.handleEmailValidationCheckmarks(checkValidation, checkPasswordValidation, checkTokenValidation);
                return;
            }

            this.checkEmailValidationTimeout = setTimeout(async () => {
                await this.handleEmailValidationCheckmarks(checkValidation, checkPasswordValidation, checkTokenValidation);
            }, 250);
        }
    }

    async handleEmailValidationCheckmarks(checkValidation, checkPasswordValidation, checkTokenValidation) {
        if (this.tokenRequired && this.showGreenCheckMarkTokenLogin && this.passwordValidLogin && checkValidation && !this.firedFunction) {
            await this.login(this.firedCorrectEmail);
        }
        if (!this.tokenRequired && this.passwordValidLogin && checkValidation && !this.firedFunction) {
            await this.login(this.firedCorrectEmail);
        }
        if (!this.firedCorrectEmail && this.email) {
            this.correctEmail = await this.customerService.checkEmail(this.email?.trim());
        }

        if (checkValidation && this.correctEmail && this.tokenRequired && (this.successFunction || !this.password || !this.token || checkPasswordValidation || checkTokenValidation)) {
            this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
            this.showGreenCheckMarkEmailLogin = true;
        } else if (checkValidation && this.correctEmail && !this.tokenRequired && (this.successFunction || !this.password || checkPasswordValidation)) {
            this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
            this.showGreenCheckMarkEmailLogin = true;
        } else if (!this.autoFillTriggered && typeof this.correctEmail === 'boolean' && !this.correctEmail) {
            this.showMiniSpinnerEmailLogin = this.showGreenCheckMarkEmailLogin = false;
            this.showErrorCheckMarkEmailLogin = true;
        }
    }

    validatorCheckOneCondition(field: 'token' | 'password' | 'newPassword' | 'email' | 'newEmail' | 'recoveryEmail', results: ValidateResult[]) {
        let valid = true;
        for (const result of results) {
            if (result.propertyName === field && !result.valid) {
                valid = false;
            }
        }
        return valid;
    }

    async passwordUpdatedOnKeyPress(event?: KeyboardEvent) {
        this.handleChangingWhileRequesting();
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!event?.key && !this.autoFillTriggered) return;
        if (event?.key === 'Enter') {
            this.checkPasswordValidation();
            return;
        }
        if (!event?.key && this.autoFillTriggered) {
            const autoFilledEmail = document.querySelector('#username-input input:-webkit-autofill');
            if (!this.firedFunction && !autoFilledEmail) {
                this.miniSpinnerPasswordStopwatch = setTimeout(() => {
                    this.passwordInvalidLogin = this.passwordValidLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.showMiniSpinnerPasswordLogin = true;
                }, 1000);
                this.passwordStopWatch = setTimeout(async() => {
                    ValidationRules
                        .ensure('password').required().minLength(6).maxLength(100)
                        .on(this);

                    const rules = await this.validator.validate();
                    const checkValidation = this.validatorCheckOneCondition('password', rules.results);
                    if (!checkValidation) {
                        this.passwordStopWatch2 = setTimeout(async() => {
                            this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                            this.passwordInvalidLogin = !this.validatorCheckOneCondition('password', rules.results);
                            this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                            this.toastPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                        }, 2000);
                    } else {
                        this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                        this.passwordValidLogin = true;
                    }
                }, 2000);
            }
            return;
        }
        if (this.loginFailed) {
            this.loginFailed = false;
            this.showGreenCheckMarkEmailLogin = true;
            this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
            this.showMiniSpinnerEmailLogin = false;
        }
        this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.toastPasswordSent = this.firedFunction = this.successFunction = this.tokenRequired = this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = false;
        this.token = undefined;
        this.validator.reset();
        if (this.email?.trim() !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.email?.trim() !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (!this.email?.trim() === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        if (this.password !== undefined) {
            this.miniSpinnerPasswordStopwatch = setTimeout(() => {
                const userNameAutoFill = document.querySelector('#username-input input:-webkit-autofill');
                if (!this.showGreenCheckMarkEmailLogin && !this.showErrorCheckMarkEmailLogin && userNameAutoFill) this.showMiniSpinnerEmailLogin = true;
                this.showMiniSpinnerPasswordLogin = true;
            }, 1000);
            this.passwordStopWatch = setTimeout(async () => {
                if (this.email?.trim() !== undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.email?.trim() !== undefined && this.token === undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .on(this);
                } else if (!this.email?.trim() === undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .on(this);
                }
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('password', rules.results);
                let checkEmailValidation: boolean;
                let checkTokenValidation: boolean;
                this.showMiniSpinnerEmailLogin = false;

                if (!this.firedCorrectEmail) {
                    this.firedCorrectEmail = true;
                    if (!this.helper.isEmpty(this.email)) this.correctEmail = await this.customerService.checkEmail(this.email);
                }

                if (this.email?.trim() !== undefined && this.token !== undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                    this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                    this.showGreenCheckMarkEmailLogin &&= this.correctEmail;
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                } else if (this.email?.trim() !== undefined && this.token === undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                    this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                    this.showGreenCheckMarkEmailLogin &&= this.correctEmail;
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                } else if (!this.email?.trim() === undefined && this.token !== undefined) {
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                }
                if (!checkValidation) {
                    this.validator.reset();
                    if (this.email?.trim() !== undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    } else if (this.email?.trim() !== undefined && this.token === undefined) {
                        ValidationRules
                            .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                            .on(this);
                        this.validator.validate();
                    } else if (!this.email?.trim() === undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    }
                    this.passwordStopWatch2 = setTimeout(async () => {
                        if (this.email?.trim() !== undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.email?.trim() !== undefined && this.token === undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .on(this);
                        } else if (!this.email?.trim() === undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                        this.passwordInvalidLogin = !this.validatorCheckOneCondition('password', rules2.results);
                        this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                        this.toastPasswordSent = true;
                        await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login(this.tokenRequired);
                }
                if (checkValidation && this.tokenRequired && (this.successFunction || !this.email?.trim() || !this.token || checkEmailValidation || checkTokenValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.email?.trim() || checkEmailValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                }
            }, 2000);
        }
    }

    async passwordUpdatedOnFocusIn() {
        this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastPasswordSent = false;
        this.validator.reset();
        this.timeouts = [this.mainStopWatch, this.mainStopWatch1, this.checkEmailValidationTimeout, this.checkPasswordValidationTimeout, this.checkTokenValidationTimeout, this.tokenStopWatch, this.tokenStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.email?.trim() !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.email?.trim() !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (!this.email?.trim() === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }

        this.passwordFocusInStopWatch = setTimeout(() => {
            if (this.password !== undefined) {
                this.passwordUpdatedOnKeyPress({ key: 'focusin' } as KeyboardEvent);
            }
        });
    }

    async checkPasswordValidation() {
        this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.password !== undefined) {
            if (this.email?.trim() !== undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.email?.trim() !== undefined && this.token === undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .on(this);
            } else if (!this.email?.trim() === undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
            const checkValidation = this.validatorCheckOneCondition('password', rules.results);

            if ((this.password.length === 0 || !checkValidation) && !this.toastPasswordSent) {
                this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = true;
                await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
            }

            if (!this.firedCorrectEmail) {
                this.firedCorrectEmail = true;
                if (!this.helper.isEmpty(this.email)) this.correctEmail = await this.customerService.checkEmail(this.email);
            }

            let checkEmailValidation: boolean;
            let checkTokenValidation: boolean;
            const userNameAutoFill = document.querySelector('#username-input input:-webkit-autofill');
            const passwordAutoFill = document.querySelector('#password-input input:-webkit-autofill');
            this.autoFillTriggered = userNameAutoFill && passwordAutoFill ? true : false;

            if (this.email?.trim() !== undefined && this.token !== undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                this.showGreenCheckMarkEmailLogin &&= this.correctEmail;
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            } else if (this.email?.trim() !== undefined && this.token === undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                this.showGreenCheckMarkEmailLogin &&= this.correctEmail;
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
            } else if (!this.email?.trim() === undefined && this.token !== undefined && (this.autoFillTriggered || (!userNameAutoFill && !passwordAutoFill))) {
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            }

            this.checkPasswordValidationTimeout = setTimeout(async () => {
                if (this.showGreenCheckMarkEmailLogin && checkValidation && !this.firedFunction) {
                    await this.login(this.tokenRequired);
                }

                if (checkValidation && this.tokenRequired && (this.successFunction || !this.email?.trim() || !this.token || checkEmailValidation || checkTokenValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.email?.trim() || checkEmailValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                } else if (!this.successFunction && !this.tokenRequired) {
                    this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = true;
                }
            }, 250);
        }
    }

    async tokenUpdatedOnKeyPress(event: KeyboardEvent) {
        if (!event?.key) return;
        this.handleChangingWhileRequesting();
        this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkPasswordLogin = this.toastTokenSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch, this.checkTokenValidationTimeout, this.checkEmailValidationTimeout, this.checkPasswordValidationTimeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.email?.trim() !== undefined && this.password !== undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.email?.trim() !== undefined && this.password === undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (!this.email?.trim() === undefined && this.password !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        }
        if (this.token !== undefined) {
            this.miniSpinnerTokenStopwatch = setTimeout(() => {
                this.showMiniSpinnerTokenLogin = true;
            }, 1000);
            this.tokenStopWatch = setTimeout(async () => {
                if (this.email?.trim() !== undefined && this.password !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.email?.trim() !== undefined && this.password === undefined) {
                    ValidationRules
                        .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (!this.email?.trim() === undefined && this.password !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                }
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('token', rules.results);
                let checkEmailValidation: boolean;
                let checkPasswordValidation: boolean;
                if (this.email?.trim() !== undefined && this.password !== undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                    this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                } else if (this.email?.trim() !== undefined && this.password === undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                    this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                } else if (!this.email?.trim() === undefined && this.password !== undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                }
                if (!checkValidation) {
                    this.validator.reset();
                    if (this.email?.trim() !== undefined && this.password !== undefined) {
                        ValidationRules
                            .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    } else if (this.email?.trim() !== undefined && this.password === undefined) {
                        ValidationRules
                            .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                            .on(this);
                        this.validator.validate();
                    } else if (!this.email?.trim() === undefined && this.password !== undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    }
                    this.tokenStopWatch2 = setTimeout(async () => {
                        if (this.email?.trim() !== undefined && this.password !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.email?.trim() !== undefined && this.password === undefined) {
                            ValidationRules
                                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (!this.email?.trim() === undefined && this.password !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerTokenLogin = false;
                        this.showErrorCheckMarkTokenLogin = !this.validatorCheckOneCondition('token', rules2.results);
                        this.toastTokenSent = true;
                        await this.toastService.showToast('Please enter a valid token', 'Entered token is incorrect or outdated.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailLogin && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login();
                }
                if (checkValidation && (this.successFunction || !this.password || !this.email?.trim() || checkEmailValidation || checkPasswordValidation)) {
                    this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkTokenLogin = false;
                    this.showGreenCheckMarkTokenLogin = true;
                }
            }, 2000);
        }
    }

    tokenUpdatedOnFocusIn() {
        this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastTokenSent = false;
        this.validator.reset();
        if (this.email?.trim() !== undefined && this.password !== undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.email?.trim() !== undefined && this.password === undefined) {
            ValidationRules
                .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (!this.email.trim() === undefined && this.password !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        }

        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.token !== undefined) {
                this.tokenUpdatedOnKeyPress({ key: 'focusin' } as KeyboardEvent);
            }
        });
    }

    async checkTokenValidation() {
        this.showMiniSpinnerTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.token !== undefined) {
            if (this.email.trim() !== undefined && this.password !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.email.trim() !== undefined && this.password === undefined) {
                ValidationRules
                    .ensure('email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (!this.email.trim() === undefined && this.password !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = false;
            const checkValidation = this.validatorCheckOneCondition('token', rules.results);

            if (!checkValidation && !this.toastTokenSent) {
                this.showErrorCheckMarkTokenLogin = true;
                await this.toastService.showToast('Please enter a valid token', 'Entered token is incorrect or outdated.', 'error');
            }

            let checkEmailValidation: boolean;
            let checkPasswordValidation: boolean;
            if (this.email.trim() !== undefined && this.password !== undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.showErrorCheckMarkPasswordLogin;
            } else if (this.email.trim() !== undefined && this.password === undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showGreenCheckMarkEmailLogin &&= !this.emailChecker(this.email)?.length;
                this.showGreenCheckMarkEmailLogin &&= emailDisallowConRegExr().test(this.email);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
            } else if (!this.email.trim() === undefined && this.password !== undefined) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.showErrorCheckMarkPasswordLogin;
            }

            this.checkTokenValidationTimeout = setTimeout(async () => {
                if (this.showGreenCheckMarkEmailLogin && this.passwordValidLogin && checkValidation && !this.firedFunction) {
                    await this.login();
                }

                if (checkValidation && (this.successFunction || !this.password || !this.email.trim() || checkEmailValidation || checkPasswordValidation)) {
                    this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkTokenLogin = false;
                    this.showGreenCheckMarkTokenLogin = true;
                } else {
                    this.showErrorCheckMarkToken = true;
                }
            }, 250);
        }
    }

    async recoveryEmailUpdatedOnKeyPress(event: KeyboardEvent) {
        if (this.isRequesting) return;
        this.showGreenCheckMarkRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.toastRecoveryEmailSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.recoveryEmailStopWatch, this.recoveryEmailStopWatch2, this.miniSpinnerRecoveryEmailStopwatch, this.recoveryEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (event?.key === 'Enter') {
            this.checkRecoveryEmailValidation();
            return;
        } else {
            if (this.recoveryEmail.trim() !== undefined) {
                this.miniSpinnerRecoveryEmailStopwatch = setTimeout(() => {
                    this.showMiniSpinnerRecoveryEmail = true;
                }, 1000);
                this.recoveryEmailStopWatch = setTimeout(async () => {
                    ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.').on(this);
                    const rules = await this.validator.validate();
                    const checkValidation = this.validatorCheckOneCondition('recoveryEmail', rules.results);
                    if (!checkValidation) {
                        this.validator.reset();
                        this.recoveryEmailStopWatch2 = setTimeout(async () => {
                            ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.').on(this);
                            const rules2 = await this.validator.validate();
                            this.showMiniSpinnerRecoveryEmail = false;
                            this.showErrorCheckMarkRecoveryEmail = !this.validatorCheckOneCondition('recoveryEmail', rules2.results);
                            this.toastRecoveryEmailSent = true;
                            await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                        }, 2000);
                    } else if (checkValidation) {
                        this.firedFunction = true;
                        await this.sendEmailReset();
                    }
                    if (checkValidation && this.successFunction) {
                        this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                        this.showGreenCheckMarkRecoveryEmail = true;
                    }
                }, 2000);
            }
        }
    }

    recoveryEmailUpdatedOnFocusIn() {
        this.showGreenCheckMarkRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.successFunction = this.showSignIn = false;
        this.firedFunction = false;
        this.toastRecoveryEmailSent = false;
        this.validator.reset();
        this.recoveryEmailFocusInStopWatch = setTimeout(() => {
            if (this.recoveryEmail?.trim() !== undefined) {
                this.recoveryEmailUpdatedOnKeyPress({ key: 'Enter' } as KeyboardEvent);
            }
        });
    }

    async checkRecoveryEmailValidation() {
        this.showMiniSpinnerRecoveryEmail = false;
        this.successFunction = false;
        this.timeouts = [this.recoveryEmailStopWatch, this.recoveryEmailStopWatch2, this.miniSpinnerRecoveryEmailStopwatch, this.recoveryEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.recoveryEmail?.trim() !== undefined) {
            ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(emailDisallowConRegExr()).withMessage('Please enter a valid email.').on(this);
            const rules = await this.validator.validate();
            this.showMiniSpinnerRecoveryEmail = false;
            const checkValidation = this.validatorCheckOneCondition('recoveryEmail', rules.results);

            if (!checkValidation && !this.toastRecoveryEmailSent) {
                this.showErrorCheckMarkRecoveryEmail = true;
                await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
            }

            if (checkValidation && !this.firedFunction && !this.showSignIn) {
                await this.sendEmailReset();
            }

            if (checkValidation && this.successFunction) {
                this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                this.showGreenCheckMarkRecoveryEmail = true;
            }
        }
    }

    async resetPasswordUpdatedOnKeyPress(event) {
        if (this.isRequesting) return;
        this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.toastResetPasswordSent = this.firedFunction = this.successFunction = false;
        this.addConditionalValidationChain(
            () => this.buildCommonMultiPropertyChains('password-confirmation'),
            () => this.addPasswordValidationChain('resetPassword'),
            this.confirmPassword !== undefined);
        this.resetPasswordValidatorProperty = await this.validator.validate();
        this.resetPasswordValid = false;
        this.resetPasswordInvalid = false;
        this.showErrorCheckMarkResetPassword = false;
        this.timeouts = [this.resetPasswordStopWatch, this.resetPasswordStopWatch2, this.miniSpinnerResetPasswordStopwatch, this.resetPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkResetPasswordValidation();
            return;
        } else {
            if (this.resetPassword !== undefined) {
                this.miniSpinnerResetPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerResetPassword = true;
                }, 1000);
                this.resetPasswordStopWatch = setTimeout(async () => {
                    let counterValidationsResetPasswordTwo = 0;
                    if (this.resetPassword) {
                        for (const validations of this.resetPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsResetPasswordTwo++;
                            }
                        }
                        if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsResetPasswordTwo = 0;
                        }
                    }
                    if (this.confirmPassword !== undefined && this.confirmPassword !== '') {
                        let counterValidationsConfirmPassword = 0;
                        for (const validations of this.resetPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsConfirmPassword++;
                            }
                        }
                        if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsConfirmPassword = 0;
                        }
                        if (counterValidationsConfirmPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                            this.confirmPasswordValid = true;
                            this.confirmPasswordInvalid = !this.confirmPasswordValid;
                            this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                        } else {
                            this.confirmPasswordValid = false;
                            this.confirmPasswordInvalid = !this.confirmPasswordValid;
                            this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                        }
                    } else if (this.confirmPassword === '') {
                        this.confirmPasswordValid = false;
                        this.confirmPasswordInvalid = !this.confirmPasswordValid;
                        this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                    }
                    if (counterValidationsResetPasswordTwo >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.resetPasswordValid = true;
                        this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = false;
                        if (this.confirmPasswordValid && this.resetPasswordValid) {
                            this.firedFunction = true;
                            await this.updatePassword();
                        }
                    } else {
                        this.resetPasswordStopWatch2 = setTimeout(async () => {
                            this.resetPasswordValid = this.showMiniSpinnerResetPassword = false;
                            this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.toastResetPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async resetPasswordUpdatedOnFocusIn() {
        this.resetPasswordInvalid = this.resetPasswordValid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.successFunction = false;
        this.firedFunction = false;
        this.toastResetPasswordSent = false;
        this.validator.reset();
        this.resetPasswordFocusInStopWatch = setTimeout(() => {
            if (this.resetPassword !== undefined) {
                this.resetPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkResetPasswordValidation() {
        this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.showErrorCheckMarkResetPassword = false;
        this.timeouts = [this.resetPasswordStopWatch, this.resetPasswordStopWatch2, this.miniSpinnerResetPasswordStopwatch, this.resetPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.resetPassword !== undefined) {
            this.addConditionalValidationChain(
                () => this.buildCommonMultiPropertyChains('password-confirmation'),
                () => this.addPasswordValidationChain('resetPassword'),
                this.confirmPassword !== undefined);
            this.resetPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsResetPassword = 0;
            if (this.resetPassword) {
                for (const validations of this.resetPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsResetPassword++;
                    }
                }
                if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsResetPassword = 0;
                }
            }

            if (this.confirmPassword !== undefined && this.confirmPassword !== '') {
                let counterValidationsConfirmPassword = 0;
                for (const validations of this.resetPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsConfirmPassword++;
                    }
                }
                if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsConfirmPassword = 0;
                }
                if (counterValidationsConfirmPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                    this.confirmPasswordValid = true;
                    this.confirmPasswordInvalid = !this.confirmPasswordValid;
                    this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                } else {
                    this.confirmPasswordValid = false;
                    this.confirmPasswordInvalid = !this.confirmPasswordValid;
                    this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                }
            } else if (this.confirmPassword === '') {
                this.confirmPasswordValid = false;
                this.confirmPasswordInvalid = !this.confirmPasswordValid;
                this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
            }

            if (counterValidationsResetPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = false;
                this.resetPasswordValid = true;
                if (this.confirmPasswordValid && this.resetPasswordValid && !this.firedFunction) {
                    await this.updatePassword();
                }
            } else if (!this.successFunction) {
                this.resetPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = false;
                this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = true;
                if (!this.toastResetPasswordSent) {
                    await this.toastService.showToast('Please enter a valid confirmation password', 'Entered password does not match the expected value.', 'error');
                }
            }
        }
    }

    async confirmPasswordUpdatedOnKeyPress(event) {
        if (this.isRequesting) return;
        this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.toastConfirmPasswordSent = this.firedFunction = this.successFunction = false;
        this.addConditionalValidationChain(
            () => this.buildCommonMultiPropertyChains('password-confirmation'),
            () => this.addPasswordValidationChain('confirmPassword'),
            this.resetPassword !== undefined);
        this.confirmPasswordValidatorProperty = await this.validator.validate();
        this.confirmPasswordValid = false;
        this.confirmPasswordInvalid = false;
        this.showErrorCheckMarkConfirmPassword = false;
        this.timeouts = [this.confirmPasswordStopWatch, this.confirmPasswordStopWatch2, this.miniSpinnerConfirmPasswordStopwatch, this.confirmPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkConfirmPasswordValidation();
            return;
        } else {
            if (this.confirmPassword !== undefined) {
                this.miniSpinnerConfirmPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerConfirmPassword = true;
                }, 1000);
                this.confirmPasswordStopWatch = setTimeout(async () => {
                    let counterValidationsConfirmPasswordTwo = 0;
                    if (this.confirmPassword) {
                        for (const validations of this.confirmPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsConfirmPasswordTwo++;
                            }
                        }
                        if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsConfirmPasswordTwo = 0;
                        }
                    }
                    if (this.resetPassword !== undefined && this.resetPassword !== '') {
                        let counterValidationsResetPassword = 0;
                        for (const validations of this.confirmPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsResetPassword++;
                            }
                        }
                        if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsResetPassword = 0;
                        }
                        if (counterValidationsResetPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                            this.resetPasswordValid = true;
                            this.resetPasswordInvalid = !this.resetPasswordValid;
                            this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                        } else {
                            this.resetPasswordValid = false;
                            this.resetPasswordInvalid = !this.resetPasswordValid;
                            this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                        }
                    } else if (this.resetPassword === '') {
                        this.resetPasswordValid = false;
                        this.resetPasswordInvalid = !this.resetPasswordValid;
                        this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                    }
                    if (counterValidationsConfirmPasswordTwo >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                        this.confirmPasswordValid = true;
                        if (this.resetPasswordValid && this.confirmPasswordValid) {
                            this.firedFunction = true;
                            await this.updatePassword();
                        }
                    } else {
                        this.confirmPasswordStopWatch2 = setTimeout(async () => {
                            this.confirmPasswordValid = this.showMiniSpinnerConfirmPassword = false;
                            this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.toastConfirmPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid confirm password', 'Entered password does not match the expected value.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async confirmPasswordUpdatedOnFocusIn() {
        this.confirmPasswordInvalid = this.confirmPasswordValid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
        this.firedFunction = false;
        this.toastConfirmPasswordSent = false;
        this.validator.reset();
        this.confirmPasswordFocusInStopWatch = setTimeout(() => {
            if (this.confirmPassword !== undefined) {
                this.confirmPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkConfirmPasswordValidation() {
        this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = this.showErrorCheckMarkConfirmPassword = false;
        this.timeouts = [this.confirmPasswordStopWatch, this.confirmPasswordStopWatch2, this.miniSpinnerConfirmPasswordStopwatch, this.confirmPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.confirmPassword !== undefined) {
            this.addConditionalValidationChain(
                () => this.buildCommonMultiPropertyChains('password-confirmation'),
                () => this.addPasswordValidationChain('confirmPassword'),
                this.resetPassword !== undefined);
            this.confirmPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsConfirmPassword = 0;
            if (this.confirmPassword) {
                for (const validations of this.confirmPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsConfirmPassword++;
                    }
                }
                if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsConfirmPassword = 0;
                }
            }

            if (this.resetPassword !== undefined && this.resetPassword !== '') {
                let counterValidationsResetPassword = 0;
                for (const validations of this.confirmPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsResetPassword++;
                    }
                }
                if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsResetPassword = 0;
                }
                if (counterValidationsResetPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                    this.resetPasswordValid = true;
                    this.resetPasswordInvalid = !this.resetPasswordValid;
                    this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                } else {
                    this.resetPasswordValid = false;
                    this.resetPasswordInvalid = !this.resetPasswordValid;
                    this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                }
            } else if (this.resetPassword === '') {
                this.resetPasswordValid = false;
                this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                this.resetPasswordInvalid = !this.resetPasswordValid;
            }

            if (counterValidationsConfirmPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                this.confirmPasswordValid = true;
                if (this.resetPasswordValid && this.confirmPasswordValid && !this.firedFunction) {
                    await this.updatePassword();
                }
            } else if (!this.successFunction) {
                this.confirmPasswordValid = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = true;
                if (!this.toastConfirmPasswordSent) {
                    await this.toastService.showToast('Please enter a valid confirm password', 'Entered password does not match the expected value.', 'error');
                }
            }
        }
    }

    async login(tokenActivated?: boolean, jwtToken?: string, signInOption?: string, baseToken?: string) {
        if (this.isRequesting) return;
        if (!tokenActivated && this.email) {
            this.isRequesting = true;
        }
        try {
            const response = await this.sessionService.login({ email: this.email.trim(), password: this.password, token: this.token, jwtToken: jwtToken, signInOption: signInOption, baseToken: baseToken });
            if (response) {
                this.emailLoginElement?.blur();
                this.passwordLoginElement?.blur();
                this.tokenLoginElement?.blur();
                this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkEmailLogin = this.showErrorCheckMarkPasswordLogin = false;
                this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                if (response?.incorrectToken || response?.tokenUsed) {
                    const title = response?.incorrectToken ? 'Please enter a valid token' : '2FA token already used';
                    const responseError = response?.incorrectToken
                        ? 'Entered token is incorrect or outdated.'
                        : 'Please try again requesting the new one or contact live chat for assistance.';
                    this.toastService.showToast(title, responseError, 'error');
                    this.showGreenCheckMarkTokenLogin = this.isRequesting = false;
                    this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = this.showErrorCheckMarkTokenLogin = true;
                    this.sessionService.destroyToken();
                    return;
                }
                this.successFunction = true;
                this.showErrorCheckMarkEmailLogin = this.showErrorCheckMarkPasswordLogin = this.passwordInvalidLogin = this.showErrorCheckMarkTokenLogin = false;
                this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
                this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                this.mainFunctionTrigger1 = setTimeout(() => {
                    if (!tokenActivated) {
                        this.loading = true;
                    }
                    this.mainFunctionTrigger2 = setTimeout(async () => {
                        this.loading = false;
                        if (response?.tokenRequired) {
                            if (!tokenActivated) {
                                await this.toastService.showToast('2FA is required for login', 'Please provide your Two Factor Authentication token.', 'info');
                            }
                            this.isRequesting = false;
                            this.tokenRequired = true;
                            this.sessionService.destroyToken();
                            return;
                        }
                        if (response?.resetPassword) {
                            await this.toastService.showToast('You are required to reset your password.', 'An email has been sent to you to do so.', 'info');
                            this.isRequesting = false;
                            const data = { email: this.email.trim() };
                            await this.customerService.requestPasswordReset(data as never);
                            this.sessionService.destroyToken();
                            return;
                        }
                        if (response?.mustValidateNewIP) {
                            await this.toastService.showToast('Authorization required', `We sent an email to ${this.email.trim()}. Please check your inbox to authorize sign in.`, 'warning', 'exclamation-yellow-toast');
                            this.isRequesting = false;
                            const data = { email: this.email.trim(), requestedIPToValidate: response.requestedIPToValidate };
                            await this.customerService.requestIpAuthorization(data);
                            return;
                        }
                        this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile(true) });
                        this.isRequesting = false;
                        if (this.params.redirect_url && this.params.redirect_url !== 'sign-in' && this.params.redirect_url !== 'sign-up' && this.params.redirect_url !== 'home') {
                            this.router.navigate(`${this.params.redirect_url.startsWith('/') ? baseUrl().slice(0, -1) : baseUrl()}${this.params.redirect_url}`);
                        } else {
                            this.router.navigate('');
                        }
                    }, 1000);
                }, 1000);
            } else {
                if (!this.helper.isEmpty(this.email) && !this.signInOption) {
                    this.correctEmail = await this.customerService.checkEmail(this.email.trim());
                    if (!this.correctEmail && !this.passwordValidLogin && !this.toastEmailSent) {
                        this.toastEmailSent = true;
                        this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    }
                }
                if (this.tokenRequired) {
                    this.showGreenCheckMarkTokenLogin = this.showMiniSpinnerTokenLogin = false;
                    this.showErrorCheckMarkTokenLogin = true;
                }
                if (this.correctEmail) {
                    this.showGreenCheckMarkEmailLogin = true;
                    this.showErrorCheckMarkEmailLogin = false;
                } else {
                    this.showErrorCheckMarkEmailLogin = true;
                    this.showGreenCheckMarkEmailLogin = false;
                }
                this.passwordValidLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.successFunction = false;
                this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.loginFailed = true;
                this.isRequesting = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async sendEmailReset() {
        if (this.isRequesting) return;
        this.isRequesting = true;
        try {
            const data = { email: this.recoveryEmail.trim() };
            const response = await this.customerService.requestPasswordReset(data as never);
            if (response) {
                this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                this.successFunction = true;
                setTimeout(() => {
                    this.loading = true;
                    setTimeout(async () => {
                        this.loading = false;
                        await this.toastService.showToast('Request received', 'If there is a matching account, a password reset email has been sent.', 'success');
                        this.email = this.recoveryEmail.trim();
                        this.isRequesting = false;
                        this.showSignIn = true;
                        this.router.navigateToRoute('sign-in');
                    }, 1000);
                }, 1000);
            } else {
                this.showGreenCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.successFunction = false;
                this.showErrorCheckMarkRecoveryEmail = true;
                this.isRequesting = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updatePassword() {
        if (this.isRequesting) return;
        this.isRequesting = true;
        if (this.resetPassword === this.confirmPassword) {
            try {
                const response = await this.customerService.resetPasswordWithToken(this.urlParams.email, this.confirmPassword, this.params.accessToken);
                if (response) {
                    this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = false;
                    this.successFunction = true;
                    setTimeout(() => {
                        this.loading = true;
                        setTimeout(async () => {
                            this.loading = false;
                            this.params.accessToken = false;
                            await this.toastService.showToast('Password updated!', 'Your password has been changed successfully. Use your details to log in.', 'success');
                            this.isRequesting = false;
                            this.router.navigateToRoute('sign-in');
                        }, 1000);
                    }, 1000);
                    return;
                } else {
                    this.resetPasswordValid = this.confirmPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
                    this.resetPasswordInvalid = this.confirmPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = true;
                    this.isRequesting = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            this.resetPasswordValid = this.confirmPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
            this.resetPasswordInvalid = this.confirmPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = true;
            await this.toastService.showToast('Passwords do not match', 'Please make sure to enter same password on both inputs.', 'error');
            this.isRequesting = false;
        }
    }

    async register(jwtToken?, signInOption?) {
        if (this.isRequesting) return;
        if (!this.isRequesting && this.newEmail && this.passwordValidRegister && this.showGreenCheckMarkEmailRegister) {
            this.isRequesting = true;
            try {
                const registerResponse = await this.customerService.register({ email: this.newEmail.trim(), password: this.newPassword, optedInForEmails: this.optedInForEmails, jwtToken: jwtToken, signInOption: signInOption, referredByUserId: this.sessionService.getReferrerCode() });
                if (registerResponse?.token) {
                    this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.showErrorCheckMarkEmailRegister = this.showErrorCheckMarkPasswordRegister = false;
                    this.successFunction = true;
                    setTimeout(() => {
                        this.loading = true;
                        setTimeout(async () => {
                            this.loading = false;
                            await this.sessionService.saveToken(registerResponse.token);
                            this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });
                            await this.toastService.showToast('Account created successfully', 'A confirmation email with further instructions has been sent', 'success');
                            this.isRequesting = false;
                            this.router.navigateToRoute('home');
                        }, 1000);
                    }, 1000);
                } else if (registerResponse) {
                    this.showSignIn = true;
                    this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.successFunction = false;
                    this.isRequesting = false;
                    this.router.navigate('sign-in');
                } else {
                    this.showGreenCheckMarkEmailRegister = this.passwordValidRegister = this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.successFunction = false;
                    this.showErrorCheckMarkEmailRegister = this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = true;
                    await this.toastService.showToast('Failed to create user', 'Please try again or try using another email and password.', 'error');
                    this.isRequesting = false;
                }
            } catch (e) {
                console.log(e);
            }
        }

    }

    handleLoginOrSignUpViaOption = (email: string, credential: string, signInOption: string) => {
        this.signInOption = signInOption;
        if (this.state === 'sign-up') {
            this.newEmail = email;
            this.newPassword = this.helper.generateRandomString();
            this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = true;
            setTimeout(async() => {
                this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
                this.showGreenCheckMarkEmailRegister = this.passwordValidRegister = true;
                await this.register(credential, signInOption);
            });
        } else {
            this.email = email;
            this.password = this.helper.generateRandomString();
            this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
            setTimeout(async () => {
                await this.login(null, credential, signInOption);
            }, 1000);
        }
    };

    initializeGoogleLibrary() {
        const googleCallback = async (response) => {
            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkEmailLogin = this.showErrorCheckMarkPasswordLogin =
            this.showGreenCheckMarkEmailRegister = this.passwordValidRegister = this.passwordInvalidRegister = this.showErrorCheckMarkEmailRegister = this.showErrorCheckMarkPasswordRegister = false;
            const userCredentials = jwtDecode<GoogleJwtPayload>(response.credential);
            this.handleLoginOrSignUpViaOption(userCredentials.email, response.credential, 'google');
        };

        this.googleLibraryInitializeInterval = setInterval(() => {
            try {
                this.googleLibraryInitializationCounter++;
                if (this.googleLibraryInitializationCounter >= 5) clearInterval(this.googleLibraryInitializeInterval);
                if (!window.google?.accounts) return;

                window.google?.accounts?.id?.initialize({
                    // eslint-disable-next-line camelcase
                    client_id: googleSignInClientId(),
                    // eslint-disable-next-line camelcase
                    ux_mode: 'popup',
                    callback: googleCallback
                });

                const createFakeGoogleWrapper = () => {
                    const googleLoginWrapper = document.createElement('div');
                    googleLoginWrapper.style.display = 'none';
                    googleLoginWrapper.classList.add('custom-google-button');
                    document.body.appendChild(googleLoginWrapper);

                    window.google?.accounts?.id?.renderButton(googleLoginWrapper, {
                        type: 'icon'
                    });

                    const googleLoginWrapperButton = googleLoginWrapper.querySelector<HTMLElement>('div[role=button]');

                    return googleLoginWrapperButton;
                };

                this.googleButtonWrapper = createFakeGoogleWrapper();

                clearInterval(this.googleLibraryInitializeInterval);
            } catch (e) {
                console.log(e);
            }
        }, 500);
    }

    initializeAppleLibrary() {
        this.appleLibraryInitializeInterval = setInterval(async() => {
            try {
                this.appleLibraryInitializationCounter++;
                if (this.appleLibraryInitializationCounter >= 5) clearInterval(this.appleLibraryInitializeInterval);
                if (!window?.AppleID?.auth) return;

                window?.AppleID?.auth?.init({
                    clientId: appleClientId(),
                    scope: 'email',
                    redirectURI: this.state === 'sign-in' ? `${this.baseUrl}sign-in` : `${this.baseUrl}sign-up`,
                    state: 'US',
                    nonce: String(Date.now()),
                    usePopup: true
                });

                document.addEventListener('AppleIDSignInOnSuccess', (event: AppleSignInSuccessEvent) => {
                    this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkEmailLogin = this.showErrorCheckMarkPasswordLogin =
                    this.showGreenCheckMarkEmailRegister = this.passwordValidRegister = this.passwordInvalidRegister = this.showErrorCheckMarkEmailRegister = this.showErrorCheckMarkPasswordRegister = false;
                    const userCredentials = jwtDecode<AppleJwtPayload>(event.detail.authorization.id_token);
                    this.handleLoginOrSignUpViaOption(userCredentials.email, event.detail.authorization.id_token, 'apple');
                });

                const createFakeAppleWrapper = () => {
                    const appleSignInBtns = document.querySelectorAll('.custom-apple-button');
                    if (appleSignInBtns) appleSignInBtns.forEach(x => x.remove());
                    const appleLoginWrapper = document.createElement('div');
                    appleLoginWrapper.id = 'appleid-signin';
                    appleLoginWrapper.setAttribute('data-color', 'black');
                    appleLoginWrapper.setAttribute('data-border', 'true');
                    appleLoginWrapper.setAttribute('data-type', 'sign-in');
                    appleLoginWrapper.style.display = 'none';
                    appleLoginWrapper.classList.add('custom-apple-button');
                    document.body.appendChild(appleLoginWrapper);

                    window?.AppleID?.auth?.renderButton();

                    const appleLoginWrapperButton = appleLoginWrapper.querySelector<HTMLElement>('div[role=button]');

                    return {
                        click: () => {
                            appleLoginWrapperButton?.click();
                        }
                    };
                };

                this.appleButtonWrapper = createFakeAppleWrapper();

                clearInterval(this.appleLibraryInitializeInterval);
            } catch (e) {
                console.log(e);
            }
        }, 500);
    }

    handleGoogleOption() {
        this.eraseCurrentAuthState();
        this.googleButtonWrapper?.click();
    }

    handleAppleOption() {
        this.eraseCurrentAuthState();
        this.appleButtonWrapper?.click();
    }

    handleDiscordOption() {
        this.eraseCurrentAuthState();
        this.router.navigate(this.state === 'sign-in' ? discordSignInUrl() : discordSignUpUrl());
    }

    async checkDiscordAuthentication() {
        const hashUrlParams = new URLSearchParams(window.location.hash.replace('#', '?'));
        if (hashUrlParams.get('token_type') && hashUrlParams.get('access_token')) {
            const response = await this.customerService.getDiscordProfile(hashUrlParams.get('token_type'), hashUrlParams.get('access_token'));
            response ? this.handleLoginOrSignUpViaOption(response.email, hashUrlParams.get('access_token'), 'discord') : this.toastService.showToast('Error', `Failed to ${this.state === 'sign-in' ? 'login via Discord account'
                : 'create account via Discord'}. Please try again later.`, 'error');
        }
    }

    eraseCurrentAuthState() {
        if (this.state === 'sign-up') {
            this.timeouts = [this.newEmailStopWatch, this.newEmailStopWatch2, this.miniSpinnerNewEmailStopwatch, this.newEmailFocusInStopWatch, this.newPasswordStopWatch, this.newPasswordStopWatch2,
                this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
            this.showGreenCheckMarkEmailRegister = this.showErrorCheckMarkEmailRegister = this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.passwordValidRegister =
                this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.toastNewPasswordSent = this.toastNewEmailSent = this.firedFunction = this.successFunction = false;
            this.newEmail = this.newPassword = undefined;
        } else {
            this.timeouts = [this.mainFunctionTrigger1, this.mainFunctionTrigger2, this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.mainStopWatch,
                this.mainStopWatch1];
            this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.showMiniSpinnerEmailLogin = this.toastEmailSent = this.firedFunction = this.firedCorrectEmail = this.successFunction =
                this.tokenRequired = this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = this.showMiniSpinnerTokenLogin = this.showMiniSpinnerPasswordLogin = this.passwordValidLogin =
                this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = false;
            this.email = this.password = this.token = undefined;
        }
        this.clearationTimeoutValueConverter.toView(this.timeouts);
    }

    checkIfSignInOrSignUpState = () => this.multipleStateValidation.some(x => x === this.state);

    handleSignInOrUpViaOptions = () => {
        if (!this.checkIfSignInOrSignUpState()) return;
        if (!this.firstTimeLoading) this.initializeAppleLibrary();
        if (this.triggeredOptions) return;
        this.triggeredOptions = true;
        this.appleLibraryInitializationCounter = this.googleLibraryInitializationCounter = 0;
        this.initializeGoogleLibrary();
        this.checkDiscordAuthentication();
    };

    async twoFactorAuthenticationResetHandler() {
        if (!this.params.email || !this.params.token) return;
        const response = await this.customerService.verify2FAEmail(this.params.email.trim(), this.params.token);
        if (response) {
            if (this.params.openVeriff) {
                this.user = response;
                await this.veriffViewModel.startVerification();
                return;
            }
            this.toastService.showToast(undefined, 'You have disabled 2FA on your account. Please try your login again', ToastType.SUCCESS);
            return;
        }
        this.toastService.showToast(undefined, 'The token provided is wrong or expired.', ToastType.ERROR);
    }
}
