import './cx-table.scss';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SizeChangedEvent } from '../../constants';
import { ISizeEvent } from '../../../types/events';

export interface ITableHeader {
    title: string;
    class: string;
}

@autoinject()
export class CxTable {
    @bindable tableHeaders: ITableHeader[] = [];
    @bindable headerWidth?: number;

    private sizeWatcher: Subscription;
    width: number;
    tableComponent: HTMLDivElement;

    constructor(
        private eventAggregator: EventAggregator
    ) {
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (this.headerWidth) this.tableComponent.style.setProperty('--header-width', `${this.headerWidth}px`);

        this.sizeWatcher = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
        });
    }

    detached() {
        this.sizeWatcher.dispose();
    }
}
