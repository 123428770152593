// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-circle-logo-purple.svg", import.meta.url);
// Module
var code = `<template>
    <div id="trade-currency">
        <div class="container p-0">
            <section
                if.bind="viewingAsAdmin ||
                    shouldRenderContent(['_FIRST_LEFT_PARAGRAPH', '_FIRST_LEFT_PARAGRAPH_BODY']) ||
                    (shouldRenderContent(['_LC_FIRST_TITLE', '_LC_FIRST_PARAGRAPH']) ||
                    shouldRenderContent(['_LC_SECOND_TITLE', '_LC_SECOND_PARAGRAPH']) ||
                    shouldRenderContent(['_LC_THIRD_TITLE', '_LC_THIRD_PARAGRAPH']))"
                class="first-paragraph"
            >
                <div class="first-paragraph-content">
                    <div if.bind="viewingAsAdmin || shouldRenderContent(['_FIRST_LEFT_PARAGRAPH', '_FIRST_LEFT_PARAGRAPH_BODY'])" class="first-paragraph-left">
                        <cx-page-content-area
                            key="\${suffix}_FIRST_LEFT_PARAGRAPH"
                            class="content-title \${pageLoading ? 'd-flex' : ''}"
                            alternative-key="\${defaultSuffix}_FIRST_LEFT_PARAGRAPH"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-page-content-area>
                        <cx-page-content-area
                            key="\${suffix}_FIRST_LEFT_PARAGRAPH_BODY"
                            class="content-body \${pageLoading ? 'd-flex mt-3' : ''}"
                            alternative-key="\${defaultSuffix}_FIRST_LEFT_PARAGRAPH_BODY"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-page-content-area>
                    </div>

                    <div
                        class="first-paragraph-right"
                        if.bind="viewingAsAdmin ||
                            shouldRenderContent(['_LC_FIRST_TITLE', '_LC_FIRST_PARAGRAPH']) ||
                            shouldRenderContent(['_LC_SECOND_TITLE', '_LC_SECOND_PARAGRAPH']) ||
                            shouldRenderContent(['_LC_THIRD_TITLE', '_LC_THIRD_PARAGRAPH'])"
                    >
                        <article
                            repeat.for="listItem of listContent"
                            if.bind="viewingAsAdmin || shouldRenderContent([listItem.header, listItem.body])">
                            <div class="lc-icon" skeleton-loading="is-loading.bind:pageLoading;">
                                <cx-dynamic-icon
                                    icon.bind="listItem.iconPath"
                                    alt.bind="listItem.alt"
                                    size.bind="32"
                                    outlined.bind="true"
                                    color="rgba(49, 15, 162, 1)"
                                ></cx-dynamic-icon>
                            </div>
                            <div>
                                <cx-page-content-area
                                    key.bind="listItem.header"
                                    class="content-title list-content-title \${pageLoading ? 'd-flex mt-2' : ''}"
                                    alternative-key.bind="listItem.alternativeHeader"
                                    skeleton-loading="is-loading.bind:pageLoading;"
                                ></cx-page-content-area>
                                <cx-page-content-area
                                    key.bind="listItem.body"
                                    class="content-body content-body-small \${pageLoading ? 'd-flex mt-2' : ''}"
                                    alternative-key.bind="listItem.alternativeBody"
                                    skeleton-loading="is-loading.bind:pageLoading;"
                                ></cx-page-content-area>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <cx-seo-dropdown is-loading.bind="pageLoading">
                <!-- ################################################# -->
                <!-- Include here the "Live exchange rates" component. -->
                <!-- ################################################# -->

                <cx-paragraph-title
                    caption="TRADE_SUPPORTED_ASSETS_TITLE"
                    text="TRADE_SUPPORTED_ASSETS_TEXT"
                    caption-style="content-title dropdown-content"
                    text-style="content-body dropdown-content"
                ></cx-paragraph-title>
                <div class="crypto-price-cards-container d-flex">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <cx-crypto-price-card
                            repeat.for="currency of mostPopularCurrencies"
                            currency.bind="currency"
                            is-loading.bind="pageLoading"
                            learn-more-func.call="goToBuySell(currency.code)"
                            learn-more-route.bind="'buy' | languageRoute & async"
                            data-stats-currencies.bind="dataStatsCurrencies"
                            cta-key="CX_EXCHANGE_NOW"
                        ></cx-crypto-price-card>
                        <div if.bind="pageLoading" class="extra-crypto-price-card d-flex align-items-center flex-column">
                            <div class="extra-card-text d-flex align-items-center flex-column">
                                <div class="line-1 skeleton"></div>
                                <div class="line-2 skeleton"></div>
                            </div>
                            <div class="learn-more-link skeleton"></div>
                        </div>
                        <div else class="extra-crypto-price-card" click.delegate="goToLearnMore('buy')">
                            <div>
                                <img src="${___HTML_LOADER_IMPORT_0___}" class="currency-logo" alt="Hero img" loading="lazy">
                                <div class="extra-card-text text-large-plus font-regular text-start dinamyc-line-height ">
                                    \${ 'CX_VIEW_MORE_CRYPTO_HERE' | siteStrings }
                                </div>
                            </div>
                            <a href.bind="tradePageRoute | languageRoute & async" class="learn-more-link-extra-card text-medium-static font-regular d-flex align-items-center">
                                <span>\${ 'CX_EXCHANGE_NOW' | siteStrings }</span>
                                <span class="material-icons ms-2">
                                    arrow_forward
                                </span>
                            </a>
                        </div>
                        <div class="extra-crypto-price-card-space"></div>
                    </div>
                    <cx-timer
                        time-limit.bind="5"
                        auto-restart.bind="autoRestart"
                        time-left.two-way="timeLeft"
                        element.ref="timer"
                        show.bind="false"
                    ></cx-timer>
                </div>
                <cx-paragraph-title
                    caption="TRADE_BENEFITS_TITLE"
                    text="TRADE_BENEFITS_TEXT"
                    caption-style="content-title dropdown-content"
                    text-style="content-body dropdown-content"
                ></cx-paragraph-title>
                <div class="learn-more-cards-container d-flex flex-column flex-xl-row">
                    <cx-learn-more-card
                        repeat.for="card of learnMoreCards"
                        page-loading.bind="isLoading"
                        title.bind="card.title"
                        paragraph.bind="card.paragraph"
                        icon.bind="card.icon"
                        icon-alt.bind="card.iconAlt"
                        button-caption="\${card.buttonCaption | siteStrings}"
                        href.bind="card.redirectRoute|languageRoute & async"
                        aria-label.bind="card.ariaLabel"
                    ></cx-learn-more-card>
                </div>

                <!-- Deafult content -->
                 <cx-page-content-area
                    if.bind="contentDefault"
                    key="\${ contentDefault }"
                    class="seo-dropdown-content"
                 ></cx-page-content-area>
            </cx-seo-dropdown>
            
        </div>
    </div>
</template>
`;
// Exports
export default code;