import './cx-pagination.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { baseUrl } from 'environment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

@autoinject()
export class CxPagination {
    @bindable page: number;
    @bindable count: number;
    @bindable inputPage;
    @bindable showDots = false;
    @bindable pageChanged: ((page: number) => void) | null;
    pages: string[];
    max = 6;
    width: number;
    routerSubscription: Subscription;

    constructor(
        private eventAggregator : EventAggregator,
        private router: Router
    ) {}

    async attached() {
        this.handleEventSubscriptions();
        this.metaTagsConstructor(this.page, this.router.currentInstruction.fragment);
        this.calculatePages();
    }

    detached() {
        this.routerSubscription?.dispose();
        const nextLink = document.querySelector('link[rel=next]');
        const prevLink = document.querySelector('link[rel=prev]');
        nextLink?.remove();
        prevLink?.remove();
    }

    handleEventSubscriptions() {
        this.routerSubscription = this.eventAggregator.subscribe('router:navigation:processing', payload => {
            this.metaTagsConstructor(this.page, payload.instruction.fragment);
        });
    }

    calculatePages() {
        this.pages = [];
        if (this.count < this.max) {
            for (let i = 1; i < this.count + 1; i++) {
                this.pages.push(i.toString());
            }
        } else if (this.page < (this.max - 2)) {
            for (let i = 1; i < this.max - 1; i++) {
                this.pages.push(i.toString());
            }
            this.pages.push('...');
            this.pages.push(this.count.toString());
        } else if ((this.count - this.page) < this.max - 3) {
            this.pages.push('1');
            this.pages.push('...');
            for (let i = (this.count - (this.max - 3)); i < this.count + 1; i++) {
                this.pages.push(i.toString());
            }
        } else {
            this.pages.push('1');
            if (this.page <= (this.count / 2) || this.page + 3 <= this.count) {
                this.pages.push('...');
            }
            for (let i = (this.page - 1); i < this.page + 2; i++) {
                this.pages.push(i.toString());
            }
            if (this.page > (this.count / 2) || this.page + 3 <= this.count) {
                this.pages.push('...');
            }
            this.pages.push(this.count.toString());
        }
        return this.pages;
    }

    countChanged() {
        this.calculatePages();
    }

    inputPageChanged() {
        if (this.inputPage <= this.count && this.inputPage >= 1) {
            this.page = parseInt(this.inputPage);
            document.getElementById('pagination').blur();
            this.calculatePages();
            this.pageChanged?.(this.page);
        }
        this.inputPage = '';
    }

    changePage(index: number, value: string) {
        if (value !== '...') {
            this.page = parseInt(value);
        } else if (index === 1) {
            this.page -= this.page > (this.count - 5) ? (this.page - (this.count - 5)) : 2;
        } else {
            this.page += this.page < 5 ? (6 - this.page) : 2;
        }
        this.calculatePages();
        this.pageChanged?.(this.page);
    }

    pageNext() {
        this.page += this.page < this.count ? 1 : 0;
        this.calculatePages();
        this.pageChanged?.(this.page);
    }

    pagePrevious() {
        this.page -= this.page > 1 ? 1 : 0;
        this.calculatePages();
        this.pageChanged?.(this.page);
    }

    metaTagsConstructor(newPage: number, url: string) {
        const nextLink = document.querySelector('link[rel=next]');
        const prevLink = document.querySelector('link[rel=prev]');
        const nextPage = newPage + (newPage < this.count ? 1 : 0);
        const prevPage = newPage - (newPage > 1 ? 1 : 0);

        if (prevLink) {
            prevLink.remove();
        }

        if (prevPage !== newPage && prevPage < newPage && this.count > 0) {
            const prevHref = this.getNewHref(prevPage, url);
            const newPrevLink = document.createElement('link');
            newPrevLink.setAttribute('rel', 'prev');
            newPrevLink.setAttribute('href', prevHref);
            document.head.appendChild(newPrevLink);
        }

        if (nextLink) {
            nextLink.remove();
        }

        if (nextPage !== newPage && nextPage > newPage && this.count > 0) {
            const nextHref = this.getNewHref(nextPage, url);
            const newNextLink = document.createElement('link');
            newNextLink.setAttribute('rel', 'next');
            newNextLink.setAttribute('href', nextHref);
            document.head.appendChild(newNextLink);
        }
    }

    getNewHref(page, url : string) {
        const urlSplit = url.split('/');
        urlSplit.shift();
        if (urlSplit.some(item => item === 'page')) {
            const indexPage = urlSplit.indexOf('page');
            urlSplit[indexPage + 1] = page;
            return `${baseUrl()}${urlSplit.join('/')}`;
        }

        return `${baseUrl()}${urlSplit.join('/')}/page/${page}`;
    }
}
