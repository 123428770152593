import { EventAggregator } from 'aurelia-event-aggregator';
import './customer-portal.scss';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class Customer {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService
    ) { }

    pages;
    customerPortalRoute;

    async activate() {
        this.pages = await this.websiteService.getPagesByWebsiteShortcode();
    }

    async attached() {
        this.helper.resizeByNavbar(this, '#customer-app');
        this.eventAggregator.publish('observe-element', ({ selector: '#navigation-pages:not(.is-dropdown)' }));
        this.customerPortalRoute = this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Customer Portal')?.id ?? 'customer-portal');
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    configureRouter(config, router) {
        config.options.pushState = true;
        this.router = router;
        config.map([
            {
                route: ['', 'profile'],
                name: 'profile',
                moduleId: PLATFORM.moduleName('pages/customer-portal/profile/profile'),
                title: 'Profile',
                titleKey: 'CX_PROFILE',
                settings: { auth: true }
            },
            {
                route: 'security',
                name: 'security',
                moduleId: PLATFORM.moduleName('pages/customer-portal/security/security'),
                title: 'Security',
                titleKey: 'CX_SECURITY',
                settings: { auth: true }
            },
            {
                route: 'security/reset-password',
                name: 'security-reset-password',
                moduleId: PLATFORM.moduleName('pages/customer-portal/security/security-reset-password/security-reset-password'),
                title: 'Reset Password',
                titleKey: 'CX_RESET_PASSWORD',
                settings: {
                    auth: true,
                    description: 'Change your sign in password',
                    descriptionKey: 'CX_RESET_PASSWORD_DESCRIPTION'
                }
            },
            {
                route: 'security/two-fa-settings',
                name: 'security-two-fa-settings',
                moduleId: PLATFORM.moduleName('pages/customer-portal/security/security-two-fa-settings/security-two-fa-settings'),
                title: '2FA settings',
                titleKey: 'CX_2FA_SETTINGS',
                settings: { auth: true }
            },
            {
                route: 'security/withdraw',
                name: 'security-withdraw',
                moduleId: PLATFORM.moduleName('pages/customer-portal/security/security-reset-password/security-reset-password'),
                title: 'Withdraw Password',
                titleKey: 'CX_WITHDRAW_PASSWORD',
                settings: {
                    auth: true,
                    isWithdraw: true,
                    description: 'Create or reset your password to withdraw balance',
                    descriptionKey: 'CX_WITHDRAW_PASSWORD_DESCRIPTION'
                }
            },
            {
                route: 'purchased',
                name: 'purchased',
                moduleId: PLATFORM.moduleName('pages/customer-portal/purchased/purchased'),
                title: 'Purchased',
                titleKey: 'CX_PURCHASED',
                settings: { auth: true }
            },
            {
                route: 'sold',
                name: 'sold',
                moduleId: PLATFORM.moduleName('pages/customer-portal/sold/sold'),
                title: 'Sold',
                titleKey: 'CX_SOLD',
                settings: { auth: true }
            },
            {
                route: 'exchange',
                name: 'exchange',
                moduleId: PLATFORM.moduleName('pages/customer-portal/exchanged/exchanged'),
                title: 'Exchange',
                titleKey: 'CX_EXCHANGE',
                settings: { auth: true }
            },
            {
                route: 'verification',
                name: 'verification',
                moduleId: PLATFORM.moduleName('pages/customer-portal/verification/verification'),
                title: 'Verification',
                titleKey: 'CX_VERIFICATION',
                settings: { auth: true },
                data: { tab: null },
            },
            {
                route: 'support',
                name: 'support',
                moduleId: PLATFORM.moduleName('pages/customer-portal/support/support'),
                title: 'Support',
                titleKey: 'CX_SUPPORT',
                settings: { auth: true }
            },
            {
                route: 'referrals',
                name: 'referrals',
                moduleId: PLATFORM.moduleName('pages/customer-portal/referrals/referrals'),
                title: 'Referrals',
                titleKey: 'CX_REFERRALS'
            },
            {
                route: 'balance',
                name: 'balance',
                moduleId: PLATFORM.moduleName('pages/customer-portal/balance/balance'),
                title: 'Balance',
                titleKey: 'CX_BALANCE',
                settings: { auth: true },
            },
            {
                route: 'balance/history',
                name: 'balance-history',
                moduleId: PLATFORM.moduleName('pages/customer-portal/balance/balance-history/balance-history'),
                title: 'Balance History',
                titleKey: 'CX_BALANCE_HISTORY',
                settings: { auth: true }
            },
            {
                route: 'balance/top-up',
                name: 'balance-top-up',
                moduleId: PLATFORM.moduleName('pages/customer-portal/balance/balance-topup/balance-topup'),
                title: 'Balance Top Up',
                titleKey: 'CX_BALANCE_TOP_UP',
                settings: { auth: true }
            },
            {
                route: 'balance/withdraw',
                name: 'balance-withdraw',
                moduleId: PLATFORM.moduleName('pages/customer-portal/balance/balance-withdraw/balance-withdraw'),
                title: 'Balance Withdraw',
                titleKey: 'CX_BALANCE_WITHDRAW',
                settings: { auth: true }
            },
            {
                route: 'subscription',
                name: 'subscription',
                moduleId: PLATFORM.moduleName('pages/customer-portal/subscription/subscription'),
                title: 'Subscription',
                titleKey: 'CX_SUBSCRIPTION',
                settings: { auth: true },
            },
            {
                route: 'subscription/manage-membership',
                name: 'manage-membership',
                moduleId: PLATFORM.moduleName('pages/customer-portal/subscription/manage-membership/manage-membership'),
                title: 'Manage Membership',
                titleKey: 'CX_MANAGE_MEMBERSHIP',
                settings: { auth: true }
            },
            {
                route: 'subscription/payment-details',
                name: 'payment-details',
                moduleId: PLATFORM.moduleName('pages/customer-portal/subscription/payment-details/payment-details'),
                title: 'Payment Details',
                titleKey: 'CX_PAYMENT_DETAILS',
                settings: { auth: true }
            },
            {
                route: 'subscription/cancel-membership',
                name: 'cancel-membership',
                moduleId: PLATFORM.moduleName('pages/customer-portal/subscription/cancel-membership/cancel-membership'),
                title: 'Cancel Membership',
                titleKey: 'CX_CANCEL_MEMBERSHIP',
                settings: { auth: true }
            },
            {
                route: 'subscription/cashback',
                name: 'cashback',
                moduleId: PLATFORM.moduleName('pages/customer-portal/subscription/cashback/cashback'),
                title: 'Cashback Earned',
                titleKey: 'CX_CASHBACK_EARNED',
                settings: { auth: true }
            },
            {
                route: 'orders',
                name: 'orders',
                moduleId: PLATFORM.moduleName('pages/customer-portal/orders/orders'),
                title: 'Orders',
                titleKey: 'CX_ORDERS',
                settings: { auth: true }
            }
        ]);

        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
    }
}
