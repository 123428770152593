import './cx-delivery-selector.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { Exchange } from 'services/models/purchase-flow/exchange';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';

@autoinject()
export class CxDeliverySelector {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(private deliveryDateConverter: DeliveryDateConverter) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');
    }

    @bindable exchange: Exchange;
    @bindable selectedDelivery: DeliveryMethod;
    @bindable deliveryMethods: DeliveryMethod[];
    baseAwsEndpoint: string;
    parent;

    attached() {
        if (this.deliveryMethods?.length) {
            this.selectDeliveryOption(this.deliveryMethods.find(x => x.name === 'regular'));
        }
    }

    selectDeliveryOption(option: DeliveryMethod) {
        this.clearSelection();
        option.selected = true;
        this.selectedDelivery = option;
        this.exchange.deliveryFeePercentage = option.markupPercent;
        this.parent.setFeeAndReceiveAmount();
    }

    clearSelection() {
        for (const option of this.deliveryMethods) {
            option.selected = false;
        }
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }
}
