// Module
var code = `<template>
    <div id="cx-paragraph-title" class="\${invertedColor ? 'text-white' : ''}">
        <div class="\${ captionStyle }">
           <cx-page-content-area key.bind="caption"></cx-page-content-area>
        </div>
        <div class="\${ textStyle }">
            <cx-page-content-area key.bind="text"></cx-page-content-area>
        </div>
    </div>
</template>
`;
// Exports
export default code;