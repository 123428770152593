import numeral from 'numeral';

export class RateCurrencyFormatValueConverter {
    toView(value: number, symbol: string, format: string) {
        if (!value) {
            return 0;
        }

        if (!format) {
            format = '(0,0.00)';
        }

        return `${symbol}${numeral(value).format(format)}`;
    }
}
