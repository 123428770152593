import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DeliveryMethod } from './models/purchase-flow/delivery-method';

@autoinject()
export class DeliveryMethodService {
    constructor(private api: ApiService) {}

    path = 'DeliveryMethod';
    deliveryMethods: DeliveryMethod[];

    async getAllDeliveryMethods() {
        if (this.deliveryMethods) {
            return this.deliveryMethods;
        }
        const deliveryMethods: DeliveryMethod[] = await this.api.doGet(this.path);
        this.deliveryMethods = deliveryMethods.filter(x => x.paymentMethodId);
        return this.deliveryMethods;
    }

    async getByPaymentMethodId(paymentMethodId: number): Promise<DeliveryMethod[]> {
        if (this.deliveryMethods) {
            return this.deliveryMethods.filter(x => x.paymentMethodId === paymentMethodId);
        }
        this.deliveryMethods = await this.api.doGet(`${this.path}/GetByPaymentMethodId/${paymentMethodId}`);
        return this.deliveryMethods;
    }
}
