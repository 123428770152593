// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/contact/banner.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/cloud-upload.svg", import.meta.url);
// Module
var code = `<template>
    <section id="contact">
        <div class="cx-container cx-contact-header">
            <cx-page-content-area key.bind="'CX_CONTACT_FIRST_TITLE'"
                class="cx-header-main-text font-bold text-extra-huge">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'CX_CONTACT_FIRST_SUBTITLE'"
                class="cx-header-second-text font-light text-medium pb-4">
            </cx-page-content-area>
        </div>
        <div class="cx-container">
            <div class="cx-group-cards row row-cols-xl-4 row-cols-lg-2 row-cols-md-1 d-flex justify-content-between align-content-between mx-0">
                <div class="card border-0 cursor-pointer p-0" click.delegate="scrollToFormWith(0)">
                    <div class="card-body d-flex align-items-center justify-content-center p-0">
                        <div class="card-title text-center font-bold text-large p-0 m-0">
                            \${ 'CX_HELP_ORDER' | siteStrings }
                        </div>
                    </div>
                </div>
                <div class="card border-0 cursor-pointer p-0" click.delegate="scrollToFormWith(1)">
                    <div class="card-body d-flex align-items-center justify-content-center p-0">
                        <div class="card-title text-center font-bold text-large p-0 m-0">
                            \${ 'CX_BUSINESS_INQUIRIES' | siteStrings }
                        </div>
                    </div>
                </div>
                <div class="card border-0 cursor-pointer p-0" click.delegate="scrollToFormWith(2)">
                    <div class="card-body d-flex align-items-center justify-content-center p-0">
                        <div class="card-title text-center font-bold text-large p-0 m-0">
                            \${ 'CX_CAREERS' | siteStrings }
                        </div>
                    </div>
                </div>
                <div class="card border-0 cursor-pointer p-0" click.delegate="scrollToFormWith(3)">
                    <div class="card-body d-flex align-items-center justify-content-center p-0">
                        <div class="card-title text-center font-bold text-large p-0 m-0">
                            \${ 'CX_HELP_ACCOUNT' | siteStrings }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cx-container cx-contact-banner">
            <img src="${___HTML_LOADER_IMPORT_0___}" alt="banner" loading="lazy" />
        </div>
        <div class="cx-container cx-contact-container">
            <div class="row row-cols-1 row-cols-md-1 row-cols-xl-2 d-flex align-items-center">
                <div class="col cx-contact-text">
                    <cx-page-content-area key.bind="'CX_CONTACT_SECOND_TITLE'"
                        class="font-bold text-extra-large cx-contact-title">
                    </cx-page-content-area>
                    <cx-page-content-area key.bind="'CX_CONTACT_PARAGRAPH_1'"
                        class="cx-contact-paragraph font-regular text-medium mb-4">
                    </cx-page-content-area>
                    <cx-page-content-area key.bind="'CX_CONTACT_PARAGRAPH_2'"
                        class="cx-contact-paragraph font-regular text-medium mb-4">
                    </cx-page-content-area>
                    <cx-page-content-area key.bind="'CX_CONTACT_PARAGRAPH_3'"
                        class="cx-contact-paragraph font-regular text-medium mb-4">
                    </cx-page-content-area>
                    <div class="visit-faq-btn-content">
                        <a href.bind="faqRoute | languageRoute:true & async">
                            <cx-button
                                class="mt-5 pt-3 text-medium"
                                button-text="\${ 'CX_VISIT_FAQ' | siteStrings }"
                                button-style.bind="'primary primary-style visit-faq-btn font-medium'"
                            ></cx-button>
                        </a>
                    </div>
                </div>
                <div class="col cx-contact-form">
                    <form id="contact-form" name="contact-form" class="pt-5" submit.delegate="sendEmail()">
                        <label for="inputDropdown" class="cx-label-form text-medium font-medium mt-4">
                            \${ 'CX_CATEGORY' | siteStrings }
                        </label>
                        <mdc-select outlined
                            class="cx-form-control cx-select my-2 form-control input-password p-0 w-100 text-regular"
                            required.bind="true"
                            value.bind="category"
                            selected-index.bind="optionSelected"
                            change.delegate="changeSelectHandler(\$event)"
                            click.delegate="handleClick(\$event)">
                            <section data-simplebar>
                                <mdc-list focusout.delegate="handleFocusOut(\$event)">
                                    <mdc-list-item class="\${ optionSelected == \$index ? 'd-none' : ''}" repeat.for="option of selectOptions" value.bind="option.name" disable-ripple="false">
                                        \${option.keyName | siteStrings:option.name}
                                    </mdc-list-item>
                                </mdc-list>
                            </section>
                        </mdc-select>
                        <label for="inputFullName" class="cx-label-form text-medium font-medium  mt-4">
                            \${ 'CX_FULL_NAME' | siteStrings }
                        </label>
                        <mdc-text-field outlined autocomplete="on" value.two-way="name" required.bind="true" type="text"
                            placeholder.bind="'CX_ENTER_NAME' | siteStrings " class="cx-form-control form-control input-password w-100 my-2">
                        </mdc-text-field>
                        <label class="cx-label-form text-medium font-medium mt-4">
                            \${ 'CX_EMAIL' | siteStrings }
                        </label>
                        <div class="position-relative d-flex align-items-center">
                            <mdc-text-field
                                outlined
                                autocomplete="on"
                                value.two-way="email & validate"
                                required.bind="true"
                                type="text"
                                placeholder.bind="'CX_ENTER_EMAIL' | siteStrings "
                                id="input-email"
                                class="cx-form-control \${ showGreenCheckMarkEmail ? 'cx-form-control--success' : '' } \${ showErrorCheckMarkEmail ? 'cx-form-control--error' : '' } form-control input-password w-100 my-2"
                                keyup.delegate="emailUpdatedOnKeyPress(\$event)"
                                focusin.delegate="emailUpdatedOnFocusIn()"
                                focusout.delegate="checkEmailValidation()"
                                ref="emailInput"
                            ></mdc-text-field>
                            <span if.bind="showGreenCheckMarkEmail" class="circle-icon check text-large position-absolute material-icons-outlined" alt="green check">check_circle</span>
                            <span if.bind="showErrorCheckMarkEmail" class="circle-icon error text-large position-absolute material-icons" alt="error">error_outline</span>
                            <mdc-circular-progress if.bind="showMiniSpinnerEmail" class="mini-spinner-icon position-absolute" size="20" stroke-width="2"></mdc-circular-progress>
                        </div>
                        <cx-phone-input
                            ref="phoneElement"
                            phone-input.two-way="phoneInput"
                            loading.two-way="loadingPhoneInput"
                            label-text="\${'CX_PHONE_NUMBER' | siteStrings }"
                            label-style="cx-label-form"
                            input-style="cx-form-control form-control input-password"
                            contain-label="true"
                            first-message.bind="false"
                            place-holder.bind="'Enter phone number'"
                        ></cx-phone-input>
                        <label class="cx-label-form text-medium font-medium  mt-4">
                            \${ 'CX_MESSAGE' | siteStrings }
                        </label>
                        <div
                            id="drop-zone-outside"
                            dragenter.trigger="dragEnterHandler(\$event)"
                            dragleave.trigger="dragLeaveHandler(\$event)"
                            dragover.delegate="dragEnterHandler(\$event)"
                            drop.trigger="dropHandler(\$event)"
                            class="cx-form-control message-area mt-2 d-flex justify-content-center cx-drop-zone-outside form-control">
                            <mdc-text-field 
                                focusin.trigger="emailFocus(\$event)"
                                focusout.delegate="emailFocus(\$event)"
                                textarea outlined
                                spellcheck="false"
                                rows="5"
                                required.bind="true"
                                end-aligned.bind="textareaEndAligned"
                                value.bind="message"
                                class="input-password bg-transparent form-control w-100">
                            </mdc-text-field>
                            <div class="wrapper-sm d-flex flex-row" id="wrapper-sm">
                                <div class="drop-zone-sm me-0 me-md-2 h-100" role="button">
                                    <label class="cursor-pointer" for="file-input">
                                        <span class="material-icons text-blue">attach_file</span>
                                    </label>
                                    <input
                                        class="d-none"
                                        id="file-input"
                                        type="file"
                                        change.delegate="changeHandler(\$event)"
                                        accept="image/*, video/*, .txt, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .pdf, .odp, .ods, .odt, .xml"
                                        multiple />
                                </div>
                                <div class="cx-first-files-list d-flex">
                                    <mdc-list class="w-100 ps-0 d-none d-md-flex">
                                        <mdc-list-item class="pe-1" repeat.for="file of tempFiles | spliceArray:0:4"
                                            value.bind="option">
                                            <div class="d-flex align-items-center ">
                                                <div class="file-name font-regular text-regular text-black cursor-default">
                                                    \${helper.sanitizeHtml(file.name, true)}
                                                </div>
                                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                                    click.delegate="deleteItem(\$index)" role="button">clear</span>
                                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2"></mdc-circular-progress>
                                            </div>
                                        </mdc-list-item>
                                    </mdc-list>
                                    <mdc-list class="w-100 ps-0 d-flex d-md-none">
                                        <mdc-list-item class="pe-1" repeat.for="file of tempFiles | spliceArray:0:2"
                                            value.bind="option">
                                            <div class="d-flex align-items-center ">
                                                <div class="file-name font-regular text-regular text-black cursor-default">
                                                    \${helper.sanitizeHtml(file.name, true)}
                                                </div>
                                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                                    click.delegate="deleteItem(\$index)" role="button">clear</span>
                                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2"></mdc-circular-progress>
                                            </div>
                                        </mdc-list-item>
                                    </mdc-list>
                                </div>
                                <div class="justify-self-end dropend d-none d-md-block" show.bind="tempFiles.length > 4 ? true : false">
                                    <span 
                                        class="material-icons text-medium align-text-bottom pt-2 dropup btn-group"
                                        click.delegate="toggleDropdown(\$event)"
                                        role="button">
                                        more_horiz
                                    </span>
                                    <mdc-list class="dropdown-menu cx-dropdown-files-list \${ showDropdown ? 'show' : '' }" focusout.delegate="toggleDropdown(\$event)" mouseout.delegate="toggleDropdown(\$event)">
                                        <mdc-list-item class="pe-2" repeat.for="file of tempFiles | spliceArray:4" value.bind="option">
                                            <div class="d-flex align-items-center ">
                                                <div class="file-name font-regular text-regular text-black cursor-default">
                                                    \${helper.sanitizeHtml(file.name, true)}
                                                </div>
                                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons" click.delegate="deleteItem(\$index + 4)" role="button">clear</span>
                                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2"></mdc-circular-progress>
                                            </div>
                                        </mdc-list-item>
                                    </mdc-list>
                                </div>
                                <div class="justify-self-end dropend d-block d-md-none" show.bind="tempFiles.length > 2 ? true : false">
                                    <span 
                                        class="material-icons text-medium align-text-bottom pt-2 dropup btn-group"
                                        click.delegate="toggleDropdown(\$event)"
                                        role="button">
                                        more_horiz
                                    </span>
                                    <mdc-list class="dropdown-menu cx-dropdown-files-list \${ showDropdown ? 'show' : '' }" focusout.delegate="toggleDropdown(\$event)" mouseout.delegate="toggleDropdown(\$event)">
                                        <mdc-list-item class="pe-2" repeat.for="file of tempFiles | spliceArray:2" value.bind="option">
                                            <div class="d-flex align-items-center ">
                                                <div class="file-name font-regular text-regular text-black cursor-default">
                                                    \${helper.sanitizeHtml(file.name, true)}
                                                </div>
                                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons" click.delegate="deleteItem(\$index + 2)" role="button">clear</span>
                                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2"></mdc-circular-progress>
                                            </div>
                                        </mdc-list-item>
                                    </mdc-list>
                                </div>
                            </div>
                            <div id="drop-zone" class="flex-column position-absolute">
                                <div class="drop-zone-lg col flex-column align-items-center justify-content-center">
                                    <img src="${___HTML_LOADER_IMPORT_1___}" alt="cloud icon" loading="lazy" />
                                    <div>\${ 'CX_DRAG_FILES' | siteStrings }</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <cx-button if.bind="!isSending" button-style="btn btn-send text-white"
                                button-text="\${ 'CX_SEND_US_MESSAGE' | siteStrings }" button-icon="send"
                                button-icon-style="text-small-plus mx-1"></cx-button>
                            <mdc-circular-progress if.bind="isSending" class="cx-spinner-icon" size="32" stroke-width="2"></mdc-circular-progress>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
`;
// Exports
export default code;