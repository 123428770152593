import './faq.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { FaqQuestion, FaqSection } from 'services/models/faq';
import { FaqSectionService } from 'services/faq-section-service';
import { ToastService } from 'services/toast-service';
import { bindable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Helper } from 'resources/helpers/helper';
import { isStaticAsset } from 'resources/constants';
import { getAWSBucketEndpoint } from 'environment';
import { SimplebarOverride } from 'resources/simplebar_override';

interface ExtendedFaqQuestion extends FaqQuestion {
    isVerificationQuestion?: boolean;
}

interface ExtendedFaqSection extends FaqSection {
    questions: ExtendedFaqQuestion[];
}

@autoinject()
export class Faq {
    @bindable isVerificationQuestion = false;
    private sections: ExtendedFaqSection[];
    @bindable({ callback:'searchChanged' }) private search: string;
    faqDialog;
    changeSearchWatch: NodeJS.Timeout;
    filteredSections;
    toastSearchSent: boolean;
    timeouts: NodeJS.Timeout[];
    pages: PageByWebsite[];
    constRepeatFor = 3;
    sizeSubscriber: Subscription;
    preSelectedCard:number;
    showDrawerContent = false;
    baseAwsEndpoint = getAWSBucketEndpoint('icons');
    prevSearch = '';
    currentLeadingIcon = 'search';
    isUpperbarOpen = false;
    isDrawerOpen = false;
    drawerContentSimplebar;
    width;
    phone: number;
    sizeChanged: Subscription;
    hasResetedDestkop: boolean;
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private faqSectionService: FaqSectionService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private taskQueue: TaskQueue,
        private helper: Helper,

        private eventAggregator: EventAggregator) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
        this.helper = helper;
        this.helper.getResolutions(this);

    }

    homePageRoute;

    getIconSrc(src) {
        return isStaticAsset().test(src) ? `/${src}` : `${this.baseAwsEndpoint}${src}`;
    }

    async activate(params) {
        [this.sections, this.pages] = await Promise.all([
            this.faqSectionService.getByWebsite(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.sections = this.sections.map(section => ({
            ...section,
            openByDefault:false,
            questions:section.questions.map(question => ({
                ...question,
                openByDefault:false
            }))
        }));
        this.filteredSections = this.sections;
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Faq')?.id);

        if (this.sections.length > 0) {
            this.sections.sort((a, b) => {
                return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position;
            });

            if (params?.card) {
                this.preSelectedCard = Number(params?.card ?? 1);
            }
        }
    }

    attached() {
        this.handleEventSubscriptions();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
    }

    detached() {
        this.sizeSubscriber?.dispose();
    }

    compareQuestionsMatch = (a, b) => Number(b !== null) - Number(a !== null) || a - b;

    compareOpenDefault = (a, b) => Number(b.openByDefault) - Number(a.openByDefault);

    keyUpHandler(ev) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchBySection(this.search);
            return;
        } else {
            this.changeSearchWatch = setTimeout(() => {
                if (this.search) {
                    this.searchBySection(this.search);

                } else {
                    this.filteredSections = this.sections;
                }
            }, 1000);
        }
        return true;
    }

    async searchBySection(value) {
        const valueFixed = value.trim().toLowerCase();

        // Check if prev search value is the same
        if (this.prevSearch === valueFixed) return;

        this.prevSearch = valueFixed;
        const sections = JSON.parse(JSON.stringify(this.sections.slice()));
        const filter = sections.map((section) => {
            const filteredQuestions = section.questions.filter(({ question }) => question.toLowerCase().trim().includes(valueFixed));
            if (filteredQuestions.length > 0) {
                section.questions = filteredQuestions;
                return section;
            }
            return;
        })
            .filter(s => s)
            .map((section, sectionIndex) => ({
                ...section,
                openByDefault: sectionIndex === 0,
                instant: true,
                questions: section.questions.map((q, questionIndex) => ({
                    ...q,
                    openByDefault: questionIndex === 0
                }))
            }));

        if (filter.length === 0) {
            if (this.toastSearchSent === false) {
                try {
                    this.toastSearchSent = true;
                    await this.toastService.showToast('Info', 'Couldn\'t find anything, ask livechat.', 'info');
                } catch (err) {
                    console.log(err);
                }
            }
            return this.filteredSections = this.sections;
        }

        const matchIsOpenByDefault = filter.find(s => s.openByDefault);
        const matchQuestionsIsOpenByDefault = matchIsOpenByDefault && matchIsOpenByDefault.questions.find(q => q.openByDefault);

        const current = this.filteredSections.find(s => s.openByDefault);
        const currentQuestions = current && current.questions.find(q => q.openByDefault);

        // Check if the matching question is already opened
        if (matchIsOpenByDefault?.id === current?.id && matchQuestionsIsOpenByDefault?.id === currentQuestions?.id && filter.length === this.filteredSections.length) return;
        this.filteredSections = filter;
    }

    resetFiltered() {
        this.filteredSections = this.sections.map(section => ({
            ...section,
            openByDefault: false,
            instant: false
        }));
    }

    handleOpenByDefault(val, id) {
        if (this.filteredSections.filter(s => s.id === id)[0].openByDefault) return;
        if (val) {
            this.filteredSections = this.filteredSections.map(section => ({
                ...section,
                openByDefault: section.id === id
            }));
            return;
        }
    }

    handleCloseUpperNavbar() {
        this.isUpperbarOpen = false;
        this.showDrawerContent = false;
        this.resetFiltered();
        this.clearSearch();
    }

    handleOpenUpperNavbar() {
        this.showDrawerContent = true;
        this.isUpperbarOpen = true;
    }

    handleLineClamp(e, id) {
        if (e.detail.value === null || e.detail.value === undefined) return;
        const headerElement = document.querySelector(`#section-header-${id}`);
        if (e.detail.value) {
            headerElement.classList.remove('line-clamp');
            headerElement.classList.add('hide-line-clamp');
            this.handleOpenByDefault(e.detail.value, id);
            return;
        }
        headerElement.classList.remove('hide-line-clamp');
        headerElement.classList.add('line-clamp');
    }

    getFirstColumnSliced(arr) {
        if (arr.length % 2 === 0) {
            return arr.slice(0, (arr.length / 2));
        }
        return arr.slice(0, (arr.length / 2) + 1);
    }

    getSecondColumnSliced(arr) {
        if (arr.length % 2 === 0) {
            return arr.slice((arr.length / 2));
        }
        return arr.slice((arr.length / 2) + 1);
    }

    clearSearch() {
        this.search = '';
        this.prevSearch = '';
        this.filteredSections = this.sections;
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            if (this.width >= this.phone) {
                !this.hasResetedDestkop && this.showDrawerContent && this.resetFiltered();
                this.showDrawerContent = false;
                this.hasResetedDestkop = true;
            } else {
                this.hasResetedDestkop = false;
                if (!this.isDrawerOpen) return;
                this.showDrawerContent = true;
            }
        });
    }

    handleLeadingIcon() {
        if (!this.search) {
            this.currentLeadingIcon = 'search';
            return;
        }
        this.currentLeadingIcon = 'arrow_left_alt';
    }

    reinstanceScrollbar() {
        const instance = SimplebarOverride.instances.get(this.drawerContentSimplebar);
        instance?.unMount?.();
        new SimplebarOverride(this.drawerContentSimplebar);
    }

    handleDrawerToggle(e) {
        this.isDrawerOpen = Boolean(e?.detail?.value);
        this.showDrawerContent = e?.detail?.value;
        this.resetFiltered();
        this.helper.handleMainPageScroll(this.isDrawerOpen);
    }

    searchChanged() {
        this.handleLeadingIcon();
    }

    handleSearchChange(event) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.search = event.target.value;

        this.helper.debounce(this, 'isSearching', 'searchingTimeout', 450, () => {
            if (this.search) {
                this.searchBySection(this.search);
                return;
            }
            setTimeout(() => this.clearSearch(), 200);
        });
    }

    showTable() {
        this.faqDialog.dialog.open();
    }
}
