// Module
var code = `<template>
    <div id="cx-exchange-pair" class="d-flex align-items-center cursor-pointer" click.delegate="clickFunction()">
        <div class="exchange-pair d-flex flex-row justify-content-between w-100">
            <img class="icon-pair" src.bind="\`/store/\${ pairData.from }.svg\`" alt="logo \${ pairData.from }" loading="lazy">
            <span class="text-medium-static font-regular">\${ pairData.from } > \${ pairData.to }</span>
            <img class="icon-pair" src.bind="\`/store/\${ pairData.to }.svg\`" alt="logo \${ pairData.to }" loading="lazy">
        </div>
    </div>
</template>
`;
// Exports
export default code;