import moment from 'moment';
export enum StagesCardForm {
    INACTIVE = 0,
    ADDING = 1,
    EDITING = 2,
    DELETING = 3,
    REQUESTING_CVV = 4
}

export class CreditCardHelper {

    creditCardTypes = {
        'ELO': {
            'type': 'ELO',
            'name': 'Elo',
            'checkOrder': 1,
            'regex': '^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|504175|506699|5067[0-6][0-9]|50677[0-8]|509[0-9][0-9][0-9]|636368|636369|636297|627780).*'
        },
        'HIPERCARD': {
            'type': 'HIPERCARD',
            'checkOrder': 2,
            'regex': '^(606282|637095).*'
        },
        'CENCOSUD': {
            'type': 'CENCOSUD',
            'name': 'Cencosud',
            'checkOrder': 3,
            'regex': '^603493.*'
        },
        'NARANJA': {
            'type': 'NARANJA',
            'name': 'Naranja',
            'checkOrder': 4,
            'regex': '^589562.*'
        },
        'TARJETASHOPPING': {
            'type': 'TARJETASHOPPING',
            'name': 'Tarjeta Shopping',
            'checkOrder': 5,
            'regex': '^(603488|(27995[0-9])).*'
        },
        'ARGENCARD': {
            'type': 'ARGENCARD',
            'name': 'Argencard',
            'checkOrder': 6,
            'regex': '^(501105).*'
        },
        'CABAL': {
            'type': 'CABAL',
            'name': 'Cabal',
            'checkOrder': 7,
            'regex': '^((627170)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400)))).*'
        },
        'CARTE_BLEUE': {
            'type': 'CARTE_BLEUE',
            'name': 'Carte Bleue',
            'checkOrder': 8,
            'regex': '^((3(6[1-4]|77451))|(4(059(?!34)|150|201|561|562|533|556|97))|(5(0[1-4]|13|30066|341[0-1]|587[0-2]|6|8))|(6(27244|390|75[1-6]|799999998))).*'
        },
        'CHINA_UNION_PAY': {
            'type': 'CHINA_UNION_PAY',
            'name': 'China Union Pay',
            'checkOrder': 9,
            'regex': '(^62(([4-6]|8)[0-9]{13,16}|2[2-9][0-9]{12,15}))$'
        },
        'JCB': {
            'type': 'JCB',
            'name': 'JCB',
            'checkOrder': 10,
            'regex': '^(2131|1800|35).*'
        },
        'DINERS': {
            'type': 'DINERS',
            'name': 'Diners Club',
            'checkOrder': 11,
            'regex': '^(3(0([0-5]|9|55)|6)).*'
        },
        'DISCOVER': {
            'type': 'DISCOVER',
            'name': 'Discover',
            'checkOrder': 12,
            'regex': '^(3[8-9]|(6((01(1|300))|4[4-9]|5))).+'
        },
        'AMEX': {
            'type': 'AMEX',
            'name': 'American Express',
            'checkOrder': 13,
            'regex': '^3(24|4[0-9]|7|56904|379(41|12|13)).+'
        },
        'MASTERCARD': {
            'type': 'MASTERCARD',
            'name': 'MasterCard',
            'checkOrder': 14,
            'regex': '^(5(([1-5])|(0[1-5]))|2(([2-6])|(7(1|20)))|6((0(0[2-9]|1[2-9]|2[6-9]|[3-5]))|(2((1(0|2|3|[5-9]))|20|7[0-9]|80))|(60|3(0|[3-9]))|(4[0-2]|[6-8]))).+'
        },
        'VISA': {
            'type': 'VISA',
            'name': 'Visa',
            'checkOrder': 15,
            'regex': '^4.+'
        },
        'UNKNOWN': {
            'type': 'UNKNOWN',
            'name': 'Unknown',
            'checkOrder': 16
        },
        'NEWCARD': {
            'type': 'NEWCARD',
            'name': 'NewCard',
            'checkOrder': 17
        }
    };

    validateCC(cardNumber): boolean {
        const n = cardNumber?.length ?? 0;
        if (n === 0) return;
        if (n > 19 || n < 13) {
            return false;
        }

        let s = 0;
        let m = 1;
        const l = n;
        for (let i = 0; i < l; i++) {
            const d = parseInt(cardNumber.substring(l - i - 1, l - i), 10) * m;
            s += d >= 10 ? d % 10 + 1 : d;
            m = m === 1 ? 2 : 1;
        }
        return s % 10 === 0;
    }

    setExpirationDateFieldHandlers(field) {
        let year, month;
        const valid = true;

        try {
            switch (field.value.length) {
                case 0:
                    break;
                case 1:
                    year = parseInt(field.value);
                    if (!(year >= 0)) {
                        throw 'Not Valid';
                    }
                    year >= 2 && (field.value = '0' + year + '/');
                    break;
                case 2:
                    if (isNaN(field.value)) {
                        throw 'Not Valid';
                    }

                    month = parseInt(field.value);

                    if (field.oldValue?.length === 3) {
                        field.value = field.value.substr(0, 2);
                        break;
                    }

                    if (month >= 1 && month <= 12) {
                        field.value += '/';
                    } else {
                        if (!(month > 12)) {
                            throw 'Not Valid';
                        }
                        field.value = '0' + field.value[0] + '/' + field.value[1];
                    }
                    break;
                case 3:
                    if (isNaN(field.value)) {
                        throw 'Not Valid';
                    }
                    month = parseInt(field.value.substr(0, 2));
                    if (!(month >= 1 && month <= 12)) {
                        throw 'Not Valid';
                    }
                    if ('/' === field.value[2]) {
                        field.value = month + '/';
                    } else {
                        if (isNaN(parseInt(field.value[2]))) {
                            throw 'Not Valid';
                        }
                        field.value = month + '/' + field.value[2];
                    }
                    break;
                case 4:
                    month = parseInt(field.value.substr(0, 2));
                    if (!(month >= 1 && month <= 12)) {
                        throw 'Not Valid';
                    }
                    break;
                default:
                    throw 'Not Valid';
            }
        } catch (error) {
            return;
        }
        if (valid) {
            field.oldValue = field.value;
        } else {
            field.value = field.oldValue || '';
        }
    }


    creditCardCvvValidation (cardNumber: string, cardCVV: string, type?: string): boolean {
        if (!type) {
            type = this.getType(cardNumber);
        } else {
            type = type.toUpperCase();
        }
        return !!this.creditCardCvvValidationNoType(cardCVV) && (null !== type && this.creditCardTypes.AMEX.type === type ? 4 === cardCVV.length : 3 === cardCVV.length);
    }

    creditCardCvvValidationNoType(cvv: string): boolean {
        return !(this.checkIfEmptyValidation(cvv) || !this.checkOnlyNumbersValidation(cvv)) && (cvv.length >= 3 && cvv.length < 5);
    }

    checkIfEmptyValidation(str: string): boolean {
        return !str || !this.normalizeString(str);
    }

    checkOnlyNumbersValidation(str) {
        return /^[0-9]+$/g.test(str);
    }

    isDateInFuture(year: number, month: number): boolean {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        if (year < 2000) {
            year += 2000;
        }
        return year > currentYear && year < 11 + currentYear || year === currentYear && month > currentMonth;
    }

    isCardExpiredOrClose(year: number, month: number) {
        const today = moment();
        const currentMonth = today.month() + 1;
        const currentYear = today.year();
        if (year < 2000) {
            year += 2000;
        }

        const isExpired = this.hasDatePassed(currentYear, currentMonth, year, month);
        if (isExpired) return 1;

        const close = today.add(4, 'months');
        const currentMonthClose = close.month() + 1;
        const currentYearClose = close.year();

        const isCloseToExpire = this.hasDatePassed(currentYearClose, currentMonthClose, year, month);
        if (isCloseToExpire) return 2;

        return 0;
    }

    hasDatePassed = (curYear, curMonth, year, month) => {
        return year < curYear || (month < curMonth && year === curYear);
    };

    creditCardExpiryDateValidation (year: number, month: number): boolean {
        return !(month > 12 || month < 1) && this.isDateInFuture(year, month);
    }

    creditCardExpiryDateValidationFromString (expiryDate: string): boolean {
        if (!expiryDate || !this.normalizeString(expiryDate))
            return false;
        const n = expiryDate.split('/');
        return this.creditCardExpiryDateValidation(parseInt(n[1]), parseInt(n[0]));
    }

    normalizeString (str: string): string {
        return str.trim().replace(/\s/g, '');
    }

    getType(cardNumber: string): string {
        let type;
        for (const key in this.creditCardTypes) {
            if (this.creditCardTypes[key].regex && new RegExp(this.creditCardTypes[key].regex).test(cardNumber)) {
                type = this.getCardTypeResource(key);
                break;
            }
        }
        return type || this.creditCardTypes.UNKNOWN.type;
    }

    getCardTypeResource(key): string {
        return this.creditCardTypes[key].type;
    }
}
