import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { StockProduct } from './models/game/product';

@autoinject()
export class ProductService {
    path = 'Product';

    constructor(private api: ApiService) {}

    async getProductsWithFilter(filter: string, gameId?: number): Promise<StockProduct[] | null> {
        return await this.api.doGet(this.path, { filter, gameId });
    }
}
