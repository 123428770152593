// Module
var code = `<template>
    <div id="customer-app" class="main-content-padding">
        <section class="portal-content">
            <aside class="sidebar sidebar-left">
                <cx-customer-portal-navigation route-name.bind="customerPortalRoute"></cx-customer-portal-navigation>
            </aside>
            <article class="main cg-col">
                <div class="main-container">
                    <router-view></router-view>
                </div>
            </article>
        </section>
    </div>
</template>
`;
// Exports
export default code;