import './cx-banner.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { clientId, websiteShortCode } from 'environment';

@autoinject()
export class CxBanner {
    constructor(
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private toastService: ToastService) { }

    @bindable text;
    @bindable state;
    @bindable showBanner;
    @bindable clickFunctionName;
    @bindable url;

    user;
    routeChangeSubscription;
    routeProcessingSubscription;

    async attached() {
        this.user = await this.sessionService.getProfile();
        this.handleEventSubscriptions();
    }

    detached() {
        this.routeChangeSubscription?.dispose();
        this.routeProcessingSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeProcessingSubscription = this.eventAggregator.subscribe('router:navigation:processing', async() => {
            if (this.clickFunctionName !== 'refresh') return;
            location.reload();
        });

        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:complete', async() => {
            if (this.state === 'warning' || this.state === 'info') return;
            this.showBanner = false;
        });
    }

    async closeBanner() {
        this.showBanner = false;
    }

    async clickFunction() {
        if (this.clickFunctionName) {
            if (this.clickFunctionName === 'resetPassword') {
                this.user = this.user ?? await this.sessionService.getProfile();

                const requestPasswordResponse = await this.customerService.requestPasswordReset({
                    email: this.user.email,
                    websiteShortCode: websiteShortCode(),
                    redirectUrl: location.href,
                    clientId: clientId()
                });

                if (!requestPasswordResponse) return;
                this.toastService.showToast('Info', 'You are required to reset your password. An email has been sent to you to do so.', 'info');
            } else if (this.clickFunctionName === 'refresh') {
                location.reload();
            }
        }
        this.closeBanner();
    }
}
