import './cx-chat-message.scss';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { apiEndpoint, baseUrl } from 'environment';
import { MdcDialogService } from '@aurelia-mdc-web/dialog';
import { ImageService } from 'services/image-service';
import { WebsiteService } from 'services/website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import moment from 'moment';
import JSZip from 'jszip';

@autoinject()
export class CxChatMessage {
    @bindable isSender;
    @bindable imageUrl;
    @bindable date;
    @bindable message;
    @bindable containerClass;
    @bindable files;
    @bindable ticketKey;
    @bindable opened = false;
    @bindable imageFiles = [];
    @bindable chatMessagesLoading;
    galleryDialog;
    pdfDialog;
    initialIndex;
    imagesList;
    user;
    environment = apiEndpoint();
    documentsList = [];
    customerPortalRoute;
    width;
    lastLoaded;
    userSubscription;
    sizeChanged;

    constructor(
        private dialogService: MdcDialogService,
        private imageService: ImageService,
        private websiteService: WebsiteService,
        private eventAggregator: EventAggregator,
        private taskQueue: TaskQueue
    ) { }

    bind(bindingContext, overridingContext) {
        this.initialIndex = overridingContext.$index;
        if (overridingContext.$last === true) {
            this.lastLoaded = true;
        }
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.customerPortalRoute = pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        await this.convertFiles();
        if (this.lastLoaded) {
            this.taskQueue.queueMicroTask(() => {
                setTimeout(() => {
                    this.chatMessagesLoading = false;
                }, 500);
            });
        }
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.userSubscription = this.eventAggregator.subscribe('user-updated', payload => {
            this.user = payload.user;
            if (this.isSender && this.user.avatarImagePath !== this.imageUrl) {
                this.imageUrl = this.user.avatarImagePath;
            }
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
        });
    }

    async convertFiles() {
        if (this.files) {
            for (const file of this.files) {
                if (!file.type) {
                    const imageData = await this.imageService.getImage('cg-support-ticket-attachments', file.fileName);
                    const newDocumentData = new Blob([imageData], { type: imageData.type });
                    file.imageData = URL.createObjectURL(newDocumentData);
                    file.type = imageData.type;
                }

                if (file.type.includes('image/')) {
                    this.imageFiles.push(file);
                } else {
                    file.extension = file.type.substring(file.type.length, file.type.indexOf('/'));
                    this.documentsList.push(file);
                }
            }
        }
    }

    openGallery(index) {
        this.opened = true;
        this.dialogService.open({ viewModel: this.galleryDialog, model: { imagesList: this.imageFiles, index: index } });
    }

    viewPdf(index) {
        this.dialogService.open({ viewModel: this.pdfDialog, model: { pdfContent: this.documentsList[index] } });
    }

    downloadAllImages() {
        if (this.imageFiles.length > 1) {
            const zip = new JSZip();
            for (const img of this.imageFiles) {
                zip.file(`${img.fileName}.png`, img.imageBlob);
            }
            zip.generateAsync({ type: 'blob' }).then((content) => {
                const linkElement = document.createElement('a');
                linkElement.href = window.URL.createObjectURL(new Blob([content], { type: 'application/zip' }));
                linkElement.download = `support_ticket_attachments_${moment.utc().local().format('MM/DD/YYYY hh:mm a')}.zip`;
                linkElement.click();
            });
        } else {
            const linkElement = document.createElement('a');
            linkElement.href = window.URL.createObjectURL(new Blob([this.imageFiles[0].imageBlob], { type: 'application/png' }));
            linkElement.download = `support_ticket_attachment_${moment.utc().local().format('MM/DD/YYYY hh:mm a')}.png`;
            linkElement.click();
        }
    }

    copyLink(dropdownMenu) {
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = `${baseUrl()}${this.customerPortalRoute}/support?ticketKey=${this.ticketKey}&messagePosition=${this.initialIndex}`;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        tempTextArea.remove();
        dropdownMenu.classList.remove('show');
    }
}
