// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/customer-portal/upload-support.svg", import.meta.url);
// Module
var code = `<template>
    <div
        id="cx-support-issue-input"
        class="cx-form-control message-area \${disabled ? 'disabled-area' : ''}"
        dragenter.trigger="dragEnterHandler(\$event)"
        dragover.delegate="dragEnterHandler(\$event)"
        dragleave.trigger="dragLeaveHandler(\$event)"
        drop.trigger="dropHandler(\$event)"
    >
        <div class="position-absolute w-100">
            <mdc-text-field
                ref="ticketContentArea"
                click.delegate="handleDropdownFocusOut()"
                keyup.delegate="keyUpHandler(\$event) & debounce: 500"
                textarea
                class="text-area input-password"
                name="ticketContent"
                placeholder="Describe your issue"
                value.bind="issue"
                spellcheck="false"
                disabled.bind="disabled"
                outlined
            ></mdc-text-field>
            <span class="material-icons position-absolute refresh-icon text-purple" if.bind="isSavingDraft">autorenew</span>
            <div class="wrapper-sm d-flex flex-row" id="wrapper-sm">
                <div class="drop-zone-sm me-0 me-md-2 h-100" role="button">
                    <label class="cursor-pointer" for="file-input">
                        <span class="material-icons attach-icon clickable-icon">attach_file</span>
                    </label>
                    <input
                        class="d-none"
                        id="file-input"
                        type="file"
                        change.delegate="changeHandler(\$event)"
                        accept="\${ inputAccept }"
                        multiple
                    />
                </div>
                <div class="cx-first-files-list d-flex">
                    <mdc-list class="w-100 ps-0 d-none d-lg-flex">
                        <mdc-list-item repeat.for="file of tempFiles | spliceArray:0:3">
                            <div class="d-flex align-items-center ">
                                <div class="file-name font-regular text-regular text-black cursor-default">
                                    \${ helper.sanitizeHtml(file.name, true) }
                                </div>
                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons" click.delegate="deleteItem(\$index)" role="button">
                                    clear
                                </span>
                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                </mdc-circular-progress>
                            </div>
                        </mdc-list-item>
                    </mdc-list>
                    <mdc-list class="w-100 ps-0 d-flex d-lg-none">
                        <mdc-list-item repeat.for="file of tempFiles | spliceArray:0:2">
                            <div class="d-flex align-items-center">
                                <div class="file-name font-regular text-regular text-black cursor-default">
                                    \${ helper.sanitizeHtml(file.name, true) }
                                </div>
                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons" click.delegate="deleteItem(\$index)" role="button">
                                    clear
                                </span>
                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                </mdc-circular-progress>
                            </div>
                        </mdc-list-item>
                    </mdc-list>
                </div>
                <div class="justify-self-end dropend d-none d-lg-block" show.bind="tempFiles.length > 3 ? true : false">
                    <span class="material-icons text-medium align-text-bottom pt-2 dropup clickable-icon"
                        click.delegate="toggleDropdown()" role="button">
                        more_horiz
                    </span>
                    <mdc-list class="dropdown-menu cx-dropdown-files-list \${ showDropdown ? 'show' : '' }">
                        <mdc-list-item class="pe-2 ps-1" repeat.for="file of tempFiles | spliceArray:3"
                            value.bind="option">
                            <div class="d-flex align-items-center ">
                                <div class="file-name font-regular text-regular text-black cursor-default">
                                    \${ helper.sanitizeHtml(file.name, true) }
                                </div>
                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                    click.delegate="deleteItem(\$index + 3)" role="button">clear</span>
                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                </mdc-circular-progress>
                            </div>
                        </mdc-list-item>
                    </mdc-list>
                </div>
                <div class="justify-self-end dropend d-block d-lg-none" show.bind="tempFiles.length > 2 ? true : false">
                    <span class="material-icons text-medium align-text-bottom pt-2 dropup clickable-icon"
                        click.delegate="toggleDropdown()" role="button">
                        more_horiz
                    </span>
                    <mdc-list class="dropdown-menu cx-dropdown-files-list \${ showDropdown ? 'show' : '' }">
                        <mdc-list-item class="pe-2 ps-1" repeat.for="file of tempFiles | spliceArray:2"
                            value.bind="option">
                            <div class="d-flex align-items-center ">
                                <div class="file-name font-regular text-regular text-black cursor-default">
                                    \${ helper.sanitizeHtml(file.name, true) }
                                </div>
                                <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                    click.delegate="deleteItem(\$index + 2)" role="button">clear</span>
                                <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                </mdc-circular-progress>
                            </div>
                        </mdc-list-item>
                    </mdc-list>
                </div>
            </div>
        </div>
        <div id="drop-zone" class="flex-column position-absolute">
            <div class="drop-zone-lg col d-flex flex-column align-items-center justify-content-center">
                <div if.bind="isAllFilesLoaded" class="d-flex align-items-center flex-column">
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="cloud icon" loading="lazy" />
                    <div class="drop-file-text">\${'CX_DROP_FILE_HERE' | siteStrings}</div>
                </div>
                <div if.bind="!isAllFilesLoaded" class="d-flex align-items-center w-100 flex-column">
                    <div class="uploading-text">\${'CX_UPLOADING' | siteStrings}...</div>
                    <mdc-linear-progress open.bind="true" buffer="1"></mdc-linear-progress>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;