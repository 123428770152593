import { IPhoneUpdatedEvent, IUserUpdatedEvent } from './../../../types/events';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import './cx-customer-portal-navigation.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/helpers/helper';
import { NavigationOption } from 'services/models/customer-portal/navigation-option';
import { ToastService } from 'services/toast-service';
import { CurrencyChangedEvent, OpenedVeriffDialogEvent, PhoneUpdatedEvent, SizeChangedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { adminPanelUrl } from 'environment';
import { User } from 'services/models/user/user';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { SiteSettingService } from 'services/site-setting-service';
import { UserUpdatedEvent } from '@chicksgroup/types';
import { UserDocumentService } from 'services/user-document-service';

@autoinject()
export class CxCustomerPortalNavigation {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private websiteService: WebsiteService,
        private helper: Helper,
        private toastService: ToastService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private siteSettingService: SiteSettingService,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private userDocumentService: UserDocumentService
    ) {
        this.helper.getResolutions(this);
    }

    @bindable routeName;
    @bindable isDropdown: boolean = false;
    @bindable viewingAsAdmin: boolean;
    @bindable isHome: boolean = false;
    @bindable width: number;
    user: User;
    routeChangeSubscriber: Subscription;
    userSubscriber: Subscription;
    phoneSubscriber: Subscription;
    routeSubscriber: Subscription;
    verificationSubscriber: Subscription;
    sizeChangedSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    loadingContentSubscriber: Subscription;
    customerPortalPageRoute;
    navigationPagesElement;
    desktop: number;
    options: NavigationOption[];
    moreOptions: NavigationOption[];
    element: HTMLElement;
    leaveListener;
    adminUrl: string = adminPanelUrl();
    loading: boolean = true;
    contentLoading: boolean = true;
    progressBarLoading: boolean = false;
    parent;
    balance: NavigationOption;
    customerBalanceEnabled;
    currentLanguage;
    isDesktop: boolean;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.currentLanguage = await this.sessionService.getLanguage();
        this.contentLoading = !this.isHome;
        this.width = this.helper.getWidth();
        this.handleEventSubscriptions();
        this.customerPortalPageRoute = await this.websiteService.getRouteOrDefault('Customer Portal');
        this.moreOptions = [
            new NavigationOption('verification', 'verified_user').setProp('keyName', 'CX_VERIFICATION'),
            new NavigationOption('security', 'lock').setProp('keyName', 'CX_SECURITY'),
            new NavigationOption('support', 'contact_support').setProp('keyName', 'CX_SUPPORT')
        ];

        this.options = [
            new NavigationOption('profile', 'person').setProp('keyName', 'CX_PROFILE'),
            new NavigationOption('subscription', 'star').setProp('keyName', 'CX_SUBSCRIPTION'),
            new NavigationOption('orders', 'list_alt').setProp('keyName', 'CX_ORDERS'),
            new NavigationOption('balance', 'account_balance_wallet').setProp('keyName', 'CX_BALANCE'),
            ...this.moreOptions
        ];

        this.handleHorizontalScroll();
        this.user = await this.sessionService.getProfile();
        if (!this.user) {
            const homePageRoute = await this.websiteService.getRouteOrDefault('Home', '');
            this.router.navigate(this.helper.toParams(`${homePageRoute}/`, { email: this.urlParams.email, token: this.urlParams.token }));
            return;
        }
        [this.user.emailInReview, this.user.idVerificationInReview, this.user.addressVerificationInReview, this.user.selfieVerificationInReview] = await Promise.all([
            this.sessionService.getEmailInReview(),
            this.sessionService.getIdVerificationInReview(this.user.id),
            this.sessionService.getAddressVerificationInReview(this.user.id),
            this.sessionService.getSelfieVerificationInReview(this.user.id)
        ]);
        if (!this.isDropdown) {
            this.options.push(new NavigationOption('more', 'more_horiz')
                .setProp('keyName', 'CX_MORE')
                .setProp('children', this.moreOptions.reversed()));
        } else {
            const canAccessAdminPanel = await this.canAccessAdminPanel();
            this.viewingAsAdmin = await this.sessionService.getAdminView() && this.user && canAccessAdminPanel;
            if (canAccessAdminPanel) {
                this.options.push(...[
                    new NavigationOption('admin panel', 'settings', this.adminUrl)
                        .setProp('keyName', 'CX_ADMIN_PANEL')
                        .setProp('callback', () => this.openAdminPanel()),
                    new NavigationOption('View As Admin', null)
                        .setProp('keyName', 'CX_VIEW_AS_ADMIN')
                        .setProp('customContent', this.makeViewAsAdminTemplate().outerHTML)
                        .setProp('callback', () => this.viewAsAdmin())
                ]);
            }
            this.options.push(
                new NavigationOption('Sign Out', 'logout')
                    .setProp('keyName', 'CX_SIGN_OUT')
                    .setProp('callback', () => this.logout())
            );
        }

        this.setParents();
        this.getOrderedChildren();
        this.customerBalanceEnabled = await this.siteSettingService.getKey('CustomerBalanceEnabled');
        this.balance = this.getOption('balance');
        this.balance.activeNavigation = this.customerBalanceEnabled.value === '1';
        this.setBalance();
        this.options.forEach(x => {
            [x, ...(x.children ?? [])].forEach(this.getHref.bind(this));
        });
        this.checkActive();
        await this.userDocumentService.getUserDocumentsById(this.user.id, null);

        if (this.isDropdown) {
            this.helper.actionAfterExists(this, 'viewAdminButton', '#view-switch', (element) => this.checkByViewAsAdmin(element), 4, 1000, false);
        }

        this.loading = false;
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
        this.element?.removeEventListener('mouseleave', this.leaveListener);
        this.helper.resetLoading('menu');
    }

    getHref(option) {
        option.href = option.children || option.callback ? undefined : (option.route || this.customerPortalPageRoute) + '/' + option.option;
    }

    shouldShowOption(option) {
        const op = option.option;

        if (this.isDropdown) return option.activeNavigation;

        return ['more', 'verification', 'security', 'support'].includes(op)
            ? op === 'more' === !this.isDesktop
            : option.activeNavigation;
    }


    getOrderedChildren() {
        this.options.filter(o => o.children).forEach(option => {
            if (this.isSticky) {
                option.orderedChildren = option.children;
                return;
            }
            const childrenCopy = option.children.slice();
            const checkIndex = (o) => ['exchange', 'purchased', 'sold'].indexOf(o.option);
            option.orderedChildren = childrenCopy.sort((a, b) => checkIndex(a) - checkIndex(b));
        });
    }

    handleEventSubscriptions() {
        const events = {
            [SizeChangedEvent]: (payload: ISizeEvent) => this.width = payload.width,
            [CurrencyChangedEvent]: () => this.setBalance(),

            [PhoneUpdatedEvent]: (payload: IPhoneUpdatedEvent) =>
                this.user.phoneNumberConfirmed = this.user.phoneNumberConfirmed || payload.successful,

            [UserUpdatedEvent]: async(payload: IUserUpdatedEvent) => {
                this.user = payload.user;
                this.viewingAsAdmin = this.viewingAsAdmin && this.user && await this.canAccessAdminPanel();
                this.setBalance();
                this.checkByViewAsAdmin();
            },

            'page-loaded': () => this.contentLoading = false,
            'router:navigation:success': () => {
                this.viewingAsAdminChanged();
                this.checkActive();
            }
        };

        if (!this.isDropdown) {
            events['loading-content'] = payload => this.progressBarLoading = payload.loading;

            events['verification-updated'] = payload => {
                ['id', 'address', 'selfie'].forEach((type, i) => {
                    const prop = `${type}VerificationInReview`;
                    if (payload.verification[prop] === undefined) return;
                    this.user[prop] = payload.verification[prop];
                    this.sessionService.deleteByUserIdAndCategoryId(this.user.id, i + 1);
                });
            };
        }

        this.helper.subscribeEvents(this, events);
        this.leaveListener = this.element.addEventListener('mouseleave', this.collapseAll.bind(this));
    }

    checkActive() {
        const checkMultiple = {
            'orders': ['order-details', 'purchased', 'sold', 'exchange'],
            'profile': ['referrals']
        };

        for (const option of this.options) {
            const name = option.option;
            const childrenElement = this.navigationPagesElement.querySelector(`.options-children-${name}`);
            if (childrenElement) {
                childrenElement.classList.add('hidden');
                childrenElement.removeAttribute('style');
            }
            option.isClicked = false;
            option.isActive = this.currentRoute.includes(name) || this.helper.includesSome(this.currentRoute ?? [], checkMultiple[name] ?? []);

            if (!option.children) continue;
            option.isActive = option.isActive || option.children?.filter(x => this.currentRoute.includes(x.option)).length >= 1;
            option.children?.forEach(x => x.isActive = !this.isDropdown && this.currentRoute.includes(x.option));
            option.isExpanded = false;
        }
    }

    getOption = (name) => this.getOptionByName(this.options, name);

    getOptionByName = (options, name) => options.find(o => o.option === name);

    setParents() {
        this.options
            .filter(option => option.children)
            .forEach(option =>
                option.setProp('children', option.children.map(child => ({ ...child, parent: option })))
            );
    }

    handleClick = (option: NavigationOption) => {
        this.options.forEach(o => o.isExpanded = false);
        option.callback?.();
        this.markActive(option);
        const hasChildren = option.children?.length;
        if (hasChildren && option.isActive) {
            this.parent?.handleDropdownClick?.(true);
        }
        if (!hasChildren && !option.callback) {
            this.parent?.removeShow?.();
        }
        if (option.route && !option.callback) return true;
    };

    makeViewAsAdminTemplate() {
        const el1 = document.createElement('div');
        el1.setAttribute('style', 'display: flex; align-items: space-between; text-transform: captilize;');
        el1.classList.add('customer-dropdown-option');
        const labelContainer = document.createElement('label');
        labelContainer.classList.add('switch');
        const inputContainer = document.createElement('input');
        inputContainer.setAttribute('id', 'view-switch');
        inputContainer.type = 'checkbox';
        const spanContainer = document.createElement('span');
        spanContainer.classList.add('slider', 'round');
        labelContainer.appendChild(inputContainer);
        labelContainer.appendChild(spanContainer);
        el1.prepend(labelContainer);
        return el1;
    }

    markActive(option) {
        if (option.children) {
            const existingExpandedNavigation = this.options.find(x => option !== x && x.isExpanded);
            if (existingExpandedNavigation) existingExpandedNavigation.isExpanded = false;
            if (this.isSticky) option.isExpanded = !option.isExpanded;
            option.isClicked = !option.isClicked;
            if (!option.isClicked) option.isExpanded = option.isClicked;
            this.hideOrShowIntercomDependingOnBubbleState(option.isExpanded && option.option === 'more');
            if (!this.isSticky && !option.isExpanded) this.helper.handleDropdownWithAnimation(this.navigationPagesElement, option, option.isClicked);
        }
        this.options.filter(o => o.option !== option.parent?.option).forEach(x => {
            if (x !== option && x.isActive) x.isClicked = false;
            x.isActive = false;
        });
        option.isActive = true;
    }

    ordersHover(option, active = true, forceSkip = false) {
        if (!option.children) return;
        this.helper.debounce(this, 'hover', `hoverTimeout${option.option}`, 150, () => {
            const skipAnimation = option.isExpanded === active;
            option.isExpanded = active;
            if (!option.children || option.isClicked || skipAnimation || forceSkip) return;
            this.helper.handleDropdownWithAnimation(this.navigationPagesElement, option, active);
        });
    }

    handleHorizontalScroll = () => {
        const customerAppElement = document.getElementById('customer-app');
        if (!customerAppElement || !this.navigationPagesElement) return;
        if (!('touchstart' in window)) customerAppElement.addEventListener('click', () => this.handleScrollEvent());
        customerAppElement.addEventListener('touchstart', () => this.handleScrollEvent());
        customerAppElement.addEventListener('wheel', () => this.handleScrollEvent());
    };

    handleScrollEvent = (event?) => {
        if (!event) return;
        this.options.forEach(x => x.isExpanded = false);
    };

    hideOrShowIntercomDependingOnBubbleState = (opened) => this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: opened, triggerDeviceStyling: true });

    @computedFrom('router.currentInstruction.queryParams')
    get urlParams() {
        return this.router?.currentInstruction?.queryParams;
    }

    @computedFrom('router.currentInstruction.fragment')
    get currentRoute() {
        return this.router?.currentInstruction?.fragment;
    }

    @computedFrom('options')
    get showAmount() {
        return this.options?.filter(x => !x.desktopHidden).length;
    }


    widthChanged() {
        this.helper.widthHandler(this);
        this.helper.debounce(this, 'widthChange', 'withTimeout', 200, () => this.getOrderedChildren());
    }

    imageOrExpanded = (option: NavigationOption): boolean => (!option.iconImage || option.isExpanded) && !option.customContent;

    async logout() {
        await this.sessionService.logout();
        this.toastService.showToast('Info', 'Please log back in to see your orders and continue trading', 'info');
    }

    viewAsAdmin() {
        const element = document.getElementById('view-switch') as HTMLInputElement;
        element.checked = !element.checked;
        this.viewingAsAdmin = element.checked;
    }

    openAdminPanel() {
        const checkOutWindow = window.open(this.adminUrl, '_blank');
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: this.adminUrl });
        }
    }

    async canAccessAdminPanel() {
        return await this.sessionService.checkRolesForPanelAccess();
    }

    viewingAsAdminChanged() {
        if (!this.isDropdown) return;
        this.sessionService.saveAdminView(this.viewingAsAdmin);
    }

    checkByViewAsAdmin(element?: HTMLInputElement) {
        element ??= document.getElementById('view-switch') as HTMLInputElement;
        if (element) element.checked = this.viewingAsAdmin;
    }

    collapseAll() {
        if (!this.isDropdown) return;
        const elements = this.helper.selectArray(document, '.force-show');
        if (elements.length) return;
        this.options.forEach(option => option.isExpanded = option.isClicked = false);
    }

    async setBalance() {
        if (!this.user || this.user?.balance < 0 || !this.isDropdown) return;
        let price = await this.currencyFormatValueConverter.toViewFiatOnly(this.user.balance);
        price = price.toUpperCase();
        if (!this.balance) return;
        this.balance.description = `${this.siteStringsValueConverter.toView('CX_BALANCE')} ${price}`;
        if (this.user.balance === 0) this.balance.subtitle = this.siteStringsValueConverter.toView('CX_ADD_BALANCE');
    }

    @computedFrom('isDropdown', 'isDesktop')
    get isSticky() {
        return !this.isDropdown && !this.isDesktop;
    }
}
