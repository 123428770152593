// Module
var code = `<template>
    <section id="bug-bounty">
        <div class="header font-bold py-2 cx-background text-light">
            <div class="mx-auto header-title text-start">Bug Bounty</div>
        </div>
        <div class="container text-medium">
            <cx-page-content-area 
                key.bind="'BUG_BOUNTY_LAST_UPDATED'"
                class="text-end last-update"
            >
            </cx-page-content-area>
            <cx-page-content-area 
                key.bind="'BUG_BOUNTY_CONTENT'"
                class="text-content"
                trigger-border-change="true"
            >
            </cx-page-content-area>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;