// Module
var code = `<template>
    <div id="cx-text-input" class="\${inputStyle}">
        <mdc-text-field
            ref="mainInputField"
            name.bind="name"
            value.two-way="value"
            outlined.bind="outlined"
            label.bind="inputLabel"
            class="cx-form-control cx-search-input border-fixed \${additionalInputs.length > 0 ? 'inline-parent' : ''} \${class}"
            placeholder.bind="inputPlaceholder"
            keyup.delegate="handleKeyUp(\$event)"
            readonly.bind="type === 'date'"
            input.delegate="handleInput(\$event)"
            focusin.delegate="handleFocusIn(\$event)"
            focusout.delegate="handleFocusOut(\$event)"
            disabled.bind="inputDisabled"
            type.bind="type"
            autocomplete.bind="autocompleteType"
            prefix.bind="leadingText">
            <span
                if.bind="leadingIcon"
                mdc-text-field-icon
                leading
                class="material-icons">
                \${leadingIcon}
            </span>
            <button
                if.bind="showTrailingIcon"
                mdc-icon-button
                mdc-text-field-icon
                trailing
                icon="close"
                tabindex="0"
                click.delegate="trailingIconClicked()"
                class="material-icons material-icon-button">
                \${trailingIcon}
            </button>
        </mdc-text-field>
        <cds-circular-progress if.bind="loading" class="mini-spinner-icon"></cds-circular-progress>
        <mdc-text-field
            repeat.for="additionalInput of additionalInputs"
            name.bind="additionalInput.name"
            id.bind="additionalInput.name"
            outlined.bind="additionalInput.outlined"
            value.two-way="additionalInput.value"
            class="cx-form-control cx-search-input additional-input \${class} \${mainInputFocused ? 'moveOut' : ''}"
            label.bind="!additionalInput.outlined || additionalInput.floatingLabel ? additionalInput.label : null"
            placeholder.bind="additionalInput.outlined && !additionalInput.floatingLabel ? additionalInput.label : ''"
            keyup.delegate="handleAdditionalKeyUp(\$event, additionalInput)"
            input.delegate="handleAdditionalInput(\$event, additionalInput)"
            focusin.delegate="handleFocusIn(\$event, additionalInput)"
            focusout.delegate="handleFocusOut(\$event, additionalInput)"
            disabled.bind="inputDisabled"
            type.bind="additionalInput.type"
            autocomplete.bind="autocompleteType"
            prefix.bind="additionalInput.leadingText">
            <mdc-icon
                if.bind="additionalInput.inputTrailingIcon && \$last"
                mdc-text-field-icon
                trailing
                class="material-icons">
                \${additionalInput.inputTrailingIcon}
            </mdc-icon>
            <span
                if.bind="additionalInput.leadingIcon"
                mdc-text-field-icon
                leading
                class="material-icons">
                \${additionalInput.leadingIcon}
            </span>
        </mdc-text-field>
    </div>
</template>
`;
// Exports
export default code;