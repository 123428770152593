import { Router } from 'aurelia-router';
import { Language } from './../../../services/models/language/language';
import './cx-language-slider.scss';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { getAWSBucketEndpoint } from 'environment';
import Glider from 'glider-js';
import { SessionService } from 'services/session-service';
import { LanguageChangedEvent } from 'resources/constants';
@autoinject()
export class CxLanguageSlider {
    constructor(
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private taskQueue: TaskQueue,
        private router: Router
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('languages');
        this.taskQueue = taskQueue;
    }

    @bindable languages: Language[];
    @bindable selectedLanguage: Language;
    @bindable languageBarLoading: boolean;
    baseAwsEndpoint: string;
    width;
    sizeChanged;
    languageSliderContainer;

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
        document.addEventListener('focusout', this.handleFocusOut.bind(this), true);
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            this.handleShowingOrHidingSliderArrows();
        });
    }

    getRenderedLastItem(last, languages) {
        if (!this.languages?.length && !languages?.length && last) {
            this.languageBarLoading = false;
            return;
        }
        this.width = this.width ?? (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
        if (last) {
            setTimeout(() => {
                this.languageBarLoading = false;
                this.taskQueue.queueMicroTask(() => {
                    const gliderElement = document.querySelector('div.glider-language');
                    if (gliderElement) {
                        const languageGlider = new Glider(gliderElement, {
                            draggable: true,
                            slidesToScroll: 'auto',
                            slidesToShow: 'auto',
                            itemWidth: '136',
                            rewind: true,
                            scrollPropagate: true,
                            scrollLock: true,
                            arrows: {
                                next: '.glider-language-next'
                            }
                        });
                        languageGlider.resize();
                        this.handleShowingOrHidingSliderArrows();
                    }
                });
            }, 500);
        }
    }

    handleShowingOrHidingSliderArrows = () => {
        const arrow = document.querySelector('.right-arrow-language');
        const languageGlider = this.languageSliderContainer?.querySelector('.glider-language') ?? document.querySelector('.glider-language');

        if (arrow.classList.contains('disabled') || languageGlider.offsetWidth >= languageGlider.scrollWidth) {
            arrow.classList.add('d-none');
            languageGlider.style.maxWidth = '100%';
            languageGlider.style.margin = 'auto';
            languageGlider.style.cursor = 'default';
        } else {
            arrow.classList.remove('d-none');
            languageGlider.style.maxWidth = languageGlider.style.margin = languageGlider.style.cursor = null;
        }
    };

    async selectLanguage(selectedLanguage: Language) {
        const prevLanguage = await this.sessionService.getLanguage();
        if (prevLanguage.id === selectedLanguage.id) return;
        const selectedOption = this.languages.findIndex(lang => lang.hrefLang === selectedLanguage.hrefLang);
        if (selectedOption > -1) {
            this.selectedLanguage = this.languages[selectedOption];
        }
        this.eventAggregator.publish(LanguageChangedEvent, { languageSelected: this.selectedLanguage });
        this.sessionService.setLanguage(selectedLanguage);
        this.changeLanguageURL(prevLanguage, this.selectedLanguage);
        location.reload();
    }

    changeLanguageURL(prevLanguage: Language, selectedLanguage: Language) {
        const hrefLang = ['en', 'en-US'].includes(selectedLanguage.hrefLang) ? '' : selectedLanguage.hrefLang;
        let currentUrl = this.router.currentInstruction.fragment;
        if (!['en', 'en-US'].includes(prevLanguage.hrefLang)) {
            currentUrl = currentUrl.replace(prevLanguage.hrefLang, '');
        }
        this.router.navigate([hrefLang, currentUrl].join('/').split('/').filter(e => e).join('/'));
    }

    handleFocus(language) {
        const isActive = this.selectedLanguage.id === language.id;

        const activeLanguageElement = document.querySelector('.language-container.active-language');

        if (!isActive && activeLanguageElement) {
            activeLanguageElement.classList.remove('active-border');
        }
    }

    handleFocusOut(_event) {
        const focusedElement = document.activeElement;
        const isLanguageContainerFocused = focusedElement && focusedElement.classList.contains('language-container');

        if (!isLanguageContainerFocused) {
            const activeLanguageElement = document.querySelector('.active-language');
            if (activeLanguageElement) {
                activeLanguageElement.classList.add('active-border');
            }
        }
    }
}
