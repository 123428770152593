import './cx-support-issue-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/helpers/helper';
import heic2any from 'heic2any';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()

export class CxSupportIssueInput {
    filesLoading: boolean;
    constructor(
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private helper: Helper,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) { }

    @bindable issue;
    @bindable filesList = [];
    @bindable isSavingDraft = false;
    @bindable fileExtensions = ['txt', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx', 'pdf', 'odp', 'ods', 'odt', 'xml', 'heic'];
    @bindable fileTypes = ['image', 'video'];
    @bindable viewingTicket;
    @bindable disabled = false;
    tempFiles = [];
    maxNumberOfFiles = 10;
    dropZoneOutside: HTMLElement;
    fileInput: HTMLElement;
    showDropdown: boolean;
    isAllFilesLoaded = true;
    inputAccept = '';
    parent;
    textAreaElement;
    ticketContentArea;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.textAreaElement = this.ticketContentArea.querySelector('textarea');
        if (!this.viewingTicket) {
            this.issue = await this.sessionService.getDraftMessage();
        }
        if (this.textAreaElement) {
            this.textAreaElement.placeholder = this.siteStringsValueConverter.toView('CX_TYPE_A_MESSAGE');
        }

        for (const type of this.fileTypes) {
            this.inputAccept += `${type}/*, `;
        }

        for (const extension of this.fileExtensions) {
            this.inputAccept += `.${extension}, `;
        }

        this.inputAccept = this.inputAccept.substring(0, this.inputAccept.length - 2);
        this.fileInput = document.getElementById('file-input');
        this.dropZoneOutside = document.getElementById('cx-support-issue-input');
    }

    dragEnterHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if ((ev.target.id === 'cx-support-issue-input' || ev.target.tagName === 'TEXTAREA') && !this.disabled) {
            this.dropZoneOutside.classList.add('hover-drop-zone');
            this.textAreaElement.placeholder = '';
        }
    }

    dragLeaveHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target.id === 'cx-support-issue-input' || ev.target.id === 'drop-zone' || ev.target.tagName === 'TEXTAREA') {
            this.dropZoneOutside.classList.remove('hover-drop-zone');
        }
    }

    async dropHandler(ev) {
        if (!this.disabled) {
            ev.preventDefault();
            ev.stopPropagation();

            this.filesLoading = true;
            if (ev.dataTransfer.items) {
                [...ev.dataTransfer.items].forEach(async item => {
                    if (item.kind === 'file') {
                        const file = item.getAsFile();

                        this.isAllFilesLoaded = false;
                        if (await this.checkFileType(file) && await this.checkFileSize(file)) {
                            if (this.tempFiles.length >= this.maxNumberOfFiles) {
                                setTimeout(() => {
                                    this.filesLoading = false;
                                    this.isAllFilesLoaded = true;
                                }, 1000);
                                this.dropZoneOutside.classList.remove('hover-drop-zone');
                                this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                            } else {
                                let newFileName = null;
                                let newItem = null;
                                if (file.name.includes('.heic')) {
                                    newFileName = file.name.replace('.heic', '_heic.png');
                                    this.tempFiles.push({ name: newFileName, isLoaded: false });
                                    newItem = this.blobToFile(await heic2any({
                                        blob: file,
                                        toType: 'image/png',
                                        quality: 1
                                    }).then(result => result), newFileName);
                                } else {
                                    this.tempFiles.push({ name: file.name, isLoaded: false });
                                }

                                this.filesList.push(newItem ? newItem : file);
                                this.tempFiles[this.findLastIndex(this.tempFiles, newItem ? newItem.name : file.name)].isLoaded = true;

                                if (this.tempFiles.filter(file => file.isLoaded === false).length === 0) {
                                    setTimeout(() => {
                                        this.filesLoading = false;
                                        this.isAllFilesLoaded = true;
                                    }, 1000);
                                    this.dropZoneOutside.classList.remove('hover-drop-zone');
                                }
                            }
                        } else {
                            setTimeout(() => {
                                this.filesLoading = false;
                                this.isAllFilesLoaded = true;
                            }, 1000);
                            this.dropZoneOutside.classList.remove('hover-drop-zone');
                        }
                    }
                });
            } else {
                [...ev.dataTransfer.files].forEach(async file => {
                    this.isAllFilesLoaded = false;
                    if (await this.checkFileType(file) && await this.checkFileSize(file)) {
                        if (this.tempFiles.length >= this.maxNumberOfFiles) {
                            setTimeout(() => {
                                this.filesLoading = false;
                                this.isAllFilesLoaded = true;
                            }, 1000);
                            this.dropZoneOutside.classList.remove('hover-drop-zone');
                            this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                        } else {
                            let newFileName = null;
                            let newItem = null;
                            if (file.name.includes('.heic')) {
                                newFileName = file.name.replace('.heic', '_heic.png');
                                this.tempFiles.push({ name: newFileName, isLoaded: false });
                                newItem = this.blobToFile(await heic2any({
                                    blob: file,
                                    toType: 'image/png',
                                    quality: 1
                                }).then(result => result), newFileName);
                            } else {
                                this.tempFiles.push({ name: file.name, isLoaded: false });
                            }

                            this.filesList.push(newItem ? newItem : file);
                            this.tempFiles[this.findLastIndex(this.tempFiles, newItem ? newItem.name : file.name)].isLoaded = true;

                            if (this.tempFiles.filter(file => file.isLoaded === false).length === 0) {
                                setTimeout(() => {
                                    this.filesLoading = false;
                                    this.isAllFilesLoaded = true;
                                }, 1000);
                                this.dropZoneOutside.classList.remove('hover-drop-zone');
                            }
                        }
                    } else {
                        setTimeout(() => {
                            this.filesLoading = false;
                            this.isAllFilesLoaded = true;
                        }, 1000);
                        this.dropZoneOutside.classList.remove('hover-drop-zone');
                    }
                });
            }

            if (ev.dataTransfer.items) {
                ev.dataTransfer.items.clear();
            } else {
                ev.dataTransfer.clearData();
            }
        }
    }

    async changeHandler(ev) {
        for (let file of ev.target.files) {
            if (await this.checkFileType(file) && await this.checkFileSize(file)) {
                if (this.tempFiles.length >= this.maxNumberOfFiles) {
                    await this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                } else {
                    let newFileName = null;
                    if (file.name.includes('.heic')) {
                        newFileName = file.name.replace('.heic', '_heic.png');
                        this.tempFiles.push({ name: newFileName, isLoaded: false });
                        file = this.blobToFile(await heic2any({
                            blob: file,
                            toType: 'image/png',
                            quality: 1
                        }).then(result => result), newFileName);
                    } else {
                        this.tempFiles.push({ name: file.name, isLoaded: false });
                    }
                    this.filesList.push(file);
                    this.tempFiles[this.findLastIndex(this.tempFiles, file.name)].isLoaded = true;
                }
            }
        }
        this.fileInput['value'] = null;
    }

    blobToFile(theBlob, fileName): File {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }

    async checkFileType(file) {
        if (this.fileTypes.filter(item => file.type.startsWith(item + '/')).length > 0 ||
            this.fileExtensions.filter(item => file.name.endsWith('.' + item)).length > 0) {
            return true;
        }

        await this.toastService.showToast(`File type for ${ file.name } is not allowed`, 'Error', 'error');
        return false;
    }

    async checkFileSize(file) {
        const maxSizePerFile = 20971520; //Equivalent to 20 MB
        const maxSizeAllFiles = 20971520; //Equivalent to 20 Mb

        if (file.size > maxSizePerFile) {
            await this.toastService.showToast(`File size for ${file.name} exceeds 20 MB`, 'Error', 'error');
            return false;
        }

        if (this.filesList.map(item => item.size).reduce((prev, curr) => prev + curr, 0) + file.size > maxSizeAllFiles) {
            await this.toastService.showToast('The total size of all files must not exceed 20 MB', 'Please try again', 'error');
            return false;
        }

        return true;
    }

    findLastIndex(array, query) {
        const arrayTemp = [];
        array.forEach(element => arrayTemp.push(element.name));
        return arrayTemp.lastIndexOf(query);
    }

    deleteItem(index: number) {
        this.tempFiles.splice(index, 1);
        this.filesList.splice(index, 1);
    }

    showItem(index: number) {
        return index < 4 ? true : false;
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        return this.showDropdown;
    }

    handleDropdownFocusOut() {
        if (this.showDropdown) {
            this.showDropdown = false;
            return this.showDropdown;
        }
    }

    async keyUpHandler(event) {
        if (event.key === 'Enter') {
            this.eventAggregator.publish('support-issue-input-keyup', { key : event.key });
        }

        if (Object.keys(this.parent).includes('isSavingDraft')) {
            this.isSavingDraft = true;
            if (!this.viewingTicket) {
                await this.sessionService.saveDraftMessage(event?.target?.value);
                if (!await this.sessionService.getDraftCreatedDate()) {
                    await this.sessionService.saveDraftCreatedDate(new Date());
                }
                await this.sessionService.saveDraftUpdatedDate(new Date());
            }
            setTimeout(() => {
                this.isSavingDraft = false;
            }, 2000);
        }
    }

    cleanFileList() {
        this.tempFiles = this.filesList = [];
    }
}
