import './cx-tooltip.scss';
import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { Callback } from 'resources/extensions/type_extensions';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxTooltip {
    constructor(private element: Element, private helper: Helper) {}

    @bindable message: string;
    @bindable icon = 'contact_support';
    @bindable size = 16;
    @bindable isLoading = false;
    @bindable noWrap = false;
    @bindable tooltip;
    @bindable color = '#9DB8FD';
    positions = ['START', 'MIDDLE', 'END'];
    @bindable xPosition = this.positions[1];

    attached() {
        this.setProperty('--size', this.size + 'px');
        this.colorChanged();
        this.noWrapChanged();
    }

    setProperty(property: string, value: string) {
        if (!value || !this.element) return false;
        (this.element as HTMLElement).style.setProperty(property, value);
        return true;
    }

    messageChanged() {
        this.actionWhenTooltip('addingMessage',
            () => this.tooltip.value = this.tooltip.tooltip.firstElementChild.innerHTML = this.message
        );
    }

    colorChanged() {
        const isSet = this.setProperty('--color', this.color);
        if (!isSet) return;
        this.element?.classList?.add?.('cx-tooltip--colored');
    }

    xPositionChanged() {
        this.actionWhenTooltip('addingXPosition',
            () => {
                this.tooltip.xPosition = this.xPosition;
                this.tooltip.tooltip.classList.add(`horizontal-${this.xPosition.toLowerCase()}`);
                if (!this.mdcTooltip) return;
                this.mdcTooltip.foundation.xTooltipPos = this.indexOfPos;
            }
        );
    }

    noWrapChanged() {
        this.actionWhenTooltip('addingWrap',
            () => this.tooltip.tooltip.firstElementChild.style.maxWidth = this.noWrap ? '100%' : null
        );
    }

    actionWhenTooltip(name: string, callback: Callback) {
        this.helper.actionAfterCondition(this, name, () => !!this.tooltip?.tooltip?.firstElementChild, callback);
    }

    @computedFrom('tooltip.tooltip.au.mdc-tooltip.viewModel')
    get mdcTooltip() {
        return this.tooltip?.tooltip?.au?.['mdc-tooltip']?.viewModel;
    }

    @computedFrom('xPosition')
    get indexOfPos() {
        return this.positions.indexOf(this.xPosition) + 1;
    }
}
