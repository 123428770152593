export class callValueConverter {
    toView() {
        // eslint-disable-next-line prefer-rest-params
        const value = arguments[0];
        if (typeof value !== 'function') return;
        // eslint-disable-next-line prefer-rest-params
        const rest = Array.from(arguments).splice(1);
        return () => value(...rest);
    }
}
