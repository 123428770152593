import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, inject, customAttribute } from 'aurelia-framework';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { Currency, Exchange, OrderCryptoExchanges } from 'services/models/purchase-flow/exchange';
import { SessionService } from 'services/session-service';

@customAttribute('receive-tooltip-content')
@inject(Element, CurrencyFormatValueConverter, EventAggregator, SessionService)
export class ReceiveTooltipContentCustomAttribute {
    @bindable orderedExchange: OrderCryptoExchanges;
    @bindable exchange: Exchange;
    exchangeUpdatedSub: Subscription;

    private baseCurrency: Currency;
    private targetCurrency: Currency;

    constructor(
        private element: HTMLElement,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService
    ) {
    }

    async attached() {
        this.exchangeUpdatedSub = this.eventAggregator.subscribe('exchange-updated', (event: { exchange: Exchange }) => {
            if (!event.exchange)
                return;
            this.exchange = event.exchange;
            this.getBaseAndTargetCurrency();
            this.createTooltipContentForExchange();
        });
    }

    detached() {
        this.exchangeUpdatedSub.dispose();
    }

    async getExchange() {
        this.exchange = await this.sessionService.getExchange();
        if (!this.exchange)
            return;
        this.getBaseAndTargetCurrency();
        this.createTooltipContentForExchange();
    }

    private getBaseAndTargetCurrency() {
        this.baseCurrency = this.exchange.giveSelectedCurrency;
        this.targetCurrency = this.exchange.receiveSelectedCurrency;
    }

    exchangeChanged() {
        if (!this.exchange)
            return;
        try {
            this.getBaseAndTargetCurrency();
            this.createTooltipContentForExchange();
        } catch (e) {
            console.log(e);
        }
    }

    orderedExchangeChanged() {
        if (!this.orderedExchange)
            return;
        try {
            this.baseCurrency = this.orderedExchange.baseCurrency;
            this.targetCurrency = this.orderedExchange.targetCurrency;
            this.createTooltipContentForOrderCryptoExchanges();
        } catch (e) {
            console.log(e);
        }
    }

    private createTooltipContentForExchange() {
        const basePrice = this.getFormatedCurrency(1, this.baseCurrency, '(0)');
        const targetPrice = this.getFormatedCurrency(this.exchange.currentRate, this.targetCurrency);
        const baseSpend = this.getFormatedCurrency(this.exchange.amountGifted, this.baseCurrency);
        const targetSpend = this.getFormatedCurrency(this.exchange.amountGifted * this.exchange.currentRate, this.targetCurrency);
        const baseFee = this.getFormatedCurrency(this.exchange.transactionFee / this.exchange.currentRate, this.baseCurrency);
        const targetFee = this.getFormatedCurrency(this.exchange.transactionFee, this.targetCurrency);
        const receive = this.getFormatedCurrency(this.exchange.amountReceived, this.targetCurrency);
        this.createTooltipContent(basePrice, targetPrice, baseSpend, targetSpend, baseFee, targetFee, receive);
    }

    // Order-completed page

    private createTooltipContentForOrderCryptoExchanges() {
        const basePrice = this.getFormatedCurrency(1, this.baseCurrency, '(0)');
        const targetPrice = this.getFormatedCurrency(this.orderedExchange.rate, this.targetCurrency);
        const baseSpend = this.getFormatedCurrency(this.orderedExchange.amountTotal, this.baseCurrency);
        const targetSpend = this.getFormatedCurrency(this.orderedExchange.amountTotal * this.orderedExchange.rate, this.targetCurrency);
        const baseFee = this.getFormatedCurrency(this.orderedExchange.paymentFee / this.orderedExchange.rate, this.baseCurrency);
        const targetFee = this.getFormatedCurrency(this.orderedExchange.paymentFee, this.targetCurrency);
        const receive = this.getFormatedCurrency(this.orderedExchange.amountTarget, this.targetCurrency);
        this.createTooltipContent(basePrice, targetPrice, baseSpend, targetSpend, baseFee, targetFee, receive);
    }

    private createTooltipContent(basePrice: string, targetPrice: string, baseSpend: string, targetSpend: string, baseFee: string, targetFee: string, receive: string) {
        this.element.innerHTML = `
            <div class="d-flex justify-content-between">
                <div>Price</div>
                <div>${basePrice} ~ ${targetPrice}</div>
            </div>
            <div class="d-flex justify-content-between">
                <div>Spend</div>
                <div>${baseSpend} ~ ${targetSpend}</div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="me-2">Transaction fee</div>
                <div>${baseFee} ~ ${targetFee}</div>
            </div>
            <div class="d-flex justify-content-between">
                <div>Receive</div>
                <div>${receive}</div>
            </div>`;
    }

    private getFormatedCurrency(value: number, currency: Currency, format?: string) {
        return this.currencyFormatValueConverter.toView(
            value,
            currency?.symbol,
            currency?.type,
            currency?.isStable,
            format);
    }
}
