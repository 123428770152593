/* eslint-disable camelcase */
const emailDisallowConRegExr = () => new RegExp(/^(\s+)?[\w-.+%-]+@([\w-]+\.)+(?!con$)[\w-]{1,4}(\s+)?$/);

const onlyNumberRegExp = () => new RegExp('\\d');

const numbersAndSlashExp = () => new RegExp('^[0-9/]+$');
const isStaticAsset = () => new RegExp('!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i');

const buttonRegExp = () => new RegExp(/\${button:\s*([^,]+),\s*link:\s*([^,}]+)(?:,\s*display:\s*([^}]+))?}/g);

const ForceCurrencyEvent = 'force-currency';
const UserUpdatedEvent = 'user-updated';
const PhoneUpdatedEvent = 'phone-updated';
const VeriffVerificationEvent = 'veriff-verification';
const CurrencyChangedEvent = 'currency-change';
const SizeChangedEvent = 'size-changed';
const MethodPayoutableChangedEvent = 'method-payoutable';
const ForcePaymentMethodEvent = 'force-payment-method';
const SpecialSelectorEvent = 'special-selector-focus-toggle';
const OpenedVeriffDialogEvent = 'opened-veriff-dialog';
const LanguageChangedEvent = 'language-change';
const AdminViewUpdatedEvent = 'admin-view-updated';
const DesktopBreakpoint = 992;
const TabletBreakpoint = 768;
const PhoneBreakpoint = 576;

export const device__smallphone = 320;
export const device__smallphoneplus = 375;
export const device__mediumphone = 425;
export const device__phone = 576;
export const device__smalltablet = 720;
export const device__tablet = 768;
export const device__smalldesktop = 952;
export const device__desktop = 992;
export const device__laptop = 1024;
export const device__mediumdesktop = 1080;
export const device__widescreen = 1200;
export const device__widescreenplus = 1235;
export const device__mediumwidescreen = 1400;
export const device__laptoplarge = 1440;
export const device__extrascreen = 1500;
export const device__extrawidescreen = 1550;
export const device__extralargeplusscreen = 1920;
export const device__extralargescreen = 3839;

export const PAGE_NAMES = {
    HOME: 'Home',
    CURRENCY: 'Currency',
    CONTACT: 'Contact Us',
    BLOG: 'Blog',
    FAQ: 'Faq',
    ABOUT: 'About Us',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_SERVICE: 'Terms of Service',
    COPYRIGHT_POLICY: 'Copyright Policy',
    CART: 'Cart',
    CUSTOMER_PORTAL: 'Customer Portal',
    EXPIRED_LINK: 'Expired Link',
    SITE_DOWN: 'Site Down',
    RATE_LIMIT: 'Rate Limit',
    ORDER_DETAILS: 'Order Details',
    BUG_BOUNTY: 'Bug Bounty',
    SITEMAP: 'Sitemap',
    SUBSCRIPTION: 'Subscription',
    BALANCE: 'Balance',
    COOKIES: 'Cookies',
    CAREERS: 'Careers',
    NOTIFICATIONS:  'Notifications',
    LOCATIONS: 'Locations',
};

export default this;

export {
    isStaticAsset,
    emailDisallowConRegExr,
    onlyNumberRegExp,
    numbersAndSlashExp,
    buttonRegExp,
    ForceCurrencyEvent,
    PhoneUpdatedEvent,
    UserUpdatedEvent,
    VeriffVerificationEvent,
    CurrencyChangedEvent,
    SizeChangedEvent,
    MethodPayoutableChangedEvent,
    DesktopBreakpoint,
    TabletBreakpoint,
    ForcePaymentMethodEvent,
    SpecialSelectorEvent,
    PhoneBreakpoint,
    OpenedVeriffDialogEvent,
    LanguageChangedEvent,
    AdminViewUpdatedEvent
};
