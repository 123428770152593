// Module
var code = `<template>
    <div id="cx-coupon-input" class="trade-form">
        <div class="grid-justify-self-end enter-coupon" click.delegate="openCoupon()" if.bind="!couponOpen" skeleton-loading="is-loading.bind:pageLoading">
            \${'CX_ENTER_COUPON_CODE' | siteStrings}
        </div>
        <form submit.delegate="checkCouponValidation()" class="position-relative d-inline-block" if.bind="couponOpen && state != 'toText'">
            <mdc-text-field
                outlined
                autocomplete="off"
                value.two-way="tempCoupon"
                id="coupon-input"
                type="text"
                name="Coupon"
                class="input-password cx-form-control coupon-input"
                class.bind="getElementStateClass(state)"
                focusin.delegate="couponUpdatedOnFocusIn()"
                focusout.delegate="couponOnFocusOut()"
            ></mdc-text-field>
            <span class="circle-icon check material-icons-outlined" alt="green check" if.bind="state == 'success'">check_circle</span>
            <span class="circle-icon error material-icons" alt="error" if.bind="state == 'error'">error_outline</span>
            <mdc-circular-progress if.bind="showMiniSpinnerCoupon" class="mini-spinner-icon" size.bind="20" stroke-width="2"></mdc-circular-progress>
        </form>
        <div if.bind="couponOpen && state == 'toText'">
            <span click.delegate="couponUpdatedOnFocusIn()" class="coupon-apply-amount cursor-pointer">\${appliedDiscount} with coupon \${coupon}</span>
        </div>
    </div>
</template>
`;
// Exports
export default code;