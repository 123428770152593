// Module
var code = `<template>
    <div id="cx-dropdown">
        <div class="text-end align-items-center position-relative \${borderless ? 'dropdown-borderless' : 'dropdown'} \${borderRadius == 'full' ? 'border-radius-full' : ''} \${position == 'last' ? 'last-input' : ''}">
            <button
                id="dropdown_btn"
                class="w-100 position-relative text-start cursor-pointer align-items-center d-flex text-normal p-btn \${borderRadius == 'full' ? 'border-radius-full' : ''} \${position == 'last' ? 'last-input' : ''} btn"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                data-bs-auto-close="outside"
                aria-expanded="false"
                type="button"
            >
                <div class="row p-0">
                    <div class="col-12 text-truncate text-normal input-placeholder \${pressed ? 'text-black' : 'text-input-placeholder'}">
                        \${ placeholder | textTruncate:12 }
                    </div>
                </div>
                <span class="ms-2 material-icons expand-icon" if.bind="!icon">
                    arrow_drop_down
                </span>
            </button>
            <span if.bind="!(parent.showGreenCheckMarkAddress || parent.showMiniSpinnerAddress)" class="calendar-icon material-icons-outlined" alt="calendar">\${icon}</span>
            <div id="dropdown-menu" class="dropdown-menu text-start expanded-selected" aria-labelledby="cx-dropdown">
                <section data-simplebar class="dropdown-scrollbar purple-scrollbar">
                    <div class="row">
                        <div
                            if.bind="showDeepName"
                            class="col-12 mb-2 cursor-pointer menu-option"
                            repeat.for="option of options"
                            click.delegate="selectOption(option)"
                        >
                            \${option.name}
                        </div>
                        <div
                            else
                            class="col-12 mb-2 cursor-pointer menu-option"
                            repeat.for="option of options"
                            click.delegate="selectOption(option)"
                        >
                            \${option}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;