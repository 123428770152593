// Module
var code = `<template>
    <div id="cx-dynamic-icon" ref="dynamicIconElement">
        <img if.bind="icon.isFile()" hex-to-filter="hex-code.bind:color" src="\${icon.isFile(true) ? \`/icons/\${icon}\` : icon}" alt="\${alt}" class="dynamic-icon \${iconClass || ''}">
        <div else>
            <div if.bind="symbol" class="material-symbols\${outlined ? '-outlined' : ''} dynamic-icon \${iconClass || ''}">\${icon}</div>
            <div else class="material-icons\${outlined ? '-outlined' : ''} dynamic-icon \${iconClass || ''}">\${icon}</div>
        </div>
    </div>
</template>
`;
// Exports
export default code;