// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/chicks/crying_chick_empty_new.svg", import.meta.url);
// Module
var code = `<template>
    <div id="balance-history" class="balance-view-container">
        <cx-back-button if.bind="width < 992" route.bind="'balance'"></cx-back-button>
        <h2 class="title">
            <cx-page-content-area 
                key.bind="'CX_BALANCE_HISTORY_TITLE'"
            ></cx-page-content-area>
        </h2>
        <cx-text-input
            if.bind="width > 576"
            class="w-100 search-bar no-shadow"
            label.bind="'CX_SEARCH' | siteStrings"
            button-icon.bind="search"
            leading-icon.bind="'search'"
            value.two-way="searchParams.query"
            key-up-function.call="searchQueryChanged() & debounce:400"
        ></cx-text-input>
        <div show.bind="width <= 576" class="mobile-search">
            <cx-filter
                options.bind="options"
                material-icon="sort"
                input-class.bind="'select-background-filled'"
                label.bind="'Show'"
                change-function.bind="handleFilterChange"
                force-default.bind="true"
                type.bind="'select'"
            ></cx-filter>
            <cx-search-drawer
                is-empty.bind="mobileSearchResponse.orders.length === 0"
                handle-search.bind="searchDrawerQueryChanged"
                search.two-way="mobileSearchParams.query"
                handle-clear.bind="searchDrawerClear"
            >
                <cx-infinite-scroll
                    load.bind="infiniteScrollLoad"
                    page.two-way="mobileSearchParams.page"
                    page-count.bind="mobilePageCount"
                >
                    <div class="d-flex flex-column gap-3 pb-100 px-regular">
                        <cx-order-row
                            repeat.for="order of mobileSearchResponse.orders"
                            order.bind="order"
                            width.bind="width"
                            click.delegate="orderClicked(order)"
                            type="Balance"
                        ></cx-order-row>
                    </div>
                </cx-infinite-scroll>
            </cx-search-drawer>
        </div>
        <cx-card show.bind="!loading" class.bind="\`orders-container \${ordersEmpty ? 'orders-container__empty' : ''}\`">
            <cx-back-button if.bind="width >= 992" route.bind="'balance'"></cx-back-button>
            <div if.bind="width > 576" class="sort-container">
                <p>\${currentCount}</p>
                <cx-filter
                    options.bind="options"
                    input-class.bind="'select-background-filled'"
                    label.bind="'Show'"
                    change-function.bind="handleFilterChange"
                    material-icon="sort"
                    force-default.bind="true"
                    type.bind="'select'"
                ></cx-filter>
            </div>
            <div if.bind="ordersEmpty" class="no-results">
                <img
                    src="${___HTML_LOADER_IMPORT_0___}"
                    loading="lazy" alt="No results"
                >
                <p>
                    \${ 'CX_NO_ENTRIES_TO_SHOW' | siteStrings}
                </p>
            </div>
            <cx-table
                if.bind="!ordersEmpty"
                table-headers.bind="tableHeaders"
            >
                <cx-order-row
                    repeat.for="order of searchResponse.orders"
                    order.bind="order"
                    width.bind="width"
                    click.delegate="orderClicked(order)"
                    type="Balance"
                ></cx-order-row>
            </cx-table>
            <cx-pagination
                if.bind="pageCount > 0 && !ordersEmpty"
                page.two-way="currentPage"
                count.bind="pageCount"
                page-changed.bind="pageChanged"
                class="mt-auto"
            ></cx-pagination>
        </cx-card>
        <cx-card if.bind="loading" class.bind="'orders-container orders-container__loading'">
            <cx-circular-progress class="spin-loader my-4"></cx-circular-progress>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;