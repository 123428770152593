import { BaseEntity } from '../baseEntity';

export class Website extends BaseEntity {
    name: string;
    shortCode: string;
}

export const WebsiteShortCode = {
    ChicksGold: 'CG',
    ChicksX: 'CX',
    DivicaSales: 'DS',
    GamerTotal: 'GT',
    AribaGold: 'AG',
    AccountKings: 'AK',
    AdminPanel: 'AP'
};
