import './referrals.scss';
import { observable, bindable, autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { WebsiteService } from 'services/website-service';
import { apiEndpoint } from 'environment';
import { baseUrl } from 'environment';
import { ReferralCodeService } from 'services/referral-code-service';
import { SubscriptionService } from 'services/subscription-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { ReferralCodeStats } from 'services/models/referrals/referralCodeStats';
import { User } from 'services/models/user/user';
import { ReferralConfigurationService } from 'services/referral-configuration-service';
import { DecimalToPercentageValueConverter } from 'resources/value-converters/decimal-to-percentage';
import { generateReferralsQRCodeImage, debounce } from '@chicksgroup/helpers';
import { ToastService } from 'services/toast-service';

type SOCIAL_MEDIA_PLATFORMS = 'facebook' | 'x' | 'whatsapp' | 'telegram' | 'linkedin' | 'reddit' | 'pinterest' | 'email';

@autoinject()
export class Referrals {
    referralCodes: string[] = [];
    @observable width: number;
    referrals;
    user: User;
    temporalReferralCode: string;
    referralURL: string;
    URLPlaceholder = 'chicksx.com/r/';
    environment = apiEndpoint();
    filteredReferrals;

    Steps = {
        Dashboard: 0,
        Earnings: 1,
        Manage: 2
    };

    @bindable globalPageStep = this.Steps.Dashboard;

    faqPageRoute;
    referralCodeReferralStats: ReferralCodeStats;
    private isAttached: boolean;
    stepData;
    benefits;
    sort;
    search;
    referralsCount: number;
    referralCodeInput;
    sortByOptions = ['Latest', 'Oldest', 'Low to high', 'High to low'];
    optionSelected = this.sortByOptions[0];
    enableReferralsTesting = false;
    editedUrl = false;
    count = 1;
    perPage: number;
    @observable page = 1;
    referralCodeResponse;
    isReferralCodeValid: boolean;
    userSubscription;
    sizeChanged;
    deviceView;
    desktop: number = 992;
    linkEditMode;
    inputContent;
    linkCopied;
    matcher;
    regularSize = 24;
    referralConfiguration;
    formattedReferralConfiguration;
    customerPortalPage;
    totalEarnings: number;

    socialMediaCaption = 'Join ChicksX. Buy, sell, exchange bitcoin, crypto or fiat instantly in any major city around the globe!';
    socialIconBasePath = '/customer-portal';
    socialMediaOptions: Map<SOCIAL_MEDIA_PLATFORMS, string> = new Map([
        ['facebook', `${this.socialIconBasePath}/facebook.svg`],
        ['x', `${this.socialIconBasePath}/x.svg`],
        ['whatsapp', `${this.socialIconBasePath}/ws.svg`],
        ['telegram', `${this.socialIconBasePath}/telegram.svg`],
        ['linkedin', `${this.socialIconBasePath}/linkedin.svg`],
        ['reddit', `${this.socialIconBasePath}/reddit.svg`],
        ['pinterest', `${this.socialIconBasePath}/pinterest.svg`],
        ['email', `${this.socialIconBasePath}/mail.svg`]
    ]);

    iterableSocialMediaOptions = Array.from(this.socialMediaOptions.keys());

    menu;
    lineMenu;
    editTextField;
    activeCode: string ;
    originalInputContent: string;
    inputState: string = 'normal';
    codeInputContent: string;
    verticalOffset: number = 0;
    sharePopUp: boolean = false;
    dialog: HTMLElement | null;
    mdDialog: HTMLElement | null;
    mdDialogHandler: (event: CustomEvent) => void;
    dialogDeepRoot: ShadowRoot;

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private websiteService: WebsiteService,
        private referralCodeService: ReferralCodeService,
        private subscriptionService: SubscriptionService,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService,
        private helper: Helper,
        private referralConfigurationService: ReferralConfigurationService,
        private decimalToPercentageValueConverter: DecimalToPercentageValueConverter,
        private toastService: ToastService
    ) {}

    async activate() {
        this.user = await this.sessionService.refreshProfile();
        this.getActiveSubscription();
        this.filteredReferrals = this.referrals;
        this.globalPageStepChanged();
        this.customerPortalPage = await this.websiteService.getPage('Customer Portal');
        await this.pageContentAreaService.getByPageId(this.customerPortalPage?.id);
        await this.fetchOrRefreshReferralCodes();
        if (!this.referralCodes?.length) {
            this.referralCodes[0] = await this.referralCodeService.checkAndSetFirstReferralCode(this.user.id);
        }
    }

    async attached() {
        this.referralConfiguration = await this.referralConfigurationService.getReferralsConfiguration(this.user.id);
        this.formattedReferralConfiguration = this.formatReferralsConfiguration(this.referralConfiguration);
        this.width = window.innerWidth;
        this.user.isSubscribed = this.subscriptionService.hasSubscription(this.user);
        this.enableReferralsTesting = this.helper.includesSome(window.location.href, ['localhost', 'dev.chicksx']);
        this.faqPageRoute = `${await this.websiteService.getRouteOrDefault('FAQ')}?search=Referral`;

        const storedCode = this.sessionService.getActiveReferralCode();
        if (storedCode && this.referralCodes.includes(storedCode)) {
            this.activeCode = storedCode;
        } else if (this.referralCodes.length > 0) {
            this.setActiveReferralCode(this.referralCodes[0]);
        }

        this.buildReferralUrl();
        this.handleInputContent();
        this.isAttached = true;
        await this.pageChanged();
        this.filteredReferrals = this.referrals?.relations;
        this.handleEventSubscriptions();
        this.linkEditMode = false;
        this.applyManualStyleOverrides();
        await this.fetchOrRefreshCodeStats();

        this.stepData = {
            [this.Steps.Earnings]: {
                title: 'Total earnings',
                route: 'earnings'
            },
            [this.Steps.Manage]: {
                title: 'Manage Referrals',
                description: 'Get your link and start saving',
                route: 'manage-referrals'
            }
        };

        this.benefits = await Promise.all(
            this.helper.range(3).map(async x => ({
                icon: await this.pageContentAreaService.getImageAltByKey(`REFERRAL_BENEFIT_BOX_${x + 1}`)
            }))
        );

        this.mdDialog = this.dialog.shadowRoot.querySelector('md-dialog');
        if (this.mdDialog) {
            this.mdDialog.addEventListener('closed', () => {
                this.sharePopUp = false;
            });
        }

        this.dialogDeepRoot = this.dialog.shadowRoot.querySelector('md-dialog').shadowRoot;
        (this.dialogDeepRoot.querySelector('.has-actions')?.querySelector('.container')?.querySelector('.actions') as HTMLElement).style.display = 'none';
    }

    handleReferralsNavigation = (path: number) => this.globalPageStep = path;

    buildReferralUrl() {
        let url = '';
        url = `${this.getPrefix()}${this.activeCode}`;
        const cleanUrl = url.replace('https://', '');
        this.referralURL = cleanUrl;
    }

    getPrefix = () => this.enableReferralsTesting
        ? this.URLPlaceholder
        : `${baseUrl()}r/`;

    hasInvalidCharacters = (code: string) => !/^[^ /<>#%{}|\\^~[\]?]+$/.test(code);

    referralCodeValid(code: string) {
        const isValid = this.helper.isInRange(code.length, 1, 20) && !code.isProfane() && code.hasLetters() && !this.hasInvalidCharacters(code);
        const errorMsg = 'The custom referral link entered is either invalid or already exists. Please try again.';
        return this.helper.validateCondition(isValid, errorMsg);
    }

    async saveUpdatedReferralCode(code: string) {
        this.isReferralCodeValid = this.referralCodeValid(code);

        if (this.isReferralCodeValid) {
            this.referralCodeResponse = await this.referralCodeService.createReferralCode(code, this.user.id);

            if (this.referralCodeResponse) {
                this.setActiveReferralCode(code);
                this.buildReferralUrl();
                this.inputState = 'valid';
                this.editedUrl = false;
                this.toastService.showToast('Success!', 'Your referral code has been created.', 'success');
                this.menu.toggleMenu();
                debounce(() => this.inputState = 'normal', 2000)();
                this.switchToLinkDefaultMode();
                await this.fetchOrRefreshReferralCodes();
                await this.fetchOrRefreshCodeStats();
                this.applyManualStyleOverrides();
                return;
            }

            this.inputState = 'invalid';
            debounce(() => this.inputState = 'normal', 2000)();
        }
    }

    copyCodeToClipboard() {
        navigator.clipboard.writeText(this.referralURL);
        this.linkCopied = true;
        this.helper.reEnableField(this, 'linkCopied', 'copiedStateTimeOut', 1500);
        this.toastService.showToast('Success!', 'Referral code copied to clipboard', 'success');
    }

    handleSortUpdate = async(event) => {
        this.optionSelected = event?.detail?.value;
        this.sort = this.helper.camelize(this.optionSelected);
        await this.pageChanged();
        this.sort = this.optionSelected;
    };

    async searchChanged() {
        const searchTerm = this.search.toLowerCase();
        this.filteredReferrals = this.referrals.relations.filter((referral) => {
            return [referral.firstName?.toLowerCase(), referral.lastName?.toLowerCase(), referral.email.toLowerCase(), String(referral.referralEarnings), String(referral.totalSpendByUser), String(referral.activity)]
                .some(element => new RegExp(searchTerm, 'i').test(element));
        });
    }

    async clearSearch() {
        this.search = '';
        this.searchChanged();
    }

    @computedFrom('referralURL')
    get referralURIComponent() {
        return encodeURIComponent(this.referralURL);
    }

    shareOnSocialMedia(platform: SOCIAL_MEDIA_PLATFORMS) {
        let shareUrl = '';

        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer.php?s=100&p[url]=${this.referralURIComponent}`;
                break;
            case 'x':
                shareUrl = `https://x.com/intent/tweet?text=${this.socialMediaCaption}&url=${this.referralURIComponent}`;
                break;
            case 'whatsapp':
                shareUrl = `https://wa.me/?text=${this.socialMediaCaption}%20${this.referralURIComponent}`;
                break;
            case 'telegram':
                shareUrl = `https://t.me/share/url?url=${this.referralURIComponent}&text=${this.socialMediaCaption}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${this.referralURIComponent}`;
                break;
            case 'reddit':
                shareUrl = `https://reddit.com/submit?url=${this.referralURIComponent}&title=${this.socialMediaCaption}`;
                break;
            case 'pinterest':
                shareUrl = `https://pinterest.com/pin/create/button/?url=${this.referralURIComponent}&description=${this.socialMediaCaption}`;
                break;
            case 'email':
                shareUrl = `mailto:?subject=${this.socialMediaCaption}&body=${this.referralURIComponent}`;
                break;
        }

        window.open(shareUrl, '_blank');
    }

    redirectToFaq = () => this.router.navigate(`/${this.faqPageRoute}`);

    toggleSharePopUp = () => {
        this.sharePopUp = !this.sharePopUp;
        this.applyManualStyleOverrides();
    };

    handleDialogClosed(event: CustomEvent) {
        this.sharePopUp = event.detail.open;
    }

    redirectToProfile = () => this.router.navigateToRoute('profile');

    async pageChanged() {
        if (!this.isAttached) {
            return;
        }

        this.referrals = await this.referralCodeService.filterRelations(
            this.page,
            this.perPage,
            this.sort
        );
        this.filteredReferrals = this.referrals.relations;
        this.referralsCount = this.referrals.totalCount;
        this.count = Math.ceil(this.referralsCount / this.perPage);
        if (this.page > this.count && this.count > 0) {
            this.page = this.count;
        }
    }

    async getActiveSubscription() {
        this.userSubscription = await this.subscriptionService.getActiveSubscription(this.user);
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async(payload) => {
            this.width = payload.width;
            this.helper.reEnableField(this, 'loadingSizeChange', 'sizeChangedTimeout', 500, async() =>
                await this.pageChanged()
            );
        });
    }

    detached() {
        this.sizeChanged?.dispose();

        if (this.mdDialog) {
            this.mdDialog.removeEventListener('closed', this.mdDialogHandler);
        }
    }

    switchToLinkEditMode() {
        this.linkEditMode = true;
        const innerInput = this.editTextField.shadowRoot.querySelector('md-filled-text-field');
        this.codeInputContent = '';
        this.temporalReferralCode = '';
        this.handleInputContent();
        debounce(() => innerInput.focus(), 50)();
    }

    switchToLinkDefaultMode() {
        if (this.inputState === 'invalid') {
            this.inputState = 'normal';
        }

        this.linkEditMode = false;
        this.handleInputContent();
    }

    async codeUpdatedOnKeyPress() {
        this.inputState = 'loading';
        this.saveReferralCodeDebounced(this.codeInputContent);
    }

    handleInputContent() {
        this.inputContent = this.linkEditMode ? this.temporalReferralCode : this.referralURL;
    }

    widthChanged() {
        this.deviceView = this.width <= this.desktop;
        this.perPage = this.deviceView ? 6 : 15;
    }

    globalPageStepChanged() {
        const steps = Object.keys(this.Steps);
        const currentStep = steps.find(x => this.Steps[x] === this.globalPageStep);
        steps.forEach(x => this[`is${x}`] = x === currentStep);
    }

    formatReferralsConfiguration(referralsConfiguration) {
        return {
            ...referralsConfiguration,
            referrerFee: this.decimalToPercentageValueConverter.toView(referralsConfiguration.referrerFee)
        };
    }

    /**
     * Handles the generation of a QR code for referral links and copies it to the clipboard.
     *
     * The function generates a QR code image for the referral URL and copies the image
     * to the clipboard. It also shows a success toast if the operation is successful,
     * and logs any errors that occur during the process.
     *
     * @returns {Promise<void>} A promise that resolves when the QR code is generated and copied to the clipboard.
     */
    async handleQRCodeGeneration(): Promise<void> {
        try {
            const blob = await generateReferralsQRCodeImage(this.referralURL, this.formattedReferralConfiguration, 'ChicksX');

            if (!blob) {
                throw new Error('QR code generation failed, no image blob returned.');
            }

            const item = new ClipboardItem({ 'image/png': blob });
            await navigator.clipboard.write([item]);
            this.toastService.showToast('QR code copied to clipboard', 'Success!', 'success');
        } catch (err) {
            console.error('Failed to copy QR code to clipboard:', err);
            this.toastService.showToast('Failed to copy QR code to clipboard', 'Error', 'error');
        }
    }

    /**
     * Selects the active referral code and refreshes the related data.
     * Updates the referral URL and fetches the stats for the selected code.
     *
     * @param {string} code - The referral code to select as active.
     * @returns {Promise<void>}
     */
    async selectActiveCode(code: string, menu: string): Promise<void> {
        try {
            this.setActiveReferralCode(code);
            this.buildReferralUrl();
            await this.fetchOrRefreshCodeStats();
            this.inputContent = this.referralURL;
            menu === 'line' ? this.lineMenu.toggleMenu() : this.menu.toggleMenu();
        } catch (error) {
            console.error('Error selecting active referral code:', error);
            this.toastService.showToast('Error!', 'Unable to select referral code.', 'error');
        }
    }

    /**
     * Deletes the selected referral code and refreshes the referral list.
     * If the deletion is successful, the first referral code in the updated list is set as active.
     *
     * @param {string} code - The referral code to delete.
     * @returns {Promise<void>}
     */
    async deleteSelectedCode(code: string): Promise<void> {
        if (this.referralCodes.length === 1) {
            this.toastService.showToast('Error!', 'Your last unique code cannot be deleted.', 'error');
            return;
        }

        const response = await this.referralCodeService.deleteReferralCode(code, this.user.id);

        if (response) {
            this.toastService.showToast('Success!', `Your referral code ${code} has been deleted.`, 'success');
            await this.fetchOrRefreshReferralCodes();
            this.setActiveReferralCode(this.referralCodes[0]);
            await this.fetchOrRefreshCodeStats();
        } else {
            this.toastService.showToast('Error!', 'An error occurred while deleting your referral code.', 'error');
        }
    }

    /**
     * Toggles the menu state with a debounce to ensure it's not triggered too frequently.
     * It checks whether the menu is open or closed and calls the appropriate functions.
     */
    toggledMenu() {
        const debouncedToggle = debounce(() => {
            if (this.menu?.open) {
                this.menuOpened();
            } else {
                this.menuClosed();
            }
        }, 50);

        debouncedToggle();
    }

    /**
     * Handles logic when the menu is opened.
     * Stores the current input content in `originalInputContent` and clears the input field.
     */
    menuOpened() {
        this.originalInputContent = this.inputContent || '';
        this.inputContent = '';
    }

    /**
     * Handles logic when the menu is closed.
     * Restores the input content if no input was provided while the menu was open.
     */
    menuClosed() {
        if (this.inputContent === '') {
            this.inputContent = this.originalInputContent;
        }
    }

    /**
     * Handles focus out events in edit mode.
     * If no input was provided, it resets the relevant properties and restores the original content.
     */
    handleEditModeFocusOut() {
        if (this.inputContent === '') {
            this.codeInputContent = '';
            this.linkEditMode = false;
            this.inputContent = this.originalInputContent;
        }
    }

    /**
     * Computed property that returns filtered referral codes based on the inputContent.
     * Only those codes that contain the inputContent will be included.
    */
    @computedFrom('inputContent', 'referralCodes')
    get filteredReferralCodes() {
        const searchTerm = this.inputContent?.toLowerCase();
        return this.referralCodes.filter(code => code?.toLowerCase().includes(searchTerm));
    }

    /**
     * Debounced function to save the updated referral code.
     *
     * This function delays the execution of saving the referral code by 2000ms.
     * If the user types continuously, the saving operation will only happen after
     * the user stops typing for the specified delay.
     *
     * @param {string} codeInputContent - The content of the referral code to be saved.
     * @returns {void} The debounced function does not return a value directly.
     */
    saveReferralCodeDebounced = debounce(async (codeInputContent: string) => {
        await this.saveUpdatedReferralCode(codeInputContent);
    }, 2000);

    /**
     * Fetches or refreshes the referral codes for the current user.
     * @async
     * @returns {Promise<void>} A promise that resolves when the referral codes are fetched.
     */
    async fetchOrRefreshReferralCodes() {
        this.referralCodes = await this.referralCodeService.getReferralCodesByUserId(this.user.id);
    }

    /**
     * Applies manual style overrides to the menu component.
     */
    applyManualStyleOverrides() {
        this.verticalOffset = (-52 * this.referralCodes.length) - 28;
        if (this.dialogDeepRoot) {
            (this.dialogDeepRoot.querySelector('.scrim') as HTMLElement).style.zIndex = '10000';
            this.dialogDeepRoot.querySelector('dialog').classList.remove('has-actions');
            this.dialogDeepRoot.querySelector('dialog').classList.add('dialog-mobile');
            (this.dialog.shadowRoot.querySelector('md-dialog').querySelector('div[slot="headline"]') as HTMLElement).style.padding = '20px 0 0';
        }
    }

    /**
     * Sets the active referral code and saves it to the session.
     * @param {string} code - The referral code to set as active.
     */
    setActiveReferralCode(code: string) {
        this.activeCode = code;
        this.sessionService.saveActiveReferralCode(code); // Save the selected code to localStorage
        this.applyManualStyleOverrides();
    }

    /**
     * Fetches or refreshes the referral code statistics for the active referral code.
     * Updates the `referralCodeReferralStats` and `totalEarnings` properties.
     *
     * @async
     * @returns {Promise<void>} A promise that resolves when the referral code statistics are fetched.
     */
    async fetchOrRefreshCodeStats() {
        this.referralCodeReferralStats = await this.referralCodeService.getReferralCodeStatsByCode(this.activeCode);
        this.totalEarnings = this.referralCodeReferralStats?.totalEarnings || 0.001;
    }

    get lastMonth() {
        return this.referralCodeReferralStats?.totalReferralsPercentageComparedToLastMonth;
    }

    get totalUsers() {
        return this.referralCodeReferralStats?.totalReferrals;
    }

    get totalSpent() {
        return this.referralCodeReferralStats?.totalAmountSpentByUsers;
    }
}
