// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-logo-purple-all-white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/chicks/chicks_auth_background.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/chicks/work_in_space_chick.svg", import.meta.url);
// Module
var code = `<template>
    <div id="auth">
        <div class="logo-container">
            <a href="\${homePageRoute}">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="chicksx-logo img-fluid logo-position" width="170" alt="logo" loading="lazy">
            </a>
        </div>
        <div class="row position-relative auth-container">            
            <div class="logo-col p-0">
                <a href="\${homePageRoute}">
                    <img src="${___HTML_LOADER_IMPORT_0___}" class="desktop-logo img-fluid" width="170" alt="logo" loading="lazy">
                </a>
            </div>
            <div class="txt-col form-position position-relative">
                <a
                    if.bind="['sign-up', 'sign-in/reset-password', 'r/:referralLink'].includes(state)"
                    class="return text-start \${isRequesting ? 'pe-none' : ''} d-inline-flex align-items-center position-absolute"
                    click.delegate="backToSignInPage()"
                    href.bind="backRoute"
                >
                    <span class="material-icons back-button me-1 rounded-circle">
                        chevron_left
                    </span>
                    Back
                </a>
                <cx-auth-form params.bind="params"></cx-auth-form>
            </div>
            <div if.bind="state === 'sign-in'" class="img-col position-relative">
                <img src="${___HTML_LOADER_IMPORT_1___}" class="img-fluid form-img position-absolute top-0" alt="Lost in space" loading="lazy">
            </div>
            <div else class="img-col position-relative">
                <img src="${___HTML_LOADER_IMPORT_2___}" class="img-fluid form-img position-absolute top-0" alt="Work in space" loading="lazy">
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;