// Module
var code = `<template>
    <div id="cx-credit-card-form"> 
        <div class="w-100 d-flex flex-column">
            <div class="buttons-container d-flex justify-content-between" show.bind="isAddingStage && !requestOnlyCvv">
                <span class="text-small">Add new card</span>
            </div>
            <div if.bind="cardInfo.type || requestOnlyCvv" class="d-flex flex-row align-items-center mb-2">
                <div class="payment-method-img justify-content-center text-center d-inline-flex align-items-center">
                    <img if.bind="cardInfo.type" src="\${handleCardImage(cardInfo.type)}" alt="payment method" loading="lazy">
                    <span else class="material-icons-outlined">image_not_supported</span>
                </div>
                <div class="col ms-3">
                    <div class="d-flex flex-row align-items-center">
                        <div class="col text-regular text-black align-self-center text-truncate">
                            \${handleCardName(cardInfo.type)}
                            <span class="opacity-70"> **** \${cardInfo.lastDigits}</span>
                        </div>
                    </div>
                </div>
            </div>
            <cx-credit-card-input
                card-number.two-way="cardNumber"
                card-month-year.two-way="cardMonthYear"
                card-cvv.two-way="cardCvv"
                card-type.two-way="cardType"
                card-info.two-way="cardInfo"
                is-exp-date-valid.from-view="isExpDateValid"
                is-cvv-valid.from-view="isCvvValid"
                is-cc-valid.from-view="isCcValid"
                user.bind="user"
                name-on-card.bind="nameOnCard"
                request-only-cvv.bind="requestOnlyCvv"
                class="input-card-number"
                show.bind="!showFieldsAddress"
                ignore-route.bind="ignoreRoute"
            ></cx-credit-card-input>
            <cx-billing-address
                ref="billingAddressEl"
                loading.bind="loading"
                countries.bind="countries"
                states.bind="states"
                billing.two-way="billing"
                user.bind="user"
                show-previous-billing.bind="!isAddingStage"
                billing-address-is-valid.two-way="billingAddressIsValid"
                show.bind="!requestOnlyCvv"
                class="billing-address"
                show-fields.from-view="showFieldsAddress"
            ></cx-billing-address>
        </div> 
    </div>
</template>
`;
// Exports
export default code;