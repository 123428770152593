// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/tracking_no.svg", import.meta.url);
// Module
var code = `<template>
    <div id="tracking-number">
        <mdc-expandable class="purchase-flow-expandable" view-model.ref="cashInMailExpandable" open.two-way="expandableOpen">
            <div slot="caption">
                <div class="text-large-plus d-flex align-items-center" style.bind="{ paddingTop: trackingNumberValid && !expandableOpen ? '21px' : '0' }">
                    <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" loading="lazy" alt="tracking">
                    Tracking Number
                </div>
                <div class="d-flex align-items-center" if.bind="trackingNumberValid && !expandableOpen">
                    <span class="font-medium">Tracking Progress: 
                        <span class="tooltip-container" class.bind="trackingData ? 'text-success-green' : 'text-grey'">\${trackingData ? trackingData.status : '' | packageStatus} 
                            <div class="tooltip-detail">
                                <div class="mb-3 caption">
                                    Sending cash in mail to ChicksX Inc,1 King St W #4800, Toronto, Canada M5H 1A1. Expected arrival: 
                                    <span if.bind="trackingData">\${ trackingData.est_delivery_date | dateFormatter }</span>
                                    <span else>-</span>
                                </div>
                                <span class="caption">Tracking number: <span class="font-bold">#\${exchange.trackingNumber}</span></span>
                                <cx-tracking-progress class='w-100' num-steps.bind="6" tracking-data.bind="trackingData" is-tooltip.bind="true" ></cx-tracking-progress>
                            </div>
                        </span>
                    </span>
                </div>
            </div>
            <div show.bind="expandableOpen">
                <div if.bind="showCashInMailInfo && !trackingNumberValid" class="info-section au-animate animate-fade-in animate-fade-out">
                    <div class="d-flex justify-content-between">
                        <div class="mb-3 d-flex align-items-center help-header">
                            <span class="material-icons-outlined text-purple info_shield"><cx-page-content-area key="CX_CUSTOMER_PORTAL_CASH_IN_MAIL_ICON"></cx-page-content-area></span>
                            <span class="font-bold text-medium">
                                <cx-page-content-area key="CX_CUSTOMER_PORTAL_CASH_IN_MAIL_TITLE"></cx-page-content-area>
                            </span>
                        </div>
                        <span class="material-icons-outlined text-purple cursor-pointer" click.delegate="triggerCashInMailInfo()">close</span>
                    </div>
                    <section data-simplebar class="info-scrollbar purple-scrollbar">
                        <div class="d-flex flex-wrap text-darkergray">
                            <cx-page-content-area key="CX_CUSTOMER_PORTAL_CASH_IN_MAIL_PARAGRAPH"></cx-page-content-area>
                        </div> 
                    </section>
                </div>
                <div if.bind="!trackingNumberValid">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <span class="d-block text-darkergray mb-4">Please send exactly \${exchange.amountGifted | currencyFormat:exchange.giveSelectedCurrency.symbol:exchange.giveSelectedCurrency.type:exchange.giveSelectedCurrency.isStable} in \${exchange.giveSelectedCurrency.code} currency through the mail from anywhere in 
                            <span class="text-purple link-styling cursor-pointer position-relative" click.delegate="triggerDeliveryOptions()">
                                \${deliveryCountry.name}
                                <div id="country-selector" if.bind="showDeliveryOptions" class="country-selector position-absolute">
                                    <span class="text-darkergray">Select a cash in mail location</span>
                                    <div repeat.for="option of deliveryOptions" click.delegate="selectDeliveryCountry(option)" class="option \${option.id == 'GB' ? 'last-option' : ''} cursor-pointer mt-3">
                                        <img if.bind="option.flag" src.bind="option.flag" class="option-flag" alt="Flag" loading="lazy">
                                        <span class="text-black">\${option.name}</span>
                                    </div>
                                </div>
                            </span>
                            to 
                            <span class="font-bold">\${deliveryCountry.address}.</span>
                            To proceed, enter the tracking number found on your package.
                            <span class="text-purple link-styling cursor-pointer" click.delegate="triggerCashInMailInfo()">Learn more</span>
                            about how to secure your cash.
                        </span> 
                        <div class="wrapper-sm d-flex flex-row">
                            <div class="justify-self-end dropend" show.bind="tempFiles.length > 2 ? true : false">
                                <span class="material-icons text-medium align-text-bottom pt-2 dropup clickable-icon"
                                    click.delegate="toggleDropdown()" role="button">
                                    more_horiz
                                </span>
                                <mdc-list tabindex="0" class="dropdown-menu cx-dropdown-files-list \${ showDropdown ? 'show' : '' }" focusout.delegate="handleDropdownFocusOut()">
                                    <mdc-list-item class="pe-2 ps-1" repeat.for="file of tempFiles | spliceArray:2"
                                        value.bind="option">
                                        <div class="d-flex align-items-center ">
                                            <div class="file-name text-black cursor-default">
                                                \${ helper.sanitizeHtml(file.name, true) }
                                            </div>
                                            <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                                click.delegate="deleteItem(\$index + 2)" role="button">clear</span>
                                            <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                            </mdc-circular-progress>
                                        </div>
                                    </mdc-list-item>
                                </mdc-list>
                            </div>
                            <div class="cx-first-files-list d-flex">
                                <mdc-list class="w-100 ps-0">
                                    <mdc-list-item repeat.for="file of tempFiles | spliceArray:0:2">
                                        <div class="d-flex align-items-center ">
                                            <div class="file-name text-black cursor-default">
                                                \${ helper.sanitizeHtml(file.name, true) }
                                            </div>
                                            <span if.bind="file.isLoaded" class="text-navy-blue text-small material-icons"
                                                click.delegate="deleteItem(\$index)" role="button">clear</span>
                                            <mdc-circular-progress if.bind="!file.isLoaded" size="18" stroke-width="2">
                                            </mdc-circular-progress>
                                        </div>
                                    </mdc-list-item>
                                </mdc-list>
                            </div>
                            <div class="me-0 me-lg-2" role="button">
                                <label class="cursor-pointer" for="file-input">
                                    <span class="material-icons attach-icon clickable-icon">attach_file</span>
                                </label>
                                <input
                                    class="d-none"
                                    id="file-input"
                                    type="file"
                                    change.delegate="changeHandler(\$event)"
                                    accept="\${ inputAccept }"
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                    <div class="position-relative d-inline-block w-100">
                        <mdc-text-field
                            outlined
                            required.bind="true"
                            value.bind="exchange.trackingNumber"
                            placeholder="Enter your tracking number"
                            keyup.delegate="trackingUpdatedOnKeyPress(\$event)"
                            focusin.delegate="trackingOnFocusIn()"
                            class="input-password w-100 cx-form-control \${!exchange.trackingNumber || showMiniSpinnerTracking || !firedFunction ? '' : showGreenCheckMarkTrackingCode ? 'cx-form-control--success' : ''}"
                            ref="trackingNumberInput"
                        ></mdc-text-field>
                        <span
                            class="circle-icon check text-large material-icons-outlined"
                            alt="green check"
                            if.bind="trackingNumberValid && exchange.trackingNumber"
                        >check_circle</span>
                        <mdc-circular-progress if.bind="showMiniSpinnerTrackingCode" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    </div>
                </div>
                <div else class="d-flex align-items-center">
                    <span class="font-medium">Tracking Progress: 
                        <span class="tooltip-container" class.bind="trackingData ? 'text-success-green' : 'text-grey'">\${trackingData ? trackingData.status : '' | packageStatus} 
                            <div class="tooltip-detail">
                                <div class="mb-3 caption">
                                    Sending cash in mail to ChicksX Inc,1 King St W #4800, Toronto, Canada M5H 1A1. Expected arrival: 
                                    <span if.bind="trackingData">\${ trackingData.est_delivery_date | dateFormatter }</span>
                                    <span else>-</span>
                                </div>
                                <span class="caption">Tracking number: <span class="font-bold">#\${exchange.trackingNumber}</span></span>
                                <cx-tracking-progress class='w-100' num-steps.bind="5" tracking-data.bind="trackingData" is-tooltip.bind="true" ></cx-tracking-progress>
                            </div>
                        </span>
                    </span>
                    <span click.delegate="triggerTrackingEdit()" class="material-icons clickable-icon rounded-circle text-purple ps-2 text-large cursor-pointer"> 
                        edit 
                    </span>
                </div>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;