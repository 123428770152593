// Module
var code = `<template>
    <div id="cx-custom-title" class="w-100">
        <div class="text-large-plus font-regular">
            <div show.bind="!titleEdit" click.delegate="toggleTitleEdit()" mouseover.trigger="onMouseOver() & debounce:50"
                class="d-flex align-items-center flex-row cursor-pointer">
                <span id="title" class="me-1 text-black text-truncate" mdc-tooltip="xPosition.bind: 'CENTER'; yPosition: 'BELOW';">\${title ? title : 'CX_CHOOSE_TITLE' | siteStrings}</span>
                <cx-touch-target expanded>
                    <button>
                        <span class="material-icons clickable-icon rounded-circle text-purple p-0 \${title === '' ? 'd-inline-block' : '' }">
                            edit
                        </span>
                    </button>
                </cx-touch-target>    
            </div>
            <div mdc-menu-surface-anchor ref="titleAnchor" show.bind="titleEdit" focusout.delegate="focusHandler(\$event)">
                <div class="d-flex flex-row align-items-center">
                    <form submit.delegate="setTicketTitle(\$event)">
                        <mdc-text-field
                            class="input-ticket-title cx-form-control p-0"
                            name="ticketContent"
                            value.two-way="title"
                            outlined
                            autocomplete="off"
                            ref="inputTicketTitle"
                            id="inputTicketTitle"
                            keyup.delegate="onKeyUp(\$event)"
                            placeholder.bind="'CX_CHOOSE_TITLE' | siteStrings"
                            focusin.delegate="onFocus()"
                            spellcheck="false"
                        ></mdc-text-field>
                    </form>
                    <span class="material-icons clickable-icon rounded-circle text-purple p-0" if.bind="title && isQuestion"
                        click.delegate="toggleTitleEdit()">
                        edit
                    </span>
                </div>
                <mdc-menu
                    class="\${showSuggestions ? 'mdc-menu-surface--open' : ''} \${ isQuestion ? 'surface-question' : 'overflow-auto'}"
                    view-model.ref="titleSuggestions"
                    stay-open-on-selection.bind="true"
                    anchor.bind="titleAnchor"
                    anchor-corner.bind="'BOTTOM_START'"
                    if.bind="relevantSuggestions.length > 0">
                    <div if.bind="!isQuestion">
                        <mdc-list>
                            <mdc-list-group>
                                <h5 class="mx-0 text-black">\${'CX_MATCHING_SUGGESTIONS' | siteStrings}</h5>
                                <mdc-list-item class="px-1" click.delegate="onSuggestionSelect(suggestion.question.id)"
                                    repeat.for="suggestion of relevantSuggestions | spliceArray:0:3">
                                    \${ suggestion.question.question }
                                </mdc-list-item>
                            </mdc-list-group>
                            <mdc-list-group if.bind="relevantSuggestions.length > 3">
                                <h5 class="mx-0 text-black">\${'CX_OTHER_SUGGESTIONS' | siteStrings}</h5>
                                <mdc-list-item class="px-1" click.delegate="onSuggestionSelect(suggestion.question.id)"
                                    repeat.for="suggestion of relevantSuggestions | spliceArray:3">\${
                                    suggestion.question.question }</mdc-list-item>
                            </mdc-list-group>
                        </mdc-list>
                    </div>
                    <div if.bind="isQuestion">
                        <div class="d-flex justify-content-end ">
                            <span class="material-icons text-large close-icon text-purple cursor-pointer"
                                click.delegate="closeQuestion()">close</span>
                        </div>
                        <div class="question-container overflow-auto">
                            <div>
                                <h3 class="question-title p-0 text-regular font-medium text-black">
                                    \${ questionSelected.question }
                                </h3>
                                <div class="question-answer p-0 text-small font-regular text-darkergray">
                                    \${ questionSelected.answer }
                                </div>
                            </div>
                        </div>
                    </div>
                </mdc-menu>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;