import './cx-timer.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxTimer {
    @bindable timeLimit = 20;
    @bindable text: string;
    @bindable timeLeft = 20;
    @bindable autoRestart = false;
    @bindable reverseRow = false;
    baseTimerPathRemainingRef: SVGPathElement;
    timePassed = 0;
    timerInterval = null;
    timeCurrent;
    FULL_DASH_ARRAY = 282.743;
    parent;
    isAttached = false;
    id: string;

    constructor() {
        this.id = Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    attached() {
        this.isAttached = true;

        if (!this.timeLeft) {
            this.timeCurrent = this.formatTimeLeft(this.timeLimit);
        } else {
            this.timePassed = this.timeLimit - this.timeLeft;
            this.timeCurrent = this.formatTimeLeft(this.timeLeft);
            this.setCircleDasharray();
        }

        this.startTimer();
    }

    detached() {
        this.isAttached = false;
        this.stop();
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    formatTimeLeft(time) {
        let seconds = (time % this.timeLimit);
        if (seconds === 0) {
            seconds = this.timeLimit;
        }
        return (seconds < 10) ? `0${seconds}` : `${seconds}`;
    }

    startTimer() {
        this.timeCurrent = this.timeLimit;
        this.timerInterval = setInterval(() => {
            this.timePassed += 1;
            this.timeLeft = this.timeLimit - this.timePassed;
            this.timeCurrent = this.formatTimeLeft(this.timeLeft);

            if (this.timePassed === this.timeLimit && this.isAttached) {
                this.baseTimerPathRemainingRef.style.transition = '0s linear all';
            } else {
                this.baseTimerPathRemainingRef.style.transition = '1s linear all';
            }

            if (this.timeLeft === 0) {
                this.resetCountdown();
                this.timeCurrent = this.timeLimit;
            }

            this.setCircleDasharray();
        }, 1000);
    }

    calculateTimeFraction() {
        const rawTimeFraction = this.timeLeft / this.timeLimit;
        return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    }

    setCircleDasharray() {
        const circleDasharray = `${(this.calculateTimeFraction() * this.FULL_DASH_ARRAY).toFixed(3)} ${this.FULL_DASH_ARRAY}`;

        if (this.isAttached) {
            this.baseTimerPathRemainingRef.setAttribute('stroke-dasharray', circleDasharray);
        }
    }

    stop() {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
    }

    resetCountdown() {
        this.parent.countdownFinished();

        if (this.isAttached) {
            if (this.autoRestart) {
                this.timePassed = 0;
                this.timeLeft = this.timeLimit;
                clearInterval(this.timerInterval);
                this.startTimer();
            } else {
                clearInterval(this.timerInterval);
            }
            this.timeCurrent = this.formatTimeLeft(this.timeLeft);
            this.baseTimerPathRemainingRef.style.transition = '0s linear all';
            this.setCircleDasharray();
        }
    }
}
