export interface IGlobalDropdownOptions {
    options: string[];
    optionSelected: (option: string) => void;
    onOpen?: () => void;
    onClose?: () => void;
    style: 'primary' | 'secondary';
    height?: number;
}

export class GlobalDropdown {
    container: HTMLDivElement;
    options: IGlobalDropdownOptions = {
        options: [],
        optionSelected: () => { },
        style: 'primary'
    };

    constructor() {
        this.container = document.getElementById('global-dropdown') as HTMLDivElement;
        this.container.style.zIndex = '9999';
        this.container.style.display = 'none';
    }

    attachToElement(element: HTMLElement, options?: IGlobalDropdownOptions) {
        element.addEventListener('focusin', () => {
            this.container.style.display = 'block';
            this.container.style.top = `${element.offsetTop + element.offsetHeight}px`;
            this.container.style.left = `${element.offsetLeft}px`;
        });

        element.addEventListener('focusout', () => {
            this.container.style.display = 'none';
        });

        if (!options) return;
        this.options = options;
        this.setDropdownOptions(this.options);
    }

    open(elementPosition?: HTMLElement) {
        this.container.style.display = 'block';

        if (elementPosition) {
            const rect = elementPosition.getBoundingClientRect();
            this.container.style.top = `${rect.top + rect.height + 3}px`;
            this.container.style.left = `${rect.left}px`;
            this.container.style.width = `${rect.width}px`;
        }

        this.options?.onOpen?.();
    }

    close() {
        this.container.style.display = 'none';
        this.options?.onClose?.();
    }

    setDropdownOptions(options: IGlobalDropdownOptions) {
        const dataContainer = this.container.querySelector('.simplebar-content');
        this.container.classList.remove(`${this.options.style}-dropdown`);
        dataContainer.innerHTML = '';
        this.options = options;
        this.container.classList.add(`${this.options.style}-dropdown`);

        if (this.options.height) this.container.style.setProperty('--dropdown-height', `${this.options.height}px`);

        options.options.forEach(option => {
            const optionElement = document.createElement('div');
            optionElement.classList.add('dropdown-option');
            optionElement.innerText = option;
            optionElement.addEventListener('click', () => {
                options.optionSelected(option);
                this.container.style.display = 'none';
            });
            dataContainer.appendChild(optionElement);
        });
    }
}
