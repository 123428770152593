import './security-reset-password.scss';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { validateTrigger, ValidationController } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { ToastService } from 'services/toast-service';
import { Helper } from 'resources/helpers/helper';
import { User, WithdrawPasswordVerificationResponse } from 'services/models/user/user';
import { CxPasswordInput } from 'resources/elements/cx-password-input/cx-password-input';
import { VeriffVerificationEvent } from 'resources/constants';
import { IVeriffVerificationEvent } from 'types/events';
import { ToastType } from 'services/models/toast';
import { CxVeriff } from 'resources/elements/cx-veriff/cx-veriff';
import { PageContentAreaService } from 'services/page-content-area-service';
import { clientId, websiteShortCode } from 'environment';

@autoinject()
export class SecurityResetPassword {

    passwordInputs: CxPasswordInput[];
    user: User = null;
    confirmInput: CxPasswordInput;
    newInput: CxPasswordInput;
    currentInput: CxPasswordInput;

    confirming = false;
    title: string;
    titleKey: string;
    description: string;
    descriptionKey: string;
    isWithdraw: boolean;
    isRequesting: boolean;
    successFunction: boolean;
    currentPassword: string | undefined;
    newPassword: string | undefined;
    confirmNewPassword: string | undefined;
    progressBarsElements: NodeListOf<HTMLElement>;

    showGreenCheckMarkNewPassword: boolean;
    showGreenCheckMarkConfirmNewPassword: boolean;
    firedUpdatePasswordError: boolean;
    hasWithdrawPassword: boolean;
    veriffSubscriber: Subscription;
    veriffViewModel: CxVeriff;
    params;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    savedWithdrawPassword: string;

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private sessionService: SessionService,
        private validator: ValidationController,
        private toastService: ToastService,
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService
    ) {
        this.validator = validator;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    async activate(params, config, navigationInstruction) {
        this.params = params;
        this.isWithdraw = Boolean(config.settings?.isWithdraw);
        this.title = config.title;
        this.titleKey = config.titleKey;
        this.description = config.settings?.description;
        this.descriptionKey = config.settings?.descriptionKey;
        this.user = await this.sessionService.refreshProfile();
        if (!this.user) {
            this.helper.redirectToSignIn(navigationInstruction, this.router);
            return;
        }
        this.hasWithdrawPassword = await this.customerService.hasWithdrawPassword();
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('security-reset-password');
            this.handleEventSubscriptions();
            this.passwordInputs = [this.confirmInput, this.newInput, this.currentInput];
            this.progressBarsElements = document.querySelectorAll('.progress-bar-animated');
            if (this.params?.resetWithdraw) {
                await this.withdrawPasswordResetHandler();
            }
        } finally {
            this.helper.validateLoading('security-reset-password');
        }
    }

    detached() {
        this.veriffSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.veriffSubscriber = this.eventAggregator.subscribe(VeriffVerificationEvent, async (payload: IVeriffVerificationEvent) => {
            if (!payload.user.idVerified) return;
            const response = await this.customerService.resetWithdrawPasswordByEmail(this.user.email.trim(), this.params.token);
            if (response) {
                await this.handleWithdrawReset();
                return;
            }
            this.toastService.showToast(undefined, 'The token provided is wrong or expired.', ToastType.ERROR);
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    updatePassword = async () => {
        this.isRequesting = true;
        if (this.newPassword === this.confirmNewPassword) {
            try {
                const hasFailedPasswordAttempts = this.isWithdraw && await this.customerService.hasFailedPasswordAttempts();
                const response = await this.onValidPasswords();
                if (response) {
                    this.progressBarsElements.forEach((el) => el.style.transition = 'none');
                    this.successFunction = true;
                    this.hasWithdrawPassword = this.hasWithdrawPassword || this.isWithdraw;
                    this.helper.checkPreviousAttempts(hasFailedPasswordAttempts, 'Your password has been set successfully.', 'reset');
                    this.isRequesting = this.confirming = false;
                    this.router.navigate('security');
                    return;
                }
                this.passwordInputs.forEach(x => x.showMiniSpinner = false);
                this.successFunction = this.isRequesting = false;
                this.currentInput.makeError();
                this.showGreenCheckMarkNewPassword = this.showGreenCheckMarkConfirmNewPassword = true;
                this.firedUpdatePasswordError = true;
                this.toastService.showToast(undefined, 'Error updating password. Please check that the current password is correct, then try again or contact live chat for assistance.', ToastType.ERROR);
            } catch (e) {
                console.log(e);
            } finally {
                setTimeout(() => this.progressBarsElements.forEach((el) => el.style.transition = null), 1000);
            }
            return;
        }

        this.successFunction = this.isRequesting = false;
        this.confirmInput.makeError();
        this.toastService.showToast(undefined, 'Passwords do not match.', ToastType.ERROR);
    };

    checkGreenMarks = (name: string) => {
        this.currentInput.showGreenCheckMark = this.currentInput.showGreenCheckMark || this.shouldCreate;
        return this.passwordInputs.filter(x => x.name !== name && x.showGreenCheckMark).length === this.passwordInputs.length - 1;
    };

    checkUnvalid = (inputs = null) => [...(inputs ?? [this.currentInput, this.newInput, this.confirmInput])].some(x => !x.password || x.showErrorCheckMark);

    resetInputs = () => this.passwordInputs?.forEach(x => x.resetVariables());

    async onValidPasswords() {
        if (this.hasWithdrawPassword) {
            const response = await this.customerService.updatePassword(this.currentPassword, this.newPassword, this.isWithdraw);
            if (response?.token) this.sessionService.saveToken(response.token);
            return response?.successful;
        }
        return await this.customerService.createWithdrawPassword(this.newPassword, this.savedWithdrawPassword);
    }

    setConfirming = (val = false) => {
        if (val) this.confirmInput.resetVariables();
        this.confirming = val;
    };

    showGreenChecker = (rules) => rules.valid && (this.successFunction || this.checkUnvalid([this.newInput, this.confirmInput]));

    async verifyWithdrawPassword(password: string) {
        this.savedWithdrawPassword = password;
        const isCurrentWithdrawPassword = await this.customerService.verifyWithdrawPassword(password);
        return {
            isValid: isCurrentWithdrawPassword === WithdrawPasswordVerificationResponse.Success,
            avoidNotification: true
        };
    }

    async triggerWithdrawEmail() {
        const response = await this.customerService.sendWithdrawEmail();
        if (!response) return;
        this.toastService.showToast(undefined, `Instructions to reset you password have been sent to ${this.user.email}`, ToastType.INFO);
    }

    async sendEmailReset() {
        try {
            const response = await this.customerService.requestPasswordReset({
                email: this.user.email,
                websiteShortCode: websiteShortCode(),
                redirectUrl: location.href,
                clientId: clientId()
            });

            if (!response) {
                return;
            }

            this.toastService.showToast(undefined, 'A password reset email has been sent.', ToastType.SUCCESS);
        } catch (e) {
            console.log(e);
        }
    }

    async handleWithdrawReset() {
        this.hasWithdrawPassword = await this.customerService.hasWithdrawPassword();
        this.toastService.showToast(undefined, 'You have reset your Withdraw Password. Please create a new one.', ToastType.SUCCESS);
    }

    async withdrawPasswordResetHandler() {
        if (!this.params.token) return;
        const response = await this.customerService.verifyWithdrawEmail(this.user.email.trim(), this.params.token);
        if (response) {
            if (this.params.openVeriff) {
                this.user = response;
                await this.veriffViewModel?.startVerification();
                return;
            }
            await this.handleWithdrawReset();
            return;
        }
        this.toastService.showToast(undefined, 'The token provided is wrong or expired.', ToastType.ERROR);
    }

    forgotPasswordHandler() {
        if (this.isWithdraw) this.triggerWithdrawEmail();
        else this.sendEmailReset();
    }

    @computedFrom('isWithdraw', 'hasWithdrawPassword')
    get shouldCreate() {
        return this.isWithdraw && !this.hasWithdrawPassword;
    }
}
