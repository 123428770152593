import './cx-currency-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DefaultCurrencyValueConverter } from 'resources/value-converters/default-currency';
import { getAWSBucketEndpoint } from 'environment';
import { Currency, PaymentMethodWebsite } from 'services/models/purchase-flow/exchange';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { CurrencyService } from 'services/currency-service';
import { ToastService } from 'services/toast-service';
import { IForceCurrencyEvent } from '../../../types/events';
import { Language } from 'services/models/language/language';
import { CxLanguageSlider } from '../cx-language-slider/cx-language-slider';
import { LanguageService } from 'services/language-service';
import { LanguageChangedEvent, CurrencyChangedEvent, ForceCurrencyEvent } from 'resources/constants';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxCurrencyDropdown {
    constructor(
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private defaultCurrencyValueConverter: DefaultCurrencyValueConverter,
        private currencyService: CurrencyService,
        private toastService: ToastService,
        private cxLanguageSlider: CxLanguageSlider,
        private languageService: LanguageService,
        private helper: Helper
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
        this.baseAwsEndpointLanguage = getAWSBucketEndpoint('languages');
    }

    @bindable availableCurrencies: Currency[];
    @bindable currencyOptions: Currency[] = [];
    @bindable currentCurrency: Currency;
    @bindable languages: Language[];
    @bindable navBarAtTop: boolean;
    @bindable currentLanguage: Language;
    @bindable languageBarLoading: boolean = true;
    paymentMethodSelected: PaymentMethodWebsite;
    paymentMethodSelectedObject: PaymentMethodWebsite;
    baseAwsEndpoint: string;
    baseAwsEndpointLanguage: string;
    pages: PageByWebsite[];
    preferredCurrency: Currency;
    customerCountry: string;
    toastSent = false;
    isLoading = true;
    clickEvent = false;
    isHover = false;
    openDropdown = false;
    forceCurrencySubscriber: Subscription;
    languageSubscriber: Subscription;
    currencySubscriber: Subscription;
    fieldUpdatedSubscriber: Subscription;
    checkSupportedSubscriber: Subscription;
    mainButtonDropdown;
    pageHostScrollbar;
    currencyAccordion;
    languageSlider;
    navBarHadTransparentBackground;
    firstTimeOpening;
    currencyDropdownComponent;
    height;
    savedCurrency;

    async attached() {
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.pageHostScrollbar = document.querySelector('#main-page-host > .simplebar-track.simplebar-vertical');
        this.mainButtonDropdown = document.querySelector('.main-dropdown-menu');
        this.currencyAccordion = document.querySelector('.main-currency-accordion');

        try {
            this.customerCountry = this.sessionService.getCountry();
            const currentCurrency = await this.sessionService.getCurrency();
            this.currentLanguage = await this.sessionService.getLanguage();
            if (!this.currencyOptions) this.currencyOptions = await this.currencyService.getCurrencyOptions();
            this.preferredCurrency = this.currencyOptions.find(currency => currency.code === currentCurrency);
            if (!this.languages) this.languages = await this.languageService.getLanguages();
            if (!this.preferredCurrency) {
                this.preferredCurrency = this.currencyOptions.find(currency => currency.code === this.defaultCurrencyValueConverter.toView(this.customerCountry));
            }
            this.sessionService.saveCurrency(this.preferredCurrency.code);
            await this.selectCurrency(this.preferredCurrency);
            this.currentCurrency = this.savedCurrency = this.preferredCurrency;
        } catch (e) {
            console.log(e);
        }
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.forceCurrencySubscriber = this.eventAggregator.subscribe(ForceCurrencyEvent, async (payload: IForceCurrencyEvent) => {
            if (!payload.forceSelector) return;

            if (payload.currentPaymentMethodSelected && payload.currency) {
                this.paymentMethodSelectedObject = this.paymentMethodSelected = payload.currentPaymentMethodSelected;
                const currencyOption = await this.currencyService.getDesiredCurrency(payload.currency);
                this.selectCurrency(currencyOption, payload.currentPaymentMethodSelected);
            } else {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected ?? null;
            }
        });

        this.languageSubscriber = this.eventAggregator.subscribe(LanguageChangedEvent, payload => {
            if (!payload.languageSelected) return;
            this.currentLanguage = this.languages?.find(x => x.id === payload?.languageSelected?.id);
        });

        this.currencySubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            if (!payload.currencySelected) return;
            this.currentCurrency = this.savedCurrency = this.preferredCurrency = this.currencyOptions.find(currency => currency.code === payload.currencySelected.code);
            if (!payload.avoidUpdate) {
                this.selectCurrency(this.currentCurrency);
            }
        });

        this.fieldUpdatedSubscriber = this.eventAggregator.subscribe('field-updated', () => {
            this.selectCurrency(this.currentCurrency);
        });

        this.checkSupportedSubscriber = this.eventAggregator.subscribe('check-supported', (payload) => {
            this.checkSupported(payload.method);
        });
    }

    async selectCurrency(selectedCurrency: Currency, paymentMethod?: PaymentMethodWebsite) {
        this.helper.debounce(this, 'checkingCurrency', 'currencyChangeTimeout', 500, async () => {
            if (!selectedCurrency) return;
            this.openDropdown = this.isHover = this.clickEvent = false;
            const oldCurrency = await this.sessionService.getCurrency();
            this.sessionService.saveCurrency(selectedCurrency.code);

            if (oldCurrency && selectedCurrency.code !== oldCurrency) {
                this.sessionService.savePreviousCurrency(oldCurrency);
            }

            this.eventAggregator.publish(CurrencyChangedEvent, { currencySelected: selectedCurrency, avoidUpdate: true });
            this.availableCurrencies = [...this.currencyOptions];
            const selectedOptionIndex = this.currencyOptions.findIndex(currency => currency.code === selectedCurrency.code);

            if (selectedOptionIndex >= 0) {
                this.currentCurrency = this.savedCurrency = this.availableCurrencies[selectedOptionIndex];
                this.availableCurrencies.splice(selectedOptionIndex, 1);
            }

            this.checkSupported(paymentMethod, oldCurrency);
        });
    }

    async checkSupported(paymentMethod?: PaymentMethodWebsite, oldCurrency?: string) {
        oldCurrency ??= await this.sessionService.getCurrency();
        let correctCurrency = '';

        if (paymentMethod?.supportedCurrencies?.length) {
            const activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
            const currencies = paymentMethod.supportedCurrencies.map(c => activeCurrencies.find(ac => ac.id === c.currencyId)?.code);

            if (!currencies.includes(oldCurrency)) {
                correctCurrency = currencies.join(', ');
            }
        }

        if (correctCurrency !== '') {
            await this.toastService.showToast(
                'Info',
                `${paymentMethod.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`,
                'info'
            );
            this.currentCurrency = this.savedCurrency = this.currencyService.activeCurrencies.find(x => x.id === paymentMethod.supportedCurrencies[0].currencyId);
            this.eventAggregator.publish(CurrencyChangedEvent, { currencySelected: this.currentCurrency, avoidUpdate: true });
        }
    }

    handleNavBarHeightByCurrencyLookup = (increase = true) => increase ? this.currencyAccordion.style.maxHeight = `${this.height}px` : this.currencyAccordion.style.maxHeight = null;

    handleCurrencyNavigationContainer = (type = 'open') => {
        const navBar = document.querySelector('#navigation-bar');
        this.languageSlider = document.querySelector('#cx-language-slider');

        let classFuncAddOrReverseRemove = 'add';
        let classFuncRemoveOrReverseAdd = 'remove';
        if (type === 'close') [classFuncAddOrReverseRemove, classFuncRemoveOrReverseAdd] = [classFuncRemoveOrReverseAdd, classFuncAddOrReverseRemove];
        this.mainButtonDropdown.classList[classFuncAddOrReverseRemove]('main-button-hover');
        this.pageHostScrollbar?.classList[classFuncAddOrReverseRemove]('opacity-0', 'invisible');
        this.currencyAccordion.classList[classFuncRemoveOrReverseAdd]('opacity-0', 'invisible');
        this.languageSlider?.classList[classFuncRemoveOrReverseAdd]('pe-none');
        if (this.navBarHadTransparentBackground && this.navBarAtTop) navBar.classList[classFuncRemoveOrReverseAdd]('navbar-transparent');
        if (type === 'open' && !this.firstTimeOpening) {
            this.firstTimeOpening = true;
            this.cxLanguageSlider.getRenderedLastItem(true, this.languages);
            this.languageBarLoading = false;
        }
    };

    closeContainerIfLeftOnSidesOrTop(e) {
        if (e.clientY <= 0 || e.offsetX < 0 || e.offsetX > this.currencyDropdownComponent.offsetWidth || e.toElement?.id === 'notifications') {
            this.handleCurrencyNavigationContainer('close');
        }
    }

    getLanguage() {
        if (!this.languages) return;
        if (this.languages.length) this.currentLanguage = this.languages.find(x => x.name === this.currentLanguage?.name);
        if (!this.currentLanguage) {
            const engLanguage = this.languages.find(x => x.name === 'ENG' && ['en', 'en-US'].some(y => y.includes(x.hrefLang)));
            this.sessionService.setLanguage(engLanguage);
            this.currentLanguage = engLanguage;
        }
    }

    async selectLanguage(language) {
        await this.languageSlider?.au?.controller?.viewModel.selectLanguage(language);
    }

    currentLanguageChanged() {
        if (!this.currentLanguage) return;
        this.sessionService.setLanguage(this.currentLanguage);
    }

    detached() {
        this.forceCurrencySubscriber.dispose();
        this.languageSubscriber.dispose();
        this.currencySubscriber.dispose();
        this.fieldUpdatedSubscriber.dispose();
    }
}
