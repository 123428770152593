import './cx-dynamic-icon.scss';
import { bindable, autoinject } from 'aurelia-framework';

@autoinject
export class CxDynamicIcon {
    constructor(private element : Element) {}

    @bindable icon: string;
    @bindable alt: string;
    @bindable size = 24;
    @bindable color = '#4300a9';
    @bindable outlined = false;
    @bindable iconClass: string;
    @bindable symbol: boolean = false;

    attached() {
        this.setSize();
    }

    setSize() {
        this.setProperty('--size', `${this.size}px`);
        this.setProperty('--color', this.color);
    }

    setProperty = (property: string, value: string) => (this.element as HTMLElement)?.style.setProperty(property, value);

    colorChanged() {
        this.setProperty('--color', this.color);
    }
}

