// Module
var code = `<template>
    <section id="cx-section" class="container-portal-style">
        <h2 
            show.bind="title" 
            class="title default"
            skeleton-loading="is-loading.bind:contentLoading"
        >
            \${title}
        </h2>
        <p  
            show.bind="description" 
            class="description mb-regular profile-subtitle custom" 
            skeleton-loading="is-loading.bind:contentLoading"
        >
            \${description}
        </p>
        <section class="content">
            <slot name="content"></slot>            
        </section>
    </section>
</template>
`;
// Exports
export default code;