import './cx-checkbox-select.scss';
import { bindable } from 'aurelia-framework';
import { device__smalltablet as tablet } from 'resources/constants';

export class CxCheckboxSelect {
    @bindable options;
    @bindable type;
    @bindable filled;
    @bindable isNotification : boolean = false;
    selectedOptions = ['all'];
    parent;
    isShowMoreFilters = false;
    checkboxExternalIsChecked = false;
    openDropdown = false;
    clickEvent = false;
    windowWidth: number;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    attached() {
        if (this.type !== 'checkbox') {
            this.options.forEach(option => option.active = true);
            this.handleFiltering(this.selectedOptions);
        }

        this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    handleFiltering(clickedOption) {
        clickedOption.active = !clickedOption.active;

        if (this.type !== 'checkbox') {
            if (clickedOption.selection === 'all') {
                this.options.forEach(option => option.active = clickedOption.active);
            } else {
                const allOptionIndex = this.options.findIndex(option => option.selection === 'all');
                const othersOptions = this.options.filter(option => option.selection !== 'all');
                const numDiffOptions = othersOptions.filter(option => this.options[allOptionIndex].active !== option.active).length;

                if (numDiffOptions > 0 && numDiffOptions < othersOptions.length) {
                    this.options[allOptionIndex].active = false;
                } else {
                    this.options[allOptionIndex].active = clickedOption.active;
                }
            }

            this.isShowMoreFilters = (this.selectedOptions.length > 1 && this.selectedOptions.length < this.options.length ? true : false);

        } else {
            this.options.forEach(option => {
                if (option.selection !== clickedOption.selection) {
                    this.checkboxExternalIsChecked = option.active = false;
                }
            });

            if (clickedOption.selection === 'all') {
                this.checkboxExternalIsChecked = clickedOption.active;
            }
        }

        this.selectedOptions = this.options.filter(option => option.active === true);
        this.parent.setActiveOption(this.selectedOptions);
    }

    clickCheckboxExternalHandler() {
        this.checkboxExternalIsChecked = !this.checkboxExternalIsChecked;
        const allOptionIndex = this.options.findIndex(option => option.selection === 'all');
        this.options[allOptionIndex].active = this.checkboxExternalIsChecked;
        this.handleFiltering({ selection: 'all', active: !this.checkboxExternalIsChecked });
    }

    clearExternalCheckbox() {
        this.checkboxExternalIsChecked = false;
    }

    toggleDropdown(event: MouseEvent) {
        switch (event.type) {
            case 'click':
                if (this.clickEvent || this.windowWidth <= tablet) {
                    this.openDropdown = !this.openDropdown;
                }
                this.clickEvent = true;
                break;
            case 'mouseenter':
                if (this.openDropdown) return;
                this.clickEvent = this.openDropdown;
                if (this.windowWidth > 996 && !this.clickEvent) {
                    this.openDropdown = this.windowWidth > 996 && !this.clickEvent;
                }
                break;
            case 'mouseleave':
                if (!this.clickEvent) {
                    this.openDropdown = false;
                }
                break;
            default:
                break;
        }
    }

    clearOptionsSelection() {
        this.options.forEach(option => option.active = false);
        this.checkboxExternalIsChecked = this.openDropdown = false;
        this.selectedOptions = this.options.filter(option => option.active === true);
        this.parent.setActiveOption(this.selectedOptions);
    }
}
