// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/location_pin.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-address-input">
        <mdc-expandable class="purchase-flow-expandable" view-model.ref="addressInputExpandable" open.two-way="expandableOpen">
            <div class="text-large-plus d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="location" loading="lazy">
                Address
            </div>
            <div show.bind="expandableOpen">
                <span class="text-darkergray">Please enter the beneficiary address that will receive cash in mail for this transaction.</span>     
                <div class="position-relative w-100 mt-3 d-none d-sm-flex joined-input \${ error ? 'error-border' : ''} \${ showGreenCheckMarkAddress ? 'success' : '' }">
                    <cx-places-autocomplete-input 
                        class="first-input w-100 cx-form-control-borderless" 
                        autocompleted-address.two-way="address"
                        placeholder="Address"
                        if.bind="!isMobile && cashInMailCheck"
                        loading.bind="loading"
                    ></cx-places-autocomplete-input>
                    <cx-dropdown
                        if.bind="countries"
                        id.bind="'countrySelector'"
                        options.bind="countries"
                        placeholder.two-way="countryPlaceholder"
                        pressed.bind="countryPlaceholder !== 'Country'"
                        borderless.bind="true"
                        selected-option.two-way="selectedOption"
                        class="w-100"
                    ></cx-dropdown>
                    <cx-dropdown
                        if.bind="states"
                        id.bind="'stateSelector'"
                        options.bind="states"
                        placeholder.two-way="statePlaceholder"
                        pressed.bind="statePlaceholder !== 'State'"
                        borderless.bind="true"
                        selected-option.two-way="selectedOption"
                        class="w-100"
                    ></cx-dropdown>
                    <mdc-text-field
                        outlined
                        required.bind="true"
                        value.bind="city"
                        placeholder="City"
                        class="input-password middle-input w-50 cx-form-control-borderless" 
                        keyup.delegate="validateAddress()"
                        focusin.delegate="validateAddress()"
                        ref="walletAddressInput"
                    ></mdc-text-field>
                    <mdc-text-field
                        outlined
                        required.bind="true"
                        value.bind="zip"
                        placeholder="Zip Code"
                        class="input-password middle-input w-50 cx-form-control-borderless" 
                        keyup.delegate="validateAddress()"
                        focusin.delegate="validateAddress()"
                        ref="walletAddressInput"
                    ></mdc-text-field>
                    <div class="verify-icons-container cx-form-control-borderless last-input">
                        <span
                        class="circle-icon error material-icons text-large"
                        alt="error"
                        if.bind="error"
                        >
                            error_outline
                        </span>
                        <span
                            class="circle-icon check text-large material-icons-outlined"
                            alt="green check"
                            if.bind="showGreenCheckMarkAddress"
                            >check_circle
                        </span>
                        <mdc-circular-progress if.bind="showMiniSpinnerAddress" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    </div>
                </div>
                <div class="position-relative d-flex flex-column w-100 pt-3 d-sm-none">
                    <cx-places-autocomplete-input 
                        class="first-input mb-3 w-100 cx-form-control-borderless"
                        autocompleted-address.two-way="address" 
                        placeholder="Address" 
                        if.bind="isMobile && cashInMailCheck"
                        loading.bind="loading"
                    >
                    </cx-places-autocomplete-input>
                    <div class="w-100 d-flex mb-3">
                    <cx-dropdown if.bind="countries" id.bind="'countrySelector'" options.bind="countries" placeholder.bind="countryPlaceholder" pressed.bind="countryPlaceholder !== 'Country'" selected-option.two-way="selectedOption" border-radius.bind="'full'" class="w-50 country-input"></cx-dropdown>
                    <cx-dropdown if.bind="states" id.bind="'stateSelector'" options.bind="states" placeholder.bind="statePlaceholder" pressed.bind="statePlaceholder !== 'State'" selected-option.two-way="selectedOption" border-radius.bind="'full'" class="w-50"></cx-dropdown>
                    </div>
                    <div class="d-flex w-100 position-relative joined-input \${ showGreenCheckMarkAddress ? 'success' : '' } \${ error ? 'error-border' : ''}"> 
                        <mdc-text-field
                            outlined
                            required.bind="true"
                            value.bind="city"
                            placeholder="City"
                            class="input-password first-input w-50 cx-form-control-borderless" 
                            keyup.delegate="validateAddress()"
                            focusin.delegate="validateAddress()"
                            ref="walletAddressInput"
                        ></mdc-text-field>
                        <mdc-text-field
                            outlined
                            required.bind="true"
                            value.bind="zip"
                            placeholder="Zip Code"
                            class="input-password last-input w-50 cx-form-control-borderless" 
                            keyup.delegate="validateAddress()"
                            focusin.delegate="validateAddress()"
                            ref="walletAddressInput"
                        ></mdc-text-field>
                        <div class="verify-icons-container cx-form-control-borderless last-input">
                            <span
                            class="circle-icon-address error material-icons text-large"
                            alt="error"
                            if.bind="error"
                            >
                                error_outline
                            </span>
                            <span
                                class="circle-icon-address check text-large material-icons-outlined"
                                alt="green check"
                                if.bind="showGreenCheckMarkAddress"
                                >check_circle
                            </span>
                            <mdc-circular-progress if.bind="showMiniSpinnerAddress" class="mini-spinner-icon-address" size="25" stroke-width="2"></mdc-circular-progress>
                        </div>
                    </div>
                </div>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;