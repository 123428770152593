export default class PaymentPlan {
    price: number;
    type: string;
    productType: string;

    constructor(price: number, type: string, productType: string) {
        this.price = price;
        this.type = type;
        this.productType = productType;
    }
}
