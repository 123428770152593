// Module
var code = `<template>
    <div id="cx-nav-preorder" ref="mainContainerRef">
        <div class="title-header">
            <h2>\${contentAction[preoderTypeAction].title | siteStrings}</h2>
            <!-- switch actions -->
            <div
                if.bind="preoderTypeAction === 'exchange'"
                class="d-flex align-items-center"
            >
                <mdc-form-field class="\${exchangeType === 'FF' ? 'purple-active' : ''}">
                    <mdc-radio
                        id="fiat"
                        value.bind="'FF'"
                        name.bind="exchange-type"
                        checked.bind="exchangeType"
                        change.delegate="exchangeTypeChanged()"
                    ></mdc-radio>
                    <label for="fiat">\${'CX_EXCHANGE_FIAT' | siteStrings}</label>
                </mdc-form-field>
                <mdc-form-field class="\${exchangeType === 'CC' ? 'purple-active' : ''}">
                    <mdc-radio
                        id="crypto"
                        value.bind="'CC'"
                        name.bind="exchange-type"
                        checked.bind="exchangeType"
                        change.delegate="exchangeTypeChanged()"
                    ></mdc-radio>
                    <label for="crypto">\${'CX_EXCHANGE_CRYPTO' | siteStrings}</label>
                </mdc-form-field>
            </div>
        </div>

        <div class="preorder-content">
            <div class="currencies">
                <cx-text-input
                    label="\${(contentAction[preoderTypeAction].leftSidePlaceHolder || contentAction[preoderTypeAction][exchangeType].leftSidePlaceHolder) | siteStrings}"
                    class="w-100 input-with-label"
                    button-icon.bind="leftFilter"
                    leading-icon.bind="'search'"
                    value.two-way="leftFilter"
                    button-icon-function.call="cleanFilter('left')"
                    key-up-function.call="filterLeftOptions()"
                    outlined.bind="false"
                ></cx-text-input>
                <h4>
                    \${(contentAction[preoderTypeAction].leftSideTitle || contentAction[preoderTypeAction][exchangeType].leftSideTitle) | siteStrings}
                </h4>

                <div class="currencies-list">
                    <cx-cycle-list max-items.bind="maxVisibleOptions">
                        <mdc-form-field
                            repeat.for="currency of leftListFiltered"
                            class="currency-button \${selectedLeft === currency.id ? 'purple-border' : ''}"
                        >
                            <mdc-radio
                                id="\${'cryptoCurrency-' + currency.id}"
                                value.bind="currency.id"
                                name.bind="cryptoCurrency"
                                checked.bind="selectedLeft"
                            ></mdc-radio>
                            <label for="\${'cryptoCurrency-' + currency.code}">
                                <img
                                    if.bind="currency.haveImage"
                                    src="\${currency.imagePath}"
                                    class="\${exchangeType === 'FF' && preoderTypeAction === 'exchange' ? 'rounded rounded-5' : ''}"
                                />
                                <span else class="material-icons-outlined">image_not_supported</span>
                                <span>\${currency.code}</span>
                            </label>
                        </mdc-form-field>
                    </cx-cycle-list>
                </div>
            </div>

            <div class="separator"></div>

            <div class="payment-methods">
                <cx-text-input
                    label="\${(contentAction[preoderTypeAction].rightSidePlaceHolder || contentAction[preoderTypeAction][exchangeType].rightSidePlaceHolder) | siteStrings}"
                    class="input-with-label search-field w-100"
                    button-icon.bind="rightFilter"
                    leading-icon.bind="'search'"
                    value.two-way="rightFilter"
                    key-up-function.call="filterRightOptions()"
                    button-icon-function.call="cleanFilter('right')"
                    outlined.bind="false"
                ></cx-text-input>
                <h4>
                    \${(contentAction[preoderTypeAction].rightSideTitle || contentAction[preoderTypeAction][exchangeType].rightSideTitle) | siteStrings}
                </h4>

                <div class="payment-methods-list">
                    <cx-cycle-list max-items.bind="preoderTypeAction !== 'exchange' ? maxVisibleOptions - 1 : maxVisibleOptions">
                        <mdc-form-field
                            repeat.for="method of rightListFiltered"
                            class="currency-button \${preoderTypeAction !== 'exchange' ? 'method-button' : ''} \${selectedRight === method.id ? 'purple-border' : ''}"
                            name.bind="paymentMethods"
                        >
                            <mdc-radio
                                id="\${'paymentMethod-' + method.id}"
                                value.bind="method.id"
                                name.bind="paymentMethods"
                                checked.bind="selectedRight"
                            ></mdc-radio>
                            <label for="\${'paymentMethod-' + method.id}">
                                <img
                                    if.bind="method.haveImage"
                                    src="\${method.imagePath}"
                                    class="\${exchangeType === 'FF' && preoderTypeAction === 'exchange' ? 'rounded rounded-5' : ''}"
                                />
                                <span else class="material-icons-outlined">image_not_supported</span>
                                <span>\${method.code}</span>
                            </label>
                        </mdc-form-field>
                    </cx-cycle-list>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;