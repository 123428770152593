// Module
var code = `<template>
    <div id="cx-row-buttons" ref="selector">
        <div ref="btnAnimated" class="button-animated p-0"></div>
        <div ref="btnGroup" class="button-group flex-row no-gutters d-flex">
          <button
            repeat.for="option of options"
            id="row-button-\${option.value}"
            type="button"
            class="p-0 flex-grow-1 font-medium \${ option.value === optionSelected.value ? 'active pe-none' : '' } \${ !animationRunning ? 'button-hover' : '' }"
            click.delegate="changeOption(option)"
          >\${ option.label }</button>
        </div>    
    </div>
</template>
`;
// Exports
export default code;