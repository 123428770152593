// Module
var code = `<template>
    <div id="page-content-image">
        <img if.bind="imagePath && !imagePath.includes('Key')" ref="pageContentImageElement" class="w-100 \${imageStyle}" src="\${baseAwsEndpoint}\${imagePath}" alt="\${imageAlt}" loading="lazy" width.bind="pageContentImageElement.naturalWidth" height.bind="pageContentImageElement.naturalHeight">
        <div if.bind="imagePath && imagePath.includes('Key')" class="d-flex justify-content-center align-items-center image-path">
            <span class="material-icons-outlined me-2">
                image_not_supported
            </span>
            \${imagePath}
        </div>
    </div>
</template>
`;
// Exports
export default code;