// Module
var code = `<template>
    <div id="cx-lookup" element.ref="lookupSelector">
        <div class="position-relative h-100" if.bind="dropdownOptions" mdc-menu-surface-anchor>
            <mdc-text-field 
                class="\${class} cursor-text mdc-text-field--with-trailing-icon \${alwaysShowLabel ? 'always-show-label' : ''} \${alwaysShowLabel  && (selectedObject || searchExists) ? 'input-margin' : ''} \${!alwaysShowLabel ? 'disabled-floating-label' : ''} \${label ? 'input-margin-label' : ''}"
                label.bind="(!selectedObject && !searchExists) || alwaysShowLabel ? label : ''"
                ref="lookupTextField"
                disabled.bind="disabled"
                placeholder.bind="placeholder"
                focusin.delegate="handleTextFieldFocusIn()"
                focusout.delegate="handleTextFieldFocusOut(\$event)"
                prefix.bind="savedSelectedObject.symbol"
                click.delegate="handleCloseOnInputClick()"
            >
                <mdc-icon if.bind="(!hideArrow || (hideArrow && !selectedObject)) && !(search.value && search.focus)" mdc-text-field-icon trailing class="material-icons temporary-workaround-for-text-field-svg arrow-icon-container mdc-select__dropdown-icon \${!disabled ? 'pe-auto' : ''}" click.delegate="!disabled ? handleArrowDropdown() : ''">arrow_drop_down</mdc-icon>
            </mdc-text-field>
            <div if.bind="search.value && search.focus" class="close-icon material-icons-round position-absolute" mousedown.trigger="clearSearch(true)">close</div>
            <mdc-lookup
                mdc-lookup.ref="mdcLookupRef"
                ref="lookupElement"
                class="lookup-container"
                options.bind="getOptions"
                display-field.bind="getDisplayField"
                value.bind="selectedObject"
                input.bind="lookupTextField"
                change.delegate="handleSelectionChanged(\$event)"
                click.delegate="handleTextFieldFocusOut(null, true)"
                preload-options
                autoselect-single-on-blur
                debounce.bind="'0'"
            >
                <template replace-part="option">
                    <span class="mdc-list-item__primary-text \${alignVertically ? 'align-vertically-main-container' : ''}">
                        <img if.bind="option.imagePath" class="currency-icon" src="\${baseAwsEndpoint}\${option.imagePath}" alt.bind="option.symbol" loading="lazy">
                        <span else class="align-vertically-first-container text-center d-flex align-items-center justify-content-center">\${option.symbol | pascalSpacing:pascalSpacing}</span>
                        <span if.bind="alignVertically">\${option.code | pascalSpacing:pascalSpacing}</span>
                        <span if.bind="!alignVertically">\${getDisplayField(option) | pascalSpacing:pascalSpacing}</span>
                    </span>
                </template>
                <template replace-part="not-found">
                    <mdc-list-item class="result-not-found">Result not found...</mdc-list-item>
                </template>
            </mdc-lookup>
        </div>
    </div>
</template>
`;
// Exports
export default code;