// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/customer-portal/upload-support.svg", import.meta.url);
// Module
var code = `<template>
    <div id="verification" class="position-relative">
        <h1 class="text-extra-large font-medium my-4" skeleton-loading="is-loading.bind:pageLoading;width:210px;height:50px;">Verification</h1>

        <!-- Phone Verification -->
        <div tabindex="0" class="single-container \${ activeVerificationTabs['phone'] && !user.phoneNumberConfirmed ? 'active' : '' } mb-4 d-flex \${!user.phoneNumberConfirmed && !phoneVerification ? 'cursor-pointer' : 'update'}" click.delegate="toggleVerificationTab('phone', \$event)" skeleton-loading="is-loading.bind:pageLoading;">
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <div class="position-relative icon-container">
                        <img if.bind="user.phoneNumberConfirmed" class="position-absolute checkmark" alt="checkmark" loading="lazy">
                        <img if.bind="!user.phoneNumberConfirmed" class="position-absolute pending" alt="pending" loading="lazy">
                        <span class="phone"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="section-header text-darkergray mt-0 mt-lg-1">\${'CX_PHONE_VERIFICATION' | siteStrings}</span>
                        <span if.bind="!user.phoneNumberConfirmed" class="section-subtitle text-purple">\${'CX_PENDING' | siteStrings}</span>
                        <span else class="section-subtitle text-success-green">\${'CX_VERIFIED' | siteStrings}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <span if.bind="user.phoneNumberConfirmed && showSupportMessage && requestedPhoneUpdate" class="ms-3 align-self-center text-red font-regular support-msg d-none d-md-block">\${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}</span>
                    <span if.bind="user.phoneNumberConfirmed" class="ms-0 ms-md-3 align-self-center verified text-truncate">\${ user.phoneNumber | phoneFormat:user.phoneCountryCode }</span>
                    <span if.bind="user.phoneNumberConfirmed" click.delegate="resetPhoneNumber()" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedPhoneUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedPhoneUpdate ? 'close' : 'edit' }</span>
                    <span if.bind="!user.phoneNumberConfirmed" class="material-icons-outlined section-icons ms-0 ms-sm-1 mt-2 text-extra-large-static add-icon cursor-pointer" click.delegate="phoneVerificationOpenTab()">\${ phoneVerification ? 'close' : 'add' }</span>
                </div>
            </div>
            <div show.bind="phoneVerification && !user.phoneNumberConfirmed" class="au-animate animate-fade-in animate-fade-out input-outer-container">
                <div class="input-inner-container">
                    <cx-phone-input
                        view-model.ref="phoneElement"
                        phone-input.two-way="phoneInput"
                        loading.two-way="loadingPhoneInput"
                        input-style="form-control input-password"
                        require-sms.bind="true"
                        user.two-way="userPhone"
                        click.delegate="\$event.stopPropagation()"
                        copy-text-bellow.bind="'CX_PHONE_INFO_MESSAGE' | siteStrings"
                    ></cx-phone-input>
                </div>
            </div>
        </div>

        <!-- Mail Verification -->
        <div tabindex="0" class="single-container \${ activeVerificationTabs['email'] && !user.emailConfirmed ? 'active' : '' } mb-4 d-flex \${!user.emailConfirmed && !emailVerification ? 'cursor-pointer' : 'update'}" click.delegate="toggleVerificationTab('email', \$event)" skeleton-loading="is-loading.bind:pageLoading;">
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <div class="position-relative icon-container">
                        <img if.bind="user.emailConfirmed" class="position-absolute checkmark" alt="checkmark" loading="lazy">
                        <img if.bind="!user.emailConfirmed" class="position-absolute pending" alt="pending" loading="lazy">
                        <span class="email"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="section-header text-darkergray mt-0 mt-lg-1">\${'CX_EMAIL_VERIFICATION' | siteStrings}</span>
                        <span if.bind="!user.emailConfirmed" class="section-subtitle text-purple">\${'CX_PENDING' | siteStrings}</span>
                        <span else class="section-subtitle text-success-green">\${'CX_VERIFIED' | siteStrings}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <span if.bind="user.emailConfirmed && showSupportMessage && requestedEmailUpdate" class="text-red font-regular support-msg d-none d-md-block">\${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}</span>
                    <span if.bind="user.emailConfirmed" class="ms-0 ms-md-3 verified text-truncate">\${ user.email }</span>
                    <div class="d-none d-md-flex align-items-center">
                        <span if.bind="user.emailConfirmed" click.delegate="showMessage('email')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedEmailUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedEmailUpdate ? 'close' : 'edit' }</span>
                    </div>
                    <div class="d-flex d-md-none position-relative edit-container align-items-center">
                        <span if.bind="user.emailConfirmed" click.delegate="showMessage('email')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedEmailUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedEmailUpdate ? 'close' : 'edit' }</span>
                        <div
                            as-element="mdc-tooltip"
                            anchor-elem.bind="root"
                            class="position-absolute custom-tooltip mdc-tooltip mdc-tooltip--multiline \${ showSupportMessage && requestedEmailUpdate ? 'mdc-tooltip--shown mdc-tooltip--shown-transition' : 'mdc-tooltip--hide mdc-tooltip--hide-transition' }"
                            role="tooltip"
                            aria-hidden="true">
                            <div class="mdc-tooltip__surface mdc-tooltip__surface-animation">
                                \${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}
                            </div>
                        </div>
                    </div>
                    <span if.bind="!user.emailConfirmed" class="material-icons-outlined section-icons ms-0 ms-sm-1 mt-2 text-extra-large-static add-icon cursor-pointer" click.delegate="emailVerification = !emailVerification">\${ emailVerification ? 'close' : 'add' }</span>
                </div>
            </div>
            <div show.bind="emailVerification && !user.emailConfirmed" class="au-animate animate-fade-in animate-fade-out input-outer-container">
                <div class="input-inner-container">
                    <span if.bind="!emailSent" class="text-darkergray text-small mt-2">\${'CX_SEND_EMAIL_WITH_CODE_MESSAGE' | siteStrings}</span>
                    <span else class="text-success-green text-small mt-2">\${'CX_CONFIRMATION_EMAIL_SENT_MESSAGE' | siteStrings}</span>
                    <form submit.delegate="catchSubmit()" class="position-relative mt-2 email-container" click.delegate="\$event.stopPropagation()">
                        <mdc-text-field
                            outlined
                            autocomplete="off"
                            value.two-way="user.email & validate"
                            id="email"
                            class="w-100 input-password cx-form-control pe-5 \${ emailSent ? 'cx-form-control--warning' : '' } \${ showCheckMark ? 'cx-form-control--success' : ''} \${ showErrorMark ? 'cx-form-control--error' : ''}"
                            required.bind="true"
                            placeholder.bind="'CX_ENTER_YOUR_EMAIL' | siteStrings"
                            focusin.delegate="emailUpdatedOnFocusIn()"
                            keyup.delegate="emailUpdatedOnKeyPress(\$event)"
                            disabled.bind="emailSent"
                            ref="emailInput"
                        ></mdc-text-field>
                        <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showCheckMark">check_circle</span>
                        <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorMark">error_outline</span>
                        <span class="circle-icon pending text-large material-icons-outlined" alt="waiting" if.bind="emailSent">watch_later</span>
                        <span repeat.for="rule of rules.results" if.bind="showEmailValidationError" class="ms-auto mt-1 align-self-center text-small text-red font-regular">\${'CX_ENTER_VALID_EMAIL_PROMPT' | siteStrings}</span>
                    </form>
                    <div if.bind="emailSent" class="row mt-2">
                        <div class="col-12 text-purple text-small">
                            <span class="link-styling cursor-pointer" click.delegate="sendConfirmationEmail()">\${'CX_RESEND_VERIFICATION_CODE' | siteStrings}</span>
                            <span class="text-medium px-1">|</span>
                            <span class="link-styling cursor-pointer" click.delegate="cancelEmailVerification()">\${'CX_CANCEL' | siteStrings}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ID Verification -->
        <div tabindex="0" class="single-container \${activeVerificationTabs['id'] && !user.idVerified ? 'active' : 'update'} mb-4 d-flex \${!user.idVerified ? 'cursor-pointer' : ''} \${!user.emailConfirmed && !user.phoneNumberConfirmed && !user.idVerified ? 'disabled' : ''}" click.delegate="toggleVerificationTab('id', \$event)" skeleton-loading="is-loading.bind:pageLoading;">
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <div class="position-relative icon-container">
                        <img if.bind="user.idVerified" class="position-absolute checkmark" alt="checkmark" loading="lazy">
                        <img if.bind="!user.idVerified" class="position-absolute pending" alt="pending" loading="lazy">
                        <span class="finger"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="section-header text-darkergray mt-0 mt-lg-1">\${'CX_ID_VERIFICATION' | siteStrings}</span>
                        <span if.bind="!user.idVerified" class="section-subtitle text-purple">\${ !user.emailConfirmed && !user.phoneNumberConfirmed && !user.idVerified ? '' : 'CX_PENDING' | siteStrings }</span>
                        <span else class="section-subtitle text-success-green">\${'CX_VERIFIED' | siteStrings}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center position-relative">
                    <span if.bind="user.idVerified && showSupportMessage && requestedIdUpdate" class="ms-3 align-self-center text-red font-regular support-msg d-none d-md-block">\${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}</span>
                    <span if.bind="user.idVerified" class="ms-0 ms-md-3 align-self-center verified text-truncate">\${ userVeriffData ? userVeriffData.firstName : user.firstName | fullname: userVeriffData ? userVeriffData.lastName : user.lastName }</span>
                    <div class="d-none d-md-flex align-items-center">
                        <span if.bind="user.idVerified" click.delegate="showMessage('id')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedIdUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedIdUpdate ? 'close' : 'edit' }</span>
                    </div>
                    <span if.bind="!user.idVerified" class="material-icons me-1 mt-2 text-extra-large-static arrow-icon section-icons" click.delegate="openCxVeriff()">arrow_right</span>
                    <div class="d-flex d-md-none position-relative edit-container align-items-center">
                        <span if.bind="user.idVerified" click.delegate="showMessage('id')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedIdUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedIdUpdate ? 'close' : 'edit' }</span>
                        <div
                            as-element="mdc-tooltip"
                            anchor-elem.bind="root"
                            class="position-absolute custom-tooltip mdc-tooltip mdc-tooltip--multiline \${ showSupportMessage && requestedIdUpdate ? 'mdc-tooltip--shown mdc-tooltip--shown-transition' : 'mdc-tooltip--hide mdc-tooltip--hide-transition' }"
                            role="tooltip"
                            aria-hidden="true">
                            <div class="mdc-tooltip__surface mdc-tooltip__surface-animation">
                                \${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div show.bind="idVerification && !user.idVerified" class="au-animate animate-fade-in animate-fade-out input-outer-container" click.delegate="\$event.stopPropagation()">
                <div class="input-inner-container id-verification mt-2">
                    <cx-veriff
                        element.ref="cxVeriff"
                        user.bind="user"
                        class="d-block mb-3 mb-md-0"
                    ></cx-veriff>
                </div>
            </div>
        </div>

        <!-- Address Verification -->
        <div
            tabindex="0"
            class="single-container \${ activeVerificationTabs['address'] && !user.addressVerified ? 'active' : '' } mb-4 d-flex \${!user.addressVerified && !addressVerification ? 'cursor-pointer' : 'update'} \${!user.idVerified ? 'disabled' : ''}"
            click.delegate="toggleVerificationTab('address', \$event)"
            skeleton-loading="is-loading.bind:pageLoading;"
        >
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <div class="position-relative icon-container">
                        <img if.bind="user.addressVerified" class="position-absolute checkmark" alt="checkmark" loading="lazy">
                        <img if.bind="!user.addressVerified" class="position-absolute pending" alt="pending" loading="lazy">
                        <span class="address"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="section-header text-darkergray mt-0 mt-lg-1">\${'CX_ADDRESS_VERIFICATION' | siteStrings}</span>
                        <span if.bind="!user.addressVerified" class="section-subtitle text-purple">\${ !user.idVerified ? '' : 'CX_PENDING' | siteStrings }</span>
                        <span else class="section-subtitle text-success-green"> \${'CX_VERIFIED' | siteStrings} </span>
                        </span>
                    </div>
                </div>
                <div class="d-flex align-items-center position-relative">
                    <span if.bind="user.addressVerified && showSupportMessage && requestedAddressUpdate" class="ms-3 align-self-center text-red font-regular support-msg d-none d-md-block">\${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}</span>
                    <span if.bind="user.addressVerified" class="ms-0 ms-md-3 align-self-center verified text-truncate">\${ userVeriffData.addresses.length > 0 ? userVeriffData.addresses[0].fullAddress : user.address | capitalizeText:'sentence' }</span>
                    <span if.bind="!user.addressVerified && !poaHidden" class="material-icons ms-0 ms-sm-1 mt-2 text-extra-large-static arrow-icon section-icons cursor-pointer" click.delegate="openCxVeriff('poa')">arrow_right</span>
                    <span if.bind="!user.addressVerified && poaHidden" class="material-icons-outlined section-icons ms-0 ms-sm-1 mt-2 text-extra-large-static add-icon cursor-pointer" click.delegate="addressVerification = !addressVerification">\${ addressVerification ? 'close' : 'add' }</span>
                    <div class="d-none d-md-flex align-items-center">
                        <span if.bind="user.addressVerified" click.delegate="showMessage('address')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedAddressUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedAddressUpdate ? 'close' : 'edit' }</span>
                    </div>
                    <div class="d-flex d-md-none position-relative edit-container align-items-center">
                        <span if.bind="user.addressVerified" click.delegate="showMessage('address')" class="material-icons font-bold cursor-pointer edit section-icons \${ showSupportMessage && requestedAddressUpdate ? 'text-close' : 'text-large-plus-static' }">\${ showSupportMessage && requestedAddressUpdate ? 'close' : 'edit' }</span>
                        <div
                            as-element="mdc-tooltip"
                            anchor-elem.bind="root"
                            class="position-absolute custom-tooltip mdc-tooltip mdc-tooltip--multiline \${ showSupportMessage && requestedAddressUpdate ? 'mdc-tooltip--shown mdc-tooltip--shown-transition' : 'mdc-tooltip--hide mdc-tooltip--hide-transition' }"
                            role="tooltip"
                            aria-hidden="true">
                            <div class="mdc-tooltip__surface mdc-tooltip__surface-animation">
                              \${'CX_CONTACT_SUPPORT_MESSAGE' | siteStrings}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div show.bind="addressVerification && !user.addressVerified" class="au-animate animate-fade-in animate-fade-out input-outer-container" click.delegate="\$event.stopPropagation()">
                <div class="input-inner-container">
                    <cx-veriff
                        view-model.ref="veriffViewModel"
                        hidden-button="true"
                        show.bind="!user.addressVerified"
                        user.bind="user"
                        if.bind="!poaHidden"
                    ></cx-veriff>
                    <div else>
                        <span if.bind="!fileUploaded" class="text-darkergray text-regular mt-2">\${'CX_UPLOAD_DOCUMENT_FRONT' | siteStrings}</span>
                        <form click.delegate="isAddressInReview ? '' : triggerDocumentSelector()" submit.delegate="catchSubmit()" class="verification-form position-relative mt-2 \${ isAddressInReview ? 'cursor-default' : 'cursor-pointer' }">
                            <mdc-text-field
                                outlined
                                autocomplete="off"
                                id="fileHolder"
                                ref="fileHolderElement"
                                class="w-100 input-password cx-form-control \${ user.addressVerified ? 'cx-form-control--success' : '' } \${ showErrorMark ? 'cx-form-control--error' : '' } \${ isAddressInReview ? 'cx-form-control--warning' : '' }"
                                required.bind="true"
                                placeholder.bind="'CX_SELECT_DOCUMENT_PROMPT' | siteStrings"
                                value.bind="fileName"
                                disabled
                                ref="addressInput"
                            ></mdc-text-field>
                            <span if.bind="!user.addressVerified && !showErrorMark && !isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon cursor-pointer">upload</span>
                            <span if.bind="user.addressVerified" class="circle-icon check text-large material-icons-outlined" alt="green check">check_circle</span>
                            <span if.bind="showErrorMark" class="circle-icon error text-large material-icons" alt="error">error_outline</span>
                            <span if.bind="isAddressInReview" class="circle-icon_close text-large material-icons-outlined" alt="waiting">watch_later</span>
                            <span if.bind="isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon cursor-pointer" click.delegate="clearAddressVerification()">close</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Additional Information -->
        <div
            if.bind="user.additionalInformation || user.idVerified"
            tabindex="0"
            class="single-container \${ activeVerificationTabs['additionalInfo'] ? 'active' : '' } mb-4 d-flex \${!additionalVerification ? 'cursor-pointer' : 'update'}"
            click.delegate="toggleVerificationTab('additionalInfo', \$event)"
            dragenter.trigger="dragEnterHandler(\$event)"
            dragover.delegate="dragEnterHandler(\$event)"
            dragleave.trigger="dragLeaveHandler(\$event)"
            drop.trigger="dropHandler(\$event)"
            id="additional-information-tab"
            skeleton-loading="is-loading.bind:pageLoading;"
        >
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <div class="position-relative icon-container">
                        <span class="additional"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="section-header text-darkergray mt-0 mt-lg-1">
                            <cx-page-content-area
                                key.bind="'CX_ADDITIONAL_INFORMATION'"
                            ></cx-page-content-area>
                        </span>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <span class="material-icons-outlined section-icons ms-0 ms-sm-1 mt-2 text-extra-large-static add-icon cursor-pointer" click.delegate="additionalVerification = !additionalVerification">\${ additionalVerification ? 'close' : 'add' }</span>
                </div>
            </div>
            <div show.bind="additionalVerification" class="au-animate animate-fade-in animate-fade-out input-outer-container">
                <div
                    id="drag-drop-additional-info"
                    class="input-inner-container additional-information cx-drop-zone-outside position-relative"
                >
                    <div class="file-input">
                        <cx-file-input
                            text.bind="fileTextKey | siteStrings:fileText"
                            upload-function-name="handleFileUpload"
                            uploading.bind="fileUpload"
                            state.two-way="fileState"
                            selectable="true"
                        ></cx-file-input>
                    </div>
                    <div id="drop-zone" class="flex-column position-absolute">
                        <div class="drop-zone-lg col d-flex flex-column align-items-center justify-content-center">
                            <div if.bind="isAllFilesDropLoaded" class="d-flex align-items-center flex-column">
                                <img src="${___HTML_LOADER_IMPORT_0___}" alt="cloud icon" loading="lazy" />
                                <div class="drop-file-text">\${'CX_DROP_FILE_HERE' | siteStrings}</div>
                            </div>
                            <div if.bind="!isAllFilesDropLoaded" class="d-flex align-items-center w-100 flex-column">
                                <div class="uploading-text">\${'CX_UPLOADING' | siteStrings}...</div>
                                <mdc-linear-progress open.bind="true" buffer="1"></mdc-linear-progress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="file"
            id="documentSelector"
            files.bind="selectedFiles"
            input.delegate="isAddressInReview ? '' : handleAddressUpload(selectedFiles)"
            class="d-none"
        >
        <div class="verification-footer">
            <cx-progress-bar
                num-steps.bind="3"
                is-loading.bind="pageLoading"
                is-verification-page.bind="'true'"
                user.bind="user"
                class="d-none d-md-block d-lg-none"
            ></cx-progress-bar>
            <cx-progress-bar
                num-steps.bind="3"
                is-loading.bind="pageLoading"
                user.bind="user"
                is-vertical.bind="'true'"
                class="d-sm-block d-md-none"
            ></cx-progress-bar>
        </div>
    </div>
</template>
`;
// Exports
export default code;