export class DialogContent {
    constructor(
        public text:string,
        public step:number = 1,
        public type: number = DialogContentType.Content,
        public width?: string | number
    ) {}
}

export const DialogContentType = {
    Content: 1,
    Title: 2
};

export default {
    DialogContent,
    DialogContentType
};
