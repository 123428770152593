import { ApiService } from './api-service';
import { autoinject } from 'aurelia-dependency-injection';
import { ICreatePaymentRequest, IPaymentRequestResponse } from './models/payment-request';

@autoinject()
export class PaymentRequestService {
    path = 'PaymentRequest';

    constructor(
        private api: ApiService
    ) {
        this.api = api;
    }

    async add(request: ICreatePaymentRequest): Promise<IPaymentRequestResponse | null> {
        return await this.api.doPost(this.path, request);
    }
}
