export interface SupportTicket {
    ticketKey: string | null;
    title: string;
    description: string;
    status: SupportTicketStatus;
    orderId: number | null;
    customerId: number;
    departmentId: number | null;
    websiteId: number;
    messages: SupportTicketMessage[] | null;
}

export enum SupportTicketStatus {
    Active = 0,
    Closed = 1
}

export interface SupportTicketMessage {
    userId: number;
    message: string;
    attachments: SupportTicketMessageAttachment[];
}

export interface SupportTicketMessageAttachment {
    url: string;
    fileName: string;
}
