import './cx-confirm-dialog.scss';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';
import { ValidationRenderer } from 'resources/validation-renderer';
import { DialogContentType } from 'resources/elements/cx-confirm-dialog/dialog-content';



@autoinject()
export class CxConfirmDialog {
    width: number;
    password: string;
    token: string;
    sizeChanged: Subscription;
    autoFillTriggered;
    timeouts: NodeJS.Timeout[];
    passwordStopWatch: NodeJS.Timeout;
    passwordStopWatch2: NodeJS.Timeout;
    miniSpinnerPasswordStopwatch: NodeJS.Timeout;
    passwordFocusInStopWatch: NodeJS.Timeout;
    passwordValid: boolean;
    passwordInvalid: boolean;
    showErrorCheckMarkPassword: boolean;
    showMiniSpinnerPassword: boolean;
    toastPasswordSent: boolean;
    firedFunction: boolean;
    successFunction: boolean;
    showGreenCheckMarkToken: boolean;
    showErrorCheckMarkToken: boolean;
    showMiniSpinnerToken: boolean;
    toastTokenSent: boolean;
    tokenStopWatch: NodeJS.Timeout;
    tokenStopWatch2: NodeJS.Timeout;
    miniSpinnerTokenStopwatch: NodeJS.Timeout;
    tokenFocusInStopWatch: NodeJS.Timeout;
    parent;

    constructor(
        private router: Router,
        private validator: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private toastService: ToastService) {
        this.validator = validator;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable user;
    @bindable dialog;
    @bindable title;
    @bindable content;
    @bindable buttons;
    @bindable icon;
    @bindable confirmText;
    @bindable usePassword = false;
    @bindable sideIcon = '/chicks/chick_delete.svg' ;
    @bindable sideAlt = 'chick icon';
    @bindable handleConfirm;
    @bindable underlineConfirmButton = false;
    @bindable htmlContent;
    @bindable onCloseCallback = () => { };
    textClass = 'font-primary text-content text-start mb-0 font-light content text-black';
    step = 1;
    visiblePassword = false;

    attached() {
        this.handleClosing();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    confirm() {
        if (this.usePassword) {
            this.step = 2;
            return;
        }
        if (this.handleConfirm) {
            this.handleConfirm();
            return;
        }
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.step = 1;
        this.setValuesByName(['show', 'Sent', 'Function'], false);
        this.password = this.token = undefined;
        this.onCloseCallback();
    }

    setValuesByName = (names, value) => this.helper.setPropertiesByName(this, names, value);

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
    }

    toggleVisible() {
        this.visiblePassword = !this.visiblePassword;
    }

    async passwordUpdatedOnKeyPress(event) {
        if (!event?.keyCode && !this.autoFillTriggered) {
            return;
        }
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.passwordValid = this.passwordInvalid = this.showErrorCheckMarkPassword = this.showMiniSpinnerPassword = this.toastPasswordSent = this.firedFunction = false;
        await this.validator.reset();
        if (!this.password) return;
        this.miniSpinnerPasswordStopwatch = setTimeout(() => {
            this.showMiniSpinnerPassword = true;
        }, 1000);
        this.passwordStopWatch = setTimeout(async () => {
            ValidationRules
                .ensure('password').required().minLength(6).maxLength(100)
                .on(this);
            const rules = await this.validator.validate();
            const checkValidation = this.helper.validatorCheckOneCondition('password', rules.results);
            if (!checkValidation) {
                await this.validator.reset();
                this.passwordStopWatch2 = setTimeout(async () => {
                    ValidationRules
                        .ensure('password').required().minLength(6).maxLength(100)
                        .on(this);
                    const rules2 = await this.validator.validate();
                    this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                    this.passwordInvalid = !this.helper.validatorCheckOneCondition('password', rules2.results);
                    this.showErrorCheckMarkPassword = !this.passwordValid;
                    this.toastPasswordSent = true;
                    this.toastService.showToast('Error', 'Please enter a valid password.', 'error');
                }, 2000);
            } else if (checkValidation) {
                this.firedFunction = true;
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                await this.triggerAccountDeleteForPassword();
            }
            if (checkValidation && this.successFunction) {
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                this.passwordValid = true;
            }
        }, 2000);
    }

    async passwordUpdatedOnFocusIn() {
        this.passwordValid = this.passwordInvalid = this.showErrorCheckMarkPassword = this.showMiniSpinnerPassword = false;
        this.toastPasswordSent = false;
        this.validator.reset();

        this.passwordFocusInStopWatch = setTimeout(() => {
            if (this.password) {
                const event = {
                    keyCode: 'focusin'
                };
                this.passwordUpdatedOnKeyPress(event);
            }
        });
    }

    async checkPasswordValidation() {
        this.showMiniSpinnerPassword = false;
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!this.password) return;
        ValidationRules
            .ensure('password').required().minLength(6).maxLength(100)
            .on(this);
        const rules = await this.validator.validate();
        this.showErrorCheckMarkPassword = this.showMiniSpinnerPassword = false;
        const checkValidation = this.helper.validatorCheckOneCondition('password', rules.results);

        if ((this.password.length === 0 || !checkValidation) && !this.toastPasswordSent) {
            this.passwordInvalid = this.showErrorCheckMarkPassword = true;
            this.toastService.showToast('Error', 'Please enter a valid password.', 'error');
        }

        if (checkValidation && !this.firedFunction && this.user) {
            await this.triggerAccountDeleteForPassword();
        }

        if (checkValidation && this.successFunction) {
            this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
            this.passwordValid = true;
            return;
        }
        if (!this.successFunction) {
            this.passwordInvalid = this.showErrorCheckMarkPassword = true;
        }
    }

    async tokenUpdatedOnKeyPress(event) {
        if (!event?.keyCode) {
            return;
        }
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = this.showErrorCheckMarkPassword = this.toastTokenSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (!this.token) return;
        this.miniSpinnerTokenStopwatch = setTimeout(() => {
            this.showMiniSpinnerToken = true;
        }, 1000);
        this.tokenStopWatch = setTimeout(async () => {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            const rules = await this.validator.validate();
            const checkValidation = this.helper.validatorCheckOneCondition('token', rules.results);
            if (!checkValidation) {
                await this.validator.reset();
                this.tokenStopWatch2 = setTimeout(async () => {
                    ValidationRules
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                    const rules2 = await this.validator.validate();
                    this.showMiniSpinnerToken = false;
                    this.showErrorCheckMarkToken = !this.helper.validatorCheckOneCondition('token', rules2.results);
                    this.toastTokenSent = true;
                    this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
                }, 2000);
            } else if (checkValidation) {
                this.firedFunction = true;
                await this.triggerAccountDeleteForToken();
            }
            if (checkValidation && this.successFunction) {
                this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                this.showGreenCheckMarkToken = true;
            }
        }, 2000);
    }

    tokenUpdatedOnFocusIn() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastTokenSent = false;
        this.validator.reset();

        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.token !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.tokenUpdatedOnKeyPress(event);
            }
        });
    }

    async checkTokenValidation() {
        this.showMiniSpinnerToken = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            const rules = await this.validator.validate();
            this.showMiniSpinnerToken = false;
            const checkValidation = this.helper.validatorCheckOneCondition('token', rules.results);

            if (!checkValidation && !this.toastTokenSent) {
                this.showErrorCheckMarkToken = true;
                this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
            }

            if (checkValidation && !this.firedFunction) {
                await this.triggerAccountDeleteForToken();
            }

            if (checkValidation && this.successFunction) {
                this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                this.showGreenCheckMarkToken = true;
            } else {
                this.showErrorCheckMarkToken = true;
            }
        }
    }

    async triggerAccountDeleteForPassword() {
        const response = await this.sessionService.verifyPassword(this.password);
        if (response && this.user.twoFactorEnabled) {
            this.successFunction = true;
            this.step = 3;
        } else if (response && !this.user.twoFactorEnabled) {
            this.successFunction = true;
            this.deleteProfile(this.password);
        } else {
            this.toastService.showToast('Error', 'Failed to delete an account. Please review the entered password or contact support for assistance.', 'error');
            this.passwordValid = this.showMiniSpinnerPassword = this.successFunction = false;
            this.passwordInvalid = this.showErrorCheckMarkPassword = true;
        }
    }

    async triggerAccountDeleteForToken() {
        const response = await this.customerService.verify2FA(this.token);
        if (response) {
            this.successFunction = true;
            this.deleteProfile(this.password);
        } else {
            this.toastService.showToast('Error', 'Failed to delete an account. Please review the entered token or contact support for assistance.', 'error');
            this.showGreenCheckMarkToken = this.showMiniSpinnerToken = this.successFunction = false;
            this.showErrorCheckMarkToken = true;
        }
    }

    async deleteProfile(password) {
        try {
            const response = await this.customerService.deleteProfile(password);
            if (response && this.user) {
                this.toastService.showToast('Success!', 'Account deleted. As a result, you have been logged out. Sign-up again to log back in, or contact support.', 'success');
                await this.sessionService.logout(this.user);
            } else {
                this.toastService.showToast('Error', 'Failed to delete an account.', 'error');
            }
        } catch (e) {
            console.error(e);
            this.toastService.showToast('Error', 'Failed to delete an account.', 'error');
        }
    }

    @computedFrom('width', 'step', 'content')
    get contentText() {
        if (typeof (this.content) === 'string') return this.content;
        return this.contentByType();
    }

    @computedFrom('width', 'step', 'title')
    get titleText() {
        if (this.title) return this.title;
        return this.contentByType(DialogContentType.Title);
    }

    contentByType(type = DialogContentType.Content) {
        return this.content?.filter(x => x.step === this.step && (x.width ? this.width <= x.width : true) && x.type === type)
            .sort((a, b) => (b.width ?? 0) - (a.width ?? 0))[0]?.text;
    }

    @computedFrom('passwordValid', 'showErrorCheckMarkPassword', 'showMiniSpinnerPassword')
    get hasCheckMark() {
        return this.passwordValid || this.showErrorCheckMarkPassword || this.showMiniSpinnerPassword;
    }
}
