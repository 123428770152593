import './cx-places-autocomplete-input.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { ScriptService } from 'services/script-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BillingAddress } from 'services/models/user/billingAddress';

@autoinject()
export class CxPlacesAutocompleteInput {
    @bindable autocompletedAddress: string;
    @bindable placeholder = 'Enter Location';
    @bindable @observable loading = true;
    @bindable useParentHook = true;
    @bindable showIcon = true;
    @bindable suggestionPosition: 'TOP' | 'BOTTOM' = 'BOTTOM';
    @bindable inputClass = 'secondary-input';
    @bindable trailingIcon?: string = null;
    @bindable onFocus?: () => void;
    @bindable onBlur?: () => void;
    @bindable onInput?: (address: string, code?: string, detail?: BillingAddress) => void;

    location: HTMLElement;
    googleAutoCompleteContainer: HTMLDivElement;
    locationAutoComplete: google.maps.places.Autocomplete;
    storeBillingAddress: BillingAddress = {
        city: 'Mississauga',
        state: 'ON',
        countryCode: 'CA',
        countryName: 'Canada',
        zip: 'L5M 2G6',
        street: '2275 Britannia Rd W Unit # 3'
    };

    storeAddress = '2275 Britannia Rd W Unit # 3 Mississauga, ON L5M 2G6, Canada';
    lastAddress: string;
    parent;
    loaded: boolean = false;

    constructor(
        private scriptService: ScriptService,
        private eventAggregator: EventAggregator
    ) {
        this.scriptService.injectGoogleScript();
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async loadingChanged(newValue: boolean) {
        if (newValue) return;
        setTimeout(() => {
            this.loadGooglePlacesInput();
        }, 1000);
    }

    async loadGooglePlacesInput() {
        if (this.loaded) return;
        this.loaded = true;

        if (!this.locationAutoComplete) {
            this.locationAutoComplete = new google.maps.places.Autocomplete(this.location?.getElementsByClassName('mdc-text-field__input')[0] as HTMLInputElement, {});
        }

        setTimeout(() => {
            setTimeout(() => {
                const googlePlaces = document.querySelectorAll('.pac-container');
                const googleAutocompleteDiv = this.getSuggestionsContainer();

                googlePlaces.forEach((el) => {
                    if (el.lastElementChild) {
                        const address = this.getMinifiedAddress(el.lastElementChild.textContent);
                        if (address !== 'storefront2275britanniardwunit#3mississaugaonl5m2g6canada') this.lastAddress = address;
                        el.lastElementChild.classList.add('store-address');
                        el.lastElementChild.innerHTML = `<span class="material-icons-outlined">storefront</span><span class="pac-item-query"><span>${this.storeAddress}</span>`;
                    }

                    googleAutocompleteDiv?.replaceChildren(el);
                });
            }, 2000);

            if (!this.locationAutoComplete) return;

            google.maps.event.addListener(this.locationAutoComplete, 'place_changed', () => {
                const addressInput = this.location.getElementsByClassName('mdc-text-field__input')[0] as HTMLInputElement;
                const inputAddress = addressInput.value;

                if (this.getMinifiedAddress(inputAddress) === this.lastAddress) {
                    this.autocompletedAddress = this.storeAddress;
                    addressInput.value = this.storeAddress;
                    this.onInput?.(this.autocompletedAddress, 'address', this.storeBillingAddress);
                    return;
                }

                const addressData = this.locationAutoComplete.getPlace();
                if (!addressData) return;
                this.autocompletedAddress = (this.location.getElementsByClassName('mdc-text-field__input')[0] as HTMLInputElement).value;

                if (this.useParentHook) {
                    this.parent.location = addressData.formatted_address;
                    this.eventAggregator.publish('addressAutoCompleted', addressData);
                    return;
                }

                const billingInfo: BillingAddress = {
                    city: addressData.address_components?.find(x => x.types.find(y => y === 'locality'))?.long_name ?? addressData.address_components?.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.long_name,
                    state: addressData.address_components?.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.short_name,
                    countryCode: addressData.address_components?.find(x => x.types.find(y => y === 'country'))?.short_name,
                    countryName: addressData.address_components?.find(x => x.types.find(y => y === 'country'))?.long_name,
                    zip: addressData.address_components?.find(x => x.types.find(y => y === 'postal_code'))?.short_name,
                    street: addressData.name
                };

                this.onInput?.(this.autocompletedAddress, 'address', billingInfo);
            });
        }, 1000);
    }

    getMinifiedAddress(value: string) {
        return value.replaceAll(' ', '').replaceAll(',', '').replaceAll('.', '').toLowerCase();
    }

    getSuggestionsContainer() {
        if (this.suggestionPosition === 'BOTTOM') {
            return document.getElementById('google-autocomplete-places') as HTMLDivElement | null;
        }

        return this.googleAutoCompleteContainer?.querySelector('.place-suggestions-container') as HTMLDivElement | null;
    }

    updateLocation = () => {
        if (!this.useParentHook) {
            this.loadGooglePlacesInput();
            return;
        }

        if (this.autocompletedAddress.length === 0) {
            this.parent.location = undefined;
            this.parent.validateAddress();
        } else {
            this.parent.location = this.autocompletedAddress;
            setTimeout(() => {
                this.parent.validateAddress();
            }, 2000);
        }
    };

    handleFocus = () => {
        this.onFocus?.();
    };

    handleBlur = () => {
        this.onBlur?.();
    };

    handleInputClear = () => {
        this.autocompletedAddress = null;
    };

    get inputIcon() {
        if (this.trailingIcon) return this.trailingIcon;
        return this.showIcon ? 'map' : 'close';
    }
}
