import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { ProductCatWithGame } from './models/game/productCategory';

@autoinject()
export class ProductCategoryService {
    path = 'ProductCategory';
    navItems: ProductCatWithGame[];
    productCategories;

    constructor(private api: ApiService) {
    }

    async getAll() {
        if (this.productCategories) return this.productCategories;
        this.productCategories = await this.api.doGet(this.path);
        return this.productCategories;
    }

    async getNavCategory(category: string): Promise<ProductCatWithGame> {
        if (category) {
            if (!this.navItems) {
                this.navItems = await this.api.doGet(this.path + '/ForNav?websiteShortCode=CX');
            }
            return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
        } else {
            throw Error('Category given was null');
        }
    }
}
