// Module
var code = `<template>
    <section id="cx-try-again-box">
        <div class="box">
            <cx-dynamic-icon icon-class="rounded d-block img-link" icon.bind="icon" alt.bind="alt" outlined.bind="outlined" size.bind="size"></cx-dynamic-icon>
            <div class="text-center cx-try-again-box__container">
                <div class="expired">
                    <cx-page-content-area if.bind="useKeys" page-content-area key.bind="main"></cx-page-content-area>
                    <p else>\${main}</p>
                </div>
                <div class="content">
                    <cx-page-content-area if.bind="useKeys" class="default-anchor" page-content-area key.bind="subtitle"></cx-page-content-area>
                    <p class.bind="'d-none' |in: !subtitle || useKeys">
                        <slot name="subtitle">\${subtitle}</slot>
                        <span if.bind="showStatus" class="click-here-container default-anchor">  
                            <br if.bind="width <= 375" /> To see site status, 
                            <a href="https://status.chicksx.com" target="_blank" rel="nofollow noopener noreferrer">click here</a>.
                        </span>
                    </p>
                </div>
            </div>
            <div class="btn-content">
                <a
                    href.bind="href"
                    id="try-again-button"
                >
                    <cx-button
                        button-text="Try again"
                        button-style="primary-style btn-try px-5"
                    ></cx-button>
                </a>
            </div>
        </div>
    </section>
</template>
`;
// Exports
export default code;