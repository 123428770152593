import SimplebarOverride from 'resources/simplebar_override';

export abstract class FadingFunctionality {
    scrollContainer: HTMLElement;
    firstTimeInitialization: boolean;

    abstract scrollBottomReached();

    setScrollContainer(container: HTMLElement) {
        this.scrollContainer = container;
    }

    reinstanceScrollbar() {
        if (!this.scrollContainer) return;
        this.simpleBarOverride(this.scrollContainer);
    }

    getScrollableElement = (): undefined | HTMLElement => {
        return this.simpleBarInstance(this.scrollContainer);
    };

    createScrollEvent(overrideFirstTimeInitialization = false, offset = 20) {
        if (overrideFirstTimeInitialization) this.firstTimeInitialization = true;
        const scrollbar = this.getScrollableElement();
        if (!scrollbar) return;
        this.simpleBarScrollEvent(scrollbar, offset, overrideFirstTimeInitialization);
        if (overrideFirstTimeInitialization) this.firstTimeInitialization = false;
    }

    addScrollClass(value: number, offset: number, name = 'top') {
        this.scrollClassCheck(this.scrollContainer, value, offset, name);
    }

    simpleBarOverride = (container: HTMLElement) => {
        if (!container) return;
        SimplebarOverride.instances.get(container)?.unMount();
        new SimplebarOverride(container);
    };

    simpleBarInstance = (container: HTMLElement): undefined | HTMLElement => {
        if (!container) return;
        return SimplebarOverride.instances.get(container)?.contentWrapperEl;
    };

    simpleBarScrollEvent = (scrollbar: HTMLElement, offset: number, overrideFirstTimeInitialization: boolean) => {
        scrollbar?.addEventListener('scroll', () => {
            const containerMaxHeight = Number(window.getComputedStyle(this.scrollContainer).maxHeight.slice(0, -2));

            if (scrollbar.scrollHeight < containerMaxHeight) {
                this.scrollContainer.classList.add('touching-top', 'touching-bottom');
                return;
            }

            const scrollBottom = Math.abs(scrollbar.scrollHeight - scrollbar.scrollTop - scrollbar.clientHeight);

            if (scrollBottom === 0) this.scrollBottomReached();

            this.addScrollClass(scrollbar.scrollTop, offset, 'top');

            if (!overrideFirstTimeInitialization || (overrideFirstTimeInitialization && !this.firstTimeInitialization)) {
                this.addScrollClass(scrollBottom, offset, 'bottom');
            }
        });
        scrollbar?.dispatchEvent(new Event('scroll'));
    };

    scrollClassCheck = (container: HTMLElement, value: number, offset: number, name: string) => {
        if (!container) return;
        container?.classList[value > offset ? 'remove' : 'add'](`touching-${name}`);
    };
}
