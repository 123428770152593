import './cx-text-input.scss';
import { bindable } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SpecialSelectorEvent } from 'resources/constants';
import { ISpecialSelectorEvent } from 'types/events';

export interface ICxTextInput {
    class?: string;
    inputStyle?: string;
    value?: string;
    label?: string;
    keyUpFunction?: (value: string, name: string) => void;
    inputFunction?: (value: string, name: string) => void;
    focusinFunction?: (input: ICxTextInput) => void;
    focusoutFunction?: (input: ICxTextInput) => void;
    type: 'number' | 'text' | 'password' | 'address' | 'date' | 'time';
    leadingIcon?: string;
    leadingText?: string;
    floatingLabel?: boolean;
    name: string;
    showSuccess?: boolean;
    showError?: boolean;
    trailingIcon?: string;
    hasDropdown?: boolean;
    dropdownOptions: string[];
    inlineInputs: ICxTextInput[];
    id?: string;
    inline: boolean;

    get outlineStyle(): string;
    get inputTrailingIcon(): string;
}

@autoinject()
export class CxTextInput {
    @bindable class = '';
    @bindable inputStyle = '';
    @bindable value: string;
    @bindable label = '';
    @bindable buttonIcon: string;
    @bindable buttonIconFunction?: () => void;
    @bindable keyUpFunction?: (value: string, code?: string) => void;
    @bindable onInput?: (value: string, code?: string) => void;
    @bindable type: 'number' | 'text' | 'password' | 'address' | 'date' | 'time' = 'text';
    @bindable leadingIcon: string;
    @bindable outlined: boolean = true;
    @bindable leadingText: string;
    @bindable floatingLabel = false;
    @bindable additionalInputs?: ICxTextInput[];
    @bindable triggerCode?: string;
    @bindable trailingIcon?: string;
    @bindable inputDisabled: boolean = false;
    @bindable placeholder?: string;
    @bindable onFocus?: () => void;
    @bindable onBlur?: () => void;
    @bindable disableAutocomplete: boolean = false;
    @bindable name: string;
    @bindable loading = false;

    mainInputFocused: boolean = false;
    datePickerInstance: Instance;
    mainInputField: HTMLInputElement;

    constructor(
        private eventAggregator: EventAggregator
    ) {}


    attached() {
        if (this.type !== 'date') return;
        this.attachDatePicked();
    }

    attachDatePicked() {
        this.datePickerInstance = flatpickr(this.mainInputField, {
            minDate: 'today',
            disableMobile: true,
            onChange: (_selectedDates, dateStr, _) => {
                this.onInput(dateStr, this.triggerCode);
            },
            onOpen: () => {
                this.sendSelectorEvent(true);
            },
            onClose: () => {
                this.sendSelectorEvent(false);
            }
        });
    }

    sendSelectorEvent(focused: boolean) {
        this.eventAggregator.publish(SpecialSelectorEvent, { focused } as ISpecialSelectorEvent);
    }

    handleKeyUp(ev: Event) {
        const target = ev.target as HTMLInputElement;
        this.keyUpFunction?.(target.value, this.triggerCode);
    }

    handleInput(ev: Event) {
        const target = ev.target as HTMLInputElement;
        this.onInput?.(target.value, this.triggerCode);
    }

    handleAdditionalKeyUp(ev: Event, input: ICxTextInput) {
        const target = ev.target as HTMLInputElement;
        input.keyUpFunction?.(target.value, input.name);
    }

    handleAdditionalInput(ev: Event, input: ICxTextInput) {
        const target = ev.target as HTMLInputElement;
        input.inputFunction?.(target.value, input.name);
    }

    handleFocusIn(ev: FocusEvent, input?: ICxTextInput) {
        if (input) {
            input.focusinFunction?.(input);
            return;
        }

        if (this.type !== 'date') this.mainInputFocused = true;
        this.onFocus?.();
        if (!this.datePickerInstance) return;
        this.datePickerInstance.open(ev, this.mainInputField);
    }

    handleFocusOut(_: Event, input?: ICxTextInput) {
        if (input) {
            input.focusoutFunction?.(input);
            return;
        }

        if (this.type !== 'date') this.mainInputFocused = false;
        this.onBlur?.();
    }

    trailingIconClicked = () => {
        if (this.trailingIcon !== 'close') return;
        this.buttonIconFunction?.();
    };

    get inputLabel() {
        return !this.outlined || this.floatingLabel ? this.label : '';
    }

    get inputPlaceholder() {
        if (this.placeholder) return this.placeholder;
        return this.outlined && !this.floatingLabel ? this.label : '';
    }

    get autocompleteType() {
        return this.disableAutocomplete ? this.type : 'one-time-code';
    }

    get showTrailingIcon() {
        const mainCondition = this.trailingIcon && !this.additionalInputs?.length && !this.loading;

        if (this.trailingIcon === 'close') {
            return mainCondition && Boolean(this.value);
        }

        return mainCondition;
    }
}
