// Module
var code = `<template>
    <section id="cx-faq-collapse-\${collapse.id}" ref="collapseRef" class="cx-faq-collapse">
        <div class="collapse-container">
            <button click.trigger="onCollapseClick()" ref="collapseRef" type="button" class="collapse-button">
                <div class="collapse-header-container">
                    <img if.bind="!isStaticAsset" src="\${baseAwsUrl}\${iconName}"
                        class="category-card-image \${active ? 'text-white' : ''}" alt="faq category image">
                    <h2 class="collapse-question">\${header}</h2>
                    <span class="material-icons open-icon" element.ref="icon">\${isOpen ? "arrow_drop_up" :
                        "arrow_drop_down"}</span>
                </div>
            </button>
        </div>
        <div class="collapse-content" ref="collapseContent">
            <div class="collapse-wrapper">
                <slot></slot>
            </div>
        </div>
    </section>
</template>`;
// Exports
export default code;