// Module
var code = `<template>
    <section id="orders">
        <cx-section
            title.bind="'CX_ORDERS' | siteStrings"
            description.bind="'CX_MANAGE_ORDERS' | siteStrings"
            content-loading.bind="contentLoading">
            <div slot="content" class="options">
                <cx-option-box
                    title.bind="'CX_BUY' | siteStrings"
                    description.bind="buyDescription & async"
                    icon="shopping_bag"
                    outlined.bind="true"
                    handle-click.bind="countByType[OrderOperationType.Buy] > 0 ? redirectTo : null | call: 'purchased'"
                    skeleton-loading="is-loading.bind:contentLoading;">
                </cx-option-box>
                <cx-option-box
                    title.bind="'CX_SELL' | siteStrings"
                    description.bind="sellDescription & async"
                    icon="local_offer"
                    outlined.bind="true"
                    handle-click.bind="countByType[OrderOperationType.Sell] > 0 ? redirectTo : null | call: 'sold'"
                    skeleton-loading="is-loading.bind:contentLoading;">
                </cx-option-box>
                <cx-option-box
                    title.bind="'CX_SWAP' | siteStrings"
                    description.bind="exchangeDescription & async"
                    icon="swap_horizontal_circle"
                    outlined.bind="true"
                    handle-click.bind="countByType[OrderOperationType.Exchange] > 0 ? redirectTo : null | call: 'exchange'"
                    skeleton-loading="is-loading.bind:contentLoading;">
                </cx-option-box>
            </div>
        </cx-section>
    </section>
</template>
`;
// Exports
export default code;