import './cx-paragraph-image.scss';
import { bindable } from 'aurelia-framework';

export class CxParagraphImage {
    @bindable caption;
    @bindable class;
    @bindable text;
    @bindable image;
    @bindable icon;
    @bindable textFirst;
    @bindable invertedColor;
    @bindable containerClass;
}
