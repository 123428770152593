import './trade-currency.scss';
import { autoinject } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageContentAreaService } from 'services/page-content-area-service';
import { CurrencyChangedEvent, DesktopBreakpoint, PhoneBreakpoint, SizeChangedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { Router } from 'aurelia-router';
import { Helper } from 'resources/helpers/helper';
import { ProductCategoryService } from 'services/product-category-service';
import { ProductCatWithGame } from 'services/models/game/productCategory';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { SessionService } from 'services/session-service';
import { CoinbaseService } from 'services/coinbase-service';
import { DataCurrencyStats } from 'services/models/currency/dataStatsCurrenciesInterface';
import { CustomerService } from 'services/customer-service';

@autoinject()
export class TradeCurrency {
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        public pageContentAreaService: PageContentAreaService,
        private productCategoryService: ProductCategoryService,
        public websiteService: WebsiteService,
        private router: Router,
        private currencyService: CurrencyService,
        private sessionService: SessionService,
        private coinbaseService: CoinbaseService,
        private customerService: CustomerService
    ) { }

    pageLoading: boolean = true;
    loadingSubscription: Subscription;
    adminViewSubscription: Subscription;
    widthSubscriber: Subscription;
    routeChangeSuccessSubscription: Subscription;
    listContent = [];
    infoQuadCardsData = [];
    isLoading = true;
    viewingAsAdmin = false;
    pageContentArea: PageContentArea[] = [];
    deviceType: string = 'DESKTOP';
    width: number;
    key: string;
    suffix: string = 'TRADE_PAGE';
    defaultSuffix: string = 'TRADE_PAGE';
    exchangeInfo: {type: string, subtype: string, exchange: string} = { type: 'buy', subtype: null, exchange: null };
    pageLoadedSubscriber: Subscription;
    tradeCategory: ProductCatWithGame;
    cryptoList: Currency[] = [];
    availableCurrencies: Currency[];
    currencyBase: Currency;
    currencyRates: { [key: string]: number; };
    currencies: string[];
    mostPopularCurrencies = [];
    dataStatsCurrencies: DataCurrencyStats[];
    currencyChangeSubscription: Subscription;
    timer;
    contentDefault: string;
    autoRestart: boolean;
    faqPageRoute: string;
    learnMoreCards = [
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_1',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_1',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_1',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_2',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_2',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_2',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_3',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_3',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_3',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_4',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_4',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_4',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        }
    ];

    async activate(params, _routeConfig, navigationInstruction) {
        const { config, router } = navigationInstruction;
        if (params?.params) {
            this.tradeCategory = await this.productCategoryService.getNavCategory('trade');
            const routeData = this.tradeCategory.gameForNav.find(x => x.slug[0] === params.params);
            if (routeData) {
                _routeConfig.navModel.settings.metaDescription = routeData.metaDescription;
                _routeConfig.navModel.title = routeData.title;
            }
            const routeFromParams = params.params.replaceAll('-', '');
            this.key = `TRADE_${routeFromParams.toUpperCase()}`;

        }
        else if (config?.settings?.keyName) this.key = config.settings.keyName;
        else this.key = `TRADE_${router.baseUrl.replaceAll('-', '').split('/').find(x => x !== '').toUpperCase()}`;
        const parts = router.baseUrl.replaceAll('-', '').split('/').filter(Boolean);
        const [type, subtype, exchange] = parts;
        this.exchangeInfo = {
            type,
            subtype: router.baseUrl.includes('swap') ? subtype : null,
            exchange: router.baseUrl.includes('swap') ? exchange : subtype
        };

        this.suffix = this.key ?? this.suffix;
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        const pageId = pages.find(x => x.name === 'Trade')?.id;
        this.pageContentArea = await this.pageContentAreaService.getByPageId(pageId);
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('trade-currency');
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.setCurrentSize();
            await this.loadInitialData();
            await this.loadAdditionalData();
            this.handleEventSubscriptions();
        } finally {
            this.helper.validateLoading('trade-currency');
        }
    }

    async loadInitialData() {
        await this.websiteService.getManyRoutes(this, ['faq', 'trade']);
        this.cryptoList = (await this.currencyService.getCryptoForSelector()).slice(0, 10);

        const [availableCurrencies, currentCurrency, currencyRates] = await Promise.all([
            this.currencyService.getActiveCurrenciesByWebsite(),
            this.sessionService.getCurrency(),
            this.coinbaseService.getExchangeRates('USD')
        ]);
        this.availableCurrencies = availableCurrencies;
        this.currencyBase = this.availableCurrencies.find(currency => currency.code === currentCurrency);
        this.currencyRates = currencyRates.data.rates;
    }

    async loadAdditionalData() {
        this.currencies = ['BTC', 'ETH', 'SOL', 'ADA'];

        [this.mostPopularCurrencies, this.dataStatsCurrencies] = await Promise.all([
            this.currencyService.getMultipleCurrencyStatsByCode(this.currencies),
            this.currencyService.getStatsDataCurrency(this.currencies)
        ]);

        this.mostPopularCurrencies.map(currency => {
            currency.priceUSD = currency.price;
            currency.price = currency.price * this.currencyRates[this.currencyBase.code];
            currency.base = this.currencyBase;
        });

        this.learnMoreCards = await Promise.all(this.learnMoreCards.map(async (card) => {
            let cardParam = (await this.pageContentAreaService.getByKey(card.redirectParam))?.markup;
            cardParam = this.helper.removeHtmlElements(cardParam);
            return {
                ...card,
                redirectRoute: cardParam ? `${this.faqPageRoute}?card=${cardParam}` : this.faqPageRoute
            };
        }));
    }

    detached() {
        this.loadingSubscription?.dispose();
        this.adminViewSubscription?.dispose();
        this.routeChangeSuccessSubscription?.dispose();
        this.widthSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.currencyChangeSubscription?.dispose();
        this.timer?.au.controller.viewModel.stop();
    }

    bind() {
        this.buildContent();
    }

    handleEventSubscriptions() {
        this.loadingSubscription = this.eventAggregator.subscribe('finished-loading', () => {
            this.isLoading = false;
        });

        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });

        this.widthSubscriber = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
            this.setCurrentSize();
        });

        this.routeChangeSuccessSubscription = this.eventAggregator.subscribe('router:navigation:success', payload => {
            if (payload.instruction?.params?.params) this.key = `TRADE_${payload.instruction?.params?.params.replaceAll('-', '').toUpperCase()}`;
            else this.key = `TRADE_${payload.instruction?.fragment.replaceAll('-', '').split('/').find(x => x !== '')?.toUpperCase()}`;

            this.suffix = this.key ?? this.suffix;
            this.buildContent();
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });

        this.currencyChangeSubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            this.currencyBase = payload.currencySelected;
            this.timer?.au.controller.viewModel.resetCountdown();
        });
    }

    buildContent() {
        this.listContent = [
            {
                iconPath: 'account_balance_wallet',
                header: `${this.suffix}_LC_FIRST_TITLE`,
                body: `${this.suffix}_LC_FIRST_PARAGRAPH`,
                alt: 'Balance wallet icon',
                alternativeHeader: `${this.defaultSuffix}_LC_FIRST_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_FIRST_PARAGRAPH`
            },
            {
                iconPath: 'shopping_cart',
                header: `${this.suffix}_LC_SECOND_TITLE`,
                body: `${this.suffix}_LC_SECOND_PARAGRAPH`,
                alt: 'Shopping cart icon',
                alternativeHeader: `${this.defaultSuffix}_LC_SECOND_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_SECOND_PARAGRAPH`
            },
            {
                iconPath: 'monetization_on',
                header: `${this.suffix}_LC_THIRD_TITLE`,
                body: `${this.suffix}_LC_THIRD_PARAGRAPH`,
                alt: 'Monetization icon',
                alternativeHeader: `${this.defaultSuffix}_LC_THIRD_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_THIRD_PARAGRAPH`
            }
        ];

        this.infoQuadCardsData = [
            {
                iconPath: `${this.suffix}_QC_FIRST_IMAGE`,
                title: `${this.suffix}_QC_FIRST_TITLE`,
                text: `${this.suffix}_QC_FIRST_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_FIRST_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_FIRST_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_FIRST_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_SECOND_IMAGE`,
                title: `${this.suffix}_QC_SECOND_TITLE`,
                text: `${this.suffix}_QC_SECOND_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_SECOND_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_SECOND_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_SECOND_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_THIRD_IMAGE`,
                title: `${this.suffix}_QC_THIRD_TITLE`,
                text: `${this.suffix}_QC_THIRD_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_THIRD_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_THIRD_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_THIRD_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_FOURTH_IMAGE`,
                title: `${this.suffix}_QC_FOURTH_TITLE`,
                text: `${this.suffix}_QC_FOURTH_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_FOURTH_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_FOURTH_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_FOURTH_PARAGRAPH`
            }
        ];

        this.contentDefault = null;

        if (!this.exchangeInfo.exchange) {
            const suffixDropdownContent = `TRADE_${ this.exchangeInfo.type.toUpperCase() }${ this.exchangeInfo.subtype ? '_' + this.exchangeInfo.subtype.toUpperCase() : '' }_DROPDOWN_CONTENT`;
            this.contentDefault = suffixDropdownContent;
        }
    }

    shouldRenderContent(keys: string | string[]): boolean {
        keys = [keys].flat();

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const finalKey = key.includes(this.suffix) ? key : `${this.suffix}${key}`;
            const defaultKey = finalKey.replace(this.suffix, this.defaultSuffix);
            const foundContent = this.pageContentArea?.find(x => x.key === finalKey) || this.pageContentArea?.find(x => x.key === defaultKey);
            const isContentImage = (finalKey.includes('IMAGE') || defaultKey.includes('IMAGE')) && foundContent?.imagePath;

            if (!foundContent || !foundContent?.markup && !isContentImage) {
                return false;
            }
        }
        return true;
    }

    setCurrentSize = (): string => this.deviceType = this.width <= PhoneBreakpoint ? 'MOBILE' : this.width <= DesktopBreakpoint ? 'TABLET' : 'DESKTOP';

    async countdownFinished() {
        try {
            this.mostPopularCurrencies = await this.currencyService.getMultipleCurrencyStatsByCode(this.currencies);
            this.mostPopularCurrencies.map(currency => {
                currency.priceUSD = currency.price;
                currency.price = currency.price * this.currencyRates[this.currencyBase.code];
                currency.base = this.currencyBase;
            });
        } catch (e) {
            this.autoRestart = false;
        }
    }

    async goToLearnMore(e) {
        await this.router.navigate(e);
    }

    async goToBuySell(crypto: string) {
        this.customerService.saveCurrencyPriceCode(crypto);
    }
}
