// Module
var code = `<template>
    <div id="cx-back-button" class="\${primaryClass}">
        <a
            class="return text-start text-cx-purple font-medium d-inline-flex cursor-pointer"
            click.delegate="onClick()"
            skeleton-loading="is-loading.bind:pageLoading;"
        >
            <div class="flex-center d-inline-flex chevron_container">
                <span class="material-icons back-button rounded-circle">
                    chevron_left
                </span>
            </div>
            <span class="back-text">\${'CX_BACK' | siteStrings}</span>
        </a>
    </div>
</template>
`;
// Exports
export default code;