// Module
var code = `<template>
    <div id="cx-breadcrumbs">
        <nav class="w-100">
            <ul class="\${class}">
                <li repeat.for="crumb of breadcrumbsWithStatus" class="d-flex">
                    <span class="crumb-wrapper" if.bind="!crumb.isActive">
                        <a if.bind="\$last && crumb.tooltip" href.bind="crumb.href" class="text-medium font-bold d-block text-truncate" mdc-tooltip="value.bind: crumb.title;">\${crumb.titleKey | siteStrings:crumb.title}</a>
                        <a else href.bind="crumb.href" class="crumb text-medium \${\$last ? 'font-bold' : 'crumb-effect font-light'} \${\$first ? 'bread-effect' : ''} \${forceParentFunction ? 'cursor-pointer' : ''} d-none d-md-block" click.delegate="forceParentFunction ? changeRouteByParentFunction() : ''">\${crumb.titleKey | siteStrings:crumb.title}</a>
                        <a if.bind="!\$last" href.bind="crumb.href" class="text-medium font-light \${forceParentFunction ? 'cursor-pointer' : ''} d-block d-md-none" click.delegate="forceParentFunction ? changeRouteByParentFunction() : ''">\${crumb.titleKey | siteStrings:crumb.title}</a>
                        <a if.bind="\$last && !crumb.tooltip" href.bind="crumb.href" class="text-medium font-bold d-block d-md-none text-truncate">\${crumb.titleKey | siteStrings:crumb.title}</a>
                    </span>
                    <span else class="crumb-wrapper text-medium font-bold text-truncate">\${crumb.titleKey | siteStrings:crumb.title}</span>
                    <span if.bind="!\$last" class="material-icons">keyboard_arrow_right</span>
                </li>
            </ul>
        </nav>
    </div>
</template>
`;
// Exports
export default code;