// Module
var code = `<template>
    <article id="cx-faq-question-section-\${questionId}" class="cx-faq-question-section">
        <div class="collapse-action-container">
            <button click.trigger="onCollapseClick()" ref="collapseRef" type="button" class="collapse-question ">
                <div clas="flex gap-10">
                    <div class="question-header-container">
                        <h2 class="question" ref="questionHeaderRef">\${question}</h2>
                        <span class="material-icons open-icon" element.ref="icon">\${isOpen ? "remove" : "add"}</span>
                    </div>
                    <div data-simplebar class="question-content purple-scrollbar" ref="questionContentRef">
                        <p class="question-answer" ref="questionAnswerRef">\${answer}</p>
                    </div>
                </div>
            </button>
        </div>
    </article>
</template>`;
// Exports
export default code;