export enum PaymentRequestCategory {
    Currency = 1,
    Items = 2,
    Services = 3,
    Accounts = 4,
    Transfers = 5,
    BalanceRefill = 6,
    Refund = 7,
    PartialRefund = 8,
    BalanceWithdraw = 9,
    BalanceRefund = 10,
    BalancePartialRefund = 11,
}
