
import { autoinject } from 'aurelia-framework';
import {
    NuveiAddCreditCard,
    NuveiEditCreditCard,
    NuveiDeleteCreditCard,
    NuveiAddUPOCreditCardResponse, NuveiPaymentMethod
} from './models/purchase-flow/exchange';
import { ApiService } from './api-service';

@autoinject()
export class NuveiService {
    path = 'Nuvei';

    constructor (private api: ApiService) {}

    async addCreditCard (data: NuveiAddCreditCard): Promise<NuveiAddUPOCreditCardResponse | null> {
        return await this.api.doPost(`${ this.path }/addCreditCard`, data);
    }

    async getVaultedCards() {
        return await this.api.doGet(`${ this.path }/vaultedCards`) as NuveiPaymentMethod[] | null;
    }

    async editCreditCard(data: NuveiEditCreditCard) {
        return await this.api.doPost(`${ this.path }/editCreditCard`, data);
    }

    async deleteCreditCard(data: NuveiDeleteCreditCard) {
        return await this.api.doPost(`${ this.path }/deleteCreditCard`, data);
    }
}
