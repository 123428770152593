import './payment-details.scss';
import '../subscription.scss';
import { autoinject, observable, TaskQueue } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExtendedUser } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { SubscriptionService } from 'services/subscription-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { UserSubscription } from 'resources/extensions/user_subscription';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SubscriptionOptions, SubscriptionSections } from 'services/models/subscription/subscription-enums';
import { RowButtonsOption } from 'resources/elements/cx-row-buttons/cx-row-buttons';
import { WebsiteService } from 'services/website-service';
import PaymentPlan from 'services/models/subscription/payment-plan';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/exchange';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';
import { CurrencyChangedEvent, SizeChangedEvent } from 'resources/constants';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
interface CdsTabItem {
    label: string;
    id: string;
    value: SubscriptionOptions;
}

@autoinject()
export class PaymentDetails extends UserSubscription {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        protected toastService: ToastService,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private fiatCurrencyFormatValueConverter: FiatCurrencyFormatValueConverter,
        private taskQueue: TaskQueue,
        sessionService: SessionService,
        subscriptionService: SubscriptionService,
        helper: Helper,
        eventAggregator: EventAggregator,
        pageContentArea: PageContentAreaService,
    ) {
        super(eventAggregator, helper, subscriptionService, pageContentArea, sessionService);
        this.helper.widthHandler(this);
    }

    @observable optionButtonSelected: RowButtonsOption;
    @observable paymentMethodInitial: PaymentMethodWebsite;
    @observable paymentMethodBackup: PaymentMethodWebsite;

    user: ExtendedUser;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    currencyUpdateSubscriber: Subscription;
    sections: typeof SubscriptionSections;
    configureMethods: boolean = false;
    backRoute: string;
    faqPageRoute: string;
    preferredCurrency: string;
    option: SubscriptionOptions;
    optionData: PaymentPlan;
    renewalMethods = ['checkout', 'balance', 'google-pay-direct', 'apple-pay-direct'];
    sizeChangedSubscriber: Subscription;
    priceFormatted: string;
    tabsElement;
    tabs: CdsTabItem[] = [
        { label: this.siteStringsValueConverter.toView('CX_MONTHLY', 'Monthly'), id: `subscription-op-${SubscriptionOptions.Monthly}`, value: SubscriptionOptions.Monthly },
        { label: this.siteStringsValueConverter.toView('CX_ANNUAL', 'Annual'), id: `subscription-op-${SubscriptionOptions.Annual}`, value: SubscriptionOptions.Annual }
    ];

    async activate() {
        this.user = await this.sessionService.checkFreeTrialAndGetUser() as ExtendedUser;
        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }
        await super.getSubscription();
        await super.initSubscriptionData(true);
        await super.getActiveSubscription(this.user);
        this.option = this.userSubscriptionInfo?.paymentPlan ?? SubscriptionOptions.Monthly;
        this.priceFormatted = await this.fiatCurrencyFormatValueConverter.toView(this.userSubscriptionInfo.price, null, this.preferredCurrency);
    }

    deactivate() {
        this.pageLoadedSubscriber?.dispose();
        this.currencyUpdateSubscriber?.dispose();
        this.sizeChangedSubscriber?.dispose();
    }

    async attached() {
        try {
            this.sections = SubscriptionSections;
            this.helper.addLoadingComponent('payment-details');
            this.user = this.user ?? await this.sessionService.getProfile() as ExtendedUser;
            if (!this.user) {
                this.router.navigateToRoute('home');
                return;
            }
            this.handleEventSubscriptions();
            this.backRoute = super.getBackRoute(this.sections.Details);
            this.faqPageRoute = await this.websiteService.getRouteOrDefault('Faq', 'faq');
            this.setOptionData();
        } finally {
            this.taskQueue.queueTask(() => {
                const selectedTabIndex = this.tabs.findIndex(tab => tab.value === this.option);
                if (this.tabsElement) this.tabsElement.selectedIndex = selectedTabIndex;
            });
            this.helper.validateLoading('payment-details');
        }
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });

        this.currencyUpdateSubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, async payload => {
            this.preferredCurrency = payload.currencySelected.originalCode;
            this.sessionService.saveCurrency(this.preferredCurrency);
            this.priceFormatted = await this.fiatCurrencyFormatValueConverter.toView(this.userSubscriptionInfo?.price, null, this.preferredCurrency);
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe(SizeChangedEvent, () => {
            this.helper.widthHandler(this);
        });
    }

    async setOptionData() {
        if (!this.option || !this.types) return;
        this.optionData = this.types[this.option];
        if (this.pageLoading) return;
        const renewalDate = this.dateFormatterValueConverter.toView(this.userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy');
        await super.changePaymentPlan(this.option, this.user, this.optionData, renewalDate);
    }

    setTab(event: CustomEvent) {
        this.option = this.tabs[event.detail.index].value;
        this.setOptionData();
    }

    setConfigure = (val = true) => this.configureMethods = val;

    async savePaymentMethods() {
        if (!this.userSubscriptionInfo || !this.paymentMethodInitial || !this.paymentMethodBackup || !this.configureMethods) return;
        await this.subscriptionService.updateSubscriber({
            subscriptionId: this.userSubscriptionInfo.subscriptionId,
            userId: this.userSubscriptionInfo.userId,
            paymentPlan: null,
            mainPaymentMethodId: this.paymentMethodInitial.paymentMethod.id,
            backupPaymentMethodId: this.paymentMethodBackup.paymentMethod.id,
            mainCardLastDigits: this.paymentMethodInitial.paymentMethod.usesCard ? this.paymentMethodInitial.paymentMethod.cardInfo?.lastDigits : null,
            backupCardLastDigits: this.paymentMethodBackup.paymentMethod.usesCard ? this.paymentMethodBackup.paymentMethod.cardInfo?.lastDigits : null
        });
        await this.updateData(this.user);
    }

    clickOnlyChild = (ev) => ev.stopPropagation();

    paymentMethodBackupChanged() {
        this.checkRenewalMethods();
    }

    paymentMethodInitialChanged() {
        this.checkRenewalMethods();
    }

    checkRenewalMethods() {
        if (!this.user?.isSubscribed) return;
        this.helper.actionAfterCondition(this, 'methodsLoaded',
            () => Boolean(this.paymentMethodBackup) && Boolean(this.paymentMethodInitial),
            () => {
                this.savePaymentMethods();
                const isBackUp = this.isRenewalMethod(this.paymentMethodBackup);
                const isMain = this.isRenewalMethod(this.paymentMethodInitial);
                if (isBackUp || isMain) return;
                this.toastService.showToast('Info', 'The primary payment method selected doesn\'t have auto-renewal. Please select debit/credit or balance as a backup payment method.', ToastType.INFO);
            });
    }

    isRenewalMethod = (method) => this.helper.includesSome(this.getReference(method), this.renewalMethods);

    getReference = (method) => method?.paymentMethod?.reference;

    async setRenewalFunction() {
        if (!this.userSubscriptionInfo) return;
        this.user = await super.setRenewal(this.user);
    }

    async currentPlanDescription() {
        const subscriptionRenewal = this.dateFormatterValueConverter.toView(this.userSubscriptionInfo.renewalDate, 'MMMM Do, YYYY');
        const subscriptionPrice = await this.fiatCurrencyFormatValueConverter.toView(this.userSubscriptionInfo.price, null, this.preferredCurrency);
        return this.siteStringsValueConverter.toView('CX_CHANGE_CURRENT_PLAN_DESCRIPTION', null, [subscriptionRenewal, subscriptionPrice]);
    }
}
