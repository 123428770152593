import './cx-user-avatar.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { User } from 'services/models/user/user';
import { apiEndpoint } from 'environment';


@autoinject()
export class CxUserAvatar {
    @bindable user: User;
    @bindable size = 29;
    @bindable border = 1;
    @bindable isEdit = false;
    @bindable forceSubscribe = false;
    @bindable useSpacing = false;
    @bindable isProfile = false;
    @bindable useIconSize = false;
    environment = apiEndpoint();
    otherAvatarDialogShow = false;
    avatarDialogModule;
    mainContainer;
    avatarModalSubscriber: Subscription;

    constructor(private eventAggregator: EventAggregator) {}

    attached() {
        this.handleEventSubscriptions();
        this.setSize();
    }

    handleEventSubscriptions() {
        this.avatarModalSubscriber = this.eventAggregator.subscribe('avatar-dialog-open-modal', async() => {
            this.handleAvatarDialogOpen();
        });
    }

    setSize() {
        this.setProperty('--iconSize', this.size);
    }

    setProperty = (property, value) => this.mainContainer?.style.setProperty(property, value + 'px');

    handleAvatarDialogOpen() {
        if (!this.isEdit) return;
        if (!this.otherAvatarDialogShow) {
            this.avatarDialogModule.dialog.open();
        }
    }
}
