// Module
var code = `<template>
    <div id="cx-customer-portal-navigation" ref="element">
        <div id="navigation-pages" class="navigation-pages text-regular \${'is-dropdown' |in: isDropdown}" ref="navigationPagesElement">
            <ul class="navigation-container">
                <li repeat.for="option of options"
                    show.bind="option.activeNavigation && shouldShowOption(option, width)"
                    class="position-relative item \${option.optionClass}"
                    skeleton-loading="is-loading.bind:contentLoading && isDesktop;width:100%">
                    <cx-navbar-option
                        view-model.ref="optionEl"
                        option.bind="option"
                        handle-click.bind="handleClick.bind(\$this)"
                        is-sticky.bind="isSticky">
                    </cx-navbar-option>
                    <div class="other-options">
                        <ul if.bind="optionEl.hasChildren" class="style-orders options-expanded-container \${!optionEl.isForceShow  ? 'hidden' : ''} options-children-\${option.option}">
                            <li repeat.for="child of option.orderedChildren" class="child item position-relative \${child.isActive ? 'active' : ''}">
                                <cx-navbar-option
                                    option.bind="child"
                                    handle-click.bind="handleClick.bind(\$this)"
                                    is-sticky.bind="isSticky">
                                </cx-navbar-option>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div if.bind="!isSticky && !isDropdown && currentRoute.includes('verification')" class="progress-bar-container">
            <cx-progress-bar
                is-loading.bind="contentLoading && isDesktop"
                num-steps.bind="3"
                is-vertical.bind="'true'"
                user.bind="user"
            ></cx-progress-bar>
        </div>
    </div>
</template>
`;
// Exports
export default code;