import './cx-faq-collapse.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { isStaticAsset as isStaticAssetRegExp } from 'resources/constants';

export class CxFaqCollapse {
    private collapseContent;
    @bindable isOpen;
    @bindable defaultIsOpen:boolean;
    @bindable header:string;
    @bindable collapse;
    @bindable questions:string[];
    @bindable iconName: string;
    @bindable children:boolean;
    @bindable child:boolean;
    resizeObserver;
    baseAwsUrl: string;
    isStaticAsset = true;

    constructor() {
        this.baseAwsUrl = getAWSBucketEndpoint('icons');
    }

    attached() {
        this.isStaticAsset = isStaticAssetRegExp().test(this.iconName);
        if (this.defaultIsOpen) this.openDefault();
    }

    openDefault() {
        const content = this.collapseContent;
        content.style.gridTemplateRows = '1fr';
        this.isOpen = this.defaultIsOpen;
    }

    onCollapseClick() {
        this.isOpen = !this.isOpen;
        const content = this.collapseContent;

        if (!this.isOpen) {
            content.style.gridTemplateRows = '0fr';
        } else {
            content.style.gridTemplateRows = '1fr';
        }
    }

}

