// Module
var code = `<template>
    <div if.bind="isVertical" id="cx-progress-bar">
        <div class="cx-progress-bar-vertical ms-sm-3">
            <mdc-linear-progress
                class="cx-linear-progress_success \${isLoading ? 'opacity-0' : ''}"
                open.bind="true"
                progress.bind="verificationProgress"
                buffer="1"
            ></mdc-linear-progress>
            <div class="d-flex justify-content-start">
                <ul class="d-flex flex-column position-relative me-2">
                    <li skeleton-loading="is-loading.bind:isLoading;" class="step rounded-pill position-absolute d-flex align-items-center justify-content-center \${user.phoneNumberConfirmed ? 'step-verification_success' : 'step-active'} material-icons">
                        <span class="step-number"></span>
                    </li>
                    <li skeleton-loading="is-loading.bind:isLoading;" class="step rounded-pill position-absolute d-flex align-items-center justify-content-center \${user.emailConfirmed && user.idVerified ? 'step-verification_success' : 'step-disabled'} \${user.phoneNumberConfirmed ? 'step-active' : ''} material-icons">
                        <span class="step-number"></span>
                    </li>
                    <li skeleton-loading="is-loading.bind:isLoading;" class="step rounded-pill position-absolute d-flex align-items-center justify-content-center \${user.addressVerified ? 'step-verification_success' : 'step-disabled'} \${user.emailConfirmed && user.idVerified ? 'step-active' : ''} material-icons">
                        <span class="step-number"></span>
                    </li>
                </ul>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column verification-column">
                        <span class="text-medium text-black font-bold mb-1 level-title" skeleton-loading="is-loading.bind:isLoading;">Level 1</span>
                        <cx-status-tag
                            status="Phone \${user.phoneNumberConfirmed ? 'Verified' : 'Verification'}"
                            tag-styling="tag \${user.phoneNumberConfirmed ? 'verified' : 'pending'}"
                            tag-icon.bind="'phone_iphone'"
                            tag-icon-styling.bind="user.phoneNumberConfirmed ? 'text-success-green' : 'text-purple'"
                            is-verification-tag.bind="'true'"
                            class="verif-skeleton"
                            skeleton-loading="is-loading.bind:isLoading;"
                        >
                        </cx-status-tag>
                        <span class="pt-2 text-tiny text-darkergray \${isLoading ? 'skeleton-lvl-1' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                            <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_1_PARAGRAPH'"></cx-page-content-area>
                        </span>
                    </div>
                    <div class="d-flex flex-column verification-column verification-column-2">
                        <div class="mb-1">
                            <span class="text-medium text-black font-bold level-title" skeleton-loading="is-loading.bind:isLoading;">Level 2</span>
                            <span show.bind="!isLoading" class="text-tiny text-darkergray">(Optional)</span>
                        </div>
                        <div class="d-flex">
                            <cx-status-tag
                                status="Email \${user.emailConfirmed ? 'Verified' : 'Verification'}"
                                tag-styling="tag \${user.emailConfirmed ? 'verified' : 'pending'}"
                                tag-icon.bind="'mail'"
                                tag-icon-styling.bind="user.emailConfirmed ? 'text-success-green me-1' : 'text-purple me-1'"
                                is-verification-tag.bind="'true'"
                                tag-icon-outlined.bind="'true'"
                                class="pb-2 me-1 verif-skeleton"
                                skeleton-loading="is-loading.bind:isLoading;"
                            >
                            </cx-status-tag>
                            <cx-status-tag
                                status="ID \${user.idVerified ? 'Verified' : 'Verification'}"
                                tag-styling="tag \${user.idVerified ? 'verified' : 'pending'}"
                                tag-icon.bind="'fingerprint'"
                                tag-icon-styling.bind="user.idVerified ? 'text-success-green me-1' : 'text-purple me-1'"
                                is-verification-tag.bind="'true'"
                                show.bind="!isLoading"
                            >
                            </cx-status-tag>
                        </div>
                        <span class="text-tiny text-darkergray \${isLoading ? 'skeleton-lvl-2' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                            <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_2_PARAGRAPH'"></cx-page-content-area>
                        </span>
                    </div>
                    <div class="d-flex flex-column verification-column">
                        <div class="mb-1">
                            <span class="text-medium text-black font-bold level-title" skeleton-loading="is-loading.bind:isLoading;">Level 3</span>
                            <span show.bind="!isLoading" class="text-tiny text-darkergray">(Optional)</span>
                        </div>
                        <cx-status-tag
                            status="Address \${user.addressVerified ? 'Verified' : 'Verification'}"
                            tag-styling="tag \${user.addressVerified ? 'verified' : 'pending'} mb-2"
                            tag-icon.bind="'fmd_good'"
                            tag-icon-styling.bind="user.addressVerified ? 'text-success-green' : 'text-purple'"
                            is-verification-tag.bind="'true'"
                            tag-icon-outlined.bind="'true'"
                            class="verif-skeleton"
                            skeleton-loading="is-loading.bind:isLoading;"
                        >
                        </cx-status-tag>
                        <span class="text-tiny text-darkergray \${isLoading ? 'skeleton-lvl-3' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                            <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_3_PARAGRAPH'"></cx-page-content-area>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div if.bind="isVerificationPage == 'true'" id="cx-progress-bar" class="w-100 d-flex pt-3 pb-5">
        <mdc-linear-progress
            class="cx-linear-progress_success \${isLoading ? 'opacity-0' : ''}"
            open.bind="true"
            progress.bind="verificationProgress"
            buffer="1">
        </mdc-linear-progress>
        <div class="position-absolute d-flex w-100">
            <ul class="d-flex flex-row verification-pills align-items-center">
                <li skeleton-loading="is-loading.bind:isLoading;" class="step rounded-pill d-flex align-items-center justify-content-center \${user.phoneNumberConfirmed ? 'step-verification_success' : 'step-active'} material-icons">
                    <span class="step-number"></span>
                </li>
                <li skeleton-loading="is-loading.bind:isLoading;" class="position-absolute step rounded-pill d-flex align-items-center justify-content-center \${user.emailConfirmed && user.idVerified ? 'step-verification_success' : 'step-disabled'} \${user.phoneNumberConfirmed ? 'step-active' : ''} material-icons">
                    <span class="step-number"></span>
                </li>
                <li skeleton-loading="is-loading.bind:isLoading;" class="position-absolute step rounded-pill d-flex align-items-center justify-content-center \${user.addressVerified ? 'step-verification_success' : 'step-disabled'} \${user.emailConfirmed && user.idVerified ? 'step-active' : ''} material-icons">
                    <span class="step-number"></span>
                </li>
            </ul>
            <div class="position-absolute w-100 d-flex flex-row verification-container">
                <div class="d-flex flex-column verification-column">
                    <span class="text-medium text-black font-bold mb-1 level-title" skeleton-loading="is-loading.bind:isLoading;width:60px;">Level 1</span>
                    <cx-status-tag
                        status="Phone \${user.phoneNumberConfirmed ? 'Verified' : 'Verification'}"
                        tag-styling="tag \${user.phoneNumberConfirmed ? 'verified' : 'pending'}"
                        tag-icon.bind="'phone_iphone'"
                        tag-icon-styling.bind="user.phoneNumberConfirmed ? 'text-success-green' : 'text-purple'"
                        is-verification-tag.bind="'true'"
                        skeleton-loading="is-loading.bind:isLoading;width:115px;"
                    >
                    </cx-status-tag>
                    <span class="pt-2 pb-3 text-tiny text-darkergray \${isLoading ? 'skeleton-lvl-1' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                        <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_1_PARAGRAPH'"></cx-page-content-area>
                    </span>
                </div>
                <div class="d-flex flex-column verification-column verification-column-2">
                    <div class="mb-1">
                        <span class="text-medium text-black font-bold level-title" skeleton-loading="is-loading.bind:isLoading;width:60px;">Level 2</span>
                        <span show.bind="!isLoading" class="text-tiny text-darkergray">(Optional)</span>
                    </div>
                    <div class="d-flex flex-row">
                        <cx-status-tag
                            status="Email \${user.emailConfirmed ? 'Verified' : 'Verification'}"
                            tag-styling="tag \${user.emailConfirmed ? 'verified' : 'pending'} me-1"
                            tag-icon.bind="'mark_email_unread'"
                            tag-icon-styling.bind="user.emailConfirmed ? 'text-success-green me-1' : 'text-purple me-1'"
                            is-verification-tag.bind="'true'"
                            tag-icon-outlined.bind="'true'"
                            class="pb-2"
                            skeleton-loading="is-loading.bind:isLoading;width:200px;"
                        >
                        </cx-status-tag>
                        <cx-status-tag
                            status="ID \${user.idVerified ? 'Verified' : 'Verification'}"
                            tag-styling="tag \${user.idVerified ? 'verified' : 'pending'} mb-2"
                            tag-icon.bind="'fingerprint'"
                            tag-icon-styling.bind="user.idVerified ? 'text-success-green me-1' : 'text-purple me-1'"
                            is-verification-tag.bind="'true'"
                            show.bind="!isLoading"
                        >
                        </cx-status-tag>
                    </div>
                    <span class="text-tiny text-darkergray pb-3 \${isLoading ? 'skeleton-lvl-2' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                        <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_2_PARAGRAPH'"></cx-page-content-area>
                    </span>
                </div>
                <div class="d-flex flex-column verification-column">
                    <div class="mb-1">
                        <span class="text-medium text-black font-bold level-title" skeleton-loading="is-loading.bind:isLoading;width:60px;">Level 3</span>
                        <span show.bind="!isLoading" class="text-tiny text-darkergray">(Optional)</span>
                    </div>
                    <cx-status-tag
                        status="Address \${user.addressVerified ? 'Verified' : 'Verification'}"
                        tag-styling="tag \${user.addressVerified ? 'verified' : 'pending'} mb-2"
                        tag-icon.bind="'receipt'"
                        tag-icon-styling.bind="user.addressVerified ? 'text-success-green me-1' : 'text-purple me-1'"
                        is-verification-tag.bind="'true'"
                        tag-icon-outlined.bind="'true'"
                        skeleton-loading="is-loading.bind:isLoading;width:128px;"
                    >
                    </cx-status-tag>
                    <span class="text-tiny text-darkergray \${isLoading ? 'skeleton-lvl-3' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                        <cx-page-content-area key.bind="'CX_CUSTOMER_PORTAL_LEVEL_3_PARAGRAPH'"></cx-page-content-area>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div if.bind="isVertical !== 'true' && isVerificationPage !== 'true'" id="cx-progress-bar" >
        <mdc-linear-progress class="cx-linear-progress" open.bind="true" progress.bind="progressCurrent" buffer="1">
        </mdc-linear-progress>
        <div class="position-absolute w-100">
            <ul class="d-flex flex-row justify-content-between">
                <li repeat.for="step of numSteps"
                    class="step rounded-pill d-flex align-items-center justify-content-center
                    \${step + 1 > currentStep ? '' : step + 1 == currentStep ? 'step-active material-icons' : 'step-success material-icons'}">
                    <span class="step-number font-bold text-large"></span>
                </li>
            </ul>
        </div>
    </div>
</template>
`;
// Exports
export default code;