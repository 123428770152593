// Module
var code = `<template>
    <div id="cx-footer-section">
        <span>\${title}</span>
        <div class="links">
            <template repeat.for="link of links">
                <a if.bind="!link.isDropdownMenu"
                    href="\${link.routeName | languageRoute & async}"
                    class="\${link.name}">
                    \${link.keyName | siteStrings}
                </a>
                <cx-footer-menu containerless else
                    link.bind="link" 
                ></cx-footer-menu>
            </template>
        </div>
    </div>
</template>
`;
// Exports
export default code;