import './cx-page-content-image.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { getAWSBucketEndpoint } from 'environment';
import { PageContentArea } from 'services/models/page/pageContentArea';

@autoinject()
export class CxPageContentImage {
    @bindable key: string;
    @bindable alternativeKey: string;
    imagePath: string;
    imageAlt: string;
    imageStyle: string;
    viewingAsAdmin: boolean;
    adminViewSubscriber: Subscription;
    sizeChanged: Subscription;
    legalPages = ['copyright-policy', 'cookies', 'terms-of-service', 'privacy-policy'];
    pageContentImageElement;
    baseAwsEndpoint;
    user;

    constructor(private pageContentAreaService: PageContentAreaService, private sessionService: SessionService, private eventAggregator: EventAggregator) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('page-content-area-image');
    }

    async attached() {
        try {
            this.user = await this.sessionService.getProfile();
            const canAccessAdminPanel = await this.sessionService.checkRolesForPanelAccess();
            this.viewingAsAdmin = this.sessionService.getAdminView() && this.user && canAccessAdminPanel;
            await this.setByKey();
            this.handleEventSubscriptions();
        } catch (e) {
            console.error(e);
        }
    }

    detached() {
        this.adminViewSubscriber?.dispose();
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
            this.setByKey();
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', () => {
            if (!this.pageContentImageElement) return;
            this.pageContentImageElement.width = this.pageContentImageElement.naturalWidth;
            this.pageContentImageElement.height = this.pageContentImageElement.naturalHeight;
        });
    }

    async setByKey() {
        const result = await this.pageContentAreaService.getByKey(this.key);
        if (this.viewingAsAdmin) {
            this.imagePath = `Key: { ${this.key} }`;
            this.imageAlt = `Key: { ${this.key} }`;
        } else {
            const alternativeContent = await this.getAlternativeKeyContent();
            this.imagePath = result ? result.imagePath : alternativeContent.imagePath;
            this.imageAlt = result ? result.imageAlt : alternativeContent.imageAlt;
        }
    }

    async keyChanged() {
        await this.setByKey();
    }

    async getAlternativeKeyContent(): Promise<PageContentArea> {
        if (!this.alternativeKey) return new PageContentArea();
        const result = await this.pageContentAreaService.getByKey(this.alternativeKey);
        return result ?? new PageContentArea();
    }
}
