// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/chick-icons/chick_crying.svg", import.meta.url);
// Module
var code = `<template>
    <div id="referrals">
        <div class="container-profile-style" show.bind="isDashboard">
            <cx-back-button on-click.call="redirectToProfile()"></cx-back-button>
            <div class="referrals-wrapper">
                <div class="referral-benefits">
                    <h1 class="text-extra-large-static font-medium section-head benefits-title">Referrals</h1>
                    <cds-menu 
                        menu-corner="top-end"
                        anchor-corner="bottom-end"
                        ref="lineMenu" 
                        menu-offset-y.bind="verticalOffset" 
                        tabindex="0"
                        class="menu-container"
                        >
                        <div slot="menu-anchor" class="d-flex align-items-center gap-1 flex-wrap text-regular-static font-override">
                            <span class="header-subtitle">Referral benefits for code</span>
                            <div class="d-flex align-items-center header-subtitle">
                                <span class="header-action truncate-text-1-b text-regular-static">https://chicksgold.com/r/\${activeCode}</span>
                                <md-icon class.bind="lineMenu.open ? 'rotate' : ''">arrow_drop_down</md-icon>
                            </div>
                        </div>
                        <div slot="menu-options" repeat.for="code of referralCodes" click.trigger="toggledMenu()" class="referral-option">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <span class="option-text truncate-text-1-b" click.trigger="selectActiveCode(code, 'line')">chicksgold.com/r/\${code}</span>
                            </div>
                        </div>
                    </cds-menu>
                    <div class="benefits-inner">
                        <cx-option-box 
                            description.bind="\`Get \$\${formattedReferralConfiguration.referrerBase} for each customer who transacts \$\${formattedReferralConfiguration.referredCondition}, within \${formattedReferralConfiguration.referredTime} days.\`"
                            icon.bind="benefits[0].icon"
                            disabled.bind="option.disabled">
                        </cx-option-box>
                        <cx-option-box 
                            description.bind="\`Earn \${formattedReferralConfiguration.referrerFee} of the total amount spent from your referrals in perpetuity\`"
                            icon.bind="benefits[1].icon"
                            disabled.bind="option.disabled">
                        </cx-option-box>
                        <cx-option-box 
                            description.bind="'Earn an additional 10% of your referral earnings while Chicks VIP is active.'" 
                            icon.bind="benefits[2].icon"
                            disabled.bind="option.disabled">
                        </cx-option-box>
                        <cx-option-box 
                            description.bind="\`New customers you refer will receive \$\${formattedReferralConfiguration.referredBase} after transacting \$\${formattedReferralConfiguration.referredCondition} within \${formattedReferralConfiguration.referredTime} days.\`"
                            icon.bind="benefits[3].icon"
                            disabled.bind="option.disabled">
                        </cx-option-box>
                    </div>
                </div>
                <div class="referral-options">
                    <div class="text-extra-large-static font-medium section-head options-title">Settings</div>
                    <div class="header-subtitle">Review earnings and manage referral settings</div>
                    <div class="options-inner d-flex">
                        <cx-list-element
                            icon.bind="'currency_exchange'"
                            title.bind="stepData[Steps.Earnings].title"
                            subtitle.bind="totalEarnings | currencyFormat:'\$':'F':true & async"
                            action.bind="referralCodeReferralStats.totalReferrals > 0 ? handleReferralsNavigation : null | call:Steps.Earnings"
                        ></cx-list-element>
                        <cx-list-element
                            icon.bind="'manage_accounts'"
                            title.bind="stepData[Steps.Manage].title"
                            subtitle.bind="stepData[Steps.Manage].description"
                            action.call="handleReferralsNavigation(Steps.Manage)"
                        ></cx-list-element>
                    </div>
                </div>
            </div>
        </div>
        <div show.bind="isEarnings" class="text-extra-large font-bold text-left">
            <div class="d-none d-lg-block">
                <span class="text-extra-large-static font-medium">\${'CX_TOTAL_EARNINGS' | siteStrings}</span>
                <div class="gx-2 gx-md-0 gy-1 gy-md-0 gap-3 gap-lg-0 d-flex justify-content-between align-items-center">
                    <div class="w-100 position-relative d-flex justify-content-between align-items-center flex-row">
                        <mdc-text-field
                            label="Search"
                            class="w-100 search-bar text-regular"
                            value.two-way="search & debounce:1000"
                            input.delegate="searchChanged() & debounce:1000"
                        >
                            <mdc-icon
                                mdc-text-field-icon leading
                                class="material-icons text-gray font-light"
                            >
                                search
                            </mdc-icon>
                            <mdc-icon
                                mdc-text-field-icon trailing
                                class="material-icons text-gray cursor-pointer"
                                if.bind="search"
                                click.delegate="clearSearch()"
                                tabindex="0"
                            >
                                close
                            </mdc-icon>
                        </mdc-text-field>
                    </div>
                </div>
            </div>
        </div>
        <div show.bind="isEarnings" class="container-profile-style container-padding">
            <cx-back-button on-click.call="handleReferralsNavigation(Steps.Dashboard)"></cx-back-button>
            <div show.bind="isEarnings" class="text-extra-large font-bold text-left">
                <div class="d-none d-sm-block d-lg-none search-bar-container">
                    <span class="text-extra-large-static font-medium">\${'CX_TOTAL_EARNINGS' | siteStrings}</span>
                    <div class="gx-2 gx-md-0 gy-1 gy-md-0 gap-3 gap-lg-0 d-flex justify-content-between align-items-center">
                        <div class="w-100 position-relative d-flex justify-content-between align-items-center flex-row">
                            <mdc-text-field
                                label="Search"
                                class="w-100 search-bar text-regular"
                                value.two-way="search & debounce:1000"
                                input.delegate="searchChanged() & debounce:1000"
                            >
                                <mdc-icon
                                    mdc-text-field-icon leading
                                    class="material-icons text-gray"
                                >
                                    search
                                </mdc-icon>
                                <mdc-icon
                                    mdc-text-field-icon trailing
                                    class="material-icons text-gray cursor-pointer"
                                    if.bind="search"
                                    click.delegate="clearSearch()"
                                    tabindex="0"
                                >
                                    close
                                </mdc-icon>
                            </mdc-text-field>
                        </div>
                    </div>
                </div>
            </div>
            <span class="d-sm-none text-extra-large-static font-medium">\${'CX_TOTAL_EARNINGS' | siteStrings}</span>
            <div class="d-flex justify-content-start align-items-stretch flex-column">
                 <div if.bind="referralsCount > 0" class="d-flex align-items-center filter-header">
                     <div class="d-none d-sm-block col-sm-6 showing-text text-regular-static font-light filter">
                        \${'CX_SHOWING_FROM' | siteStrings:'params':[filteredReferrals.length, referralsCount]}
                    </div>
                     <cx-filter
                         class="px-0 filter-select"
                         type="select"
                         material-icon="sort"
                         label="Sort By"
                         value.bind="optionSelected"
                         options.bind="sortByOptions"
                         change-function.bind="handleSortUpdate"
                         input-class="select-background-filled"
                     ></cx-filter>
                     <cx-search-drawer
                        class="d-block d-sm-none rounded search-drawer"
                        products.bind="filteredReferrals"
                        search.two-way="search"
                        button-style.bind="'button-blue-filled search-btn'"
                    ></cx-search-drawer>
                 </div>
                 <cx-circular-progress show.bind="pageLoading" class="spin-loader pb-5 pb-sm-0"></cx-circular-progress>
                 <template if.bind="!pageLoading">
                     <div if.bind="referralsCount > 0" class="row main-row text-white-50 text-regular d-none d-sm-flex">
                         <div class="col-1 col-sm-1"></div>
                         <div class="col-3 text-uppercase text-small-static font-regular">\${'CX_YOUR_REFERRALS' | siteStrings}</div>
                         <div class="col-3 text-uppercase text-small-static font-regular gutter-fix">\${'CX_TOTAL_SPENT' | siteStrings}</div>
                         <div class="col-3 text-uppercase text-small-static font-regular font-regular">\${'CX_REFERRAL_EARNINGS' | siteStrings}</div>
                         <div class="col-2 text-uppercase d-md-block text-small-static font-regular p-0">\${'CX_ACTIVITY' | siteStrings}</div>
                     </div>
                     <section data-simplebar class="referrals-area" if.bind="!deviceView">
                         <cx-referral-row
                             repeat.for="referral of filteredReferrals"
                             order.bind="order"
                             hide-remaining-balance.bind="hideRemainingBalance"
                             is-last.bind="\$last"
                             referral.bind="referral"
                         ></cx-referral-row>
                         <div class="d-flex justify-content-center align-items-center flex-column pb-3" if.bind="filteredReferrals.length === 0">
                             <img src="${___HTML_LOADER_IMPORT_0___}" alt="Chick Icon" loading="lazy">
                             <div class="text-center m-auto text-regular no-results">\${'CX_NO_REFERRALS_TO_SHOW' | siteStrings}</div>
                         </div>
                         <div if.bind="referralsCount > 0" class="d-flex justify-content-center d-none d-lg-block">
                            <cx-pagination
                                count.two-way="count"
                                page.two-way="page"
                            ></cx-pagination>
                        </div>
                    </section>
                    <section else class="referrals-area">
                        <cx-referral-row
                            repeat.for="referral of filteredReferrals"
                            order.bind="order"
                            hide-remaining-balance.bind="hideRemainingBalance"
                            is-last.bind="\$last"
                            referral.bind="referral"
                        ></cx-referral-row>
                        <div class="d-flex justify-content-center align-items-center flex-column pb-3" if.bind="filteredReferrals.length === 0">
                            <img src="${___HTML_LOADER_IMPORT_0___}" alt="Chick Icon" loading="lazy">
                            <div class="text-center m-auto text-regular no-results">\${'CX_NO_REFERRALS_TO_SHOW' | siteStrings}</div>
                        </div>
                        <div if.bind="referralsCount > 0" class="d-flex justify-content-center d-none d-lg-block">
                           <cx-pagination
                               count.two-way="count"
                               page.two-way="page"
                           ></cx-pagination>
                       </div>
                   </section>
                    <div class="d-flex justify-content-center d-lg-none pagination-container">
                        <cx-pagination
                            count.two-way="count"
                            page.two-way="page"
                        ></cx-pagination>
                    </div>
                 </template>
             </div>
        </div>
        <div class="container-profile-style" show.bind="isManage">
            <cx-back-button on-click.call="handleReferralsNavigation(Steps.Dashboard)"></cx-back-button>
            <div class="referral-header">
                <div class="referral-inner">
                    <span class="text-extra-large font-medium no-font-bp referral-inner__title">\${'CX_MANAGE_REFERRALS' | siteStrings}</span>
                    <span class="font-light profile-subtitle">Review your referral settings and statistics</span>
                </div>
            </div>
            <div class="manage-referrals">
                <div class="manage-inner">
                    <div class="manage-box">
                        <span class="text-medium-static font-bold mb-1">\${'CX_YOUR_REFERRAL_LINK_TITLE' | siteStrings}</span>
                        <span class="manage-cta text-regular-static text-black mb-2">
                            \${'CX_CUSTOMER_PORTAL_REFERRAL_PROGRAM_INFO' | siteStrings:'params':[referralConfiguration.referrerBase, referralConfiguration.referredCondition, referralConfiguration.referredTime]}
                        </span>
                        <div class="link-combo">
                            <cds-menu ref="menu" menu-offset-y="-25" show.bind="!linkEditMode" class="menu-container">
                                <cds-text-field
                                    slot="menu-anchor"
                                    trailing-icon="arrow_drop_down"
                                    variant="filled"
                                    placeholder.bind="'CX_SEARCH_REFERRAL_CODE' | siteStrings"
                                    indicator="border"
                                    value.two-way="inputContent"
                                    state.two-way="inputState"
                                    label="Referral code"
                                    click.trigger="toggledMenu()"
                                    autocomplete="off"
                                >
                                </cds-text-field>
                                    <div slot="menu-options" repeat.for="code of filteredReferralCodes" class="referral-option">
                                        <div class="d-flex justify-content-between align-items-center w-100">
                                            <span class="option-text" click.trigger="selectActiveCode(code)">\${code}</span>
                                            <md-icon click.trigger="deleteSelectedCode(code)">delete</md-icon>
                                        </div>
                                    </div>
                                    <div slot="menu-options" class="referral-option">
                                        <div class="d-flex justify-content-between align-items-center w-100">
                                            <span class="option-text" click.trigger="switchToLinkEditMode()">\${'CX_ADD_REFERRAL' | siteStrings}</span>
                                            <md-icon>add</md-icon>
                                        </div>
                                    </div>
                                </section>
                            </cds-menu>
                            <cds-text-field
                                ref="editTextField"
                                show.bind="linkEditMode"
                                variant="filled"
                                placeholder.bind="'CX_CREATE_REFERRAL_CODE' | siteStrings"
                                indicator="border"
                                value.two-way="codeInputContent"
                                label.bind="'CX_REFERRAL_CODE' | siteStrings"
                                state.two-way="inputState"
                                keyup.trigger="codeUpdatedOnKeyPress() & debounce:1500"
                                focusout.trigger="handleEditModeFocusOut()"
                            >
                            </cds-text-field>
                            <cds-icon-button 
                                click.trigger="copyCodeToClipboard()"
                                class="button copy-button">
                                <md-icon>content_copy</md-icon>
                            </cds-icon-button>
                            <cds-icon-button 
                                click.trigger="handleQRCodeGeneration()"
                                class="button qr-button">
                                <md-icon>qr_code</md-icon>
                            </cds-icon-button>
                        </div>
                    </div>
                    <div class="stats-box">
                        <cx-rate-of-change
                            content.bind="\`\$\${totalSpent}\`"
                            value.bind="totalSpent"
                            description.bind="pageContentAreaService.getSiteString('CX_TOTAL_REFERRAL_SPENT')">
                        </cx-rate-of-change>
                        <cx-rate-of-change
                            value.bind="lastMonth"
                            description.bind="pageContentAreaService.getSiteString('CX_TOTAL_REFERRALS')"
                            content.bind="totalUsers">
                        </cx-rate-of-change>
                    </div>
                </div>
            </div>
            <cds-dialog ref="dialog" open.bind="sharePopUp" class="dialog">
                <div slot="headline" class="d-flex flex-column font-bold text-medium-static mt-2 mb-1 w-100 p-0">
                    <div class="align-self-center text-wrap h-auto">Share your referral link in social media</div>
                </div>
                <div slot="content" class="d-flex flex-column dialog">
                    <div class="align-self-center icons-container">
                        <cds-icon-button class="" repeat.for="platformName of iterableSocialMediaOptions" variant="filled" fill-icon-on-hover click.trigger="shareOnSocialMedia(platformName)">
                            <cds-icon icon.bind="platformName"></cds-icon>
                        </cds-icon-button>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <cds-text-field
                            disabled
                            variant="filled"
                            indicator="border"
                            value.bind="inputContent"
                            autocomplete="off"
                            >
                        </cds-text-field>
                        <cds-icon-button 
                            click.trigger="copyCodeToClipboard()"
                            class="button copy-button">
                            <md-icon>content_copy</md-icon>
                        </cds-icon-button>
                    </div>
                </div>
            </cds-dialog>
            <div class="referral-options">
                <div class="options-inner d-flex">
                    <cx-list-element
                        icon.bind="'share'"
                        title.bind="'Share'"
                        subtitle.bind="'Share you referral link in social media'"
                        action.call="toggleSharePopUp()"
                    ></cx-list-element>
                    <cx-list-element
                        icon.bind="'contact_support'"
                        title.bind="'CX_LEARN_MORE' | siteStrings"
                        subtitle.bind="'CX_REFERRAL_TOS_FAQ' | siteStrings"
                        action.call="redirectToFaq()"
                    ></cx-list-element>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;