import { children } from 'aurelia-framework';

interface MdcButton extends HTMLElement {
    root: HTMLButtonElement;
}

export class CxTouchTarget {
    @children('*') button: (HTMLButtonElement | HTMLAnchorElement | MdcButton)[];
    expanded: boolean;
    cxTouchTarget: HTMLElement;
    isAttached: boolean = false;
    initialWidth: number = 0;

    attached() {
        !this.button && (this.isAttached = true);
        if (this.isButtonTouchable) return;
        this.applyClasses();
        this.isAttached = true;
    }

    buttonChanged() {
        if (!this.isAttached || this.isButtonTouchable) return;
        this.applyClasses();
    }

    get isButtonTouchable() {
        return !this.button || this.button[0]?.classList?.contains('mdc-button--touch');
    }

    applyClasses() {
        this.expanded = this.cxTouchTarget.hasAttribute('expanded');
        const button = this.button[0];
        if (button instanceof HTMLButtonElement || ('root' in button && button.root instanceof HTMLButtonElement) || (button instanceof HTMLAnchorElement)) {
            this.initialWidth = button.offsetWidth;
            this.applyButtonStyles(button);
            this.applyButtonTouchDiv(button);
            this.applyRippleDiv(button);
            this.applyButtonLabelClasses(button);
        }
    }

    applyButtonStyles(button: HTMLButtonElement | HTMLAnchorElement | MdcButton) {
        button.classList.add('mdc-button', 'mdc-button--touch');
    }

    applyButtonTouchDiv(button: HTMLButtonElement | HTMLAnchorElement | MdcButton) {
        const touchDiv = document.createElement('div');
        touchDiv.classList.add('mdc-button__touch');

        if (this.expanded) {
            touchDiv.style.left = `${((this.initialWidth - 48) / 2)}px`;
            touchDiv.style.width = '48px';
        }

        button.appendChild(touchDiv);
    }

    applyRippleDiv(button: HTMLButtonElement | HTMLAnchorElement | MdcButton) {
        let rippleDiv = button.querySelector('.mdc-button__ripple');
        if (!rippleDiv) {
            rippleDiv = document.createElement('div');
            rippleDiv.classList.add('mdc-button__ripple');
            button.appendChild(rippleDiv);
        }
    }

    applyButtonLabelClasses(button: HTMLButtonElement | HTMLAnchorElement | MdcButton) {
        const elementsWithText = button.querySelectorAll(':scope > *:not(.mdc-button__ripple):not(.mdc-button__touch):not(style):not(script):not(template):not(.au):not(.material-icons)');
        elementsWithText.forEach(element => {
            if (element.textContent.trim()) {
                element.classList.add('mdc-button__label');
            }
        });
    }
}
