import './cx-crypto-price-card.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { getAWSBucketEndpoint } from 'environment';
import { Flip } from 'resources/helpers/flip';
import { Helper } from 'resources/helpers/helper';
import { Chart } from 'chart.js/auto';
import { DataCurrencyStats, DataStat } from 'services/models/currency/dataStatsCurrenciesInterface';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class CxCryptoPriceCard {
    @bindable @observable currency;
    @bindable @observable isLoading: boolean = true;
    @bindable learnMoreRoute;
    @bindable learnMoreFunc;
    @bindable dataStatsCurrencies: DataCurrencyStats[];
    @bindable ctaKey: string = 'CX_LEARN_MORE';
    baseAwsEndpoint;
    upwardTrend = true;
    flipElPrice: HTMLElement;
    flipElPercentage: HTMLElement;
    flipElArrow: HTMLElement;
    flipInstancePrice;
    flipInstancePercentage;
    flipInstanceArrow;
    dataValues = [];
    labels = [];
    chartData;
    intervalGraphs;
    startedInterval = false;
    lastUpdatedPrice = null;

    constructor(private router: Router, private helper: Helper, private pageContentAreaService: PageContentAreaService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    currencyChanged(newValue, oldValue) {
        this.lastUpdatedPrice = newValue.priceUSD;
        if (newValue?.price !== oldValue?.price && !this.isLoading) {
            this.flipInstancePrice.flipTo({
                from: this.doubleToInteger(oldValue?.price),
                to: this.doubleToInteger(newValue?.price)
            });
            const newPercentage = this.doubleToInteger(newValue?.changePercentLast24);
            const oldPercentage = this.doubleToInteger(oldValue?.changePercentLast24);
            if (newPercentage !== oldPercentage) {
                this.upwardTrend = newPercentage >= 0;
                this.flipInstancePercentage.flipTo({
                    from: Math.abs(oldPercentage),
                    to: Math.abs(newPercentage)
                });
                this.flipInstanceArrow.flipTo(this.upwardTrend ? { to: 1 } : { to: 0 });
            }
        }

        if (!this.isLoading && !this.startedInterval) {
            this.startedInterval = true;
            this.intervalGraphs = setInterval(() => {
                this.updateChart(this.lastUpdatedPrice);
            }, 60000);
        }
    }

    doubleToInteger(value: number) {
        return parseInt((value * 100).toFixed(0));
    }

    isLoadingChanged() {
        if (!this.isLoading) {
            this.upwardTrend = this.currency?.changePercentLast24 >= 0;

            setTimeout(() => {
                this.flipElPrice = document.getElementById(`currency-price-${ this.currency.code }`);
                if (this.flipElPrice) {
                    this.flipInstancePrice = new Flip ({
                        node: this.flipElPrice,
                        from: this.doubleToInteger(this.currency.price),
                        separateEvery: 3,
                        separateOnly: 2,
                        separator: '.',
                        duration: 2
                    });
                }
                this.flipElPercentage = document.getElementById(`percentage-variation-${ this.currency.code }`);
                if (this.flipElPercentage) {
                    this.flipInstancePercentage = new Flip ({
                        node: this.flipElPercentage,
                        from: Math.abs(this.doubleToInteger(this.currency.changePercentLast24)),
                        separateEvery: 3,
                        separateOnly: 2,
                        separator: '.',
                        duration: 2
                    });
                }
                this.flipElArrow = document.getElementById(`arrow-trend-${ this.currency.code }`);
                if (this.flipElArrow) {
                    this.flipInstanceArrow = new Flip ({
                        from: this.upwardTrend ? 1 : 0,
                        node: this.flipElArrow,
                        systemArr: ['<span class="material-icons">arrow_downward</span>', '<span class="material-icons">arrow_upward</span>'],
                        isFloatingNumber: false,
                        duration: 2
                    });
                }
                this.loadGraphsData();
            });

        }
    }

    async goToLearnMore() {
        const { currentInstruction } = this.router;

        if (currentInstruction.config.name === 'home') {
            this.router.navigate(`buy/${this.currency?.code?.toLowerCase()}`);
            return;
        }

        this.router.parent.navigate(`buy/${this.currency?.code?.toLowerCase()}`);
    }

    loadGraphsData() {
        const containerGraph = document.getElementById('graph-' + this.currency.code);
        const canvas = document.createElement('canvas');
        const width = containerGraph.offsetWidth;
        const height = containerGraph.offsetHeight;
        canvas.width = width;
        canvas.height = height;
        const color = this.getColorLine(this.currency.code);
        const statsByCurrency = this.getDataStatsCurrency(this.currency.code);
        this.labels = statsByCurrency.map(data => this.helper.timestampToDateHour(data.time));
        this.dataValues = statsByCurrency.map(data => data.price);

        this.chartData = new Chart(canvas, {
            type: 'line',
            data: {
                labels: this.labels,
                datasets: [{
                    data: this.dataValues,
                    borderColor: color,
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0,
                    pointHoverRadius: 0
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                scales: {
                    x: {
                        display: false,
                        beginAtZero: true
                    },
                    y: {
                        display: false,
                        beginAtZero: false,
                    },
                }
            }
        });
        containerGraph.appendChild(canvas);
    }

    getColorLine(code: string): string {
        const colors = {
            BTC: 'rgba(255, 132, 0, 1)',
            ETH: 'rgba(98, 126, 234, 1)',
            SOL: 'rgba(201, 50, 247, 1)',
            ADA: 'rgba(0, 51, 173, 1)'
        };

        return colors[code];
    }

    getDataStatsCurrency(code: string): DataStat[] {
        const filteredData = this.dataStatsCurrencies.find(data => data.code === code);
        return filteredData ? filteredData.dataStats : [];
    }

    updateChart(value : number) {
        const time = this.helper.timestampToDateHour(new Date().getTime());
        this.labels = this.labels.slice(1);
        this.dataValues = this.dataValues.slice(1);
        this.labels.push(time);
        this.dataValues.push(value);
        this.chartData.data.labels = this.labels;
        this.chartData.data.datasets[0].data = this.dataValues;
        this.chartData.update();
    }
}
