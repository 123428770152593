// Module
var code = `<template>
    <main id="cx-option-box">
        <div class="option-box \${isDark ? 'option-box--dark' : ''} \${handleClick ? 'cursor-pointer' : ''} \${disabled ? 'option-box--disabled' : ''}" click.delegate="doAction()">
            <div class="option-box__main">
                <cx-dynamic-icon icon.bind="icon" color.bind="color" alt.bind="alt" icon-class="option-box__icon" outlined.bind="outlined" size.bind="iconSize" symbol.bind="symbol"></cx-dynamic-icon>
                <div class="option-box__info">
                    <h3 class="option-box__title \${description ?  '' : 'option-box__title--single'}">
                        \${title}
                    </h3>
                    <p class="option-box__\${doubleTitle ? 'title' : 'description'} option-box__second \${description ? '' : 'd-none'}">
                        <slot name="description">\${description}</slot>
                    </p>
                </div>
            </div>
            <slot name="content"></slot>
            <div if.bind="handleClick && (clickCondition !== undefined ? clickCondition : true)" class="d-flex option-box__arrow \${arrowRight ? 'option-box__arrow--right' : ''}">
                <div class="material-icons text-purple">arrow_left</div>
            </div>
        </div>
    </main>
</template>
`;
// Exports
export default code;