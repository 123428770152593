import './cx-pseudo-table.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxPseudoTable {
    @bindable ticket;
    @bindable order;
    @bindable index;
    @bindable user;
    parent;

    bind(bindingContext, overrideContext) {
        this.parent = overrideContext?.parentOverrideContext?.bindingContext;
    }

    switchToDetails(index) {
        if (this.ticket) {
            this.parent.switchToDetails(index);
        }
    }
}
