import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { FaqSection } from './models/faq';

@autoinject()
export class FaqSectionService {
    path = 'faqSection';
    retrievedContent = [];
    faqSection;
    faqSectionForSupport;

    constructor(private api: ApiService) {
    }

    async getByWebsite(): Promise<FaqSection[]> {
        if (this.faqSection) return this.faqSection;
        const params = {
            websiteShortCode: websiteShortCode()
        };

        this.faqSection = await this.api.doGet(this.path + '/ByWebsite', params);
        return this.faqSection;
    }

    async getForSupport(): Promise<FaqSection[]> {
        if (this.faqSectionForSupport) return this.faqSectionForSupport;
        this.faqSectionForSupport = await this.api.doGet(this.path + '/Support?websiteShortCode=CX');
        return this.faqSectionForSupport;
    }
}
