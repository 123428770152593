
export enum SubscriptionSections {
    'Main',
    'Cashback',
    'Manage',
    'LearnMore',
    'Details',
    'Cancel',
    'ChangePlan'
}

export enum SubscriptionOptions {
    Monthly = 1,
    Annual = 2
}

export enum UnsubscribedUserReason {
    NoLongerNeeded,
    MembershipTooExpensive,
    NotEnoughBenefits,
    Other
}
