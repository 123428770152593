// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-main-logo.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/home/star_full_grey_1100.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/home/star_half_grey_1100.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/home/star_semisesqui_grey_1100.svg", import.meta.url);
// Module
var code = `<template>
    <div id="footer" class="w-100" ref="footerContainer">
        <div class="footer-content">
            <div class="info-section">
                <a href="\${homePageRoute.routeName | languageRoute & async}" class="logo d-block">
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="ChicksX Logo" loading="lazy">
                </a>
                <a href="\${baseUrl}" class="exchange-link">The lowest fee crypto exchange</a>
                <a href="mailto:support@chicksx.com" class="contact-email d-block">support@chicksx.com</a>
            </div>
            <div class="main-content">
                <div class="links-section-mobile">
                    <cx-footer-dropdown title.bind="'ChicksX'" links.bind="chicksxSection"></cx-footer-dropdown>
                    <cx-footer-dropdown title.bind="'CX_SUPPORT' | siteStrings"
                        links.bind="supportSection"></cx-footer-dropdown>
                    <cx-footer-dropdown title.bind="'CX_LEGAL' | siteStrings"
                        links.bind="legalSection"></cx-footer-dropdown>
                </div>
                <div class="links-section-tablet-desktop">
                    <cx-footer-section title.bind="'ChicksX'" links.bind="chicksxSection"></cx-footer-section>
                    <cx-footer-section title.bind="'CX_SUPPORT' | siteStrings"
                        links.bind="supportSection"></cx-footer-section>
                    <cx-footer-section title.bind="'CX_LEGAL' | siteStrings"
                        links.bind="legalSection"></cx-footer-section>
                </div>
            </div>
            <div class="right-section">
                <div class="social-media">
                    <span class="d-none d-lg-block">\${'CX_SOCIAL' | siteStrings}</span>
                    <cx-social-media></cx-social-media>
                </div>
                <div class="trustpilot-reviews">
                    <a href="https://www.trustpilot.com/review/chicksx.com" class="d-block" target="_blank"
                        rel="nofollow noopener noreferrer">
                        \${'CX_TRUSTPILOT_REVIEWS' | siteStrings}
                    </a>
                    <div class="stars flex-center-between mb-regular">
                        <img repeat.for="i of amountOfStars" src="${___HTML_LOADER_IMPORT_1___}"
                            class="trust-pilot-star" alt="Star icon" loading="lazy">
                        <img if.bind="halfStar" src="${___HTML_LOADER_IMPORT_2___}" class="trust-pilot-star"
                            alt="Half Star icon" loading="lazy">
                        <img if.bind="semiSesquiStar" src="${___HTML_LOADER_IMPORT_3___}"
                            class="trust-pilot-semisesqui-star" alt="Semisesqui Star icon" loading="lazy">
                        <span if.bind="viewingAsAdmin" class="admin-view-size ms-1">Key: { CX_TRUST_PILOT_RATING
                            }/5</span>
                        <span else class="text-medium-static trust-pilot-text">\${ trustPilotStarRating }/5</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-section text-center w-100">
            Copyright © 2021, ChicksX.com. \${'CX_RIGHTS_RESERVED' | siteStrings}
        </div>
    </div>
</template>
`;
// Exports
export default code;