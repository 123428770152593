// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/arrow-down-grey-1100.svg", import.meta.url);
// Module
var code = `<template>
    <div id="footer-dropdown" class="footer-dropdown inactive" ref="dropdown">
        <div class="dropdown-top d-flex align-items-center justify-content-between" click.delegate="dropdownToggle()">
            <span>\${title}</span>
            <img src="${___HTML_LOADER_IMPORT_0___}" alt="Arrow icon" class="arrow-icon" loading="lazy">
        </div>
        <div class="dropdown-content">
            <template repeat.for="link of links" containerless>
                <a if.bind="!link.isDropdownMenu" 
                    click.delegate="closeDropdowns()"
                    href="/\${link.routeName | languageRoute & async}" 
                    class="\${link.name}">
                    <!-- \${link.name !== 'Cookies' ? link.name : 'Cookies Policy'} -->
                    \${link.keyName | siteStrings}
                </a>
                <cx-footer-menu containerless else 
                    link.bind="link">
                </cx-footer-menu>
            </template>
        </div>
    </div>
</template>
`;
// Exports
export default code;