// Module
var code = `<template>
    <div id="cx-payoutable-form" if.bind="show">
        <cx-card>
            <div class="payoutable-data-container card-main">
                <div if.bind="!addressInputFocused" slot="card-title" class="card-title">
                    <mdc-icon
                        click.delegate="formTitleClicked()"
                        show.bind="secondaryForm"
                        class="material-icons-outlined cursor-pointer">
                        arrow_left
                    </mdc-icon>
                    <p
                        click.delegate="formTitleClicked()"
                        class="m-0 \${formTitleStyle}">
                        \${formTitle}
                    </p>
                </div>
                <div slot="card-body" class="card-body">
                    <cx-text-input
                        repeat.for="pm of textInputs"
                        id="\${pm.id}"
                        show.bind="!secondaryForm && !addressInputFocused"
                        value.two-way="formData[pm.name]"
                        input-style.bind="\`secondary-input \${pm.outlineStyle}\`"
                        class.bind="pm.class"
                        floating-label.bind="true"
                        label.bind="pm.label"
                        trigger-code.bind="pm.name"
                        type.bind="pm.type"
                        on-input.bind="handleTextInput"
                        input-disabled.bind="loading"
                        additional-inputs.bind="pm.inlineInputs"
                        trailing-icon.bind="pm.inputTrailingIcon"
                        disable-autocomplete.bind="true"
                    ></cx-text-input>
                    <cx-places-autocomplete-input
                        show.bind="!secondaryForm"
                        repeat.for="pm of addressInputs"
                        input-class.bind="\`secondary-input \${pm.outlineStyle}\`"
                        autocompleted-address.two-way="formData[pm.name]"
                        placeholder="Address"
                        show-icon.bind="false"
                        on-input.bind="handleTextInput"
                        use-parent-hook.bind="false"
                        suggestion-position.bind="'TOP'"
                        on-blur.bind="handleAddressInputFocusOut"
                        on-focus.bind="handleAddressInputFocusIn"
                        trailing-icon.bind="pm.inputTrailingIcon"
                    >
                        <div
                            slot="input-label"
                            class="input-label"
                            show.bind="addressInputFocused"
                        >
                            <p
                                class="cursor-pointer"
                                click.delegate="toggleSecondaryForm(true)">
                                Enter address manually
                            </p>
                        </div>
                    </cx-places-autocomplete-input>
                    <cx-text-input
                        repeat.for="pm of secondaryFormInputs"
                        id="\${pm.id}"
                        show.bind="secondaryForm"
                        value.two-way="self[pm.name]"
                        input-style.bind="\`secondary-input \${pm.outlineStyle}\`"
                        class.bind="pm.class"
                        floating-label.bind="true"
                        label.bind="pm.label"
                        trigger-code.bind="pm.name"
                        type.bind="pm.type"
                        on-input.bind="pm.inputFunction"
                        input-disabled.bind="loading"
                        additional-inputs.bind="pm.inlineInputs"
                        trailing-icon.bind="pm.inputTrailingIcon"
                        disable-autocomplete.bind="true"
                        name.bind="pm.name"
                    ></cx-text-input>
                </div>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;