import './cx-address-input.scss';
import { autoinject, bindable, observable, TaskQueue } from 'aurelia-framework';
import { ICountry, IState } from 'country-state-city';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { ToastService } from 'services/toast-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Exchange } from 'services/models/purchase-flow/exchange';

@autoinject()
export class CxAddressInput {
    constructor(
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private providerToastService: ToastService,
        private eventAggregator: EventAggregator,
        private taskQueue: TaskQueue
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable address: string;
    @bindable country: string;
    @bindable state: string;
    @bindable city: string;
    @bindable zip: string;
    @bindable countryPlaceholder = 'Country';
    @bindable statePlaceholder = 'State';
    @bindable countries: ICountry[];
    @bindable states: IState[];
    @observable selectedOption: ICountry | IState;
    @bindable loading: boolean = true;
    addressInputExpandable;
    expandableOpen = false;
    showGreenCheckMarkAddress: boolean;
    showMiniSpinnerAddress: boolean;
    parent;
    timeouts: NodeJS.Timeout[];
    globalTimeout: NodeJS.Timeout;
    countryCode: string;
    error: boolean;
    subscribe: Subscription;
    sizeChangeSubcription: Subscription;
    isMobile: boolean;
    width: number;
    cashInMailCheck: boolean = false;
    exchange: Exchange;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        this.subscribe = this.eventAggregator.subscribe('addressAutoCompleted', async (addessInfo: google.maps.places.PlaceResult) => {
            const country = addessInfo.address_components.find((comp) => comp.types.includes('country'));
            const state = addessInfo.address_components.find((comp) => comp.types.includes('administrative_area_level_1'));
            const city = addessInfo.address_components.find((comp) => comp.types.includes('administrative_area_level_2'));

            // set address data
            this.country = country.long_name;
            this.countryCode = country.short_name;
            this.state = state.long_name;
            this.city = city?.long_name;

            // set placeholder value for dropdowns
            this.countryPlaceholder = country.long_name;
            this.statePlaceholder = state.long_name;

            // update states from country
            this.parent.buildStatesFromCountry(this.countryCode);

            setTimeout(() => {
                this.validateAddress();
            }, 1000);
        });

        this.taskQueue.queueMicroTask(() => {
            this.updateDeviceContext();
        });

        this.sizeChangeSubcription = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            this.updateDeviceContext();
        });

        this.exchange = this.parent.exchange;
        this.PaymentMethodCheck();
    }

    async detached() {
        this.subscribe.dispose();
        this.sizeChangeSubcription.dispose();
    }

    selectedOptionChanged(newValue: ICountry | IState) {
        if (!(newValue as IState).countryCode) {
            this.country = newValue.name;
            this.countryCode = newValue.isoCode;
            this.parent.buildStatesFromCountry(newValue.isoCode);
            this.state = null;
        } else {
            this.state = newValue.name;
        }
        setTimeout(() => {
            this.validateAddress();
        }, 1000);
    }

    reset() {
        this.address = '';
        this.country = '';
        this.state = '';
        this.city = '';
        this.zip = '';
        this.countryPlaceholder = 'Country';
        this.statePlaceholder = 'State';
        this.validateAddress();
    }

    inputsAreFill = () => !!(this.address && this.country && this.state && this.city && this.zip);

    validateAddress() {
        this.showMiniSpinnerAddress = this.showGreenCheckMarkAddress = false;
        this.timeouts = [this.globalTimeout];

        setTimeout(() => {
            this.inputsAreFill() && (this.showMiniSpinnerAddress = true);
        }, 1000);

        this.clearationTimeoutValueConverter.toView(this.timeouts);

        if (this.inputsAreFill()) {
            this.error = false;
            this.parent.disabledPayButton = true;

            this.globalTimeout = setTimeout(() => {
                const zipCodeValid = postcodeValidatorExistsForCountry(this.countryCode) && postcodeValidator(this.zip, this.countryCode);

                if (zipCodeValid) {
                    this.showGreenCheckMarkAddress = true;
                    this.parent.deliveryAddressValid = true;
                    this.parent.disabledPayButton = false;
                } else {
                    this.error = true;
                    this.providerToastService.showToast('Error', `The entered zip code does not match the format for ${this.country}`, 'error');
                    this.showGreenCheckMarkAddress = false;
                    this.parent.deliveryAddressValid = false;
                }

                this.parent.validateCryptoPayButton();
                this.showMiniSpinnerAddress = false;
            }, 3000);
        } else {
            this.parent.deliveryAddressValid = false;
            this.parent.validateCryptoPayButton();
        }
    }

    onToggle() {
        this.addressInputExpandable.toggle();
    }

    updateDeviceContext() {
        this.isMobile = this.width <= 576;
    }

    PaymentMethodCheck() {
        this.cashInMailCheck = this.exchange?.receivingPaymentMethod?.paymentMethod.reference === 'cash-in-mail';
    }
}
