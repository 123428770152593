// Module
var code = `<template>
    <div id="balance-topup">
        <cx-card class.bind="'balance-view-container'">
            <cx-back-button route="balance" page-loading.bind="pageLoading"></cx-back-button>
            <div class="title-container">
                <h1 class="page-title" skeleton-loading="is-loading.bind:pageLoading;width:200px;height:32px;">
                    <cx-page-content-area key="CX_TOP_UP_BALANCE_TITLE"></cx-page-content-area>
                </h1>
                <p skeleton-loading="is-loading.bind:pageLoading;width:200px;height:16px;">
                    <span>\${ 'CX_BALANCE_AVAILABLE' | siteStrings}: \${convertedBalance}</span>
                    <mdc-icon class="d-none d-lg-inline material-symbols-outlined tooltip-icon vertical-align-sub"
                        mdc-tooltip="value.bind: tooltipText.message; y-position: ABOVE; x-position: START"
                        mdc-tooltip.ref="balanceTooltipRef"
                        mouseover.trigger="setTooltipStyle(balanceTooltipRef)"
                        aria-label="additional information">
                        contact_support
                    </mdc-icon>
                    <mdc-icon class="d-lg-none material-symbols-outlined tooltip-icon vertical-align-sub"
                        click.delegate="showToast(tooltipText)"
                        aria-label="additional information">
                        contact_support
                    </mdc-icon>
                </p>
            </div>
            <div class="form-container">
                <div class="payment-container">
                    <div class="payment-inputs-container">
                        <cx-text-input
                            leading-text.bind="currencySymbol"
                            label.bind=" 'CX_BALANCE_AVAILABLE' | siteStrings"
                            class="input-style quantity-input"
                            value.two-way="amount & debounce:400"
                            input-style="quantity-input outline-styles"
                            type="number"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-text-input>
                        <cx-payment-method-selector
                            selected-payment-method.two-way="selectedPaymentMethod"
                            input-class="payment-method-selector-class"
                            ignore-route.bind="true"
                            is-cart-page.bind="true"
                            cc-recently-saved.from-view="ccRecentlySaved"
                            auto-select.bind="false"
                            outlined.bind="false"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-payment-method-selector>
                        <cx-button
                            class="btn-style"
                            button-disabled.bind="disabledPayButton"
                            button-text.bind="buttonText & async"
                            button-style="primary navbar-btn h-100 w-100"
                            disabled-style="primary-disabled-button"
                            click.delegate="startOrder()"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-button>
                    </div>
                    <div class="extra-info-containers">
                        <p if.bind="user.pendingBalance" class="legend-label" skeleton-loading="is-loading.bind:pageLoading;">
                            <span class="legend-balance legend-opacity pe-1">
                                <cx-page-content-area key="CX_BALANCE_PENDING"></cx-page-content-area>
                            </span>
                            <span class="legend-opacity pending-balance pe-1">\$\${user.pendingBalance}</span>
                            <mdc-icon class="d-none d-lg-inline material-symbols-outlined tooltip-icon vertical-align-sub"
                                mdc-tooltip="value.bind: balancePendingTooltipText.message"
                                mdc-tooltip.ref="balancePendingTooltipRef"
                                mouseover.trigger="setTooltipStyle(balancePendingTooltipRef, true)"
                                aria-label="additional information">
                                contact_support
                            </mdc-icon>
                            <mdc-icon class="d-lg-none material-symbols-outlined tooltip-icon vertical-align-sub"
                                click.delegate="showToast(balancePendingTooltipText)"
                                aria-label="additional information">
                                contact_support
                            </mdc-icon>
                        </p>
                        <div class="fees-container">
                            <p if.bind="currentCurrency !== 'USD'" class="legend-label" skeleton-loading="is-loading.bind:pageLoading;">
                                <i class="material-icons static-font me-1">sync_alt</i>
                                <span class="legend-opacity">\${ 'CX_RATE' | siteStrings }: \${conversionRate | numberFormat: '0,0.000'}</span>
                            </p>
                            <p if.bind="fees > 0" class="legend-label" skeleton-loading="is-loading.bind:pageLoading;">
                                <i class="material-icons static-font me-1">receipt_long</i>
                                <span class="legend-opacity">\${ 'CX_FEES' | siteStrings }: \${fees | fiatCurrencyFormat: '0,0.00':null:false:invertedRate & async}</span>
                            </p>
                            <p if.bind="totalWithFees" class="legend-label" skeleton-loading="is-loading.bind:pageLoading;">
                                <i class="material-icons static-font me-1">paid</i>
                                <span class="legend-opacity">\${ 'CX_TOTAL' | siteStrings }: \${totalWithFees | fiatCurrencyFormat: '0,0.00':null:false:invertedRate & async}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <cx-info-banner if.bind="selectedPaymentMethod.paymentStepNotes" class="no-stick">
                    <div innerhtml.bind="selectedPaymentMethod.paymentStepNotes" skeleton-loading="is-loading.bind:pageLoading;"></div>
                </cx-info-banner>
            </div>
        </cx-card>
        <cx-card if.bind="selectedPaymentMethod && !phoneVerified" class="mt-5">
            <div class="phone-verification-area">
                <cx-phone-input
                    contain-label.bind="true"
                    user.bind="user"
                    require-sms.bind="true"
                    label-text.bind=" 'CX_PHONE_VERIFICATION' | siteStrings "
                    use-label-margin.bind="false"
                ></cx-phone-input>
            </div>
        </cx-card>
        <cx-cash-in-mail
            if.bind="selectedPaymentMethod.paymentMethod.reference === 'cash-in-mail'"
            user.bind="user"
            files-list.bind="userDocumentsList"
            exchange.bind="exchange"
        ></cx-cash-in-mail>
        <cx-cash-in-person
            if.bind="selectedPaymentMethod.paymentMethod.reference === 'cash-in-person'"
            user.bind="user"
            meetup-address-valid.two-way="meetupAddressValid"
            location.two-way="location"
            selected-delivery.two-way="selectedDelivery"
            date.two-way="date"
            time.two-way="time"
            exchange.bind="exchange"
        ></cx-cash-in-person>
        <cx-veriff
            view-model.ref="veriffViewModel"
            hidden-button.bind="true"
            user.bind="user"
            class="d-block mb-3 mb-md-0"
        ></cx-veriff>
    </div>
</template>
`;
// Exports
export default code;