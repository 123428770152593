// Module
var code = `<template>
    <div id="cx-auth-purchase-flow">
        <form class="user-form" submit.delegate="login()" if.bind="loggingIn">
            <div class="login-area row">
                <div class="col-12 col-sm-6 mb-2 mb-sm-0">
                    <label class="py-1 text-regular text-black">
                        Email Address
                    </label>
                    <div class="position-relative login-input-field d-flex align-items-center">
                        <mdc-text-field
                            outlined
                            autocomplete="on"
                            value.two-way="user.slowValidateEmail & validate"
                            id="username-input"
                            required.bind="true"
                            autocomplete="email"
                            placeholder="Enter your email"
                            type="text"
                            name="Email"
                            class="input-email cx-form-control purchase_auth_input \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkEmail ? 'cx-form-control--success' : showErrorCheckMarkEmail ? 'cx-form-control--error' : ''}"
                            keyup.delegate="emailUpdatedOnKeyPress()"
                            focusin.delegate="emailUpdatedOnFocusIn()"
                            focusout.delegate="emailUpdatedOnFocusOut()"
                            ref="emailLoginInput"
                        ></mdc-text-field>
                        <span class="circle-icon-email check material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkEmail">check_circle</span>
                        <span class="circle-icon-email error material-icons" alt="error" if.bind="showErrorCheckMarkEmail">error_outline</span>
                        <mdc-circular-progress if.bind="showMiniSpinnerEmail" class="mini-spinner-icon-email" size="25" stroke-width="2"></mdc-circular-progress>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-2">
                    <label class="py-1 text-regular text-black">
                        Password
                    </label>
                    <div class="position-relative login-input-field">
                        <div class=" d-flex align-items-center">
                            <mdc-text-field
                                outlined
                                value.two-way="user.password & validate"
                                id="password-input"
                                autocomplete="password"
                                name="Password"
                                placeholder="Enter password"
                                required.bind="true"
                                type="\${visible ? 'text' : 'password'}"
                                class="purchase_auth_input cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${passwordValid ? 'cx-form-control--success' : passwordInvalid ? 'cx-form-control--error' : ''}"
                                keyup.delegate="passwordUpdatedOnKeyPress(\$event)"
                                focusout.delegate="passwordUpdatedOnFocusOut()"
                                focusin.delegate="passwordUpdatedOnFocusIn()"
                                ref="passwordLoginInput"
                            ></mdc-text-field>
                            <span class="circle-icon-password check material-icons-outlined" alt="green check" if.bind="passwordValid">check_circle</span>
                            <span class="circle-icon-password error material-icons" alt="error" if.bind="showErrorCheckMarkPassword">error_outline</span>
                            <mdc-circular-progress if.bind="showMiniSpinnerPassword" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                        </div>                        
                        <a href="sign-in/reset-password" class="no-decoration subtext cursor-pointer text-regular text-gray font-light link-click position-absolute" if.bind="showForgotPassword">
                            Forgot password?
                        </a>
                    </div>
                </div>
                <div if.bind="tokenRequired" class="col-12 col-sm-6">
                    <label class="text-regular text-black token-label">
                        Token
                    </label>
                    <div class="position-relative login-input-field">
                        <div class=" d-flex align-items-center">
                            <mdc-text-field
                                outlined
                                autocomplete="on"
                                value.two-way="user.token & validate"
                                id="username-input"
                                type="text"
                                required.bind="true"
                                name="Token"
                                placeholder.bind="'CX_ENTER_2FA_TOKEN' | siteStrings"
                                class="purchase_auth_input cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkToken ? 'cx-form-control--success' : showErrorCheckMarkToken ? 'cx-form-control--error' : ''}"
                                keyup.delegate="tokenUpdatedOnKeyPress()"
                                focusout.delegate="tokenUpdatedOnFocusOut()"
                                focusin.delegate="tokenUpdatedOnFocusIn()"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                ref="tokenLoginInput"
                            ></mdc-text-field>
                            <span class="circle-icon-email check material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkToken">check_circle</span>
                            <span class="circle-icon-email error material-icons" alt="error" if.bind="showErrorCheckMarkToken">error_outline</span>
                            <mdc-circular-progress if.bind="showMiniSpinnerToken" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                        </div>
                        <a click.delegate="lostVerification()" class="no-decoration subtext cursor-pointer text-regular text-gray font-light link-click position-absolute" if.bind="tokenRequired">
                            Lost your 2FA token?
                        </a>
                    </div>
                </div>
            </div>
        </form>
        <form class="user-form" submit.delegate="register()" if.bind="!loggingIn">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="py-1 text-regular text-black">
                        Email Address
                    </label>
                    <div class="position-relative login-input-field input-email d-flex align-items-center"> 
                        <mdc-text-field
                            outlined
                            ref="email"
                            autocomplete="on"
                            value.two-way="user.slowValidateEmail & validate"
                            id="username-input"
                            required.bind="true"
                            autocomplete="email"
                            placeholder="Enter your email"
                            type="text"
                            name="Email"
                            class="w-100 purchase_auth_input cx-form-control \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkEmail ? 'cx-form-control--success' : showErrorCheckMarkEmail ? 'cx-form-control--error' : ''}"
                            keyup.delegate="emailUpdatedOnKeyPress()"
                            focusin.delegate="emailUpdatedOnFocusIn()"
                            focusout.delegate="emailUpdatedOnFocusOut()"
                        ></mdc-text-field>
                        <span class="circle-icon-email check material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkEmail">check_circle</span>
                        <span class="circle-icon-email error material-icons" alt="error" if.bind="showErrorCheckMarkEmail">error_outline</span>
                        <mdc-circular-progress if.bind="showMiniSpinnerEmail" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-8 text-small text-gray font-regular my-auto pe-lg-0 tos-copy">
                        By continuing you agree to ChicksX Inc's
                    <a class="no-decoration link-click text-small text-purple" href="\${termsOfServiceRoutePage}" target="_blank">Terms of Service</a>
                        and
                    <a class="no-decoration link-click text-small text-purple" href="\${privacyPolicyRoutePage}" target="_blank">Privacy Policy</a>.
                </div>
            </div>
        </form>
    </div>
</template>
`;
// Exports
export default code;