// Module
var code = `<template>
    <div id="cx-credit-card-input" class="input-credit-card d-flex flex-row align-items-center w-100 position-relative \${ showGreenCheckMark ? 'input-credit-card--success' : showErrorCheckMark ? 'input-credit-card--error' : '' }">
        <mdc-text-field
            outlined
            value.bind="userName"
            label="Name"
            class="input-password \${ requestOnlyCvv ? 'w-70' : isCartPage ? 'w-33' : 'w-60' } first-input using-label me-3"
            if.bind="cardInfo"
            disabled.bind="true"
        ></mdc-text-field>
        <!-- Adding card -->
        <mdc-text-field
            outlined
            ref="cardNumberEl"
            value.two-way="cardNumberDisplayed"
            label="Card number"
            class="input-password first-input using-label \${ cardNumberDisplayed || cardMonthYear || cardCvv ? '' : 'first-input--empty' } \${ cardNumberIsFocused ? 'w-100' : '' }"
            maxlength="20"
            focusout.delegate="handleCCFocus(\$event)"
            focusin.delegate="handleCCFocus(\$event)"
            keyup.delegate="keyUpCCHandler(\$event) & debounce:400"
            keydown.delegate="keyDownHandler(\$event)"
            if.bind="!cardInfo"
        ></mdc-text-field>
        <!-- Editing card -->
        <mdc-text-field
            outlined
            value.two-way="cardMonth"
            label="MM"
            class="input-password middle-input using-label w-10 \${ cardInfo && isCartPage ? 'me-3' : '' }"
            ref="cardMonthEl"
            if.bind="cardInfo && !requestOnlyCvv"
            keyup.delegate="validateMonthExpDate()"
            maxlength="2"
        ></mdc-text-field>
        <mdc-text-field
            outlined
            value.two-way="cardYear"
            label="YY"
            class="input-password middle-input using-label w-10 \${ cardInfo && isCartPage ? 'me-3' : '' }"
            maxlength="2"
            keyup.delegate="validateYearExpDate()"
            if.bind="cardInfo && !requestOnlyCvv"
            ref="cardYearEl"
        ></mdc-text-field>
        <mdc-text-field
            outlined
            value.two-way="cardMonthYear"
            label="MM/YY"
            class="input-password \${ requestOnlyCvv ? 'w-40' : '' } middle-input using-label \${ cardMonthYear || cardCvv ? '' : 'middle-input--empty' }"
            ref="cardMonthYearEl"
            keyup.delegate="validateExpDate(\$event)"
            focusout.delegate="focusExpDate()"
            focusin.delegate="focusExpDate()"
            keydown.delegate="keyDownDateHandler(\$event)"
            maxlength="5"
            if.bind="!cardInfo && !cardNumberIsFocused"
        ></mdc-text-field>
        <mdc-text-field
            outlined
            value.two-way="cardCvv"
            label="CVV"
            class="input-password last-input using-label \${ cardMonthYear || cardCvv ? '' : 'last-input--empty' }"
            maxlength="4"
            ref="cardCVVEl"
            keyup.delegate="keyUpCvv(\$event) & debounce:400"
            keydown.delegate="keyDownHandler(\$event)"
            focusout.delegate="focusCvvHandler(\$event)"
            focusin.delegate="focusCvvHandler(\$event)"
            if.bind="!cardNumberIsFocused && isCartPage"
        ></mdc-text-field>
        <mdc-circular-progress if.bind="showMiniSpinner" class="mini-spinner-icon position-absolute" size="20" stroke-width="1"></mdc-circular-progress>
        <span if.bind="showGreenCheckMark" class="circle-icon p-0 check text-large position-absolute material-icons-outlined" alt="green check">check_circle</span>
        <span if.bind="showErrorCheckMark" class="circle-icon p-0 position-absolute error text-large material-icons" alt="error">error_outline</span> 
    </div>
</template>
`;
// Exports
export default code;