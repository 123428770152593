import './cx-cash-in-mail.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EasyPostService } from 'services/easypost-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { PageContentAreaService } from 'services/page-content-area-service';
import { apiEndpoint } from 'environment';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { WebsiteService } from 'services/website-service';
import heic2any from 'heic2any';
import { CountryService } from 'services/country-service';
import { CountryDto } from 'services/models/country/countryDto';
import { User } from 'services/models/user/user';
import { Exchange } from 'services/models/purchase-flow/exchange';
import { TrackingData } from 'services/models/purchase-flow/tracking-data';
import { Helper } from 'resources/helpers/helper';

interface DeliveryOption {
    name: string;
    flag: string;
    address: string;
}

@autoinject()
export class CxCashInMail {
    constructor(
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private easyPostService: EasyPostService,
        private toastService: ToastService,
        private pageContentAreaService: PageContentAreaService,
        private customerService: CustomerService,
        private sessionService: SessionService,
        private websiteService: WebsiteService,
        private countryService: CountryService,
        private helper: Helper
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    environment = apiEndpoint();
    @bindable user: User;
    @bindable exchange: Exchange;
    @bindable trackingNumberValid: boolean;
    @bindable filesList: File[] = [];

    showMiniSpinnerTrackingCode: boolean;
    showGreenCheckMarkTrackingCode: boolean;
    trackingUpdatedStopWatch: NodeJS.Timeout;
    trackingSpinnerStopWatch: NodeJS.Timeout;
    trackingFocusInStopWatch: NodeJS.Timeout;
    timeouts: NodeJS.Timeout[];
    firedFunction: boolean;
    trackingData: TrackingData;
    parent;
    showCashInMailInfo: boolean;
    fileExtensions = ['pdf', 'png', 'jpg', 'heic'];
    fileTypes = [];
    maxNumberOfFiles = 10;
    inputAccept = '';
    tempFiles = [];
    showDropdown = false;
    deliveryCountry: DeliveryOption;
    customerCountry: string;
    showDeliveryOptions = false;
    countries: CountryDto[] = [];
    deliveryOptions: DeliveryOption[] = [];
    cashInMailExpandable;
    expandableOpen = false;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Purchase Flow')?.id);
    }

    async attached() {
        this.customerCountry = this.sessionService.getCountry();
        this.countries = await this.countryService.getWithStores();
        this.deliveryOptions = this.makeDeliveryOptions();

        // This will be done dynamically with AP in future sprint, temporary solution

        this.deliveryCountry = this.deliveryOptions[0];

        for (const type of this.fileTypes) {
            this.inputAccept += `${type}/*, `;
        }

        for (const extension of this.fileExtensions) {
            this.inputAccept += `.${extension}, `;
        }

        this.inputAccept = this.inputAccept.substring(0, this.inputAccept.length - 2);
    }

    triggerCashInMailInfo() {
        this.showCashInMailInfo = !this.showCashInMailInfo;
    }

    triggerDeliveryOptions() {
        this.showDeliveryOptions = !this.showDeliveryOptions;
    }

    selectDeliveryCountry(option) {
        this.deliveryCountry = option;
    }

    async validatingTrackingNumber() {
        this.showMiniSpinnerTrackingCode = false;
        this.trackingData = await this.easyPostService.getTrackingData(this.exchange.trackingNumber);
        this.firedFunction = true;
        this.parent.trackingNumberValid = Boolean(this.trackingData);
        if (!this.trackingData && this.exchange.trackingNumber.length > 0) {
            await this.toastService.showToast('Info', 'Tracking number hasn\'t been registered yet. Please ensure you contact your shipper after creating your order.', 'info');
            this.trackingData = {
                carrier: '',
                publicUrl: '',
                status: 'created',
                trackingCode: this.exchange.trackingNumber
            };
            this.parent.trackingNumberValid = true;
        }
        this.parent.validateCryptoPayButton?.();
    }

    async trackingUpdatedOnKeyPress(ev: KeyboardEvent) {
        this.firedFunction = false;
        this.showMiniSpinnerTrackingCode = this.showGreenCheckMarkTrackingCode = false;
        this.timeouts = [this.trackingUpdatedStopWatch, this.trackingSpinnerStopWatch, this.trackingFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.exchange.trackingNumber) {
            this.trackingSpinnerStopWatch = setTimeout(() => {
                this.showMiniSpinnerTrackingCode = true;
                this.trackingUpdatedStopWatch = setTimeout(async() => {
                    this.validatingTrackingNumber();
                }, 2000);
            }, 1000);
            return;
        }
        if (ev?.key === 'Enter') {
            this.validatingTrackingNumber();
            return;
        }
        if (this.parent.exchange.trackingNumber?.length === 0) this.parent.isCryptoPayValid = false;
        this.validatingTrackingNumber();
    }

    trackingOnFocusIn() {
        this.showMiniSpinnerTrackingCode = this.showGreenCheckMarkTrackingCode = false;
        this.firedFunction = false;
        this.trackingFocusInStopWatch = setTimeout(() => {
            if (this.exchange.trackingNumber) {
                this.trackingUpdatedOnKeyPress({ key: '' } as KeyboardEvent);
            }
        });
    }

    triggerTrackingEdit() {
        this.parent.trackingNumberValid = false;
        this.parent.validateCryptoPayButton?.();
    }

    async changeHandler(ev) {
        const fileInput = document.getElementById('file-input');
        for (let file of ev.target['files']) {
            if (await this.checkFileType(file) && await this.checkFileSize(file)) {
                if (this.tempFiles.length >= this.maxNumberOfFiles) {
                    await this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                } else {
                    let newFileName = null;
                    if (file.name.includes('.heic')) {
                        newFileName = file.name.replace('.heic', '_heic.png');
                        this.tempFiles.push({ name: newFileName, isLoaded: false });
                        file = this.blobToFile(await heic2any({
                            blob: file,
                            toType: 'image/png',
                            quality: 1
                        }).then(result => result), newFileName);
                    } else {
                        this.tempFiles.push({ name: file.name, isLoaded: false });
                    }
                    this.filesList.push(file);
                    this.tempFiles[this.findLastIndex(this.tempFiles, file['name'])]['isLoaded'] = true;
                }
            }
        }

        fileInput['value'] = null;
    }

    blobToFile(theBlob, fileName): File {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }

    async checkFileType(file) {
        if (this.fileTypes.filter(item => file['type'].startsWith(item + '/')).length > 0 ||
            this.fileExtensions.filter(item => file['name'].endsWith('.' + item)).length > 0) {
            return true;
        }

        await this.toastService.showToast('File type is not allowed', file['name'], 'error');
        return false;
    }

    async checkFileSize(file: File) {
        const maxSizePerFile = 20971520; //Equivalent to 20 MB
        const maxSizeAllFiles = 20971520; //Equivalent to 20 Mb

        if (file['size'] > maxSizePerFile) {
            await this.toastService.showToast('File size exceeds 20 MB', file['name'], 'error');
            return false;
        }

        if (this.filesList.map(item => item['size']).reduce((prev, curr) => prev + curr, 0) + file['size'] > maxSizeAllFiles) {
            await this.toastService.showToast('The total size of all files must not exceed 20 MB', 'Please try again', 'error');
            return false;
        }

        return true;
    }

    findLastIndex(array, query) {
        const arrayTemp = [];
        array.forEach(element => arrayTemp.push(element.name));
        return arrayTemp.lastIndexOf(query);
    }

    deleteItem(index: number) {
        this.tempFiles.splice(index, 1);
        this.filesList.splice(index, 1);
    }

    showItem(index: number) {
        return index < 4 ? true : false;
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        return this.showDropdown;
    }

    handleDropdownFocusOut() {
        if (this.showDropdown) {
            this.showDropdown = false;
            return this.showDropdown;
        }
    }

    cleanFileList() {
        this.tempFiles = this.filesList = [];
    }

    makeDeliveryOptions() {
        const deliveryOptions: DeliveryOption[] = [];

        for (const country of this.countries) {
            const { address = '', city = '', state = '', zipCode = '' } = country.storeLocations[0];
            const flag = country.imagePath ? `${this.environment}Image/chicks-currencies/${country.imagePath}` : null;
            const deliveryOption = {
                name: country.name,
                flag: flag,
                address: `${address} ${city}, ${state} ${zipCode}`
            };

            deliveryOptions.push(deliveryOption);
        }
        return deliveryOptions;
    }

    onToggle() {
        this.cashInMailExpandable.toggle();
    }
}
