export class DefaultCurrencyValueConverter {
    toView(value: string) {

        const currenciesCountries = [
            {
                countries: ['CA'],
                currency: 'CAD'
            }, {
                countries: ['NO'],
                currency: 'NOK'
            }, {
                countries: ['SE'],
                currency: 'SEK'
            }, {
                countries: ['BR'],
                currency: 'BRL'
            }, {
                countries: ['JP'],
                currency: 'JPY'
            }, {
                countries: ['CN'],
                currency: 'CNY'
            }, {
                countries: ['KI', 'TV', 'NF', 'NR', 'CC', 'CX', 'AU'],
                currency: 'AUD'
            }, {
                countries: ['PM', 'ES', 'NL', 'AX', 'MQ', 'ME', 'LV', 'AD', 'GP', 'RE', 'LU', 'AT', 'EE', 'BL', 'PT', 'MC', 'BE', 'SM', 'DE', 'TF', 'CY', 'IT', 'SI', 'GR', 'LT', 'MT', 'FI', 'YT', 'MF', 'SK', 'XK', 'IE', 'GF', 'VA', 'FR'],
                currency: 'EUR'
            }, {
                countries: ['GG', 'IM', 'GB', 'JE'],
                currency: 'GBP'
            }, {
                countries: ['PN', 'CK', 'NZ', 'TK', 'NU'],
                currency: 'NZD'
            }, {
                countries: ['LI', 'CH'],
                currency: 'CHF'
            }, {
                countries: ['DK', 'FO'],
                currency: 'DKK'
            }
        ];

        const defaultCurrency = currenciesCountries.find(item => item.countries.includes(value));
        return defaultCurrency ? defaultCurrency.currency : 'USD';
    }
}
