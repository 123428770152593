import './cx-tracking-progress.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { TrackingData } from 'services/models/purchase-flow/tracking-data';

@autoinject()
export class CxTrackingProgress {
    @bindable numSteps: number;
    @bindable trackingData: TrackingData;
    @bindable isTooltip: boolean;
    currentStep = 0; // Created
    progressCurrent = 0;
    sizeChanged: Subscription;
    isVertical = false;

    constructor(private eventAggregator: EventAggregator) { }

    attached() {
        this.currentStep = 0;
        if (!this.trackingData) return;
        this.currentStep = 2; // Accepted
        if (this.trackingData.status === 'created') {
            this.currentStep = 1; // Created
        } else if (this.trackingData.status === 'in_transit') {
            this.currentStep = 3; // In progress
        } else if (this.trackingData.status === 'out_for_delivery') {
            this.currentStep = 4; // Shipped
        } else if (this.trackingData.status === 'delivered') {
            this.currentStep = 6; // Delivered
        } else if (this.trackingData.status !== 'pre_transit') {
            this.currentStep = 5; // In transit
        }
        this.setProgress(this.currentStep - 1);
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.isVertical = payload.width < 579;
        });
    }

    setProgress(step: number) {
        this.progressCurrent = (1 / (this.numSteps - 1)) * step;
    }
}
