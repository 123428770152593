import './cx-status-tag.scss';
import { bindable } from 'aurelia-framework';

export class CxStatusTag {
    @bindable status: string;
    @bindable tagStyling: string;
    @bindable isVerificationTag: string;
    @bindable tagIcon: string;
    @bindable tagIconStyling: string;
    @bindable tagIconOutlined: string;

    getStatusClass() {
        return this.status.toLowerCase().replaceAll(' ', '-');
    }
}
