import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class BlacklistService {
    path = 'Blacklist';
    userBlacklist;

    constructor(private api: ApiService, private helper: Helper) {}

    async getBlacklistByUserEmail(userEmail: string, updateList = false) {
        if (this.userBlacklist && !updateList) {
            return this.userBlacklist;
        }
        this.userBlacklist = await this.helper.fetchData(this.api, `${this.path}/${userEmail}/GetByUserEmailProfile`, `BlacklistService/${userEmail}`, updateList);
        return this.userBlacklist;
    }
}
