// Module
var code = `<template>
    <div id="notifications-container-lg">
        <div class="inner-container">
            <span class="notifications-main-title text-black font-medium text-extra-large d-none d-lg-block">\${'CX_ALL_NOTIFICATIONS' | siteStrings}</span>
            <span class="notifications-main-title text-black font-medium text-extra-large d-block d-lg-none">\${'CX_NOTIFICATIONS' | siteStrings}</span>
            <div class="d-block d-sm-flex d-lg-block flex-column flex-sm-row flex-lg-column align-items-center ">
                <div class="d-flex align-items-center control-spacer">
                    <cx-checkbox-select class="m-0 me-lg-3" type.bind="'checkbox'" options.two-way="selectOptions" ref="checkboxSelect"></cx-checkbox-select>
                    <div class="delete-buttons d-flex flex-row">
                        <cx-button
                            if.bind="anyIsChecked"
                            class="font-regular d-none d-lg-block me-3 ms-1"
                            button-text="\${'CX_DELETE' | siteStrings}"
                            button-style.bind="'secondary font-regular btn w-100 text-purple'"
                            click.delegate="deleteNotifications()"
                        ></cx-button>
                        <div
                            if.bind="anyIsChecked"
                            class="delete-icon d-flex d-lg-none align-items-center"
                            click.delegate="deleteNotifications()"
                        >
                            <span class="material-icons text-purple d-block d-lg-none cursor-pointer">
                                delete_outline
                            </span>
                        </div>
                        <!-- TODO: check if read and unread works properly -->
                        <cx-button
                            if.bind="anyIsChecked"
                            class="font-regular ms-1"
                            button-text.bind="isAnUnread ? 'CX_MARK_READ' : 'CX_MARK_UNREAD'|siteStrings"
                            button-style.bind="'secondary font-regular btn w-100 mark-read text-purple'"
                            click.delegate="markNotificationsAsRead()"
                        ></cx-button>
                    </div>
                </div>
                <div class="search-bar-container">
                    <cx-text-input
                        label="Search"
                        value.two-way="search"
                        class="searchbar w-100"
                        key-up-function.call="keyUpHandler(\$event)"
                        leading-icon.bind="'search'"
                        button-icon.bind="search"
                        button-icon-function.call="clearSearch()"
                    ></cx-text-input>
                </div>               
            </div>
            <hr class="header-divisor d-block d-lg-none">
            <div class="notifications-container pb-3 pb-lg-0 \${ newNotifications.length == 0 || previousNotifications.length == 0 ? 'gruped-notifications' : '' }">
                <section data-simplebar class="lilac-scrollbar">
                    <span class="text-black new-divisor font-medium" if.bind="newNotifications.length > 0">\${'CX_NEW' | siteStrings}</span>
                    <div class="d-flex flex-column new-notifications-container">
                        <cx-notification
                            repeat.for="notification of newNotifications"
                            notification-data.bind="notification"
                            selected-filter.bind="clickedOption"
                            loading.bind="loading"
                            function-checked.call="functionChecked(notification.id)"                            
                        ></cx-notification>
                    </div>
                    <div class="text-black earlier-divisor font-medium \${ newNotifications.length == 0 ? 'm-0' : '' }" if.bind="previousNotifications.length > 0">Earlier</div>
                    <div class="earlier-notifications-container">
                        <div class="d-flex flex-column">
                            <cx-notification
                                repeat.for="notification of previousNotifications"
                                notification-data.bind="notification"
                                selected-filter.bind="clickedOption"
                                loading.bind="loading"
                                function-checked.call="functionChecked(notification.id)"
                            ></cx-notification>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;