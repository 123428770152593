// Module
var code = `<template>
    <div
        id="cx-dropdown-content"
        class="\${invertedClass}"
        mouseenter.trigger="mouseEventsHandler(\$event)"
        mouseleave.trigger="mouseEventsHandler(\$event)"
        name="content-dropdown"
    >
        <div class="dropdown" if.bind="!isLoading">
            <button
                class="btn text-normal font-medium d-flex flex-row align-items-center \${ openDropdown || contentHovered ? 'show' : '' } \${ isHover || isClicked ? 'bottom-border' : '' }"
                type="button"
                id="dropdownMenuButton"
                click.delegate="mouseEventsHandler(\$event)"
                name="content-dropdown-buttom"
                ref="contentButton"
            >
                \${ title }
                <span class="material-icons arrow">keyboard_arrow_down</span>
            </button>
        </div>
    </div>
</template>
`;
// Exports
export default code;