// Module
var code = `<template>
    <div class="notification__check-container check-container">
        <mdc-form-field class="check-container__form-field text-white col-12 cursor-pointer \${disabled ? 'pe-none' : ''}">
            <mdc-checkbox
                    change.delegate="onChange()"
                    value.bind="value"
                    checked.bind="value"
                    class="checkbox"
            ></mdc-checkbox>
            <label class="check-container__title cursor-pointer">\${title}</label>
        </mdc-form-field>
        <p class="check-container__subtitle">
            \${subtitle}
        </p>
    </div>
</template>
`;
// Exports
export default code;