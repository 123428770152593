// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/discord_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/instagram_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/twitter_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/facebook_white.svg", import.meta.url);
// Module
var code = `<template>
    <div id="social-media" class="d-flex flex-row justify-content-between">
            <cx-touch-target expanded>
            <a href="https://discord.gg/chicksx-com" target="_blank" rel="nofollow noopener noreferrer">
                <div class="social-media-icons">
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="Social Media" loading="lazy" class="discord">
                </div>
            </a>
            </cx-touch-target>
            <cx-touch-target expanded>
            <a href="https://www.instagram.com/chicksxcrypto/" target="_blank" rel="nofollow noopener noreferrer">
                <div class="social-media-icons">
                    <img src="${___HTML_LOADER_IMPORT_1___}" alt="Social Media" loading="lazy" class="instagram">
                </div>
            </a>
            </cx-touch-target>
            <cx-touch-target expanded>
            <a href="https://twitter.com/ChicksXcrypto" target="_blank" rel="nofollow noopener noreferrer">
                <div class="social-media-icons">
                    <img src="${___HTML_LOADER_IMPORT_2___}" alt="Social Media" loading="lazy" class="twitter">
                </div>
            </a>
            </cx-touch-target>
            <cx-touch-target expanded>
            <a href="https://www.facebook.com/chicksxcrypto" target="_blank" rel="nofollow noopener noreferrer">
                <div class="social-media-icons">
                    <img src="${___HTML_LOADER_IMPORT_3___}" alt="Social Media" loading="lazy" class="facebook">
                </div>
            </a>
            </cx-touch-target>
    </div>
</template>
`;
// Exports
export default code;