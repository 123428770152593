import './cx-custom-title.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class CxCustomTitle {
    constructor(
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private eventAggregator: EventAggregator,
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable title = '';
    @bindable titleEdit = false;
    @bindable sectionsFaq = [];
    showSuggestions;
    titleSuggestions;
    inputTicketTitle;
    allQuestions = [];
    isQuestion = false;
    questionSelected;
    inputElement;
    relevantSuggestions = [];
    changeTitleWatch;
    timeouts;
    titleElement = document.getElementById('title');

    attached() {
        this.inputElement = document.getElementById('inputTicketTitle').getElementsByTagName('input')[0];
        this.titleElement = document.getElementById('title');
        this.titleElement.removeAttribute('mdc-tooltip');
        if (this.sectionsFaq.length > 0) {
            this.sectionsFaq.sort((a, b) => {
                return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position;
            });
            this.allQuestions = this.sectionsFaq.map(item => item.questions).flat();
        }
    }

    toggleTitleEdit() {
        if (!this.isQuestion) {
            this.titleEdit = !this.titleEdit;
        }
        setTimeout(() => {
            this.onFocus();
            if (this.isQuestion) {
                this.closeQuestion();
            }
        }, 50);
    }

    setTicketTitle(ev) {
        ev.preventDefault();
        this.titleEdit = this.showSuggestions = this.isQuestion = false;
    }

    onSuggestionSelect(id) {
        this.questionSelected = this.allQuestions.find(item => item.id === id);
        this.title = this.questionSelected.question;
        this.isQuestion = true;
        this.onFocus();
    }

    closeQuestion() {
        this.isQuestion = false;
    }

    onKeyUp() {
        this.timeouts = [this.changeTitleWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.changeTitleWatch = setTimeout(() => {
            if (this.title) {
                this.showSuggestions = true;
                if (this.showSuggestions) {
                    this.onFocus();
                    this.searchSuggestions();
                }
            } else {
                this.showSuggestions = false;
                this.isQuestion = false;
            }
        }, 50);
    }

    onMouseOver() {
        const el = document.querySelector('.mdc-tooltip.mdc-tooltip--shown');
        const elChild = el?.getElementsByTagName('div')[0];
        this.titleElement.removeAttribute('mdc-tooltip');
        el?.setAttribute('hidden', '');
        if (this.titleElement.offsetWidth < this.titleElement.scrollWidth) {
            this.titleElement.setAttribute('mdc-tooltip', `value.bind: ${this.title}; xPosition.bind: 'CENTER'; yPosition: 'BELOW';`);
            if (el) {
                el.removeAttribute('hidden');
                elChild.innerHTML = this.title;
            }
        }

        this.eventAggregator.publish('tooltip-shown');
    }

    searchSuggestions() {
        this.relevantSuggestions = [];
        const splitTitle = this.title.toLowerCase().split(' ');
        for (const question of this.allQuestions) {
            if (question.question && question.answer) {
                let countWordsQuestion = 0;
                let countWordsAnswer = 0;

                if (question.question.toLowerCase().includes(this.title)) {
                    countWordsQuestion++;
                }
                if (question.answer.toLowerCase().includes(this.title)) {
                    countWordsAnswer++;
                }

                splitTitle.forEach(item => {
                    if (question.question.toLowerCase().includes(item)) {
                        countWordsQuestion++;
                    }
                    if (question.answer.toLowerCase().includes(item)) {
                        countWordsAnswer++;
                    }
                });

                if (countWordsQuestion > 0 || countWordsAnswer > 0) {
                    this.relevantSuggestions.push({ question: question, countWordsQuestion: countWordsQuestion, countWordsAnswer: countWordsAnswer });
                }
            }
        }

        this.relevantSuggestions.sort((a, b) => b.countWordsAnswer - a.countWordsAnswer).sort((a, b) => b.countWordsQuestion - a.countWordsQuestion);
    }

    onFocus() {
        this.inputTicketTitle.focus();
        this.searchSuggestions();
    }
}
