import { autoinject } from 'aurelia-dependency-injection';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class ScriptService {
    constructor(private helper: Helper) { }

    injectIntercomScript() {
        this.helper.injectScript('intercom-script', 'https://widget.intercom.io/widget/vese6jb0', true);
    }

    injectGoogleScript() {
        this.helper.injectScript('google-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwRB5w56D8uTYXbNythSvbzJykWFqhgQg&loading=async&callback=Function.prototype&libraries=places');
    }

    injectGoogleSignInScript() {
        this.helper.injectScript('google-sign-in-script', 'https://accounts.google.com/gsi/client');
    }

    injectAppleSignInScript() {
        this.helper.injectScript('apple-sign-in-script', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
    }
}
