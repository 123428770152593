/* eslint-disable @typescript-eslint/no-explicit-any */
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { debug, fingerprintApiKey, fingerprintDomainUrl, environmentName } from 'environment';
import { Currency, PaymentMethodWebsite } from 'services/models/purchase-flow/exchange';
import sanitizeHtml from 'sanitize-html';
import FingerprintJS, { defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro';
import { Router } from 'aurelia-router';
import { CurrencyFormatValueConverter } from '../value-converters/currency-formatter';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';
import { ISizeEvent } from 'types/events';
import { ApiService } from 'services/api-service';
import { ToastService } from 'services/toast-service';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { AnyObject } from 'chart.js/dist/types/basic';
import * as constants from 'resources/constants';
import { ToastType } from 'services/models/toast';
import { User } from 'services/models/user/user';
import { PerformanceMetricsService } from '../../services/performance-metrics-service';
import moment from 'moment';

interface DataExchange {
    giveSelectedCurrency: Currency;
    giveCurrencyOptions: Currency[];
    receiveSelectedCurrency: Currency;
    receiveCurrencyOptions: Currency[];
    selectedPaymentMethod: PaymentMethodWebsite;
    receivingPaymentMethod: PaymentMethodWebsite;
}

interface DataLists {
    currencyList: Currency[];
    cryptoMethodsList: Currency[];
    cryptoList: Currency[];
}
@autoinject()
export class Helper {
    constructor(
        private eventAggregator: EventAggregator,
        private toastService: ToastService,
        private performanceMetrics: PerformanceMetricsService,
    ) {
        this.matcher = new RegExpMatcher({
            ...englishDataset.build(),
            ...englishRecommendedTransformers
        });

        this.getResolutions(this);
    }

    matcher: RegExpMatcher;
    timeouts: NodeJS.Timeout[];
    styleHeightTimeout: NodeJS.Timeout;
    hiddenBounceTimeout1: NodeJS.Timeout;
    hiddenBounceTimeout2: NodeJS.Timeout;
    signInOptions = ['login', 'sign_up', 'CompleteRegistration', 'SignUp'];
    fingerprint;
    pendingRequests = new Map();
    serviceState = {};
    loadingState: string[] = [];
    skeletonListeners: { [key: string]: (ev: KeyboardEvent) => void } | undefined;
    desktop: number;
    phone: number;

    generateRandomString = (length = 8) => Math.random().toString(36).substring(0, length);

    // ----- DOM Functionality -----

    // Needs to have eventAggregator imported to work.
    resizeByNavbar(obj, parent, navSelector = '#navigation-bar', height = null) {
        const navbar = document.querySelector(navSelector);
        const app = document.querySelector(parent);
        this.changePaddingByNavbar(app, navbar, height);
        obj.eventAggregator.publish('observe-element', ({ selector: navSelector }));
        obj.navBarSubscriber = obj.eventAggregator.subscribe(`size-changed-${navSelector.removeSelectorSymbol()}`, () => this.changePaddingByNavbar(app, navbar, height));
    }

    changePaddingByNavbar = (app, navbar, height) => {
        app.style.marginTop = navbar.clientHeight.toString() + 'px';
        if (!height) return;
        app.style.height = `calc( ${height} - ${app.style.marginTop})`;
    };

    handleMainPageScroll(isOpen = true) {
        const disableMainPageScroll = document.getElementById('main-page-host');
        if (isOpen) {
            disableMainPageScroll.style.overflow = 'hidden';
        } else {
            disableMainPageScroll ? disableMainPageScroll.style.overflow = null : '';
        }
        this.eventAggregator.publish('drawer-closed', { isClosed: false });
    }
    // ----- Object Functionality ----

    userNameFormat(user, veriffUser, dOb = false) {
        const fullNameData = veriffUser?.firstName || veriffUser?.lastName
            ? veriffUser
            : user;

        const fullName = this.getFullName(fullNameData);
        const dateOfBirth = dOb ? this.dateFormat(veriffUser?.dateOfBirth, 'MM/DD/YY') : null;
        return this.joinString([fullName, dateOfBirth], ' | ');
    }

    getFullName = (data) => {
        const names = [data?.firstName, data?.lastName].map(x => x?.toCapitalCase(null));
        return this.joinString(names);
    };

    copyArrayOfObjects = (arr) => {
        const copy = [...arr];
        return copy.map(x => Object.assign({}, x));
    };

    getEnumName(enumVar: object, value: number | string): string {
        return value || value === 0 ? Object.keys(enumVar).find(x => enumVar[x] === value) : undefined;
    }

    /*
    This function will recieve an object, and set the specified value to all properties that include any of the specified names, and not modify those that include the
    string that are inside of the exclude array, if it exists.
    */
    setPropertiesByName(obj, names, value, exclude = []) {
        this.getPropertiesByName(obj, names, exclude).forEach(x => obj[x] = value);
    }

    getPropertiesByName(obj, names, exclude = []) {
        return Object.keys(obj)?.filter(x => this.includesWithout(x, names, exclude));
    }

    getVariablesByName(obj, names, exclude = []) {
        return this.getPropertiesByName(obj, names, exclude).map(x => obj[x]);
    }

    // ----- General Functionality ----

    phoneFormat(value, code) {
        if (!value) {
            return '-';
        }

        const aux = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        const part1 = aux[1] || '';
        const part2 = aux[2] || '';
        const part3 = aux[3] || '';

        return `+${code ?? ''}${part1 ? ` ${part1}` : ''}${part2 ? `-${part2}` : ''}${part3 ? `-${part3}` : ''}`;
    }

    handlePasteNumberValidation = (event: ClipboardEvent, element) => {
        if (!element) return;
        const clipData = event.clipboardData || window['clipboardData'];
        const text = clipData?.getData('text/plain');
        if (!text?.toLowerCase()?.includes('e') && !text?.includes('+') && !text?.includes('-')) return true;
        event?.preventDefault();
        return false;
    };

    debounce = (obj, fieldName, timeoutName, time = 2000, callback = null) => {
        obj[fieldName] = true;
        if (obj[timeoutName]) {
            clearTimeout(obj[timeoutName]);
            obj[timeoutName] = null;
        }
        obj[timeoutName] ??= setTimeout(async() => {
            obj[timeoutName] = null;
            obj[`${fieldName}Inner`] = true;
            const response = await callback?.();
            obj[`${fieldName}Inner`] = false;
            if (!response) obj[fieldName] = false;
        }, time);
    };

    disposeAll = (events) => {
        events?.forEach(x => {
            x?.dispose();
        });
    };

    disposeAllSubscribers(obj) {
        this.disposeAll(this.getVariablesByName(obj, ['Subscriber']));
    }

    subscribeEvents(obj, events) {
        Object.keys(events).forEach(event => {
            const eventName = this.camelize(event.replaceAll('-', ' ')) + 'Subscriber';
            obj[eventName] = obj.eventAggregator.subscribe(event, events[event]);
        });
    }

    subscribeSignalRConnections(connection, events) {
        if (!connection) {
            return;
        }
        connection.listeners = Object.keys(events);
        connection.listeners.forEach(event => {
            connection.on(event, events[event]);
        });
    }

    killSignalRListeners = (connection, listeners?: string[]) => {
        listeners ??= connection.listeners;
        listeners.forEach(x => {
            connection?.off(x);
        });
    };

    getTrustPilotStarRatingVariables(obj : AnyObject, pageContentArea: PageContentArea[]) {
        obj.trustPilotStarRating = pageContentArea?.find(x => x.key === 'CX_TRUST_PILOT_RATING')?.markup ?? '4.5';
        obj.trustPilotStarRating = this.sanitizeHtml(obj.trustPilotStarRating, true);
        obj.trustPilotStarRating = obj.trustPilotStarRating.replace(',', '.');
        obj.trustPilotStarRating = Number(obj.trustPilotStarRating).toFixed(1).toFloat();
        obj.amountOfStars = obj.trustPilotStarRating.toInt();
        const checker = (obj.trustPilotStarRating % 1 !== 0) && (obj.trustPilotStarRating - obj.trustPilotStarRating?.toInt()).toFloat().toFixed(1);
        obj.halfStar = checker.toFloat() < 0.6;
        obj.semiSesquiStar = !obj.halfStar;
    }

    // ----- Date/Time Functionality ----

    dateFormat(value: string | Date, type = 'calendar', format?: string, locale = 'en-US') {
        if (!value) {
            return;
        }

        if (!format) {
            format = 'MMMM Do YYYY, h:mmA';
        }

        if (type === 'calendar') {
            return moment(value).locale(locale).calendar();
        } else if (type === 'format') {
            return moment(value).locale(locale).format(format);
        }

        if (type === 'dateFromNow') {
            moment.updateLocale('en', {
                relativeTime: {
                    h: '1 hour'
                }
            });
            return moment.utc(value).local().fromNow();
        }

        if (type === 'timeless') {
            const date = moment(value);
            return date.format('MMMM YYYY');
        }

        return moment.utc(value).local().format(format);
    }

    // ----- String Functionality -----

    joinString = (str, separator = ' ', def = '') => str?.filter(x => Boolean(x?.trim()))?.join(separator) ?? def;

    isFile = (str: string, noRoute: boolean = false) => /.*\.[^/\\]+$/.test(str) && (noRoute ? this.excludeAll(str, ['/', '\\']) : true);

    camelize = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

    isProfane = (str) => this.matcher.hasMatch(str);

    removeSelectorSymbol = (selector) => selector.replace(/^[#.:[]?([a-zA-Z_-][\w-]*).*/, '$1');

    toPascal = (str) => this.toCapitalize(this.camelize(str), 'first');

    // Check if the string is empty
    isEmpty = (str: string) => (!str?.trim()?.length);

    updateOptionLists = async (dataExchange: DataExchange, dataLists: DataLists, type?: 'B' | 'S' | 'FF') => {
        let auxiliarGiveList: Currency[] = [];
        let auxiliarReceiveList: Currency[] = [];

        if (type === 'B' || !type || (dataExchange?.receivingPaymentMethod?.paymentMethod && !dataExchange?.receivingPaymentMethod?.paymentMethod?.reference.includes('cash-in-person'))) {
            auxiliarGiveList = [...dataLists.currencyList];
            auxiliarReceiveList = [...dataLists.cryptoList];
        } else if (type === 'S') {
            auxiliarGiveList = [...dataLists.cryptoMethodsList];
            auxiliarReceiveList = [...dataLists.currencyList];
        } else if (type === 'FF') {
            auxiliarGiveList = [...dataLists.currencyList];
            auxiliarReceiveList = [...dataLists.currencyList];
        }

        return { auxiliarGiveList, auxiliarReceiveList };
    };

    redirectWithOptionsSelected(router, pageRoute, data, intent: 'buy' | 'sell' | 'swap/fiat' | 'swap/crypto') {
        let queryParams = '';
        const hasFrom = Object.keys(data).includes('from');
        const hasTo = Object.keys(data).includes('to');

        if (!hasFrom && !hasTo) {
            queryParams = data ?? pageRoute;
        }
        else
            queryParams += `${intent}/${data.from.toLowerCase()}-to-${data.to.toLowerCase()}`;
        router.parent.navigate(`/${queryParams}`);
    }

    getTradeIntent(give: Currency, receive: Currency): 'buy' | 'sell' | 'swap/fiat' | 'swap/crypto' {
        if (give.type === 'F' && receive.type === 'C')
            return 'buy';
        if (give.type === 'C' && receive.type === 'F')
            return 'sell';
        if (give.type === 'F' && receive.type === 'F')
            return 'swap/fiat';
        if (give.type === 'C' && receive.type === 'C')
            return 'swap/crypto';
    }

    handleGtagEvent = (eventName: string, itemObject = null, currency: string, price: number, couponCode?: string, method?: string) => {
        this.removeGtagEvent();
        if (!itemObject && !price && !this.signInOptions.includes(eventName)) return;
        const script = document.createElement('script');
        script.setAttribute('id', 'ga4-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];

        script.innerHTML = `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-13NV7SGM8N');
                            
                            gtag('event', '${eventName}'`;

        if (!this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && (debug() || method))) script.innerHTML += ', {';

        if (price) price = Math.abs(price);

        if (price) {
            script.innerHTML += `'currency': "${currency}",
                                 'value': ${price}`;
        }

        if (debug()) {
            if (price) script.innerHTML += ', ';
            script.innerHTML += '\'debug_mode\': true';
        }

        if (couponCode) {
            if (price || debug()) script.innerHTML += ', ';
            script.innerHTML += `'coupon': "${couponCode}"`;
        }

        if (itemObject?.length) {
            if (price || debug() || couponCode) script.innerHTML += ', ';
            script.innerHTML += '\'items\': [';

            for (const [index, item] of Array.from(itemObject as any[]).entries()) {
                script.innerHTML += `{
                                        'item_name': "${item.name}",\n`;

                script.innerHTML += '\'affiliation\': "ChicksX Inc.",\n';

                script.innerHTML += `'price': ${price},
                                    'quantity': 1
                                    }`;
                if (index < itemObject.length - 1) script.innerHTML += ',';
            }

            script.innerHTML += ']';
        }

        if (method) {
            if (debug()) script.innerHTML += ', ';
            script.innerHTML += `'method': "${method}"`;
        }

        if (debug() || !this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && method)) script.innerHTML += '}';
        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    handleTwitterEvent = (user: User) => {
        this.removeTwitterEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'twitter-event');
        script.setAttribute('type', 'text/javascript');
        script.innerHTML = `twq('event', 'tw-omvft-omvfx', {
                                email_address: ${user.email}
                            })`;
    };

    removeTwitterEvent = () => document.getElementById('twitter-event')?.remove();

    removeGtagEvent = () => document.getElementById('ga4-event')?.remove();

    toParams = (path, obj) => {
        if (!obj || this.isObjectEmpty(obj)) return path;
        return path + '?' + Object.keys(obj)
            .filter(x => obj[x] === 0 || obj[x])
            .map(x => {
                if (Array.isArray(obj[x])) {
                    return this.fromArrayToParams(x, obj[x], true);
                }
                return `${x}=${encodeURIComponent(obj[x])}`;
            })
            .join('&');
    };

    fromArrayToParams = (parameter, array, fromParams = false) => (fromParams ? '' : '?') + array.map(x => `${parameter}=${encodeURIComponent(x)}`).join('&');

    // Checks if object is empty
    isObjectEmpty = (obj) => obj ? Object.keys(obj).length === 0 : null;
    handleFacebookPixelEvent = (eventName, itemObject?, currency?, price?) => {
        if (debug()) return;
        this.removeFacebookPixelEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'fb-pixel-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `fbq('track', '${eventName}'`;

        if (!this.signInOptions.includes(eventName)) {
            script.innerHTML += ', {';

            if (currency) {
                script.innerHTML += `currency: "${currency}"`;
            }

            if (price) {
                script.innerHTML += `, value: ${price}`;
            }

            if (itemObject?.length) {
                script.innerHTML += ', contents: [';

                for (const [index, item] of Array.from(itemObject as any[]).entries()) {
                    script.innerHTML += `{
                                            id: "${item.id}",
                                            quantity: 1
                                        }`;
                    if (index < itemObject.length - 1) script.innerHTML += ',';
                }

                script.innerHTML += ']';

                if (eventName === 'InitiateCheckout') {
                    script.innerHTML += `, num_items: ${itemObject.length}`;
                }

                if (eventName === 'AddToCart') {
                    script.innerHTML += ', content_type: "product"';
                }
            }

            script.innerHTML += '}';
        }

        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeFacebookPixelEvent = () => document.getElementById('fb-pixel-event')?.remove();

    handleRedditEvent = (eventName, itemObject?, currency?, price?) => {
        if (debug()) return;
        this.removeRedditEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'reddit-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `rdt('track', '${eventName}'`;

        if (!this.signInOptions.includes(eventName)) {
            script.innerHTML += ', {';

            if (currency && price && eventName !== 'ViewContent') {
                script.innerHTML += `currency: "${currency}"`;
            }

            if (price && currency && eventName !== 'ViewContent') {
                script.innerHTML += `, value: ${price}`;
            }

            if (itemObject?.length) {
                script.innerHTML += `${currency && price && eventName !== 'ViewContent' ? ', ' : ''}products: [`;

                for (const [index, item] of Array.from(itemObject as any[]).entries()) {
                    script.innerHTML += `{
                                            id: "${item.id}",
                                            category: "Product",
                                            name: "${item.serviceFullName ?? item.name}"
                                        }`;
                    if (index < itemObject.length - 1) script.innerHTML += ',';
                }

                script.innerHTML += ']';

                if (eventName === 'AddToCart') {
                    script.innerHTML += `, itemCount: ${itemObject.length}`;
                }
            }

            script.innerHTML += '}';
        }

        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeRedditEvent = () => document.getElementById('reddit-event')?.remove();

    getCookieValue = (cookieName: string) => document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)?.pop();

    removeHtmlElements = (apiKeyMarkup: string) => apiKeyMarkup ? apiKeyMarkup.replace(/(<([^>]+)>)/gi, '').trim() : '';

    // Returns an array of numbers by the given amount
    range = (amount, startFromOne = false, excludeNumber?) => {
        let keys = [...Array(amount).keys()];
        keys = startFromOne ? keys.map(i => i + 1) : keys;
        if (typeof excludeNumber === 'number') excludeNumber = [excludeNumber];
        if (excludeNumber) return keys.filter(num => !excludeNumber.includes(num));
        return keys;
    };

    truncateInTheMiddle(str: string): string {
        if (!str) return '';
        return `${str.substring(0, 6)}...${str.substring(str.length - 4)}`;
    }

    getCardImageType = (cardType: string) => {
        const cardTypeMappings = {
            'vi': 'visa',
            've': 'visa',
            'vd': 'visa',
            'visa': 'visa',
            'mc': 'mastercard',
            'mastercard': 'mastercard',
            'master_card': 'mastercard',
            'am': 'amex',
            'amex': 'amex',
            'american express': 'amex',
            'dc': 'diners',
            'diners': 'diners',
            'diners club': 'diners',
            'di': 'discover',
            'discover': 'discover',
            'maestro': 'maestro',
            'jcb': 'jcb',
            'mada': 'mada'
        };

        const simplifiedType = cardTypeMappings[cardType?.toLowerCase()] || 'generic';
        return `/payment-methods/${simplifiedType}.svg`;
    };

    getCardName(type: string) {
        switch (type?.toLowerCase()) {
            case 'vi':
            case 'visa':
                return 'Visa';
            case 'mc':
            case 'mastercard':
            case 'master_card':
                return 'Mastercard';
            case 'am':
            case 'amex':
            case 'american express':
                return 'AMEX';
            default:
                break;
        }
    }

    injectScript = (id: string, src: string, async = false) => {
        const el = document.getElementById(id);
        if (el) return;
        const script = document.createElement('script');
        script.setAttribute('id', id);
        script.setAttribute('type', 'text/javascript');
        if (async) script.setAttribute('async', 'true');
        script.setAttribute('src', src);
        document.body.appendChild(script);
    };

    includesBy = (arr: string | string[], values: string[], func: string, name: string = 'includes'): boolean => values[func](x => arr?.[name](x));

    includesAll = (arr: string | string[], values: string[], name?: string) => this.includesBy(arr, values, 'every', name);

    includesSome = (arr: string | string[], values: string[], name?: string) => this.includesBy(arr, values, 'some', name);

    excludeAll = (arr: string | string[], values: string[], name: string = 'includes') => values?.every(x => !arr?.[name](x));

    calculateSpreadFee = (spreadFee: number, currentRate: number) => {
        if (spreadFee !== 0) {
            const fiatSpreadFee = 1 - (spreadFee / 100);
            return currentRate *= fiatSpreadFee;
        }
        return currentRate;
    };

    sanitizeHtml = (value, noHtml = false) => {
        if (!value) return '';
        const params = {
            allowedTags: ['div', 'p', 'img', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'section', 'li', 'ol', 'ul',
                'br', 'i', 'span', 'strong', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr'],
            allowedAttributes: {
                a: ['href', 'name', 'target', 'class'],
                img: ['src', 'alt', 'class']
            },
            selfClosing: ['img'],
            allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'data']
        };

        if (noHtml) {
            params.allowedTags = [];
            params.allowedAttributes = {} as { a: string[]; img: string[]; };
        }
        return sanitizeHtml(value, params);
    };

    getAddressByType = (placeDetails, type) => placeDetails.address_components?.find(x => x.types.includes(type));

    getAddressComponent = (placeDetails, type) => this.getAddressByType(placeDetails, type)?.long_name || '';

    getAddressShortName = (placeDetails, type) => this.getAddressByType(placeDetails, type)?.short_name || '';

    fetchFingerprintForUser = async() => {
        if (environmentName() === 'local') return;
        if (!this.fingerprint) {
            this.fingerprint = await FingerprintJS.load({
                apiKey: fingerprintApiKey(),
                scriptUrlPattern: [
                    `${fingerprintDomainUrl()}web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                    defaultScriptUrlPattern
                ],
                endpoint: [
                    fingerprintDomainUrl().slice(0, -1),
                    defaultEndpoint
                ]
            });
        }
        const result = await this.fingerprint.get();
        if (!result) return;
        return result.visitorId;
    };

    saveWindowLocalStorageValue(localStorage, value) {
        window.localStorage[localStorage] = value;
    }

    getWindowLocalStorageValue(localStorage) {
        return window.localStorage[localStorage];
    }

    destroyWindowLocalStorageValue(localStorage) {
        window.localStorage.removeItem(localStorage);
    }

    checkEntityStatus(value: string, fulfilled: boolean | string) {
        if (!value) {
            return;
        }

        const status = value.split(':')[1];

        //Orders & Notifications
        if (value.includes('complete') && (fulfilled === '1' || fulfilled === 'True' || fulfilled === true)) {
            return 'Completed';
        }
        if (this.includesSome(value, ['marked-sent', 'refund-requested', 'partially-refunded', 'partial-refund', 'refunded', 'rejected', 'created', 'partially-delivered', 'all', 'none'])) {
            return this.toCapitalize(status ? status?.replaceAll('-', ' ') : value.replaceAll('-', ' '), 'first');
        }
        if (['active', 'closed', 'draft', 'read', 'unread'].includes(value)) {
            return this.toCapitalize(value, 'first');
        }
        return 'Pending';
    }

    toCapitalize = (value: string, type?: string) => {
        if (!value) return '';
        if (type === 'first') {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        const sentence = value.split(' ');
        return sentence.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    toCapitalizeLastLetter = (value: string) => {
        if (!value) return '';
        return value.slice(0, -1) + value.slice(-1).toUpperCase();
    };

    singleSeparator = (str: string, separator: string = ' ') => str?.split(separator)?.filter(x => x)?.join(separator);

    parseInputAmount(value: number) {
        return +value % 1 === 0 ? parseFloat(value.toString()).toFixed(2) : value.toString();
    }

    toggleClasses(element, classToRemove, classToAdd) {
        if (!element) return;
        element.classList.remove(classToRemove);
        element.classList.add(classToAdd);
    }

    handlePrerender404 = (router: Router) => {
        const prerenderMeta = document.getElementById('prerender-404-status-code');
        if (router.currentInstruction.config.name === '404' && !prerenderMeta) {
            const prerenderMetaTag = document.createElement('meta');
            prerenderMetaTag.setAttribute('id', 'prerender-404-status-code');
            prerenderMetaTag.setAttribute('name', 'prerender-status-code');
            prerenderMetaTag.setAttribute('content', '404');
            document.head.appendChild(prerenderMetaTag);
        }
    };

    parseOrderStatus(value: string, fulfilled: boolean | string) {
        if (value.includes('complete') && (fulfilled === '1' || fulfilled === 'True' || fulfilled === true)) {
            return 'Completed';
        }

        if (this.includesSome(value, ['marked-sent', 'refund-requested', 'partially-refunded', 'refunded', 'rejected', 'created', 'partially-delivered', 'all', 'none'])) {
            return this.toCapitalize(value.replace('-', ' '), 'first');
        }

        if (['active', 'closed', 'draft', 'read', 'unread'].includes(value)) {
            return this.toCapitalize(value, 'first');
        }

        return 'Pending';
    }

    #includesBy = (arr: string | string[], values: string[], func: string) => values[func](x => arr?.includes(x));

    // Checks if Array or String contains values specified in includes, but also does not contain values specified in excludes.
    // Returns: Boolean defining if it matches.
    includesWithout(arr: string | string[], includes: string[], excludes: string[], explicit = false) {
        const func = explicit ? 'every' : 'some';
        return this.#includesBy(arr, includes, func) && this.excludeAll(arr, excludes);
    }

    formatByCurrency(value: number, currency: Currency, valueConverter: CurrencyFormatValueConverter) {
        return valueConverter.toView(value, currency.symbol, currency.type, currency.isStable);
    }

    compareBy = (var1, var2, modifier) => modifier(var1) === modifier(var2);

    isInRange = (amount, min, max) => {
        if (min > max) [max, min] = [min, max];
        return amount >= min && amount <= max;
    };

    validateCondition(condition, message, onFail = null, _title = 'Error', _type = 'error') {
        if (condition) return true;
        onFail?.();
        return false;
    }

    reEnableField = (obj, fieldName, timeoutName, time = 2000, callback = null) => {
        obj[fieldName] = true;
        if (obj[timeoutName]) {
            clearTimeout(obj[timeoutName]);
            obj[timeoutName] = null;
        }
        obj[timeoutName] ??= setTimeout(async() => {
            obj[timeoutName] = null;
            obj[`${fieldName}Inner`] = true;
            const response = await callback?.();
            obj[`${fieldName}Inner`] = false;
            if (!response) obj[fieldName] = false;
        }, time);
    };

    clearTimeouts(value) {
        if (!value) return;
        for (const timeout of value) {
            clearTimeout(timeout);
        }
    }

    static getScreenHeight() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    static getScreenWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    static getScreenSize(): ISizeEvent {
        return {
            height: this.getScreenHeight(),
            width: this.getScreenWidth()
        };
    }

    async handlePendingRequest(component, apiCall) {
        let isRequestPending = this.pendingRequests.get(component);
        if (isRequestPending === undefined) {
            isRequestPending = false;
            this.pendingRequests.set(component, isRequestPending);
        }

        while (isRequestPending) {
            await new Promise(resolve => setTimeout(resolve, 100));
            isRequestPending = this.pendingRequests.get(component);
        }

        this.pendingRequests.set(component, true);

        try {
            return await apiCall();
        } finally {
            this.pendingRequests.set(component, false);
        }
    }

    invalidateServiceData(serviceName: string) {
        this.serviceState[serviceName] = null;
    }

    async fetchData(api: ApiService, path: string, serviceName: string, forceFetch = false) {
        if (!this.serviceState[serviceName]) {
            this.serviceState[serviceName] = {
                responseData: null,
                isRequestPending: false,
                requestQueue: []
            };
        }

        const { responseData, isRequestPending, requestQueue } = this.serviceState[serviceName];

        if (responseData && !forceFetch) {
            return responseData;
        }

        if (isRequestPending) {
            return new Promise((resolve) => {
                requestQueue.push(resolve);
            });
        }

        try {
            this.serviceState[serviceName].isRequestPending = true;
            this.serviceState[serviceName].responseData = await api.doGet(`${path}`);
            this.processRequestQueue(serviceName);
            return this.serviceState[serviceName].responseData;
        } finally {
            this.serviceState[serviceName].isRequestPending = false;
        }
    }

    processRequestQueue(serviceName) {
        const { responseData, requestQueue } = this.serviceState[serviceName];
        while (requestQueue.length > 0) {
            const resolve = requestQueue.shift();
            if (resolve) {
                resolve(responseData);
            }
        }
    }

    clearServiceQueueState(serviceName) {
        this.serviceState[serviceName] = null;
    }

    getArrayOfHours(startHour: number, maxHour: number) {
        const hours: string[] = [];
        const intlFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

        for (let hour = startHour; hour < maxHour; hour++) {
            const date = new Date(0, 0, 0);
            date.setHours(hour, 0, 0, 0);
            hours.push(intlFormat.format(date));
            date.setMinutes(30);
            hours.push(intlFormat.format(date));
        }

        return hours;
    }

    convertTo24Hour(time: string): string {
        let [hours, minutes] = time.split(':');
        const modifier = minutes.slice(-2);
        minutes = minutes.slice(0, minutes.length - 2);

        if (modifier.toUpperCase() === 'PM' && hours !== '12') {
            hours = (parseInt(hours) + 12).toString();
        } else if (modifier.toUpperCase() === 'AM' && hours === '12') {
            hours = '00';
        }

        if (hours.length === 1) hours = `0${hours}`;

        return `${hours}:${minutes}`.replaceAll(' ', '');
    }

    separateByChunks(array, chunks) {
        const result = [];
        for (let i = 0; i < array?.length; i += chunks) {
            result.push(array.slice(i, i + chunks));
        }
        return result;
    }

    /**
     * Transform a string with a secure format for routing
     * @param {string} string
     * @returns {string} The secure-formatted string to use as a route
     */
    toRoute = (string) => string.toLowerCase().trim().replaceAll(' ', '-');

    timestampToDateHour(timestamp) {
        const date = new Date(timestamp * 1000);
        const intlFormat = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return intlFormat.format(date);
    }

    getResolutions(obj, suffix = '') {
        const resolutions = Object.keys(constants).map(x => x.split('device__')[1]).filter(x => x);
        resolutions.forEach(x => obj[x + suffix] = constants[`device__${x}`]);
    }

    handleDropdownWithAnimation = (pageElement, option, active = true, classOptionName = 'options-children') => {
        if (!pageElement) return;
        const childrenElement = pageElement.querySelector(`.${classOptionName}-${option.option}`);
        if (!childrenElement) return;
        this.timeouts = [this.styleHeightTimeout, this.hiddenBounceTimeout1, this.hiddenBounceTimeout2];
        this.clearTimeouts(this.timeouts);
        childrenElement.classList.remove('hidden__bounce');
        if (active) {
            const height = option.children.length * 50;
            childrenElement.style.height = `${height + 10}px`;
            childrenElement.classList.remove('hidden');

            this.styleHeightTimeout = setTimeout(() => childrenElement.style.height = `${height}px`, 300);
            return;
        }
        childrenElement.style.height = null;
        childrenElement.classList.add('hidden');
        this.hiddenBounceTimeout1 = setTimeout(() => {
            childrenElement.classList.toggle('hidden__bounce');
            this.hiddenBounceTimeout2 = setTimeout(() => childrenElement.classList.toggle('hidden__bounce'), 180);
        }, 200);
    };

    selectArray = (element, query) => Array.from(element?.querySelectorAll(query) ?? []);

    getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    actionAfterExists = (obj, variableName, query, callback = null, attempts = 4, time = 1000, instant = true) => {
        return this.actionAfterHandler(obj, variableName, query, callback, attempts, time, instant, 'query');
    };

    actionAfterCondition = (obj, variableName, condition, callback = null, attempts = 4, time = 1000, instant = true) => {
        return this.actionAfterHandler(obj, variableName, condition, callback, attempts, time, instant, 'condition');
    };

    actionAfterHandler = (obj, variableName, checking, callback = null, attempts = 4, time = 1000, instant = true, type) => {
        const fieldName = `${variableName}Checking`;
        const intervalName = `${variableName}Interval`;
        const attemptsName = `${intervalName}Attempts`;
        if (obj[intervalName]) return;

        obj[attemptsName] = 0;
        obj[fieldName] = true;

        const checkHandler = () => {
            if (obj[attemptsName] === attempts) {
                clearInterval(obj[intervalName]);
                obj[intervalName] = null;
                obj[fieldName] = false;
                return true;
            }
            const check = type === 'query' ? document.querySelector(checking) : checking?.();
            if (check) {
                callback?.(check);
                clearInterval(obj[intervalName]);
                obj[intervalName] = null;
                return true;
            }
            obj[attemptsName]++;
        };

        if (instant && checkHandler()) return;

        obj[intervalName] ??= setInterval(() => {
            if (checkHandler()) return;
        }, time);
    };

    addLoadingComponent(page, model?) {
        this.loadingState.push(page);

        if (!debug() || !model) {
            return;
        }

        this.skeletonListeners ??= {};

        const toggleLoading = ev => {
            if (ev.code === 'KeyE' && ev.shiftKey && ev.ctrlKey) {
                model.contentLoading = !model.contentLoading;
            }
        };

        if (!this.skeletonListeners[page]) {
            this.skeletonListeners[page] = toggleLoading;
            document.addEventListener('keypress', toggleLoading);
        }
    }

    removeSkeletonVisualizer(page) {
        if (!this.skeletonListeners?.[page]) return;
        document.removeEventListener('keypress', this.skeletonListeners[page]);
        delete this.skeletonListeners[page];
    }

    validateLoading(page) {
        if (this.loadingState.includes(page)) this.loadingState.splice(this.loadingState.indexOf(page), 1);
        if (!this.loadingState.length) this.eventAggregator.publish('page-loaded');
    }

    resetLoading = (page?) => {
        this.loadingState = [];
        if (!debug() || !page) return;
        this.removeSkeletonVisualizer(page);
    };

    createOrSelectElement(query, parent) {
        let element = parent.querySelector(query);
        if (element) return element;
        element = this.createElementFromSelector(query);
        parent.appendChild(element);
        return element;
    }

    createElementFromSelector(selector) {
        const pattern = /^(.*?)(?:#(.*?))?(?:\.(.*?))?(?:@(.*?)(?:=(.*?))?)?$/;
        const matches = selector.match(pattern);
        const element = document.createElement(matches[1] || 'div');
        if (matches[2]) element.id = matches[2];
        if (matches[3]) element.className = matches[3];
        if (matches[4]) element.setAttribute(matches[4], matches[5] || '');
        return element;
    }

    checkPreviousAttempts(hasFailedAttempts, originalMessage, action) {
        const type = hasFailedAttempts ? ToastType.INFO : ToastType.SUCCESS;
        if (hasFailedAttempts) originalMessage += ` Previous ${action} attempts were detected. For security, please review your account access and passwords. `;
        this.toastService.showToast(undefined, originalMessage, type);
    }

    redirectToSignIn(navigationInstruction, router) {
        const queryString = navigationInstruction?.queryString;
        const url = `/sign-in?redirect_url=${window.location.pathname.substring(1)}${queryString ? `?${encodeURIComponent(queryString)}` : ''}`;
        router?.navigate(url);
        return url;
    }

    validatorCheckOneCondition = (field, results) => !results.some(x => x.propertyName === field && !x.valid);

    widthHandler(obj) {
        const width = this.getWidth();
        const isDesktop = width > this.desktop;
        const isTablet = width > this.phone && width <= this.desktop;
        const isPhone = width <= this.phone;
        Object.assign(obj, { isDesktop, isTablet, isPhone });
    }

    copyProperties = (obj, obj2, props) => {
        props?.forEach(x => {
            obj[x] = obj2[x];
        });
    };

    elementsContainAnyClass = (elements, classes) => {
        return elements.some(x => this.containsAnyClass(x, classes));
    };

    containsAnyClass = (element, classes) => {
        return this.includesSome(element.classList, classes, 'contains');
    };

    combineApplicationLdJsonSchemasIntoOne = (schema, router = null) => {
        const existingGlobalSchema = document.getElementById('chicksx-schema');
        const existingGlobalSchemaText = existingGlobalSchema?.textContent || existingGlobalSchema?.innerText;
        const existingGlobalSchemaParsed = existingGlobalSchemaText ? JSON.parse(existingGlobalSchemaText) : null;
        if (existingGlobalSchema) existingGlobalSchema.remove();

        const globalSchema = document.createElement('script');
        globalSchema.setAttribute('id', 'chicksx-schema');
        globalSchema.type = 'application/ld+json';
        const combinedSchemas = `{
            "@context": "https://schema.org/",
            "@graph": [
                ${schema}${existingGlobalSchemaParsed ? ',' : ''}
                ${existingGlobalSchemaParsed?.['@graph'] ? existingGlobalSchemaParsed['@graph'].map(obj => JSON.stringify(obj)).join(',') : ''}
            ]
        }`;

        //Check for duplicated schemas and remove
        const combinedSchemasClone = JSON.parse(combinedSchemas);
        const seenTypes = {};
        const reversedSchema = combinedSchemasClone['@graph'].slice().reverse();

        combinedSchemasClone['@graph'] = reversedSchema.filter(item => {
            if (seenTypes[item['@type']]) return false;
            seenTypes[item['@type']] = true;
            return true;
        }).reverse();

        let finalizedCombinedSchemas = JSON.stringify(combinedSchemasClone, null, 4);

        if (router) {
            const schemasArray = [];

            if (!router.currentInstruction.config.hasBlogPostSchema) {
                schemasArray.push('BlogPosting', 'Person');
            }

            if (!router.currentInstruction.config.hasFinancialServiceSchema) {
                schemasArray.push('FinancialService', 'GeoCoordinates', 'OpeningHoursSpecification');
            }

            finalizedCombinedSchemas = this.removeFromGlobalSchema(combinedSchemasClone, schemasArray);
        }

        globalSchema.innerHTML = finalizedCombinedSchemas;
        document.head.appendChild(globalSchema);
    };

    removeFromGlobalSchema = (mainSchema, schemasArray) => {
        if (!mainSchema) return;
        mainSchema['@graph'] = mainSchema['@graph'].filter(obj => !schemasArray.includes(obj['@type']));
        return JSON.stringify(mainSchema, null, 4);
    };

    includesNumber = (number, array) => array.includes(number);

    fetchIPsForCustomer = async() => {
        if (debug()) return;
        let ipv4Address;
        let ipv6Address;

        try {
            const ipv4Fetch = await fetch('https://api.ipify.org');
            ipv4Address = await ipv4Fetch.text();
        } catch (error) {
            //skip
        }

        try {
            const ipv6Fetch = await fetch('https://api6.ipify.org');
            ipv6Address = await ipv6Fetch.text();
        } catch (error) {
            //skip
        }

        return { ipv4Address, ipv6Address };
    };

    static classExtender(...bases) {
        class Bases {
            [x: string]: any;

            constructor() {
                bases.forEach(base => Object.assign(this, new base()));
            }
        }
        bases.forEach(base => {
            Object.getOwnPropertyNames(base.prototype)
                .filter(prop => prop !== 'constructor')
                .forEach(prop => Bases.prototype[prop] = base.prototype[prop]);
        });
        return Bases;
    }

    handleStateOrNonceCheck = (localStorageName, stateOrNonceFromResponse, customToastMessage) => {
        const nonceOrStateFromLocalStorage = window.localStorage.getItem(localStorageName);

        if (stateOrNonceFromResponse !== nonceOrStateFromLocalStorage) {
            this.toastService.showToast('Error', customToastMessage, 'error');
            return false;
        }

        window.localStorage.removeItem(localStorageName);
        return true;
    };

    clamp = (value, max, min = 0) => Math.min(Math.max(value, min), max);

    convertNumberWithoutComma = (value, type = null) => {
        value = value?.toString().replaceAll(',', '');
        switch (type) {
            case 'float':
                return parseFloat(value);
            case 'int':
                return parseInt(value);
            default:
                return value;
        }
    };
}
