import './subscription.scss';
import { autoinject, computedFrom, observable, TaskQueue } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { ExtendedUser, TotalSpentByUser, User } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { SubscriptionService } from 'services/subscription-service';
import PaymentPlan from 'services/models/subscription/payment-plan';
import { CustomerService } from 'services/customer-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import {
    Billing,
    CashInPersonDetail,
    CouponCode,
    Currency,
    NuveiAddUPOCreditCardResponse,
    NuveiPaymentDataRequest,
    OrderDetail, PaymentMethod,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    RecentlySavedCC
} from 'services/models/purchase-flow/exchange';
import { SubscriptionOptions, SubscriptionSections } from 'services/models/subscription/subscription-enums';
import { IpAddress } from 'services/models/user/ipAddress';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { RowButtonsOption } from 'resources/elements/cx-row-buttons/cx-row-buttons';
import { CreditCardHelper } from 'resources/helpers/credit-card-helper';
import { UserVerification } from 'resources/extensions/user_verification';
import { ToastService } from 'services/toast-service';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { OrderRequest } from 'services/models/order';
import { baseUrl, websiteShortCode } from 'environment';
import { NuveiService } from 'services/nuvei-service';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { OrderService } from 'services/order-service';
import { StockProduct } from 'services/models/game/product';
import { AdminViewUpdatedEvent, CurrencyChangedEvent } from 'resources/constants';
import { IForceCurrencyEvent } from 'types/events';
import { ForceCurrencyEvent } from 'resources/constants';
import { UserSubscription } from 'resources/extensions/user_subscription';
import { CurrencyService } from 'services/currency-service';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import moment from 'moment';

interface CdsTabItem {
    label: string;
    id: string;
    value: SubscriptionOptions;
}

@autoinject()
export class Subscriptions extends Helper.classExtender(UserVerification, UserSubscription) {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private sessionService: SessionService,
        private toastService: ToastService,
        private fiatCurrencyFormatValueConverter: FiatCurrencyFormatValueConverter,
        private nuveiService: NuveiService,
        private deliveryDateConverter: DeliveryDateConverter,
        private orderService: OrderService,
        private pageContentAreaService: PageContentAreaService,
        private subscriptionService: SubscriptionService,
        private currencyService: CurrencyService,
        private eventAggregator: EventAggregator,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private customerService: CustomerService,
        private helper: Helper,
        private creditCardHelper: CreditCardHelper,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private taskQueue: TaskQueue
    ) {
        super();

        helper.getResolutions(this);
        helper.widthHandler(this);
    }

    @observable optionButtonSelected: RowButtonsOption;
    @observable selectedPaymentMethod: PaymentMethodWebsite;
    @observable meetupAddressValid: boolean = false;
    @observable trackingNumberValid: boolean = false;
    configureMethods: boolean;
    contentWrapper: HTMLElement;
    totalSpentByUser: TotalSpentByUser;
    validCountry: boolean;
    idVerificationCriteriaMet: boolean;
    phoneVerified: boolean;
    veriffStep: boolean;
    summaryButtonState: string;
    billing: Billing = new Billing();
    paymentMethodSelector;
    preferredCurrency: string;
    paymentMethods: PaymentMethodWebsite[] = [];
    userProxy: IpAddress;
    currencyUpdateSubscriber: Subscription;
    vpnDetectionSubscriber: Subscription;
    sizeChangedSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    forceCurrencySubscriber: Subscription;
    adminViewSubscriber: Subscription;
    viewingAsAdmin: boolean = false;
    step: number;
    contentLoading: boolean = true;
    section: SubscriptionSections = SubscriptionSections.Main;
    currentSection: string;
    customerPortalPage: PageByWebsite;
    customerPortalPageRoute: string = '';
    params: object = {};
    user: ExtendedUser;
    firstNameOnCard: string;
    lastNameOnCard: string;
    width: number;
    loadingSubscriptionBenefits: boolean = true;
    optionData: PaymentPlan;
    option: SubscriptionOptions = SubscriptionOptions.Annual;
    totalPrice: number;
    convertedTotalPrice: string;
    loadingFromAuth: boolean = false;
    reference: string;
    loadingComponent: boolean = true;
    userDocumentList = [];
    exchange;
    paymentProcessing: boolean;
    veriffViewModel;
    regularSize: number = 24;
    products: StockProduct[];
    coupon: CouponCode | null;
    ccRecentlySaved: RecentlySavedCC | null;
    selectedDelivery: DeliveryMethod;
    location: string;
    date: string;
    time: string;
    triggeredStartOrder: boolean;
    sections;
    toastSent: boolean;
    activeCurrencies: Currency[];
    freeTrialExpiresAt = moment().add(30, 'days');
    termsOfServicePageRoute: string;
    privacyPolicyPageRoute: string;
    tabsElement;
    tabs: CdsTabItem[] = [
        { label: this.siteStringsValueConverter.toView('CX_MONTHLY', 'Monthly'), id: `subscription-op-${SubscriptionOptions.Monthly}`, value: SubscriptionOptions.Monthly },
        { label: this.siteStringsValueConverter.toView('CX_ANNUAL', 'Annual'), id: `subscription-op-${SubscriptionOptions.Annual}`, value: SubscriptionOptions.Annual }
    ];

    async activate(params) {
        this.params = params;
        this.user = await this.sessionService.checkFreeTrialAndGetUser() as ExtendedUser;

        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }

        await this.pageContentAreaService.getByPageId(this.customerPortalPage?.id);
        this.firstNameOnCard = this.user.firstName;
        this.lastNameOnCard = this.user.lastName;
        await this.getSubscription();
        await this.getActiveSubscription(this.user);
        this.sections = SubscriptionSections;
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('subscription');
            this.width = this.helper.getWidth();
            this.helper.widthHandler(this);

            [
                this.preferredCurrency,
                this.paymentMethods,
                this.userProxy,
                this.activeCurrencies
            ] = await Promise.all([
                this.sessionService.getCurrency(),
                this.paymentMethodWebsiteService.getByWebsite(),
                this.sessionService.getUserProxy(),
                this.currencyService.getActiveCurrenciesByWebsite()
            ]);
            this.handleEventSubscriptions();
            this.startVerificationListeners();
            this.contentWrapper = document.querySelector('#main-page-host .simplebar-content-wrapper');

            await this.initSubscriptionData();
            this.loadingSubscriptionBenefits = false;

            await this.setConvertedTotalPrice();

            const incomingSection = Object.keys(this.sectionData).find(x => this.sectionData[x]?.route === this.router?.currentInstruction?.params?.childRoute);
            if (incomingSection) this.setSection(+incomingSection);

            this.totalSpentByUser = await this.customerService.getTotalSpentByUser(this.user.id);

            if (this.validCountry) {
                this.idVerificationCriteriaMet = await this.customerService.verificationCategoryCriteriaMet('ID & Selfie Verification');
            }
            await this.websiteService.getManyRoutes(this, ['Privacy Policy', 'Customer Portal', 'Terms Of Service']);

            this.setBillingForUser();
            await this.checkVerification(this.user, this.totalPrice, this.selectedPaymentMethod?.paymentMethod);

            const balancePaymentMethod = this.paymentMethodSelector?.paymentMethods?.find(e => e.paymentMethod?.name === 'Balance');
            if (this.paymentMethodSelector?.paymentMethods) {
                this.paymentMethodSelector.paymentMethods = this.paymentMethodSelector.paymentMethods.filter(e => e.paymentMethod?.name !== balancePaymentMethod?.name);
            }
        } finally {
            this.helper.validateLoading('subscription');
            this.taskQueue.queueTask(() => {
                const selectedTabIndex = this.tabs.findIndex(tab => tab.value === this.option);
                if (this.tabsElement) this.tabsElement.selectedIndex = selectedTabIndex;
            });
        }
    }

    detached() {
        this.step = 1;
        this.helper.disposeAll(this.helper.getVariablesByName(this, ['Subscriber']));
        this.helper.resetLoading();
    }

    handleEventSubscriptions() {
        this.currencyUpdateSubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, async payload => {
            this.preferredCurrency = payload.currencySelected.originalCode;
            this.sessionService.saveCurrency(this.preferredCurrency);
            await this.setCurrency(payload.currencySelected.originalCode, this.preferredCurrency);
            await this.setConvertedTotalPrice();
        });

        this.vpnDetectionSubscriber = this.eventAggregator.subscribe('vpn-detection', async(payload) => {
            if ((payload.vpn || payload.tor) && payload.ip !== '127.0.0.1' && payload.host !== 'localhost') {
                this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Please ensure you\'re using a genuine internet connection before making a purchase. The use of a VPN or TOR may result in failed attempts or a refund' });
            }
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            this.widthHandler();
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.contentLoading = false;
        });

        this.adminViewSubscriber = this.eventAggregator.subscribe(AdminViewUpdatedEvent, payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    async userVerificationUpdated(user: User) {
        await this.checkVerification(user, this.totalPrice, this.selectedPaymentMethod?.paymentMethod);
    }

    widthHandler() {
        this.helper.widthHandler(this);
        this.helper.debounce(this, 'resizing', 'resizeTimeout', 500, () => {
            if (!this.selectedPaymentMethod?.paymentMethod?.reference.includes('google-pay-direct') || !this.user) return;
            const element = document.querySelector('.cg-google-pay-box');
            if (element.children.length > 0) return;
        });
    }

    async autoTriggerOrder() {
        await this.sessionService.refreshProfile();
        await this.startOrder();
        if (!this.triggeredStartOrder) {
            this.triggeredStartOrder = true;
            this.startOrder();
        }
    }

    async setOptionData() {
        if (!this.option || !this.types) return;
        this.optionData = this.types[this.option];
        this.totalPrice = this.optionData?.price;
        await this.setConvertedTotalPrice();
    }

    setSection = (incomingSection) => {
        this.section = incomingSection;
        if (incomingSection === SubscriptionSections.Manage) this.router.navigateToRoute(this.sectionData[SubscriptionSections.Manage].route);
        if (incomingSection === SubscriptionSections.Cashback) this.router.navigateToRoute(this.sectionData[SubscriptionSections.Cashback].route);
    };

    setBillingForUser() {
        if (!this.user) return;
        this.user.street = this.user.address;
        this.helper.copyProperties(this.billing, this.user, ['street', 'country', 'city', 'state', 'zip']);
    }

    get disabledPayButton() {
        return !this.totalPrice || !this.selectedPaymentMethod || (!this.payEnabled && !this.veriffStep);
    }

    async checkVerification(user: User, totalPrice: number, selectedPaymentMethod?: PaymentMethod) {
        await this.checkForVerification(user, totalPrice, selectedPaymentMethod);

        if (selectedPaymentMethod?.reference === 'cash-in-mail' && !this.trackingNumberValid) this.payEnabled = false;
        else if (selectedPaymentMethod?.reference === 'cash-in-person' && !this.meetupAddressValid) this.payEnabled = false;
    }

    async phoneVerificationUpdated(phoneConfirmed: boolean) {
        if (!phoneConfirmed) {
            this.user.phoneVerified = false;
        }

        await this.checkVerification(this.user, this.totalPrice, this.selectedPaymentMethod?.paymentMethod);
    }

    optionButtonSelectedChanged() {
        this.option = this.optionButtonSelected.value as SubscriptionOptions;
        this.setOptionData();
    }

    async selectedPaymentMethodChanged(newValue: PaymentMethodWebsite) {
        const eventData: IForceCurrencyEvent = {
            currentPaymentMethodSelected: newValue,
            currency: this.preferredCurrency,
            forceSelector: true
        };

        this.eventAggregator.publish(ForceCurrencyEvent, eventData);
        this.loadingComponent = true;
        this.exchange = {
            selectedPaymentMethod: this.selectedPaymentMethod,
            receivingPaymentMethod: this.selectedPaymentMethod
        };
        this.reference = this.selectedPaymentMethod?.paymentMethod?.reference;
        await this.checkVerification(this.user, this.totalPrice, this.selectedPaymentMethod?.paymentMethod);
        this.loadingComponent = false;
    }

    async validatePaymentMethod() {
        if (!this.selectedPaymentMethod?.paymentMethod) return false;

        if (this.selectedPaymentMethod.paymentMethod.usesCard && !this.selectedPaymentMethod.paymentMethod.cardInfo) {
            this.toastService.showToast(
                'Payment method error',
                'Missing card information',
                'error'
            );
            return false;
        }

        if (this.totalPrice < this.selectedPaymentMethod.minimum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.minimum);
            this.toastService.showToast(
                'Payment method error',
                `Payment Method Minimum Not Met ${formattedAmount}`,
                'error'
            );
            return false;
        }

        if (this.totalPrice > this.selectedPaymentMethod.maximum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.maximum);
            this.toastService.showToast(
                'Payment method error',
                `Payment Maximum Exceeded ${formattedAmount}`,
                'error'
            );
            return false;
        }

        return true;
    }

    async canCreateOrder() {
        const paymentMethodValid = await this.validatePaymentMethod();
        if (!paymentMethodValid) return false;

        this.payEnabled = false;
        const amountRequiresVeriff = await this.requiresVeriff(this.user, this.selectedPaymentMethod.paymentMethod, this.totalPrice);

        if (amountRequiresVeriff || (this.veriffStep && !this.verified)) {
            this.veriffViewModel.startVerification();
            return false;
        }

        return true;
    }

    async validateCashMethods(isValid: boolean) {
        if (isValid) await this.checkVerification(this.user, this.totalPrice, this.selectedPaymentMethod?.paymentMethod);
        else this.payEnabled = false;
    }

    async trackingNumberValidChanged(newValue: boolean) {
        await this.validateCashMethods(newValue);
    }

    async meetupAddressValidChanged(newValue: boolean) {
        await this.validateCashMethods(newValue);
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }

    async startOrder() {
        try {
            const createOrder = await this.canCreateOrder();
            if (!createOrder) return;
            const reference = this.getReference(this.selectedPaymentMethod);

            if (reference === 'balance' && this.totalPrice > this.user.balance) {
                return this.toastService.showToast('Info', 'Insufficient funds. Please top up your Chicks Gold balance to continue.', 'info');
            }

            this.paymentProcessing = true;

            const amountRequiresVeriff = await this.requiresVeriff(this.user, this.selectedPaymentMethod.paymentMethod, this.totalPrice);
            if (!this.user.idVerified && (amountRequiresVeriff && this.idVerificationCriteriaMet)) {
                await this.veriffViewModel.startVerification();
            }
            else {
                const orderData = await this.buildOrder();
                const order = await this.orderService.createSubscriptionOrder(orderData);
                if (!order) {
                    this.handleError('Failed to create order');
                    this.paymentProcessing = false;
                    return;
                }
                this.finish(order, null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    async finish(order, url) {
        this.triggeredStartOrder = false;
        this.sessionService.savePurchased(true);

        if (url) this.router.navigate(url);
        else {
            this.router.navigateToRoute('order-completed', { id: order.id });
        }
    }

    async buildOrder() : Promise<OrderRequest> {
        this.products = await this.subscriptionService.getProductsBySubscriptionName();
        const product = this.products.filter(p => p.name.includes(this.optionData.productType))[0];
        let pylonPaymentData: PylonPaymentDataRequest;
        let nuveiPaymentData: NuveiPaymentDataRequest;
        let inPersonDetail: CashInPersonDetail;

        switch (this.selectedPaymentMethod.paymentMethod.reference) {
            case 'pylon': {
                pylonPaymentData = {
                    cardReferenceID: null,
                    baseUrl: baseUrl(),
                };
                break;
            }
            case 'nuvei': {
                let nuveiCardSaved: NuveiAddUPOCreditCardResponse | null = null;
                if (this.ccRecentlySaved) nuveiCardSaved = await this.nuveiService.addCreditCard(this.ccRecentlySaved);

                nuveiPaymentData = {
                    cvv: this.ccRecentlySaved?.cvv,
                    userPaymentOptionId: nuveiCardSaved ? nuveiCardSaved.userPaymentOptionId : parseInt(this.selectedPaymentMethod.paymentMethod.cardInfo.cardId ?? '0')
                };
                break;
            }
            case 'cash-in-person': {
                inPersonDetail = {
                    date: this.date,
                    location: this.location,
                    time: this.time,
                    meetingType: this.getDeliveryDate(this.selectedDelivery)
                };
                break;
            }
        }

        const orderDetail: OrderDetail = {
            cashInPersonDetail: inPersonDetail
        };

        return {
            websiteShortCode: websiteShortCode(),
            totalPrice: this.user.hasFreeTrial ? 0.00 : this.totalPrice,
            products: [{
                productId: product.id,
                quantity: 1,
                price: this.user.hasFreeTrial ? 0.00 : this.totalPrice,
                isFreeTrial: this.user.hasFreeTrial
            }],
            currencyUsed: this.preferredCurrency,
            paymentMethodId: this.selectedPaymentMethod.paymentMethod.id,
            status: this.getReference(this.selectedPaymentMethod) + ':created',
            address: this.ccRecentlySaved?.billingAddress?.address,
            city: this.ccRecentlySaved?.billingAddress?.city,
            state: this.ccRecentlySaved?.billingAddress?.state,
            country: this.ccRecentlySaved?.billingAddress?.country,
            zip: this.ccRecentlySaved?.billingAddress?.zip,
            couponCodeString: this.coupon?.code,
            referralLinkUrl: this.sessionService.getReferralLink(),
            firstName: this.firstNameOnCard,
            lastName: this.lastNameOnCard,
            cardLastFour: this.ccRecentlySaved?.ccCardNumber?.slice(-4),
            cardLast4: this.ccRecentlySaved?.ccCardNumber?.slice(-4),
            cardType: this.ccRecentlySaved?.ccCardNumber ? this.creditCardHelper.getType(this.ccRecentlySaved?.ccCardNumber) : null,
            payWithBalance: this.user.balanceEnabled && this.user.balance > 0,
            subscriptionId: this.subscription.id,
            orderDetail,
            pylonPaymentData,
            nuveiPaymentData,
        };
    }

    getReference = (method) => method?.paymentMethod?.reference;

    async handleError(e) {
        console.error(e);
        this.summaryButtonState = 'active';
        this.contentLoading = false;
        this.user = await this.sessionService.refreshProfile() as ExtendedUser;
        this.paymentProcessing = false;
    }

    async setCurrency(currency: string, oldCurrency: string | Currency) {
        let correctCurrency = '';

        if (oldCurrency && typeof oldCurrency === 'object') oldCurrency = oldCurrency.code;
        if (this.selectedPaymentMethod?.paymentMethod) {
            if (this.selectedPaymentMethod.supportedCurrencies?.length) {
                const currencies = this.selectedPaymentMethod.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
                if (!currencies.includes(oldCurrency as string)) {
                    currency = currencies[0];
                    correctCurrency = currencies.join(', ');
                } else {
                    currency = currencies.includes(this.preferredCurrency) ? this.preferredCurrency : oldCurrency as string;
                }
            }
        }
        if (correctCurrency !== '' && correctCurrency !== oldCurrency) {
            this.toastSent = true;
            await this.toastService.showToast('Info', `${this.selectedPaymentMethod?.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`, 'info');

            this.preferredCurrency = currency;
            await this.sessionService.saveCurrency(currency);
            setTimeout(() => {
                this.toastSent = false;
            }, 500);
        }
    }

    async setRenewalFunction() {
        if (!this.userSubscriptionInfo) return;
        this.user = await super.setRenewal(this.user);
    }

    async setConvertedTotalPrice() {
        this.convertedTotalPrice = await this.fiatCurrencyFormatValueConverter.toView(this.totalPrice, null, this.preferredCurrency);
    }

    setTab(event: CustomEvent) {
        this.option = this.tabs[event.detail.index].value;
        this.setOptionData();
        this.taskQueue.queueTask(() => this.setTabsStyles());
    }

    setTabsStyles() {
        const cdsTabs = document.querySelector('cds-tabs');
        if (cdsTabs) {
            const shadowRoot = cdsTabs.shadowRoot;
            if (shadowRoot) {
                const activeTab = shadowRoot.querySelector('md-primary-tab[active]') as HTMLElement;
                const allTabs = shadowRoot.querySelectorAll('md-primary-tab');

                allTabs.forEach((tab, key) => {
                    const tabEl = tab as HTMLElement;
                    if (key === 0) {
                        tabEl.style.cssText = '--md-primary-tab-container-shape-start-start: 4px;';
                        tabEl.style.borderStartStartRadius = '4px';
                    }

                    if (key === allTabs.length - 1) {
                        tabEl.style.cssText = '--md-primary-tab-container-shape-start-end: 4px;';
                        tabEl.style.borderStartEndRadius = '4px';
                    }
                    tabEl.style.border = 'none';
                    tabEl.style.borderBottom = '1px solid rgba(240, 240, 240, 1)';
                });

                if (activeTab) {
                    activeTab.style.border = '1px solid rgba(240, 240, 240, 1)';
                    activeTab.style.borderBottom = 'none';
                }
            }
        }
    }

    @computedFrom('meetupAddressValid', 'exchange.receivingPaymentMethod', 'exchange.selectedPaymentMethod')
    get showStepNotes() {
        if (!this.exchange) return;

        const { receivingPaymentMethod, selectedPaymentMethod } = this.exchange;

        const isNotCashOrInterac = !['cash-in-mail', 'cash-in-person', 'interac'].includes(receivingPaymentMethod.paymentMethod.reference);
        const hasPaymentStepNotes = Boolean(this.helper.sanitizeHtml(receivingPaymentMethod.paymentStepNotes || selectedPaymentMethod.paymentStepNotes, true).length);

        const isCashInPersonWithValidMeetup = (receivingPaymentMethod.paymentMethod.reference === 'cash-in-person' && this.meetupAddressValid) || isNotCashOrInterac;

        return ((isCashInPersonWithValidMeetup) || selectedPaymentMethod.paymentMethod.reference === 'interac') && hasPaymentStepNotes;
    }
}
