import { PageContentAreaService } from 'services/page-content-area-service';
import { CouponService } from 'services/coupon-service';
import './cx-coupon-input.scss';
import { bindable, autoinject, observable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { ToastService } from 'services/toast-service';
import { CouponCode, Currency } from 'services/models/purchase-flow/exchange';

@autoinject()
export class CxCouponInput {
    parent;
    timeouts: NodeJS.Timeout[] = [];
    triggeredCouponNotification = false;

    constructor(
        private couponService: CouponService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService,
        private pageContentAreaService: PageContentAreaService
    ) {

    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable text: string;
    @bindable youReceiveAmount: number;
    @bindable youGiveAmount: number;
    @bindable appliedDiscount: string;
    @bindable discountAmount: number;
    @bindable youReceiveCurrency: Currency;
    @bindable coupon: string;
    @bindable validCoupon: CouponCode;
    @bindable state: 'close' | 'toText' | 'error' | 'success';
    @bindable couponOpen = false;
    @bindable fromCheckout = false;
    @bindable rate: number;
    @bindable pageLoading: boolean = false;
    id: string;
    width: number;
    couponStopWatch: NodeJS.Timeout;
    couponStopWatch2: NodeJS.Timeout;
    couponStopWatch3: NodeJS.Timeout;
    miniSpinnerStopWatch: NodeJS.Timeout;
    showMiniSpinnerCoupon: boolean;
    couponFocusInStopWatch: NodeJS.Timeout;
    @observable tempCoupon: string;
    lastValidCoupon: CouponCode;

    created() {
        this.id = 'menu-' + Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.validCoupon) {
            await this.calculateCouponDiscount();
            this.state = 'toText';
            this.couponOpen = true;
        }
    }

    openCoupon() {
        this.clearCoupon();
        this.couponOpen = true;
        this.focusInput();
    }

    getElementStateClass(state: 'error' | 'warning' | 'success') {
        return `cx-form-control--${state}`;
    }

    clearCoupon() {
        this.coupon = null;
        this.tempCoupon = null;
        this.state = null;
        this.killTimeouts();
    }

    killTimeouts() {
        this.timeouts = [this.couponStopWatch, this.couponStopWatch2, this.couponStopWatch3, this.miniSpinnerStopWatch, this.couponFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
    }

    async tempCouponChanged() {
        if (!this.tempCoupon) return;
        this.state = null;
        this.showMiniSpinnerCoupon = false;
        this.killTimeouts();
        this.state = 'close';
        await this.checkCouponValidation();
    }

    couponUpdatedOnFocusIn() {
        this.tempCoupon = null;
        this.state = null;
        this.focusInput();
    }

    async couponOnFocusOut() {
        this.killTimeouts();
        if (!this.tempCoupon && !this.validCoupon) {
            this.couponOpen = false;
            return;
        }
        if (this.state === 'error') return;
        if (!this.validCoupon) {
            return await this.checkCouponValidation();
        }
        this.state = 'toText';
    }

    async checkCouponValidation() {
        if (this.tempCoupon) {
            try {
                this.miniSpinnerStopWatch = setTimeout(() => {
                    this.showMiniSpinnerCoupon = true;
                }, 1000);
                this.couponStopWatch = setTimeout(async() => {
                    if (this.tempCoupon !== this.coupon) {
                        const amountInUsd = await this.parent.getAmountInCurrency(this.youGiveAmount);
                        const validatedCoupon = await this.couponService.validateCoupon(this.tempCoupon.trim(), amountInUsd);
                        if (validatedCoupon) {
                            this.state = 'success';
                            this.showMiniSpinnerCoupon = false;
                            this.toastService.showToast('Success', 'You just added your ChicksX coupon to your exchange operation successfully.', 'success');
                            this.validCoupon = { ...validatedCoupon };
                            setTimeout(async () => {
                                await this.calculateCouponDiscount();
                                this.state = 'toText';
                            }, 1000);
                            this.coupon = this.tempCoupon;
                        } else {
                            this.showMiniSpinnerCoupon = false;
                            this.state = 'error';
                            this.triggeredCouponNotification = true;
                        }
                    } else {
                        this.showMiniSpinnerCoupon = false;
                    }
                }, 2000);
            } catch (e) {
                console.log(e);
            }
        }
    }

    focusInput() {
        setTimeout(() => {
            const input = document.getElementById('coupon-input');
            input?.focus();
        }, 100);
    }

    resetCoupon() {
        this.lastValidCoupon = { ...this.validCoupon };
        this.validCoupon = null;
        this.state = 'close';
        this.couponOpen = false;
        this.appliedDiscount = '';
    }

    async calculateCouponDiscount(giveAmount?: number) {
        let amountInUsd = 0;
        if (!this.validCoupon && this.lastValidCoupon && this.fromCheckout) {
            amountInUsd = await this.parent.getAmountInCurrency(giveAmount || this.youGiveAmount);
            if (!this.checkCouponAmount(this.lastValidCoupon, amountInUsd)) return;
            this.validCoupon = { ...this.lastValidCoupon };
            this.toastService.showToast('Success', 'You just added your ChicksX coupon to your exchange operation successfully.', 'success');
            return;
        }
        if (!this.validCoupon) return;
        amountInUsd = await this.parent.getAmountInCurrency(giveAmount || this.youGiveAmount);
        if (!this.checkCouponAmount(this.validCoupon, amountInUsd)) {
            let couponError = '';
            if (amountInUsd < this.validCoupon.minimumPurchase) couponError = `The minimum purchase for this coupon is ${this.validCoupon.minimumPurchase}`;
            if (amountInUsd > this.validCoupon.maximumPurchase) couponError = `The maximum purchase for this coupon is ${this.validCoupon.maximumPurchase}`;
            this.resetCoupon();
            this.toastService.showToast('Coupon removed', `${couponError}, please edit your order to get your discount`, 'info');
            return;
        }

        let discount = this.youReceiveAmount * (this.validCoupon.value / 100);

        if (this.validCoupon.type === 'Value') {
            if (this.parent?.transformUsdToReceiveCurrency) {
                discount = this.parent.transformUsdToReceiveCurrency(this.validCoupon.value);
            } else {
                discount = this.validCoupon.value;
            }
        }

        if (this.youReceiveCurrency.type === 'C')
            this.appliedDiscount = ` +${discount.toFixed(6)} ${this.youReceiveCurrency.symbol}`;
        else
            this.appliedDiscount = ` + ${this.youReceiveCurrency.symbol}${discount.toFixed(2)}`;
    }

    checkCouponAmount(coupon: CouponCode, amount: number) {
        return ((coupon.minimumPurchase > 0 && amount < coupon.minimumPurchase) || (coupon.maximumPurchase > 0 && amount > coupon.maximumPurchase)) ? false : true;
    }
}
