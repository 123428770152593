import { autoinject } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class SiteStringsValueConverter {
    signals = ['admin-view-updated'];

    constructor(private pageContentAreaService: PageContentAreaService) { }

    toView = (value: string, fallback = '', params: (string|number)[] = []) => this.pageContentAreaService.displaySiteString(value, fallback, params);
}
