// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/green_check.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-id-selfie-verification-progress">
        <div class="verification-step me-2">
            <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon me-2" alt="success" if.bind="verified" loading="lazy">
            <div class="number me-2" if.bind="!verified">1</div>
            \${'CX_ID_VERIFICATION' | siteStrings}
        </div>
        <div class="verification-step me-2">
            <div class="line me-2 d-none d-md-block"></div>
            <img src="${___HTML_LOADER_IMPORT_0___}" class="circle-icon me-2" alt="success" if.bind="verified" loading="lazy">
            <div class="number me-2" if.bind="!verified">2</div>
            \${'CX_SELFIE_VERIFICATION' | siteStrings}
        </div>
        <div class="verification-step" if.bind="flowPage">
            <div class="line me-2 d-none d-md-block"></div>
            <div class="number me-2">3</div>
            \${'CX_PAYMENT_INSTRUCTIONS' | siteStrings}
        </div>
    </div>
</template>
`;
// Exports
export default code;