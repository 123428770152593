import './manage-membership.scss';
import '../subscription.scss';
import { autoinject, observable, TaskQueue } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExtendedUser } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { SubscriptionService } from 'services/subscription-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { UserSubscription } from 'resources/extensions/user_subscription';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SubscriptionOptions, SubscriptionSections } from 'services/models/subscription/subscription-enums';
import { WebsiteService } from 'services/website-service';
import { CurrencyChangedEvent, SizeChangedEvent } from 'resources/constants';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { RowButtonsOption } from 'resources/elements/cx-row-buttons/cx-row-buttons';
import PaymentPlan from 'services/models/subscription/payment-plan';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { ToastService } from 'services/toast-service';

interface CdsTabItem {
    label: string;
    id: string;
    value: SubscriptionOptions;
}

@autoinject()
export class ManageMembership extends UserSubscription {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        sessionService: SessionService,
        subscriptionService: SubscriptionService,
        helper: Helper,
        eventAggregator: EventAggregator,
        pageContentArea: PageContentAreaService,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private fiatCurrencyFormatter: FiatCurrencyFormatValueConverter,
        protected siteStringsValueConverter: SiteStringsValueConverter,
        private taskQueue: TaskQueue,
        protected toastService: ToastService
    ) {
        super(eventAggregator, helper, subscriptionService, pageContentArea, sessionService);
        this.helper.widthHandler(this);
    }

    @observable optionButtonSelected: RowButtonsOption;

    user: ExtendedUser;
    preferredCurrency: string;
    sections;
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    currencyUpdateSubscriber: Subscription;
    faqPageRoute: string;
    backRoute: string = 'subscription';
    priceFormatted: string;
    optionData: PaymentPlan;
    option: SubscriptionOptions = SubscriptionOptions.Annual;
    sizeChangedSubscriber: Subscription;
    width: number;
    tabsElement;
    tabs: CdsTabItem[] = [
        { label: this.siteStringsValueConverter.toView('CX_MONTHLY', 'Monthly'), id: `subscription-op-${SubscriptionOptions.Monthly}`, value: SubscriptionOptions.Monthly },
        { label: this.siteStringsValueConverter.toView('CX_ANNUAL', 'Annual'), id: `subscription-op-${SubscriptionOptions.Annual}`, value: SubscriptionOptions.Annual }
    ];

    async activate() {
        this.user = await this.sessionService.checkFreeTrialAndGetUser() as ExtendedUser;
    }

    deactivate() {
        this.pageLoadedSubscriber?.dispose();
        this.currencyUpdateSubscriber?.dispose();
        this.sizeChangedSubscriber?.dispose();
    }

    async attached() {
        try {
            this.sections = SubscriptionSections;
            this.helper.addLoadingComponent('manage-membership');
            this.user = this.user ?? await this.sessionService.getProfile() as ExtendedUser;
            if (!this.user) {
                this.router.navigateToRoute('home');
                return;
            }
            this.handleEventSubscriptions();
            await super.getSubscription();
            await super.getActiveSubscription(this.user);
            await super.initSubscriptionData(true);
            this.option = this.userSubscriptionInfo?.paymentPlan ?? SubscriptionOptions.Monthly;
            this.backRoute = super.getBackRoute(this.sections.Manage);

            this.preferredCurrency = await this.sessionService.getCurrency();
            this.priceFormatted = await this.fiatCurrencyFormatter.toView(this.userSubscriptionInfo.price, null, this.preferredCurrency);

            this.faqPageRoute = `${await this.websiteService.getRouteOrDefault('FAQ')}?search=VIP`;
        } finally {
            this.taskQueue.queueTask(() => {
                const selectedTabIndex = this.tabs.findIndex(tab => tab.value === this.option);
                if (this.tabsElement) this.tabsElement.selectedIndex = selectedTabIndex;
            });
            this.helper.validateLoading('manage-membership');
        }
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });

        this.currencyUpdateSubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, async payload => {
            this.preferredCurrency = payload.currencySelected.originalCode;
            this.sessionService.saveCurrency(this.preferredCurrency);
            this.priceFormatted = await this.fiatCurrencyFormatter.toView(this.userSubscriptionInfo?.price, null, this.preferredCurrency);
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe(SizeChangedEvent, () => {
            this.helper.widthHandler(this);
        });
    }

    redirectToFaq = () => this.router.navigate(`/${this.faqPageRoute}`);

    setSection(incomingSection: SubscriptionSections) {
        if (incomingSection === SubscriptionSections.Details) this.router.navigateToRoute(this.sectionData[SubscriptionSections.Details].route);
        if (incomingSection === SubscriptionSections.Cancel) this.router.navigateToRoute(this.sectionData[SubscriptionSections.Cancel].route);
    }

    async setRenewalFunction() {
        if (!this.userSubscriptionInfo) return;
        this.user = await super.setRenewal(this.user);
    }

    async setOptionData() {
        if (!this.option || !this.types) return;
        this.optionData = this.types[this.option];
        if (this.pageLoading) return;
        const renewalDate = this.dateFormatterValueConverter.toView(this.userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy');
        await super.changePaymentPlan(this.option, this.user, this.optionData, renewalDate);
    }

    setTab(event: CustomEvent) {
        this.option = this.tabs[event.detail.index].value;
        this.setOptionData();
    }
}
