import './cx-check-container.scss';
import { bindable, autoinject } from 'aurelia-framework';

@autoinject()
export class CxCheckContainer {
    constructor(private element: Element) {}

    @bindable title: string;
    @bindable subtitle: string;
    @bindable disabled: boolean;
    @bindable onChange = () => {};
    @bindable value: boolean;
}

