import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';

export default class AuthFunctionality {
    tokenRequired: boolean;
    customerService: CustomerService;
    toastService: ToastService;
    email: string;
    user : UserForm;
    password: string;
    constructor() {}

    async lostVerification() {
        if (!this.tokenRequired) return;
        const response = await this.customerService.send2FAEmail(this.email?.trim() ?? this.user?.email.trim(), this.password?.trim() ?? this.user?.password?.trim());
        if (response) {
            this.toastService.showToast(undefined, 'We have sent you a recovery email!', ToastType.SUCCESS);
            return;
        }
        this.toastService.showToast(undefined, 'There was a problem while sending a recovery email.', ToastType.ERROR);
    }
}
