import { bindable } from 'aurelia-framework';
import { FadingFunctionality } from 'resources/extensions/fading_functionality';

export class CxInfiniteScroll extends FadingFunctionality {
    @bindable pageCount: number = 0;
    @bindable page: number = 0;
    @bindable load: (page: number) => Promise<void>;

    private scrollDataContainer: HTMLDivElement;
    private loading = false;

    attached() {
        this.setScrollContainer(this.scrollDataContainer);
        this.reinstanceScrollbar();
        this.createScrollEvent();
    }

    async scrollBottomReached() {
        if (this.page >= this.pageCount || this.loading) return;

        this.loading = true;
        await this.load(++this.page);
        this.loading = false;
    }
}
