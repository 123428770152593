import './cx-row-buttons.scss';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { Subscription } from 'aurelia-event-aggregator';

export class RowButtonsOption {
    value: string | number;
    label: string;
}

@autoinject()
export class CxRowButtons {
    constructor(
        private eventAggregator: EventAggregator,
        private taskQueue: TaskQueue,
        private helper: Helper
    ) {}

    @bindable options: RowButtonsOption[];
    @bindable maxWidthSelector: number;
    @bindable heightSelector: number;
    @bindable optionSelected: RowButtonsOption;
    @bindable backgroundColor: string;
    @bindable backgroundColorHover: string;
    @bindable backgroundColorActive: string;
    @bindable textColor: string;
    @bindable textColorActive: string;

    selector: HTMLDivElement;
    oldOptionSelected: RowButtonsOption;
    animationRunning: boolean;
    btnAnimated: HTMLElement;
    pageLoadedSubscriber: Subscription;
    pageLoading: boolean = true;

    bind() {
        this.handleSubscriptions();
    }

    attached() {
        if (!this.options) return;
        if (!this.optionSelected) this.optionSelected = this.options[0];
        this.setStyles();
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.taskQueue.queueTask(() => this.moveButtonSelector(this.optionSelected, false));
        });
    }

    changeOption(option: RowButtonsOption) {
        this.oldOptionSelected = this.optionSelected;
        if (this.oldOptionSelected === option) return;
        this.optionSelected = option;
        this.moveButtonSelector(option);
    }

    moveButtonSelector(option: RowButtonsOption, withTransition: boolean = true) {
        if (!option) return;
        this.animationRunning = true;
        const id = `row-button-${option.value}`;
        const elementToMove = document.getElementById(id);
        if (!elementToMove) return;

        const elementToMoveTarget = elementToMove.getBoundingClientRect();
        const parentElement = this.btnAnimated.parentElement;

        this.btnAnimated.style.transition = withTransition ? 'all 0.5s' : '';
        this.btnAnimated.style.width = elementToMove.clientWidth + 'px';
        this.btnAnimated.style.left = option !== this.options[0] ? `${elementToMoveTarget.left - parentElement.getBoundingClientRect().left}px` : '0px';

        setTimeout(() => this.animationRunning = false, 500);
    }

    selectorIsActive(option: RowButtonsOption) {
        if (!this.optionSelected) return '';
        return option.value === this.optionSelected.value ? 'active pe-none' : '';
    }

    setStyles() {
        if (this.maxWidthSelector) {
            this.selector.style.setProperty('--max-width-selector', `${this.maxWidthSelector}px`);
            this.selector.style.setProperty('--width-button-animated', `${ 100 / this.options.length }%`);
        }
        if (this.heightSelector) this.selector.style.setProperty('--height-selector', `${this.heightSelector}px`);
        if (this.backgroundColor) this.selector.style.setProperty('--background-color', `${this.backgroundColor}`);
        if (this.backgroundColorHover) this.selector.style.setProperty('--background-color-hover', `${this.backgroundColorHover}`);
        if (this.backgroundColorActive) this.selector.style.setProperty('--background-color-active', `${this.backgroundColorActive}`);
        if (this.textColor) this.selector.style.setProperty('--text-color', `${this.textColor}`);
        if (this.textColorActive) this.selector.style.setProperty('--text-color-active', `${this.textColorActive}`);
    }
}
