import './cx-credit-card-form.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { ICountry, IState } from 'country-state-city';
import { BillingAddress } from 'services/models/user/billingAddress';
import { Helper } from 'resources/helpers/helper';
import { User } from 'services/models/user/user';
import { CardInfo } from 'services/models/purchase-flow/exchange';
import { StagesCardForm } from 'resources/helpers/credit-card-helper';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class CxCreditCardForm {
    constructor(
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private helper: Helper,
        private eventAggregator: EventAggregator
    ) {}


    @bindable cardNumber: string;
    @bindable cardMonthYear:string;
    @bindable cardCvv: string;
    @bindable address: string;
    @bindable country: string;
    @bindable state: string;
    @bindable city: string;
    @bindable zip: string;
    @bindable billing: BillingAddress;
    @bindable countryPlaceholder = 'Country';
    @bindable statePlaceholder = 'State';
    @bindable countries: ICountry[];
    @bindable states: IState[];
    @bindable loading: boolean;
    @bindable saveFunction = () => {};
    @bindable cardType: string;
    @bindable @observable stage = StagesCardForm.INACTIVE;
    @bindable cardInfo: CardInfo;
    @bindable user: User;
    @bindable isExpDateValid: boolean;
    @bindable isCvvValid: boolean;
    @bindable @observable billingAddressIsValid: boolean;
    @bindable nameOnCard: { firstName: string, lastName: string };
    @bindable isCcRecentlySaved: boolean = false;
    @observable isCcValid: boolean;
    @bindable ignoreRoute: boolean = false;

    showGreenCheckMarkAddress: boolean;
    showMiniSpinnerAddress: boolean;
    timeouts: NodeJS.Timeout[];
    countryCode: string;
    error: boolean;
    isAddingStage: boolean = false;
    requestOnlyCvv: boolean = false;
    showFieldsAddress: boolean;
    billingAddressEl;
    editingStopwatch: NodeJS.Timeout;

    isCcValidChanged(newVal: boolean) {
        this.timeouts = [this.editingStopwatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!newVal) return;

        if (this.stage === StagesCardForm.EDITING && this.billingAddressIsValid || this.stage === StagesCardForm.REQUESTING_CVV) {
            this.editingStopwatch = setTimeout(() => {
                this.eventAggregator.publish('toggle-payment-methods', { state: false });
            }, 1000);
            return;
        }

        if (!this.billingAddressIsValid) {
            this.focusBillingAddressInput();
            return;
        }

        if (this.isAddingStage) {
            this.saveFunction();
        }
    }

    focusBillingAddressInput() {
        this.billingAddressEl?.au.controller.viewModel.billingAddressInput?.focus();
    }

    billingAddressIsValidChanged(newVal: boolean) {
        this.timeouts = [this.editingStopwatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!newVal || !this.isCcValid) return;

        if (this.stage === StagesCardForm.EDITING && this.isCcValid) {
            this.editingStopwatch = setTimeout(() => {
                this.eventAggregator.publish('toggle-payment-methods', { state: false });
            }, 1000);
            return;
        }

        if (this.isAddingStage) {
            this.saveFunction();
        }
    }

    stageChanged() {
        this.isAddingStage = this.stage === StagesCardForm.ADDING;
        this.requestOnlyCvv = this.stage === StagesCardForm.REQUESTING_CVV;

        if (this.stage === StagesCardForm.INACTIVE) {
            this.billingAddressIsValid = this.isCcValid = false;
        }
    }

    handleCardImage(type) {
        return this.helper.getCardImageType(type);
    }

    handleCardName(type) {
        return this.helper.getCardName(type);
    }
}
