import './cx-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxDropdown {
    @bindable options;
    @bindable selectedOption;
    @bindable placeholder: string;
    @bindable borderRadius: string;
    @bindable borderless: boolean;
    @bindable position: string;
    @bindable icon: string;
    @bindable pressed = false;
    showDeepName: boolean;
    dropdownContainers: NodeListOf<Element>;
    btnContainers: NodeListOf<Element>;
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.options[0].name ? this.showDeepName = true : this.showDeepName = false;
        this.dropdownContainers = document.querySelectorAll('.dropdown-menu');
        this.btnContainers = document.querySelectorAll('.dropdown_btn');
    }

    selectOption(option) {
        this.pressed = true;
        this.selectedOption = option;
        this.showDeepName ? this.placeholder = option.name : this.placeholder = option;
        this.dropdownContainers.forEach(container => {
            container.classList.remove('show');
        });

        this.btnContainers.forEach(container => {
            container.classList.remove('show');
        });
    }
}
