/* eslint-disable @typescript-eslint/no-explicit-any */
import { computedFrom } from 'aurelia-framework';
import { Callback } from 'resources/extensions/type_extensions';

export class NavigationOption {
    constructor(
        public option: string,
        public icon: string,
        public route?: string,
        public id?: string,
        public iconImage: boolean = false,
        public children?: NavigationOption[],
        public isActive: boolean = false,
        public isClicked: boolean = false,
        public activeNavigation: boolean = true,
        public isExpanded: boolean = false,
        public desktopHidden: boolean = false,
        public customContent?: HTMLElement,
        public callback?: Callback,
        public newTab: boolean = false,
        public description?: string,
        public orderedChildren?: NavigationOption[],
        public subtitle?: string,
        public parent?: NavigationOption,
        public keyName?: string,
        public isHover: boolean = false,
    ) {
        this.id = id ?? `${option}-link`;
    }

    setProp(prop: string, value: any): this {
        (this as any)[prop] = value;
        return this;
    }

    @computedFrom('isExpanded', 'isActive')
    get optionClass() {
        return `${this.isExpanded ? 'expanded' : ''} ${this.isActive ? 'active' : ''}`;
    }
}
