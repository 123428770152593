import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';

export class DeliveryDateConverter {
    toView(value: DeliveryMethod) {
        if (!value) return;
        let time: string;
        if (value.minHours || value.maxHours) {
            time = `${(value.minHours ? value.minHours + '-' : '')}${value.maxHours} hours`;
        }
        if (value.minDays || value.maxDays) {
            time = `${(value.minDays ? value.minDays + '-' : '')}${value.maxDays} day${value.maxDays === 1 ? '' : 's'}`;
        }
        return time;
    }
}
