import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Language } from './models/language/language';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class LanguageService {
    path = 'LanguageWebsite';
    languages;

    constructor(private api: ApiService, private helper: Helper) { }

    async getLanguages(): Promise<Language[]> {
        return this.helper.handlePendingRequest(this, async() => {
            if (this.languages) return this.languages;
            const result = await this.api.doGet(`${this.path}/ByWebsite?websiteShortCode=CX`);
            this.languages = result.map(lang => lang.language).sort((a, b) => {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                return 0;
            });
            return this.languages;
        });
    }
}
