import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SupportTicket, SupportTicketMessage, SupportTicketStatus } from 'services/models/support-ticket/support-ticket';

@autoinject()
export class SupportTicketService {
    path = 'SupportTicket';

    constructor(private api: ApiService) {}

    async createTicket(supportTicket: SupportTicket) {
        return this.api.doPost(this.path, supportTicket);
    }

    async getTicketsByCustomerId(userId: number, recordQuantity: number, page: number): Promise<SupportTicket[]> {
        const tickets = await this.api.doGet(`${this.path}/GetByCustomerId/${recordQuantity}/${page}`);
        const ticketStatus = SupportTicketStatus;
        tickets.forEach((ticket) =>
            ticket.messages?.length > 0 && (ticket.messages[ticket.messages?.length - 1]).userId !== userId
                ? ticket.status = 'pending'
                : ticket.status === ticketStatus.Closed
                    ? ticket.status = 'closed'
                    : ticket.status = 'active');
        return tickets;
    }

    async getTicket(supportTicketId: number) {
        const ticket = await this.api.doGet(`${this.path}/${supportTicketId}`);
        const ticketStatus = SupportTicketStatus;
        ticket.status === ticketStatus.Closed ? ticket.status = 'closed' : ticket.status = 'active';
        return ticket;
    }

    async chat(supportTicketId: number, message: SupportTicketMessage) {
        return this.api.doPost(`${this.path}/${supportTicketId}/chat`, message);
    }

    async getAvgResponseTime() {
        return this.api.doGet(`${this.path}/GetAvgResponseTime`);
    }

    async changeTicketStatus(supportTicketId, status) {
        return this.api.doPost(`${this.path}/${supportTicketId}/ChangeTicketStatus/${status}`, {});
    }

    async getTicketsCountByUserIdAndWebsite() {
        return this.api.doGet(`${this.path}/GetTicketsCount`);
    }

    async getTicketByKey(key) {
        return this.api.doGet(`${this.path}/GetTicketByKey/${key}`);
    }
}
