import './cx-credit-card-input.scss';
import { CardInfo } from 'services/models/purchase-flow/exchange';
import { MDCTextField } from '@material/textfield';
import { autoinject, TaskQueue, bindable, observable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { ToastService } from 'services/toast-service';
import { CreditCardHelper } from 'resources/helpers/credit-card-helper';
import { Helper } from 'resources/helpers/helper';
import { User } from 'services/models/user/user';
import { onlyNumberRegExp, numbersAndSlashExp } from 'resources/constants';
import { FullnameValueConverter } from 'resources/value-converters/fullname-formatter';
import { Router } from 'aurelia-router';

@autoinject()
export class CxCreditCardInput {
    constructor(
        private taskQueue: TaskQueue,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService,
        private creditCardHelper: CreditCardHelper,
        private helper: Helper,
        private fullnameValueConverter: FullnameValueConverter,
        private router: Router
    ) {}

    @bindable @observable cardNumber: string;
    @bindable cardMonthYear:string;
    @bindable cardCvv: string;
    @bindable cardType: string;
    @bindable cardInfo: CardInfo;
    @bindable user: User;
    @bindable nameOnCard: { firstName: string, lastName:string };
    @bindable isExpDateValid: boolean = false;
    @bindable requestOnlyCvv: boolean = false;
    @bindable isCvvValid: boolean = false;
    @bindable isCcValid: boolean = false;
    @bindable ignoreRoute: boolean = false;

    cardNumberDisplayed: string = '';
    showGreenCheckMark = false;
    showErrorCheckMark = false;
    showMiniSpinner = false;
    cardNumberIsFocused = false;
    isCCNumberValid = false;
    isCardNumberValid = false;
    cardMonthYearEl: MDCTextField;
    cardNumberEl: MDCTextField;
    cardCVVEl: HTMLElement;
    miniSpinnerTimer: NodeJS.Timeout;
    checkIsCCNumberValidTimer: NodeJS.Timeout;
    checkExpDateValidTimer: NodeJS.Timeout;
    checkIsCVVValidTimer: NodeJS.Timeout;
    cardNumberFocusInStopwatch: NodeJS.Timeout;
    userName: string;
    cardMonth: string;
    cardYear: string;
    isCartPage: boolean = false;
    cvvFocusInStopwatch: NodeJS.Timeout;

    attached() {
        this.userName = this.fullnameValueConverter.toView(this.nameOnCard ? this.nameOnCard?.firstName : this.user?.firstName, this.nameOnCard ? this.nameOnCard?.lastName : this.user?.lastName, false);
        if (!this.requestOnlyCvv) this.showMiniSpinner = this.showGreenCheckMark = this.showErrorCheckMark = false;
        if (this.cardInfo?.cardExpiry) {
            this.cardMonth = this.cardInfo.cardExpiry.month.toString().padStart(2, '0');
            this.cardYear = this.cardInfo.cardExpiry.year.toString();
            this.cardMonthYear = `${this.cardMonth}/${this.cardYear}`;
            this.cardCvv = this.cardInfo?.cvv;
            this.cardType = this.cardInfo.type;
            this.focusExpDate();
        }
        this.isCartPage = this.router.currentInstruction.config.route === 'cart' || this.ignoreRoute;
    }

    cardNumberChanged(newVal) {
        if (newVal) return;

        this.cardNumberDisplayed = '';
        this.isExpDateValid = this.isCvvValid = this.isCcValid = false;
    }

    handleCCFocus(ev: Event) {
        const { type } = ev;
        this.clearTimeoutsAndIcons([this.cardNumberFocusInStopwatch]);
        this.cardNumberIsFocused = type === 'focusin';
        if (type === 'focusout') {
            this.creditCardFocusOut();
            this.cardNumberDisplayed = this.helper.truncateInTheMiddle(this.cardNumber);
        }
        if (type === 'focusin') {
            this.cardNumberDisplayed = this.cardNumber;
            this.cardNumberFocusInStopwatch = setTimeout(() => this.keyUpCCHandler(), 400);
        }

        this.showMiniSpinner = false;
    }

    creditCardFocusOut() {
        this.showMiniSpinner = false;
        const timeouts = [this.cardNumberFocusInStopwatch, this.miniSpinnerTimer, this.checkIsCCNumberValidTimer];
        this.clearationTimeoutValueConverter.toView(timeouts);
        this.cardNumber = this.cardNumberDisplayed;
        if (this.cardNumber) this.validateCc();

        this.cardType = this.creditCardHelper.getType(this.cardNumber);
    }

    validateCc() {
        this.showMiniSpinner = false;
        if (this.cardNumberIsFocused) this.cardNumber = this.cardNumberDisplayed;
        this.isCCNumberValid = this.creditCardHelper.validateCC(this.cardNumber);
        if (this.isCCNumberValid) {
            this.cardNumberIsFocused = false;
            this.taskQueue.queueMicroTask(() => this.cardMonthYearEl.focus());
            return;
        }

        this.showValidationError();
    }

    keyUpCCHandler(ev? : KeyboardEvent) {
        this.showMiniSpinner = this.showGreenCheckMark = this.showErrorCheckMark = false;
        const timeouts = [this.miniSpinnerTimer, this.checkIsCCNumberValidTimer];
        this.clearationTimeoutValueConverter.toView(timeouts);
        if (ev?.key === 'Enter') {
            if (this.cardNumberDisplayed?.length > 0) this.validateCc();
        } else {
            if (this.cardNumberDisplayed?.length > 0) {
                this.miniSpinnerTimer = setTimeout(() => {
                    this.showMiniSpinner = true;
                    this.checkIsCCNumberValidTimer = setTimeout(() => {
                        this.validateCc();
                    }, 2000);
                }, 1000);
            }
        }
    }

    validateExpDate(ev: KeyboardEvent) {
        this.showMiniSpinner = this.showGreenCheckMark = this.showErrorCheckMark = false;
        this.creditCardHelper.setExpirationDateFieldHandlers(this.cardMonthYearEl);
        const timeouts = [this.checkExpDateValidTimer];
        this.clearationTimeoutValueConverter.toView(timeouts);
        if (ev.key === 'Enter') {
            this.focusExpDate();
        } else {
            this.checkExpDateValidTimer = setTimeout(() => {
                this.focusExpDate();
            }, 2000);
        }
    }

    focusExpDate() {
        if (this.cardMonthYear) {
            const timeouts = [this.checkExpDateValidTimer, this.miniSpinnerTimer, this.checkIsCVVValidTimer, this.cvvFocusInStopwatch];
            this.clearationTimeoutValueConverter.toView(timeouts);
            this.isExpDateValid = this.creditCardHelper.creditCardExpiryDateValidationFromString(this.cardMonthYear);
            this.showGreenCheckMark = this.showErrorCheckMark = false;
            if (this.isExpDateValid) {
                this.checkExpDateValidTimer = setTimeout(() => {
                    this.showMiniSpinner = false;
                    if (!this.isCartPage) {
                        this.showGreenCheckMark = true;
                        this.creditCardCvvValidation();
                    } else this.cardCVVEl?.focus();
                }, !this.isCartPage ? 2000 : 1000);
            } else {
                this.toastService.showToast('Error', 'Incorrect card format. Please check your card number, MM/YY and CVV.', 'error');
                this.showMiniSpinner = false;
                this.showErrorCheckMark = this.showErrorCheckMark || !this.isCartPage;
            }
        }
    }

    validateMonthExpDate() {
        this.clearTimeoutsAndIcons ([this.checkExpDateValidTimer], true);
        this.isCcValid = false;
        this.checkExpDateValidTimer = setTimeout(() => {
            this.cardMonth = this.cardMonth.padStart(2, '0');
            this.cardMonthYear = `${this.cardMonth}/${this.cardYear}`;
            this.focusExpDate();
        }, 1000);
    }

    validateYearExpDate() {
        this.clearTimeoutsAndIcons ([this.checkExpDateValidTimer], true);
        this.isCcValid = false;
        this.checkExpDateValidTimer = setTimeout(() => {
            this.cardMonthYear = `${this.cardMonth}/${this.cardYear}`;
            this.focusExpDate();
        }, 1000);
    }

    keyUpCvv(ev?: KeyboardEvent, comesFromFocus: boolean = false) {
        this.clearTimeoutsAndIcons ([this.miniSpinnerTimer, this.checkIsCVVValidTimer, this.cvvFocusInStopwatch]);
        this.isCcValid = this.isCvvValid = false;
        if (ev?.key === 'Enter') {
            if (this.cardCvv?.length > 0) this.creditCardCvvValidation();
        } else {
            if (this.cardCvv?.length > 0) {
                this.miniSpinnerTimer = setTimeout(() => {
                    this.showMiniSpinner = true;
                    this.checkIsCVVValidTimer = setTimeout(() => {
                        this.creditCardCvvValidation();
                    }, 2000);
                }, comesFromFocus ? 0 : 1000);
            }
        }
    }

    focusCvvHandler(ev: MouseEvent) {
        const { type } = ev;
        this.clearTimeoutsAndIcons([this.cvvFocusInStopwatch]);

        if (type === 'focusout') {
            const timeouts = [this.miniSpinnerTimer];
            this.clearationTimeoutValueConverter.toView(timeouts);
            if (this.cardCvv) this.creditCardCvvValidation();
        }

        if (type === 'focusin') {
            this.isCcValid = false;
            this.cvvFocusInStopwatch = setTimeout(() => this.keyUpCvv(new KeyboardEvent('keyup'), true), 400);
        }

        this.showMiniSpinner = false;
    }

    clearTimeoutsAndIcons (timeouts: NodeJS.Timeout[], setMiniSpinner = false) {
        this.showGreenCheckMark = this.showErrorCheckMark = this.showMiniSpinner = false;
        this.clearationTimeoutValueConverter.toView(timeouts);
        if (!this.isCartPage && setMiniSpinner) this.showMiniSpinner = true;
    }

    keyDownHandler(ev: KeyboardEvent) {
        if (ev.ctrlKey || ev.altKey || typeof ev.key !== 'string' || ev.key.length !== 1) {
            return true;
        }

        if (!onlyNumberRegExp().test(ev.key)) {
            ev.preventDefault();
        }

        return onlyNumberRegExp().test(ev.key);
    }

    keyDownDateHandler(ev: KeyboardEvent) {
        if (ev.ctrlKey || ev.altKey || typeof ev.key !== 'string' || ev.key.length !== 1) {
            return true;
        }

        if (!numbersAndSlashExp().test(ev.key)) {
            ev.preventDefault();
        }

        return numbersAndSlashExp().test(ev.key);
    }

    creditCardCvvValidation() {
        this.showMiniSpinner = false;

        this.isCCNumberValid = this.isCCNumberValid || !!this.cardInfo?.cardId;

        if (!(this.cardNumber || this.cardInfo?.cardId) || !this.cardMonthYear) return;

        if (!this.cardInfo?.cardId) this.cardType = this.creditCardHelper.getType(this.cardNumber);

        this.isCvvValid = this.creditCardHelper.creditCardCvvValidation(this.cardNumber, this.cardCvv, this.cardType);

        this.isCcValid = this.requestOnlyCvv || (this.isCartPage && !this.isCvvValid) ? this.isCvvValid : (this.isCCNumberValid && this.isExpDateValid);

        if (this.isCcValid) {
            this.showGreenCheckMark = true;
            return;
        }

        this.showValidationError();
    }

    showValidationError() {
        this.showErrorCheckMark = true;
        this.toastService.showToast('Error', 'Incorrect card format. Please check your card number, MM/YY and CVV.', 'error');
    }
}
