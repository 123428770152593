// Module
var code = `<template>
    <div id="cx-address-input">
        <div class="meetup-combo position-relative w-100 d-flex joined-input-container">
            <cx-places-autocomplete-input 
                class="main-container" 
                autocompleted-address.two-way="autocompletedAddress" 
                loading.bind="loading"
                if.bind="cashInPersonCheck"
            >
            </cx-places-autocomplete-input>
            <div class="time-container">
                <div class="position-relative w-50">
                    <mdc-text-field
                        id="datepickerInput" 
                        outlined
                        required.bind="true"
                        value.bind="date"
                        placeholder="Enter date" 
                        class="w-100 input-password datepickerInput middle-input cx-form-control-borderless"
                        focusout.delegate="validateAddress() & debounce:500"
                    >
                    </mdc-text-field>
                    <span class="calendar-icon material-icons-outlined" alt="calendar">date_range</span>
                </div>
                <cx-dropdown click.delegate="validateAddress() & debounce:500" if.bind="hours.length" id.bind="'timeSelector'" options.bind="hours" placeholder.two-way="timePlaceholder" pressed.bind="timePlaceholder !== 'Enter time'"  borderless.bind="true" selected-option.two-way="selectedOption" position.bind="'last'" icon.bind="'schedule'" class="w-50"></cx-dropdown>
            </div>
            <span
                class="circle-icon check material-icons-outlined"
                alt="green check"
                if.bind="showGreenCheckMarkAddress"
                >check_circle
            </span>
            <mdc-circular-progress if.bind="showMiniSpinnerAddress" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
        </div>
    </div>
</template>
`;
// Exports
export default code;