// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/triangle-right.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-list-element" click.delegate="handleClick()">
        <cx-list-item class="\${hoverEffect}">
            <div class="main-flex">
                <mdc-icon
                    if.bind="icon"
                    trailing
                    class="\${materialIconStyle}">
                    \${icon}
                </mdc-icon>
                <div class="body-flex">
                    <div class="title-flex">
                        <p class="title">\${title}</p>
                        <p class="subtitle">\${subtitle}</p>
                    </div>
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="navigation icon" class="\${navigationArrowStyle}" loading="lazy">
                </div>
            </div>
        </cx-list-item>
    </div>
</template>
`;
// Exports
export default code;