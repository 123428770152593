import { User } from 'services/models/user/user';
import './cx-cash-in-person.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Exchange } from 'services/models/purchase-flow/exchange';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';

@autoinject()
export class CxCashInPerson {
    constructor(private deliveryDateConverter: DeliveryDateConverter) {}

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable user: User;
    @bindable exchange: Exchange;
    @bindable time: string;
    @bindable selectedDelivery: DeliveryMethod;
    @bindable location: string;
    @bindable date: string;
    @bindable meetupAddressValid: boolean;
    @bindable loading: boolean = true;
    meetupTime: string;
    parent;
    meetupComboViewmodel;
    cashInPersonExpandable;
    expandableOpen = false;

    validateCryptoPayButton() {
        this.parent.validateCryptoPayButton?.();
    }

    attached() {
        this.selectedDeliveryChanged();
    }

    selectedDeliveryChanged() {
        this.meetupTime = this.deliveryDateConverter.toView(this.selectedDelivery);
    }

    reset() {
        this.meetupComboViewmodel.reset();
    }

    onToggle() {
        this.cashInPersonExpandable.toggle();
    }
}
