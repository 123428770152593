import { Router } from 'aurelia-router';
import './404.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { Helper } from 'resources/helpers/helper';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class UnknownRoute {
    constructor(private websiteService: WebsiteService, private helper: Helper, private router: Router) { }

    homePageRoute;

    async attached() {
        this.helper.handlePrerender404(this.router);
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).prerenderReady = true;
    }
}
