import './cx-button.scss';
import { bindable } from 'aurelia-framework';

export class CxButton {
    @bindable buttonStyle?: string;
    @bindable buttonText?: string;
    @bindable buttonIcon?: string;
    @bindable buttonIconStyle?: string;
    @bindable buttonDisabled?: boolean;
    @bindable disabledStyle = '';
    @bindable isTruncate = false;
}
