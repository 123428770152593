// Module
var code = `<template>
    <section id="cookies" class="legal-page">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">Cookies</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'COOKIES_LAST_UPDATED'" class="text-end last-update">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SUMMARY_OF_COOKIES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SUMMARY_OF_COOKIES_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_EXAMPLES_SESSION_COOKES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SESSION_COOKIES_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_PREFERENCE_COOKIES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_PREFERENCE_COOKIES_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SECURITY_COOKIES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SECURITY_COOKIES_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_ADVERSITING_COOKIES_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_ADVERSITING_COOKIES_CONTENT'" class="text-content">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;