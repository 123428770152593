import './cx-referral-row.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class CxReferralRow {
    @bindable referral;

    constructor(private router: Router) {
    }
}
