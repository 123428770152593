// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/google.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/apple.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/discord-auth.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/chicksgold.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/icons/chicksx.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/icons/acckings.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/icons/gamertotal.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/icons/divicasales.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-authenticate-form" class="position-relative">
        <cx-veriff
            view-model.ref="veriffViewModel"
            hidden-button="true"
            user.bind="user"
            class="d-block">
        </cx-veriff>
        <!-- Sign In -->
        <div if.bind="state === 'sign-in'" id="login-container" class="auth-container d-flex flex-column">
            <h1 class="title font-bold">
                \${'CX_SIGN_IN' | siteStrings}
            </h1>
            <form class="\${loading ? '' : 'form'}" submit.delegate="login(tokenRequired)">
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_EMAIL_ADDRESS' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        ref="emailLoginElement"
                        outlined
                        autocomplete="on"
                        value.two-way="email & validate"
                        id="username-input"
                        required.bind="true"
                        name="Email"
                        placeholder.bind="'CX_ENTER_EMAIL' | siteStrings"
                        class="input-password w-100 cx-form-control \${ isRequesting ? 'pe-none' : '' } \${ showGreenCheckMarkEmailLogin ? 'cx-form-control--success' : showErrorCheckMarkEmailLogin ? 'cx-form-control--error' : '' }"
                        keyup.delegate="emailUpdatedOnKeyPress(\$event)"
                        focusin.delegate="emailUpdatedOnFocusIn()"
                        focusout.delegate="checkEmailValidation()"
                        keydown.delegate="onTabPressed(\$event)"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkEmailLogin">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkEmailLogin">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerEmailLogin" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_PASSWORD' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        ref="passwordLoginElement"
                        outlined
                        value.two-way="password & validate"
                        id="password-input"
                        name="Password"
                        required.bind="true"
                        type="\${visible ? 'text' : 'password'}"
                        class="input-password cx-form-control w-100 \${ isRequesting ? 'pe-none' : '' } \${ passwordValidLogin ? 'cx-form-control--success' : passwordInvalidLogin ? 'cx-form-control--error' : '' }"
                        placeholder.bind="'CX_ENTER_PASSWORD' | siteStrings"
                        keyup.delegate="passwordUpdatedOnKeyPress(\$event)"
                        focusin.delegate="passwordUpdatedOnFocusIn()"
                        focusout.delegate="checkPasswordValidation()"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="passwordValidLogin">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkPasswordLogin">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerPasswordLogin" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
                <div if.bind="tokenRequired">
                    <label class="heading text-regular text-auth">
                        \${'CX_TOKEN' | siteStrings}
                    </label>
                </div>
                <div class="position-relative" if.bind="tokenRequired">
                    <mdc-text-field
                        ref="tokenLoginElement"
                        outlined
                        autocomplete="on"
                        value.two-way="token & validate"
                        id="token-input"
                        type="text"
                        name="Token"
                        placeholder.bind="'CX_ENTER_2FA_TOKEN' | siteStrings"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkTokenLogin ? 'cx-form-control--success' : showErrorCheckMarkTokenLogin ? 'cx-form-control--error' : ''}"
                        keyup.delegate="tokenUpdatedOnKeyPress(\$event)"
                        focusin.delegate="tokenUpdatedOnFocusIn()"
                        focusout.delegate="checkTokenValidation()"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkTokenLogin">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkTokenLogin">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerTokenLogin" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
                <div if.bind="loading" class="d-flex justify-content-center spin-loader">
                    <mdc-circular-progress size="45" stroke-width="3"></mdc-circular-progress>
                </div>
                <input type="submit" hidden/>
            </form>
            <a click.delegate="lostVerification()" href.bind="tokenRequired ? '' : 'sign-in/reset-password'" class="\${isRequesting ? 'pe-none' : ''} text-center cursor-pointer text-forgot text-underline text-regular">
                \${(tokenRequired ? 'CX_LOST_2FA_TOKEN' : CX_FORGOT_YOUR_PASSWORD) | siteStrings}
            </a>
            <p class="margin-question-have-account font-primary text-center text-auth text-regular">
                \${'CX_DONT_HAVE_ACCOUNT' | siteStrings}
                <a href="sign-up" class="\${isRequesting ? 'pe-none' : ''} no-decoration cursor-pointer link-auth link-click">
                    \${'CX_SIGN_UP' | siteStrings}
                </a>
            </p>
            <div class="d-flex flex-column align-items-center">
                <span class="text-small separator-text or-label position-relative">\${'CX_OR' | siteStrings}</span>
                <div class="d-flex flex-row options-container">
                    <!-- Google Sign in Option -->
                    <cx-touch-target expanded>
                        <button class="sign-in-options auth-opt" click.delegate="handleGoogleOption()">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_0___}" alt="Google" loading="lazy">
                        </button>
                    </cx-touch-target>
                    <!-- Apple Sign in Option -->
                    <cx-touch-target expanded>
                        <button class="sign-in-options auth-opt" click.delegate="handleAppleOption()">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_1___}" alt="Apple" loading="lazy">
                        </button>
                    </cx-touch-target>
                    <!-- Discord Sign in Option -->
                    <cx-touch-target expanded>
                        <button class="sign-in-options auth-opt" click.delegate="handleDiscordOption()">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_2___}" alt="Discord" loading="lazy">
                        </button>
                    </cx-touch-target>
                </div>
                <span class="text-small separator-text chicks-group-label position-relative">Chicks Group</span>
                <div class="d-flex flex-row websites-container">
                    <cx-touch-target expanded>
                        <a class="chicksgold-website" 
                            href="\${chicksGoldBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="chicksGoldIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(chicksGoldIconTooltip)"
                            mdc-tooltip="value: Chicks Gold - Most trusted video game asset marketplace.; y-position: ABOVE;">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_3___}" alt="ChicksGold" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="chicksx-website" 
                            href="\${baseUrl}" 
                            target="_blank"
                            mdc-tooltip.ref="chicksXIconTooltip" mouseover.trigger="tooltipArrowStyling.toView(chicksXIconTooltip)"
                            mdc-tooltip="value: ChicksX - Buy, sell, exchange bitcoin, crypto or fiat instantly in any major city around the globe.; y-position: ABOVE;">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_4___}" alt="ChicksX" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="acckings-website" 
                            href="\${accKingsBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="accKingsIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(accKingsIconTooltip)"
                            mdc-tooltip="value: AccountKings - Play like royalty. Buy and sell accounts for your favorite games.; y-position: ABOVE;">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_5___}" alt="AccKings" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="gamertotal-website" 
                            href="\${gamerTotalBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="gamerTotalIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(gamerTotalIconTooltip)"
                            mdc-tooltip="value: Gamer Total - Browse listings, compare offers, and buy currency.; y-position: ABOVE;">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_6___}" alt="GamerTotal" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="divicasales-website" 
                            href="\${divicaSalesBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="divicaSalesIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(divicaSalesIconTooltip)"
                            mdc-tooltip="value: Divica Sales - Your destination to buy, sell, boost or swap your video game assets.; y-position: ABOVE;">
                            <img class="sign-in-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_7___}" alt="DivicaSales" loading="lazy">
                        </a>
                    </cx-touch-target>
                </div>
                <span class="text-small all-accounts-label text-center">\${'CX_ONE_ACCOUNT_ALL_CHICKS' | siteStrings}</span>
            </div>
        </div>
        <!-- Sign Up-->
        <div  if.bind="['sign-up', 'r/:referralLink'].includes(state)" id="register-container" class="auth-container d-flex flex-column">
            <h1 class="title font-bold">
                \${'CX_SIGN_UP' | siteStrings}
            </h1>
            <form submit.delegate="register()">
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_EMAIL_ADDRESS' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        outlined
                        autocomplete="false"
                        value.two-way="newEmail & validate"
                        id="register-username-input"
                        required.bind="true"
                        type="text"
                        placeholder.bind="'CX_ENTER_EMAIL' | siteStrings"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkEmailRegister ? 'cx-form-control--success' : showErrorCheckMarkEmailRegister ? 'cx-form-control--error' : ''}"
                        keyup.delegate="newEmailUpdatedOnKeyPress(\$event)"
                        focusin.delegate="newEmailUpdatedOnFocusIn()"
                        focusout.delegate="checkNewEmailValidation()"
                        ref="emailSignUpInput"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkEmailRegister">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkEmailRegister">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerEmailRegister" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_PASSWORD' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        outlined
                        autocomplete="new-password"
                        value.two-way="newPassword & validate"
                        id="register-password-input"
                        required.bind="true"
                        type="\${visiblePassword ? 'text' : 'password'}"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${passwordValidRegister ? 'cx-form-control--success' : passwordInvalidRegister ? 'cx-form-control--error' : ''}"
                        keyup.delegate="newPasswordUpdatedOnKeyPress(\$event)"
                        focusin.delegate="newPasswordUpdatedOnFocusIn()"
                        focusout.delegate="checkNewPasswordValidation()"
                        placeholder.bind="'CX_ENTER_PASSWORD' | siteStrings"
                        ref="passwordSignUpInput"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="passwordValidRegister">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkPasswordRegister">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerPasswordRegister" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    <cx-validator-progress
                        if.bind="!signInOption"
                        value.bind="newPassword"
                        validator-property.two-way="newPasswordValidatorProperty"
                    ></cx-validator-progress>
                </div>
                <input type="submit" hidden/>
            </form>
            <mdc-form-field>
                <mdc-checkbox checked.bind="optedInForEmails" class="checkbox"
                    change.delegate="optedInForEmailsOnClick()"></mdc-checkbox>
                <label class="font-light text-regular text-auth text-center text-auth-regular">
                    \${'CX_SUBSCRIBE_NEWSLETTER' | siteStrings}
                </label>
            </mdc-form-field>
            <div if.bind="loading" class="d-flex justify-content-center spin-loader my-4">
                <mdc-circular-progress size="45" stroke-width="3"></mdc-circular-progress>
            </div>
            <div class="d-flex flex-column align-items-center social-media-sign-up-icons">
                <span class="text-small or-label text-auth position-relative">\${'CX_OR' | siteStrings}</span>
                <div class="d-flex flex-row options-container">
                    <!-- Google Sign up Option -->
                    <cx-touch-target expanded>
                        <button class="sign-up-options auth-opt" click.delegate="handleGoogleOption()">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_0___}" alt="Google" loading="lazy">
                        </button>
                    </cx-touch-target>
                    <!-- Apple Sign up Option -->
                    <cx-touch-target expanded>
                        <button class="sign-up-options auth-opt" click.delegate="handleAppleOption()">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_1___}" alt="Apple" loading="lazy">
                        </button>
                    </cx-touch-target>
                    <!-- Discord Sign up Option -->
                    <cx-touch-target expanded>
                        <button class="sign-up-options auth-opt" click.delegate="handleDiscordOption()">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_2___}" alt="Discord" loading="lazy">
                        </button>
                    </cx-touch-target>
                </div>
                <span class="text-small chicks-group-label text-auth position-relative">Chicks Group</span>
                <div class="d-flex flex-row websites-container">
                    <cx-touch-target expanded>
                        <a class="chicksgold-website" 
                            href="\${chicksGoldBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="chicksGoldIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(chicksGoldIconTooltip)"
                            mdc-tooltip="value: Chicks Gold - Most trusted video game asset marketplace.; y-position: ABOVE;">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_3___}" alt="ChicksGold" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="chicksx-website" 
                            href="\${baseUrl}" 
                            target="_blank"
                            mdc-tooltip.ref="chicksXIconTooltip" mouseover.trigger="tooltipArrowStyling.toView(chicksXIconTooltip)"
                            mdc-tooltip="value: ChicksX - Buy, sell, exchange bitcoin, crypto or fiat instantly in any major city around the globe.; y-position: ABOVE;">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_4___}" alt="ChicksX" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="acckings-website" 
                            href="\${accKingsBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="accKingsIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(accKingsIconTooltip)"
                            mdc-tooltip="value: AccountKings - Play like royalty. Buy and sell accounts for your favorite games.; y-position: ABOVE;">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_5___}" alt="AccKings" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="gamertotal-website" 
                            href="\${gamerTotalBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="gamerTotalIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(gamerTotalIconTooltip)"
                            mdc-tooltip="value: Gamer Total - Browse listings, compare offers, and buy currency.; y-position: ABOVE;">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_6___}" alt="GamerTotal" loading="lazy">
                        </a>
                    </cx-touch-target>
                    <cx-touch-target expanded>
                        <a class="divicasales-website" 
                            href="\${divicaSalesBaseUrl}" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                            mdc-tooltip.ref="divicaSalesIconTooltip"
                            mouseover.trigger="tooltipArrowStyling.toView(divicaSalesIconTooltip)"
                            mdc-tooltip="value: Divica Sales - Your destination to buy, sell, boost or swap your video game assets.; y-position: ABOVE;">
                            <img class="sign-up-icons cursor-pointer" src="${___HTML_LOADER_IMPORT_7___}" alt="DivicaSales" loading="lazy">
                        </a>
                    </cx-touch-target>
                </div>
            </div>
            <span class="text-center text-regular text-auth mt-10px">
                \${'CX_ALREADY_HAVE_ACCOUNT' | siteStrings}
                <a class="\${isRequesting ? 'pe-none' : ''} link-auth no-decoration cursor-pointer link-click" href="sign-in">
                    \${'CX_SIGN_IN' | siteStrings}
                </a>
            </span>
            <span class="register-paragraph font-primary font-light text-small text-auth text-center mt-10px mx-auto">
                \${'CX_BY_CONTINUING' | siteStrings}
                <a class="text-green no-decoration cursor-pointer link-click" href="\${termsOfServicePageRoute}" target="_blank">\${'CX_TERMS_OF_SERVICE' | siteStrings}</a>
                \${'CX_AND' | siteStrings}
                <a class="text-green no-decoration cursor-pointer link-click" href="\${privacyPolicyPageRoute}" target="_blank">\${'CX_PRIVACY_POLICY' | siteStrings}</a>.
            </span>
        </div>
        <!-- Reset Password -->
        <div if.bind="state === 'sign-in/reset-password'" id="reset-container" class="auth-container d-flex flex-column">
            <h1 class="title font-bold" if.bind=!params.accessToken>
                \${'CX_RESET_PASSWORD' | siteStrings}
            </h1>
            <h1 class="title font-bold" else>
                \${'CX_CHANGE_PASSWORD' | siteStrings}
            </h1>
            <form if.bind="!params.accessToken" class="form" submit.delegate="sendEmailReset()">
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_EMAIL_ADDRESS' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        outlined
                        autocomplete="on"
                        value.two-way="recoveryEmail & validate"
                        id="username-input"
                        required.bind="true"
                        type="text"
                        placeholder.bind="'CX_ENTER_EMAIL' | siteStrings"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${showGreenCheckMarkRecoveryEmail ? 'cx-form-control--success' : showErrorCheckMarkRecoveryEmail ? 'cx-form-control--error' : ''}"
                        keyup.delegate="recoveryEmailUpdatedOnKeyPress(\$event)"
                        focusin.delegate="recoveryEmailUpdatedOnFocusIn()"
                        focusout.delegate="checkRecoveryEmailValidation()"
                        ref="emailResetInput"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showGreenCheckMarkRecoveryEmail">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkRecoveryEmail">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerRecoveryEmail" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
                <div if.bind="loading" class="d-flex justify-content-center spin-loader my-4">
                    <mdc-circular-progress size="45" stroke-width="3"></mdc-circular-progress>
                </div>
                <input type="submit" hidden/>
            </form>
            <form else class="form" submit.delegate="updatePassword()">
                <div>
                    <label class="heading text-regular text-auth">
                        \${'CX_NEW_PASSWORD' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        outlined
                        value.two-way="resetPassword & validate"
                        id="register-password-input"
                        required.bind="true"
                        type="\${visiblePassword ? 'text' : 'password'}"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${resetPasswordValid ? 'cx-form-control--success' : resetPasswordInvalid ? 'cx-form-control--error' : ''}"
                        keyup.delegate="resetPasswordUpdatedOnKeyPress(\$event)"
                        focusin.delegate="resetPasswordUpdatedOnFocusIn()"
                        focusout.delegate="checkResetPasswordValidation()"
                        placeholder.bind="'CX_ENTER_NEW_PASSWORD' | siteStrings"
                        ref="newPasswordInput"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="resetPasswordValid">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkResetPassword">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerResetPassword" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    <cx-validator-progress
                        value.bind="resetPassword"
                        validator-property.two-way="resetPasswordValidatorProperty"
                    ></cx-validator-progress>
                </div>
                <div class="confirm-pwd-container">
                    <label class="heading text-regular text-auth">
                        \${'CX_CONFIRM_NEW_PASSWORD' | siteStrings}
                    </label>
                </div>
                <div class="position-relative">
                    <mdc-text-field
                        outlined
                        value.two-way="confirmPassword & validate"
                        id="confirm-password-input"
                        required.bind="true"
                        type="\${visibleConfirmPassword ? 'text' : 'password'}"
                        class="input-password cx-form-control w-100 \${isRequesting ? 'pe-none' : ''} \${confirmPasswordValid ? 'cx-form-control--success' : confirmPasswordInvalid ? 'cx-form-control--error' : ''}"
                        keyup.delegate="confirmPasswordUpdatedOnKeyPress(\$event)"
                        focusin.delegate="confirmPasswordUpdatedOnFocusIn()"
                        focusout.delegate="checkConfirmPasswordValidation()"
                        placeholder.bind="'CX_CONFIRM_NEW_PASSWORD' | siteStrings"
                        ref="confirmPasswordInput"
                    ></mdc-text-field>
                    <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="confirmPasswordValid">check_circle</span>
                    <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorCheckMarkConfirmPassword">error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerConfirmPassword" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                    <cx-validator-progress
                        value.bind="confirmPassword"
                        validator-property.two-way="confirmPasswordValidatorProperty"
                    ></cx-validator-progress>
                </div>
                <div if.bind="loading" class="d-flex justify-content-center spin-loader my-4">
                    <mdc-circular-progress size="45" stroke-width="3"></mdc-circular-progress>
                </div>
                <input type="submit" hidden/>
            </form>
        </div>
    </div>
</template>
`;
// Exports
export default code;